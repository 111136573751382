import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import './errorPage.css';
import eroricon from '../../images/eroricon.png';
import {Loading} from '../../actions/LoadingAction';
import * as routes from '../../constants/routes';

class ErrorPage extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {};
  }
  componentDidMount(){
    this.props.Loading(false);
  }

  render () {
    return (
      <div className="errormaindiv">
        <div className="aligncenter">
          <img alt="" src={eroricon} className="errorimg" />
        </div>
        <div className="oops">
          Oops!
        </div>
        <div className="errortext">
          Something has gone wrong. <br />
          Blow on the cartridge and try again.
        </div>
        <div className="aligncenter">
          <button className="errorbutton" onClick={()=>window.location.href=routes.LANDING_PAGE}> Go To Home Page</button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators ({
    Loading
  }, dispatch);
};

export default connect (mapStateToProps, mapDispatchToProps) (ErrorPage);
