import React, {Component} from 'react';

import moment from 'moment';

// import ServiceOnDemandProfile
//   from '../../../service-On-Demand-Profile/serviceOnDemandProfile';
import ServiceOnDemandSidebarPage
  from '../../../../components/serviceOnDemandSidebar/serviceOnDemandSidebar';
import ServiceOnDemandCommonComponent
  from '../../../Service-On-Demand-CommanComponent/Service-On-Demand-CommanComponent';
  import {acceptServiceRequest,rejectServiceRequest} from '../../../../actions/serviceRequestAction';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as routes from '../../../../constants/routes';
import './recievedRequestDetail.css';
import provider1 from '../../../../images/provider1.png';
import sergroup from '../../../../images/sergroup.png';
import yellowdot
  from '../../../../images/serviceOnDemandMyRequest/yellowdot.png';
import greywatch
  from '../../../../images/serviceOnDemandMyRequest/greywatch.png';
import star from '../../../../images/serviceOnDemandProfile/star.png';
import roman from '../../../../images/serviceOnDemandSearch/roman.png';
import close_icon from '../../../../images/close_icon.png';
import {Button, Modal, ModalBody,Progress} from 'reactstrap';
import {GetMyRequestsRecieved} from '../../../../actions/serviceOnDemandAction';
import profilePlaceholder from '../../../../images/profile_placeholder.png';
import StarRatingComponent from 'react-star-rating-component';

class ServiceOnDemandRecievedRequestDetailPage extends Component {
  constructor (props) {
    super (props);
    this.state = {
      rejectServiceModal: false,
      requestRejectModal: false,
      myRequestsRecievedList: [],
    };
  }
  componentWillReceiveProps (nextProps) {
   
    if (nextProps) {
      if (
        nextProps.service &&
        nextProps.service.myRequestRecieved &&
        nextProps.service.myRequestRecieved.length > 0
      ) {
        this.setState ({
          myRequestsRecievedList: nextProps.service.myRequestRecieved[0],
        });
      }
    }
  }

  componentDidMount () {
    
    let geturl = this.props.match.params;

    if (geturl && geturl.serviceId) {
      this.setState (
        {
          serviceId: geturl.serviceId,
        },
        () => {
          let sendRequest = {
            data: {
              status: '',
              docID: this.state.serviceId,
            },
          };
          this.props.GetMyRequestsRecieved (sendRequest);
        }
      );
    }
  }
  openRejectServiceModal = () => {
    this.setState ({rejectServiceModal: true});
  };
  closeRejectServiceModal = () => {
    this.setState ({rejectServiceModal: false});
  };

  openRequestRejectModal = () => {
    this.setState ({requestRejectModal: true});
  };
  closeRequestRejectModal = () => {
    this.setState ({requestRejectModal: false});
  };
  acceptRequest=()=>{
    let geturl = this.props.match.params;
    if (geturl && geturl.serviceId) {
      this.setState (
        {
          serviceId: geturl.serviceId,
        },
        () => {
          let sendRequest = {
            data: {
              docID: this.state.serviceId,
            },
          };
          this.props.acceptServiceRequest (sendRequest);
        }
      );
    }
  }
  rejectRequest=()=>{
    let geturl = this.props.match.params;
    if (geturl && geturl.serviceId) {
      this.setState (
        {
          serviceId: geturl.serviceId,
        },
        () => {
          let sendRequest = {
            data: {
              docID: this.state.serviceId,
            },
          };
          this.props.rejectServiceRequest (sendRequest);
        }
      );
    }
  }
  
  render () {
    const {myRequestsRecievedList} = this.state;
    return (
      <div style={{display: 'flex', background: '#FFFFFF', height: '100%'}}>

        <div className="receivedRequestMaineContainer">
          <div style={{width: 230}}>
            <ServiceOnDemandCommonComponent />
          </div>
          <div className="ICEPRO">
            {' '}
            SERVICE PROVIDER DASHBOARD
          </div>
          <div>
            <Button
              className="gobackButtons"
              onClick={() => this.props.history.goBack ()}
            >
              <div>
                <img alt="" src={provider1} />
              </div>
              <span> GO BACK </span>
            </Button>
            <div className="REQUESTsss">
              REQUEST #
              {myRequestsRecievedList ? myRequestsRecievedList.request_id : ''}
            </div>

          </div>
          <div className="qvfGE">
            {myRequestsRecievedList ? myRequestsRecievedList.title : ''}
          </div>
          <div style={{display: 'flex'}}>
            <div>
              <div className="xsersc">
                Description:
              </div>
              <div className="sclooking">
                {myRequestsRecievedList
                  ? myRequestsRecievedList.description
                  : ''}
                <br />{' '}

                {' '}

                {' '}
                <br />
                {' '}

              </div>
              <div className="serJOB">
                JOB DETAILS
              </div>
              <div style={{display: 'flex'}}>
                <div>
                  <div className="sermanufaxf">Manufacturer: </div>
                  <div className="sermanufaxf">Modality: </div>
                  <div className="sermanufaxf">System Name: </div>
                  <div className="sermanufaxf">Type of Service: </div>
                  <div className="sermanufaxf">Expected Delivery Date: </div>
                  <div className="sermanufaxf">Type of Call: </div>
                  <div className="sermanufaxf">Payment Offered: </div>
                </div>
                <div>
                  <div className="serGESer">
                    {' '}{' '}
                    {myRequestsRecievedList
                      ? myRequestsRecievedList.manufacturer
                      : ''}
                    {' '}
                  </div>
                  <div className="serGESer">
                    {myRequestsRecievedList
                      ? myRequestsRecievedList.modality
                      : ''}
                  </div>
                  <div className="serGESer">
                    {myRequestsRecievedList
                      ? myRequestsRecievedList.systemName
                      : ''}
                    {' '}
                  </div>
                  <div className="serGESer">
                    {myRequestsRecievedList
                      ? myRequestsRecievedList.typeOfService
                      : ''}
                  </div>
                  <div className="serGESer">

                    {myRequestsRecievedList &&
                      myRequestsRecievedList.serviceDate
                      ? moment (myRequestsRecievedList.serviceDate).format (
                          'DD/MM/YYYY'
                        )
                      : ''}
                  </div>
                  <div className="serGESer">
                    {myRequestsRecievedList && myRequestsRecievedList.callType
                      ? myRequestsRecievedList.callType
                      : ''}
                    {' '}
                  </div>

                  <div className="serGESer">
                    {myRequestsRecievedList
                      ? myRequestsRecievedList.paymentOffered
                      : ''}
                    {' '}
                  </div>
                </div>
                <div style={{width: 140, textAlign: 'center'}}>
                  <div className="sermanufaxf">Error Code: </div>
                  <div className="sermanufaxf">Error Log: </div>
                </div>
                <div>
                  <div className="serGESer">
                    {' '}{' '}
                    {myRequestsRecievedList
                      ? myRequestsRecievedList.errorLogName
                      : ''}
                    {' '}
                  </div>
                  <div className="serGESer">
                    {myRequestsRecievedList
                      ? myRequestsRecievedList.errorLogName
                      : ''}
                    {' '}
                  </div>
                  <div>
                    {myRequestsRecievedList &&
                      myRequestsRecievedList.errorLogURL &&
                      <a
                        href={myRequestsRecievedList.errorLogURL}
                        target="_blank"
                      >
                        <Button className="seerdow">Download Log</Button>
                      </a>}
                  </div>
                </div>
              </div>
            </div>

            <div className="receivedRequestrightPannel">
              <div className="seStatus">
                Status:
              </div>
              <div>
                <div className="sewAwarded">
                  <div
                    className={
                      myRequestsRecievedList.status === 'draft'
                        ? 'greencircle'
                        : ''
                    }
                  />
                  <div
                    className={
                      myRequestsRecievedList.status === 'awarded'
                        ? 'yellowcircle'
                        : ''
                    }
                  />
                  <div
                    className={
                      myRequestsRecievedList.status === 'accepted'
                        ? 'yellowcircle'
                        : ''
                    }
                  />
                  <div
                    className={
                      myRequestsRecievedList.status === 'job completed'
                        ? 'orangecircle'
                        : ''
                    }
                  />
                  <div
                    className={
                      myRequestsRecievedList.status === 'rejected'
                        ? 'orangecircle'
                        : ''
                    }
                  />
                  <div
                    className={
                      myRequestsRecievedList.status === 'pending'
                        ? 'whitecircle'
                        : ''
                    }
                  />
                  <div
                    className={
                      myRequestsRecievedList.status === 'confirmed'
                        ? 'lightgreen'
                        : ''
                    }
                  />
                  <span>
                    {myRequestsRecievedList && myRequestsRecievedList.status
                      ? myRequestsRecievedList.status
                      : ''}
                  </span>
                </div>
                    {/*<div className="serload" />*/}
                <Progress value={myRequestsRecievedList && myRequestsRecievedList.progressBar ? 
                  myRequestsRecievedList.progressBar : ""} className="serload"/>
                <div className="sewAwarded">
                  <img alt="" src={greywatch} />
                  <span>

                    {myRequestsRecievedList &&
                      myRequestsRecievedList.serviceDate
                      ? moment (myRequestsRecievedList.serviceDate).format (
                          'DD/MM/YYYY'
                        )
                      : ''}

                  </span>
                </div>
              </div>

              {/*myRequestsRecievedList.status === 'awarded' &&
                <div className="serwait">
                  WAITING FOR PAYMENT
                      </div>*/}
              {myRequestsRecievedList.status === 'job started' &&
                <div className="serwait">
                  JOB COMPLETED
                </div>}
              {myRequestsRecievedList.status === 'rejected' &&
                <div className="serwait">
                  Rejected
                </div>}

              {myRequestsRecievedList.status === 'job completed' &&
                <div className="serSTART">
                  LEAVE FEEDBACK
                </div>}
              {myRequestsRecievedList.status === 'confirmed' &&
                <div className="serSTART">
                  START SERVICE
                </div>}
             
                {myRequestsRecievedList.status === 'awarded' && <div
                  style={{
                    display: 'flex',
                    marginTop: 9,
                    justifyContent: 'space-around',
                  }}
                >
                  <div>
                    <Button
                      className="serREJECT"
                      // onClick={this.openRejectServiceModal}
                      onClick={this.rejectRequest}
                    >
                      REJECT
                    </Button>
                  </div>
                  <div>
                    <Button className="serACCEPT" onClick={this.acceptRequest}>
                      ACCEPT
                    </Button>
                  </div>
                </div>}
              <div className="serLEAVE">
                LEAVE FEEDBACK
              </div>
              <div className="sertancx">
                <div style={{display: 'flex'}}>
                  <div style={{width: 200}}>
                    <div className="sertan">

                      {myRequestsRecievedList.requestorTitle
                        ? myRequestsRecievedList.requestorTitle
                        : ''}
                      {' '}

                      {myRequestsRecievedList &&
                        myRequestsRecievedList.requestorDisplayName
                        ? myRequestsRecievedList.requestorDisplayName
                        : (myRequestsRecievedList &&
                            myRequestsRecievedList.requestorFirstName
                            ? myRequestsRecievedList.requestorFirstName
                            : '') +
                            ' ' +
                            (myRequestsRecievedList &&
                              myRequestsRecievedList.requestorLastName
                              ? myRequestsRecievedList.requestorLastName
                              : '')}

                    </div>
                    {/*<div className="serAngeles">Los Angeles, California</div>*/}

                    <StarRatingComponent
                      name={'rating'}
                      starCount={5}
                      emptyStarColor={'#E4E4E4'}
                      starColor={'#F5D028'}
                    />
                  </div>
                  <div>
                    <img
                      alt=""
                      src={
                        myRequestsRecievedList &&
                          myRequestsRecievedList.requestorProfilePictureURL
                          ? myRequestsRecievedList.requestorProfilePictureURL
                          : profilePlaceholder
                      }
                      className="requesterProfile"
                    />
                  </div>
                </div>
                <div>
                  <Button className="serbutto">
                    {' '}SEND MESSAGE
                    <img alt="" src={sergroup} style={{paddingLeft: 5}} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Modal
            size="sm"
            isOpen={this.state.rejectServiceModal}
            toggle={this.closeRejectServiceModal}
            centered={true}
          >
            <ModalBody className="srrejecmodal">
              <div onClick={this.closefeedbackModal}>
                <img
                  alt=""
                  src={close_icon}
                  style={{width: 25, float: 'right', cursor: 'pointer'}}
                />
              </div>
              <div className="serRejectService">
                Reject Service Request
              </div>
              <div className="serRequestor">
                Do you wish to reject this service request
                from [Service Requestor Name]?
              </div>
              <div style={{display: 'flex'}}>
                <div>
                  <Button className="sernobuttom">NO</Button>
                </div>
                <div><Button className="seryesbutton">YES</Button></div>
              </div>
            </ModalBody>
          </Modal>
          <Modal
            size="sm"
            isOpen={this.state.rejectServiceModal}
            toggle={this.closeRejectServiceModal}
            centered={true}
          >
            <ModalBody className="srrejecmodal">
              <div onClick={this.closeRejectServiceModal}>
                <img
                  alt=""
                  src={close_icon}
                  style={{width: 25, float: 'right', cursor: 'pointer'}}
                />
              </div>
              <div className="serRejectService">
                Reject Service Request
              </div>
              <div className="serRequestor">
                Do you wish to reject this service request
                from [Service Requestor Name]?
              </div>
              <div style={{display: 'flex', float: 'right', marginTop: 30}}>
                <div>
                  <Button className="sernobuttom">
                    NO
                  </Button>
                </div>
                <div>
                  <Button
                    className="seryesbutton"
                    onClick={this.openRequestRejectModal}
                  >
                    YES
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size="sm"
            isOpen={this.state.requestRejectModal}
            toggle={this.closeRequestRejectModal}
            centered={true}
          >
            <ModalBody className="serrejectedserv">
              <div className="dersRejected">
                Service Request Rejected
              </div>
              <div className="serrejectedthis">
                {' '}
                You have rejected this service request
                from [Service Requestor Name].
              </div>
              <div style={{textAlign: 'center'}}>
                <Button className="sermodalbutton">
                  RETURN TO MY REQUESTS
                </Button>
              </div>
            </ModalBody>
          </Modal>

        </div>
        <ServiceOnDemandSidebarPage history={this.props.history} />
      </div>
    );
  }
}
const mapStateToProps = state => {

  return {
    service: state.serviceOnDemandReducer,
    serviceRequest: state.serviceRequestReducer
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      GetMyRequestsRecieved,
      acceptServiceRequest,
      rejectServiceRequest
      
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  ServiceOnDemandRecievedRequestDetailPage
);
