import { LOGOUT } from './Authreducer';

let initialState = {
    loading:null,
};

export const LOADING = 'LOADING';
export const CUSTOM_LOADING = 'CUSTOM_LOADING';


export default (state=initialState,action) => {
    switch (action.type) {
        case LOADING:
            return {...state, loading:action.status ,customLoader:null};

        case CUSTOM_LOADING:
            return {...state,loading:false, customLoader:action.status};
        case LOGOUT:
            return { ...initialState };
        default:
            return state;
    }
};


