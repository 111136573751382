
import {LOADING ,CUSTOM_LOADING} from './../reducers/Loadingreducer';



export const Loading = status => {
  return dispatch => {
    dispatch ({type: LOADING, status});
  };
};

export const CustomLoading = status => {
  return dispatch => {
    dispatch ({type: CUSTOM_LOADING, status});
     
  };
};