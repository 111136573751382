
import baseService from './Baseservice';
import vaultbaseService from './vaultBaseservice';



export const getVaultListService = (payload) => {
    return baseService.post('vaultSearchUserFiles', payload);
};
export const vaultGetSignedURLService = (payload) => {
    return baseService.post('vaultGetSignedURL', payload);
};
export const vaultStarredService = (payload) => {
    return baseService.post('vaultManageStarredFiles', payload);
};

// export const vaultCreateUserFolderService = (payload) => {
//     return baseService.post('vaultCreateUserFolder', payload);
// };

export const vaultCreateUserFolderService = (payload) => {
    return vaultbaseService.post('vaultCreateUserFolder', payload);
};





export const vaultManageFileSharesService = (payload) => {
    return baseService.post('vaultManageFileShares', payload);
};

export const vaultDeleteUserFolderService = (payload) => {
    return baseService.post('vaultDeleteUserFolder', payload);
};


// export const vaultDeleteFileService = (payload) => {
//     return vaultbaseService.delete(`rdee-vault/${payload.fileUUID}`);
// };
export const vaultDeleteFileService = (payload) => {
    return vaultbaseService.delete(`rdee-vault/`,payload);
};
export const vaultBulkDeleteFileService = (payload) => {
    return vaultbaseService.delete(`rdee-vault`,payload);
};

export const vaultBulkDownloadFileService = (payload) => {
    return baseService.post(`vaultGetFileDownloadLinks`,payload);
};

export const vaultManageShareFileExternallyService = (payload) => {
    return baseService.post(`vaultManageShareFileExternally`,payload);
};
export const vaultCreateExternalShareLinkService = (payload) => {
    return baseService.post(`vaultCreateExternalShareLink`,payload);
};



export const vaultGetFileDetailsService = (payload) => {
    return baseService.post('vaultGetFileDetails', payload);
};

export const vaultSearchFilesSharedWithUserService = (payload) => {
    return baseService.post('vaultSearchFilesSharedWithUser', payload);
};










