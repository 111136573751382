import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import './contactSupport.css';
import aboutlogo from '../../../images/accountSetting/aboutlogo.png';

import supportmail from '../../../images/accountSetting/supportmail.png';
import supportCall from '../../../images/accountSetting/supportCall.png';
import supportLocation
  from '../../../images/accountSetting/supportLocation.png';
import InfoSyscoming from '../../../images/comingsoon/InfoSyscoming.svg';
import  CommonAccessContainer from './../../../components/UI/commonAccessContainer/commonAccessContainer';

import {checkAvailableAccess} from '../../../constants/localMessage/errorMessage';

class ContactSupport extends Component {
  constructor (props) {
    super (props);
    this.state = {
      isAccess:true
    };
  }
  componentDidMount(){
    if(checkAvailableAccess ("contact_support") && checkAvailableAccess ("contact_support")=== 'contact_support'){
      this.setState({
        isAccess:true
      })
    }else{
      this.setState({
        isAccess:false
      })
    }
  }

  render () {
    const {isAccess}= this.state;
    return (
      <div className="contactUSContainer" >
        {isAccess && <div style={{display: 'flex'}}>
          <div>
            <div className="contactUsHeading">
              Questions, Comments or Concerns?

            </div>
            <div className="contactUsTitle"> Let us help you.</div>
            <div style={{display: 'inline-grid'}}>
              <div className="supportText">
                <div className="blackCircleBox">
                  <img alt="supportmail" src={supportmail} />
                </div>

                support@radiologex.com
              </div>
              <div className="supportText">
                <div className="blackCircleBox">
                  <img alt="supportmail" src={supportCall} />
                </div>
                323-903-6932
              </div>
              <div className="supportText">
                <div className="blackCircleBox">
                  <img alt="supportmail" src={supportLocation} />
                </div>
                <div style={{display: 'inline-grid', lineHeight: "22px"}}>
                  <div>19540 Jamboree Rd </div>

                  <div>Suite 310 </div>
                  <div>Irvine, CA 92612</div>
                </div>
              </div>
            </div>

          </div>
        </div>}
        {!isAccess  &&<div  className="underConstructionSettings">
         {checkAvailableAccess('contact_support')=== 'upgradeScreen'  && <span > Upgrade Your Plan</span>}
        {checkAvailableAccess('contact_support')=== 'comingSoonScreen'  && <span> Available Soon</span>}
        {checkAvailableAccess('contact_support')=== 'kycScreen'  && <span> Your KYC is incomplete </span>}
        {checkAvailableAccess('contact_support')=== 'maintenanceScreen'  && <span> Under Maintenance </span>}
        {checkAvailableAccess('contact_support')=== 'not_available'  && <span> Not Available  </span>}

      </div>}
    {/*!isAccess  && <CommonAccessContainer bgImage={InfoSyscoming} message={checkAvailableAccess('contact_support')} />*/}
          
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {};
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators ({}, dispatch);
};
export default connect (mapStateToProps, mapDispatchToProps) (ContactSupport);
