import React, { useState } from 'react';
import { Row, Col, Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './UserProfileHeaderDropDown.css';
import * as routes from '../../../constants/routes';
// import notificationIcon from '../../../images/notification.png';
import menuIcon from '../../../images/sidemenuicon.png';
import profilePlaceholder from '../../../images/profile_placeholder.png';

import requestIcon from '../../../images/icons/Group.svg';
import notificationIcon from '../../../images/icons/Noti_A.svg';

import defaultNotification from '../../../images/icons/defaultNotification.svg';
import defaultContactRequest from '../../../images/icons/defaultContactRequest.svg';

import defaultNotificationBlack from '../../../images/icons/defaultNotificationBlack.svg';
import defaultContactRequestBlack from '../../../images/icons/defaultContactRequestBlack.svg';

import requestIconBlack from '../../../images/icons/requestIconBlack.svg';
import notificationIconBlack from '../../../images/icons/notificationIconBlack.svg';
import { checkHeaderMenuColor } from '../../../utility/checkActivePage';

import UserStatus from '../../UI/UserStatus';

import logo from '../../logo.svg';

const sampleBruceWayneImg =
  'https://www.altfg.com/film/wp-content/uploads/images/2012/01/christian-bale-bruce-wayne.jpg';

export default function UserProfileHeaderDropDown({
  userImage = sampleBruceWayneImg,
  userName = 'User',
  userSettingRouteName = '/',
  userProfileRouteName = routes.PROFILE,
  signOutFunction = () => console.log('Sign out clicked'),
  dashboardprofile = [],
  isOpenSidebar = false,
  iconHide = false,
  openUserListPopover = false,
  userStatus = '',
  _hadleUpdateStatus,
}) {
  // console.log(iconHide);
  const [isOpened, setCollapse] = useState(false);

  //  const [blackText, setblackText] = useState(window.innerWidth < 1920 ?true:false);
  // const [blackText, setblackText] = useState(false);

  // const [blackText, setblackText] = useState(checkHeaderMenuColor());

  const toggle = () => setCollapse(!isOpened);

  const toggleMenu = (event) => {
    if (event.target.matches('.openmenu')) {
      var element = document.getElementById('sidebarmenu');
      if (element.matches('.menuDisplayHide')) {
        element.classList.remove('menuDisplayHide');
      } else {
        element.classList.add('menuDisplayHide');
      }
    }
  };

  let blackText = checkHeaderMenuColor();
  // var sidebarelement= document.getElementById('collapseRightPanel');
  // if(sidebarelement){
  //   sidebarelement.addEventListener("click", function() {
  //     if (sidebarelement.matches('.rightPanelContainerOverlay')) {
  //       checkHeaderMenuColor(true);

  //     }else{
  //       checkHeaderMenuColor(true);

  //       console.log("unmatch")

  //     }
  //   });
  // }
  // console.log(blackText);

  return (
    <div>
      {/*<Container className="dropdown-parent ">*/}
      {/* put the div instead of Container*/}

      <div className='dropdown-label checkdropMenu'>
        <div className='checkdropMenuFlex'>
          {!iconHide && (
            <Link
              to={routes.CONTACTREQUEST}
              className='iconsPaddingTop'
              data-rh='Contact request'
            >
              {blackText && !isOpenSidebar ? (
                <img
                  src={
                    dashboardprofile &&
                    dashboardprofile.pendingContactRequests &&
                    dashboardprofile.pendingContactRequests.length > 0
                      ? requestIconBlack
                      : defaultContactRequestBlack
                  }
                  className='notificationIconCss '
                  alt='Notification'
                />
              ) : (
                <img
                  src={
                    dashboardprofile &&
                    dashboardprofile.pendingContactRequests &&
                    dashboardprofile.pendingContactRequests.length > 0
                      ? requestIcon
                      : defaultContactRequest
                  }
                  className='notificationIconCss '
                  alt='Notification'
                />
              )}
            </Link>
          )}
          {!iconHide && (
            <Link
              to={routes.NOTIFICATION}
              className='iconsPaddingTop'
              data-rh='View notification'
            >
              {blackText && !isOpenSidebar ? (
                <img
                  src={
                    dashboardprofile &&
                    dashboardprofile.notifications &&
                    dashboardprofile.notifications.length > 0
                      ? notificationIconBlack
                      : defaultNotificationBlack
                  }
                  className='notificationIconCss '
                  alt='Notification'
                />
              ) : (
                <img
                  src={
                    dashboardprofile &&
                    dashboardprofile.notifications &&
                    dashboardprofile.notifications.length > 0
                      ? notificationIcon
                      : defaultNotification
                  }
                  className='notificationIconCss '
                  alt='Notification'
                />
              )}
            </Link>
          )}
          <div
            // onClick={toggle}
            className='checkdropMenuFlex '
          >
            <div style={{ textAlign: 'center' }}>
              <div className='profileboxImageContainer profileboxMargin'>
                <img
                  src={userImage}
                  className='profileboxMargin checkdropMenu '
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = profilePlaceholder;
                  }}
                />
              </div>
            </div>
            <div>
              <label
                style={{ display: 'inline-flex' }}
                className={'userNamePosition  userNamePositionTop '}
              >
                <div
                  className='userNameMenu checkdropMenu '
                  style={{
                    color:
                      blackText && !isOpenSidebar && !openUserListPopover
                        ? '#000000'
                        : '#FFFFFF',
                    width: '100%',
                  }}
                >
                  {userName}
                </div>

                <FontAwesomeIcon
                  onClick={() => toggle()}
                  className='checkdropMenu'
                  style={{
                    color:
                      blackText && !isOpenSidebar && !openUserListPopover
                        ? '#000000'
                        : '#FFFFFF',
                    fontSize: 18,
                    marginRight: 1,
                    marginLeft: 5,
                  }}
                  icon={faChevronDown}
                />
              </label>
              <div>
                <div className='downMenuContainer'>
                  <UserStatus
                    iconHide={true}
                    userStatus={userStatus}
                    _hadleUpdateStatus={_hadleUpdateStatus}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id='dropdownmenu'
        className={`dropdownnewwrapper ${isOpened ? '' : 'hide'}`}
      >
        <svg
          className='dropdownArrow'
          width='18'
          height='18'
          viewBox='0 0 18 18'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path opacity='0.8' d='M9 0L0 18H18L9 0Z' fill='#17171D' />
        </svg>

        <div
          className={`dropdownnew checkdropMenu`}
          isOpened={isOpened}
          onClick={toggle}
        >
          <div>
            <Link to={userProfileRouteName}>Profile</Link>
          </div>
          <div>
            <Link to={routes.ACCOUNT_SETTINGS}>Settings</Link>
          </div>
          <div onClick={signOutFunction}>Logout</div>
        </div>
      </div>
    </div>
  );
}

UserProfileHeaderDropDown.propTypes = {
  userImage: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  userSettingRouteName: PropTypes.string.isRequired,
  signOutFunction: PropTypes.func.isRequired,
};
