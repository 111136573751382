import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Button} from 'reactstrap';
import ImageCrop from '../ImageCrop';

import {
  IMAGE_TYPE_ERROR,
  GROUP_DETAIL_PHOTO_REQUIRED,
  GROUP_DETAIL_PHOTO_ERROR,
  GROUP_DETAIL_NAME_REQUIRED,
  GROUP_DETAIL_NAME_TEXT_LENGTH,
} from '../../../constants/errorMessages';
import {
  createNotification,
  NOTIFICATION_TYPE_WARNING,
  NOTIFICATION_TYPE_ERROR,
} from '../../../constants/notificationtoast';
import './CreateGroup.css';

// const image = require('../../../images/Group.png');
const image = require ('../../../images/icons/placeholderProfile.png');

const validate = image => {
  if (!image || (typeof image === 'string' && image.length === 0)) {
    throw new Error (GROUP_DETAIL_PHOTO_REQUIRED);
  }
  if (image && typeof image !== 'string') {
    // 1 byte size
    const megaByteSize = 1048576;
    // Get size of the blob
    const sizeInBytes = image.size;
    if (sizeInBytes > 5 * megaByteSize) {
      throw new Error (GROUP_DETAIL_PHOTO_ERROR);
    }
  }
  return {
    image,
  };
};

export default function CreateGroupImg({
  submitButtonText = 'Submit',
  onSubmitClick = data => console.log ('Submit clicked', data),
  imageSrc = '',
  // name = '',
  // placeholder = 'Group Name*'
}) {
  const [croppedImage, setCroppedImage] = useState (null);
  // const [nameVal, setName] = useState((name || ''));
  const [loadedImage, setLoadedImage] = useState (null);
  const inputOpenFileRef = useRef (null);
  const img = croppedImage
    ? URL.createObjectURL (croppedImage)
    : imageSrc || image;

  const onFileSelectButtonPress = event => {
    event.preventDefault ();
    inputOpenFileRef.current.click ();
  };
  const loadImage = e => {
    if (e.target.files && e.target.files.length > 0) {

      let files = e.target.files;
      let response = files[0].size / 1000000;
  
      if (response && response > 5) {
        createNotification (NOTIFICATION_TYPE_ERROR, GROUP_DETAIL_PHOTO_ERROR);
        return;
      }

      const file = e.target.files[0];
      const fileType = file['type'];
      const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
      // Check If file is not an image
      if (!validImageTypes.includes (fileType)) {
        createNotification (NOTIFICATION_TYPE_ERROR, IMAGE_TYPE_ERROR);
      } else {
        const reader = new FileReader ();
        reader.addEventListener ('load', () => setLoadedImage (reader.result));
        reader.readAsDataURL (e.target.files[0]);
        onSubmitClick (e.target.files[0]);
      }
    }
  };
  const submit = () => {
    try {
      const values = validate (croppedImage || imageSrc);
      onSubmitClick (values);
    } catch (error) {
      if (error.message) {
        createNotification (NOTIFICATION_TYPE_WARNING, error.message);
      }
    }
  };

  return (
    <div style={{paddingLeft: 20}}>

      <div className="groupIConButton" id="groupCreate1">

        <div>
          <img
            src={img}
            style={{width: 100, borderRadius: 100, border: '2px solid #FFFFFF'}}
            id="groupCreate2"
          />
        </div>
        <div style={{marginTop: 17}}  id="groupCreate3">
          <Button
            className="groupImageUploadcreating "
            href=""
            onClick={onFileSelectButtonPress}
            data-rh="Select image" 
            id="groupCreate4"
          >
            Upload
          </Button>
        </div>

      </div>
      <div  id="groupCreate5">
        <div className="grouppicSelection"  id="groupCreate6">
          Group Picture*
        </div>
        <div className="picturewar"  id="groupCreate7">
          Display Picture must be of type
          JPEG/PNG with a file size of
          maximum 5 MB
        </div>

      </div>

      {loadedImage &&
        <ImageCrop
          image={loadedImage}
          onSubmitClick={croppedImage => {
            // setCroppedImage (croppedImage);
            // setLoadedImage (null);


            try {
              const values = validate (croppedImage || imageSrc);
              // onSubmitClick (values);

              setCroppedImage (croppedImage);
              setLoadedImage (null);
          
              onSubmitClick (values.image);

            } catch (error) {
              if (error.message) {
                createNotification (NOTIFICATION_TYPE_WARNING, error.message);
              }
            }



          }}
        />}
      <input
        ref={inputOpenFileRef}
        type="file"
        style={{display: 'none'}}
        onChange={loadImage}
        id="groupCreate7"
      />
    </div>
  );
}

CreateGroupImg.propTypes = {
  imageSrc: PropTypes.string,
  onSubmitClick: PropTypes.func.isRequired,
};
