import sha256 from 'js-sha256';
// import CryptoJS from "react-native-crypto-js";
import CryptoJS from 'crypto-js'

// const p = 239423; // OLD ONE SHOULD NOT BE USED
// const g = 13;

const p = 912367; // NEW ONE 
const g = 26;

export const EncryptFunction = (email, password) => {
    let emailLength = email.length;
    let position = email.indexOf("@");
    let salt = `RDEE${email}${emailLength}${position}`
    let finalStringWithPassword = `${salt}${password}`
    return sha256(finalStringWithPassword)
}

// Message encryption //

export const KeyGen = (base, modulo, exponent) => {
    var result = 1;
    while (exponent > 0) {
        if (exponent % 2 == 1) {
            result = (result * base) % modulo;
        }
        base = (base * base) % modulo;
        exponent = exponent >>> 1;
    }
    return result;
}

export const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

// // this function will generate the user's Shared key
// export const userSharedKey = (userPublicKey) => {
//     return KeyGen(g, p, userPublicKey)
// }

// // it will generate the final secret key, which will be passed while encrtion or decryption
// // opposite shared key and user own public key will calculated the secret key
// export const CalculatedSharedSecret = (oppositeUserSharedKey, userOwnPublicKey) => {
//     return KeyGen(oppositeUserSharedKey, p, userOwnPublicKey);
// }

// this function will generate the user's Shared key
export const userSharedKey = (userOwnPrivateKey) => {
    return KeyGen(g, p, userOwnPrivateKey)
}

// it will generate the final secret key, which will be passed while encrtion or decryption
// opposite shared key and user own public key will calculated the secret key
export const CalculatedSharedSecret = (oppositeUserSharedKey, userOwnPrivateKey) => {
    // console.log("const g and p", g, p)
    return KeyGen(oppositeUserSharedKey, p, userOwnPrivateKey);
}

// it will ENCRYPT the messages 
export const encryptMessage = (message, secretKey) => {
    // console.log("message>>>>>>encryptMessage",message, "secretKey>>>>", secretKey )
    // Encrypt
    let ciphertext = CryptoJS.AES.encrypt(message, secretKey).toString();
    // console.log("ciphertext>>>>>", ciphertext, "message>>>>>", message, ">secretKey>>>", secretKey)
    return ciphertext
}

// it will DECRYPT the messages 
export const decryptMessage = (encryptedMessage, secretKey) => {
    let originalText = '';
    // console.log("decryptMessage, secretKey>>>>>", "encryptedMessage>>>>>", encryptedMessage, typeof encryptedMessage, "secretKey>>>>", secretKey, typeof secretKey)
    try {
        if (encryptedMessage && secretKey && (typeof encryptedMessage == 'string') && (typeof secretKey == 'string')) {
            let bytes = CryptoJS.AES.decrypt(encryptedMessage, secretKey);
            // console.log("decrpyt 111111")
            originalText = bytes.toString(CryptoJS.enc.Utf8);
            // console.log("decrpyt 4444444",originalText )
            return originalText ? originalText : '';

        } else {
            return "";
        }
    } catch (error) {
        console.log("error while decrypting first catch", error)
        try {
            // console.log("decrpyt 2222222")
            if (encryptedMessage && secretKey && (typeof encryptedMessage == 'string') && (typeof secretKey == 'string')) {
                let bytes = CryptoJS.AES.decrypt(encryptedMessage, "1");
                originalText = bytes.toString(CryptoJS.enc.Utf8);
                return originalText ? originalText : '';

            } else {
                return "";
            }
        } catch (err) {
            console.log("error while decrypting Second catch", err)
            return ''
        }
    }
}