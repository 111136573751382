import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

import './MyModal.css';

export default function MyModal({
    show = false,
    showSubmitCancel = false,
    onSubmit = () => console.log('On Submit called'),
    toggle = () => console.log('Toggle Clicked'),
    submitText = 'Next',
    title = '',
    children,
    className,
}) {
    let modalClassName = "my-modal";
    if (className) {
        modalClassName = `${className} ${modalClassName}`
    }
    return (
        <Modal isOpen={show} toggle={toggle} className={modalClassName}>
            <ModalBody>
                {title &&<div className='my-title'>{title}</div>}
                {children}
            </ModalBody>
            {
                showSubmitCancel && <ModalFooter>
                    <Button
                        className="cancel-button footer-buttons"
                        onClick={toggle}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="submit-button footer-buttons"
                        onClick={onSubmit}
                    >
                        {submitText}
                    </Button>
                </ModalFooter>
            }
        </Modal>
    )
}