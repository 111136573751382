import baseService from './Baseservice';

// export const loginService = (loginData) => {
//     return baseService.post('/userLogin', loginData);
// };
export const loginService = (loginData) => {
    return baseService.post('/userLoginAuthOnly', loginData);
};

export const checkTrusonaEnabledService = (request) => {
    return baseService.post('/checkTrusonaEnabled', request);
};

// CONTACTS/////
export const getAllUserContacts = (contactData = {data:{}}) => {
    return baseService.post('/getAllUserContacts', contactData);
};

export const addUserContact = (loginData) => {
    return baseService.post('/addUserContact', loginData);
};

export const addUserRequest= (loginData) => {
    return baseService.post('/processAddContactRequest', loginData);
};


export const deleteUserContact = (loginData) => {
    return baseService.post('/deleteUserContact', loginData);
};

////

// Notifications//

export const getUserNotificationHistory = (loginData) => {
    // return baseService.post('/getUserNotificationHistory', loginData);
    return baseService.post('/getUserNotificationHistory_v2', loginData);

};

export const messengerGetMutedNotificationsList = (data) => {
    // return baseService.post('/getUserNotificationHistory', data);
    return baseService.post('/messengerGetMutedNotificationsList', data);

};

export const messengerMuteNotifications = (data) => {
    // return baseService.post('/getUserNotificationHistory', data);
    return baseService.post('/messengerMuteNotifications', data);

};

export const messengerUnMuteNotifications = (data) => {
    // return baseService.post('/getUserNotificationHistory', data);
    return baseService.post('/messengerUnMuteNotifications', data);

};

export const deleteUserNotificationHistory = (loginData) => {
    return baseService.post('/deleteUserNotificationHistory', loginData);
};

export const addUserNotificationHistory = (loginData) => {
    return baseService.post('/addUserNotificationHistory', loginData);
}; 

export const setUserNotificationHistoryStatus = (loginData) => {
    return baseService.post('/setUserNotificationHistoryStatus', loginData);
};

//////

export const registerService = (registerData) => {
    return baseService.post('/modifyBusinessDetails', registerData);
};
export const uploadBusinessProfilePictureService = (registerData) => {
    return baseService.post('/uploadBusinessProfilePicture', registerData);
};



export const modifyPersonalInfoService = (registerData) => {
    return baseService.post('/modifyPersonalInfo', registerData);
};
export const modifyEducationDetailsService = (request) => {
    return baseService.post('/modifyEducationDetails', request);
};

export const getEducationDetailsService = (request) => {
    return baseService.post('/getEducationDetails', request);
};
export const deleteEducationDetailsService = (request) => {
    return baseService.post('/deleteEducationDetails', request);
};


export const modifyProfessionalInfoService = (request) => {
    return baseService.post('/modifyProfessionalInfo', request);
};

export const getProfessionalDetailsService = (request) => {
    return baseService.post('/getProfessionalInfo', request);
};
export const deleteProfessionalDetailsService = (request) => {
    return baseService.post('/deleteProfessionalInfo', request);
};


export const getFindBusinessService = (request) => {
    // return baseService.post('/searchBusinessNameOrID', request);
    return baseService.post('/searchBusinessNameOrID_v2', request);
};
export const addModifyBusinessDetailsService = (request) => {
    return baseService.post('/modifyBusinessDetails', request);
};
export const sendBusinessInviteService = (request) => {
    return baseService.post('/businessAccountsAddPendingInvite', request);
};

export const registerDeviceTokenService = (request) => {
    return baseService.post('/registerDeviceToken', request);
};

export const sendUserNotification = (request) => {
    return baseService.post('/sendUserNotification', request);
};

export const getUserDashboard = (request) => {
    return baseService.post('/getUserDashboard', request);
};

export const completeUserKYC = (request) => {
    return baseService.post('/completeUserKYC', request);
};

export const onfidoResumeChecks = (request) => {
    return baseService.post('/onfidoResumeChecks', request);
};

export const onfidoGetSDKTokenForApplicant = (request) => {
    return baseService.post('/onfidoGetSDKTokenForApplicant', request);
};




export const passwordResetGenerateOTP = (request) => {
    return baseService.post('/passwordResetGenerateOTP', request);
};

export const passwordResetVerifyOTP = (request) => {
    return baseService.post('/passwordResetVerifyOTP', request);
};

export const removeUserFromBusinessService = (request) => {
    return baseService.post('/removeUserFromBusiness', request);
};

export const passwordResetChangePassword = (request) => {
    return baseService.post('/passwordResetChangePassword', request);
};

export const resendEmailVerify = Data => {
    return baseService.post ('/resendEmailVerify', Data);
};
export const changeUserPasswordService = Data => {
    return baseService.post ('/changeUserPassword', Data);
};
export const userSettingsModifySearchSettingsService = Data => {
    return baseService.post ('/userSettingsModifySearchSettings', Data);
};
export const userSettingsModifyNotificationSettingsService = Data => {
    return baseService.post ('/userSettingsModifyNotificationSettings', Data);
};

export const cancelSentPendingContactRequestService = Data => {
    return baseService.post ('/cancelSentPendingContactRequest', Data);
};

export const sendPhoneNumberVerificationService = Data => {
    return baseService.post ('/sendPhoneNumberVerification', Data);
};

export const verifyPhoneNumberOTPService = Data => {
    return baseService.post ('/verifyPhoneNumberOTP', Data);
};

export const rdeeEmailVerificationService = Data => {
    return baseService.post ('/rdeeEmailVerification', Data);
};

export const userSettingsToggleTrusonaAuthService = Data => {
    return baseService.post ('/userSettingsToggleTrusonaAuth', Data);
};


export const setSeenWelcomeScreenService = Data => {
    return baseService.post ('/setSeenWelcomeScreen', Data);
};

export const businessAccountsGetAccountsService = Data => {
    return baseService.post ('/businessAccountsGetAccounts', Data);
};
export const businessAccountsSetActiveAccountService = Data => {
    return baseService.post ('/businessAccountsSetActiveAccount', Data);
};
export const businessAccountsGetGroupsService = Data => {
    return baseService.post ('/businessAccountsGetGroups', Data);
};
export const UpdatebusinessAccountsManageGroupsService = Data => {
    return baseService.post ('/businessAccountsManageGroups', Data);
};
export const businessAccountsGetPendingInvitesService = Data => {
    return baseService.post ('/businessAccountsGetPendingInvites', Data);
};

export const businessAccountsAcceptPendingInviteService = Data => {
    return baseService.post ('/businessAccountsAcceptPendingInvite', Data);
};
export const businessAccountsRejectPendingInviteService = Data => {
    return baseService.post ('/businessAccountsRejectPendingInvite', Data);
};








