import React, {Component} from 'react';
import {Button, Input, Progress} from 'reactstrap';
// import * as routes from '../../constants/routes';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import '././authSettings.css';
import greendot from '../../../images/serviceOnDemandMyRequest/greendot.png';
import fingerset from '../../../images/fingerset.png';
import union from '../../../images/profile/union.png';
import lockset2 from '../../../images/lockset2.png';
import lockset from '../../../images/lockset.png';
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class AuthSettings extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      authScreen: true,
      changePass: false,
      passSaved: false,
    };
  }
  openChangePass = () => {
    this.setState ({authScreen: false});
    this.setState ({changePass: true});
  };
  openPassSaved = () => {
    this.setState ({changePass: false, passSaved: true});
  };
  render () {
    return (
      <div style={{padding: 25, width: 470}}>
        {this.state.authScreen === true &&
          <div>
            <div className="setuthentic">
              Authentication Settings
            </div>
            <div className="setingauthvec">
              <div>
                <img alt="" src={lockset} style={{marginLeft: 30}} />
              </div>
              <div onClick={this.openChangePass}>
                <div className="changesetjkh">
                  Change Password
                </div>
                <div className="srtgtghange">
                  Change your Account Password
                </div>
              </div>
            </div>
            <div className="setingauthvec2">
              <div>
                <img alt="" src={fingerset} style={{marginLeft: 30}} />
              </div>
              <div>
                <div className="changesetjkh">
                  Password-less Authentication
                </div>
                <div
                  style={{
                    display: 'flex',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 28,
                  }}
                >
                  <img alt="" src={greendot} />
                  <span className="srtgtngese"> Enabled </span>
                </div>
              </div>
            </div>
          </div>}
        {this.state.changePass === true &&
          <div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <img
                alt=""
                src={union}
                style={{width: 25, height: 20, cursor: 'pointer'}}
                onClick={() => {
                  this.setState ({changePass: false, authScreen: true});
                }}
              />
              <div className="authchangep"> Change Password </div>
            </div>
            <div className="setenternew">Enter a new Password</div>
            <div>
              <input className="setininetrne" placeholder="Password*" />
            </div>
            <div>
              <input className="setininetrne2" placeholder="Password*" />
            </div>
            <div style={{textAlign: 'center'}}>
              <button className="setedbubbut2" onClick={this.openPassSaved}>
                Confirm
              </button>
            </div>
            <div className="mesaagesetin">
              Your password is not secure. A password <br />
              must be a contain at least one lower-case letter,<br />
              upper-case letter, a number and a special charecter.
            </div>
          </div>}
        {this.state.passSaved === true &&
          <div>
          <div style={{display: 'flex',alignItems: "center"}}>
          <img alt="" src={union} style={{width: 25,height:20,cursor:"pointer"}} 
          onClick={()=>{this.setState({changePass:true,passSaved:false})}}/>
          <div className="authchangep"> Change Password </div>
        </div>
        <div style={{textAlign:"center"}}>
        <img alt="" src={lockset2} style={{width:78,height:104,marginTop: 100}}/>
        </div>
        <div className="nseewspassa">
        New Password Saved!
        </div>
        <div className="setinjhftext2">
        You new password is updated<br /> 
        successfully.
        </div>
        <div style={{textAlign: 'center'}}>
        <button className="setedbubbut2" 
        onClick={()=> 
         this.setState ({changePass: false, 
         passSaved: false,authScreen:true})}>
        Return to Settings
        </button>
      </div>
          </div>}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    // service: state.serviceOnDemandReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators ({}, dispatch);
};

export default connect (mapStateToProps, mapDispatchToProps) (AuthSettings);
