import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import { buildImage } from '../../Contacts/Contact'
import './Member.css';

export default function Member({
    name='Contact Name',
    imageSrc,
    onRemoveClick = id => console.log(`${id} is clicked to be removed`),
    addMemberToGroup=id => console.log(`${id} is clicked to be removed`),
    position='',
    firmName=''
    
}) {
    return (<div className="member-container" onClick={addMemberToGroup}>
        <div className="member-image-container">
            {(imageSrc) ? <img src={imageSrc} className="contact-image"/> : buildImage(name)}
        </div>
        <div className="member-name-container">
        
        <div>{name}</div>
        <div className="position">{position}</div>
        <div className="position">{firmName}</div>

        </div>
      



    
        <div className="member-del-container" >
            <FontAwesomeIcon icon={faTrashAlt} onClick={onRemoveClick}/>
        </div>
    </div>);
};

Member.propTypes = {
    name: PropTypes.string,
    imageSrc: PropTypes.string,
    onRemoveClick: PropTypes.func.isRequired,
}