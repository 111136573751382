
import {getMessage} from '../constants/localMessage/errorMessage';
import { contentManagementGetAllHighlightsService } from '../services/highlightService';
import {createNotification} from '../constants/notificationtoast';
import {GET_HIGHLIGHT_LIST} from '../reducers/HightLightReducer';


import {Loading} from './LoadingAction';
export const getHighLightListing = request => {
  return dispatch => {
    dispatch (Loading (true));
    contentManagementGetAllHighlightsService (request)
      .then (res => {
       
        if (res && res.data &&  res.data.result && res.data.result.status === true) {
        
          dispatch ({type: GET_HIGHLIGHT_LIST, payload: res.data.result.Data});
         

         
          dispatch (Loading (false));
        } else {
          dispatch ({type: GET_HIGHLIGHT_LIST, payload: []});
          if (res && res.data &&  res.data.result && res.data.result){
            createNotification ('error',getMessage(res.data.result.responseCode));


          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));
        console.log (err);
        throw err;
      });
    
  };
};














