import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { getLabels, selectLabel } from './../actions/sidebarActions'
import { mountScripts } from './../api/scripts'
import { signOut, signIn, checkSignInStatus } from './../api/authentication'
import {
  getLabelMessages,
  emptyLabelMessages
} from './../actions/message-listActions'
import MessageList from './MessageList'
import { Loading } from '../../../actions/LoadingAction'
import * as routes from '../../../constants/routes'

import { Button } from 'reactstrap'

import Sidebar from './sidebar/sidebar'

class EmailDashboardPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      messagesResult: '',
      signInStatus: '',
      activeURL: ''
    }
    this.init = this.init.bind(this)
    this.initClient = this.initClient.bind(this)
    this.onSignInSuccess = this.onSignInSuccess.bind(this)
    this.getLabelMessages = this.getLabelMessages.bind(this)
    this.loadLabelMessages = this.loadLabelMessages.bind(this)
    this.navigateToNextPage = this.navigateToNextPage.bind(this)
    this.navigateToPrevPage = this.navigateToPrevPage.bind(this)
    this.onSignout = this.onSignout.bind(this)
  }

  // componentDidMount() {
  //   this.props.getLabels();

  // }
  componentDidMount () {
    mountScripts().then(this.init)
    this.props.Loading(false);
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.location.search !== this.props.location.search) {
     
      this.loadMessagesByURL();
    }
  }
  loadMessagesByURL(){
    const searchParam = this.props.location.search

      
    //   // console.log(prevProps);
    //   console.log(this.props);
    //   // alert("edewdew " + (this.props.match.params.item));
    // // this.props.getLabelMessages({
    // //   ...(labelIds && { labelIds }),
    // //   pageToken: token
    // // })
    if(this.props.match && this.props.match.params && this.props.match.params.item){
      
      // const labelIds = (this.props.searchQuery === '' ? [this.props.match.params.item] : undefined);
      const labelIds = [this.props.match.params.item.toUpperCase()];
      const token = searchParam.indexOf('?') === 0 ? searchParam.slice(1) : null;
      this.props.getLabelMessages({
            ...(labelIds && { labelIds }),
            pageToken: token
          })
    }
    this.showActiveLink();

  }
  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if (nextProps.messageItems) {
        this.setState({
          messagesResult: nextProps.messageItems.messages
        })
      }
    }
  }

  init () {
    window.gapi.load('client:auth2', this.initClient)
  }
  initClient () {
    checkSignInStatus()
      .then(this.onSignInSuccess)
      .catch(_ => {
        console.log('auth failed')
        this.setState({
          signInStatus: false
        })
      })
  }
  onSignInSuccess (googleUser) {
    // console.log(googleUser);
    if (googleUser) {
      this.props.getLabels();
      this.getLabelMessages = this.getLabelMessages.bind(this)

      if (this.props.location.search) {
        // const searchParam = this.props.location.search
        // const token =
        //   searchParam.indexOf('?') === 0 ? searchParam.slice(1) : null

        // const labelIds =
        //   this.props.searchQuery === ''
        //     ? [this.props.parentLabel.id]
        //     : undefined
        // this.props.getLabelMessages({
        //   ...(labelIds && { labelIds }),
        //   pageToken: token
        // })
        this.loadMessagesByURL();
      } else {
        if (this.props.match.params && this.props.match.params.item) {
          // console.log(this.props.match.params.item);
          // console.log(this.props.sideMenu.labelslist)
          if (
            this.props.sideMenu &&
            this.props.sideMenu.labelslist &&
            this.props.sideMenu.labelslist.length > 0
          ) {
            for (let item of this.props.sideMenu.labelslist) {
              if (
                this.props.match.params.item.toLowerCase() ===
                item.id.toLowerCase()
              ) {
                this.showActiveLink();
                this.getLabelMessages({ labelIds: [item.id] })
              }
            }
          }
        }
      }

      this.setState({
        signInStatus: googleUser
        // googleUser
      })
    }
  }
  showActiveLink(){
    if (
      this.props.sideMenu &&
      this.props.sideMenu.labelslist &&
      this.props.sideMenu.labelslist.length > 0
    ) {
      for (let item of this.props.sideMenu.labelslist) {
        if (
          this.props.match.params.item.toLowerCase() ===
          item.id.toLowerCase()
        ) {
          this.setState({
            activeURL: item.id
          })
        }
      }
    }
  }

  onSignIn () {
    signIn().then(this.onSignInSuccess)
  }
  onSignout () {
    const that = this
    signOut().then(_ => {
      that.props.history.replace('inbox')
      window.location.reload(true)
    })
  }

  loadLabelMessages (label) {
    // console.log(label);
    //   const currentSearchQuery = this.props.searchQuery;
    //   this.props.clearPageTokens();
    //   this.props.selectLabel(label.id);

    // const newPathToPush = `/${label.id.toLowerCase()}`;

    // if (currentSearchQuery && currentSearchQuery !== "") {
    // this.props.setSearchQuery("");
    // const {pathname} = this.props.location;
    // if (newPathToPush === pathname) {
    //   this.getLabelMessages({labelIds: [label.id] });
    //   return;
    // }
    // }

    //   this.props.history.push(`/${label.id.toLowerCase()}`);
    // console.log(this.props);
    // console.log(label);
    if (label && label.id) {
      this.getLabelMessages({ labelIds: [label.id] })
      this.setState({
        activeURL: label.id
      })
    }

    this.props.history.push(
      `${routes.CIENT_EMAIL_DASHBOARD}/${label.id.toLowerCase()}`
    )
  }

  getLabelMessages ({ labelIds, q, pageToken }) {
    this.props.emptyLabelMessages()
    this.props.getLabelMessages({ labelIds, q, pageToken })
  }

  navigateToNextPage (token) {
    const searchParam = this.props.location.search
    const currentToken =
      searchParam.indexOf('?') === 0 ? searchParam.slice(1) : ''
    // console.log(currentToken);
    // console.log(token);
    // this.props.setPageTokens({
    //   prevPageToken: currentToken
    // });
    this.props.history.push(token)
  }

  navigateToPrevPage (token) {
    this.props.history.goBack()
  }

  render () {
    // console.log(this.props.messageItems);
    // console.log(this.props.messageItems.messages);
    // console.log(this.props.match.path.slice(1));

    // console.log(this.props.messageItems.messages.find(el => el.id ===this.props.match.path.slice(1) ));
    const { signInStatus, activeURL } = this.state;
    return (
      <div className='emailClientMainContainer'>
        <div>
          {signInStatus ? (
            <div className='row'>
              <div className='col col-3'>
                <Sidebar
                  activeURL={activeURL}
                  history={this.props.history}
                  loadLabelMessages={this.loadLabelMessages}
                />
              </div>

              <div className='col col-9 mailContainerScrolller'>
                {this.state.messagesResult && (
                  <MessageList
                    {...this.props}
                    // getLabelMessages={this.getLabelMessages}
                    messagesResult={this.state.messagesResult}
                    // toggleSelected={this.props.toggleSelected}
                    navigateToNextPage={this.navigateToNextPage}
                    navigateToPrevPage={this.navigateToPrevPage}
                    // pageTokens={this.props.pageTokens}
                    // addInitialPageToken={this.addInitialPageToken}
                    // parentLabel={that.props.labelsResult.labels.find(el => el.id === props.match.path.slice(1) )}
                    // searchQuery={this.props.searchQuery}
                    onSignout={this.onSignout}
                  />
                )}
              </div>
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <div className='headingTitle'>Client Email</div>
              <Button color='primary' onClick={() => this.onSignIn()}>
                Sign-in with google
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    messageItems: state.MessageReducer,
    sideMenu: state.SidebarReducer
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getLabels,
      getLabelMessages,
      emptyLabelMessages,
      // toggleSelected,
      selectLabel,
      // setPageTokens,
      // addInitialPageToken,
      // clearPageTokens,
      // setSearchQuery,
      Loading
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailDashboardPage)
