import React, {Component, useEffect, useState} from 'react';
import moment from 'moment';
import shave from 'shave';
import PropTypes from 'prop-types';

import './ConversationListItem.css';
import _ from 'lodash';
import firebase from 'firebase';
import { onlineStatus } from "../../../utility/onlineStatusHandle" ;
import placeholderProfile from  '../../../images/profile_placeholder.png';
import videoIcon from  '../../../images/icons/messenger/videoIcon.png';
import ImageIcon from   '../../../images/icons/messenger/photoIcon.png';
import fileIcon from   '../../../images/icons/messenger/filesIcon.png';
import muteIcon from   '../../../images/icons/muteIcon.svg';
import { lastMessageTobeShown } from '../../../utility/Chat/utility';




import { capitalfirstLetter, ShowNameAccordingToLength } from '../../../utility/stringFunctions';

export default function ConversationListItem({
  chatType,
  chatThread,
  photo,
  name,
  text,
  onClick,
  read,
  lastTime,
  type,
  userId,
  selectedConversationUserID,
  oppositeUserUid,
  isMute
}) {
  // console.log("name>>>>>>>>>>>", name)
  // var messageInfo = {chatType,
  //   chatThread,
  //   photo,
  //   name,
  //   text,
  //   onClick,
  //   read,
  //   lastTime,
  //   type,
  //   userId,
  //   selectedConversationUserID,
  //   oppositeUserUid,
  //   isMute}
  // let loginUser = JSON.parse(localStorage.getItem('loginuser'));
  // let userOwnUID = _.get(loginUser,'docID', '')

  const [userStatus, setUserStatus] = useState(null);
  const [userloggedIn, setUserloggedIn] = useState(false);
  const [userIsUserOnline, setIsUserOnline] = useState(false);
  const [statusColor, setStatusColor] = useState('');
  const [userName , setUserName] =  useState(name ? name : '')
  const [userProfilePic , setUserProfilePic] =  useState(photo ? photo : '')

    // use effect in place of component did mount
  useEffect( () => {
    if((chatType == 'singleChat') && oppositeUserUid){
      let database = firebase.database()
      var unsubscribeUserDetails = database.ref(`Users/${oppositeUserUid}/onlineStatus`).on('value', snapshot => {
        let onlineDataGet = onlineStatus(snapshot.val());
        setUserStatus(_.get(onlineDataGet, 'statusMessage', ''))
        setUserloggedIn(_.get(onlineDataGet, 'loggedIn', false))
        setIsUserOnline(_.get(onlineDataGet, 'isUserOnline', false))
        setStatusColor(_.get(onlineDataGet, 'statusIcon', ''))
      })
    } 
    return ()=>{
      if((chatType == 'singleChat') && oppositeUserUid){
        firebase.database().ref(`Users/${oppositeUserUid}/onlineStatus`).off('value', unsubscribeUserDetails)
      }
    }
  },[]);

  const mymoment = moment (lastTime);
  const dayDifference = moment ().diff (mymoment, 'd');
  const timeDisplay = dayDifference > 1
    ? mymoment.format ('DD/MM/YYYY')
    : mymoment.fromNow ();
    // console.log("userStatus>>>>", userStatus,"userloggedIn>>>",userloggedIn, "userIsUserOnline>>>>>", userIsUserOnline )
  return (
    <div id={`conversation-list-item${userId}`} className={"conversation-list-item "+ (userId===selectedConversationUserID ? " conversation-list-itemActive ":"")} onClick={onClick}>
      <div className="conversation-subView" id={`conversation-subView${userId}`} >
       
      <div style={{width:70,display:'inline-flex'}}>
        <div className="conversation-photo-item">
    <img
           style={{ width: 50, height: 50, borderRadius: 85 }}
          src={
           ( isMute? muteIcon: (userProfilePic !== '' && userProfilePic !== undefined && userProfilePic !== null
              ? userProfilePic
              : placeholderProfile))
          }
          onError={e => {
            e.target.onerror = null;
            e.target.src = placeholderProfile;
          }}
          alt="conversation"
          id={`conversation-userImage${userId}`}
        />
        </div>
        <div className="userStatusCircle">
          <div className="userChatActiveStatus " style={{background:statusColor}} />
        </div>
       
        </div>
       
        <div className="conversation-info" id={`conversation-conversation-info${userId}`}>
          <div className="conversation-title-date" id={`conversation-conversation-title-date${userId}`}>
          <div className="date-item" id={`conversation-date-item${userId}`}>{timeDisplay}</div>
        {!read && <div className="conversation-dot" />}
          </div>
          <div className="conversation-title" id={`conversation-conversation-title${userId}`}>
              {chatType === 'singleChat' ? capitalfirstLetter(ShowNameAccordingToLength(userName)) :userName}
          </div>
          {/* <div style={{}}className="date-item">{userloggedIn && userIsUserOnline &&  (userStatus=="Active") ? 'now' : timeDisplay}</div> */}
          
          <div className={'userActive-messenger-list'} id={`userActive-messenger-list-conversation${userId}`}>
            <p className="conversation-snippet" style={{textTransform: type==='attach' ? 'capitalize' :''}}>
              {type && type==='attach' && text &&   (text === 'video' || text === 'videoCall')&& 
              <img style={{width:18}} className="chatListFileICons" src={videoIcon}/> }
              {type && type==='attach' && text && text === 'image' && 
              <img className="chatListFileICons" src={ImageIcon}/> }
              {type && type==='attach' && text && (text !== 'image' && text !== 'video' && text !== 'videoCall') && 
              <img className="chatListFileICons" src={fileIcon}/> }
              {text}
              {/* {lastMessageTobeShown(messageInfo, userOwnUID)} */}
              {/*type && type === 'text' ? text : text*/}

             
            </p>
            {/* {userloggedIn && userIsUserOnline &&  (userStatus=="Active") 
            && <div className="userActiveStatusCircle " style={{background:'#4AEA87'}} />
            } */}
          </div>
        </div>
      </div>
    </div>
  );
}

ConversationListItem.propTypes = {
  chatType: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  lastTime: PropTypes.number,
};
