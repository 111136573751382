import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ComposeMessage from '../compose-message/ComposeMessage'

import './sidebar.css'
class Sidebar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      menuItems: []
    }
    this.openLabel = this.openLabel.bind(this)
  }

 
  componentDidMount () {}
  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if (nextProps.sideMenu) {
        if (
          nextProps.sideMenu.labelslist &&
          nextProps.sideMenu.labelslist.length > 0
        ) {
          // console.log(nextProps.sideMenu.labelslist);
          let menuItem=[];

          for(let item of nextProps.sideMenu.labelslist){
            if(item.id==="INBOX" || item.id==="SENT" || item.id==="DRAFT" ||item.id==="SPAM" ||item.id==="TRASH"){
              menuItem.push(item);
            }
           

          }
          this.setState({
            menuItems: menuItem
          })
          // this.setState({
          //   menuItems: nextProps.sideMenu.labelslist
          // })
        }
      }
    }
  }
  openLabel (item) {
    this.props.loadLabelMessages(item)
  }

  sortByname=()=>{
    return function (a, b) {
      var nameA = a.id.toLowerCase (),
        nameB = b.id.toLowerCase ();
      if (
        nameA < nameB //sort string ascending
      )
        return -1;
      if (nameA > nameB) return 1;
      return 0;
    };
  }
  render () {
    const { menuItems } = this.state
    return (
      <div style={{paddingTop:15}}>
         <ComposeMessage
              subject=""
              to=""
            >
              <button className="btn btn-dark align-self-center w-75 font-weight-bold">
                Compose
              </button>
            </ComposeMessage>
        <div>
          {menuItems &&
            menuItems.length > 0 &&
            menuItems.map((item, i) => (
              <div
                key={i}
                className={
                  'sideMenuItem ' +
                  (this.props.activeURL === item.id ? 'activeMenuBar' : '')
                }
                onClick={() => {
                  this.openLabel(item)
                }}
              >
                {item.name}{' '}
                <span>
                  {item.messagesUnread ? `(${item.messagesUnread})` : ''}
                </span>
              </div>
            ))}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    sideMenu: state.SidebarReducer
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      //   getLabels,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar)
