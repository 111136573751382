import React from 'react';
import PropTypes from 'prop-types';
import './Contact.css';

export const buildImage = (userName) => <div className="text-image">{ userName && userName.length>0 ? userName[0].toUpperCase():''}</div>;

export default function Contact({
    imageSrc,
    name,
    profile,
    instituteName,
    onClick = () => console.log('Clicked contact'),
    userId = 'User123',
    showEditOnContact= false,
    onEditClick = (id) => console.log('Edit clicked for ', id), 
    isAddIcon
 
}) {
    return (
        <div className="contact" onClick={() => onClick(userId)}>
            <div className="contact-image-container">
                {(imageSrc) ? <img  alt="" src={imageSrc} className="contact-image"/> : buildImage(name)}
            </div>
            <div className="contact-info">
                <div className="contact-name">{name}</div>
                <div className="contact-profile">{profile}</div>
                <div>{instituteName}</div>
            </div>
         {isAddIcon && <div className="addContactIcons">
            <img alt=""
                src={require('../../../../images/addContact.png') }
                onClick = {() => onEditClick(userId)}
                
            />
            </div>}
            {showEditOnContact && <img alt=""
                src={require('../../../../images/EditVector.png') }
                onClick = {() => onEditClick(userId)}
                className="edit-image"
            />}
        </div>
    );
};

Contact.propTypes = {
    imageSrc: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    profile: PropTypes.string.isRequired,
    instituteName: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    userId: PropTypes.string.isRequired,
}