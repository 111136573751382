import React, {Component} from 'react';
import {Row, Col, Button, Modal, ModalBody} from 'reactstrap';
import moment from 'moment';


import profilePlaceholder from '../../images/profile_placeholder.png';
import leftbackarrow from '../../images/icons/leftbackarrow.png';

import groupRequestIcon from '../../images/icons/groupRequestIcon.png';
import blueUsericon from '../../images/icons/blueUsericon.svg';


import {acceptContactFunc} from '../../actions/contacts';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import './contactRequest.css';

import {
  getUserNotifications,
  getAllsendRequestusers,
  getAllreceiveRequestusers,
  cancelSendUserContactRequest
} from '../../actions/contacts';
import UserContactListPage
  from '../../components/contacts/usercontacts/userList';
import * as routes from '../../constants/routes';
import  RightSideContainer from '../../components/UI/rightSideContainer/rightSideContainer';
import { ShowNameAccordingToLength } from '../../utility/stringFunctions';

class contactRequest extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      isReceive: true,
      sendRequestsList: [],
      receiveRequestsList: [],
    };
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if (nextProps.contacts) {
     
        if (nextProps.contacts.sendRequests) {
          this.setState ({
            sendRequestsList: nextProps.contacts.sendRequests,
          });
        }
        if (nextProps.contacts.receiveRequests) {
          this.setState ({
            receiveRequestsList: nextProps.contacts.receiveRequests,
          });
        }
      }
    }
  }
  
  componentDidMount () {
    let sendRequest = {
      data: {},
    };
    this.props.getAllsendRequestusers (sendRequest);
    this.props.getAllreceiveRequestusers (sendRequest);
  }
  changeTab (status) {
    this.setState ({
      isReceive: !this.state.isReceive,
    });
  }
  goToprofile (item) {
    if (item && item.docID) {
      this.props.history.push (routes.CONTACTUSERDETAIL + `/${item.docID}`);
    }
  }

  acceptRequest(item, status){
    let sendRequest = {
      data: {
        messageID: item.messageID,
        accepted: status
      },
    };

    this.props.acceptContactFunc(sendRequest ,item.sourceUID);
  }
  cancelSendRequest(item){
    let sendRequest={
      data:{
        messageID:item.messageID,
        targetUID:item.targetUID
      }
    }
    
    this.props.cancelSendUserContactRequest(sendRequest,item.targetUID);
  }
  render () {
    const {sendRequestsList, receiveRequestsList} = this.state;
    return (
      <div className="contactListContiner">
        <div className="mainrow">
          <div style={{textAlign:'right',marginBottom:20}}>
          <Button className="contactRequestbackbutton" onClick={()=>this.props.history.goBack()}   data-rh="Go back">
              <div>
              <img src={leftbackarrow} alt="backIcon" />
            </div> Go 
             <span> Back</span></Button>
          </div>
          <div className="innerRequestBox">
          <div className="contactText">
          <div>
            Contact <span> Requests</span>
            </div>
            <div>
            <Button className="myContactButtom" onClick={()=>this.props.history.goBack()}   data-rh="Go back">
             
              <img src={blueUsericon} alt="backIcon" />
            My Contacts</Button>
             </div>
          </div>
          <div className="contactBox">

            <div className="tab">
              <div
                className="firsttabBox tabbox"
                style={{
                  background: this.state.isReceive ? '#4453D6' : '#8B2FA0',
                }}
              >
                <img className="grpIcon" src={groupRequestIcon} />
                {this.state.isReceive
                  ? 'Requests Received (' + receiveRequestsList.length + ')'
                  : ' Requests I’ve Sent (' + sendRequestsList.length + ')'}
              </div>
              <div
                className="tabbox secondtabBox"
                onClick={() => {
                  this.changeTab (false);
                }}
              >
                {!this.state.isReceive
                  ? 'Requests Received (' + receiveRequestsList.length + ')'
                  : ' Requests I’ve Sent (' + sendRequestsList.length + ')'}

              </div>

            </div>
            <div style={{borderTop:'4px dotted #FFFFFF',margin:20,opacity:0.5}}/> 
            <div className="requestContainer">
              {this.state.isReceive
                ? <div>
                    {receiveRequestsList &&
                      receiveRequestsList.length === 0 &&
                      <div>
                        <p className="noRequestData">
                          {' '}Received Request Not Found
                        </p>
                      </div>}
                    {receiveRequestsList &&
                      receiveRequestsList.length > 0 &&
                      receiveRequestsList.map ((item, i) => (
                        <div key={i}>
                          <div className=" innerContainerBox">
                            <img
                              src={
                                item &&
                                  item.contactDetails &&
                                  item.contactDetails.profilePictureURL
                                  ? item.contactDetails.profilePictureURL
                                  : profilePlaceholder
                              }
                              onError={e => {
                                e.target.onerror = null;
                                e.target.src = profilePlaceholder;
                              }}
                              className="proimg"
                            />

                            <div
                              className="userNameBox"
                              onClick={() =>
                                this.goToprofile (item.contactDetails)}
                            >
                              <div className="laura">

                              
                                {item && item.contactDetails ?   ShowNameAccordingToLength(item.contactDetails.firstName + ' ' + item.contactDetails.lastName):''}
                                      {' '}
                                      {/*item &&
                                        item.contactDetails &&
                                        item.contactDetails.lastName
                                        ? item.contactDetails.lastName
                                      : ''*/}
                              </div>
                              <div className="neuro">
                                {item &&
                                  item.contactDetails &&
                                  item.contactDetails.position
                                  ? item.contactDetails.position
                                  : ''}
                              </div>
                            </div>

                            <div className="requestButtons">
                              <span className="date">
                                {item  && item.pendingActionDetails && item.pendingActionDetails.lastUpdatedAtISO
                                  ? moment (
                                      new Date (item.pendingActionDetails.lastUpdatedAtISO)
                                    ).format ('DD/MM/YYYY')
                                  : ''}

                              </span>

                              <Button className="approve" onClick={()=>this.acceptRequest(item.pendingActionDetails,true)} data-rh="Accept request">
                                Approve Contact
                              </Button>
                              <Button className="remove" onClick={()=>this.acceptRequest(item.pendingActionDetails,false)} data-rh="Cancel request">Remove</Button>
                            </div>
                          </div>
                          <div className="divider" />

                        </div>
                      ))}

                  </div>
                : <div>

                    {sendRequestsList &&
                      sendRequestsList.length === 0 &&
                      <div>
                        <p className="noRequestData"> Send Request Not Found</p>
                      </div>}
                    {sendRequestsList &&
                      sendRequestsList.length > 0 &&
                      sendRequestsList.map ((item, i) => (
                        <div key={i}>
                          <div className=" innerContainerBox">
                            <img
                              src={
                                item &&
                                  item.contactDetails &&
                                  item.contactDetails.profilePictureURL
                                  ? item.contactDetails.profilePictureURL
                                  : profilePlaceholder
                              }
                              onError={e => {
                                e.target.onerror = null;
                                e.target.src = profilePlaceholder;
                              }}
                              className="proimg"
                            />

                            <div
                              className="userNameBox"
                              onClick={() =>
                                this.goToprofile (item.contactDetails)}
                            >
                              <div className="laura">
                              {item && item.contactDetails ?   ShowNameAccordingToLength(item.contactDetails.firstName + ' ' + item.contactDetails.lastName):''}

                                      
                                        {/*item &&
                                          item.contactDetails &&
                                          item.contactDetails.lastName
                                          ? item.contactDetails.lastName
                                        : ''*/}

                              </div>
                              <div className="neuro">
                                {item &&
                                  item.contactDetails &&
                                  item.contactDetails.position
                                  ? item.contactDetails.position
                                  : ''}

                              </div>
                            </div>

                            <div className="requestButtons">
                              <span className="date">
                                {item && item.lastUpdatedAtISO
                                  ? moment (
                                      new Date (item.lastUpdatedAtISO)
                                    ).format ('DD/MM/YYYY')
                                  : ''}

                              </span>

                              <Button className="pendingButton">
                                Pending Approval{' '}
                              </Button>
                              <Button className="clearButton" onClick={()=>this.cancelSendRequest(item)} data-rh="Cancel request">Cancel</Button>
                            </div>
                          </div>
                          <div className="divider" />

                        </div>
                      ))}

                  </div>}
            </div>

          </div>
          </div>
          </div>
        <RightSideContainer>
          <UserContactListPage history={this.props.history} />

        </RightSideContainer>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.ContactsReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getUserNotifications,
      getAllsendRequestusers,
      getAllreceiveRequestusers,
      acceptContactFunc,
      cancelSendUserContactRequest
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (contactRequest);
