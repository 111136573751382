let initialState = {
  //  serviceRequest:{}
};

export const ACCEPT_SERVICE_REQUEST = 'ACCEPT_SERVICE_REQUEST';
export const REJECT_SERVICE_REQUEST = 'REJECT_SERVICE_REQUEST';
export const AWARDED_SERVICE_REQUEST = 'AWARDED_SERVICE_REQUEST';


export default (state = initialState, action) => {
  switch (action.type) {
    case ACCEPT_SERVICE_REQUEST:
      return {
        ...state,
        acceptServiceRequest: action.payload,
        status:true
      };
    case REJECT_SERVICE_REQUEST:
      return {
        ...state,
        rejectServiceRequest: action.payload,
        status:false
      };
      case AWARDED_SERVICE_REQUEST:
      return {
        ...state,
        awardedServiceRequest: action.payload,
        
      };
    default:
      return state;
  }
};
