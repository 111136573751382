import axios from 'axios';
import store from '../store';
import { createNotification} from '../constants/notificationtoast';
import {RDEE_VAULT_URL} from './servicesConfig';

const vaultbaseService = axios.create({baseURL:RDEE_VAULT_URL});

vaultbaseService.interceptors.request.use((config)=>{
    const currentState = store.getState();
    const { Authreducer } = currentState;


    if(
        Authreducer &&
        Authreducer.authToken
    ) {
        config.headers['Authorization'] = "Bearer " + Authreducer.authToken;

        // config.headers['Authorization'] = "Bearer " +"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoicmFkaW9sb2dleC1mcm9udGVuZCIsImlhdCI6MTU3Njc1ODg4NywiZXhwIjoxNjA4Mjk0ODg3fQ.6vc14BTXXyBpyx-SvMimSFAovWAQdE7Wnxumvx2vdUI";
        // config.headers['Authorization'] = "Bearer " +RDEE_BC_TOKEN;
    
    }
    return config;
}, error => {
    return Promise.reject(error);
});

vaultbaseService.interceptors.response.use((config)=>{
    return config;
},error => {
    if(error && error.response && error.response.status && error.response.status === 401){
        // console.log(error.response.data);
        if(error.response.data && error.response.data.message){
            createNotification('error', error.response.data.message);
        }

        localStorage.clear();
        setTimeout(()=>{
            window.location.reload();
        },1000)


        if(error.response.data && error.response.data.error && error.response.data.error.message){
            createNotification('error', error.response.data.error.message);
            
        }
        if(error.response.data && error.response.data.error && error.response.data.error_message){
            createNotification('error', error.response.data.error_message);
        }
       

    }
    if(error  && error.response && error.response.status){
       
    if( error.response.status === 403){
        createNotification('error', error.response.data.data.msg);

    }
    if(error.response.status === 500 || error.response.status === 400 ){
        // localStorage.clear();
        // window.location.href="/";
       

        if(error.response.data && error.response.data.data && error.response.data.data[0]){
            createNotification('error', error.response.data.data[0].msg);
            return { error};
           
        }
    }
    return { error};

}

});
export default vaultbaseService;