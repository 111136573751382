import React, { Component } from 'react'
import './contentApps.css'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Loading } from '../../actions/LoadingAction'
import leftbackarrow from '../../images/icons/leftbackarrowBlue.png'
import { Button } from 'reactstrap'
import ReactPlayer from 'react-player'
import { checkAvailableAccess } from '../../constants/localMessage/errorMessage'
import contentAppsComing from '../../images/comingsoon/contentAppsComing.svg'
import CommonAccessContainer from './../../components/UI/commonAccessContainer/commonAccessContainer'

class ContentApps extends Component {
  // defining state
  constructor (props) {
    super(props)
    this.state = {
      urls: [
        // {url: 'https://www.youtube.com/watch?v=Klq1Txa_N84'},
        {
          url:
            'https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FUPLOADS_1_RDEE%20Sizzle%20Reel%20%20January%202021_1080pFHR.mp4?alt=media&token=565ad007-215f-492f-945a-54d9931ad8a5'
        },
        {
          url:
            'https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FUPLOADS_7_Enable%20Passwordless%20Authentication%20in%20RDEE_1080pFHR.mp4?alt=media&token=d0692b63-47de-4054-9dcc-61e624d17a29'
        },
        {
          url:
            'https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FUPLOADS_6_Meet%20RDEE%20Connect%20%20Meet_1080pFHR.mp4?alt=media&token=67a5903d-cf5c-4c69-a023-81315081a4b6'
        }

        // {url: 'https://vimeo.com/478538309'},
      ],
      medicalurls: [
        {
          url:
            'https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FMEDICAL%20IMAGING_1_A%20RIFT%20IN%20THE%20RETINA%20MAY%20HELP%20REPAIR%20THE%20OPTIC%20NERVE%20(%232).mp4?alt=media&token=ac37600f-e96e-4c9e-8f9b-2c9983af8439'
        },
        {
          url:
            'https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FMEDICAL%20IMAGING_2_Top%2010%20Medical%20Innovations%20for%202021.mp4?alt=media&token=c0ec1763-0484-487c-82ed-77f5aa2e8b34'
        },
        {
          url:
            'https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FMEDICAL%20IMAGING_3_4DCT%20Treatment%20for%20Patients%20with%20Recurrent%20Patellar%20Instability_1080p.mp4?alt=media&token=897dea38-799c-4a96-adad-41dd816eea1c'
        },
        {
          url:
            'https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FMEDICAL%20IMAGING_4_Antibioticdestroying%20genes%20widespread%20in%20bacteria%20in%20soil%20and%20on%20people_1080p.mp4?alt=media&token=9f0ea2f8-7d8f-4498-8472-83d0d0c185fd'
        },
        {
          url:
            'https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FMEDICAL%20IMAGING_5_Hip%20Fracture-Dislocation%20With%20Residual%20Cartilage%20Deformity%20(Graphic).mp4?alt=media&token=fd4d6d1f-fb1e-47e0-a7a3-e9af43e38ff0'
        }
      ],
      coronavirus: [
        {
          url:
            'https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FCORONAVIRUS_1_COVID19%20Animation%20What%20Happens%20If%20You%20Get%20Coronavirus_1080p.mp4?alt=media&token=189e8b5a-ff43-40f5-bdfd-40898f23215a'
        },
        {
          url:
            'https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FCORONAVIRUS_2_Puurs%20Ships%20COVID-19%20Vaccine%20Doses%20Following%20European%20Union%20Conditional%20Marketing%20Authorization.mp4?alt=media&token=353063c4-2b02-47d7-beed-1984c5709e72'
        },
        {
          url:
            'https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FCORONAVIRUS_3_Coronavirus%20Critical%20Care%20Update%20-%20January%202021.mp4?alt=media&token=87934854-dc87-43ba-a166-34e369184120'
        },
        {
          url:
            'https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FCORONAVIRUS_4_Coronavirus%20outbreak%20(covid%2019)%20explained%20through%203D%20Medical%20Animation.mp4?alt=media&token=66064063-984c-4ab3-bb55-ca95339a2e31'
        }
      ],
      research: [
        {
          url:
            'https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FRESEARCH_1_A%20World%20in%20a%20Grain%20of%20Sand_%20New%20Discoveries%20in%20Kidney%20Stones-%20Preview.mp4?alt=media&token=21207e94-c152-4e07-b7d5-75ede8f812c3'
        },
        {
          url:
            'https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FRESEARCH_2_Breast%20A.I.%20Research.mp4?alt=media&token=f5a1db40-6724-432d-9e18-db8163a32528'
        },
        {
          url:
            'https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FRESEARCH_3_Making%20cancer%20cells%20more%20susceptible%20to%20dying_480p.mp4?alt=media&token=5641ded0-4f2e-4fc0-817a-3420d264e025'
        },
        {
          url:
            "https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FRESEARCH_4_Debra's%20story_%20Cancer%20research%2C%20clinical%20care%20and%20prevention%20at%20the%20School%20of%20Medicine.mp4?alt=media&token=adff9724-dbb5-4b9c-805f-d9d795ed7bfd"
        }
      ],
      events: [
        {
          url:
            'https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FUPLOADS_2_Part%201%20Radiologex%20on%20the%20Blockchain%20Breakfast%20Podcast%20122020_1080p.mp4?alt=media&token=c3590ef6-38d4-438c-a7b3-f86a3bbdeefb'
        },
        {
          url:
            'https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FUPLOADS_3_Part%202%20Radiologex%20on%20the%20Blockchain%20Breakfast%20Podcast%20122020_1080p.mp4?alt=media&token=5a4739cc-51dd-44a7-9e9a-d171c387bd3e'
        },
        {
          url:
            'https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FUPLOADS_4_Part%203_%20Radiologex%20on%20the%20Blockchain%20Breakfast%20Podcast%2012_20_20.mp4?alt=media&token=eaa90e90-6dc8-48d8-a64b-521030b2661e'
        },
        {
          url:
            'https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FUPLOADS_8_Radiologex%20Demo%20at%20Arab%20Health%202020%20in%20Dubai%20UAE_1080p.mp4?alt=media&token=8b9b815e-1a06-41c9-ba89-122d5fe2b515'
        },
        {
          url:
            'https://firebasestorage.googleapis.com/v0/b/radiologex/o/stream%2FPlatform%2FUPLOADS_5_Meet%20R%20DEE%20Vault_1080pFHR.mp4?alt=media&token=c0c74831-f5f8-4d65-b320-8cc9178074e8'
        }
      ],
      getResponseData: '',
      isAccess: true
    }
  }

  componentDidMount () {
    this.props.Loading(false)
    if (
      checkAvailableAccess('contents_app') &&
      checkAvailableAccess('contents_app') === 'contents_app'
    ) {
      this.setState({
        isAccess: true
      })
    } else {
      this.setState({
        isAccess: false
      })
    }
  }

  updatePlayerurls (index, updateStatus) {
    const { urls, medicalurls, coronavirus, research, events } = this.state
    for (let items of urls) {
      items.playing = false
    }

    for (let items of medicalurls) {
      items.playing = false
    }
    for (let items of coronavirus) {
      items.playing = false
    }
    for (let items of research) {
      items.playing = false
    }
    for (let items of events) {
      items.playing = false
    }

    const newurls = [...this.state.urls]
    const newmedicalurls = [...this.state.medicalurls]
    const newcoronavirus = [...this.state.coronavirus]
    const newresearch = [...this.state.research]
    const newevents = [...this.state.events]

    if (updateStatus === 'about') {
      urls[index].playing = true
    }
    if (updateStatus === 'medical') {
      newmedicalurls[index].playing = true
    }
    if (updateStatus === 'coronavirus') {
      newcoronavirus[index].playing = true
    }
    if (updateStatus === 'research') {
      newresearch[index].playing = true
    }
    if (updateStatus === 'events') {
      newevents[index].playing = true
    }

    this.setState({
      urls: newurls,
      medicalurls: newmedicalurls,
      coronavirus: newcoronavirus,
      research: newresearch,
      events: newevents
    })
  }

  render () {
    const { isAccess } = this.state
    return (
      <div className='contentAppContiner '>
        {isAccess && (
          <div>
            <div
              className='streamgoBackButtonBox'
              style={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <div className='heading'>R-DEE Stream </div>

              <Button
                className='appsbackbutton'
                onClick={() => this.props.history.goBack()}
              >
                <div>
                  <img src={leftbackarrow} alt='backIcon' />
                </div>
                <span style={{ opacity: 0.6 }}> Go</span>
                <span> Back</span>
              </Button>
            </div>
            <div>
              <div className='heading catheadingTitle'>About R-DEE </div>
              <div className='streamPlayerFlexWrap'>
                {this.state.urls.map((item, i) => (
                  <ReactPlayer
                    key={item.url}
                    className='streamplayerCss'
                    url={item.url}
                    light={!item.playing}
                    playing={item.playing}
                    controls
                    playsinline
                    onClick={() => this.updatePlayerurls(i, 'about')}
                  />
                ))}
              </div>
            </div>

            <div>
              <div className='heading catheadingTitle'>MEDICAL IMAGING </div>
              <div className='streamPlayerFlexWrap'>
                {this.state.medicalurls.map((item, i) => (
                  <ReactPlayer
                    key={i}
                    className='streamplayerCss'
                    url={item.url}
                    light={!item.playing}
                    controls
                    playsinline
                    playing={item.playing}
                    onClick={() => this.updatePlayerurls(i, 'medical')}
                  />
                ))}
              </div>
            </div>

            <div>
              <div className='heading catheadingTitle'>CORONAVIRUS </div>
              <div className='streamPlayerFlexWrap'>
                {this.state.coronavirus.map((item, i) => (
                  <ReactPlayer
                    key={i}
                    className='streamplayerCss'
                    url={item.url}
                    light={!item.playing}
                    controls
                    playsinline
                    playing={item.playing}
                    onClick={() => this.updatePlayerurls(i, 'coronavirus')}
                  />
                ))}
              </div>
            </div>
            <div>
              <div className='heading catheadingTitle'>RESEARCH </div>
              <div className='streamPlayerFlexWrap'>
                {this.state.research.map((item, i) => (
                  <ReactPlayer
                    key={i}
                    className='streamplayerCss'
                    url={item.url}
                    light={!item.playing}
                    controls
                    playsinline
                    playing={item.playing}
                    onClick={() => this.updatePlayerurls(i, 'research')}
                  />
                ))}
              </div>
            </div>
            <div className='heading catheadingTitle'>Press & Events </div>
            <div className='streamPlayerFlexWrap'>
              {this.state.events.map((item, i) => (
                <ReactPlayer
                  key={i}
                  className='streamplayerCss'
                  url={item.url}
                  light={!item.playing}
                  controls
                  playsinline
                  playing={item.playing}
                  onClick={() => this.updatePlayerurls(i, 'events')}
                />
              ))}
            </div>
          </div>
        )}

        {!isAccess && (
          <CommonAccessContainer
            bgImage={contentAppsComing}
            message={checkAvailableAccess('contents_app')}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    // contact: state.ContactsReducer,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      Loading
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentApps)
