const IMAGE_TYPE_ERROR = 'Invalid file selected. Please select a valid Image file';

const GROUP_DETAIL_PHOTO_REQUIRED = 'A photo as a group icon is required.';
const GROUP_DETAIL_PHOTO_ERROR = 'The group icon should be less than 5mb in size.';
const GROUP_DETAIL_NAME_REQUIRED = 'Group name is required.';
const GROUP_DETAIL_NAME_TEXT_LENGTH = 'Group name cannot be more than 30 characters long.';

export {
    IMAGE_TYPE_ERROR,
    GROUP_DETAIL_PHOTO_REQUIRED,
    GROUP_DETAIL_PHOTO_ERROR,
    GROUP_DETAIL_NAME_REQUIRED,
    GROUP_DETAIL_NAME_TEXT_LENGTH
};