import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button} from 'reactstrap';
import nextWhiteArrow from '../../../../images/icons/carousel/nextWhiteArrow.svg';
import previousWhiteArrow from '../../../../images/icons/carousel/previousWhiteArrow.svg';

import './listToolbar.css'
class ListToolbar extends Component {
  constructor (props) {
    super(props)
    this.state = {
      menuItems: []
    }
    this.openLabel = this.openLabel.bind(this)
  }

 
  componentDidMount () {}
  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if (nextProps.sideMenu) {
        if (
          nextProps.sideMenu.labelslist &&
          nextProps.sideMenu.labelslist.length > 0
        ) {
          this.setState({
            menuItems: nextProps.sideMenu.labelslist
          })
        }
      }
    }
  }
  openLabel (item) {
    this.props.loadLabelMessages(item)
  }

  render () {
    const { nextToken,prevToken } = this.props;
    console.log( nextToken,prevToken);
    return (
      <div>
        <div className="navigationButtonBar">
            <Button disabled={!prevToken} className="navigateButton" onClick={()=>this.props.navigateToPrevPage(prevToken)}>
            
            <img src={previousWhiteArrow} alt="prev"/> Previous</Button>
            <Button disabled={!nextToken} className="navigateButton" onClick={()=>this.props.navigateToNextPage(nextToken)}> Next <img src={nextWhiteArrow} alt="next"/></Button>

            <Button className="navigateButton" onClick={()=>this.props.onSignout()}> Logout</Button>

        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    sideMenu: state.SidebarReducer
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      //   getLabels,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListToolbar)
