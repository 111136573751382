import React, {Component} from 'react';
import './vaultGrid.css';
import {connect} from 'react-redux';
import moment from 'moment';
import {bindActionCreators} from 'redux';
import {Button, Progress} from 'reactstrap';
import smallDownWhite from '../../../images/icons/vault/whiteDown.png';
import folderIcon from '../../../images/icons/vault/folderIcon.svg';
import starIcon from '../../../images/icons/vault/starIcon.png';
import {dayTimeSince} from '../../../utility/convertTimeString';
import blueCheckmark from '../../../images/icons/vault/blueCheckmark.png';
import whiteFileIcon from '../../../images/icons/vault/whiteFileIcon.svg';
import whiteCameraIcon from '../../../images/icons/vault/whiteCameraIcon.svg';
import whitePlayIcon from '../../../images/icons/vault/whitePlayIcon.svg';
import pacsIcon from '../../../images/icons/vault/pacsIcon.png';


class VaultGridView extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      folderSorting: false,
      filesSorting: false,
      recentSorting: false,
      sortByName: '',
      sortByFile:''
    };
    this.folderSortBy = React.createRef ();
    this.filesSortBy = React.createRef ();
    this.recentSortBy = React.createRef ();
  }

  componentWillUnmount () {
    document.removeEventListener ('mousedown', this.handleClickOutside);
  }
  componentDidMount () {
    document.addEventListener ('mousedown', this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (
      this.folderSortBy.current &&
      !this.folderSortBy.current.contains (event.target)
    ) {
      this.setState ({
        folderSorting: false,
      });
    }

    if (
      this.filesSortBy.current &&
      !this.filesSortBy.current.contains (event.target)
    ) {
      this.setState ({
        filesSorting: false,
      });
    }

    if (
      this.recentSortBy.current &&
      !this.recentSortBy.current.contains (event.target)
    ) {
      this.setState ({
        recentSorting: false,
      });
    }
  };

  sortByName (value) {
    this.setState ({
        sortByName: value,
        filesSorting: false,
        folderSorting:false
      });
  }

  sortByNameFile(value){
    this.setState({
      sortByFile:value,
      filesSorting: false,
      folderSorting:false
    })
  }

  selectItems (item) {
    this.props.selectItems (item);
  }

  


    compareByNameData (status) {
      if (status === 'asc') {
        return function (a, b) {
          var nameA = a.fileName.toLowerCase (),
            nameB = b.fileName.toLowerCase ();
          // var nameA = a.fileName,
          // nameB = b.fileName;
          if (
            nameA < nameB //sort string ascending
          )
            return -1;
          if (nameA > nameB) return 1;
          return 0;
        };
      } else {
        return function (a, b) {
          var nameA = a.fileName.toLowerCase (),
            nameB = b.fileName.toLowerCase ();
          // var nameA = a.fileName,
          // nameB = b.fileName;
          if (
            nameB < nameA //sort string descending
          )
            return -1;
          if (nameB > nameA) return 1;
          return 0;
        };
      }
    }
  



  render () {
    const {
      folderSorting,
      filesSorting,
      sortByName,
      sortByFile
     
    } = this.state;
    const {uploadedList ,isEnabledownload} = this.props;

    let folderItems=[];
    let fileItems=[];


    for(let item of uploadedList){

      if(item && item.userFileType === 'folder'){
          folderItems.push(item)
        }else{
          fileItems.push(item)
        }
    }
   

    if(sortByName==='asc'){
      folderItems =  folderItems.sort (this.compareByNameData ('asc'));
    }
    if(sortByName==='desc'){
      folderItems =  folderItems.sort (this.compareByNameData ('desc'));
    }

    if(sortByFile==='asc'){
      fileItems =  fileItems.sort (this.compareByNameData ('asc'));
    }
    if(sortByFile==='desc'){
      fileItems =  fileItems.sort (this.compareByNameData ('desc'));
    }


    
    return (
      <div>
        <div>

          {/*<div className="uploadedFilesHeader">
            <div className="fileTypeTitle"><span> RECENT </span> FILES</div>
            <div ref={this.recentSortBy}>

              <div className="fileSortByTitle" style={{width: 200}}>
                {' '}Sort by...
                <Button
                  style={{marginLeft: 10}}
                  className="filterFileButton"
                  onClick={() =>
                    this.setState ({
                      recentSorting: !recentSorting,
                    })}
                >
                  <img src={smallDownWhite} alt="img" />
                </Button>
              </div>

              {recentSorting &&
                <div style={{position: 'relative', width: 150}}>
                  <div style={{textAlign: 'right'}}>
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="sortByArrow "
                    >
                      <path d="M9 0L0 18H18L9 0Z" fill="#FFFFFF" />
                    </svg>
                  </div>
                  <div className="addVaultPopup">

                    <div className="listText">
                      Sort by Name A-Z

                    </div>
                    <div className="listText">
                      File Type

                    </div>
                    <div className="listText">
                      Sort by Name Z-A

                    </div>
                    <div className="listText">
                      Last Modified

                    </div>
                    <div className="listText">
                      Last Opened

                    </div>
                  </div>
                </div>}

            </div>

              </div>

          <div className="inlineFolderView">

            {myFiles.map ((item, i) => (
              <div key={i} className="filesMainImagesBox">
                <div
                  className="fileContentBox"
                  style={{backgroundImage: 'url(' + bgImage + ')'}}
                />
                <div className="fileListingNameBox">
                  <div style={{width: 60, paddingLeft: 20}}>
                    <img
                      style={{width: 25, height: 20}}
                      src={folderIcon}
                      alt="icon"
                    />
                  </div>
                  <div>
                    <div className="fileNameBold">
                      IMG_2020.098134098.jpg
                      <div className="fileUploadedStatus">
                        {' '}Added to Vault today
                      </div>

                    </div>
                  </div>

                </div>
              </div>
            ))}
          </div>*/}

          <div className="uploadedFilesHeader" id="folderGirdUploadHeader">
            <div className="fileTypeTitle"  id="folderGirdUploadHeaderTitle"> <b> FOLDERS </b> </div>
            <div ref={this.folderSortBy}  id="folderGirdUploadHeaderSortModal">

              <div className="fileSortByTitle inlineFlexItem" style={{width: 250}}>
              <div>
              <Button
                  className="selectGridButton"
                  onClick={() =>{
                    this.props.isEnableSelection();
                  }
                  }
                  id="folderGirdUploadHeaderSortModalselectGridButton">
                
                {isEnabledownload ? 'Unselect' : 'Select'}
                </Button>
              </div>
                <div className="inlineFlexItem">
                {' '}Sort by...
                <Button
                  style={{marginLeft: 10}}
                  className="filterFileButton"
                  onClick={() =>
                    this.setState ({
                      folderSorting: !folderSorting,
                    })}
                    id="folderGirdUploadHeaderSortModalfilterFileButton"
                >
                  <img src={smallDownWhite} alt="img" />
                </Button>
                </div>
              </div>

              {folderSorting &&
                <div style={{position: 'relative', width: 250}}>
                <div className="nameGridFilterVaultPopup" id="gridFolderSortnameGridFilterVaultPopup">

                    <div
                      className="listText"
                      onClick={() => this.sortByName ('asc')}
                      id="gridFolderSortnameGridFilterVaultPopup1"
                    >
                      <div className="checkBoxDiv"  id="gridFolderSortnameGridFilterVaultPopup2">
                        {sortByName === 'asc' &&
                          <img
                            className="checkBlueimage"
                            src={blueCheckmark}
                            alt="blueCheckmark"
                          />}
                      </div>
                      Sort by Name A-Z

                    </div>
                    <div
                      className="listText"
                      onClick={() => this.sortByName ('desc')}
                      style={{borderBottom:0}}
                      id="gridFolderSortnameGridFilterVaultPopup3"
                    >
                      <div className="checkBoxDiv"  id="gridFolderSortnameGridFilterVaultPopup4">
                        {sortByName === 'desc' &&
                          <img
                            className="checkBlueimage"
                            src={blueCheckmark}
                            alt="blueCheckmark"
                            id="gridFolderSortnameGridFilterVaultPopup5"
                          />}
                      </div>
                      Sort by Name Z-A

                    </div>
                   {/*} <div className="listText">
                      <div className="checkBoxDiv" />
                      Last Modified

                    </div>
                    <div className="listText">
                      <div className="checkBoxDiv" />
                      Last Opened

              </div>*/}
                  </div>
                </div>}

            </div>

          </div>

          <div className="inlineFolderView"  id="gridFolderSortnameGridFilterVaultPopup6">

            {folderItems.map ((item, i) => {
              if (
                item && item.userFileType === 'folder'
              ) {
                return (
                  <div  id={`VaultgridFolderListItem${i}`} style={{
                    // cursor:isEnabledownload &&  (item.userFileType === 'folder')? 'pointer': 'initial',
                    // opacity: isEnabledownload &&
                    // (item.userFileType === 'folder' && !item.fileUUID )
                    // ? 0.5
                    // : '',
                    cursor:'pointer',
                    background: isEnabledownload &&
                    item.isSelected ? '#4453D6' : '#333333'
                  }} className="folderNameBoxes" key={i} onContextMenu={(e)=>{
                    e.preventDefault();
                    // if(!isEnabledownload){
                    //   this.selectItems (item);
                    // }
                    if(item.fileUUID){
                      this.selectItems (item);
                    }

                  }} onClick={()=>{
                    if(!isEnabledownload){
                      this.props.openFolder(item);
                    }else{
                      if(item.userFileType === 'folder' && item.fileUUID){
                        this.props.onSelectDownloadFiles (item,item.isSelected);
                      }
                    }
                  }} >
                    <div style={{display: 'inline-flex'}} id={`VaultgridFolderListItemBox${i}`} >
                      <div style={{width: 30}}>
                        <img
                          style={{width: 25, height: 20}}
                          src={folderIcon}
                          alt="folderIcon"
                        />
                      </div>
                      <div className="folderNameText" id={`VaultgridFolderListItemName${i}`}>
                        {' '}{item.fileName ? item.fileName : ''}
                      </div>
                    </div>

                    
                    <div style={{display:'inline-flex'}}>
                    {item.starred && <Button  className="fileMenuOption" id={`VaultgridFolderListItemStarred${i}`}>
                      <img
                        style={{width: 25, height: 25}}
                        src={starIcon}
                        alt="folderIcon"
                        id={`VaultgridFolderListItemStarredImg${i}`}
                      />
                    </Button>}
                      {/*<Button
                        className="gridfileMenuOption"
                        onClick={() => this.selectItems (item)}
                        data-rh="Menu"
                      >
                        •
                        •
                        •
                      </Button>*/}
                    </div>
                  </div>
                );
              }
            })}

          </div>

        </div>

        <div className="uploadedFilesHeader" id="uploadedFilesHeader">
          <div className="fileTypeTitle" id="VaultFileuploadedFilesHeader2" ><b> FILES </b></div>
          <div ref={this.filesSortBy} id="VaultFileuploadedFilesHeader3">

            <div id="VaultFileuploadedFilesHeader4" className="fileSortByTitle inlineFlexItem"  style={{width: 250}} >
            <div>
            <Button
                className="selectGridButton"
                onClick={() =>{
                  this.props.isEnableSelection();
                }
                }
                id="VaultFileuploadedFilesHeader5"
              >
              {isEnabledownload ? 'Unselect' : 'Select'}
              </Button>
            </div>
            <div className="inlineFlexItem" id="VaultFileuploadedFilesHeader6">
              {' '}Sort by...
              <Button
                style={{marginLeft: 10}}
                className="filterFileButton"
                onClick={() =>
                  this.setState ({
                    filesSorting: !filesSorting,
                  })}
                  id="VaultFileuploadedFilesHeader7"
              >
                <img src={smallDownWhite} alt="img" id="VaultFileuploadedFilesHeader8"/>
              </Button>
            </div>
            </div>


            {filesSorting &&
              <div style={{position: 'relative', width: 250}} id="VaultFileuploadedFilesHeader9">
                <div className="nameGridFilterVaultPopup" id="VaultFileuploadedFilesHeader10">

                  <div
                    className="listText"
                    onClick={() => this.sortByNameFile ('asc')}
                    id="VaultFileuploadedFilesHeader11"
                  >
                    <div className="checkBoxDiv">
                      {sortByFile === 'asc' &&
                        <img
                          className="checkBlueimage"
                          src={blueCheckmark}
                          alt="blueCheckmark"
                          id="VaultFileuploadedFilesHeader12"
                        />}
                    </div>
                    Sort by Name A-Z

                  </div>
                   {/*}<div className="listText">
                    <div className="checkBoxDiv" />
                    File Type

            </div>*/}
                  <div
                    className="listText"
                    onClick={() => this.sortByNameFile ('desc')}
                    style={{borderBottom:0}}
                    id="VaultFileuploadedFilesHeader13"
                  >
                    <div className="checkBoxDiv">
                      {sortByFile === 'desc' &&
                        <img
                          className="checkBlueimage"
                          src={blueCheckmark}
                          alt="blueCheckmark"
                          id="VaultFileuploadedFilesHeader14"
                        />}
                    </div>
                    Sort by Name Z-A

                  </div>
                   {/*}<div className="listText">
                    <div className="checkBoxDiv" />
                    Last Modified

                  </div>
                  <div className="listText">
                    <div className="checkBoxDiv" />
                    Last Opened

          </div>*/}
                </div>
              </div>}

          </div>

        </div>

        <div className="inlineFolderView" id="VaultFileuploadedFilesHeader15">

          {fileItems.map ((item, i) => {
            if (
              item && item.userFileType === 'file'
            ) {
              return (
                <div  id={`VaultgridFileListItem${i}`}  key={i} className="filesMainImagesBox" style={{borderRadius:0 ,cursor:isEnabledownload?'pointer':'initial' , }} onContextMenu={(e)=>{
                  e.preventDefault();
                  // if(!isEnabledownload){
                    this.selectItems (item);
                  // }
                  
                }} onClick={()=>{
                  if(isEnabledownload){
                    this.props.onSelectDownloadFiles (item,item.isSelected);
                  }
                }}>
                 
                  <div  id={`VaultgridFileListItemName${i}`} className="fileListingNameBox" style={{background: isEnabledownload &&
                    item.isSelected ? '#4453D6' : '#333333',}}>
                    <div style={{width: 60, paddingLeft: 15}}>
                    {item.fileType ===
                      'other' &&
                      <img
                        src={whiteFileIcon}
                        alt="file type"
                        style={{width: 24, height: 30}}
                        id={`VaultgridFileListFileTypeStatusOther${i}`}
                      />}

                    {item.fileType ===
                      'dicom' &&
                      <img
                        onError={e => {
                          e.target.onerror = null;
                          e.target.src = pacsIcon;
                        }}
                        src={item.base64 ? item.base64: pacsIcon}
                        alt="file type"
                        style={{width: 28, height: 30}}
                        id={`VaultgridFileListFileTypeStatusDicom${i}`}
                      />}

                      
                    {item.fileType ===
                      'image' &&
                      <img
                        // src={whiteCameraIcon}
                        onError={e => {
                          e.target.onerror = null;
                          e.target.src = whiteCameraIcon;
                        }}

                        // src={item.shareableLink?item.shareableLink:whiteCameraIcon}
                        src={item.base64 ? item.base64: whiteCameraIcon}
                        alt="file type"
                        style={{width: 34, height: 34}}
                        id={`VaultgridFileListFileTypeStatusImage${i}`}
                      />}
                    {(item.fileType ===
                      'video' ||
                      item.fileType ===
                        'audio' )&&
                        <img
                          src={whitePlayIcon}
                          alt="file type"
                          style={{width: 34, height: 34}}
                          id={`VaultgridFileListFileTypeStatusAudioVideo${i}`}

                        />}

                    </div>
                    <div>
                      <div className="fileNameBold" id={`VaultgridFileListItemName${i}`}>
                        {' '}{item.fileName ? item.fileName : ''}
                   

                        {item.progress && Number(item.progress < 100)  ?<div>
                          <div className="gridfileStatusBox">
                            <Progress
                             value={item.progress ? item.progress : 0}
                              className="GriduploadProgressBar"
                              id={`VaultgridFileListItemNameProgress${i}`}
                            />
                            {' '} {item.progress} %

                          </div> </div>:
                        <div className="fileUploadedStatus" id={`VaultgridFileListItemNameAddtoVault${i}`}>
                          {' '}
                          Added to Vault
                          {' '}
                          
                          {item && item.updated ? dayTimeSince (moment(item.updated).format()) : ''}
                    </div>}
                   
                    </div>

                    </div>
                    
                    <div style={{display:'inline-flex',width:40,justifyContent:'flex-end'}}>
                    {item.starred && <Button  className="fileMenuOption"  id={`VaultgridFileListItemNameStarredItem${i}`}>
                      <img
                        style={{width: 25, height: 25}}
                        src={starIcon}
                        alt="folderIcon"
                        id={`VaultgridFileListItemNameStarredItemimg${i}`}
                      />
                    </Button>}
                      {/*<Button
                        className="gridfileMenuOption"
                        onClick={() => this.selectItems (item)}
                        data-rh="Menu"
                      >
                        •
                        •
                        •
                      </Button>*/}
                    </div>

                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userinfo: state.Authreducer,
    // vaultData: state.vaultReducers,

    // contact: state.ContactsReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators ({}, dispatch);
};

export default connect (mapStateToProps, mapDispatchToProps) (VaultGridView);
