import React, {Component} from 'react';
import './InfoSys.css';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Loading} from '../../actions/LoadingAction';
// import leftbackarrow from '../../images/icons/leftbackarrowBlue.png';
// import {Button} from 'reactstrap';
// import ReactPlayer from 'react-player'
// import {DEV_SERVER} from '../../services/servicesConfig';
import {checkAvailableAccess} from './../../constants/localMessage/errorMessage';
import InfoSyscoming from '../../images/comingsoon/InfoSyscoming.svg';
import  CommonAccessContainer from './../../components/UI/commonAccessContainer/commonAccessContainer';



class InfoSys extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      isAccess: true
      };
  }
  componentDidMount(){
    this.props.Loading(false);
    // this.setState({
    //   hideContent: checkAvailableAccess('infosys')
    // })
    if(checkAvailableAccess ("infosys") && checkAvailableAccess ("infosys")=== 'infosys'){
      this.setState({
        isAccess:true
      })
    }else{
      // this.setState({
      //   isAccess:false
      // })
    }

      
  }

  render () {
    const {isAccess }= this.state;
    return (
      <div>
    {isAccess  && <CommonAccessContainer bgImage={InfoSyscoming} message={checkAvailableAccess('infosys')} />}
      
      
     {/*} <div className="backgroundAvailaleSoon" style={{backgroundImage: `url(${InfoSyscoming})` }}>

      <div className="centerBGimageText">Available Soon</div>
      
      
    </div>*/}
    </div>
      
    
    );
  }
}

const mapStateToProps = state => {
  return {
    // contact: state.ContactsReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators ({
    Loading,
  }, dispatch);
};

export default connect (mapStateToProps, mapDispatchToProps) (InfoSys);
