import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import './GroupInfoCard.css';
import profilePlaceholder from '../../../images/profile_placeholder.png';
import defaultNotification from '../../../images/icons/defaultNotification.png';
import { dataFormat } from './../../../utility/convertTimeString';


export default function GroupInfoCard({
  name = 'Group name',
  groupHeader = 'Group Details',
  showEditButton = false,
  onEditClick = () => console.log('EDit clicked'),
  onLeaveGroupClick = () => console.log('EDit clicked'),
  goupImage,
  groupCreatedTime,
  isMuted,
  onChange = () => console.log('onChange clicked'),


}) {
  return (
    <div style={{marginLeft: 7}}>
      <div className="groupInfoCard" id="connect-groupInfoCard">

        <Button className="groupTitleButton" id="connect-groupInfoCard1">Group <span> Info</span></Button>
        <div style={{margin:15}}>
          <div className="groupNameImageSection" id="connect-groupInfoCard2">

            <div style={{ width: 100 }} id="connect-groupInfoCard3">
              <img src={goupImage} className="groupImageStyle" onError={e => {
                e.target.onerror = null;
                e.target.src = profilePlaceholder;
              }} alt="Avatar" id="connect-groupInfoCard4"/>
            </div>
            <div className="groupName" id="connect-groupInfoCard5">
              {name}
            </div>

          </div>
          <div>
          </div>
          <div className="toggleNotificationSection" id="connect-groupInfoCard6">

            <div className="notificationGroupTitleText" id="connect-groupInfoCard7">
              <img id="connect-groupInfoCard8" src={defaultNotification} alt="icon" style={{ width: 20, marginRight: 10 }} />  Group Notifications
            </div>
            <div>
              <div className="onOfftext" id="connect-groupInfoCard9">OFF/<span>ON</span></div>
              <label className="groupSwitch" id="connect-groupInfoCard10">
                <input
                  type="checkbox"
                  // value={true}
                  checked={!isMuted}
                  // onChange={() => {
                  //   this.setState ({
                  //     phoneNumber: !phoneNumber,
                  //   });
                  // }}
                  onChange={onChange}
                  id="connect-groupInfoCard11"
                />
                <span style={{alignItems: 'flex-end'}} className="groupSlider round" />
              </label>
            </div>

          </div>
          <div id="connect-groupInfoCard12" className="borderBottom" style={{ marginTop: 0, marginBottom: 0 }} />
          <div id="connect-groupInfoCard13" className="Groupcrea">Group created : {groupCreatedTime ? dataFormat(groupCreatedTime) : ''}</div>
        </div>

        <div style={{ textAlign: 'right', marginTop: 20, justifyContent: 'flex-end' }} id="connect-groupInfoCard14">

          {showEditButton && <Button id="connect-groupInfoCard15" className="manageGroup" onClick={onEditClick} data-rh="Edit group">Manage Group</Button>}
          <Button id="connect-groupInfoCard16" onClick={onLeaveGroupClick} className="leaveGroup" data-rh="Exit from group">Leave Group</Button>

        </div>
      </div>

      {/*<div className="info-card">
            <div className="info-card-header">
                <p>{groupHeader}</p>
                {
                    showEditButton && <img
                        src={require('../../../images/EditVector.png')}
                        onClick={onEditClick}
                    />
                }
            </div>
            <div className="info-card-body">
                <div className="overlay"/>
                <img src={goupImage} alt="Avatar" />
                <p>{name}</p>
            </div>
            </div>*/}
    </div>
  );
};

GroupInfoCard.propTypes = {
  name: PropTypes.string.isRequired,
  groupHeader: PropTypes.string,
  showEditButton: PropTypes.bool,
  goupImage: PropTypes.string.isRequired,
  onEditClick: PropTypes.func,
  onLeaveGroupClick: PropTypes.func,
}