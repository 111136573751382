import React, {Component} from 'react';
import {Row, Col, Button, Progress} from 'reactstrap';

import doctorImage from '../../images/serviceOnDemandProfile/doctor.png';
import acivityImage from '../../images/serviceOnDemandProfile/acivity.png';
import ForwordImage from '../../images/serviceOnDemandProfile/forword.png';
import profilePlaceholder from '../../images/profile_placeholder.png';
import bellIcon from '../../images/bell.png';
import bluesidesear from '../../images/bluesidesear.png';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as routes from '../../constants/routes';
import './serviceOnDemandProfile.css';
import StarRatingComponent from 'react-star-rating-component';
import {GetServiceProfile} from '../../actions/serviceOnDemandAction';
import serviceAreaList from '../../constants/localJSON/serviceAreaList.json';
import serviceOffered from '../../constants/localJSON/serviceOffered.json';
import yellowdot from '../../images/serviceOnDemandMyRequest/yellowdot.png';
import {GetServiceProviders} from '../../actions/serviceOnDemandAction';
import {MultiSelect} from 'primereact/multiselect';

import moment from 'moment';

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});
class ServiceOnDemandProfilePage extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      userProfile: [],
      businessDetails: [],
      isSearch: false,
      searchString: '',
      servicesOffered: '',
      serviceArea: '',
      Dropdownvalue: '',
      Dropdownvalue2: '',
      myRequestsRecievedList: [],
      myRequestsList: [],
    };
  }

  componentDidMount () {
    let loginUser = localStorage.getItem ('loginuser');
    if (loginUser) {
      let getuserInfo = JSON.parse (loginUser);
      const sendRequest = {
        data: {
          docID: getuserInfo.docID,
        },
      };

      this.props.GetServiceProfile (sendRequest);
    }
  }
  componentWillReceiveProps (nextProps) {
   

    if (
      nextProps &&
      nextProps.serviceProfile &&
      nextProps.serviceProfile.myserviceRequest
    ) {
      this.setState ({
        myRequestsList: nextProps.serviceProfile.myserviceRequest.slice (0, 4),
      });
    }
    if (
      nextProps &&
      nextProps.serviceProfile &&
      nextProps.serviceProfile.myRequestRecieved
    ) {
      this.setState ({
        myRequestsRecievedList: nextProps.serviceProfile.myRequestRecieved.slice (
          0,
          4
        ),
      });
    }

    if (
      nextProps &&
      nextProps.serviceProfile &&
      nextProps.serviceProfile.serviceProfile
    ) {
      this.setState ({
        userProfile: nextProps.serviceProfile.serviceProfile,
        businessDetails: nextProps.serviceProfile.businessDetails,
      });
    }
  }
  searchServiceProviders = () => {
    this.setState (
      {
        isSearch: true,
      },
      () => {
        let sendRequest = {
          data: {
            serviceArea: this.state.Dropdownvalue,
            servicesOffered: this.state.Dropdownvalue2,
            searchString: this.state.searchString,
          },
        };
        this.props.GetServiceProviders (sendRequest);
      }
    );
    // this.setState ({serviceArea: '', servicesOffered: '', searchString: ''});
  };
  render () {
    const {contacts} = this.props;
    const {
      userProfile,
      businessDetails,
      myRequestsRecievedList,
      myRequestsList,
    } = this.state;
    return (
      <div className="flex1 ">
        <div className="servicemainContainer">
          <div>
            <div className="padd10">
              <Row>

                <Col sm="6" style={{paddingTop: 20}}>
                  <img src={bellIcon} className="notiIcon" />
                  <Button
                    className="EditButton"
                    onClick={() =>
                      this.props.history.push (
                        routes.SERVICEONDEMAND_SERVICE_PROFILE +
                          `/${userProfile.serviceProfile.docID}`
                      )}
                  >
                    View Profile
                  </Button>
                </Col>
                <Col sm="6">
                  <img
                    src={
                      userProfile && userProfile.profilePictureURL
                        ? userProfile.profilePictureURL
                        : profilePlaceholder
                    }
                    nError={e => {
                      e.target.onerror = null;
                      e.target.src = profilePlaceholder;
                    }}
                    className="profileImages floatRight"
                  />
                </Col>

              </Row>
              {/* --------profile Name-------- */}
              <div className=" textCenter bottomBorder">
                <p className="name">
                  {userProfile && userProfile.title ? userProfile.title : ''}
                  {' '}{' '}
                  {' '}
                  {userProfile && userProfile.displayName
                    ? userProfile.displayName
                    : (userProfile && userProfile.firstName
                        ? userProfile.firstName
                        : '') +
                        ' ' +
                        (userProfile && userProfile.lastName
                          ? userProfile.lastName
                          : '')}

                </p>
                <div className="inlineFlex ">
                  <img src={doctorImage} className="dociconClassCard2" />
                  <span className="certificate">
                    {/*Certified Neurologists */}
                  </span>
                </div>
                <div>

                  <StarRatingComponent
                    name={'rating'}
                    starCount={5}
                    value={
                      userProfile &&
                        userProfile.serviceProfile &&
                        userProfile.serviceProfile.totalServicesOffered
                        ? userProfile.serviceProfile.totalServicesOffered
                        : 0
                    }
                    emptyStarColor={'#E4E4E4'}
                    starColor={'#F5D028'}
                    style={{fontSize: '80px'}}
                    // onStarClick={this.onStarClick}
                  />
                </div>

              </div>
              {/* --------Notification Name-------- */}

              {this.props.latestactivity &&
                <div>
                  <div>
                    <p className="notificationTitle">Notifications</p>
                  </div>
                  <div>
                    <p className="latestActivity">LATEST ACTIVITY</p>
                    <div className="activityDiv inlineFlex padd10">
                      <img src={acivityImage} className="AcivityImg" />
                      <div className="w90">
                        <p className="acivityName">Andrew Moore</p>
                        <p className="acivityWork">
                          SERVICE REQUEST submitted{' '}
                        </p>
                      </div>
                      <div className="acivityIconDisplay ">
                        <img src={ForwordImage} className="AcivityIcon" />
                      </div>
                    </div>

                    <div className="activityDiv inlineFlex padd10">
                      <img src={acivityImage} className="AcivityImg" />
                      <div className="w90">
                        <p className="acivityName">Andrew Moore</p>
                        <p className="acivityWork">SERVICE REQUEST </p>
                      </div>
                      <div className="acivityIconDisplay ">
                        <img src={ForwordImage} className="AcivityIcon" />
                      </div>
                    </div>

                    <div className="activityDiv inlineFlex padd10">
                      <img src={acivityImage} className="AcivityImg" />
                      <div className="w90">
                        <p className="acivityName">Andrew Moore</p>
                        <p className="acivityWork">SERVICE REQUEST </p>
                      </div>
                      <div className="acivityIconDisplay ">
                        <img src={ForwordImage} className="AcivityIcon" />
                      </div>
                    </div>
                    <div style={{textAlign: 'right'}}>
                      <Button className="viewAllButton mTop10">
                        View All
                      </Button>
                    </div>

                  </div>
                </div>}

              {this.props.myplacerequest &&
                <div>
                  <div className="divmyplaces">
                    My Placed Requests
                  </div>
                  <div style={{marginTop: 14}}>
                    {myRequestsList.map ((item, i) => (
                      <div className="bldspdreqdiv" key={i}>
                        <div>
                          <div className="bldaytcf">
                            {item && item.serviceDate
                              ? moment (item.serviceDate).format ('DD/MM/YYYY')
                              : ''}
                          </div>
                          <div className="bludknjke">
                            {' '}{item && item.title ? item.title : ''}
                          </div>
                        </div>
                        <div>
                          <div style={{marginRight: 19}}>
                            <Progress
                              value="75"
                              style={{width: 113, height: 7}}
                            />
                          </div>
                          <div className="bplaceded">
                            <div
                              className={
                                item.status === 'draft' ? 'greencircle' : ''
                              }
                            />
                            <div
                              className={
                                item.status === 'awarded' ? 'yellowcircle' : ''
                              }
                            />
                            <div
                              className={
                                item.status === 'accepted' ? 'yellowcircle' : ''
                              }
                            />
                            <div
                              className={
                                item.status === 'job completed'
                                  ? 'orangecircle'
                                  : ''
                              }
                            />
                            <div
                              className={
                                item.status === 'rejected' ? 'orangecircle' : ''
                              }
                            />
                            <div
                              className={
                                item.status === 'pending' ? 'whitecircle' : ''
                              }
                            />
                            <div
                              className={
                                item.status === 'confirmed' ? 'lightgreen' : ''
                              }
                            />
                            <span style={{paddingTop: 2, paddingLeft: 5}}>
                              {item && item.status ? item.status : ''}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}

                  </div>
                  <div style={{textAlign: 'right'}}>
                    <Button className="viewAllButton mTop10">
                      View All
                    </Button>
                  </div>
                </div>}
              {myRequestsRecievedList &&
                myRequestsRecievedList.length > 0 &&
                <div>
                  <div className="vblsuereqre">
                    Requests Received
                  </div>
                  <div style={{marginTop: 15}}>
                    {myRequestsRecievedList.map ((item, index) => (
                      <div key={index} className="requesrecrec">
                        <div>
                          <div className="sssdecfb">
                            {item && item.serviceDate
                              ? moment (item.serviceDate).format ('DD/MM/YYYY')
                              : ''}
                          </div>
                          <div className="recdivjkhfd">
                            {item && item.title ? item.title : ''}
                          </div>
                        </div>
                        <div>
                          <div style={{marginRight: 19}}>
                            <Progress
                              value="75"
                              style={{width: 113, height: 7}}
                            />
                          </div>
                          <div className="bluewarded">
                            <div
                              className={
                                item.status === 'draft' ? 'greencircle' : ''
                              }
                            />
                            <div
                              className={
                                item.status === 'awarded' ? 'yellowcircle' : ''
                              }
                            />
                            <div
                              className={
                                item.status === 'accepted' ? 'yellowcircle' : ''
                              }
                            />
                            <div
                              className={
                                item.status === 'job completed'
                                  ? 'orangecircle'
                                  : ''
                              }
                            />
                            <div
                              className={
                                item.status === 'rejected' ? 'orangecircle' : ''
                              }
                            />
                            <div
                              className={
                                item.status === 'pending' ? 'whitecircle' : ''
                              }
                            />
                            <div
                              className={
                                item.status === 'confirmed' ? 'lightgreen' : ''
                              }
                            />

                            <span style={{paddingTop: 2, paddingLeft: 5}}>
                              {item && item.status ? item.status : ''}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}

                  </div>
                  <div style={{textAlign: 'right'}}>
                    <Button className="viewAllButton mTop10">
                      View All
                    </Button>
                  </div>

                </div>}

              {this.props.isSearch &&
                <div>
                  <div className="serchprov">
                    Search Providers
                  </div>
                  <div>
                    <input
                      className="searchprovin"
                      placeholder="Enter search term"
                      name="searchString"
                      value={this.state.searchString}
                      onChange={e =>
                        this.setState (
                          byPropKey ('searchString', e.target.value)
                        )}
                      style={{color: '#FFFFFF'}}
                    />
                  </div>
                  <div className="profilemultipleselect">

                    <MultiSelect
                      maxSelectedLabels={1}
                      placeholder="Select Service Area"
                      value={this.state.Dropdownvalue}
                      options={serviceAreaList}
                      onChange={e => this.setState ({Dropdownvalue: e.value})}
                    />
                  </div>
                  <div className="profilemultipleselect">

                    <MultiSelect
                      maxSelectedLabels={1}
                      placeholder="Select Service Offered"
                      value={this.state.Dropdownvalue2}
                      options={serviceOffered}
                      onChange={e => this.setState ({Dropdownvalue2: e.value})}
                    />

                  </div>

                  
                  <div style={{marginRight:10,textAlign:'right'}}>
                    <Button
                      className="seatbluesi"
                      onClick={() => this.searchServiceProviders ()}
                    >
                      <img alt="" src={bluesidesear} style={{marginRight: 3}} />
                      SEARCH
                    </Button>
                  </div>
                </div>}

              <div className="mylatestfeed">
                MY LATEST FEEDBACK
              </div>
              <div>
                <div className="bluerec">
                  <div>
                    <img alt="" src={acivityImage} className="bluesideimg" />
                  </div>
                  <div className="centerNameBox">
                    <div className="beverlbluesid">
                      Beverly Walsh
                    </div>
                    <div className="bluesreqn">
                      Request #100341
                    </div>
                  </div>
                  <div>
                    <div className="bluesideda">
                      11/24/19
                    </div>
                    <div>
                      <StarRatingComponent
                        name={'rating'}
                        starCount={5}
                        // value={(item && item.serviceData  && item.serviceData.siemens_servicing_total_score) ? item.serviceData.siemens_servicing_total_score:0 }
                        emptyStarColor={'#E4E4E4'}
                        starColor={'#F5D028'}
                        style={{fontSize: '100px'}}
                        // onStarClick={this.onStarClick}
                      />
                    </div>
                  </div>
                </div>
                <div className="bluerec" style={{marginTop: 17}}>
                  <div>
                    <img alt="" src={acivityImage} className="bluesideimg" />
                  </div>
                  <div className="centerNameBox">
                    <div className="beverlbluesid">
                      Beverly Walsh
                    </div>
                    <div className="bluesreqn">
                      Request #100341
                    </div>
                  </div>
                  <div>
                    <div className="bluesideda">
                      11/24/19
                    </div>
                    <div>
                      <StarRatingComponent
                        name={'rating'}
                        starCount={5}
                        // value={(item && item.serviceData  && item.serviceData.siemens_servicing_total_score) ? item.serviceData.siemens_servicing_total_score:0 }
                        emptyStarColor={'#E4E4E4'}
                        starColor={'#F5D028'}
                        style={{fontSize: '100px'}}
                        // onStarClick={this.onStarClick}
                      />
                    </div>
                  </div>
                </div>
                <div className="bluerec" style={{marginTop: 17}}>
                  <div>
                    <img alt="" src={acivityImage} className="bluesideimg" />
                  </div>
                  <div className="centerNameBox">
                    <div className="beverlbluesid">
                      Beverly Walsh
                    </div>
                    <div className="bluesreqn">
                      Request #100341
                    </div>
                  </div>
                  <div>
                    <div className="bluesideda">
                      11/24/19
                    </div>
                    <div>
                      <StarRatingComponent
                        name={'rating'}
                        starCount={5}
                        // value={(item && item.serviceData  && item.serviceData.siemens_servicing_total_score) ? item.serviceData.siemens_servicing_total_score:0 }
                        emptyStarColor={'#E4E4E4'}
                        starColor={'#F5D028'}
                        style={{fontSize: '100px'}}
                        // onStarClick={this.onStarClick}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    serviceProfile: state.serviceOnDemandReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      GetServiceProfile,
      GetServiceProviders,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  ServiceOnDemandProfilePage
);
