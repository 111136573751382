import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import './walletSettings.css'
import { Button, Input } from 'reactstrap'
import PinInput from 'react-pin-input'
import rdeepaysetimg from '../../../images/accountSetting/rdeepaysetimg.png'
import {
  changeWalletPin,
  pinGenerateSentOtp
} from '../../../actions/Paymentaction'
import { createNotification } from '../../../constants/notificationtoast'
import { EncryptFunction } from '../../../utility/encryption'

import greendot from '../../../images/serviceOnDemandMyRequest/greendot.png'
import authsetimg from '../../../images/accountSetting/authsetimg.png'
import resetauthseimg from '../../../images/accountSetting/resetauthseimg.png'
class WalletSetting extends Component {
  constructor (props) {
    super(props)
    this.state = {
      rdeePayPinDiv: false,
      changePinDiv: false,
      changePinStep: 1,
      pinValue: '',
      confimPinValue: '',
      sendmoneyerror: '',
      isSubmit: false,
      email: '',
      verificationOTP: '',
      isSendOTP: false,
      time: {},
      seconds: 0
    }
    this.timer = 0
    this.countDown = this.countDown.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    // sentPinOTP
    if (nextProps) {
      if (nextProps.payment.sentPinOTP) {
        this.setState(
          {
            isSendOTP: true
          },
          () => {
            this.setState(
              {
                time: {},
                seconds: 59
              },
              () => {
                this.startTimer()
              }
            )
          }
        )
      }
      if (nextProps.payment && nextProps.payment.changePin) {
        // console.log (nextProps.payment.changePin);
        this.setState({
          changePinStep: 2,
          changePinDiv: false,
          pinSavedDiv: true
        })
      }
      if (nextProps.payment.sendmoneyerror) {
        this.setState({
          sendmoneyerror: nextProps.payment.sendmoneyerror
        })
      }
    }
  }
  componentDidMount () {
    let loginUser = JSON.parse(localStorage.getItem('loginuser'))

    if (loginUser) {
      this.setState({
        email: loginUser.emailAddress
      })
    }
    let timeLeftVar = this.secondsToTime(this.state.seconds)
    // console.log (timeLeftVar);
    this.setState({ time: timeLeftVar })

    // this.setState (
    //   {
    //     time: {},
    //     seconds: 59,
    //   },
    //   () => {
    //     this.startTimer ();
    //   }
    // );
    this.startTimer()
  }
  secondsToTime (secs) {
    let hours = Math.floor(secs / (60 * 60))

    let divisor_for_minutes = secs % (60 * 60)
    let minutes = Math.floor(divisor_for_minutes / 60)

    let divisor_for_seconds = divisor_for_minutes % 60
    let seconds = Math.ceil(divisor_for_seconds)

    let obj = {
      h: hours,
      m: minutes,
      s: seconds
    }
    return obj
  }
  startTimer () {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000)
    }
  }
  countDown () {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds
    })

    // Check if we're at zero.
    if (seconds == 0) {
      clearInterval(this.timer)
    }
  }

  openRdeePayPinDiv = () => {
    this.setState({
      rdeePayPinDiv: true,
      changePinDiv: false,
      pinSavedDiv: false
    })
  }
  openChangePin = () => {
    this.setState({
      rdeePayPinDiv: false,
      changePinDiv: true,
      changePinStep: 1,
      pinSavedDiv: false
    })
  }
  doChangePin () {
    if (
      this.state.verificationOTP.length === 6 &&
      this.state.pinValue.length === 4
    ) {
      let loginUser = JSON.parse(localStorage.getItem('loginuser'))
      let hashKeyPIN = EncryptFunction(
        loginUser.emailAddress,
        this.state.pinValue
      )

      let sendRequest = {
        uid: loginUser.docID,
        otp: this.state.verificationOTP,
        new_pin: hashKeyPIN
      }
      this.props.changeWalletPin(sendRequest)
    }
  }
  doGenerateOTP () {
    // console.log(this.state.isSendOTP)

    if (this.state.isSendOTP) {
      this.setState(
        {
          isSubmit: true
        },
        () => {
          alert('jj')

          if (this.state.verificationOTP) {
            this.setState({
              changePinStep: 2
            })
          }
        }
      )
    } else {
      let sendRequest = { data: {} }
      this.props.pinGenerateSentOtp(sendRequest)
    }
  }
  render () {
    const {
      rdeePayPinDiv,
      changePinDiv,
      changePinStep,
      pinSavedDiv,
      isSendOTP,
      isSubmit
    } = this.state
    return (
      <div>
        <div style={{ display: 'flex' }}>
          <div className='walletsettinmaindiv'>
            <div className='heaallesetingr'> WALLET SETTINGS</div>
            {/* <div
              style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
              onClick={this.openRdeePayPinDiv}
            >
              <img
                alt=""
                src={rdeepaysetimg}
                style={{
                  width: 30,
                  height: 31,
                  marginLeft: 43,
                }}
              />
              <div>
                <p className="RDEEPayPIsefr">R-DEE Pay PIN</p>
                <p className="setkfhEnabled">
                  <img alt="" src={greendot} />Enabled
                </p>
              </div>
            </div> */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                alt='rdeepaysetimg'
                src={rdeepaysetimg}
                style={{
                  width: 30,
                  height: 31,
                  marginLeft: 43
                }}
              />
              <div style={{ cursor: 'pointer' }} onClick={this.openChangePin}>
                <p className='RDEEPayPIsefr'>Change Settle Pay PIN</p>
                <p className='setkfhEnabled'>Change a new PIN</p>
              </div>
            </div>
            {/* <div style={{display: 'flex', alignItems: 'center'}}>
              <img
                alt=""
                src={authsetimg}
                style={{
                  width: 30,
                  height: 31,
                  marginLeft: 43,
                }}
              />
              <div>
                <p className="Authenticationtype">
                  Authentication Type
                </p>
                <p className="settingAuthentication">
                  Change your Authentication Type
                </p>
              </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <img
                alt=""
                src={resetauthseimg}
                style={{
                  width: 30,
                  height: 31,
                  marginLeft: 43,
                }}
              />
              <div>
                <p className="ResetcsdAuth">
                  Reset Authentication
                </p>
                <p className="Resettodefau">
                  Reset to default Authentication
                </p>
              </div>
              </div> */}
          </div>
          {rdeePayPinDiv && (
            <div className='rdeepaypaindiv'>
              <div className='hepaypinesetingr'> Settle PIN</div>
              <div className='walletonoff'>
                OFF <span>/ON</span>
              </div>
              <div style={{ display: 'flex' }}>
                <p className='RDEEscdscdPayPIN'>Settle PIN is Enabled</p>
              </div>
              <div style={{ display: 'flex' }}>
                <p className='RDEEscdscdPayPIN'>
                Settle  PIN is Disabled (OFF STATE)
                </p>
              </div>
              <div style={{ textAlign: 'center' }}>
                <Button className='rdsinsavebutton'>Save Changes</Button>
              </div>
            </div>
          )}
          {changePinDiv && (
            <div className='changenewpindivset'>
              <div className='heaallesetingr'> CHANGE PIN</div>
              {changePinStep === 1 && (
                <div>
                  {isSendOTP ? (
                    <p className='EnterNsihodelfe'>
                      Your OTP has been sent to your email.Enter the code and
                      your new PIN below.
                    </p>
                  ) : (
                    <p className='EnterNsihodelfe'>
                      We will send a code or One Time Password (OTP) to your
                      email to reset your PIN.
                    </p>
                  )}

                  {isSendOTP && (
                    <div style={{ marginTop: 10 }}>
                      <p className='changepinotplabel'>Enter OTP</p>
                      <div style={{ display: 'inline-flex' }}>
                        <Input
                          className='OTPCofirmField'
                          type='text'
                          name='enterotp'
                          id='enterotp'
                          placeholder='Enter 6 digit code'
                          value={this.state.verificationOTP}
                          onChange={e => {
                            this.setState(
                              {
                                verificationOTP: e.target.value,
                                isSubmit: true
                              },
                              () => {
                                this.doChangePin()
                              }
                            )
                          }}
                        />{' '}
                        <div className='otpellipse'>
                          <span style={{ fontWeight: 'bold' }}>
                            {' '}
                            {this.state.time.s}
                          </span>{' '}
                          s
                        </div>
                      </div>

                      {!this.state.verificationOTP && isSubmit && (
                        <p className='validationerrorMessage changepinotplabel'>
                          {' '}
                          OTP is required *{' '}
                        </p>
                      )}
                      {this.state.time.s > 0 && (
                        <div>
                          <p
                            className='changepinotplabel'
                            style={{ marginBottom: 0 }}
                          >
                            Enter New PIN
                          </p>

                          <PinInput
                            length={4}
                            focus={false}
                            secret
                            ref={p => (this.pin = p)}
                            type='numeric'
                            style={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                            inputStyle={{
                              borderColor: 'transparent',
                              borderBottomColor: '#4AEA87',
                              borderBottomWidth: 2,
                              borderTopWidth: 0,
                              borderRadius: 0,
                              color: '#FFFFFF'
                            }}
                            inputFocusStyle={{
                              borderBottomColor: '#4AEA87',
                              color: '#FFFFFF'
                            }}
                            onComplete={(value, index) => {
                              this.setState(
                                {
                                  pinValue: value,
                                  isSubmit: true
                                },
                                () => {
                                  this.doChangePin()
                                }
                              )
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}

                  <div style={{ textAlign: 'center', marginTop: 10 }}>
                    {this.state.time.s === 0 && isSendOTP && (
                      <Button
                        className='restpasbut'
                        onClick={() => {
                          this.timer = 0
                          this.setState(
                            {
                              isSendOTP: false
                            },
                            () => {
                              this.doGenerateOTP()
                            }
                          )
                        }}
                      >
                        Resend OTP
                      </Button>
                    )}
                    {!isSendOTP && (
                      <Button
                        className='settsavenesbutton'
                        onClick={() => this.doGenerateOTP()}
                      >
                        Send OTP to Email
                      </Button>
                    )}
                  </div>
                </div>
              )}
              {changePinStep === 2 && (
                <div>
                  <p className='EnterNsihodelfe'>Enter New PIN</p>
                  <div>
                    <PinInput
                      length={4}
                      focus
                      secret
                      ref={p => (this.pin = p)}
                      type='numeric'
                      style={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                      inputStyle={{
                        borderColor: 'transparent',
                        borderBottomColor: '#4AEA87',
                        borderBottomWidth: 2,
                        borderTopWidth: 0,
                        borderRadius: 0,
                        color: '#FFFFFF'
                      }}
                      inputFocusStyle={{
                        borderBottomColor: '#4AEA87',
                        color: '#FFFFFF'
                      }}
                      onComplete={(value, index) => {
                        this.setState(
                          {
                            pinValue: value
                          },
                          () => {
                            this.setState({
                              changePinStep: 3
                            })
                          }
                        )
                      }}
                    />
                  </div>
                </div>
              )}
              {changePinStep === 3 && (
                <div>
                  <p className='EnterNsihodelfe'>Confirm New PIN</p>
                  <div>
                    <PinInput
                      length={4}
                      focus
                      secret
                      ref={p => (this.pin = p)}
                      type='numeric'
                      style={{
                        padding: '10px',
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                      inputStyle={{
                        borderColor: 'transparent',
                        borderBottomColor: '#4AEA87',
                        borderBottomWidth: 2,
                        borderTopWidth: 0,
                        borderRadius: 0,
                        color: '#FFFFFF'
                      }}
                      inputFocusStyle={{
                        borderBottomColor: '#4AEA87',
                        color: '#FFFFFF'
                      }}
                      onComplete={(value, index) => {
                        this.setState(
                          {
                            confimPinValue: value
                          },
                          () => {
                            if (
                              this.state.confimPinValue !== this.state.pinValue
                            ) {
                              createNotification('error', 'Invalid Confirm Pin')
                              return
                            }
                            this.doChangePin()
                          }
                        )
                      }}
                    />
                    <p className='errorMessage'>
                      {this.state.sendmoneyerror
                        ? this.state.sendmoneyerror
                        : null}
                      {/* you have entered your PIN incorrectly more than 5 times. your PIN has been reset. kidly email support@radiologex.com to proceed further */}
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
          {pinSavedDiv && (
            <div className='pinSavedDivsetvf'>
              <div className='heaallesetingr'> CHANGE PIN</div>
              <div style={{ textAlign: 'center' }}>
                <img
                  alt=''
                  src={rdeepaysetimg}
                  style={{
                    width: 78,
                    height: 85,
                    marginTop: 31
                  }}
                />
              </div>
              <p className='newpinsacekj'>New PIN Saved!</p>
              <p className='YounescdwRDE'>
                You new Settle PIN has been updated successfully.
              </p>
            </div>
          )}
        </div>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    payment: state.PaymentReducer
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      changeWalletPin,
      pinGenerateSentOtp
    },
    dispatch
  )
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletSetting)
