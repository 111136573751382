import {LOGOUT} from './Authreducer';

let initialState = {
  highLightList: [],
};

export const GET_HIGHLIGHT_LIST = 'GET_HIGHLIGHT_LIST';

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_HIGHLIGHT_LIST:
      return {...state, highLightList: action.payload};
    case LOGOUT:
      return {...initialState};
    default:
      return state;
  }
};
