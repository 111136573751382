import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
// import { SignInLink } from "./SignIn";
import {
  Button,
  Modal,
  ModalBody,
} from 'reactstrap';
import ServiceOnDemandSidebarPage
  from '../../../../components/serviceOnDemandSidebar/serviceOnDemandSidebar';
import provider1 from '../../../../images/provider1.png';
import Ellipse1 from '../../../../images/Ellipse1.png';
import settingCard1 from '../../../../images/serviceOnDemand/settingCard1.png';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import './createServiceProfile.css';
import close_icon from '../../../../images/close_icon.png';
import requestimg
  from '../../../../images/serviceOnDemandMyRequest/requestimg.png';
import * as routes from '../../../../constants/routes';
// import { CreateServiceProfile } from '../../../../actions/serviceOnDemandAction';
import ServiceOnDemandCommonComponent
  from '../../../Service-On-Demand-CommanComponent/Service-On-Demand-CommanComponent';

import {
  CreateServiceProfile,
  AddServiceOffered,
  GetServiceProfile,
} from '../../../../actions/serviceOnDemandAction';
import serviceAreaList from '../../../../constants/localJSON/serviceAreaList.json';
import {Dropdown} from 'primereact/dropdown';



const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class serviceOnDemandCreateServiceProfilePage extends Component {
  constructor (props) {
    super (props);
    this.state = {
      addserviceModal: false,
      showAddDataView: true,
      congratsModal: false,
      saveprochangesModal: false,
      aboutMe: '',
      serviceArea: '',
      serviceOffered: '',
      pricerate: '',
      // Dropdownvalue: ' Service Area',
      servicesOffered: [],
      isSubmit: false,
      profileData:[],
      selectedServiceArea:'',
      isCreateProfile:'',
      isAddnewService:false,
      loginUserid:''
    };
  }
  componentWillReceiveProps (nextProps) {
  
    if (nextProps) {
      if (
        nextProps &&
        nextProps.userServiceProfile &&
        nextProps.userServiceProfile
      ) {

        this.setState({
          isCreateProfile:nextProps.userServiceProfile.serviceProfile.hasServiceProfile
        },()=>{
      
        })
        if( nextProps.userServiceProfile.serviceProfile &&  nextProps.userServiceProfile.serviceProfile.serviceProfile){

        this.setState (
          {
            profileData: nextProps.userServiceProfile.serviceProfile.serviceProfile
          },
          () => {
            this.setState({
              aboutMe: this.state.profileData.aboutMe,
              selectedServiceArea:  this.state.profileData.serviceArea,
              serviceOffered:'',
              pricerate:'',
              isAddnewService: false,
              addserviceModal: false
            })
           
          }
        );
      }

      if (
        nextProps &&
        nextProps.userServiceProfile.serviceProfile&&
        nextProps.userServiceProfile.serviceProfile.servicesOffered
      ) {
        this.setState (
          {
            servicesOffered: nextProps.userServiceProfile.serviceProfile.servicesOffered
          },
          () => {
           
          }
        );
      }


      }
     
    }
  }
  async componentDidMount () {
    let loginUser = await localStorage.getItem ('loginuser');
    if (loginUser) {
      
      let getuserData = JSON.parse (loginUser);
      this.setState({
        loginUserid: getuserData.docID
      })
      let sendRequest = {
        data: {
          docID: getuserData.docID,
        },
      };
      this.props.GetServiceProfile (sendRequest);

    }
  }
  openAddServiceModal = () => {
    this.setState ({addserviceModal: true});
  };
  closeAddServiceModal = () => {
    this.setState ({addserviceModal: false});
  };
  showAddDataView = () => {
    this.setState ({addserviceModal: false});
    this.setState ({showAddDataView: true});
  };
  openCongratsModal = () => {
    this.setState ({congratsModal: true});
  };
  closeCongratsModal = () => {
    this.setState ({congratsModal: false});
  };
  openSaveProChanges = () => {
    this.setState ({saveprochangesModal: true});
  };
  closeSaveProChanges = () => {
    this.setState ({saveprochangesModal: false});
  };
 


  createProfile = () => {
    this.setState ({isSubmit: true}, () => {
      const {selectedServiceArea, aboutMe} = this.state;
      const isInvalid = selectedServiceArea === '' || aboutMe === '';
      if (isInvalid) {
        return false;
      }
      let request = {
        data: {
          aboutMe: aboutMe,
          serviceArea: selectedServiceArea,
          searchable: 'true'
        },
      };
     

      this.props.CreateServiceProfile (request);
      this.setState ({
        aboutMe:aboutMe, 
        serviceArea: selectedServiceArea
      });
    });
  };
  AddServiceOffered = () => {
    const {serviceOffered, pricerate } = this.state;

    this.setState({
      isAddnewService:true
    },()=>{

      const isInvalid = serviceOffered === '' || pricerate === '';
        if (isInvalid) {
          return false;
        }

        let request = {
          data: {
            serviceOffered: this.state.serviceOffered,
            priceRate: this.state.pricerate,
          },
        };


        this.props.AddServiceOffered(request,this.state.loginUserid);
        // this.setState({serviceOffered:'',pricerate:'', isAddnewService: false});

    })
   
    // this.setState ({addserviceModal: false,showAddDataView:true});

    // this.setState ({showAddDataView: true});
  };
  render () {
    const {serviceOffered, pricerate ,isAddnewService} = this.state;
    return (
      <div style={{display: 'flex', background: '#FFFFFF', height: '100%'}}>

        <div className="servicreamaidi">
          <div style={{width: 230}}>
            <ServiceOnDemandCommonComponent />
          </div>
          <div className="cSERVICEROVIDER">SERVICE PROVIDER DASHBOARD</div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div className="crePROVIDESERVICE">PROVIDE A SERVICE</div>
            <div
              className="cregoback"
              onClick={() => this.props.history.goBack ()}
            >
              <div> <img alt="" src={provider1} /></div>
              <span> GO BACK </span>
            </div>
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div>
              <div className="creProfileSetup">
              {this.state.isCreateProfile ? 'Edit My Provider Profile':  'Profile Setup'}
              </div>
              <div className="crrerofileDetails">Profile Details:</div>
              <div>
                <div className="creAboutMe">About Me*</div>
                <textarea
                  value={this.state.aboutMe}
                  className="cretextarea"
                  placeholder="(100 Characters)"
                  onChange={e =>
                    this.setState (byPropKey ('aboutMe', e.target.value))}
                />
                {!this.state.aboutMe &&
                  this.state.isSubmit &&
                  <p className="validationerrorMessage">
                    About Me  is required *
                  </p>}
                <div className="profileSetup">
                  {' '}
                  <Dropdown  placeholder="Select Service Area" value={this.state.selectedServiceArea} options={serviceAreaList} onChange={(e) => this.setState({selectedServiceArea: e.value})} />
                  
                </div>
                {!this.state.selectedServiceArea &&
                  this.state.isSubmit &&
                  <p className="validationerrorMessage">
                    Service Area is required *
                  </p>}
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div className="crweServicesOffer">Services Offered:</div>
                <div>
                  <Button
                    className="creaddnewbut"
                    onClick={this.openAddServiceModal}
                  >
                    ADD NEW
                  </Button>
                </div>
              </div>
              {this.state.servicesOffered.length === 0 &&
                <div className="crenorvicesadd">No services added</div>}
            </div>
            <div>
              <div>
                <div className="creIncludeM">
                  Include My Profile in Directory
                </div>
                {/* <div><ButtonToggle className="cretoglebut"></ButtonToggle></div>*/}
              </div>
            </div>
          </div>
          {this.state.showAddDataView === true &&
            <div>
              {this.state.servicesOffered.length > 0 &&
                <div className="crexzReviews">Ratings & Reviews</div>}
              {this.state.servicesOffered &&
                this.state.servicesOffered.length > 0 &&
                this.state.servicesOffered.map ((item, index) => (
                  <div key={index}>

                    <div className="creADdatview">
                      <div className="creadviewt"> {item.serviceOffered} </div>
                      <div className="creaddperhour"> 
                     
                      USD ${item.priceRate} per hour
                      </div>
                      <div className="creServicesPro0">
                        <img alt="" src={settingCard1} style={{width: 24}} />
                        0 Services Provided{' '}
                      </div>
                      <div className="creatingsadded">No ratings added yet</div>
                      <div><img alt="" src={Ellipse1} /></div>
                    </div>
                  </div>
                ))}
              <div style={{textAlign: 'center'}}>
                <Button
                  className="submitProfileButton"
                  onClick={() => this.createProfile ()}
                >
                  Submit Profile
                  <div className="submitProfileImg">
                    <img alt="img" src={requestimg} />
                  </div>
                </Button>
              </div>

            </div>}
          <Modal
            // size="sm"
            isOpen={this.state.addserviceModal}
            toggle={this.closeAddServiceModal}
            centered={true}
          >
            <ModalBody className="addservicemodal">
              <div onClick={this.closeAddServiceModal}>
                <img
                  alt=""
                  src={close_icon}
                  style={{width: 25, float: 'right', cursor: 'pointer'}}
                />
              </div>
              <div className="creAddSe">Add Service</div>
              <div
                style={{textAlign: 'center'}}
                className="crereateperin_outer"
              >
                <input
                  className="addxermod"
                  placeholder="Service Offered"
                  value={this.state.serviceOffered}
                  onChange={e =>
                    this.setState (
                      byPropKey ('serviceOffered', e.target.value)
                    )}
                />
              </div>
              {!serviceOffered && isAddnewService  && <p className="validationErrorMessage"> Service offered is required</p>}
             
              <div style={{textAlign: 'center'}}>
                <input
                  pattern="[0-9]*"
                  className="crereateperin"
                  placeholder="Rate (Per Hour)*"
                  value={this.state.pricerate}
                  onChange={evt => {
                    const pricerate = evt.target.validity.valid
                      ? evt.target.value
                      : this.state.pricerate;
                    this.setState ({
                      pricerate,
                    });
                  }}
                  // onChange={e =>
                  //   this.setState (
                  //     byPropKey ('pricerate', e.target.value)
                  //   )}
                />
              </div>
              {!pricerate && isAddnewService  && <p  className="validationErrorMessage"> Rate is required</p>}
              <div>
                <Button className="creasddbut" onClick={this.AddServiceOffered}>
                  ADD
                </Button>
              </div>
            </ModalBody>
          </Modal>
          <Modal
            // size="sm"
            isOpen={this.state.congratsModal}
            toggle={this.closeCongratsModal}
            centered={true}
          >
            <ModalBody className="crecongomoda">
              <div className="moCongratulations">Congratulations!</div>
              <div className="crmoprofilehasbe">
                Your profile has been added to the
                Service-on-Demand provider directory and
                you are discoverable by service seekers.
              </div>
              <div style={{textAlign: 'center'}}>
                <Button
                  className="cremodbutt"
                  onClick={() =>
                    this.props.history.push (
                      routes.SERVICEONDEMAND_SERVICE_PROFILE
                    )}
                >
                  GO TO MY PROFILE{' '}
                </Button>
              </div>
              <div className="creeditmy">Edit My Profile</div>
            </ModalBody>
          </Modal>
          <Modal
            // size="sm"
            isOpen={this.state.saveprochangesModal}
            toggle={this.closeSaveProChanges}
            centered={true}
          >
            <ModalBody className="creprochngmo">
              <div onClick={this.closeSaveProChanges}>
                <img
                  alt=""
                  src={close_icon}
                  style={{width: 25, float: 'right'}}
                />
              </div>
              <div className="cremoaveProf">Save Profile Changes</div>
              <div style={{display: 'flex'}}>
                <Button className="cremodpronobut">NO</Button>
                <Button className="cremodproYESbut">YES</Button>
              </div>
            </ModalBody>
          </Modal>
        </div>
        <ServiceOnDemandSidebarPage history={this.props.history}/>
      </div>
    );
  }
}
const mapStateToProps = state => {
  console.log (state);
  return {
    userServiceProfile: state.serviceOnDemandReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      CreateServiceProfile,
      AddServiceOffered,
      GetServiceProfile,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  serviceOnDemandCreateServiceProfilePage
);
