import store from './../../store';
import firebase from './../../config/firebase';

export const getMessage = data => {
  let returnData;
  const state = store.getState ();
  if (state && state.Authreducer && state.Authreducer.errorList) {
    // returnData=state.Authreducer.errorList[data].responseMessage_en;
    if (state.Authreducer.errorList[data]) {
      returnData = state.Authreducer.errorList[data].responseMessage_en;
    }
  }
  return returnData;
};

// export const checkAvailableAccess = data => {
//   let returnData ;
//   const state = store.getState ();
//   if (
//     state &&
//     state.Authreducer &&
//     state.Authreducer.checkEnableFeature && 
//     state.Authreducer.checkEnableFeature.length>0
//   ) {
//     // console.log( state.Authreducer.checkEnableFeature);
//     for (let item of state.Authreducer.checkEnableFeature) {
//       if (item.moduleName.toLowerCase() === data.toLowerCase()) {
//         item.comingSoon=false;
//         returnData = item;
//       }
//     }
//   }
//   return returnData;
// };


export const checkAvailableAccess = data => {
  let returnData ;
  const state = store.getState ();
  if (
    state &&
    state.Authreducer &&
    state.Authreducer.checkEnableFeature && 
    state.Authreducer.checkEnableFeature
  ) {
    // console.log( state.Authreducer.checkEnableFeature);
    // console.log(data);
    if(data && state.Authreducer.checkEnableFeature[data]){
      returnData = state.Authreducer.checkEnableFeature[data];
      // if(data==='messenger'){
      //   returnData="not_available";
      // }
    }
  }
  return returnData;
};

export const updateKYCNode = (data) => {
  const database = firebase.database();
  let obj = {
    lastTimeUpdate: Math.floor(Date.now()),
    ButtonState: data.ButtonState,
    ButtonText: data.ButtonText,
    OnfidoStatus: data.OnfidoStatus,
    Userid:data.userid,
  }
  database.ref(`kycstate/${data.userid}`).update(obj);

};



