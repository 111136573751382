import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Modal, ModalBody } from 'reactstrap'
import { Carousel } from 'react-responsive-carousel'
import StarRatingComponent from 'react-star-rating-component'
import './placeOrderDetails.css'
import {
  getMarketPlaceOrder,
  marketplaceOrderShippedStatus,
  payOrderItem,
  resetdoOrderValue,
  marketplaceOrderDeliveredStatus,
  buyerGiveReviewToseller,
  cancelPlaceOrder,
  doCompleteOrderItem,
  doMarketplaceOrderPaidStatus,
  marketPlaceRaisedDisputeBuyerSide,
  marketPlaceOrderReturnedBuyerSide,
  marketPlaceReturnedCompleteBuyerSide,
  resetOrderList
} from '../../../../../actions/marketplaceaction'
import leftbackarrow from '../../../../../images/icons/leftbackarrow.png'
import fileRemoveIcon from '../../../../../images/marketPlaceBuyer/fileRemoveIcon.svg'
import copyIcon from '../../../../../images/marketPlaceBuyer/copyIcon.svg'

import profilePlaceholder from '../../../../../images/profile_placeholder.png'
import downloadInvoice from '../../../../../images/marketPlaceBuyer/downloadInvoice.svg'

import dropdownpro from '../../../../../images/marketPlaceBuyer/dropdownpro.png'
import close_icon from '../../../../../images/close_icon.png'
import sendMoney from '../../../../../images/icons/sendMoney.png'
import PinInput from 'react-pin-input'
import * as routes from '../../../../../constants/routes'
import { CapitalizeFirstLetter } from '../../../../../constants/helper'

// import markefilter from '../../../images/marketPlaceBuyer/markefilter.png';
import moment from 'moment'
import MarketPlaceSidemenu from '../../marketplaceSidemenu/marketSideMenu'
import placeholderProfile from '../../../../../images/profile/pronew.png'

import productDetails from '../../../../../images/marketPlaceBuyer/productDetails.png'
import nonradioPlaceholder from '../../../../../images/marketPlaceBuyer/nonradioPlaceholder.png';


import { getTransactionHistory } from '../../../../../actions/Paymentaction'
import { EncryptFunction } from '../../../../../utility/encryption'
import { dataTimeFormat } from '../../../../../utility/convertTimeString'
import RightSideContainer from '../../../../../components/UI/rightSideContainer/rightSideContainer'
import { getProfileDashboard } from '../../../../../actions/Authaction'
import {
  priceWithDecimal,
  shippingTrackingURL
} from '../../../../../utility/commonFunctions'
import ProductZoomDetail from '../../../../UI/productZoom/productZoom'
import { ShowNameAccordingToLength } from '../../../../../utility/stringFunctions'
import { saveAs } from 'file-saver'
import { createNotification } from '../../../../../constants/notificationtoast'
// import { Dropdown } from 'primereact/dropdown'
import SelectInput from '../../../../InputCustom/selectInput/selectDropdown'


class PlacedOrderDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      specification: true,
      logistics: true,
      titleInfo: true,
      inspection: true,
      overView: true,
      otherInfo: true,
      dimentions: true,
      compliance: true,
      condition: true,
      orderDetail: '',
      productDetail: [],
      reputationDetails: '',
      payForOrderModal: false,
      pinModal: false,
      orderPaidModal: false,
      userBalance: '',
      rdCredit: '',
      pinValue: '',
      payOrderError: '',
      confirmDelivery: false,
      leaveFeedBack: false,
      rating: 0,
      reviewText: '',
      leaveFeedsuccess: false,
      cancelOrder: false,
      transfer_purposes: '',
      productCategoryType: '',
      imagePreviewModal: false,
      imgIndex: 0,
      viewInvoiceModal: false,
      paymentPreviewModal: false,
      selectPaymentMethod: false,
      paymentUploadInvoiceModal: false,
      previewPayInvoiceFile: '',
      selectedPayInvoiceFile: '',
      trackShippingInfo: false,
      copyText: false,
      raisedDispute: false,
      disputeReason: '',
      viewraisedDispute: false,
      returnShippingInfo: false,
      selectedShipmentReceip: '',
      previewShipmentReceip: '',
      selectShippingAgent: '',
      confirmRefundPayment: false,
      shippingAgentList: [
        {
          label: 'FedEx',
          value: 'FedEx'
        },
        {
          label: 'DHL',
          value: 'DHL'
        },
        {
          label: 'UPS',
          value: 'UPS'
        },
        {
          label: 'Aramex',
          value: 'Aramex'
        }
      ],
      selectedRefundReceip: '',
      previewRefundReceip: '',
      trackingNumber: '',
      trackingURL: ''
    }
    this.closeModal = this.closeModal.bind(this)
    this.showImage = this.showImage.bind(this)
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if (
        nextProps &&
        nextProps.product &&
        nextProps.product.placeCancelOrder
      ) {
        this.props.history.goBack()
      }

      if (nextProps.product && nextProps.product.buyerFeedback) {
        this.setState({
          leaveFeedBack: false,
          leaveFeedsuccess: true
        })
      }
      if (nextProps.product && nextProps.product.payOrderError) {
        this.setState({
          payOrderError: nextProps.product.payOrderError
          // modal: false,
          // orderConfirmation: false,
          // pay: false,
        })
      }
      if (nextProps.product && nextProps.product.payOrdersuccess) {
        this.setState({
          payOrdersuccess: nextProps.product.payOrdersuccess,
          pinModal: false,
          orderPaidModal: true,
          paymentPreviewModal: false,
          selectPaymentMethod: false,
          paymentUploadInvoiceModal: false,
          previewPayInvoiceFile: '',
          selectedPayInvoiceFile: ''
        })
      }

      if (nextProps && nextProps.payment) {
        // nextProps.payment.history.balance.amount =
        // nextProps.payment.history.balance.amount =

        // console.log(nextProps.payment.history.rdeeBalance.amount);

        // if (nextProps.payment.history && nextProps.payment.history.rdeeBalance) {
        //   nextProps.payment.history.rdeeBalance.amount =
        //     nextProps.payment.history.rdeeBalance.amount -
        //     Number (this.state.transactionAmount);
        // }

        // if (nextProps.payment.history && nextProps.payment.history.balance) {
        //   nextProps.payment.history.balance.amount =
        //     nextProps.payment.history.balance.amount -
        //     Number (this.state.transactionAmount);
        // }

        this.setState({
          userBalance:
            nextProps.payment.history && nextProps.payment.history.balance
              ? nextProps.payment.history.balance
              : '',
          rdCredit:
            nextProps.payment.history && nextProps.payment.history.rdeeBalance
              ? nextProps.payment.history.rdeeBalance
              : ''
        })
      }
      if (nextProps && nextProps.product && nextProps.product.placeOrderList) {
        this.setState(
          {
            orderDetail: nextProps.product.placeOrderList,
            confirmDelivery: false
          },
          () => {
            if (
              this.state.orderDetail.productDetails &&
              this.state.orderDetail.productDetails[0]
            ) {
              this.setState({
                productCategoryType: this.state.orderDetail.productDetails[0]
                  .productCategoryType
              })
            }
            this.setState({
              productDetail: this.state.orderDetail,
              reputationDetails: this.state.orderDetail.reputationDetails,
              cancelOrder: false,
              raisedDispute: false,
              returnShippingInfo: false,
              confirmRefundPayment: false
            })
            // this.setState({
            //   reputationDetails:this.state.orderDetail.reputationDetails
            // })
            // console.log (this.state.orderDetail);
          }
        )
      }
    }
  }
  componentDidMount () {
    this.props.resetdoOrderValue();

    if (this.props.match.params && this.props.match.params.orderId) {
      let sendRequestUserProfile = {
        data: {}
      }
      this.props.getProfileDashboard(sendRequestUserProfile)
      this.props.resetOrderList();
      let sendRequest = {
        data: {
          docID: this.props.match.params.orderId
        }
      }
      this.props.getMarketPlaceOrder(sendRequest)

      let sendRequest1 = {
        data: {
          limit: 0,
          offset: 0
        }
      }
      this.props.getTransactionHistory(sendRequest1)
    }
  }
  openDetailDropDown (status) {
    if (status === 'specification') {
      this.setState({
        specification: !this.state.specification
      })
    } else if (status === 'logistics') {
      this.setState({
        logistics: !this.state.logistics
      })
    } else if (status === 'titleInfo') {
      this.setState({
        titleInfo: !this.state.titleInfo
      })
    } else if (status === 'overView') {
      this.setState({
        overView: !this.state.overView
      })
    } else if (status === 'otherInfo') {
      this.setState({
        otherInfo: !this.state.otherInfo
      })
    } else if (status === 'dimentions') {
      this.setState({
        dimentions: !this.state.dimentions
      })
    } else if (status === 'compliance') {
      this.setState({
        compliance: !this.state.compliance
      })
    } else if (status === 'condition') {
      this.setState({
        condition: !this.state.condition
      })
    } else {
      this.setState({
        inspection: !this.state.inspection
      })
    }
  }

  closeModal () {
    this.setState({
      payForOrderModal: false,
      pinModal: false,
      orderPaidModal: false,
      confirmDelivery: false,
      leaveFeedBack: false,
      leaveFeedsuccess: false,
      cancelOrder: false,
      imagePreviewModal: false,
      viewInvoiceModal: false,
      paymentPreviewModal: false,
      selectPaymentMethod: false,
      paymentUploadInvoiceModal: false,
      trackShippingInfo: false,
      raisedDispute: false,
      viewraisedDispute: false,
      confirmRefundPayment: false
    })
    this.props.resetdoOrderValue()
  }

  doPlaceOrder () {
    const {
      orderDetail,
      productDetail,
      selectedPayInvoiceFile,
      previewPayInvoiceFile
    } = this.state
    let loginUser = JSON.parse(localStorage.getItem('loginuser'))

    let paramsAddNotiHistory = {
      data: {
        source: 'rdeemarketplace', // "sourceModule" for push notifiaction and "source" for notification history
        subject: (selectedPayInvoiceFile && selectedPayInvoiceFile.name
          ? ('Payment initiated for Order ' + orderDetail.orderID+'!'):('Order ' + orderDetail.orderID+' payment complete!')),
        body: ((loginUser.title+' ') +ShowNameAccordingToLength(
            loginUser.firstName
              ? loginUser.firstName + ' '
              : '' + loginUser && loginUser.lastName
                ? loginUser.lastName
                : ''
          )) +(selectedPayInvoiceFile && selectedPayInvoiceFile.name
            ? ' has initiated payment for order' + orderDetail.orderID +'.Click to view the order.'
            : ' has paid USD $' +
            priceWithDecimal(orderDetail.totalOrderPrice) +
            ' for the order ' + orderDetail.orderID +' Funds will be release after buyer receives the item.'),
        imageURL: loginUser.profilePictureURL,
        targetUID: orderDetail.sellerUID,
        titleName:  (selectedPayInvoiceFile && selectedPayInvoiceFile.name
          ? ('Payment initiated for Order ' + orderDetail.orderID+'!'):('Order ' + orderDetail.orderID+' payment complete!')),
        navigateToID: orderDetail.docID,
        orderScreenType: 'seller'
      }
    }

    if (selectedPayInvoiceFile && selectedPayInvoiceFile.name) {
      let sendUploadProofInvoice = {
        data: {
          docID: this.state.productDetail.docID,
          receiptFileName: selectedPayInvoiceFile.name,
          receiptFileType: selectedPayInvoiceFile.type,
          receipt: previewPayInvoiceFile,
          paymentType: 'external'
        }
      }
      // console.log('paid externally')
      this.props.doMarketplaceOrderPaidStatus(
        sendUploadProofInvoice,
        paramsAddNotiHistory
      )
    } else {
      let hashKeyPIN = EncryptFunction(
        loginUser.emailAddress,
        this.state.pinValue
      )

      let sendRequest = {
        seller_address: this.state.productDetail.productDetails[0].sellerWallet,
        buyer_address: this.state.productDetail.buyerWalletAddress,
        passphrase: hashKeyPIN,
        // order_amount: priceWithDecimal(this.state.productDetail.totalOrderPrice),
        order_amount: Number(this.state.productDetail.totalOrderPrice),
        order_id: this.state.productDetail.docID,
        transfer_purposes: this.state.transfer_purposes
      }

      console.log('paid internally')

      this.props.payOrderItem(sendRequest, paramsAddNotiHistory)
    }
  }

  doConfirmExternalShipment () {
    this.setState({
      payOrderError: ''
    })
    const { orderDetail, productDetail } = this.state

    let loginUser = JSON.parse(localStorage.getItem('loginuser'))
    let sendRequest = {
      data: {
        docID: orderDetail.docID
      }
    }
    let paramsAddNotiHistory = {
      data: {
        source: 'rdeemarketplace', // "sourceModule" for push notifiaction and "source" for notification history
        subject: 'Order ' + orderDetail.orderID + ' delivery confirmed!',
        body: ((loginUser.title+' ') +ShowNameAccordingToLength(
          loginUser.firstName
            ? loginUser.firstName + ' '
            : '' + loginUser && loginUser.lastName
              ? loginUser.lastName
              : ''
        ))+
          ' has confirmed that the items in order ' +
          orderDetail.orderID +
          ' were received. Your payment has been released.',
        imageURL: loginUser.profilePictureURL,
        targetUID: orderDetail.sellerUID,
        titleName: 'Order ' + orderDetail.orderID + '  delivery confirmed!',
        navigateToID: orderDetail.docID,
        orderScreenType: 'seller'
      }
    }
    // console.log(sendRequest);
    // console.log(paramsAddNotiHistory);

    this.props.marketplaceOrderDeliveredStatus(
      sendRequest,
      paramsAddNotiHistory
    )
  }

  doConfirmShipment () {
    this.setState({
      payOrderError: ''
    })
    const { orderDetail, productDetail } = this.state

    let loginUser = JSON.parse(localStorage.getItem('loginuser'))
    let hashKeyPIN = EncryptFunction(
      loginUser.emailAddress,
      this.state.pinValue
    )
    let sendRequest = {
      data: {
        docID: orderDetail.docID,
        paymentTransactionID: orderDetail.transactionID,
        sellerPaymentAddress: orderDetail.paymentTransactionID,
        originalEscrowTransactionID: orderDetail.escrowAddress
      }
    }

    let sendCompleteOrder = {
      seller_address: this.state.productDetail.productDetails[0].sellerWallet,
      buyer_address: this.state.productDetail.buyerWalletAddress,
      passphrase: hashKeyPIN,
      order_amount: Number(this.state.productDetail.totalOrderPrice),
      order_id: orderDetail.docID,
      transfer_purposes: this.state.transfer_purposes
    }

    let paramsAddNotiHistory = {
      data: {
        source: 'rdeemarketplace', // "sourceModule" for push notifiaction and "source" for notification history
        subject: 'Order ' + orderDetail.orderID + '  shipment confirmed!',
        body:
          (productDetail.productDetails[0].productCategoryType === 'Radiology'
            ? productDetail.productDetails[0].systemName
            : (productDetail.productDetails[0] && productDetail.productDetails[0].productName?productDetail.productDetails[0].productName:'')) +
          ' ' +
          ((loginUser.title+' ')+ShowNameAccordingToLength(
            loginUser.firstName
              ? loginUser.firstName + ' '
              : '' + loginUser && loginUser.lastName
                ? loginUser.lastName
                : ''
          ) + ' has confirmed that the items in order ' +
          orderDetail.orderID+' were received. Your payment has been released'),
        imageURL: loginUser.profilePictureURL,
        targetUID: orderDetail.sellerUID,
        titleName: 'Order ' + orderDetail.orderID + '  shipment confirmed!',
        navigateToID: orderDetail.docID,
        orderScreenType: 'seller'
      }
    }

    this.props.doCompleteOrderItem(
      sendCompleteOrder,
      sendRequest,
      paramsAddNotiHistory
    )
  }

  _handleKeyVal (receiver) {
    let loginUser = JSON.parse(localStorage.getItem('loginuser'))
    let keyVal = ''
    let userId = loginUser.docID
    if (receiver > userId) {
      keyVal = `${receiver}${userId}`
    } else {
      keyVal = `${userId}${receiver}`
    }
    return keyVal
  }

  goToChat () {
    const { orderDetail } = this.state
    if (
      orderDetail &&
      orderDetail.productDetails &&
      orderDetail.productDetails[0]
    ) {
      const sellerInfo = orderDetail.productDetails[0]

      let userChatInfo = {
        oppositeUserUid: sellerInfo.listedBy,
        profilePictureURL:
          sellerInfo &&
          sellerInfo.sellerDetails &&
          sellerInfo.sellerDetails.profilePictureURL
            ? sellerInfo.sellerDetails.profilePictureURL
            : profilePlaceholder,
        displayName:
          sellerInfo.sellerDetails.firstName +
          ' ' +
          sellerInfo.sellerDetails.lastName,
        chatType: 'singleChat'
      }
      this.props.history.push(routes.MESSENGER, {
        userChatInfo: userChatInfo,
        messengerKey: this._handleKeyVal(sellerInfo.listedBy)
      })
    }
  }

  onStarClick (nextValue, prevValue, name) {
    this.setState({ rating: nextValue })
  }

  doLeaveFeedback () {
    const { orderDetail, productDetail } = this.state
    let loginUser = JSON.parse(localStorage.getItem('loginuser'))

    let sendRequest = {
      data: {
        docID: orderDetail.docID,
        reviewText: this.state.reviewText,
        reviewScore: this.state.rating
      }
    }

    let paramsAddNotiHistory = {
      data: {
        source: 'rdeemarketplace', // "sourceModule" for push notifiaction and "source" for notification history
        subject: 'Order ' + orderDetail.orderID + '  feedback received!',

        body:
          (productDetail.productDetails[0].productCategoryType === 'Radiology'
            ? productDetail.productDetails[0].systemName:(productDetail.productDetails[0]&& productDetail.productDetails[0].productName?productDetail.productDetails[0].productName:''
            )) +
          ' ' +
          ((loginUser.title+' ')+ShowNameAccordingToLength(
            loginUser.firstName
              ? loginUser.firstName + ' '
              : '' + loginUser && loginUser.lastName
                ? loginUser.lastName
                : ''
          ) +
          ' has left feedback for the order ' +
          orderDetail.orderID +
          '.'),
        imageURL: loginUser.profilePictureURL,
        targetUID: orderDetail.sellerUID,
        titleName: 'Order ' + orderDetail.orderID + ' feedback received!',
        navigateToID: orderDetail.docID,
        orderScreenType: 'seller'
      }
    }

    this.props.buyerGiveReviewToseller(sendRequest, paramsAddNotiHistory)
  }

  doCancelOrders () {
    const { orderDetail ,productDetail} = this.state
    let sendRequest = {
      data: {
        docID: orderDetail.docID
      }
    }
    let loginUser = JSON.parse(localStorage.getItem('loginuser'))

    let paramsAddNotiHistory = {
      data: {
        source: 'rdeemarketplace', // "sourceModule" for push notifiaction and "source" for notification history
        subject: 'Order ' + orderDetail.orderID + ' cancelled.',

        body:((loginUser.title+' ')+ShowNameAccordingToLength(
            loginUser.firstName
              ? loginUser.firstName + ' '
              : '' + loginUser && loginUser.lastName
                ? loginUser.lastName
                : ''
          ) +
          ' has cancelled the order ' +
          orderDetail.orderID +
          '.'),
        imageURL: loginUser.profilePictureURL,
        targetUID: orderDetail.sellerUID,
        titleName: 'Order ' + orderDetail.orderID + ' cancelled.',
        navigateToID: orderDetail.docID,
        orderScreenType: 'seller'
      }
    }

    this.props.cancelPlaceOrder(sendRequest,paramsAddNotiHistory)
  }
  showImage (index) {
    this.setState({
      imagePreviewModal: true,
      imgIndex: index
    })
  }
  downloadInvoice () {
    const { orderDetail } = this.state
    saveAs(orderDetail.invoiceURL, orderDetail.invoice)
  }

  onChangeInvoiceselectProof = e => {
    // console.warn ('warning function call details');
    if (e && e.target.files) {
      let files = e.target.files
      let response = files[0].size / 1000000
      // console.log(Math.floor(response));

      if (response && response > 5) {
        createNotification(
          'error',
          'The uploaded file is too large. File can only be a maximum of 5 MB.'
        )
        return false
      }
      console.log(e.target.files[0])

      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.setState({
          previewPayInvoiceFile: reader.result
        })
      })
      reader.readAsDataURL(e.target.files[0])

      this.setState({
        selectedPayInvoiceFile: e.target.files[0]
      })
    }
  }

  onChangeSelectShipmentProof = e => {
    // console.warn ('warning function call details');
    if (e && e.target.files) {
      let files = e.target.files
      let response = files[0].size / 1000000
      // console.log(Math.floor(response));

      if (response && response > 5) {
        createNotification(
          'error',
          'The uploaded file is too large. File can only be a maximum of 5 MB.'
        )
        return false
      }
      console.log(e.target.files[0])

      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.setState({
          previewShipmentReceip: reader.result
        })
      })
      reader.readAsDataURL(e.target.files[0])

      this.setState({
        selectedShipmentReceip: e.target.files[0]
      })
    }
  }

  doBuyerDispute () {
    const { orderDetail, disputeReason, productDetail } = this.state
    let sendRequest = {
      data: {
        docID: orderDetail.docID,
        disputeReason: disputeReason
      }
    }

    let loginUser = JSON.parse(localStorage.getItem('loginuser'))

    let paramsAddNotiHistory = {
      data: {
        source: 'rdeemarketplace', // "sourceModule" for push notifiaction and "source" for notification history
        subject: 'Dispute raised for Order ' + orderDetail.orderID ,

        body: ((loginUser.title+' ')+ShowNameAccordingToLength(
            loginUser.firstName
              ? loginUser.firstName + ' '
              : '' + loginUser && loginUser.lastName
                ? loginUser.lastName
                : ''
          ) +
          ' has  raised dispute for the order ' +
          orderDetail.orderID +
          '.Click to view the order.'),
        imageURL: loginUser.profilePictureURL,
        targetUID: orderDetail.sellerUID,
        titleName: 'Dispute raised for Order ' + orderDetail.orderID,
        navigateToID: orderDetail.docID,
        orderScreenType: 'seller'
      }
    }

    this.props.marketPlaceRaisedDisputeBuyerSide(
      sendRequest,
      paramsAddNotiHistory
    )
  }

  doRefundOrderProduct () {
    const {
      orderDetail,
      selectedShipmentReceip,
      previewShipmentReceip,
      selectShippingAgent,
      trackingNumber,
      trackingURL,
      productDetail
    } = this.state
    let sendRequest = {
      data: {
        docID: orderDetail.docID,
        shippingCompany: selectShippingAgent,
        trackingNumber: trackingNumber,
        trackingURL: trackingURL,
        shippingDocumentFileName: selectedShipmentReceip.name,
        shippingDocumentFileType: selectedShipmentReceip.type,
        shippingDocument: previewShipmentReceip
      }
    }
    // console.log(sendRequest.data)

    let loginUser = JSON.parse(localStorage.getItem('loginuser'))

    let paramsAddNotiHistory = {
      data: {
        source: 'rdeemarketplace', // "sourceModule" for push notifiaction and "source" for notification history
        subject: 'Order ' + orderDetail.orderID + ' shipped back.',

        body:((loginUser.title+' ')+ShowNameAccordingToLength(
            loginUser.firstName
              ? loginUser.firstName + ' '
              : '' + loginUser && loginUser.lastName
                ? loginUser.lastName
                : ''
          ) +
          ' has shipped back the order ' +
          orderDetail.orderID +
          '.Click to view the order'),
        imageURL: loginUser.profilePictureURL,
        targetUID: orderDetail.sellerUID,
        titleName: 'Order ' + orderDetail.orderID + ' shipped back.',
        navigateToID: orderDetail.docID,
        orderScreenType: 'seller'
      }
    }
    this.props.marketPlaceOrderReturnedBuyerSide(
      sendRequest,
      paramsAddNotiHistory
    )
  }

  onChangeSelectRefundProof = e => {
    // console.warn ('warning function call details');
    if (e && e.target.files) {
      let files = e.target.files
      let response = files[0].size / 1000000
      // console.log(Math.floor(response));

      if (response && response > 5) {
        createNotification(
          'error',
          'The uploaded file is too large. File can only be a maximum of 5 MB.'
        )
        return false
      }

      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.setState({
          previewRefundReceip: reader.result
        })
      })
      reader.readAsDataURL(e.target.files[0])

      this.setState({
        selectedRefundReceip: e.target.files[0]
      })
    }
  }

  updateTrackURL () {
    const { trackingNumber, selectShippingAgent } = this.state
    if (selectShippingAgent && trackingNumber && trackingNumber.length > 0) {
      this.setState({
        trackingURL: shippingTrackingURL(selectShippingAgent) + trackingNumber
      })
    } else {
      this.setState({
        trackingURL: ''
      })
    }
  }

  render () {
    const {
      productDetail,
      orderDetail,
      reputationDetails,
      userBalance,
      rdCredit,
      transfer_purposes,
      productCategoryType,
      imagePreviewModal,
      imgIndex,
      viewInvoiceModal,
      paymentPreviewModal,
      selectPaymentMethod,
      paymentUploadInvoiceModal,
      selectedPayInvoiceFile,
      previewPayInvoiceFile,
      raisedDispute,
      disputeReason,
      viewraisedDispute,
      returnShippingInfo,
      selectedShipmentReceip,
      previewShipmentReceip,
      confirmRefundPayment,
      selectedRefundReceip,
      previewRefundReceip
    } = this.state

    return (
      <div className='placeorderDEtailContainer' id='placeorderDetailPage'>
        <div>
          <div style={{ textAlign: 'right' }} id='placeorderDetailPage1'>
            <Button
              className='placeorderdetailsbackbutton'
              onClick={() => this.props.history.goBack()}
              id='placeorderDetailPage2'
            >
              <div>
                <img src={leftbackarrow} alt='backIcon' />
              </div>
              Go <span> Back</span>
            </Button>
          </div>
          <div className='placedetailmaindiv' id='placeorderDetailPage3'>
            <div>
              <div className='placeSiemensAvapro' id='placeorderDetailPage4'>
                {orderDetail &&
                orderDetail.productDetails &&
                orderDetail.productDetails[0] &&
                orderDetail.productDetails[0]
                  ? orderDetail &&
                    orderDetail.productDetails[0].productCategoryType &&
                    orderDetail.productDetails[0].productCategoryType ===
                      'Radiology'
                    ? orderDetail.productDetails[0].deviceSaleName 
                    : (orderDetail &&
                      orderDetail.productDetails &&
                      orderDetail.productDetails[0] &&
                      orderDetail.productDetails[0].productName
                      ? orderDetail.productDetails[0].productName
                      : '')
                  : 'N/A'}

                <p id='placeorderDetailPage5'>
                  {' '}
                  USD${' '}
                  {orderDetail && orderDetail.totalOrderPrice
                    ? priceWithDecimal(orderDetail.totalOrderPrice)
                    : 0}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
                id='placeorderDetailPage6'
              >
                <div className='OrderPlaceDetail' id='placeorderDetailPage7'>
                  <p style={{ margin: 0 }} id='placeorderDetailPage8'>
                    Order #:{' '}
                    {orderDetail && orderDetail.orderID
                      ? orderDetail.orderID
                      : 'N/A'}
                  </p>
                  <p style={{ margin: 0 }} id='placeorderDetailPage9'>
                    Quantity:{' '}
                    {orderDetail && orderDetail.productQuantity
                      ? orderDetail.productQuantity
                      : 0}
                  </p>{' '}
                  <p
                    style={{ margin: 0 }}
                    className='OrderPlaced50PM'
                    id='placeorderDetailPage10'
                  >
                    Order Placed:{' '}
                    {orderDetail && orderDetail.orderDateAtISO
                      ? dataTimeFormat(orderDetail.orderDateAtISO)
                      : 'N/A'}
                  </p>
                  {orderDetail && orderDetail.shippingAddress && (
                    <p className='OrderPlaced50PM' id='placeorderDetailPage10'>
                      Shipping Address:{' '}
                      {orderDetail &&
                      orderDetail.shippingAddress &&
                      orderDetail.shippingAddress.streetAddress
                        ? orderDetail.shippingAddress.streetAddress
                        : ''}
                      {orderDetail &&
                      orderDetail.shippingAddress &&
                      orderDetail.shippingAddress.city
                        ? ', ' + orderDetail.shippingAddress.city
                        : ''}
                      {orderDetail &&
                      orderDetail.shippingAddress &&
                      orderDetail.shippingAddress.state
                        ? ', ' + orderDetail.shippingAddress.state
                        : ''}
                      {orderDetail &&
                      orderDetail.shippingAddress &&
                      orderDetail.shippingAddress.country
                        ? ', ' + orderDetail.shippingAddress.country
                        : ''}
                      {orderDetail &&
                      orderDetail.shippingAddress &&
                      orderDetail.shippingAddress.zipcode
                        ? ', ' + orderDetail.shippingAddress.zipcode
                        : ''}
                    </p>
                  )}
                </div>
                <div>
                  {orderDetail &&
                    orderDetail.orderStatus &&
                    orderDetail.orderStatus === 'seller confirmed' && (
                    <div
                      className='Orderconfcsirmedb'
                      id='placeorderDetailPage11'
                    >
                        Order confirmed by seller. Awaiting payment.{' '}
                    </div>
                  )}

                  {orderDetail &&
                    orderDetail.orderStatus &&
                    orderDetail.orderStatus === 'paid' &&
                    orderDetail.paymentType === 'internal' && (
                    <div id='placeorderDetailPage12'>
                      {' '}
                      <p
                        className='Paymentcompletedt'
                        id='placeorderDetailPage13'
                      >
                          Payment completed to RDEE Escrow.{' '}
                      </p>
                      <p
                        className='Paymentcompletedt'
                        id='placeorderDetailPage14'
                      >
                        {' '}
                          Awaiting seller to confirm shipment.
                      </p>
                    </div>
                  )}

                  {orderDetail &&
                    orderDetail.orderStatus &&
                    orderDetail.paymentType &&
                    orderDetail.orderStatus === 'payment verified' &&
                    orderDetail.paymentType === 'external' && (
                    <div>
                      <p
                        className='Paymentcompletedt'
                        id='placeorderDetailPageew13'
                      >
                          Payment completed to outside RDEE.{' '}
                      </p>
                      <p
                        className='Paymentcompletedt'
                        id='placeorderDetailPage16'
                      >
                        {' '}
                          Awaiting your confirmation of shipment.
                      </p>
                    </div>
                  )}

                  {orderDetail &&
                    orderDetail.orderStatus &&
                    orderDetail.orderStatus === 'shipped' && (
                    <div>
                      <p
                        className='Paymentcompletedt'
                        id='placeorderDetailPage15'
                      >
                          Shipment completed.
                      </p>
                      <p
                        className='Paymentcompletedt'
                        id='placeorderDetailPage16'
                      >
                        {' '}
                          Awaiting your confirmation of delivery.
                      </p>
                    </div>
                  )}

                  {orderDetail &&
                    orderDetail.orderStatus &&
                    orderDetail.orderStatus === 'delivered' && (
                    <div>
                      <p
                        className='Paymentcompletedt'
                        id='placeorderDetailPage17'
                      >
                        {' '}
                          Delivery confirmed.
                      </p>
                      <p
                        className='Paymentcompletedt'
                        id='placeorderDetailPage18'
                      >
                          Please leave feedback for the seller.
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                <Button
                  className='placemessagebuton'
                  onClick={() => this.goToChat()}
                  data-rh='Message to seller'
                  id='placeorderDetailPage19'
                  style={{ marginBottom: 10 }}
                >
                  Message Seller
                </Button>
                {orderDetail &&
                  orderDetail.orderStatus &&
                  orderDetail.orderStatus === 'seller confirmed' && (
                  <Button
                    className='placepayfororderbuton'
                    // onClick={() => this.setState ({payForOrderModal: true})}
                    onClick={() =>
                      this.setState({ paymentPreviewModal: true })
                    }
                    data-rh='Pay for order'
                    id='placeorderDetailPage20'
                  >
                      Pay for Order
                  </Button>
                )}

                {/* {orderDetail && (orderDetail.orderStatus === 'seller confirmed' ||  orderDetail.orderStatus === 'paid') && orderDetail.invoiceURL &&
                    <Button className="placepayfororderbuton" id="placeorderDetailinvoiceURLPage30" onClick={()=>this.setState({viewInvoiceModal:true})}>
                    View Invoice
                  </Button>
                  } */}

                {orderDetail &&
                  orderDetail.orderStatus &&
                  orderDetail.orderStatus === 'paid' && (
                  <Button
                    className='placepayfororderbuton1'
                    id='placeorderDetailPage21'
                  >
                      Payment Sent
                  </Button>
                )}

                {orderDetail &&
                  orderDetail.orderStatus &&
                  orderDetail.orderStatus === 'shipped' && (
                  <Button
                    className='placepayfororderbuton'
                    onClick={() => this.setState({ confirmDelivery: true })}
                    data-rh='Confirm delivery'
                    id='placeorderDetailPage22'
                  >
                      Confirm Delivery
                  </Button>
                )}

                {orderDetail &&
                  orderDetail.orderStatus &&
                  orderDetail.orderStatus === 'shipped' &&
                  orderDetail.trackingNumber && (
                  <Button
                    className='placeOrderTrackbutton'
                    onClick={() => this.setState({ trackShippingInfo: true })}
                    data-rh='Track Order'
                    id='placeordertrackDetailPage22'
                  >
                      Track Order
                  </Button>
                )}

                {orderDetail &&
                  orderDetail.orderStatus &&
                  orderDetail.orderStatus === 'rma issued' && (
                  <Button
                    className='placeOrderTrackbutton'
                    onClick={() =>
                      this.setState({ returnShippingInfo: true })
                    }
                    data-rh='Return Order'
                    id='placeorderreturnDetailPage22'
                  >
                      Return Order
                  </Button>
                )}

                {orderDetail &&
                  orderDetail.orderStatus &&
                  orderDetail.orderStatus === 'refunded' && (
                  <Button
                    className='placepayfororderbuton'
                    onClick={() =>
                      this.setState({ confirmRefundPayment: true })
                    }
                    data-rh='View Refund '
                    id='placeorderconfirmrefundOrderDetailPage22'
                  >
                      View Refund
                  </Button>
                )}
                {orderDetail &&
                  orderDetail.orderStatus &&
                  orderDetail.orderStatus === 'returned' && (
                  <Button
                    // className='ordercancelleddiv'
                    className='placepayfororderbuton1'
                    // onClick={() => this.setState({ returnShippingInfo: true })}
                    data-rh='Order Returned'
                    id='placeorderreturnOrderDetailPage22'
                  >
                      Order Returned
                  </Button>
                )}

                {orderDetail &&
                  orderDetail.orderStatus &&
                  (orderDetail.orderStatus === 'delivered' &&
                    productDetail.sellerReviewed === false) && (
                    <Button
                    className='placepayfororderbuton'
                    onClick={() => this.setState({ leaveFeedBack: true })}
                    data-rh='Leave feedback'
                    id='placeorderDetailPage23'
                  >
                      Leave Feedback
                  </Button>
                )}

                {orderDetail &&
                  orderDetail.orderStatus &&
                  (orderDetail.orderStatus === 'seller confirmed' ||
                    orderDetail.orderStatus === 'ordered') && (
                    <Button
                    className='placecamcelorderbuton'
                    onClick={() =>
                      this.setState({
                        cancelOrder: true
                      })
                    }
                    data-rh='Cancel order'
                    id='placeorderDetailPage24'
                  >
                      Cancel Order
                  </Button>
                )}

                {orderDetail &&
                  orderDetail.orderStatus &&
                  orderDetail.orderStatus === 'delivered' && (
                  <Button
                    className='placepayfororderbuton'
                    onClick={() =>
                      this.setState({
                        raisedDispute: true,
                        disputeReason: ''
                      })
                    }
                    data-rh='Raise Dispute'
                    id='placeorderdisputeDetailPage22'
                  >
                      Raise Dispute
                  </Button>
                )}
                {orderDetail &&
                  orderDetail.disputeReason &&
                  (productDetail &&
                    productDetail.sellerReviewed === false &&
                    orderDetail.orderStatus &&
                    (orderDetail.orderStatus !== 'returned' &&
                      orderDetail.orderStatus !== 'refunded')) && (
                      <Button
                    className='placepayfororderbuton'
                    onClick={() => this.setState({ viewraisedDispute: true })}
                    data-rh='View Dispute'
                    id='placeorderviewdisputeDetailPage22'
                  >
                      View Dispute
                  </Button>
                )}
              </div>
              {orderDetail &&
                orderDetail.productDetails &&
                orderDetail.productDetails[0] &&
                orderDetail.productDetails[0].status &&
                orderDetail.productDetails[0].status === 'canceled' && (
                <div
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                  id='placeorderDetailPage25'
                >
                  <div
                    className='ordercancelleddiv'
                    id='placeorderDetailPage26'
                  >
                      Order Cancelled
                  </div>
                </div>
              )}
              {/* } <div style={{display: 'flex'}}>
                <Button className="placemebutonsck2">Message Seller</Button>
                <Button className="placoscsrderbuton2">Confirm Delivery</Button>
              </div>
              <div style={{display: 'flex', marginTop: 10}}>
                <Button className="placemebutonsck2">Message Seller</Button>
                <Button className="placoscsrderbuton2">Leave Feedback</Button>
              </div> */}

              {productCategoryType === 'Radiology' && (
                <div>
                  <div
                    className={
                      'placeorderdropownpro ' +
                      (!this.state.specification
                        ? ' placeOrderdetailActiveBG '
                        : '')
                    }
                    id='placeorderDetailPage27'
                  >
                    <div className='placdSTIONSpro' id='placeorderDetailPage28'>
                      SPECIFICATIONS
                    </div>
                    <div
                      onClick={() => this.openDetailDropDown('specification')}
                      id='placeorderDetailPage29'
                    >
                      <img
                        alt='icon'
                        src={dropdownpro}
                        className={
                          !this.state.specification ? 'placecloseimgstyle' : ''
                        }
                        style={{ marginRight: 15 }}
                        id='placeorderDetailPage30'
                      />
                    </div>
                  </div>
                  {this.state.specification && (
                    <div
                      className='placeOrderContentBox'
                      id='placeorderDetailPage31'
                    >
                      <div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage32'
                        >
                          Manufacturer
                        </div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage33'
                        >
                          {' '}
                          System Name
                        </div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage34'
                        >
                          {' '}
                          Date of Manufacture
                        </div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage35'
                        >
                          {' '}
                          Working Condition
                        </div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage36'
                        >
                          {' '}
                          Condition
                        </div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage37'
                        >
                          {' '}
                          Console Type
                        </div>
                      </div>
                      <div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPage38'
                        >
                          {' '}
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].manufacturer
                            ? orderDetail.productDetails[0].manufacturer
                            : 'N/A'}
                        </div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPage39'
                        >
                          {' '}
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].systemName
                            ? orderDetail.productDetails[0].systemName
                            : 'N/A'}
                        </div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPage40'
                        >
                          {' '}
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].dateOfManufacture
                            ? moment(
                              orderDetail.productDetails[0].dateOfManufacture
                            ).format('MM/DD/YYYY')
                            : 'N/A'}
                        </div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPage41'
                        >
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].workingCondition
                            ? orderDetail.productDetails[0].workingCondition
                            : 'N/A'}
                        </div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPage42'
                        >
                          {' '}
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].condition
                            ? orderDetail.productDetails[0].condition
                            : 'N/A'}
                        </div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPage43'
                        >
                          {' '}
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].consoleType
                            ? orderDetail.productDetails[0].consoleType
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className={
                      'placeorderdropownpro ' +
                      (!this.state.logistics
                        ? ' placeOrderdetailActiveBG '
                        : '')
                    }
                    id='placeorderDetailPage44'
                  >
                    <div className='placdSTIONSpro'>LOGISTICS</div>
                    <div
                      onClick={() => this.openDetailDropDown('logistics')}
                      id='placeorderDetailPage45'
                    >
                      <img
                        alt='icon'
                        src={dropdownpro}
                        className={
                          this.state.logistics ? '' : 'placecloseimgstyle'
                        }
                        style={{ marginRight: 15 }}
                        id='placeorderDetailPage46'
                      />
                    </div>
                  </div>
                  {this.state.logistics && (
                    <div
                      className='placeOrderContentBox'
                      id='placeorderDetailPage47'
                    >
                      <div id='placeorderDetailPage48'>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage49'
                        >
                          Location
                        </div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage50'
                        >
                          Accessibility
                        </div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage51'
                        >
                          {' '}
                          Rigging
                        </div>
                      </div>
                      <div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPage52'
                        >
                          {' '}
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].logisticsLocation
                            ? orderDetail.productDetails[0].logisticsLocation
                            : 'N/A'}
                        </div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPage53'
                        >
                          {' '}
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].accessibility
                            ? orderDetail.productDetails[0].accessibility
                            : 'N/A'}
                        </div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPage54'
                        >
                          {' '}
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].rigging
                            ? orderDetail.productDetails[0].rigging
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className={
                      'placeorderdropownpro ' +
                      (!this.state.titleInfo
                        ? ' placeOrderdetailActiveBG '
                        : '')
                    }
                  >
                    <div className='placdSTIONSpro' id='placeorderDetailPage55'>
                      TITLE INFORMATION
                    </div>
                    {/* <div className='placeTSeapro' id='placeorderDetailPage56'>
                      Title Search
                    </div> */}
                    <div
                      onClick={() => this.openDetailDropDown('titleInfo')}
                      id='placeorderDetailPage57'
                    >
                      <img
                        alt='icons'
                        src={dropdownpro}
                        className={
                          this.state.titleInfo ? ' ' : 'placecloseimgstyle'
                        }
                        style={{ marginRight: 15 }}
                      />
                    </div>
                  </div>
                  {this.state.titleInfo && (
                    <div
                      className='placeOrderContentBox'
                      id='placeorderDetailPage58'
                    >
                      <div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage59'
                        >
                          Ownership Status
                        </div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage60'
                        >
                          Title Availability
                        </div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage61'
                        >
                          Title Search link
                        </div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage62'
                        >
                          {' '}
                          Liens/Loans on Equipment
                        </div>
                      </div>
                      <div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPage63'
                        >
                          {' '}
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].ownershipStatus
                            ? orderDetail.productDetails[0].ownershipStatus
                            : 'N/A'}
                        </div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPage64'
                        >
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].titleAvailability
                            ? orderDetail.productDetails[0].titleAvailability
                            : 'N/A'}
                        </div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPage65'
                        >
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].titleSearchLink
                            ? orderDetail.productDetails[0].titleSearchLink
                            : 'N/A'}
                        </div>

                        <div
                          className='labelValueText'
                          id='placeorderDetailPage66'
                        >
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].equipmentLiensLoans
                            ? orderDetail.productDetails[0].equipmentLiensLoans
                            : 'N/A'}{' '}
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className={
                      'placeorderdropownpro ' +
                      (!this.state.inspection
                        ? ' placeOrderdetailActiveBG '
                        : '')
                    }
                    id='placeorderDetailPage67'
                  >
                    <div className='placdSTIONSpro' id='placeorderDetailPage68'>
                      INSPECTION REPORT
                    </div>
                    {/* <div
                      className='placedoadrepipro'
                      id='placeorderDetailPage69'
                    >
                      {orderDetail &&
                      orderDetail.productDetails &&
                      orderDetail.productDetails[0] &&
                      orderDetail.productDetails[0].inspectionReportName &&
                      orderDetail.productDetails[0].inspectionReportURL ? (
                        <a
                            href={
                              orderDetail.productDetails[0].inspectionReportURL
                            }
                            target='_blank'
                          >
                          Download Report
                          </a>
                        ) : (
                          'Download Report'
                        )}
                    </div> */}
                    <div
                      onClick={() => this.openDetailDropDown('inspection')}
                      id='placeorderDetailPage70'
                    >
                      <img
                        alt='icon'
                        src={dropdownpro}
                        className={
                          this.state.inspection ? ' ' : 'placecloseimgstyle'
                        }
                        style={{ marginRight: 15 }}
                      />
                    </div>
                  </div>
                  {this.state.inspection && (
                    <div
                      className='placeOrderContentBox'
                      id='placeorderDetailPage71'
                    >
                      <div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage72'
                        >
                          Type
                        </div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage73'
                        >
                          File
                        </div>
                      </div>
                      <div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPage74'
                        >
                          {' '}
                          Self-declared
                        </div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPage75'
                        >
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].inspectionReportName
                            ? orderDetail.productDetails[0].inspectionReportName
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {productCategoryType !== 'Radiology' && (
                <div>
                  <div
                    className={
                      'placeorderdropownpro ' +
                      (!this.state.overView ? ' placeOrderdetailActiveBG ' : '')
                    }
                    id='placeorderDetailPage76'
                  >
                    <div className='placdSTIONSpro' id='placeorderDetailPage77'>
                      OVERVIEW
                    </div>
                    <div
                      onClick={() => this.openDetailDropDown('overView')}
                      id='placeorderDetailPage78'
                    >
                      <img
                        alt='icon'
                        src={dropdownpro}
                        className={
                          !this.state.overView ? 'placecloseimgstyle' : ''
                        }
                        style={{ marginRight: 15 }}
                      />
                    </div>
                  </div>
                  {this.state.overView && (
                    <div
                      className='placeOrderContentBox'
                      id='placeorderDetailPage79'
                    >
                      <div id='placeorderDetailPage80'>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage81'
                        >
                          Seller SKU
                        </div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage82'
                        >
                          Brand
                        </div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage83'
                        >
                          {' '}
                          Expiration Date on Product?
                        </div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage84'
                        >
                          {' '}
                          Manufacturer
                        </div>
                      </div>
                      <div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPage85'
                        >
                          {' '}
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].productSku
                            ? orderDetail.productDetails[0].productSku
                            : 'N/A'}
                        </div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPage86'
                        >
                          {' '}
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].brand
                            ? orderDetail.productDetails[0].brand
                            : 'N/A'}
                        </div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPage87'
                        >
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].productExpires
                            ? orderDetail.productDetails[0].productExpires
                            : 'N/A'}
                        </div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPage88'
                        >
                          {' '}
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].manufacturer
                            ? orderDetail.productDetails[0].manufacturer
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className={
                      'placeorderdropownpro ' +
                      (!this.state.otherInfo
                        ? ' placeOrderdetailActiveBG '
                        : '')
                    }
                    id='placeorderDetailPage89'
                  >
                    <div className='placdSTIONSpro' id='placeorderDetailPage90'>
                      OTHER INFO
                    </div>
                    <div
                      onClick={() => this.openDetailDropDown('otherInfo')}
                      id='placeorderDetailPage91'
                    >
                      <img
                        alt='icon'
                        src={dropdownpro}
                        className={
                          !this.state.otherInfo ? 'placecloseimgstyle' : ''
                        }
                        style={{ marginRight: 15 }}
                      />
                    </div>
                  </div>
                  {this.state.otherInfo && (
                    <div
                      className='placeOrderContentBox'
                      id='placeorderDetailPage92'
                    >
                      <div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage93'
                        >
                          Catalog Number
                        </div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage94'
                        >
                          Generic Keywords
                        </div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPage95'
                        >
                          {' '}
                          Importer Nameoduct ID
                        </div>
                      </div>
                      <div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPage96'
                        >
                          {' '}
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].catalogNumber
                            ? orderDetail.productDetails[0].catalogNumber
                            : 'N/A'}
                        </div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPage97'
                        >
                          {' '}
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].genericKeywords
                            ? orderDetail.productDetails[0].genericKeywords
                            : 'N/A'}
                        </div>

                        <div
                          className='labelValueText'
                          id='placeorderDetailPage98'
                        >
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].importerName
                            ? orderDetail.productDetails[0].importerName
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className={
                      'placeorderdropownpro ' +
                      (!this.state.dimentions
                        ? ' placeOrderdetailActiveBG '
                        : '')
                    }
                    id='placeorderDetailPage99'
                  >
                    <div
                      className='placdSTIONSpro'
                      id='placeorderDetailPageContent1'
                    >
                      DIMENSIONS
                    </div>
                    <div
                      onClick={() => this.openDetailDropDown('dimentions')}
                      id='placeorderDetailPageContent2'
                    >
                      <img
                        alt='icon'
                        src={dropdownpro}
                        className={
                          !this.state.dimentions ? 'placecloseimgstyle' : ''
                        }
                        style={{ marginRight: 15 }}
                        id='placeorderDetailPageContent3'
                      />
                    </div>
                  </div>
                  {this.state.dimentions && (
                    <div
                      className='placeOrderContentBox'
                      id='placeorderDetailPageContent4'
                    >
                      <div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPageContent5'
                        >
                          Size
                        </div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPageContent6'
                        >
                          {' '}
                          Color
                        </div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPageContent7'
                        >
                          {' '}
                          Weight
                        </div>
                      </div>
                      <div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPageContent8'
                        >
                          {' '}
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].size
                            ? orderDetail.productDetails[0].size
                            : 'N/A'}
                        </div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPageContent9'
                        >
                          {' '}
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].color
                            ? orderDetail.productDetails[0].color
                            : 'N/A'}
                        </div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPageContent10'
                        >
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].weight
                            ? orderDetail.productDetails[0].weight
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className={
                      'placeorderdropownpro ' +
                      (!this.state.compliance
                        ? ' placeOrderdetailActiveBG '
                        : '')
                    }
                    id='placeorderDetailPageContent11'
                  >
                    <div
                      className='placdSTIONSpro'
                      id='placeorderDetailPageContent12'
                    >
                      COMPLIANCE
                    </div>
                    <div
                      onClick={() => this.openDetailDropDown('compliance')}
                      id='placeorderDetailPageContent13'
                    >
                      <img
                        alt='icon'
                        src={dropdownpro}
                        className={
                          !this.state.compliance ? 'placecloseimgstyle' : ''
                        }
                        style={{ marginRight: 15 }}
                        id='placeorderDetailPageContent14'
                      />
                    </div>
                  </div>
                  {this.state.compliance && (
                    <div
                      className='placeOrderContentBox'
                      id='placeorderDetailPageContent15'
                    >
                      <div id='placeorderDetailPageContent16'>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPageContent17'
                        >
                          Country of Origin
                        </div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPageContent18'
                        >
                          {' '}
                          Specific Product Use
                        </div>
                      </div>
                      <div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPageContent19'
                        >
                          {' '}
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].countryOfOrigin
                            ? orderDetail.productDetails[0].countryOfOrigin
                            : 'N/A'}
                        </div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPageContent20'
                        >
                          {' '}
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].specificProductUse
                            ? orderDetail.productDetails[0].specificProductUse
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                  )}
                  <div
                    className={
                      'placeorderdropownpro ' +
                      (!this.state.condition
                        ? ' placeOrderdetailActiveBG '
                        : '')
                    }
                    id='placeorderDetailPageContent21'
                  >
                    <div
                      className='placdSTIONSpro'
                      id='placeorderDetailPageContent22'
                    >
                      CONDITION
                    </div>
                    <div
                      onClick={() => this.openDetailDropDown('condition')}
                      id='placeorderDetailPageContent23'
                    >
                      <img
                        alt='icon'
                        src={dropdownpro}
                        className={
                          !this.state.condition ? 'placecloseimgstyle' : ''
                        }
                        style={{ marginRight: 15 }}
                      />
                    </div>
                  </div>
                  {this.state.condition && (
                    <div
                      className='placeOrderContentBox'
                      id='placeorderDetailPageContent24'
                    >
                      <div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPageContent25'
                        >
                          Item Condition
                        </div>
                        <div
                          className='labelTitleText'
                          id='placeorderDetailPageContent26'
                        >
                          Condition Note
                        </div>
                      </div>
                      <div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPageContent27'
                        >
                          {' '}
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].itemCondition
                            ? orderDetail.productDetails[0].itemCondition
                            : 'N/A'}
                        </div>
                        <div
                          className='labelValueText'
                          id='placeorderDetailPageContent28'
                        >
                          {' '}
                          {orderDetail &&
                          orderDetail.productDetails &&
                          orderDetail.productDetails[0] &&
                          orderDetail.productDetails[0].conditionNote
                            ? orderDetail.productDetails[0].conditionNote
                            : 'N/A'}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div style={{ marginLeft: 50 }}>
              <div
                style={{ display: 'flex' }}
                id='placeorderDetailPageContent29'
              >
                <div style={{ width: 300 }} id='placeorderDetailPageContent30'>
                  <Carousel showThumbs={false}>
                    {orderDetail &&
                    orderDetail.productDetails &&
                    orderDetail.productDetails[0] &&
                    orderDetail.productDetails[0].productImageList &&
                    orderDetail.productDetails[0].productImageList.length >
                      0 ? (
                        orderDetail.productDetails[0].productImageList.map(
                          (item, i) => (
                            <div
                              key={i}
                              id={`placeorderDetailPageContentimgs${i}`}
                            >
                              <img
                                src={item || (productCategoryType && productCategoryType==='Radiology'? productDetails:nonradioPlaceholder)}
                                onError={e => {
                                  e.target.onerror = null;
                                  e.target.src = (productCategoryType && productCategoryType==='Radiology'? productDetails:nonradioPlaceholder)                                
                                }}
                                alt='products'
                                // style={{height: 299, width: 209}}
                                style={{
                                  height: '100%',
                                  width: '100%',
                                  minHeight: 299,
                                  minWidth: 209
                                }}
                              />
                            </div>
                          )
                        )
                      ) : (
                        <div>
                          <img
                            src={(productCategoryType && productCategoryType==='Radiology'? productDetails:nonradioPlaceholder)}
                            onError={e => {
                              e.target.onerror = null;
                              e.target.src = (productCategoryType && productCategoryType==='Radiology'? productDetails:nonradioPlaceholder)                                
                            }}
                            alt='products'
                            // style={{height: 299, width: 209}}
                            style={{
                              height: '100%',
                              width: '100%',
                              minHeight: 299,
                              minWidth: 209
                            }}
                            id='placeorderDetailPageContent33'
                          />
                        </div>
                      )}
                  </Carousel>
                </div>
                <div>
                  <div
                    className='placerigCircleImage'
                    id='placeorderDetailPageContent34'
                  >
                    {orderDetail &&
                      orderDetail.productDetails &&
                      orderDetail.productDetails[0] &&
                      orderDetail.productDetails[0].productImageList &&
                      orderDetail.productDetails[0].productImageList[0] &&
                      orderDetail.productDetails[0].productImageList.length >
                        0 &&
                      orderDetail.productDetails[0].productImageList.map(
                        (item, i) => (
                          <img
                            key={i}
                            alt='img'
                            src={item || (productCategoryType && productCategoryType==='Radiology'? productDetails:nonradioPlaceholder)}
                            onError={e => {
                              e.target.onerror = null;
                              e.target.src = (productCategoryType && productCategoryType==='Radiology'? productDetails:nonradioPlaceholder)                                
                            }}
                            className='circleimage1'
                            onClick={() => this.showImage(i)}
                            id={`placeorderDetailPageContentimgss${i}`}
                          />
                        )
                      )}
                  </div>
                </div>
              </div>
              <div
                className='placeselinfobopro'
                id='placeorderDetailPageContent35'
              >
                <div
                  style={{ display: 'flex', alignItems: 'baseline' }}
                  id='placeorderDetailPageContent36'
                >
                  <div
                    className='placesnfoheadiung'
                    id='placeorderDetailPageContent37'
                  >
                    SELLER INFORMATION
                  </div>
                  <div>
                    <div
                      className='placetcsdcdpro'
                      id='placeorderDetailPageContent38'
                    >
                      {' '}
                      TOP 10%
                    </div>
                    <div
                      style={{ marginLeft: 135, marginTop: 10 }}
                      id='placeorderDetailPageContent39'
                    >
                      <StarRatingComponent
                        id='placeorderDetailPageContent40'
                        name={'rating'}
                        starCount={5}
                        value={
                          productDetail &&
                          productDetail.reputationDetails &&
                          productDetail.reputationDetails.reputationData &&
                          productDetail.reputationDetails.reputationData
                            .sellerRatings &&
                          productDetail.reputationDetails.reputationData
                            .sellerRatings.sellerReputationScore
                            ? productDetail.reputationDetails.reputationData
                              .sellerRatings.sellerReputationScore
                            : 0
                        }
                        emptyStarColor={'#E4E4E4'}
                        starColor={'#F5D028'}
                        style={{ fontSize: '80px', margin: 0 }}
                        //   value={reputationDetails && reputationDetails.providerData && reputationDetails.providerData.providerRating ? reputationDetails.providerData.providerRating:0}
                      />
                    </div>
                  </div>
                </div>
                <div
                  style={{ display: 'flex' }}
                  id='placeorderDetailPageContent41'
                >
                  <div id='placeorderDetailPageContent42'>
                    <img
                      alt='productDetails'
                      // src={productDetails}
                      src={
                        productDetail &&
                        productDetail.productDetails &&
                        productDetail.productDetails[0].sellerDetails &&
                        productDetail.productDetails[0].sellerDetails
                          .profilePictureURL
                          ? productDetail.productDetails[0].sellerDetails
                            .profilePictureURL
                          : placeholderProfile
                      }
                      onError={e => {
                        e.target.onerror = null
                        e.target.src = placeholderProfile
                      }}
                      style={{
                        width: 50,
                        height: 50,
                        borderRadius: 50,
                        marginLeft: 27
                      }}
                      id='placeorderDetailPageContent43'
                    />
                  </div>
                  <div>
                    <p
                      className='plaeJuccdcrthapro'
                      id='placeorderDetailPageContent44'
                    >
                      {ShowNameAccordingToLength(
                        (productDetail &&
                          productDetail.productDetails &&
                          productDetail.productDetails[0].sellerDetails &&
                          productDetail.productDetails[0].sellerDetails
                            .firstName
                          ? productDetail.productDetails[0].sellerDetails
                            .firstName
                          : '') + ' ' + (productDetail &&
                            productDetail.productDetails &&
                            productDetail.productDetails[0].sellerDetails &&
                            productDetail.productDetails[0].sellerDetails
                              .lastName
                            ? productDetail.productDetails[0].sellerDetails
                              .lastName
                            : '')
                      )}
                    </p>
                    <p
                      className='placehjvfonpro'
                      id='placeorderDetailPageContent45'
                    >
                      Reputation:
                      {productDetail &&
                      productDetail.reputationDetails &&
                      productDetail.reputationDetails.reputationData &&
                      productDetail.reputationDetails.reputationData
                        .sellerRatings &&
                      productDetail.reputationDetails.reputationData
                        .sellerRatings.sellerReputationScore
                        ? productDetail.reputationDetails.reputationData
                          .sellerRatings.sellerReputationScore
                        : 0}
                      /5.0
                    </p>
                  </div>
                  <div>
                    <Button
                      className='placevbuttonprdcdco'
                      onClick={() => {
                        this.props.history.push(
                          routes.CONTACTUSERDETAIL +
                            `/${productDetail.sellerUID}`
                        )
                      }}
                      data-rh='View profile'
                      id='placeorderDetailPageContent46'
                    >
                      Visit Profile{' '}
                    </Button>
                  </div>
                </div>
                <div
                  className='placeRevprdcdco'
                  id='placeorderDetailPageContent47'
                >
                  Recent Reviews:
                </div>
                {productDetail &&
                productDetail.reputationDetails &&
                productDetail.reputationDetails.latestReview &&
                productDetail.reputationDetails.latestReview.length > 0 &&
                productDetail.reputationDetails.latestReview[0].length > 0 ? (
                    productDetail.reputationDetails.latestReview[0].map(
                      (item, i) => (
                        <div
                          className='placexrescdfpropro'
                          id={`placeorderDetailPageContent48review${i}`}
                          style={{ marginTop: 10 }}
                          key={i}
                        >
                          <div style={{ display: 'flex' }}>
                            <div>
                              <img
                                alt='img'
                                src={
                                  item && item.reviewerProfilePictureURL
                                    ? item.reviewerProfilePictureURL
                                    : placeholderProfile
                                }
                                onError={e => {
                                  e.target.onerror = null
                                  e.target.src = placeholderProfile
                                }}
                                style={{
                                  width: 50,
                                  height: 50,
                                  borderRadius: 50
                                }}
                                id={`placeorderDetailPageContent49review${i}`}
                              />
                            </div>
                            <div style={{ width: 220 }}>
                              <p
                                className='placeFcdcdaulpro'
                                id={`placeorderDetailPageContent50review${i}`}
                              >
                                {item && item.reviewerTitle
                                  ? item.reviewerTitle
                                  : ''}{' '}
                                {item && item.reviewerDisplayName
                                  ? item.reviewerDisplayName
                                  : item.reviewerFirstName
                                    ? item.reviewerFirstName
                                    : 'N/A'}
                              </p>
                              <p
                                className='placefjkrorpro'
                                id={`placeorderDetailPageContent51review${i}`}
                              >
                                {item && item.reviewDate
                                  ? moment(item && item.reviewDate).format(
                                    'MM/DD/YYYY ; h:MMa'
                                  )
                                  : 'N/A'}
                              </p>
                            </div>
                            <div style={{ marginLeft: 5 }}>
                              <StarRatingComponent
                                id={`placeorderDetailPageContent52review${i}`}
                                name={'rating'}
                                starCount={5}
                                value={
                                  (item && item.reviewScore )? item.reviewScore : 0
                                }
                                // value={
                                //   userProfile &&
                                //     userProfile.serviceProfile &&
                                //     userProfile.serviceProfile.totalServicesOffered
                                //     ? userProfile.serviceProfile.totalServicesOffered
                                //     : 0
                                // }
                                emptyStarColor={'#E4E4E4'}
                                starColor={'#F5D028'}
                                style={{ fontSize: '80px', margin: 0 }}
                              //   value={reputationDetails && reputationDetails.providerData && reputationDetails.providerData.providerRating ? reputationDetails.providerData.providerRating:0}
                              />
                            </div>
                          </div>
                          <div
                            className='placeudcfdvetthes'
                            id={`placeorderDetailPageContent53review${i}`}
                            style={{ paddingLeft: 60 }}
                          >
                            {item && item.reviewText ? item.reviewText : 'N/A'}{' '}
                          </div>
                        </div>
                      )
                    )
                  ) : (
                    <div
                      className='placeRevprdcdco'
                      style={{ fontSize: 20, textAlign: 'center' }}
                    >
                    No Review
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
        <RightSideContainer>
          <MarketPlaceSidemenu />
        </RightSideContainer>

        <Modal size='sm' isOpen={this.state.payForOrderModal} centered>
          <ModalBody
            className='payforordemodal'
            id='placeorderDetailPageContent54'
          >
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              <div
                className='placwpayfororder'
                id='placeorderDetailPageContent55'
              >
                Pay for Order
              </div>
              <div onClick={() => this.closeModal()}>
                <img
                  alt='close_icon'
                  src={close_icon}
                  style={{ width: 20, marginLeft: 82, cursor: 'pointer' }}
                  id='placeorderDetailPageContent56'
                />
              </div>
            </div>
            <p
              className='walletbalancepayforo'
              id='placeorderDetailPageContent57'
            >
              Wallet Balance
            </p>
            <p
              className='balcncewalltpahyfor'
              id='placeorderDetailPageContent58'
            >
              {userBalance && userBalance.currency
                ? userBalance.currency
                : 'USD'}
              $
              <span>
                {userBalance && userBalance.amount ? userBalance.amount : 0}
              </span>
            </p>
            <p
              className='balcncewalltpahyfor'
              id='placeorderDetailPageContent59'
            >
              {' '}
              R-DEE Credits: {rdCredit && rdCredit.amount
                ? rdCredit.amount
                : 0}{' '}
              {rdCredit && rdCredit.currency ? rdCredit.currency : ''}{' '}
            </p>
            <div className='detaildaororder' id='placeorderDetailPageContent60'>
              <div>
                <img
                  alt='close_icon'
                  // src={productDetails}
                  src={
                    orderDetail &&
                    orderDetail.productDetails &&
                    orderDetail.productDetails[0] &&
                    orderDetail.productDetails[0].productImageList &&
                    orderDetail.productDetails[0].productImageList[0]
                      ? orderDetail.productDetails[0].productImageList[0]
                      : productDetails
                  }
                  onError={e => {
                    e.target.onerror = null
                    e.target.src = productDetails
                  }}
                  style={{
                    width: 70,
                    height: 70,
                    borderRadius: 10
                  }}
                  id='placeorderDetailPageContent61'
                />
              </div>
              <div>
                <p
                  className='OrderidpayTitle'
                  id='placeorderDetailPageContent62'
                >
                  {orderDetail &&
                  orderDetail.productDetails &&
                  orderDetail.productDetails[0] &&
                  orderDetail.productDetails[0]
                    ? orderDetail &&
                      orderDetail.productDetails[0].productCategoryType &&
                      orderDetail.productDetails[0].productCategoryType ===
                        'Radiology'
                      ?orderDetail.productDetails[0].deviceSaleName: (orderDetail.productDetails[0] && orderDetail.productDetails[0].productName?orderDetail.productDetails[0].productName:'')
                      
                    : 'N/A'}
                </p>
                <p className='Orderidpayfor' id='placeorderDetailPageContent63'>
                  Order ID:{' '}
                  {orderDetail &&
                  orderDetail.productDetails &&
                  orderDetail.productDetails[0] &&
                  orderDetail.productDetails[0].orderID
                    ? orderDetail.productDetails[0].orderID
                    : 'N/A'}
                </p>
                <p
                  className='USpatyjfdpayfor'
                  id='placeorderDetailPageContent64'
                >
                  USD${' '}
                  {orderDetail &&
                  orderDetail.productDetails &&
                  orderDetail.productDetails[0] &&
                  orderDetail.productDetails[0].priceUSD
                    ? priceWithDecimal(orderDetail.productDetails[0].priceUSD)
                    : 'N/A'}
                </p>
              </div>
            </div>

            <p className='RDEEcreditsvfpay' id='placeorderDetailPageContent65'>
              <span>
                Total Place Order Quantity :{' '}
                {orderDetail && orderDetail.productQuantity
                  ? orderDetail.productQuantity
                  : 'N/A'}
              </span>
            </p>
            <p
              className='RDEEcreditsvfpay'
              style={{ padding: 0 }}
              id='placeorderDetailPageContent66'
            >
              <span>
                Total Place Order Price :{' '}
                {orderDetail && orderDetail.totalOrderPrice
                  ? priceWithDecimal(orderDetail.totalOrderPrice)
                  : 'N/A'}
              </span>
            </p>

            <p
              className='RDEEcreditsvfpay'
              style={{ padding: 0 }}
              id='placeorderDetailPageContent67'
            >
              <span>R-DEE credits</span> after placing order:{' '}
              <span>
                {rdCredit && rdCredit.amount
                  ? rdCredit.amount -
                    (orderDetail &&
                    orderDetail.totalOrderPrice &&
                    orderDetail.totalOrderPrice
                      ? priceWithDecimal(orderDetail.totalOrderPrice)
                      : 0)
                  : ''}
              </span>
            </p>
            <div style={{ marginTop: 15 }}>
              <p className='purposeText' id='placeorderDetailPageContent68'>
                Purpose
              </p>

              <input
                className='purposeInput'
                type='text'
                placeholder='Enter Purpose *'
                value={transfer_purposes}
                onChange={e =>
                  this.setState({
                    transfer_purposes: e.target.value
                  })
                }
                id='placeorderDetailPageContent70'
              />
            </div>

            <div
              style={{ textAlign: 'center' }}
              id='placeorderDetailPageContent69'
            >
              <Button
                style={{ cursor: !transfer_purposes ? 'unset' : '' }}
                disabled={!transfer_purposes}
                className='paymentcofirmbuttonpay'
                onClick={() =>
                  this.setState({ pinModal: true, payForOrderModal: false })
                }
                id='placeorderDetailPageContent71'
              >
                Confirm Payment
              </Button>
            </div>
          </ModalBody>
        </Modal>
        <Modal size='sm' isOpen={this.state.pinModal} centered>
          <ModalBody
            className='pinmodalpayplce'
            id='placeorderDetailPageContent72'
          >
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              <div
                className='placwpayfororder'
                id='placeorderDetailPageContent73'
              >
                Pay for Order
              </div>
              <div onClick={() => this.closeModal()}>
                <img
                  alt=''
                  src={close_icon}
                  style={{ width: 20, marginLeft: 82, cursor: 'pointer' }}
                />
              </div>
            </div>
            <p
              className='payforpinEnteryourPIN'
              id='placeorderDetailPageContent74'
            >
              Enter your PIN to confirm sending money.
            </p>
            <PinInput
              length={4}
              focus={false}
              secret
              ref={p => (this.pin = p)}
              type='numeric'
              style={{
                padding: '10px',
                display: 'flex',
                justifyContent: 'center'
              }}
              inputStyle={{
                borderColor: 'transparent',
                borderBottomColor: '#4AEA87',
                borderBottomWidth: 2,
                borderTopWidth: 0,
                borderRadius: 0,
                color: '#FFFFFF'
              }}
              inputFocusStyle={{
                borderBottomColor: '#4AEA87',
                color: '#FFFFFF'
              }}
              onComplete={(value, index) => {
                this.setState(
                  {
                    pinValue: value
                  },
                  () => {
                    this.doPlaceOrder()
                  }
                )
              }}
            />
            {this.state.payOrderError && (
              <div
                className='payforwrongpinmessa'
                id='placeorderDetailPageContent76'
              >
                {this.state.payOrderError}
                {/* You have entered your PIN incorrectly
                more than 5 times. Your PIN has been reset.
                Kindly email support@radiologex.com
                to proceed further. */}
              </div>
            )}
          </ModalBody>
        </Modal>
        <Modal size='sm' isOpen={this.state.orderPaidModal} centered>
          <ModalBody
            className='orderpaidodalplace'
            id='placeorderDetailPageContent76'
          >
            <div
              style={{ display: 'inline-flex', alignItems: 'center' }}
              id='placeorderDetailPageContent77'
            >
              <div
                className='placwpayfororder'
                id='placeorderDetailPageContent78'
              >
                Order Paid
              </div>
              <div onClick={() => this.closeModal()}>
                <img
                  alt='close_icon'
                  src={close_icon}
                  style={{ width: 20, marginLeft: 82, cursor: 'pointer' }}
                  id='placeorderDetailPageContent79'
                />
              </div>
            </div>
            <div
              style={{ textAlign: 'center' }}
              id='placeorderDetailPageContent80'
            >
              <img
                alt='sendMoney'
                src={sendMoney}
                style={{ width: 110, height: 110, marginTop: 46 }}
                id='placeorderDetailPageContent81'
              />
            </div>
            <div
              className='Congratulationspayplace'
              id='placeorderDetailPageContent82'
            >
              <p
                style={{ fontWeight: 'bold' }}
                id='placeorderDetailPageContent83'
              >
                Congratulations!{' '}
              </p>
              <p>
                {' '}
                You paid{' '}
                <span style={{ fontWeight: 'bold' }}>
                  USD${' '}
                  {orderDetail && orderDetail.totalOrderPrice
                    ? priceWithDecimal(orderDetail.totalOrderPrice)
                    : 0}
                </span>{' '}
                to{' '}
                <span style={{ fontWeight: 'bold' }}>
                  {orderDetail &&
                  orderDetail.productDetails &&
                  orderDetail.productDetails[0] &&
                  orderDetail.productDetails[0].sellerDetails &&
                  orderDetail.productDetails[0].sellerDetails.firstName
                    ? orderDetail.productDetails[0].sellerDetails.firstName
                    : ''}{' '}
                  {orderDetail &&
                  orderDetail.productDetails &&
                  orderDetail.productDetails[0] &&
                  orderDetail.productDetails[0].sellerDetails &&
                  orderDetail.productDetails[0].sellerDetails.lastName
                    ? orderDetail.productDetails[0].sellerDetails.lastName
                    : ''}
                </span>
              </p>
              <p id='placeorderDetailPageContent84'>
                {' '}
                for Order #
                {orderDetail &&
                orderDetail.productDetails &&
                orderDetail.productDetails[0] &&
                orderDetail.productDetails[0].orderID
                  ? orderDetail.productDetails[0].orderID
                  : 'N/A'}
                .
              </p>
            </div>
            <div
              style={{ textAlign: 'center' }}
              id='placeorderDetailPageContent85'
            >
              <Button
                className='returntoorderplace'
                onClick={() => {
                  this.closeModal();
                  window.location.reload();
                }}
                id='placeorderDetailPageContent86'
              >
                Return to Order
              </Button>
            </div>
            <div
              style={{ textAlign: 'center' }}
              onClick={() => this.props.history.push(routes.PLACED_ORDERS)}
              id='placeorderDetailPageContent87'
            >
              <Button
                className='ViewAllOplace'
                id='placeorderDetailPageContent88'
              >
                View All Orders
              </Button>
            </div>
          </ModalBody>
        </Modal>

        {/* Shipment confirmation */}

        <Modal
          size='sm'
          isOpen={this.state.confirmDelivery}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody
            className='PlaceOrderConfirmOrderModal'
            id='placeorderDetailPageContent90'
          >
            <div>
              <p className='confirmTitle' id='placeorderDetailPageContent91'>
                Confirm Delivery
              </p>
              <p className='confirmmsg' id='placeorderDetailPageContent92'>
                Have you received the{' '}
                {orderDetail &&
                orderDetail.productDetails &&
                orderDetail.productDetails[0] &&
                orderDetail.productDetails[0]
                  ? orderDetail &&
                    orderDetail.productDetails[0].productCategoryType &&
                    orderDetail.productDetails[0].productCategoryType ===
                      'Radiology'
                    ? orderDetail.productDetails[0].deviceSaleName 
                    : (orderDetail.productDetails[0] && orderDetail.productDetails[0].productName?orderDetail.productDetails[0].productName:'')
                  : 'N/A'}{' '}
                order from{' '}
                {orderDetail &&
                orderDetail.productDetails &&
                orderDetail.productDetails[0] &&
                orderDetail.productDetails[0].sellerDetails &&
                orderDetail.productDetails[0].sellerDetails.firstName
                  ? orderDetail.productDetails[0].sellerDetails.firstName
                  : ''}{' '}
                {orderDetail &&
                orderDetail.productDetails &&
                orderDetail.productDetails[0] &&
                orderDetail.productDetails[0].sellerDetails &&
                orderDetail.productDetails[0].sellerDetails.lastName
                  ? orderDetail.productDetails[0].sellerDetails.lastName
                  : ''}{' '}
                ?
              </p>
              {orderDetail && orderDetail.paymentType === 'internal' && (
                <div>
                  <p
                    className='payforpinEnteryourPIN'
                    id='placeorderDetailPageContent93'
                  >
                    Enter your PIN to delivery.{' '}
                  </p>
                  <PinInput
                    length={4}
                    focus={false}
                    secret
                    ref={p => (this.pin = p)}
                    type='numeric'
                    style={{
                      padding: '10px',
                      display: 'flex',
                      justifyContent: 'center'
                    }}
                    inputStyle={{
                      borderColor: 'transparent',
                      borderBottomColor: '#4AEA87',
                      borderBottomWidth: 2,
                      borderTopWidth: 0,
                      borderRadius: 0,
                      color: '#FFFFFF'
                    }}
                    inputFocusStyle={{
                      borderBottomColor: '#4AEA87',
                      color: '#FFFFFF'
                    }}
                    // onComplete={(value, index) => {
                    //   this.setState (
                    //     {
                    //       pinValue: value,
                    //     },
                    //     () => {
                    //       // this.doPlaceOrder ();
                    //     }
                    //   );
                    // }}
                    onChange={(value, index) => {
                      // console.log (value);
                      this.setState(
                        {
                          pinValue: value
                        },
                        () => {
                          // this.doPlaceOrder ();
                        }
                      )
                    }}
                  />

                  <div style={{ marginTop: 15 }}>
                    <p
                      className='purposeText'
                      id='placeorderDetailPageContent94'
                    >
                      Purpose
                    </p>

                    <input
                      className='purposeInput'
                      type='text'
                      placeholder='Purpose *'
                      value={transfer_purposes}
                      onChange={e =>
                        this.setState({
                          transfer_purposes: e.target.value
                        })
                      }
                      id='placeorderDetailPageContent95'
                    />
                  </div>
                </div>
              )}

              {this.state.payOrderError && (
                <div
                  className='payforwrongpinmessa'
                  style={{ width: 'auto' }}
                  id='placeorderDetailPageContent96'
                >
                  {this.state.payOrderError}
                  {/* You have entered your PIN incorrectly
                more than 5 times. Your PIN has been reset.
                Kindly email support@radiologex.com
                to proceed further. */}
                </div>
              )}

              <div className='buttonDiv' id='placeorderDetailPageContent97'>
                <Button
                  id='placeorderDetailPageContent98'
                  className='buttons'
                  onClick={() => this.closeModal()}
                >
                  No
                </Button>
                {orderDetail && orderDetail.paymentType === 'internal' ? (
                  <Button
                    disabled={
                      !this.state.pinValue ||
                      this.state.pinValue.length < 4 ||
                      !transfer_purposes
                    }
                    className='buttons yesButton'
                    onClick={() => this.doConfirmShipment()}
                    id='placeorderDetailPageContent99'
                  >
                    Yes
                  </Button>
                ) : (
                  <Button
                    className='buttons yesButton'
                    onClick={() => this.doConfirmExternalShipment()}
                    id='placeorderDetailPageConteddnt99'
                  >
                    Yes
                  </Button>
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* Feed Back Modal */}

        <Modal
          size='sm'
          isOpen={this.state.leaveFeedBack}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody
            className='PlaceOrderConfirmOrderModal'
            id='placeorderDetailPageleave1'
          >
            <div>
              <p className='confirmTitle' id='placeorderDetailPageleave2'>
                Leave Feedback
              </p>
              <p className='confirmmsg' id='placeorderDetailPageleave3'>
                How would you rate your experience with{' '}
                {orderDetail &&
                orderDetail.productDetails &&
                orderDetail.productDetails[0] &&
                orderDetail.productDetails[0].sellerDetails &&
                orderDetail.productDetails[0].sellerDetails.firstName
                  ? orderDetail.productDetails[0].sellerDetails.firstName
                  : ''}{' '}
                {orderDetail &&
                orderDetail.productDetails &&
                orderDetail.productDetails[0] &&
                orderDetail.productDetails[0].sellerDetails &&
                orderDetail.productDetails[0].sellerDetails.lastName
                  ? orderDetail.productDetails[0].sellerDetails.lastName
                  : ''}{' '}
                ?{' '}
              </p>
              <div
                style={{ marginTop: 10, textAlign: 'center' }}
                id='placeorderDetailPageleave4'
              >
                <StarRatingComponent
                  name={'rating'}
                  starCount={5}
                  // value={
                  //   productDetail &&
                  //   productDetail.reputationDetails &&
                  //   productDetail.reputationDetails.latestReview
                  //     ? productDetail.serviceProfile.totalServicesOffered
                  //     : 0
                  // }
                  value={this.state.rating}
                  emptyStarColor={'#E4E4E4'}
                  starColor={'#F5D028'}
                  style={{ fontSize: '100px', margin: 0 }}
                  onStarClick={this.onStarClick.bind(this)}
                  id='placeorderDetailPageleave5'
                  //   value={reputationDetails && reputationDetails.providerData && reputationDetails.providerData.providerRating ? reputationDetails.providerData.providerRating:0}
                />

                <textarea
                  className='textAreainput'
                  placeholder='Leave a review of your experience'
                  value={this.state.reviewText}
                  onChange={event => {
                    this.setState({
                      reviewText: event.target.value
                    })
                  }}
                  id='placeorderDetailPageleave6'
                />
              </div>
              <div className='buttonDiv' id='placeorderDetailPageleave7'>
                <Button
                  className='buttons'
                  onClick={() => this.closeModal()}
                  id='placeorderDetailPageleave8'
                >
                  No
                </Button>
                <Button
                  className='buttons yesButton'
                  onClick={() => this.doLeaveFeedback()}
                  id='placeorderDetailPageleave9'
                >
                  Yes
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size='sm'
          isOpen={this.state.leaveFeedsuccess}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody
            className='PlaceOrderConfirmOrderModal'
            id='placeorderDetailPageleave10'
          >
            <div>
              <p className='confirmTitle' id='placeorderDetailPageleave11'>
                Leave Feedback
              </p>
              <p className='confirmmsg' id='placeorderDetailPageleave12'>
                Thank you for your feedback.{' '}
              </p>
              <div
                style={{ marginTop: 10, textAlign: 'center' }}
                id='placeorderDetailPageleave13'
              >
                <StarRatingComponent
                  id='placeorderDetailPageleave14'
                  name={'rating'}
                  starCount={5}
                  value={this.state.rating}
                  emptyStarColor={'#E4E4E4'}
                  starColor={'#F5D028'}
                  style={{ fontSize: '100px', margin: 0 }}
                  // onStarClick={this.onStarClick.bind (this)}
                  //   value={reputationDetails && reputationDetails.providerData && reputationDetails.providerData.providerRating ? reputationDetails.providerData.providerRating:0}
                />

                <textarea
                  className='textAreainput'
                  placeholder='Leave a review of your experience'
                  value={this.state.reviewText}
                  onChange={event => {
                    this.setState({
                      reviewText: event.target.value
                    })
                  }}
                  readOnly
                  id='placeorderDetailPageleave15'
                />
              </div>
              <div className='buttonDiv' id='placeorderDetailPageleave16'>
                <Button
                  className='buttons yesButton'
                  onClick={() => this.closeModal()}
                  id='placeorderDetailPageleave17'
                >
                  Ok
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size='sm'
          isOpen={this.state.cancelOrder}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody
            className='PlaceOrderConfirmOrderModal'
            id='placeorderDetailPageleave18'
          >
            <div>
              <p className='confirmTitle' id='placeorderDetailPageleave19'>
                Cancel Order
              </p>
              <p className='confirmmsg' id='placeorderDetailPageleave20'>
                Are you sure you want to cancel your order? This can’t be
                undone.
              </p>
              <div className='buttonDiv' id='placeorderDetailPageleave21'>
                <Button
                  id='placeorderDetailPageleave22'
                  className='buttons'
                  onClick={() => this.closeModal()}
                >
                  No
                </Button>
                <Button
                  className='buttons yesButton'
                  onClick={() => this.doCancelOrders()}
                  id='placeorderDetailPageleave23'
                >
                  Yes
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size='sm'
          isOpen={viewInvoiceModal}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody
            className='PlaceOrderConfirmOrderModal'
            id='PlaceOrderDetailsPageViewInvoice197'
          >
            <div>
              <p
                className='confirmTitle'
                id='PlaceOrderDetailsPageViewInvoice189'
                style={{ textAlign: 'center' }}
              >
                View Invoice
              </p>

              <div
                style={{ marginTop: 10, textAlign: 'center' }}
                id='PlaceOrderDetailsPageViewInvoice200'
              >
                {orderDetail && orderDetail.invoiceURL && (
                  <iframe
                    src={orderDetail.invoiceURL}
                    frameBorder='0'
                    scrolling='auto'
                    height='100%'
                    width='100%'
                    style={{ minHeight: 400, minWidth: 250 }}
                  />
                )}
              </div>

              <div
                style={{ textAlign: 'center', marginTop: 20 }}
                id='PlaceOrderDetailsPageViewInvoice203'
              >
                <Button
                  className='placemessagebuton'
                  onClick={() => this.downloadInvoice()}
                  data-rh='Close'
                  id='PlaceOrderDetailsPageViewInvoice204'
                >
                  Download Invoice
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size='sm'
          isOpen={paymentPreviewModal}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody
            className='paymentPreviewModal'
            id='PlaceOrderDetailsPageViewpaymentPreviewModal197'
          >
            <div>
              <p className='headerTitle'>Pay for Order...</p>

              {productDetail &&
                productDetail.productDetails &&
                productDetail.productDetails[0] &&
                productDetail.productDetails[0].sellerDetails &&
                productDetail.productDetails[0].sellerDetails.firstName && (
                <div className='userInfoPreviewBox'>
                  <div>
                    <div className='userprofileImageBox'>
                      <img
                        src={
                          productDetail.productDetails[0].sellerDetails
                            .profilePictureURL
                            ? productDetail.productDetails[0].sellerDetails
                              .profilePictureURL
                            : placeholderProfile
                        }
                        onError={e => {
                          e.target.onerror = null
                          e.target.src = placeholderProfile
                        }}
                        alt='userprofileImage'
                        className='userprofileImage'
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className='userProfileName'>
                      {ShowNameAccordingToLength(
                        productDetail &&
                            productDetail.productDetails &&
                            productDetail.productDetails[0].sellerDetails &&
                            productDetail.productDetails[0].sellerDetails
                              .firstName
                          ? productDetail.productDetails[0].sellerDetails
                            .firstName
                          : '' + ' ' + productDetail &&
                              productDetail.productDetails &&
                              productDetail.productDetails[0].sellerDetails &&
                              productDetail.productDetails[0].sellerDetails
                                .lastName
                            ? productDetail.productDetails[0].sellerDetails
                              .lastName
                            : ''
                      )}
                    </p>
                  </div>
                </div>
              )}
              <div
                className='borderBottom1Dashed'
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              {selectPaymentMethod && (
                <div>
                  <div className='invoiceFileTitle'>Select payment method:</div>

                  <div style={{ textAlign: 'center', marginTop: 30 }}>
                    <Button
                      className='payForExternalLinkButton'
                      onClick={() => {
                        this.setState({
                          paymentUploadInvoiceModal: true,
                          previewPayInvoiceFile: '',
                          selectedPayInvoiceFile: ''
                        })
                      }}
                      data-rh='External Payment'
                    >
                      External Payment
                    </Button>

                    <div
                      className='invoiceFileTitle'
                      style={{ marginTop: 10, fontSize: 14 }}
                    >
                      You can make a payment bank transfer or other
                      <p>agent outside of the R-DEE app.</p>
                    </div>

                    <div style={{ textAlign: 'center', marginTop: 30 }}>
                      <Button
                        className='payForOrderBlueButton'
                        // onClick={() => this.downloadInvoice ()}
                        onClick={() => {
                          this.setState({
                            payForOrderModal: true,
                            previewPayInvoiceFile: '',
                            selectedPayInvoiceFile: ''
                          })
                        }}
                        data-rh='Pay via Wyre'
                      >
                        Pay via Wyre
                      </Button>
                      <div
                        className='invoiceFileTitle'
                        style={{ marginTop: 10, fontSize: 14 }}
                      >
                        You will be directed to a secure gateway via
                        <p>R-DEE using Wyre to complete payment.</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {!selectPaymentMethod && (
                <div>
                  <div className='invoiceFileTitle'>Invoice file:</div>

                  {orderDetail && orderDetail.invoiceURL && (
                    <div>
                      <a
                        className='previewInvoiceFilename'
                        href={orderDetail.invoiceURL}
                        target='_blank'
                      >
                        {orderDetail.invoice}
                      </a>
                    </div>
                  )}
                  <div className='preViewInvoiceFileBox'>
                    {orderDetail && orderDetail.invoiceURL && (
                      <iframe
                        src={orderDetail.invoiceURL}
                        frameBorder='0'
                        scrolling='auto'
                        height='100%'
                        width='100%'
                        style={{ minHeight: 315, minWidth: 315 }}
                      />
                    )}
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <Button
                      className='downloadButton'
                      onClick={() => this.downloadInvoice()}
                      data-rh='Download'
                    >
                      <img
                        style={{ width: 15, marginRight: 5 }}
                        src={downloadInvoice}
                        alt='downloadIcon'
                      />
                      Download
                    </Button>
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        className='payForOrderBlueButton'
                        onClick={() =>
                          this.setState({ selectPaymentMethod: true })
                        }
                        data-rh=' Pay for Order'
                      >
                        Pay for Order
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size='sm'
          isOpen={paymentUploadInvoiceModal}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody
            className='paymentPreviewModal'
            id='PlaceOrderDetailsPageViewpaymentPreviewModal197'
          >
            <div>
              <p className='headerTitle'>Pay for Order...</p>

              {productDetail &&
                productDetail.productDetails &&
                productDetail.productDetails[0] &&
                productDetail.productDetails[0].sellerDetails &&
                productDetail.productDetails[0].sellerDetails.firstName && (
                <div className='userInfoPreviewBox'>
                  <div>
                    <div className='userprofileImageBox'>
                      <img
                        src={
                          productDetail.productDetails[0].sellerDetails
                            .profilePictureURL
                            ? productDetail.productDetails[0].sellerDetails
                              .profilePictureURL
                            : placeholderProfile
                        }
                        onError={e => {
                          e.target.onerror = null
                          e.target.src = placeholderProfile
                        }}
                        alt='userprofileImage'
                        className='userprofileImage'
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className='userProfileName'>
                      {ShowNameAccordingToLength(
                        productDetail &&
                            productDetail.productDetails &&
                            productDetail.productDetails[0].sellerDetails &&
                            productDetail.productDetails[0].sellerDetails
                              .firstName
                          ? productDetail.productDetails[0].sellerDetails
                            .firstName
                          : '' + ' ' + productDetail &&
                              productDetail.productDetails &&
                              productDetail.productDetails[0].sellerDetails &&
                              productDetail.productDetails[0].sellerDetails
                                .lastName
                            ? productDetail.productDetails[0].sellerDetails
                              .lastName
                            : ''
                      )}
                    </p>
                  </div>
                </div>
              )}
              <div
                className='borderBottom1Dashed'
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              <div>
                <div className='invoiceFileTitle'>Upload proof of payment:</div>

                <div style={{ textAlign: 'right' }}>
                  <label
                    className='selectUploadProofButton'
                    onClick={() => this.setState({ selectPaymentMethod: true })}
                    data-rh='Select File'
                  >
                    Select
                    <input
                      type='file'
                      name='selectProof'
                      accept='image/jpeg,application/pdf'
                      onChange={e => this.onChangeInvoiceselectProof(e)}
                    />
                  </label>
                </div>
                {!selectedPayInvoiceFile && (
                  <div
                    className='invoiceFileTitle'
                    style={{ marginTop: 10, marginBottom: 5, opacity: 0.5 }}
                  >
                    PDF or JPG files only. Max: 5 MB
                  </div>
                )}

                {selectedPayInvoiceFile && selectedPayInvoiceFile.name && (
                  <div style={{ display: 'inline-flex' }}>
                    <a
                      className='previewInvoiceFilename'
                      href={previewPayInvoiceFile}
                      target='_blank'
                    >
                      {selectedPayInvoiceFile.name}
                    </a>

                    <Button
                      className='fileRemoveIconStyle'
                      onClick={() => {
                        this.setState({
                          previewPayInvoiceFile: '',
                          selectedPayInvoiceFile: ''
                        })
                      }}
                    >
                      <img
                        style={{ width: 20, height: 20 }}
                        src={fileRemoveIcon}
                        alt='fileRemoveIcon'
                      />
                    </Button>
                  </div>
                )}
                {previewPayInvoiceFile && (
                  <div className='preViewInvoiceFileBox'>
                    <iframe
                      src={previewPayInvoiceFile}
                      frameBorder='0'
                      scrolling='auto'
                      height='100%'
                      width='100%'
                      style={{ minHeight: 315, minWidth: 315 }}
                    />
                  </div>
                )}
                <div style={{ textAlign: 'center' }}>
                  <Button
                    className='payForOrderBlueButton'
                    onClick={() => this.doPlaceOrder()}
                    data-rh='Payment Initiated'
                    disabled={!previewPayInvoiceFile}
                  >
                    Payment Initiated
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size='sm'
          isOpen={this.state.trackShippingInfo}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody
            className='paymentPreviewModal'
            id='PlaceOrderDetailsPageViewpaymentPreviewModal197'
          >
            <div>
              <p className='headerTitle'>Shipping Info...</p>

              {productDetail &&
                productDetail.productDetails &&
                productDetail.productDetails[0] &&
                productDetail.productDetails[0].sellerDetails &&
                productDetail.productDetails[0].sellerDetails.firstName && (
                <div className='userInfoPreviewBox'>
                  <div>
                    <div className='userprofileImageBox'>
                      <img
                        src={
                          productDetail.productDetails[0].sellerDetails
                            .profilePictureURL
                            ? productDetail.productDetails[0].sellerDetails
                              .profilePictureURL
                            : placeholderProfile
                        }
                        onError={e => {
                          e.target.onerror = null
                          e.target.src = placeholderProfile
                        }}
                        alt='userprofileImage'
                        className='userprofileImage'
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className='userProfileName'>
                      {ShowNameAccordingToLength(
                        productDetail &&
                            productDetail.productDetails &&
                            productDetail.productDetails[0].sellerDetails &&
                            productDetail.productDetails[0].sellerDetails
                              .firstName
                          ? productDetail.productDetails[0].sellerDetails
                            .firstName
                          : '' + ' ' + productDetail &&
                              productDetail.productDetails &&
                              productDetail.productDetails[0].sellerDetails &&
                              productDetail.productDetails[0].sellerDetails
                                .lastName
                            ? productDetail.productDetails[0].sellerDetails
                              .lastName
                            : ''
                      )}
                    </p>
                  </div>
                </div>
              )}
              <div
                className='borderBottom1Dashed'
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              <div>
                <div className='invoiceFileTitle'>Shipping Document:</div>

                {productDetail && productDetail.shippingDocumentURL && (
                  <div className='preViewInvoiceFileBox'>
                    <iframe
                      src={productDetail.shippingDocumentURL}
                      frameBorder='0'
                      scrolling='auto'
                      height='100%'
                      width='100%'
                      style={{ minHeight: 315, minWidth: 315 }}
                    />
                  </div>
                )}
                <div style={{ textAlign: 'center' }}>
                  <Button
                    className='downloadButton'
                    onClick={() => {
                      // this.downloadInvoice()

                      const { orderDetail } = this.state
                      saveAs(orderDetail.invoiceURL, orderDetail.invoice)
                    }}
                    data-rh='Download'
                  >
                    <img
                      style={{ width: 15, marginRight: 5 }}
                      src={downloadInvoice}
                      alt='downloadIcon'
                    />
                    Download
                  </Button>
                </div>

                <div style={{ marginTop: 10 }}>
                  <div className='invoiceFileTitle'>
                    Shipped via {productDetail && productDetail.shippingCompany}
                  </div>
                  <div
                    style={{ paddingLeft: 50, opacity: 1 }}
                    className='invoiceFileTitle'
                  >
                    <p style={{ margin: 0 }}>Tracking ID</p>
                    <div
                      style={{ display: 'inline-flex', alignItems: 'center' }}
                      id='trackingCode'
                    >
                      {productDetail && productDetail.trackingNumber}
                      <Button
                        className='fileRemoveIconStyle'
                        onClick={() => {
                          var copyText = document.getElementById('trackingCode')
                          var textArea = document.createElement('textarea')
                          textArea.value = copyText.textContent
                          document.body.appendChild(textArea)
                          textArea.select()
                          document.execCommand('Copy')
                          textArea.remove()

                          this.setState(
                            {
                              copyText: true
                            },
                            () => {
                              setTimeout(() => {
                                this.setState({
                                  copyText: false
                                })
                              }, 1500)
                            }
                          )

                          // this.setState({
                          //   previewPayInvoiceFile: '',
                          //   selectedPayInvoiceFile: ''
                          // })
                        }}
                        style={{ paddingTop: 2 }}
                      >
                        <img
                          style={{ width: 20, height: 20 }}
                          src={copyIcon}
                          alt='fileRemoveIcon'
                        />
                      </Button>
                      {this.state.copyText && (
                        <span style={{ color: '#32CD32', marginLeft: 5 }}>
                          Copied!
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {productDetail && productDetail.trackingURL && (
                  <div style={{ textAlign: 'center', marginTop: 10 }}>
                    <Button
                      className='payForOrderBlueButton'
                      onClick={() => {
                        window.open(productDetail.trackingURL, '_blank')
                      }}
                      data-rh='View Tracking'
                    >
                      View Tracking
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size='sm'
          isOpen={raisedDispute}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody className='paymentPreviewModal'>
            <div>
              <p className='headerTitle'>Raise Dispute...</p>

              {productDetail &&
                productDetail.productDetails &&
                productDetail.productDetails[0] &&
                productDetail.productDetails[0].sellerDetails &&
                productDetail.productDetails[0].sellerDetails.firstName && (
                <div className='userInfoPreviewBox'>
                  <div>
                    <div className='userprofileImageBox'>
                      <img
                        src={
                          productDetail.productDetails[0].sellerDetails
                            .profilePictureURL
                            ? productDetail.productDetails[0].sellerDetails
                              .profilePictureURL
                            : placeholderProfile
                        }
                        onError={e => {
                          e.target.onerror = null
                          e.target.src = placeholderProfile
                        }}
                        alt='userprofileImage'
                        className='userprofileImage'
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className='userProfileName'>
                      {ShowNameAccordingToLength(
                        productDetail &&
                            productDetail.productDetails &&
                            productDetail.productDetails[0].sellerDetails &&
                            productDetail.productDetails[0].sellerDetails
                              .firstName
                          ? productDetail.productDetails[0].sellerDetails
                            .firstName + ' '
                          : '' + productDetail &&
                              productDetail.productDetails &&
                              productDetail.productDetails[0].sellerDetails &&
                              productDetail.productDetails[0].sellerDetails
                                .lastName
                            ? productDetail.productDetails[0].sellerDetails
                              .lastName
                            : ''
                      )}
                    </p>
                  </div>
                </div>
              )}
              <div
                className='borderBottom1Dashed'
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              <div>
                <div className='invoiceFileTitle'>Dispute Reason:</div>
                <div style={{ marginTop: 10 }}>
                  <div>
                    <textarea
                      className='buyerDisputeReason'
                      placeholder='Enter Reason...'
                      value={CapitalizeFirstLetter(disputeReason)}
                      onChange={e =>
                        this.setState({ disputeReason: e.target.value })
                      }
                    />
                  </div>
                </div>{' '}
                <div style={{ textAlign: 'center' }}>
                  <Button
                    className='payForOrderBlueButton'
                    onClick={() => this.doBuyerDispute()}
                    data-rh='Submit'
                    disabled={!disputeReason}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size='sm'
          isOpen={viewraisedDispute}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody className='paymentPreviewModal'>
            <div>
              <p className='headerTitle'>View Dispute...</p>

              {productDetail &&
                productDetail.productDetails &&
                productDetail.productDetails[0] &&
                productDetail.productDetails[0].sellerDetails &&
                productDetail.productDetails[0].sellerDetails.firstName && (
                <div className='userInfoPreviewBox'>
                  <div>
                    <div className='userprofileImageBox'>
                      <img
                        src={
                          productDetail.productDetails[0].sellerDetails
                            .profilePictureURL
                            ? productDetail.productDetails[0].sellerDetails
                              .profilePictureURL
                            : placeholderProfile
                        }
                        onError={e => {
                          e.target.onerror = null
                          e.target.src = placeholderProfile
                        }}
                        alt='userprofileImage'
                        className='userprofileImage'
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className='userProfileName'>
                      {ShowNameAccordingToLength(
                        productDetail &&
                            productDetail.productDetails &&
                            productDetail.productDetails[0].sellerDetails &&
                            productDetail.productDetails[0].sellerDetails
                              .firstName
                          ? productDetail.productDetails[0].sellerDetails
                            .firstName + ' '
                          : '' + productDetail &&
                              productDetail.productDetails &&
                              productDetail.productDetails[0].sellerDetails &&
                              productDetail.productDetails[0].sellerDetails
                                .lastName
                            ? productDetail.productDetails[0].sellerDetails
                              .lastName
                            : ''
                      )}
                    </p>
                  </div>
                </div>
              )}
              <div
                className='borderBottom1Dashed'
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              <div>
                <div className='invoiceFileTitle'>Dispute Reason:</div>

                <div style={{ marginTop: 10 }}>
                  <div>
                    <p className='invoiceFileTitle' style={{ opacity: 1 }}>
                      {orderDetail && orderDetail.disputeReason
                        ? orderDetail.disputeReason
                        : ''}
                    </p>

                    {orderDetail && orderDetail.disputeResolution && (
                      <div>
                        <div className='invoiceFileTitle'>
                          Dispute Resolution:
                        </div>
                        <p className='invoiceFileTitle' style={{ opacity: 1 }}>
                          {orderDetail && orderDetail.disputeResolution
                            ? orderDetail.disputeResolution
                            : ''}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size='sm'
          isOpen={returnShippingInfo}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody
            className='paymentPreviewModal'
            id='PlaceOrderDetailsPageViewpaymentPreviewModal197'
          >
            <div>
              <p className='headerTitle'>Return for Order...</p>

              {productDetail &&
                productDetail.productDetails &&
                productDetail.productDetails[0] &&
                productDetail.productDetails[0].sellerDetails &&
                productDetail.productDetails[0].sellerDetails.firstName && (
                <div className='userInfoPreviewBox'>
                  <div>
                    <div className='userprofileImageBox'>
                      <img
                        src={
                          productDetail.productDetails[0].sellerDetails
                            .profilePictureURL
                            ? productDetail.productDetails[0].sellerDetails
                              .profilePictureURL
                            : placeholderProfile
                        }
                        onError={e => {
                          e.target.onerror = null
                          e.target.src = placeholderProfile
                        }}
                        alt='userprofileImage'
                        className='userprofileImage'
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className='userProfileName'>
                      {ShowNameAccordingToLength(
                        productDetail &&
                            productDetail.productDetails &&
                            productDetail.productDetails[0].sellerDetails &&
                            productDetail.productDetails[0].sellerDetails
                              .firstName
                          ? productDetail.productDetails[0].sellerDetails
                            .firstName + ' '
                          : '' + productDetail &&
                              productDetail.productDetails &&
                              productDetail.productDetails[0].sellerDetails &&
                              productDetail.productDetails[0].sellerDetails
                                .lastName
                            ? productDetail.productDetails[0].sellerDetails
                              .lastName
                            : ''
                      )}
                    </p>
                  </div>
                </div>
              )}
              <div
                className='borderBottom1Dashed'
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              <div>
                <div className='confirmReturnShipmentDropdown'>
                  <SelectInput
                    items={this.state.shippingAgentList}
                    value={this.state.selectShippingAgent}
                    className='buyerconfirmShipmentTypeDropdown'
                    dropDownContainerClass='buyerconfirmShipmentTypeContainerDropdown'
                    onChange={value => {
                      if(value){

                      this.setState({
                        selectShippingAgent: value
                      },()=>{
                        this.updateTrackURL();
                      })
                    }

                    }}
                    placeholder='Shipping Agent'
                  />
                
                  {/* <Dropdown
                    className='confirmReturnShipmentTypeDropdown'
                    placeholder='Shipping Agent'
                    value={this.state.selectShippingAgent}
                    options={this.state.shippingAgentList}
                    onChange={e => {
                      if (e && e.value) {
                        this.setState(
                          {
                            selectShippingAgent: e.value
                          },
                          () => {
                            this.updateTrackURL()
                          }
                        )
                      }
                    }}
                  /> */}
                </div>

                <div
                  style={{
                    textAlign: 'center',
                    marginTop: 20,
                    marginBottom: 15
                  }}
                >
                  <input
                    value={this.state.trackingNumber}
                    type='text'
                    className='confirmReturnShipmentTrackNumberField'
                    placeholder='Tracking Number'
                    onChange={e => {
                      if (e && e.target && e.target.value) {
                        this.setState(
                          {
                            trackingNumber: e.target.value
                          },
                          () => {
                            this.updateTrackURL()
                          }
                        )
                      } else {
                        this.setState(
                          {
                            trackingNumber: ''
                          },
                          () => {
                            this.updateTrackURL()
                          }
                        )
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    wordBreak: 'break-all',
                    marginTop: 20,
                    marginBottom: 15
                  }}
                >
                  Tracking URL : {this.state.trackingURL}
                  {/* <input value={this.state.trackingURL} type="text" className="confirmReturnShipmentTrackNumberField" placeholder="Tracking Number"
    onChange={e => {
      if(e && e.target && e.target.value){
        this.setState ({
          trackingURL: e.target.value,
      });
      }else{
        this.setState ({
          trackingURL:'',
      });
      }

    }}
  /> */}
                </div>

                <div className='invoiceFileTitle'>
                  Upload proof of shipment receipt:
                </div>

                <div style={{ textAlign: 'right' }}>
                  <label
                    className='selectUploadProofButton'
                    data-rh='Select File'
                  >
                    Select
                    <input
                      type='file'
                      name='selectProof'
                      accept='image/jpeg,application/pdf'
                      onChange={e => this.onChangeSelectShipmentProof(e)}
                    />
                  </label>
                </div>
                {!selectedShipmentReceip && (
                  <div
                    className='invoiceFileTitle'
                    style={{ marginTop: 10, marginBottom: 5, opacity: 0.5 }}
                  >
                    PDF or JPG files only. Max: 5 MB
                  </div>
                )}

                {selectedShipmentReceip && selectedShipmentReceip.name && (
                  <div style={{ display: 'inline-flex' }}>
                    <a
                      className='previewInvoiceFilename'
                      href={previewShipmentReceip}
                      target='_blank'
                    >
                      {selectedShipmentReceip.name}
                    </a>

                    <Button
                      className='fileRemoveIconStyle'
                      onClick={() => {
                        this.setState({
                          previewShipmentReceip: '',
                          selectedShipmentReceip: ''
                        })
                      }}
                    >
                      <img
                        style={{ width: 20, height: 20 }}
                        src={fileRemoveIcon}
                        alt='fileRemoveIcon'
                      />
                    </Button>
                  </div>
                )}
                {previewShipmentReceip && (
                  <div className='preViewInvoiceFileBox'>
                    <iframe
                      src={previewShipmentReceip}
                      frameBorder='0'
                      scrolling='auto'
                      height='100%'
                      width='100%'
                      style={{ minHeight: 315, minWidth: 315 }}
                    />
                  </div>
                )}
                <div style={{ textAlign: 'center' }}>
                  <Button
                    className='payForOrderBlueButton'
                    onClick={() => this.doRefundOrderProduct()}
                    data-rh='Submit'
                    disabled={
                      !previewShipmentReceip ||
                      !this.state.trackingNumber ||
                      !this.state.selectShippingAgent ||
                      !this.state.trackingURL
                    }
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size='sm'
          isOpen={confirmRefundPayment}
          centered
          toggle={() => this.closeModal()}
        >
          <ModalBody
            className='paymentPreviewModal'
            id='PlaceOrderDetailsPageViewpaymentPreviewModal197'
          >
            <div>
              <p className='headerTitle'>Refund...</p>

              {productDetail &&
                productDetail.productDetails &&
                productDetail.productDetails[0] &&
                productDetail.productDetails[0].sellerDetails &&
                productDetail.productDetails[0].sellerDetails.firstName && (
                <div className='userInfoPreviewBox'>
                  <div>
                    <div className='userprofileImageBox'>
                      <img
                        src={
                          productDetail.productDetails[0].sellerDetails
                            .profilePictureURL
                            ? productDetail.productDetails[0].sellerDetails
                              .profilePictureURL
                            : placeholderProfile
                        }
                        onError={e => {
                          e.target.onerror = null
                          e.target.src = placeholderProfile
                        }}
                        alt='userprofileImage'
                        className='userprofileImage'
                      />
                    </div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p className='userProfileName'>
                      {ShowNameAccordingToLength(
                        productDetail &&
                            productDetail.productDetails &&
                            productDetail.productDetails[0].sellerDetails &&
                            productDetail.productDetails[0].sellerDetails
                              .firstName
                          ? productDetail.productDetails[0].sellerDetails
                            .firstName+ ' '
                          : '' + productDetail &&
                              productDetail.productDetails &&
                              productDetail.productDetails[0].sellerDetails &&
                              productDetail.productDetails[0].sellerDetails
                                .lastName
                            ? productDetail.productDetails[0].sellerDetails
                              .lastName
                            : ''
                      )}
                    </p>
                  </div>
                </div>
              )}
              <div
                className='borderBottom1Dashed'
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              <div>
                {/* <div className='invoiceFileTitle'>Upload proof of refund receipt:</div>

                <div style={{ textAlign: 'right' }}>
                  <label
                    className='selectUploadProofButton'
                    data-rh='Select File'
                  >
                    Select
                    <input
                      type='file'
                      name='selectProof'
                      accept='image/jpeg,application/pdf'
                      onChange={e => this.onChangeSelectRefundProof(e)}

                    />
                  </label>
                </div>
                {!selectedRefundReceip && (
                  <div
                    className='invoiceFileTitle'
                    style={{ marginTop: 10, marginBottom: 5, opacity: 0.5 }}
                  >
                    PDF or JPG files only. Max: 5 MB
                  </div>
                )} */}

                {orderDetail && orderDetail.refundReceipt && (
                  <div style={{ display: 'inline-flex' }}>
                    <a
                      className='previewInvoiceFilename'
                      href={orderDetail.refundReceiptURL}
                      target='_blank'
                    >
                      {orderDetail.refundReceipt}
                    </a>

                    {/* <Button
                      className='fileRemoveIconStyle'
                      onClick={() => {
                        this.setState({
                          selectedRefundReceip: '',
                          previewRefundReceip: ''
                        })
                      }}
                    >
                      <img
                        style={{ width: 20, height: 20 }}
                        src={fileRemoveIcon}
                        alt='fileRemoveIcon'
                      />
                    </Button> */}
                  </div>
                )}
                {orderDetail && orderDetail.refundReceiptURL && (
                  <div className='preViewInvoiceFileBox'>
                    <iframe
                      src={orderDetail.refundReceiptURL}
                      frameBorder='0'
                      scrolling='auto'
                      height='100%'
                      width='100%'
                      style={{ minHeight: 315, minWidth: 315 }}
                    />
                  </div>
                )}

                {orderDetail &&
                  orderDetail.paymentType &&
                  orderDetail.paymentType === 'internal' && (
                  <div>Please check in your RDEE wallet.</div>
                )}
                {/* <div style={{ textAlign: 'center' }}>
                  <Button
                    className='payForOrderBlueButton'
                    onClick={() => this.doConfirmRefundOrderPayment()}
                    data-rh='Submit'
                    disabled={!previewRefundReceip}
                  >
                    Submit refund
                  </Button>
                </div> */}
              </div>
            </div>
          </ModalBody>
        </Modal>

        {imagePreviewModal && (
          <ProductZoomDetail
            productDetail={
              orderDetail &&
              orderDetail.productDetails &&
              orderDetail.productDetails[0]
                ? orderDetail.productDetails[0]
                : []
            }
            imgIndex={imgIndex}
            closeModal={this.closeModal}
            showImage={this.showImage}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    // placeOrderDetail: state.MarketplaceReducer,
    payment: state.PaymentReducer,
    product: state.MarketplaceReducer
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getMarketPlaceOrder,
      getTransactionHistory,
      resetdoOrderValue,
      payOrderItem,
      marketplaceOrderDeliveredStatus,
      buyerGiveReviewToseller,
      cancelPlaceOrder,
      doCompleteOrderItem,
      getProfileDashboard,
      doMarketplaceOrderPaidStatus,
      marketPlaceRaisedDisputeBuyerSide,
      marketPlaceOrderReturnedBuyerSide,
      marketPlaceReturnedCompleteBuyerSide,
      resetOrderList
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlacedOrderDetails)
