import { getLabelList } from "./../api";
// import { setSearchQuery } from "../content/message-list/actions/message-list.actions";
import {GET_LABELS ,SELECT_LABEL} from './../../../reducers/emailreducer/sidebarReducer'



export const getLabels = () => dispatch => {
  getLabelList().then(labelList => {
    // console.log(labelList);
    dispatch({ type: GET_LABELS,payload: labelList});
  });
};

export const selectLabel = (labelId) => dispatch => {
  console.log(labelId);
  // dispatch(setSearchQuery(""));
  dispatch({
    type: SELECT_LABEL,
    payload: labelId
  });
};