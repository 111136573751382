



 import React, {Component,useState} from 'react';
// import { SignInLink } from "./SignIn";
import {
  Button,
} from 'reactstrap';
// import * as routes from "../constants/routes";
// import logo from '../   ../../../logo.svg';
import logo from '../logoBlack.png'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import './Service-On-Demand-CommanComponent.css';
  

  

class ServiceOnDemandCommanComponentPage extends Component {
  constructor (props) {
    super (props);
    this.state={
      isOpened:false
    }
  }
  toggle = () => {
    this.setState({
      isOpened:!this.state.isOpened
    })
  }

  //  toggleMenu = event => {
  //   if (event.target.matches ('.openmenu')) {
  //     var element = document.getElementById ('sidebarmenu');
  //     if (element.matches ('.menuDisplayHide')) {
  //       element.classList.remove ('menuDisplayHide');
  //     } else {
  //       element.classList.add ('menuDisplayHide');
  //     }
  //   }
  // };


  toggleMenu = event => {
    if (event.target.matches ('.openmenu')) {
      var element = document.getElementById ('sidebarmenu');
      setTimeout(()=>{
      if (element.matches ('.menuDisplayHide')) {
        element.classList.remove ('menuDisplayHide');
      } else {
          element.classList.add ('menuDisplayHide');
      }
    },200)
    }
  };
  

  detailPage = () => {
    // this.props.history.push (routes.SERVICEONDEMANDCREATESERVICEREQUEST_DETAIL);
  };
  
  render () {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          // background: 'black',
        }}
     >
          <Button className="menuButtonForComman openmenu" onClick={this.toggleMenu}>
            <img src={logo} alt="Menu icon" className="openmenu" />
          </Button>
       
       </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    //   payment: state.PaymentReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      // createNewWallet,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  ServiceOnDemandCommanComponentPage
);
