import React, {Component} from 'react';
import {Button} from 'reactstrap';
import ChatIcon from '../../../images/sidebar/chat.png';

import '../contacts.css';
import _ from 'lodash'

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {getAllUserContacts_v2, getUserDetail ,getAllUserContactsFunc} from '../../../actions/contacts';

import * as routes from '../../../constants/routes';
import profilePlaceholder from './../../../images/profile_placeholder.png';
import bluePlusIcon from './../../../images/icons/bluePlusIcon.svg';

import searchIcon from './../../../images/icons/search.svg';
import { ShowNameAccordingToLength } from '../../../utility/stringFunctions';
import Skeleton from 'react-loading-skeleton'
const WAIT_INTERVAL = 1000;

const contactBoxAnimation=()=>(
                <div className="contactListing" >

                  <div className="contactListing-image-container">
                  <Skeleton circle={true} height={50} width={50} />
                  </div>

                  <div className="contactListing-info">
                  <Skeleton count={3} width={300} />

                  </div>
          </div>
)

class UserContactListPage extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      searchText: '',
      contactList: [],
      searching: '',
      isloadedContent:false
    };
    this.triggerChange=this.triggerChange.bind(this);
  }
  

  componentDidMount () {
    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
    let sendRequest = {
       data: {
        searchString:''
      }
    };
    this.props.getAllUserContactsFunc (sendRequest);
  }

  goToBusinessprofile () {
    this.props.history.push (routes.BUSINESSDETAIL + '/1');
  }
  changeText = event => {
    this.setState ({searchText: event.target.value});
  };
  goToChat (item) {
 
    let userChatInfo = {
      oppositeUserUid: item.contact.contactDetail.docID,
      profilePictureURL: item.contact.contactDetail &&
        item.contact.contactDetail.profilePictureURL
        ? item.contact.contactDetail.profilePictureURL
        : profilePlaceholder,
        oppositeUserDisplayName: _.get(item.contact,'contactDetail.firstName', '')+' '+ _.get(item.contact,'contactDetail.lastName', '')
    };
    // this.props.history.push(routes.MESSENGER, {userChatInfo: userChatInfo} );
  }

  componentWillReceiveProps (nextProps) {

    if (nextProps) {
      if (
        nextProps.contacts
      ) {
        this.setState (
          {
            contactList: nextProps.contacts,
            isloadedContent:true
          },
          () => {
         
          }
        );
      }
    }
  }

  goToprofile (item) {
    if (item) {
      let sendRequest = {
        data: {
          getPersonalDetails: true,
          getProfessionalDetails: true,
          getEducationalDetails: true,
          getBusinessProfile: true,
          docID: item.docID,
        },
      };
      this.props.getUserDetail (sendRequest);

      this.props.history.push (routes.CONTACTUSERDETAIL + `/${item.docID}`);
    }
  }
  onSearchItems(value){

    clearTimeout (this.timer);
    
    if (this.state.searching.length > 1) {
      this.timer = setTimeout (this.triggerChange, WAIT_INTERVAL);
    }
    if (!this.state.searching) {
      this.timer = setTimeout (this.triggerChange, WAIT_INTERVAL);
    }
  }

  triggerChange(){
    this.props.getAllUserContactsFunc({ data: {
      searchString:this.state.searching
    } });
  }

  render () {
    let searchText = this.state.searchText;
    const {contactList ,isloadedContent} = this.state;
    console.log(contactList);

    let filterContacts = this.state.searching
      ? contactList.filter (
          row =>
            (row.contact &&
              row.contact.contactDetail &&
              row.contact.contactDetail.displayName &&
              row.contact.contactDetail.displayName
                .toLowerCase ()
                .indexOf (this.state.searching.toLowerCase ()) > -1) ||
            (row.contact &&
              row.contact.contactDetail &&
              row.contact.contactDetail.searchHandle &&
              row.contact.contactDetail.searchHandle
                .toLowerCase ()
                .indexOf (this.state.searching.toLowerCase ()) > -1)
        )
      : contactList;

    return (
      <div>
        {/*<div className="myProfileTitle">
            {' '}
            Contacts
            
    </div>*/}
    <div className="contactHeader">
            <div className="titleContact">
              Contacts
            </div>
            <div className="titleContact">
              <Button className="newContactButton" onClick={()=>this.props.history.push(routes.CONTACT)}> 
                <img style={{width:20,height:20,marginRight:10}}  src={bluePlusIcon} alt="bluePlusIcon" />
              New Contact</Button>
            </div>
          </div>
        <div className="contactHeader">
          <div className="contactsearchBoxInput">
            <img src={searchIcon} alt="Search" className="searchContactIcon" />
            <input
              type="text"
              className="searchInput"
              placeholder="Search by name or handle"
              value={this.state.searching}
              // onChange={e => this.setState ({searching: e.target.value})}

              onChange={e => this.setState({ searching: e.target.value },()=>{

                this.onSearchItems(this.state.searching);
                
                
              })}

            />
          </div>
        </div>
        <div className="contactScrollView ContactgradientBg1">
          {contactList &&
            contactList.length > 0 &&
            contactList.map ((item, index) => (
              <div key={index} onClick={() => this.goToChat (item)}>

                {/*<Button
              className="chatButton1 "
              onClick={() => this.goToChat (item)}
            >
              <img src={ChatIcon} alt="chat Iconss" />
            </Button>*/}
                <div
                  className="contactListing"
                  onClick={() => this.goToprofile (item.contact.contactDetail)}
                >

                  <div className="contactListing-image-container">
                    <img
                      src={
                        item.contact &&
                          item.contact.contactDetail &&
                          item.contact.contactDetail.profilePictureURL
                          ? item.contact.contactDetail.profilePictureURL
                          : profilePlaceholder
                      }
                      className="contactListing-image"
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = profilePlaceholder;
                      }}
                      alt="contactListingimage"

                    />
                  </div>

                  <div className="contactListing-info">

                    <div className="contactListing-name">

                    {item.contact &&
                      item.contact.contactDetail ? ShowNameAccordingToLength(item.contact.contactDetail.firstName + ' ' +item.contact.contactDetail.lastName):''}


                    
                      {/*item.contact &&
                        item.contact.contactDetail &&
                        item.contact.contactDetail.lastName
                        ? item.contact.contactDetail.lastName
                      : ''*/}
                    </div>
                    <div className="contactListing-profile">
                      {item.contact &&
                        item.contact.contactDetail &&
                        item.contact.contactDetail.position
                        ? item.contact.contactDetail.position
                        : ''}
                      {' '}
                    </div>
                    <div className="contactListing-moreInfo">
                      {item.contact &&
                        item.contact.contactDetail &&
                        item.contact.contactDetail.professionalTitle
                        ? item.contact.contactDetail.professionalTitle
                        : ''}
                    </div>

                  </div>
                </div>
              </div>
            ))}

          {isloadedContent && contactList &&
            contactList.length === 0 &&
            <div className="no_contact_data">
              There are no contacts to show.
            </div>}

            {!isloadedContent &&  
            
            
            <Skeleton count={5} wrapper={contactBoxAnimation}/>
            
            
            }

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.ContactsReducer.contacts,
    
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getAllUserContactsFunc,
      getUserDetail,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  UserContactListPage
);
