import React, { Component } from 'react';
// import { Link, withRouter } from 'react-router-dom';
import './landingPage.scss';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as routes from '../../constants/routes';
import { Loading } from '../../actions/LoadingAction';

import radiologexLogo from './../../images/logo/radiologex-logo.svg';

class LandingPage extends Component {
  //defining state
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.props.Loading(false);
  }

  render() {
    return (
      <div className='landingmaindiv gradientColorPurpleBlue'>
        <img className='logo' src={radiologexLogo} alt='radiologexLogo' />
        <div className='optionBox'>
          <div className='MeetRDEE'>
            Meet <span style={{ fontWeight: 'bold' }}>R-DEE</span>{' '}
          </div>
          <div className='TheSecure'>
            The Secure, All-in-One Network and<br></br>
            Productivity Suite Built for the<br></br> Medical Industry.
          </div>
          <form className='landingPageForm'>
            <button
              className='landbutton1'
              onClick={() => this.props.history.push(routes.SIGN_UP)}
              data-rh='Register'
            >
              Register
            </button>
            <button
              className='landloginbutt'
              onClick={() => this.props.history.push(routes.SIGN_IN)}
              data-rh='Login'
            >
              Login
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // contact: state.ContactsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      Loading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
