import React, {Component} from 'react';
import {Button} from 'reactstrap';
// import ServiceOnDemandProfile
//   from '../../../service-On-Demand-Profile/serviceOnDemandProfile';
import ServiceOnDemandSidebarPage
  from '../../../../components/serviceOnDemandSidebar/serviceOnDemandSidebar';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import './serviceProfile.css';
import provider1 from '../../../../images/provider1.png';
import work from '../../../../images/work.png';
import build from '../../../../images/build.png';
import loc from '../../../../images/loc.png';
import buttonframe from '../../../../images/buttonframe.png';
import star from '../../../../images/serviceOnDemandSearch/star.png';
import roman from '../../../../images/serviceOnDemandSearch/roman.png';
import settingCard2 from '../../../../images/serviceOnDemand/settingCard2.png';
import greygear from '../../../../images/serviceOnDemandMyRequest/greygear.png';
import acivity from '../../../../images/serviceOnDemandProfile/acivity.png';
import * as routes from '../../../../constants/routes';
import profile_placeholder from '../../../../images/profile_placeholder.png';
import ServiceOnDemandCommonComponent
  from '../../../Service-On-Demand-CommanComponent/Service-On-Demand-CommanComponent';
  import StarRatingComponent from 'react-star-rating-component';

import {
  GetServiceProfile,
  CreateServiceProfile,
} from '../../../../actions/serviceOnDemandAction';
import {getProfessionalDetail} from '../../../../actions/Authaction';
class serviceOnDemandServiceProfilePage extends Component {
  constructor (props) {
    super (props);
    this.state = {
      reviewDiv: false,
      serviceProfileData: [],
      professionalDetail: [],
      personalDetail: [],
      servicesOffered: [],
    };
    this.openreviewDiv = this.openreviewDiv.bind (this);
  }
  openreviewDiv () {
    this.setState ({reviewDiv: true});
  }
  closereviewDiv = () => {
    this.setState ({reviewDiv: false});
  };

  componentWillReceiveProps (nextProps) {
  
    if (nextProps) {
      if (
        nextProps &&
        nextProps.serviceProfile &&
        nextProps.serviceProfile.serviceProfile
      ) {
        this.setState (
          {
            serviceProfileData: nextProps.serviceProfile.serviceProfile,
            professionalDetail: nextProps.serviceProfile.serviceProfile
              .businessDetails,
            servicesOffered: nextProps.serviceProfile.serviceProfile
              .servicesOffered,
          },
          () => {
     
          }
        );
     
      }
      // if(nextProps && nextProps.professionalDetail && nextProps.professionalDetail.user &&
      //   nextProps.professionalDetail.user.Data && nextProps.professionalDetail.user.Data.userDetails[1]&&
      //   nextProps.professionalDetail.user.Data.userDetails[1].professionalDetails[0]){
      //  this.setState({professionalDetail: nextProps.professionalDetail.user.Data.userDetails[1].professionalDetails[0]})
      // this.setState({personalDetail: nextProps.professionalDetail.user.Data.userDetails[0].personalDetails})
      // }
    }
  }
  componentDidMount () {
    let geturl = this.props.match.params;
    if (geturl && geturl.providerId) {
      this.setState (
        {
          providerId: geturl.providerId,
        },
        () => {
          let sendRequest = {
            data: {
              docID: this.state.providerId,
            },
          };
          this.props.GetServiceProfile (sendRequest);
        }
      );
    }
  }
  render () {
    const {
      serviceProfileData,
      professionalDetail,
      personalDetail,
      servicesOffered,
    } = this.state;
    return (
      <div style={{display: 'flex', background: '#FFFFFF', height: '100%'}}>
        <div className="inlineFlex100ProviderProfile">
          {/*------------First----------*/}
          <div className="parentRowProviderProfile">

            <div style={{padding: 15}}>
              <div style={{width: 230}}>
                <ServiceOnDemandCommonComponent />
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                  <div className="vieROV">
                    SERVICE PROVIDER DASHBOARD
                  </div>
                  <div className="viewVIDERPR">
                    MY PROVIDER PROFILE
                  </div>
                </div>
                <div onClick={this.closereviewDiv} style={{cursor: 'pointer'}}>
                  <img alt="" src={provider1} />
                  <span className="GOBACK"> GO BACK </span>
                </div>
              </div>
              <div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div>
                    <div className="viewdrjohn">
                      {serviceProfileData && serviceProfileData.title
                        ? serviceProfileData.title
                        : ' '}
                      {' '}
                      {serviceProfileData && serviceProfileData.displayName
                        ? serviceProfileData.displayName
                        : serviceProfileData && serviceProfileData.firstName
                            ? serviceProfileData.firstName
                            : '' +
                                ' ' +
                                (serviceProfileData &&
                                  serviceProfileData.lastName)
                                ? serviceProfileData.lastName
                                : ''}
                    </div>
                    <div>
                      <img alt="" src={work} />
                      <span className="viewCo-Founder">
                        {' '}{' '}
                        {professionalDetail && professionalDetail.position
                          ? professionalDetail.position
                          : ' '}
                      </span>
                    </div>
                    <div>
                      <img alt="" src={build} />
                      <span className="viewEverus">
                        {' '}
                        {' '}
                        {professionalDetail && professionalDetail.firmName
                          ? professionalDetail.firmName
                          : ' '}
                      </span>
                    </div>
                    <div>
                      <img alt="" src={loc} />
                      <span className="viewElMonte">
                        {' '}
                        {' '}{' '}
                        {personalDetail && personalDetail.address1
                          ? personalDetail.address1
                          : ' '}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div style={{display: 'flex', paddingTop: 20}}>
                      <div>
                        <div className="vieerate">
                          4.7 / 5.0{' '}
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <StarRatingComponent
                            name={'rating'}
                            starCount={5}
                            // value={
                            //   userProfile &&
                            //     userProfile.serviceProfile &&
                            //     userProfile.serviceProfile.totalServicesOffered
                            //     ? userProfile.serviceProfile.totalServicesOffered
                            //     : 0
                            // }
                            emptyStarColor={'#E4E4E4'}
                            starColor={'#F5D028'}
                            style={{fontSize: '80px'}}
                            // onStarClick={this.onStarClick}
                          />
                        </div>
                        <div className="viewReviews4">
                          4 Reviews
                        </div>
                      </div>
                      <div>
                        <img
                          alt=""
                          src={
                            serviceProfileData &&
                              serviceProfileData.profilePictureURL
                              ? serviceProfileData.profilePictureURL
                              : profile_placeholder
                          }
                          className="servproimg"
                        />
                      </div>
                    </div>
                    <div>
                      <Button
                        className="vvieviewpro"
                        onClick={() =>
                          this.props.history.push (
                            routes.SERVICEONDEMAND_CREATE_SERVICE_PROFILE
                          )}
                      >
                        EDIT MY PROFILE
                        <img
                          alt=""
                          src={buttonframe}
                          style={{marginLeft: 10}}
                        />
                      </Button>
                    </div>
                  </div>

                </div>

              </div>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                  <div className="vieABOUTME">
                    ABOUT ME{' '}
                  </div>
                  <div className="vewlongtext">
                    <p style={{margin: 0}}>
                      {' '}
                      {serviceProfileData && serviceProfileData.serviceProfile
                        ? serviceProfileData.serviceProfile.aboutMe
                        : ' '}
                      {' '}
                    </p>
                    <p style={{margin: 0}}>
                      {' '}

                    </p>
                    <p style={{margin: 0}}> </p>
                  </div>
                </div>
                <div />
              </div>
              <div className="viServiceArea">
                Location (Service Area)
              </div>
              <div className="viFrancisco">
                {serviceProfileData && serviceProfileData.serviceProfile
                  ? serviceProfileData.serviceProfile.serviceArea
                  : ''}
              </div>

              {this.state.reviewDiv === false
                ? <div>

                    <div style={{display: 'flex', marginTop: 22}}>
                      <div className="viOFFERED">
                        SERVICES OFFERED
                      </div>
                      <div className="viRatingsReviews">
                        Ratings & Reviews
                      </div>
                    </div>
                    <div className="vieRectangle082">
                      <div className="vieGEServici">
                        GE Servicing
                      </div>
                      <div className="vieUSDperhou">
                        USD $85 per hour{' '}
                      </div>
                      <div>
                        <img alt="" src={settingCard2} style={{height: 25}} />
                        <span className="vieProvided29">
                          29 Services Provided
                        </span>
                      </div>
                      <div className="viratingno">
                        4.7 / 5.0
                      </div>
                      <div style={{textAlign: 'center'}}>
                        <StarRatingComponent
                          name={'rating'}
                          starCount={5}
                          // value={
                          //   userProfile &&
                          //     userProfile.serviceProfile &&
                          //     userProfile.serviceProfile.totalServicesOffered
                          //     ? userProfile.serviceProfile.totalServicesOffered
                          //     : 0
                          // }
                          emptyStarColor={'#E4E4E4'}
                          starColor={'#F5D028'}
                          style={{fontSize: '80px'}}
                          // onStarClick={this.onStarClick}
                        />
                      </div>
                      <div className="viViewAll" onClick={this.openreviewDiv}>
                        View All
                      </div>

                    </div>
                  </div>
                : <div>
                    <div className="vieallreviews"> ALL REVIEWS</div>
                    <div style={{display: 'flex'}}>
                      <div className="viewServicecard1" style={{marginTop: 12}}>
                        <div style={{display: 'flex'}}>
                          <div>
                            <img alt="" src={acivity} style={{width: 44}} />
                          </div>
                          <div style={{paddingLeft: 10}}>
                            <div className="viLouisWen">
                              Louis Wen
                            </div>
                            <div className="vitime">
                              06/08/2019; 4:32pm
                            </div>
                          </div>
                          <div>
                            <img
                              alt=""
                              src={star}
                              style={{width: 97, height: 17, marginLeft: 77}}
                            />
                          </div>
                        </div>
                        <div className="viewS-o-D">
                          Ms.Julie is very responsive on S-o-D. Whenever I
                          needed guide, she always replies within 1 hours
                          time. Her explaination is clear and detailed
                          enough to guide you in your problem!{' '}
                        </div>
                      </div>
                      <div
                        className="viewServicecard1"
                        style={{marginTop: 12, marginLeft: 10}}
                      >
                        <div style={{display: 'flex'}}>
                          <div>
                            <img alt="" src={acivity} style={{width: 44}} />
                          </div>
                          <div style={{paddingLeft: 10}}>
                            <div className="viLouisWen">
                              Louis Wen
                            </div>
                            <div className="vitime">
                              06/08/2019; 4:32pm
                            </div>
                          </div>
                          <div>
                            <img
                              alt=""
                              src={star}
                              style={{width: 97, height: 17, marginLeft: 77}}
                            />
                          </div>
                        </div>
                        <div className="viewS-o-D">
                          Ms.Julie is very responsive on S-o-D. Whenever I
                          needed guide, she always replies within 1 hours
                          time. Her explaination is clear and detailed
                          enough to guide you in your problem!{' '}
                        </div>
                      </div>
                    </div>
                  </div>}

            </div>
          </div>
          {/*------------First----------*/}

          {/*------------Second----------*/}
          <div className="notificationCSSProviderProfile">
            <ServiceOnDemandSidebarPage history={this.props.history}/>
          </div>
          {/*------------Second----------*/}

        </div>

      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    serviceProfile: state.serviceOnDemandReducer,
    professionalDetail: state.Authreducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      // createNewWallet,
      GetServiceProfile,
      getProfessionalDetail,
      CreateServiceProfile,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  serviceOnDemandServiceProfilePage
);
