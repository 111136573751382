import React, {Component} from 'react';
import { Button} from 'reactstrap';
import moment from 'moment';


import profilePlaceholder from '../../images/profile_placeholder.png';

import leftbackarrow from '../../images/icons/leftbackarrow.png';

import * as routes from '../../constants/routes';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import './highlights.css';

import {
  getHighLightListing,
} from '../../actions/hightLightAction';
import {dataFormat } from '../../utility/convertTimeString';


import {checkAvailableAccess} from '../../constants/localMessage/errorMessage';
import InfoSyscoming from '../../images/comingsoon/InfoSyscoming.svg';
import  CommonAccessContainer from '../UI/commonAccessContainer/commonAccessContainer';



class HighLightsPage extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      hightLightList: [],
      isAccess:true

    };
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps && nextProps.highlights) {
      if (nextProps.highlights.highLightList) {
        

          this.setState ({
            hightLightList: this.sortByDate(nextProps.highlights.highLightList)
          });
        }
      }
  }

  sortByDate(lising){

    lising.sort(function(a,b){
      return Number(new Date(b.lastUpdatedAtEpoch)) - Number(new Date(a.lastUpdatedAtEpoch));
    });
    return lising;
  }

  componentDidMount () {
    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));

    const sendRequest = {
      data: {
        limit: 50,
    	  offset: 0
      },
    };

    this.props.getHighLightListing (sendRequest);


    // if(checkAvailableAccess ("notifications") && checkAvailableAccess ("notifications")=== 'notifications'){
    //   this.setState({
    //     isAccess:true
    //   })

    //     const sendRequest = {
    //       data: {},
    //     };

    //     this.props.getHighLightListing (sendRequest);
    //   }else{
    //     this.setState({
    //       isAccess:false
    //     })
    //   }
  }

  render () {
    const {
      hightLightList,
      isAccess
    } = this.state;
    return (
      <div className="flex">

         <div className="highlightsContainer">
         <div className="highLightsTitle">
         MY RADIOLOGEX  <b> HIGHLIGHTS</b>
         </div>
         <div>
         
         <div style={{textAlign:'right'}}>
              <Button
                className="highLightbackbutton"
                onClick={() => this.props.history.goBack ()}
                data-rh="Go back"
              >
                <div>
                  <img src={leftbackarrow} alt="backIcon" />
                </div>

                Go <span> Back</span>
              </Button>
         
         </div>
         
          <div className="highLightOuterBox">


            {hightLightList && hightLightList.length>0 && hightLightList.map((item, i)=>(
              <div key={i} className="HightlishtsInnerContentBoxes"  onClick={()=>{
                window.open(item.redirectionLink, "_blank"); 
              }}>
                <div className="highfeedTitle">{item.type?item.type:'' }</div>
                <div className="imgsContentBox" style={{backgroundImage:`url(${item.imageURL})`}}/>

                <div>
                <p className="highContentDate">  {item && item.scheduledAtTimestamp
                  ? dataFormat(item.scheduledAtTimestamp)
                  : ''}</p>
                <p className="highContentTitle">{item && item.heading ? item.heading:''}</p>

                </div>
            
            </div>))}

            {hightLightList && hightLightList.length===0 &&<div className="noHightLightsFound">
                Data Not Found
              </div>}
          </div>
         </div>
          
         </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    highlights: state.hightLightReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getHighLightListing,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (HighLightsPage);
