import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Container, Row, Col, Button, Form, FormGroup, Label, Input, Alert } from "reactstrap";
import logo from "../logo.svg";
import * as routes from "../../constants/routes";
import { auth, db } from "../../firebase";

const BusinessDatabaseOneResultPage = ({ history }) => (
      <div className="div-flex-signup">
        <SignUpForm history={history} />
      </div>
);

//################### Sign Up Form ###################
const INITIAL_STATE = {
  username: "",
  email: "",
  passwordOne: "",
  perfectSearch: "",
  error: null,
  showingAlert: false
};

//A Higher order function with prop name as key and the value to be assigned to
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
});

class SignUpForm extends Component {
  //defining state
  state = {
    ...INITIAL_STATE
  };

  // onChange = (propName, value) => {
  //   this.setState({
  //     [propName]: value
  //   });
  // };

  onSubmit = event => {
    const { username, email, passwordOne } = this.state;
    const { history } = this.props;
    auth
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      //it the above functions resolves, reset the state to its initial state values, otherwise, set the error object
      .then(authUser => {
        //creating a user in the database after the sign up through Firebase auth API
        db.doCreateUser(authUser.user.uid, username, email)
          .then(() => {
            this.setState({
              ...INITIAL_STATE
            });
            history.push(routes.DASH); //redirects to Home Page
          })
          .catch(error => {
            this.setState(byPropKey("error", error));
            this.timer(); //show alert message for some seconds
          });
      })
      .catch(err => {
        this.setState(byPropKey("error", err));
        this.timer(); //show alert message for some seconds
      });

    event.preventDefault(); //prevents refreshing
  };

  timer = () => {
    this.setState({
      showingAlert: true
    });

    setTimeout(() => {
      this.setState({
        showingAlert: false
      });
    }, 4000);
  };

  render() {
    const {
      firstname,
      lastname,
      email,
      passwordOne,
      mobile,
      error,
      showingAlert,
      file,
      displayName,
      perfectSearch
    } = this.state;
    //a boolen to perform validation
    const isInvalid =
      passwordOne === "" ||
      email === "" ||
      firstname === "" ||
      mobile === "";

    return (
      <Row>
        {showingAlert && (
          <Alert color="danger" onLoad={this.timer}>
            {error.message}
          </Alert>
        )}
            <Col sm="12" md="12" lg="12" className='text-center'>
            <img src={logo} />
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" className='pClass'>
              <p>
              Look up for your business from our database.
              </p>
            </Col>
            <Col xs="12" sm="12" lg="4" md={{ size: 4, offset: 4 }} className="text-center" style={{color:"#fff"}}>
                <FormGroup> 
                <Input 
                    style={{color:"#fff"}}
                    className="commanButtonClass"
                    type="password"
                    name="password"
                    id="examplePassword1"
                    placeholder="Eastern Billion Med Technologies"
                    value={passwordOne}
                    onChange={e =>
                        this.setState(byPropKey("passwordOne", e.target.value))
                    }
                    />
                </FormGroup> 
            </Col>
            <Col xs="12" sm="12" md="12" lg="12" className='pClass'>
              <p style={{color:"#60FF52"}}>
              One Result Found
              </p>
            </Col>
            <Col xs="12" sm="12" md="4" lg="4" md={{ size: 4, offset: 4 }}>
              <p style={{color:"#8C8C8C",margin:15}}>
              Business Name
              <p style={{color:"#fff"}}>
                Eastern Billion Med Technologies
              </p>
              </p>
              <p style={{color:"#8C8C8C",margin:15}}>
              Business ID
              <p style={{color:"#fff"}}>
              10054339
              </p>
              </p>
              <p style={{color:"#8C8C8C",margin:15}}>
              Country
              <p style={{color:"#fff"}}>
              Europe
              </p>
              </p>
              <p style={{color:"#8C8C8C",margin:15}}>
              City
              <p style={{color:"#fff"}}>
              Berlin
              </p>
              </p>
            </Col>
            <Col xs="12" sm="12" lg="4" md={{ size: 4, offset: 4 }} className="text-center">
                <FormGroup> 
                <Link to={routes.INFORMATIONSUBMIT}>
                    <Button /* disabled={isInvalid} */ type="submit" className="commanButtonClass">
                    Next
                    </Button>
                    </Link>
                </FormGroup> 
            </Col>
        </Row> 
    );
  }
}

//################### Sign Up Link ###################
//used in the sign in when the user don't have an account registered yet
const SignUpLink = () => (
  <p>
    Don't have an account yet? <Link to={routes.SIGN_UP}>Register here</Link>
  </p>
);

//exports
export default withRouter(BusinessDatabaseOneResultPage); //using a HoC to get access to history
export { SignUpForm, SignUpLink };

// <form onSubmit={this.onSubmit}>
//   <input
//     value={username}
//     onChange={e => this.setState(byPropKey("username", e.target.value))}
//     // onChange={e => this.onChange("username", e.target.value)}
//     type="text"
//     placeholder="Full Name"
//   />
//   <input
//     value={email}
//     onChange={e => this.setState(byPropKey("email", e.target.value))}
//     type="text"
//     placeholder="Email Address"
//   />
//   <input
//     value={passwordOne}
//     onChange={e =>
//       this.setState(byPropKey("passwordOne", e.target.value))
//     }
//     type="password"
//     placeholder="Password"
//   />
//   <input
//     value={passwordTwo}
//     onChange={e =>
//       this.setState(byPropKey("passwordTwo", e.target.value))
//     }
//     type="password"
//     placeholder="Confirm Password"
//   />
//   <button disabled={isInvalid} type="submit">
//     Sign Up
//   </button>

//   {error && <p>{error.message}</p>}
// </form>
