import React, { Component } from 'react';
// Components
import { Button } from 'reactstrap';
// Icons
import logo from '../../../images/logo/radiologex-logo.svg';
import radiologexWhiteCube from '../../../images/sidebar/radiologexWhiteCube.svg';
// Styles
import './sideBarLogo.scss';

class SideBarLogo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: false,
    };
  }
  toggle = () => {
    this.setState({
      isOpened: !this.state.isOpened,
    });
  };

  //  toggleMenu = event => {
  //   if (event.target.matches ('.openmenu')) {
  //     var element = document.getElementById ('sidebarmenu');
  //     if (element.matches ('.menuDisplayHide')) {
  //       element.classList.remove ('menuDisplayHide');
  //     } else {
  //       element.classList.add ('menuDisplayHide');
  //     }
  //   }
  // };

  toggleMenu = (event) => {
    if (event.target.matches('.openmenu')) {
      var element = document.getElementById('sidebarmenu');
      setTimeout(() => {
        if (element.matches('.menuDisplayHide')) {
          element.classList.remove('menuDisplayHide');
        } else {
          element.classList.add('menuDisplayHide');
        }
      }, 200);
    }
  };

  detailPage = () => {
    // this.props.history.push (routes.SERVICEONDEMANDCREATESERVICEREQUEST_DETAIL);
  };

  render() {
    return (
      <Button
        className={
          'menuToggleButton openmenu ' +
          (this.props.bg === 'default'
            ? ' gradientButtonMenuBG '
            : ' tranpaRentMenuBG ')
        }
        onClick={this.toggleMenu}
      >
        <img
          src={logo}
          alt='Menu icon'
          className='openmenu largeLogoStyle largeRadioIcon'
        />
        <img
          src={radiologexWhiteCube}
          alt='Menu icon'
          className='openmenu smallRadioIcon smallLogoStyle'
        />
      </Button>
    );
  }
}

export default SideBarLogo;
