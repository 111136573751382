import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {SignInLink} from './SignIn';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from 'reactstrap';
import logo from './logo.svg';
import * as routes from '../constants/routes';
import {auth, db} from '../firebase';
import {
  getProfessionalDetail,
  AddProfessionalDetail,
  DeleteProfessionalDetail,
} from '../actions/Authaction';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import Ellipse from './../images/Ellipse1.png';
import Vector from './../images/Vector.png';
import DatePicker from 'react-datepicker';

// const ProfesionalDetailPage = ({ history }) => (
//       <div className="div-flex-signup">
//         <SignUpForm history={history} />
//       </div>
// );

//################### Sign Up Form ###################
const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  perfectSearch: '',
  error: null,
  showingAlert: false,
};

//A Higher order function with prop name as key and the value to be assigned to
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class ProfesionalDetailPage extends Component {
  //defining state
  state = {
    ...INITIAL_STATE,
  };
  constructor (props) {
    super (props);
    this.state = {
      professionData: [],
      isAddData: false,
      ExperienceTypeList: [
        {label: 'Employed', value: 'Employed'},
        {label: 'Business', value: 'Business'},
        {label: 'Govt Service', value: 'Govt Service'},
        {label: 'Private', value: 'Private'},
      ],
      experienceType: '',
      firmName: '',
      position: '',
      startDate: new Date (),
      endDate: new Date (),
      currentlyWorking: '',
      isSubmit: false,
    };
  }
  // onChange = (propName, value) => {
  //   this.setState({
  //     [propName]: value
  //   });
  // };
  componentWillReceiveProps (nextProps) {
   
    if (nextProps) {
      if (
        nextProps.userinfo.professionalDetails &&
        nextProps.userinfo.professionalDetails.result &&
        nextProps.userinfo.professionalDetails.result.status
      ) {
      
        this.setState ({
          professionData: nextProps.userinfo.professionalDetails.result.Data,
          isAddData: false,
          experienceType: '',
          firmName: '',
          position: '',
          startDate: new Date (),
          endDate: new Date (),
          isSubmit: false,
        });
      }
    }
  }
  componentDidMount () {
    const sendRequest = {
      data: {},
    };
    this.props.getProfessionalDetail (sendRequest);
  }
  onSubmit = event => {
    event.preventDefault (); //prevents refreshing

    this.setState (
      {
        isSubmit: true,
      },
      () => {
        const {
          experienceType,
          firmName,
          position,
          startDate,
          endDate,
        } = this.state;

        const isInvalid =
          experienceType === '' ||
          firmName === '' ||
          position === '' ||
          startDate === '' ||
          endDate === '' ||
          position.length > 100 ||
          firmName.length > 100;

        if (isInvalid) {
          return false;
        }

        let sendRequest = {
          data: {
            experienceType: this.state.experienceType,
            firmName: this.state.firmName,
            position: this.state.position,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            currentlyWorking: this.state.currentlyWorking,
          },
        };
     
        this.props.AddProfessionalDetail (sendRequest);
      }
    );
  };

  timer = () => {
    this.setState ({
      showingAlert: true,
    });

    setTimeout (() => {
      this.setState ({
        showingAlert: false,
      });
    }, 4000);
  };

  Deletedetail = item => {
    const data = {
      data: {
        docID: item.docID,
      },
    };
    this.props.DeleteProfessionalDetail (data);
  };
  render () {
    const {
      error,
      showingAlert,
      experienceType,
      firmName,
      position,
      startDate,
      endDate,
      isSubmit,
    } = this.state;

    return (
      <div className="div-flex-signup">
        <div className="div-flex-businessScroll">
          {!this.state.isAddData &&
            <Row>
              {showingAlert &&
                <Alert color="danger" onLoad={this.timer}>
                  {error.message}
                </Alert>}
              <Col sm="12" md="12" lg="12" className="text-center">
                <img alt="logo" src={logo} />
              </Col>
              <Col xs="12" sm="12" md="12" lg="12" className="pClass">
                {this.state.professionData.length === 0 &&
                  <Col>
                    <p className="headtext1">
                      Insert your Professional Details
                    </p>

                    <Col
                      className="text-center"
                      xs="8"
                      sm="8"
                      md={{offset: 2}}
                      style={{
                        backgroundColor: '#0C0F1D',
                        marginTop: '30px',
                        borderRadius: '5px',
                        height: '60px',
                        justifyContent: 'center',
                      }}
                    >
                      <p
                        style={{
                          fontSize: 16,
                          lineHeight: '55px',
                          fontStyle: 'italic',
                          fontFamily: 'Helvetica',
                        }}
                      >
                        {' '}No Experience Added
                      </p>
                    </Col>
                  </Col>}

                {this.state.professionData &&
                  this.state.professionData.length > 0 &&
                  <div>
                    <p className="headtext"> Add your Professional Details</p>
                  </div>}

                {this.state.professionData &&
                  this.state.professionData.map ((item, index) => (
                    <Col
                      key={index}
                      xs="12"
                      sm="12"
                      lg="12"
                      md="12"
                      className="eductionList"
                    >

                      <Col
                        xs="12"
                        sm="12"
                        lg="6"
                        md={{size: 3, offset: 3}}
                        style={{
                          backgroundColor: '#0C0F1D',
                          borderRadius: 5,
                          padding: 1,
                          paddingTop: 12,
                        }}
                      >
                        <div onClick={() => this.Deletedetail (item)}>
                          <img
                            alt="img"
                            src={Ellipse}
                            style={{
                              float: 'right',
                              marginRight: 20,
                              marginTop: 21,
                            }}
                          />
                          <img
                            alt="img"
                            src={Vector}
                            style={{
                              float: 'right',
                              marginTop: 30,
                              position: 'relative',
                              left: 13,
                            }}
                          />
                        </div>
                        <p
                          className="protext1"
                          style={{
                            textAlign: 'left',
                            paddingLeft: 20,
                          }}
                        >
                          {' '}{item.firmName}{' '}
                        </p>
                        <p
                          className="protext2"
                          style={{
                            textAlign: 'left',
                            paddingLeft: 20,
                          }}
                        >
                          {item.position}
                        </p>
                      </Col>
                    </Col>
                  ))}

              </Col>
              <Col
                xs="12"
                sm="12"
                md="6"
                lg="6"
                md={{size: 6, offset: 3}}
                className="text-center"
              />
              <Col
                xs="12"
                sm="12"
                lg="4"
                md={{size: 4, offset: 4}}
                className="text-center"
              >
                <FormGroup>

                  {this.state.professionData &&
                    this.state.professionData.length > 0
                    ? <Link to={routes.FINDBUSINESS}>
                        {' '}<Button
                          // onClick={() => this.setState ({isAddData: true})}
                          type="submit"
                          className="buttonClass"
                        >
                          Next
                        </Button>
                      </Link>
                    : <Button
                        onClick={() => this.setState ({isAddData: true})}
                        type="submit"
                        className="buttonClass"
                      >
                        Add Working Experience
                      </Button>}
                  {this.state.professionData &&
                    this.state.professionData.length > 0
                    ? <p
                        onClick={() => this.setState ({isAddData: true})}
                        style={{
                          fontFamily: 'Helvetica',
                          fontStyle: 'normal',
                          fontWeight: 'bold',
                          fontSize: 16,
                          textAlign: 'center',
                          color: '#4092B7',
                          marginTop: 15,
                          cursor: 'pointer',
                        }}
                      >
                        ADD NEW
                      </p>
                    : <Link to={routes.FINDBUSINESS}>
                        <p
                          // onClick={() => this.setState ({isAddData: true})}
                          style={{
                            fontFamily: 'Helvetica',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            fontSize: 16,
                            textAlign: 'center',
                            color: '#4092B7',
                            marginTop: 15,
                            cursor: 'pointer',
                          }}
                        >
                          Add Later
                        </p>
                      </Link>}

                  {/* <Link to={routes.FINDBUSINESS}>
              <Button
                 style={{marginTop:10}}
                  className="buttonClass"
                >
                  Next
                </Button> 
                </Link>*/}
                </FormGroup>
              </Col>
              {/* <Col
              xs="12"
              sm="12"
              lg="4"
              md={{size: 4, offset: 4}}
              className="text-center"
            >
              <Link to={routes.FINDBUSINESS}>

                <p
                  style={{
                    fontFamily: 'Helvetica',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: 16,
                    textAlign: 'center',
                    color: '#4092B7',
                  }}
                >
                  {' '}
                  ADD LATER{' '}
                </p>
              </Link>

            </Col> */}
            </Row>}

          {this.state.isAddData &&
            <form onSubmit={this.onSubmit}>
              <Row style={{marginTop: 10}}>
                <Col sm="12" md="12" lg="12" className="text-center">
                  <img alt="logo" src={logo} />
                </Col>
                <Col xs="12" sm="12" md="12" lg="12" className="pClass">
                  <p>
                    Add Professional Details
                  </p>
                </Col>
                <Col
                  className="alignFormField"
                  xs="6"
                  sm="6"
                  md="4"
                  lg="4"
                  md={{size: 2, offset: 4}}
                >

                  <FormGroup className="formInputField">
                    <Input
                      type="select"
                      name="experienceType"
                      id="select"
                      className="inputSelect"
                      value={this.state.experienceType}
                      onChange={e =>
                        this.setState (
                          byPropKey ('experienceType', e.target.value)
                        )}
                    >
                      <option xs="12" lg="4" value="">
                        Experience Type
                      </option>
                      {this.state.ExperienceTypeList &&
                        this.state.ExperienceTypeList.map ((item, index) => (
                          <option key={index} xs="12" lg="4" value={item.value}>
                            {item.label}
                          </option>
                        ))}

                    </Input>

                    {!experienceType &&
                      isSubmit &&
                      <p className="validationerrorMessage">
                        Experience type is  required *
                      </p>}

                  </FormGroup>
                </Col>
                <Col
                  className="alignFormField"
                  xs="6"
                  sm="6"
                  md="4"
                  lg="4"
                  md={{size: 4, offset: 4}}
                >
                  <FormGroup className="formInputField">
                    <Input
                      type="text"
                      name="firmName"
                      id="firmName"
                      maxLength="102"
                      placeholder="Name Of Firm *"
                      value={this.state.firmName}
                      onChange={e =>
                        this.setState (byPropKey ('firmName', e.target.value))}
                    />
                    {!firmName &&
                      isSubmit &&
                      <p className="validationerrorMessage">
                        Firm name is required *
                      </p>}
                    {firmName &&
                      firmName.length > 100 &&
                      <p className="validationerrorMessage">
                        {' '}Maximum limit 100 characters
                      </p>}
                  </FormGroup>

                </Col>
                <Col
                  className="alignFormField"
                  xs="6"
                  sm="6"
                  md="4"
                  lg="4"
                  md={{size: 4, offset: 4}}
                >
                  <FormGroup className="formInputField">
                    <Input
                      type="text"
                      name="position"
                      id="position"
                      maxLength="102"
                      placeholder="Position *"
                      value={this.state.position}
                      onChange={e =>
                        this.setState (byPropKey ('position', e.target.value))}
                    />
                    {!position &&
                      isSubmit &&
                      <p className="validationerrorMessage">
                        Position is required *
                      </p>}
                    {position &&
                      position.length > 100 &&
                      <p className="validationerrorMessage">
                        {' '}Maximum limit 100 characters
                      </p>}
                  </FormGroup>
                </Col>

                <Col
                  className="alignFormField"
                  xs="6"
                  sm="6"
                  md="4"
                  lg="4"
                  md={{size: 4, offset: 4}}
                >
                  <FormGroup className="formInputField">
                    <DatePicker
                      maxDate={this.state.endDate}
                      name="startdate"
                      id="startdate"
                      placeholder="Start Date *"
                      selected={this.state.startDate}
                      onChange={date =>
                        this.setState (byPropKey ('startDate', date))}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />
                    {!startDate &&
                      isSubmit &&
                      <p className="validationerrorMessage">
                        Start Date is required *
                      </p>}

                  </FormGroup>
                </Col>
                <Col
                  className="alignFormField"
                  xs="6"
                  sm="6"
                  md="4"
                  lg="4"
                  md={{size: 4, offset: 4}}
                >
                  <FormGroup className="formInputField">
                    <DatePicker
                      disabled={this.state.currentlyWorking}
                      maxDate={new Date()}
                      minDate={this.state.startDate}
                      name="enddate"
                      id="enddate"
                      placeholder="End Date *"
                      selected={this.state.endDate}
                      onChange={date =>
                        this.setState (byPropKey ('endDate', date))}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />
                    {!endDate &&
                      isSubmit &&
                      <p className="validationerrorMessage">
                        End Date is required *
                      </p>}

                  </FormGroup>
                </Col>
                <Label
                  className="text-center"
                  xs="12"
                  sm="12"
                  lg="4"
                  md={{size: 4, offset: 4}}
                >
                  <FormGroup>
                    <Input
                      className="agreeCheckbox"
                      type="checkbox"
                      name="enddate"
                      id="enddate"
                      placeholder="End Date *"
                      value={this.state.currentlyWorking}
                      onChange={e =>
                        this.setState (
                          byPropKey (
                            'currentlyWorking',
                            !this.state.currentlyWorking
                          )
                        )}
                    />
                    <span style={{color: '#FFF', fontSize: 18, marginLeft: 20}}>
                      I'm Currently Working here
                    </span>
                  </FormGroup>
                </Label>
                <Col
                  className="text-center"
                  xs="12"
                  sm="12"
                  lg="4"
                  md={{size: 4, offset: 4}}
                >
                  <Button className="buttonClass">
                    Add{' '}
                  </Button>
                  {/* <Button
                  style={{marginTop: 20}}
                  className="buttonClass"
                  onClick={() =>
                    this.setState ({
                      isAddData: false,
                    })}
                >
                  Back
                </Button> */}
                  <div className=" linkRegiterClass">
                    <Link to={routes.EDUCATIONDETAIL}>Go Back</Link>

                  </div>
                </Col>
              </Row>

            </form>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userinfo: state.Authreducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getProfessionalDetail,
      AddProfessionalDetail,
      DeleteProfessionalDetail,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  ProfesionalDetailPage
);
