import React, {Component} from 'react';
import ServiceOnDemandProfilePage
  from '../service-On-Demand-Profile/serviceOnDemandProfile';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Frame from '../../images/serviceOnDemandSearch/Frame.png';
import './Service-On-Demand-CreateServiceRequest.css';
import {
  Button,
} from 'reactstrap';
// import star from '../../images/serviceOnDemandSearch/star.png';
import StarRatingComponent from 'react-star-rating-component';
import greygear from '../../images/serviceOnDemandMyRequest/greygear.png';
import * as routes from '../../constants/routes';
import ServiceOnDemandCommonComponent
  from '../Service-On-Demand-CommanComponent/Service-On-Demand-CommanComponent';
import {GetServiceProviders} from '../../actions/serviceOnDemandAction';
import serviceAreaList from '../../constants/localJSON/serviceAreaList.json';
import serviceOfferedList from '../../constants/localJSON/serviceOffered.json';
import {MultiSelect} from 'primereact/multiselect';

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});
class serviceOnDemandCreateServiceRequestPage extends Component {
  constructor (props) {
    super (props);
    this.state = {
      isSearch: false,
      searchString: '',
      servicesOffered: '',
      serviceArea: '',
      serviceProvider: [],
    };
  }
  componentWillReceiveProps (nextProps) {
   
    if (nextProps) {
      if (
        nextProps &&
        nextProps.service &&
        nextProps.service.searchServiceProvider
      ) {
        this.setState (
          {serviceProvider: nextProps.service.searchServiceProvider},
          () => {
       
          }
        );
      
      }
    }
  }
  detailPage = () => {
    this.props.history.push (routes.SERVICEONDEMANDCREATESERVICEREQUEST_DETAIL);
  };
  serviceArea () {
    this.setState ({
      serviceAreaDropDown: !this.state.serviceAreaDropDown,
      serviceOfferedDropDown: false,
    });
  }
  serviceOffered () {
    this.setState ({
      serviceOfferedDropDown: !this.state.serviceOfferedDropDown,
      serviceAreaDropDown: false,
    });
  }
  searchServiceProvider = () => {
    this.setState (
      {
        isSearch: true,
      },
      () => {
        let sendRequest = {
          data: {
            serviceArea: this.state.serviceArea,
            servicesOffered: this.state.servicesOffered,
            searchString: this.state.searchString,
          },
        };
        this.props.GetServiceProviders (sendRequest);
      }
    );
  };
  render () {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          background: '#FFFFFF',
        }}
      >
        <div className="secmadi">
          <div style={{width: 230}}>
            <ServiceOnDemandCommonComponent />
          </div>
          <div>

            <Button
              className="gobackButtonsRequest"
              onClick={() => this.props.history.goBack ()}
            >
              <div>
                <img alt="" src={Frame} />
              </div>

              <span className="BACKjkjk">GO BACK</span>
            </Button>
            <div className="CHOOSEA">
              CHOOSE A
            </div>
          </div>
          <div className="ServiceProviderrr">
            {' '}
            Service Provider
          </div>
          <div className="Browseall">
            Browse all available service providers to begin your service request, or use the search tools to find a specific service provider in your area.
          </div>
          <div>
            <input
              className="Rectangddffddle"
              style={{height: 49, background: '#C4C4C4'}}
              placeholder="Enter search term"
              type="text"
              name="searchString"
              value={this.state.searchString}
              onChange={e =>
                this.setState (byPropKey ('searchString', e.target.value))}
            />
          </div>
          <div style={{display: 'flex', marginTop: 14}}>

            <MultiSelect
              maxSelectedLabels={1}
              placeholder="Select Service Area"
              value={this.state.serviceArea}
              options={serviceAreaList}
              onChange={e => this.setState ({serviceArea: e.value})}
            />
            <MultiSelect
              maxSelectedLabels={1}
              placeholder="Select Service Offered"
              value={this.state.servicesOffered}
              options={serviceOfferedList}
              onChange={e => this.setState ({servicesOffered: e.value})}
            />

            {/* <div>
              <input className="rreerr" placeholder="Select Service Area" />
            </div>
            <div>
              <input
                className="reccuuioo"
                placeholder="Select Service Offered"
              />
            </div> */}
            <div>
              <Button
                className="recciii"
                onClick={() => this.searchServiceProvider ()}
              >
                SEARCH
              </Button>
            </div>
          </div>
          <div style={{display: 'flex', marginBottom: 10}}>
            {/* <div className="carrrrrect" onClick={this.detailPage}>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                  <div className="MJulieMartha">Ms. Julie Martha</div>
                  <div className="aliforniaaaa">
                    San Francisco, California
                  </div>
                </div>
                <div>
                  <img alt="" src={roman} style={{width: 50}} />
                </div>
              </div>
              <div>
                <div className="verageRate">Average Rate:</div>
                <div className="s85perho">$85 per hour</div>
              </div>
              <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                  <div className="verageRate">Rating:</div>
                  <div className="s85perho">4.7</div>
                </div>
                <div>
                  <img alt="" src={star} style={{width: 100, paddingTop: 15}} />
                </div>
              </div>
              <div style={{textAlign: 'center'}}>
                <img alt="" src={settingCard1} style={{width: 20}} />
                <span className="ServicesProvid21">
                  21 Services Provided
                </span>
              </div>
              <div className="geRecngle">
                GE Servicing{' '}
              </div>
              <div className="geRecngle">
                Shimadzu Servicing
              </div>
              <div className="geRecngle">
                Siemens Servicing
              </div>
          </div>*/}

            {this.state.serviceProvider &&
              this.state.serviceProvider.length > 0 &&
              this.state.isSearch &&
              this.state.serviceProvider.map ((item, index) => (
                <div key={index}>
                  <div
                    className="Rectangle2001"
                    onClick={() =>
                      this.props.history.push (
                        routes.SERVICEONDEMANDCREATESERVICEREQUEST_DETAIL +
                          `/${item.docID}`
                      )}
                  >
                    <div
                      style={{display: 'flex', justifyContent: 'space-between'}}
                    >
                      <div>
                        <div className="MssJulieMart">
                          {item && item.providerTitle ? item.providerTitle : ''}
                          {item && item && item.providerDisplayName
                            ? item.providerDisplayName
                            : (item && item && item.providerFirstName
                                ? item.providerFirstName
                                : '') +
                                ' ' +
                                (item && item && item.providerLastName
                                  ? item.providerLastName
                                  : '')}
                        </div>
                        <div className="Cxxaliforniaaaa">
                          {item && item.serviceArea ? item.serviceArea : ''}
                        </div>
                      </div>
                      <div>
                        <img
                          alt=""
                          src={
                            item && item.providerProfilePictureURL
                              ? item.providerProfilePictureURL
                              : ''
                          }
                          className="searcprocardimg"
                        />
                      </div>
                    </div>
                    <div>
                      <div className="AssverageRate">Average Rate:</div>
                      <div className="szz85perhour">
                        $ {item && item.avgRate ? item.avgRate : ''} per hour
                      </div>
                    </div>
                    <div
                      style={{display: 'flex', justifyContent: 'space-between'}}
                    >
                      <div>
                        <div className="AssverageRate">Rating:</div>
                        <div className="szz85perhour">4.7</div>
                      </div>
                      <div style={{textAlign: 'center'}}>

                        <StarRatingComponent
                          name={'rating'}
                          starCount={5}
                          // value={
                          //   userProfile &&
                          //     userProfile.serviceProfile &&
                          //     userProfile.serviceProfile.totalServicesOffered
                          //     ? userProfile.serviceProfile.totalServicesOffered
                          //     : 0
                          // }
                          emptyStarColor={'#E4E4E4'}
                          starColor={'#F5D028'}
                          style={{fontSize: '80px'}}
                          // onStarClick={this.onStarClick}
                        />
                      </div>
                    </div>
                    <div style={{textAlign: 'center'}}>
                      <img alt="" src={greygear} style={{width: 20}} />
                      <span className="SsddervicesProvided21">
                        {item && item.totalServicesOffered
                          ? item.totalServicesOffered
                          : ''}
                        {' '}
                        Services Provided
                        {' '}
                      </span>
                    </div>
                    {item &&
                      item.servicesOffered &&
                      item.servicesOffered.length > 0 &&
                      item.servicesOffered.map ((service, index) => (
                        <div key={index}>
                          <div className="gsaeRectangle">
                            {service}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              ))}

            {/* <div className="Rectangle2000">
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div>
                    <div className="MssJulieMart">Dr. Andrey Martins</div>
                    <div className="Cxxaliforniaaaa">
                      San Francisco, California
                    </div>
                  </div>
                  <div>
                    <img alt="" src={roman} style={{width: 50}} />
                  </div>
                </div>
                <div>
                  <div className="AssverageRate">Average Rate:</div>
                  <div className="szz85perhour">$85 per hour</div>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div>
                    <div className="AssverageRate">Rating:</div>
                    <div className="szz85perhour">4.7</div>
                  </div>
                  <div>
                    <img
                      alt=""
                      src={star}
                      style={{width: 100, paddingTop: 15}}
                    />
                  </div>
                </div>
                <div style={{textAlign: 'center'}}>
                  <img alt="" src={greygear} style={{width: 20}} />
                  <span className="SsddervicesProvided21">
                    21 Services Provided
                  </span>
                </div>
                <div className="gsaeRectangle">
                  GE Servicing{' '}
                </div>
                <div className="gsaeRectangle">
                  Shimadzu Servicing
                </div>
              
          </div>*/}
          </div>

        </div>

        <div style={{width: 480}}>
          <ServiceOnDemandProfilePage
            history={this.props.history}
            isSearch={true}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    service: state.serviceOnDemandReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      GetServiceProviders,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  serviceOnDemandCreateServiceRequestPage
);
