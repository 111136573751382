import React from 'react';
import PropTypes from 'prop-types';

import Member from './Member';
import './GroupMembers.css';

export default function GroupMembers({
    header = 'Header Text',
    members = [],
    onRemoveClick = id => console.log(`${id} is clicked to be removed`),
    addMemberToGroup = id => console.log(`${id} is clicked to be removed`),
    submitButtonText = 'Submit',
    onSubmitClick = () => console.log('Submit is clicked') 
}) {
    let body = null;

    if (members.length > 0) {
        body = members.map(obj => {console.log('user contactssss', obj);
        let memberDetail = obj.contactDetail;
         return( <Member
            key={memberDetail.docID}
            name={memberDetail.displayName}
            imageSrc={memberDetail.profilePictureURL}
            position={memberDetail.position}
            firmName={memberDetail.firmName}
            onRemoveClick={() => onRemoveClick(memberDetail.docID)} 
            addMemberToGroup={() => addMemberToGroup(memberDetail)} 
        />)})
    } else {
        body = (<div className="empty-member-list">No members to display</div>)
    }
    return (<div className="group-member-container">
        <div className="group-member-header">{header}</div>
        <div className="group-member-body">{body}</div>
        <div className="group-member-footer">
            <div className="submit-button" onClick={() => onSubmitClick(members)}>
                {submitButtonText}
            </div>
        </div>
    </div>);
}

GroupMembers.propTypes = {
    header: PropTypes.string,
    members: PropTypes.array.isRequired,
    onRemoveClick: PropTypes.func.isRequired,
    submitButtonText: PropTypes.string.isRequired,
    onSubmitClick: PropTypes.func.isRequired,
}