import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import queryString from 'query-string'

import {
  deleteConversationDetails,
  getChatRecentMessagesDashboard,
} from '../../../actions/Messenger'
import { createNotification } from '../../../constants/notificationtoast'
import { GROUPDETAILS } from '../../../constants/routes'
import {
  decryptMessage,
  CalculatedSharedSecret
} from '../../../utility/encryption'

import ConversationList from '../ConversationList'
import MessageList from '../MessageList'
import MessageListGroupChat from '../MessageListGroupChat'
import DefaultScreen from '../DefaultScreen'
import { Button } from 'reactstrap'
import _ from 'lodash'
import backIcon from '../../../images/icons/messenger/backwhiteArrow.png'
import './Messenger.css'
import { checkAvailableAccess } from '../../../constants/localMessage/errorMessage'
import CommonAccessContainer from '../../../components/UI/commonAccessContainer/commonAccessContainer'

import connectComing from '../../../images/comingsoon/connectComing.svg'
import QRCode from 'react-qr-code'
import PinInput from 'react-pin-input'
import firebase from 'firebase'
import {
  userDeviceData,
  userDeviceDataUpdated,
  secretKeyForWebUpdated
} from '../../../actions/Authaction'

class Messenger extends Component {
  constructor (props) {
    super(props)
    this.state = {
      userChatInfo: {},
      messengerKey: _.get(this.props.location, 'state.messengerKey', ''),
      chatType: '',
      conversationId: null,
      currentuserid: '',
      isAccess: true,
      pincodeValue: null,
      transportKey: _.get(this.props.userinfo, 'transportKey'),
      isLoaded: false
    }
    this.triggerChange = this.triggerChange.bind(this)
  }

  static getDerivedStateFromProps (nextProps) {
    return {
      // userChatInfo: nextProps.messengerList.messenger.length > 0 ? nextProps.messengerList.messenger[0] : {}
    }
  }

  async _handleOnPressMessenger (data) {
    // console.log("_handleOnPressMessenger DATA******", data, data.key)
    this.setState({
      chatType: data.chatType,
      userChatInfo: data,
      conversationId: data.key,
      messengerKey: data.key
    })

    if (_.get(this.props.userinfo, 'user.Data.userDetails[0]', '')) {
      let myId = _.get(
        this.props.userinfo,
        'user.Data.userDetails[0].personalDetails.docID',
        ''
      )
      this.props.getChatRecentMessagesDashboard(myId)
    }
  }

  _handleMessengerValue = conversationId => {
    if (this.state.chatType === 'groupChat') {
      return conversationId
    } else if (this.state.chatType === 'singleChat') {
      let keyVal = ''
      let myId = this.props.userinfo.user.Data.userDetails[0].personalDetails
        .docID
      if (conversationId > myId) {
        keyVal = `${conversationId}${myId}`
      } else {
        keyVal = `${myId}${conversationId}`
      }
      return keyVal
    }
  }

  onDeleteSubmit = async chatID => {
    // alert("de")
    // this.setState({
    //   chatType: '',
    //   conversationId: null,
    // });
    const {
      deleteConversationDetails,
      messengerList: { messenger }
    } = this.props
    const { conversationId, chatType, messengerKey } = this.state

    let loginUser = JSON.parse(localStorage.getItem('loginuser'))
    // console.log(loginUser);
    let currentuserid = loginUser.docID
    await deleteConversationDetails({
      conversationId,
      messengerKey,
      chatType,
      conversationList: messenger,
      currentuserid,
      chatID
    })
  }

  redirectToGroupInfo = () => {
    const { history } = this.props
    const { conversationId } = this.state
    if (conversationId) {
      const redirectRoute = `${GROUPDETAILS}?conversationId=${conversationId}`
      history.push(redirectRoute)
    }
  }

  _handleOnLeaveGroup () {
    this.setState({
      userChatInfo: {},
      messengerKey: _.get(this.props.location, 'state.messengerKey', ''),
      chatType: '',
      conversationId: null
    })
  }

  _creatingNewChat (data) {
    this.setState(
      {
        chatType: 'singleChat',
        userChatInfo: data.userChatInfo
      },
      () => {
        this.setState({
          messengerKey: this._handleMessengerValue(
            this.state.userChatInfo.oppositeUserUid
          )
        })
      },
      () => {}
    )
  }

  _handleOnCompletePincode (value, index) {
    // console.log('pincode value entered is >>>>>>>>>', value, index)
    this.setState({
      pincodeValue: value
    })
  }

  _handleCancelOnPincode () {
    const database = firebase.database()
    this.setState(
      {
        pincodeValue: null
      },
      () => {
        database
          .ref(
            `Users/${_.get(
              this.props.userinfo,
              'user.Data.userDetails[0].personalDetails.docID',
              ''
            )}/onlineStatus`
          )
          .update({
            isWebConnectActive: false,
            encryptedTextForWebToDecryptToStartConnect: null
          })

        //  UPDATE THE REDUCER FROM HERE DIRECTLY
        this.props.userDeviceDataUpdated({
          ...this.props.userinfo.userDeviceData,
          isWebConnectActive: false,
          encryptedTextForWebToDecryptToStartConnect: null
        })
      }
    )
  }

  _handleContinueOnPincode () {
    const { pincodeValue } = this.state
    // console.log("pincodeValue>>>>>>>>>>> ",typeof pincodeValue, pincodeValue, pincodeValue.length)
    if (typeof pincodeValue === 'string' && pincodeValue.length == 4) {
      this._handleMatchEcryptedText()
    }
  }

  _handleMatchEcryptedText () {
    const { pincodeValue, transportKey } = this.state
    const database = firebase.database()
    // console.log('pincodeValue>>>>> final', pincodeValue, transportKey)
    let secretKeyFromMobileForWeb = CalculatedSharedSecret(
      pincodeValue,
      transportKey
    ) // secret key to transport the chat
    // let secretKeyFromMobileForWeb = 220537; // DUMMY and To be removed later
    // console.log(
    //   'secretKeyFromMobileForWeb>>>>>>>>>>',
    //   secretKeyFromMobileForWeb
    // )
    let decryptedText = decryptMessage(
      _.get(
        this.props.userinfo,
        'userDeviceData.encryptedTextForWebToDecryptToStartConnect',
        ''
      ).toString(),
      secretKeyFromMobileForWeb.toString()
    )
    // console.log("decryptedText>>>>>>>>>>>", decryptedText)
    // console.log("decryptedText>>>>>>>>>>>this.ccc", _.get(this.props.userinfo,'user.Data.userDetails[0].personalDetails.docID', ''))
    if (
      decryptedText ==
      _.get(
        this.props.userinfo,
        'user.Data.userDetails[0].personalDetails.emailAddress',
        ''
      )
    ) {
      database
        .ref(
          `Users/${_.get(
            this.props.userinfo,
            'user.Data.userDetails[0].personalDetails.docID',
            ''
          )}/onlineStatus`
        )
        .update({
          isWebConnectActive: true,
          encryptedTextForWebToDecryptToStartConnect: null
        })

      //  UPDATE THE REDUCER FROM HERE DIRECTLY
      this.props.userDeviceDataUpdated({
        ...this.props.userinfo.userDeviceData,
        isWebConnectActive: true,
        encryptedTextForWebToDecryptToStartConnect: null
      })

      // setting up secret key in the reducer
      this.props.secretKeyForWebUpdated(secretKeyFromMobileForWeb)
      // console.log(" for secretKeyForWebUpdated this.props.userinfo>>>>>>>>", this.props.userinfo)
    } else {
      createNotification(
        'error',
        'Something went wrong or Pincode is incorrect'
      )
      // show a message that Something went wrong or Pincode is incorrect

      // database.ref(`Users/${_.get(this.props.userinfo,'user.Data.userDetails[0].personalDetails.docID', '')}/onlineStatus`).update({
      //   isWebConnectActive: false,
      //   encryptedTextForWebToDecryptToStartConnect: null
      // })
    }
  }

  render () {
    // console.log("i am on messenger page userChatInfo>>>>>", this.props.userinfo.userDeviceData.FCMTokenForConnect)
    // console.log("messengerList>>>>>", this.state.transportKey)
    const {
      currentuserid,
      chatType,
      userChatInfo,
      messengerKey,
      conversationId,
      isAccess,
      pincodeValue,
      transportKey
    } = this.state
    // console.log("this.props >>> in messenegr main screen", this.props.userinfo.authToken)

    const {
      messengerList: { messenger }
    } = this.props

    let view = null
    if (chatType === 'singleChat') {
      const [selectedConversation] = messenger.filter(
        obj => obj.oppositeUserUid === conversationId
      )
      view = (
        <MessageList
          userChatInfo={userChatInfo}
          history={this.props.history}
          currentuserid={currentuserid}
          messengerKey={messengerKey}
          conversationTitle={
            selectedConversation && selectedConversation.displayName
              ? selectedConversation.displayName
              : userChatInfo.displayName
          }
          onDeleteConversation={this.onDeleteSubmit}
        />
      )
    } else if (chatType === 'groupChat') {
      const [selectedConversation] = messenger.filter(
        obj => obj.groupId === conversationId
      )
      view = (
        <MessageListGroupChat
          userChatInfo={userChatInfo}
          history={this.props.history}
          currentuserid={currentuserid}
          messengerKey={messengerKey}
          conversationTitle={
            selectedConversation && selectedConversation.displayName
              ? selectedConversation.displayName
              : ''
          }
          onDeleteConversation={this.onDeleteSubmit}
          onGroupInfoClick={this.redirectToGroupInfo}
          onLeaveGroupClick={() => this._handleOnLeaveGroup()}
        />
      )
    } else {
      view = <DefaultScreen {...this.props} />
    }
    let qrCodeData = _.get(this.state, 'transportKey', '')
      ? _.get(this.state, 'transportKey', '').toString()
      : ''
    // console.log("tqrCodeData>>>>>>", qrCodeData)
    return (
      <div>
        <div >
          {/* Show only QR code if user has not started using the connect on web */}
          {isAccess &&
          !_.get(
            this.props.userinfo,
            'userDeviceData.isWebConnectActive',
            false
          ) &&
          !_.get(
            this.props.userinfo,
            'userDeviceData.encryptedTextForWebToDecryptToStartConnect',
            ''
          ) &&
          qrCodeData && ( // if transportkey has some value then qr code to be shown
            <div className="qrcodeContainerBox">
              <div className='qrCodeScannerBox'>
                <div className='qrCodeScannerTextBox'>
                  <div className='qrCodeScannerTitle'>
                      To use R-DEE Connect on R-DEE Web Portal:
                  </div>
                  <div className='qrCodeScannerStepText'>
                    <div>1. Open R-DEE app on your phone</div>
                    <div>2. Tap Menu to go to Connect.</div>
                    <div>
                        3. Tap Options on the Connect and select <b >Connect on
                        R-DEE Web</b>
                    </div>
                    <div>
                        4. Point your phone to this screen to capture the code.
                    </div>
                  </div>
                </div>
                <div>
                  <QRCode value={qrCodeData} />
                </div>
              </div>
            </div>
          )}
          {/* QR code till here */}

          {/* Show only QR code if user has not started using the connect on web */}
          {isAccess &&
            !_.get(
              this.props.userinfo,
              'userDeviceData.isWebConnectActive',
              false
            ) &&
            _.get(
              this.props.userinfo,
              'userDeviceData.encryptedTextForWebToDecryptToStartConnect',
              ''
            ) && 
            (
            <div className="qrcodeContainerBox" >
            <div className='qrCodeScannerBox'>
              <div >
                <div className='qrCodeScannerTitle'>
                Enter the same PIN that you created on R-DEE mobile app to proceed
                </div>
                <div style={{ textAlign: 'center' }}>
                  <PinInput
                    length={4}
                    initialValue=''
                    secret
                    onChange={(value, index) => {
                      this.setState({
                        pincodeValue: null
                      })
                    }}
                    type='numeric'
                    inputMode='number'
                    style={{ padding: '10px' }}
                    onComplete={(value, index) =>
                      this._handleOnCompletePincode(value, index)
                    }
                    autoSelect
                    regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                    inputStyle={{
                      borderColor: 'transparent',
                      borderBottomColor: '#4AEA87',
                      borderBottomWidth: 2,
                      borderTopWidth: 0,
                      borderRadius: 0,
                      color: '#FFFFFF'
                    }}
                    inputFocusStyle={{
                      borderBottomColor: '#4AEA87',
                      color: '#FFFFFF'
                    }}
                  />
                  {pincodeValue &&
                      typeof pincodeValue === 'string' &&
                      pincodeValue.length === 4 && (
                      <Button
                      className='qrScannerSubmit'
                      data-rh='Continue'
                      onClick={() => this._handleContinueOnPincode()}
                    >
                          Continue
                    </Button>
                  )}

                  {
                    <Button
                      className='qrScannerSubmit'
                      data-rh='Cancel'
                      onClick={() => this._handleCancelOnPincode()}
                    >
                        Cancel
                    </Button>
                  }
                  <div>
                      Please ensure you have entered same PIN that you created
                      on your phone
                  </div>
                </div>
                </div>

              </div>
            </div>
          )}
          {/* QR code till here */}
        </div>

        {isAccess &&
          _.get(
            this.props.userinfo,
            'userDeviceData.isWebConnectActive',
            false
          ) && (
          <div className='scrollMessengerView'>
            <div className='messenger'>
              <div className='scrollable sidebar'>
                <ConversationList
                  onClickConversation={data =>
                    this._handleOnPressMessenger(data)
                  }
                  history={this.props.history}
                  creatingNewChat={data => this._creatingNewChat(data)}
                />
              </div>

              <div className='content'>{view}</div>

              <div
                style={{
                  textAlign: 'right',
                  marginRight: 20,
                  marginLeft: 10,
                  marginTop: 20
                }}
              >
                <Button
                  data-rh='Go back'
                  className='messengerBack'
                  onClick={() => this.props.history.goBack()}
                >
                  <div>
                    <img src={backIcon} alt='back icon' />
                  </div>
                    Go <span> Back </span>
                </Button>
              </div>
            </div>
          </div>
        )}
        {!isAccess && (
          <CommonAccessContainer
            bgImage={connectComing}
            message={checkAvailableAccess('messenger')}
          />
        )}
      </div>
    )
  }

  triggerChange () {
    this.setState({
      isLoaded: true
    })
    setTimeout(() => {
      if (this.state.isLoaded && this.props.userinfo) {
        // this.props.userDeviceData(this.props.userinfo)
        let userInfo = this.props.userinfo;
        let userID = _.get(
          userInfo,
            'user.Data.userDetails[0].personalDetails.docID',
            null
          );
         
          if(userID){
            const database = firebase.database();
            this.unsubscribeUserDetailMessenger = database.ref(`Users/${userID}/onlineStatus`).on("value", snapShot => {
                // console.log("snapShot>>> web authContainer user device data", snapShot.val());
                this.props.userDeviceDataUpdated({
                  ...snapShot.val()
                })
            }) 
          }

      }
    }, 2000)
  }

  componentDidMount = () => {
    clearTimeout(this.timer)
    this.timer = setTimeout(this.triggerChange, 2000)

    if (
      checkAvailableAccess('messenger') &&
      checkAvailableAccess('messenger') === 'messenger'
    ) {
      this.setState(
        {
          isAccess: true
        },
        () => {
          const {
            messengerList: { messenger },
            location: { search }
          } = this.props
          // console.log("messengerList>>>> in didmount>>>>", this.props.messengerList)

          if (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.fetchNewGroupChat
          ) {
            console.log(this.props.location.state.fetchNewGroupChat)
            this._handleOnPressMessenger(
              this.props.location.state.fetchNewGroupChat
            )
          }

          if (
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.userChatInfo
          ) {
            if (
              this.props.location.state.userChatInfo &&
              this.props.location.state.userChatInfo.chatType === 'groupChat'
            ) {
              this._handleOnPressMessenger(
                this.props.location.state.userChatInfo
              )
            } else {
              this.setState(
                {
                  chatType: 'singleChat',
                  userChatInfo: this.props.location.state.userChatInfo
                },
                () => {
                  this.setState({
                    messengerKey: this._handleMessengerValue(
                      this.state.userChatInfo.oppositeUserUid
                    )
                  })
                }
              )
            }
          }

          let loginUser = JSON.parse(localStorage.getItem('loginuser'))
          if (loginUser) {
            this.setState({
              currentuserid: loginUser.docID
            })
          }

          if (search) {
            const { conversationId } = queryString.parse(search)
            const conversation = messenger.filter(obj => {
              return obj.chatType === 'singleChat'
                ? obj.oppositeUserUid === conversationId
                : obj.groupId === conversationId
            })
            if (conversation && conversation.length > 0) {
              const selectedConversation = conversation[0]
              this.setState(
                {
                  chatType: selectedConversation.chatType,
                  conversationId
                },
                () => {
                  this.setState({
                    messengerKey: this._handleMessengerValue(conversationId)
                  })
                }
              )
            }
          }
        }
      )
    } else {
      this.setState({
        isAccess: false
      })
    }
  }

  componentWillUnmount = async () => {
    const database = firebase.database()
    let userID = _.get(
      this.props.userinfo,
      'user.Data.userDetails[0].personalDetails.docID',
      null
    )
    if(userID){
      await database.ref(`Users/${userID}/onlineStatus`).off('value',this.unsubscribeUserDetailMessenger)
    }
    if(_.get(this.props,'messengerList.messengerRawList', []).length > 0){
      _.get(this.props,'messengerList.messengerRawList', []).map(async(info)=>{
        // console.log("info>>>>>>>>>>>messengerRawList", info)
        let nodeName = _.get(info,'chatType', '') == 'singleChat' 
          ? "Messages" 
          : _.get(info,'chatType', '') == 'groupChat'
            ? 'GroupMessages'
            : '';
        await database.ref(`${nodeName}/${_.get(info,'chatThread', '')}/metaData`).off('value');
      });
    } 
  }
}

const mapStateToProps = state => {
  return {
    userinfo: state.Authreducer,
    messengerList: state.MessengerReducer
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      deleteConversationDetails,
      getChatRecentMessagesDashboard,
      userDeviceData,
      userDeviceDataUpdated,
      secretKeyForWebUpdated
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Messenger)
