import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button,Modal,ModalBody} from 'reactstrap';
import {
  getVaultFileDownload,
  vaultExternalFileSharingCheck
} from '../../../actions/vaultAction';
import queryString from 'query-string';
import * as routes from '../../../constants/routes';
import {
  fetchStatusCode
} from '../../../actions/Authaction';



class DownLoadShareFile extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      loginUserId: '',
      isLoaded:false,
      confirmCodeModal:true,
      sharedCode:'',
      sharedFileId:''
    };
  }
 
  componentDidMount(){
    const {history } = this.props;
    // console.log(history.location);
    this.props.fetchStatusCode ();

    if(history && history.location && history.location.search){
        // console.log(history.location.search);
      const {fileUUID} = queryString.parse (history.location.search);
      // console.log(fileUUID);
      if(!this.state.isLoaded){
          this.setState({
            isLoaded:true
          },()=>{
             if (fileUUID) {
               this.setState({
                sharedFileId: fileUUID
               })
                // let sendRequest = {
                //   data: {
                //     targetFile: fileUUID
                //   },
                // };
        
                // this.props.getVaultFileDownload (sendRequest);
              }

          })
      }
}

  }


  checkValidateCode(){
    const { sharedCode ,sharedFileId}= this.state;
    let sendRequest= {
        data: {
          fileUUID: sharedFileId,
          shareCode: sharedCode
        }
    }
    // console.log(sendRequest);
    this.props.vaultExternalFileSharingCheck(sendRequest);

  }
  
 

  render () {
    const { confirmCodeModal ,sharedCode}= this.state;

    return (
          <div className=" vaultContainerBG" style={{display:'flex',alignItems:'center'}}>
          <div style={{textAlign:'center',width:'100%'}}>

            {/* <Button className="backTodashboardVault" onClick={()=>this.props.history.push(routes.PROFILE_DASHBOARD)}>Go to Dashboard</Button> */}
          </div>



         <Modal
            size="sm"
            isOpen={confirmCodeModal}
            centered={true}
          //   toggle={() => {this.setState({
          //     confirmCodeModal:false
          //   })
          
          
          // }}
          >
            <ModalBody className="shareDriveConfirm" id="openShareFileConfirm">
              <div>

                <p className="confirmTitle" id="openShareFileConfirm1">Please enter shared file code</p>
                    
                <div className="confirmmsgText"   id="openShareFileConfirm2" >
                    
                  <input className="confirmCodeFileInput" id="confirmCodeFileInput" value={sharedCode} type="text" placeholder="Please enter code "
                  onChange={e => {
                    this.setState ({
                      sharedCode: e.target.value,
                    });
                  }}/>
                </div>
                 
                <div className="buttonDiv"  id="openShareFileConfirm3">
                  <Button
                    className="buttons yesButton"
                    onClick={() => this.checkValidateCode ()}
                    disabled={!sharedCode}
                    
                    id="openShareFileConfirm4"
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        


          </div>


    );
  }
}

const mapStateToProps = state => {
  return {
    userinfo: state.Authreducer,
    vaultData: state.vaultReducers,
    contacts: state.ContactsReducer.contacts,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getVaultFileDownload,
      vaultExternalFileSharingCheck,
      fetchStatusCode
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (DownLoadShareFile);
