import React, {Component} from 'react';
import {Button, Alert, Modal, ModalBody, Progress ,Tooltip} from 'reactstrap';
import logo from '../../../images/icons/radiologexIcon.png';
import eyeIcon from '../../../images/icons/eyeIcon.png';
import hideEyeIcon from '../../../images/icons/hideEyeIcon.png';




import {Link} from 'react-router-dom';
import {EncryptFunction} from '../../../../utility/encryption';
// import { SignUpLink } from "./SignUp";
import * as routes from '../../../../constants/routes';
import {
  login,
  updateFirebaseToken,
  registerDeviceToken,
  resendEmail,
  resetResetPasswordReducer,
  checkEmailExist,
  fetchStatusCode,
  signOut
} from '../../../../actions/Authaction';
import firebase from '../../../../config/firebase';
import {Loading} from '../../../../actions/LoadingAction';

// import firebase from './../config/config';
import {createNotification} from '../../../../constants/notificationtoast';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
// const axios = require('axios');
// import {messaging} from '../../../../init-fcm';
import emailIcon from './../../../../images/icons/email.png';
import password from './../../../../images/icons/password.png';
import accountSuccess from './../../../../images/icons/accountsuccess.png';
import {authErrorMessage} from '../../../../constants/localMessage/auth/authHelper';
import * as authValidation from '../../../../constants/localMessage/auth/authHelper';

import Style from './login.css';
class LoginComponent extends Component {

  constructor (props) {
    super (props);
    this.state = {
      email: '',
      password: '',
      // email:"shubhamvishwakarma19+15@gmail.com",
      // password:"Shubham@123#",
      emailnew: '',
      token: '',
      modal: false,
      isSubmit: false,
      invalidEmailFormat: false,
      isVerification: false,
      agree: false,
      existEmail: false,
      isSubmitEmail: false,
      isSubmittedEmail: false,
      serverErrorMessage: '',
      hidden: true,
      accountStatus:''
    };
    this.handleChange = this.handleChange.bind (this);
    this.onSubmit = this.onSubmit.bind (this);
    this.toggleShow = this.toggleShow.bind(this);
  }

  // I am commenting the code since it is clearing the local storage and is not persisting the data
  /*componentWillMount(){
  localStorage.clear();
}*/

  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      // console.log (nextProps.userinfo);
      if (
        nextProps &&
        nextProps.userinfo &&
        nextProps.userinfo.userExist &&
        nextProps.userinfo.userExist.Data
      ) {
        //  if(nextProps.userinfo.userExist.status){
        if (
          nextProps.userinfo.userExist.Data &&
          nextProps.userinfo.userExist.Data.emailRegistered
        ) {
          this.setState ({
            existEmail: nextProps.userinfo.userExist.Data.emailRegistered,
            isSubmittedEmail: false,
            // serverErrorMessage:nextProps.userinfo.userExist.message
          });
        }
        if (
          nextProps.userinfo.userExist.Data &&
          nextProps.userinfo.userExist.Data.emailRegistered === false
        ) {
          this.setState ({
            existEmail: false,
            isSubmittedEmail: true,
            serverErrorMessage: nextProps.userinfo.userExist.message,
          });
        }

        //  }
        //  if(nextProps.userinfo.userExist.status===false){
        //    console.log("rferfer")
        //   this.setState({
        //     existEmail:false,
        //     isSubmittedEmail: true,
        //     // serverErrorMessage:nextProps.userinfo.userExist.message
        //   })
        //  }
      }

      if (nextProps && nextProps.userinfo && nextProps.userinfo.user) {
        if(nextProps.userinfo.user.Data && nextProps.userinfo.user.Data.registrationStepsCompleted && nextProps.userinfo.user.Data.registrationStepsCompleted<3){
          this.props.history.push(routes.PROFILE_COMPLETION);
        }else{

        if (nextProps.userinfo.user.status === true) {
          // console.log (nextProps.userinfo.user);

          if (
            nextProps.userinfo.user &&
            nextProps.userinfo.user.Data &&
            nextProps.userinfo.user.Data.userDetails
          ) {
            if (nextProps.userinfo.user.Data.userDetails[0].personalDetails) {
              this.setState ({
                modal: false,
              });

              const checkVerified = nextProps.userinfo.user.Data
                .userDetails[6] &&
                nextProps.userinfo.user.Data.userDetails[6].emailVerified
                ? nextProps.userinfo.user.Data.userDetails[6].emailVerified
                : false;
              if (checkVerified) {
                localStorage.setItem (
                  'loginuser',
                  JSON.stringify (
                    nextProps.userinfo.user.Data.userDetails[0].personalDetails
                  )
                );
                if (
                  nextProps.userinfo.user.Data.userDetails[0].personalDetails
                    .walletAddress
                ) {
                  localStorage.setItem (
                    'address',
                    nextProps.userinfo.user.Data.userDetails[0].personalDetails
                      .walletAddress
                  );
                } else {
                  localStorage.setItem ('address', '');
                }

                if(this.props.location &&this.props.location.state && this.props.location.state.lastlocation){
                  this.props.history.push (this.props.location.state.lastlocation);
                }else{
                  this.props.history.push (routes.PROFILE_DASHBOARD);
                }
   
              } else {
                this.setState ({
                  isVerification: true,
                });
              }
            }
          }
        } else {
          if (nextProps.userinfo.user && nextProps.userinfo.user.Data) {
            if (nextProps.userinfo.user.Data.emailVerified === false) {
              this.setState ({
                isVerification: true,
              });
            }else{

              if(nextProps.userinfo.user.Data.accountStatus){
                this.setState({
                  accountStatus:nextProps.userinfo.user.Data.accountStatus
                })
              }
            }
          }
        }
      }

      }
    }
  }

  async componentDidMount () {
   
    localStorage.clear ();
    this.props.updateFirebaseToken ('');
    this.props.resetResetPasswordReducer ();
    this.props.fetchStatusCode ();
    this.props.Loading (false);
    // messaging
    //   .requestPermission ()
    //   .then (async () => {
    //     const token = await messaging.getToken ();
    //     // console.log (token);
    //     // const sendrequest={
    //     //   data: {
    //     //     deviceRegToken: token,
    //     //     devicePlatform: "web"
    //     //   }
    //     // }
    //     // this.props.registerDeviceToken(sendrequest)
    //   })
    //   .catch (function (err) {
    //     console.log ('Unable to get permission to notify.', err);
    //   });
    navigator.serviceWorker.addEventListener ('message', message => {
      // this.props.history.push(routes.NOTIFICATION);
      console.log (message);
    });
    navigator.serviceWorker.addEventListener ('notificationclick', event => {
      // alert(JSON.stringify(event));

      // this.props.history.push (routes.NOTIFICATION);
      // console.log (event);
    });
    this.props.fetchStatusCode ();

  }

  openModal () {
    this.setState ({modal: true});
  }
  closeModal () {
    this.setState ({
      modal: false,
      invalidEmailFormat: false,
      emailnew: '',
      isSubmit: false,
    });
  }
  handleChange (event) {
    this.setState (
      {
        [event.target.name]: event.target.value,
      },
      () => {}
    );
  }

  doCheckEmail () {
    this.setState (
      {
        isSubmitEmail: true,
        // password: hashKey
      },
      () => {
        const {email} = this.state;

        if (!email) {
          return false;
        }
        let sendRequest = {
          data: {
            emailAddress: email,
          },
        };
        this.props.checkEmailExist (sendRequest);
      }
    );
  }

  onSubmit = e => {
    this.setState (
      {
        isSubmit: true,
        // password: hashKey
      },
      () => {
        const {email, password} = this.state;

        if (!email) {
          return false;
        }

        let checkvalidation = email !== '' && password !== '';
        if (!checkvalidation) {
          return false;
        }
        this.props.Loading(true);
        let hashKey = EncryptFunction (this.state.email, this.state.password);

        firebase
          .auth ()
          .signInWithEmailAndPassword (this.state.email, hashKey)
          .then (res => {
            if (res) {
              if (res.user && res.user.ya) {
                this.props.updateFirebaseToken (res.user.ya);
                const sendrequest = {
                  data: {
                    emailAddress: this.state.email,
                    password: hashKey,
                    useTrusona: false,
                  },
                };
                this.props.login (sendrequest);
              }

              // console.log("user at firebase",res)
              // return <Redirect to={routes.HOME} />;
            } else {
              console.log ('user signed out or still need to sign in', res);
             this.props.Loading(false);

              // return <Redirect to={routes.SIGN_IN} />;
            }
          })
          .catch (e => {
            console.log ('error', e);
            createNotification ('error', e.message);
             this.props.Loading(false);

          });
      }
    );
  };

  truSonaLogin () {
    const {email} = this.state;

    const sendrequest = {
      data: {
        // emailAddress: 'hitesh@gmail.com',
        // emailAddress:'forturate@gmail.com',
        // emailAddress: emailnew,
        emailAddress: email,
        password: '',
        useTrusona: true,
        loginSource: 'web',
      },
    };
    this.props.login (sendrequest);
  }

  emailResend = () => {
    let sendRequest = {
      data: {
        emailAddress: this.state.email,
      },
    };
    this.props.resendEmail (sendRequest);
  };
  toggleShow() {
    this.setState({ hidden: !this.state.hidden });
  }


  render () {
    const {
      emailnew,
      error,
      isSubmit,
      invalidEmailFormat,
      isVerification,
      existEmail,
      isSubmitEmail,
      isSubmittedEmail,
      hidden,
      accountStatus
    } = this.state;

    return (
      <div className="div-flex gradientColorPurpleBlue responsiveScroll">
        <div>

            <Modal size="sm" isOpen={this.state.modal} centered={true}>
              <ModalBody className="passlessmod">
                <div>
                  <p className="passworfless">
                    Passwordless Authentication
                  </p>
                  <label className="modelLableText">
                    Please enter your email ID:
                  </label>
                  <input
                    type="text"
                    name="emailnew"
                    // id="exampleEmail"
                    placeholder="E-mail ID"
                    value={emailnew}
                    onChange={this.handleChange}
                    className="passwordlesinpu"
                  />
                  {!emailnew &&
                    isSubmit &&
                    <div className="redBoxErrorMsg center">

                      {authErrorMessage (authValidation.EMAIL_BLANK)}
                    </div>}
                  {invalidEmailFormat &&
                    isSubmit &&
                    <p className="redBoxErrorMsg center">
                      {authErrorMessage (authValidation.EMAIL_FORMAT)}
                    </p>}

                  <div className="buttonDiv">
                    <Button
                      className="passlessbacbu"
                      onClick={() => this.closeModal ()}
                    >
                      Back
                    </Button>
                    <Button
                      type="submit"
                      onClick={() => this.truSonaLogin ()}
                      value="submit"
                      className="passlesslogbu"
                    >
                      Login
                    </Button>
                   
                  </div>
                </div>
              </ModalBody>
            </Modal>

            <Modal size="lg" isOpen={isVerification} centered={true}>
              <ModalBody>

                <div>
                  <div className="notverificationModel">
                    <div className="setupacc2">
                      Setup Account
                    </div>
                    <div>
                      <Progress value={100} className="profgress2" />
                    </div>
                    <div className="successAccount">
                      <img src={accountSuccess} alt="success" />

                      <div className="AccountCr">
                        Account Not Verified!
                      </div>

                      <div className="Thankyoumsg">
                        <p> To log into your account, </p>
                        <p>Lets verify your email.</p>

                        <p
                          style={{
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingTop: 20,
                          }}
                        >
                          We’ve sent an email with instructions to verify
                          your email address to
                          {' '}
                          {' '}
                          {' '}
                          <span>{this.state.email}  </span>
                          {' '}
                          Click the URL link in the email message within 24 hours.
                        </p>

                      </div>

                    </div>
                    <div
                      style={{textAlign: 'center'}}
                      className="successAccountFooter"
                    >
                      <Button
                        className="step3buttonl"
                        onClick={() =>
                          this.setState (
                            {
                              isVerification: false,
                            },
                            () => {
                              this.props.resetResetPasswordReducer ();
                            }
                          )}
                      >
                        Proceed to Login
                      </Button>
                      <div className="Thankyoumsg">

                        <Button
                          className="resendConfirmation"
                          onClick={() => this.emailResend ()}
                        >

                          <p>Resend confirmation email</p>
                        </Button>
                      </div>

                    </div>
                  </div>
                </div>
              </ModalBody>
            </Modal>



            <Modal size="lg" isOpen={accountStatus} centered={true}>
            <ModalBody>

              <div>
                <div className="notverificationModel">
                  <div className="accountDisableTitle">
                   {accountStatus}
                  </div>
                 
                  <div className="successAccount">
                    <img src={accountSuccess} alt="success" />

                  

                    <div className="Thankyoumsg" style={{marginTop:15,padding:10}}>
                      <p> We are verifying your details and your account will be activated automatically upon successful verification. </p>
                      <p
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 20,
                        }}
                      >
                      Please write to us at 
                        {' '}
                        {' '}
                        {' '}
                        <span> support@radiologex.com  </span>
                        {' '}
                        in case this has taken more than 3 working days.
                      </p>

                    </div>

                  </div>
                  <div
                    style={{textAlign: 'center'}}
                    className="successAccountFooter"
                  >
                    <Button
                      className="step3buttonl"
                      onClick={() =>
                        this.setState (
                          {
                            accountStatus: '',
                          },
                          () => {
                            this.props.signOut ();
                          }
                        )}
                    >
                      Logout
                    </Button>

                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
         
            

         

          <div>
            <div style={{textAlign:'center'}}>
                <img style={{width:201}} alt="logo" src={logo}  />
            </div>
            <div className="mobileLoginmaindiv">
              <div className="WELCOMEloginTitle">
               
                <div> Welcome!</div>
                Let’s set up your account
              </div>

            <div style={{marginTop:15}}/>
              <div className="loginInputFields">
                <div className="inputLabel">Email</div>
                <input type="text" placeholder="Enter your email here" className="loginInput" value={this.state.email}
                onChange={e => {
                  this.setState ({
                    isSubmittedEmail: false,
                    email: e.target.value,
                  });
                }}
                readOnly={existEmail}/>
                {isSubmittedEmail &&
                    <p className="redBoxErrorMsg center">
                      {this.state.serverErrorMessage}
                    </p>}
                    {!this.state.email &&
                        isSubmitEmail &&
                        <p className="redBoxErrorMsg center">
                          {authErrorMessage (authValidation.EMAIL_BLANK)}
                        </p>}

    
              </div>
               {existEmail &&<div className="loginInputFields">
                <div className="inputLabel">Password</div>
                
                <input type={hidden ? "password" : "text"} placeholder="Enter your password here" className="loginInput" value={this.state.password}
                onChange={e => {
                  this.setState ({
                    password: e.target.value,
                  });
                }}/>
                <div style={{textAlign:'right'}}>
                <Button className="eyeIconButton"onClick={this.toggleShow}>
                    <img src={hidden ? eyeIcon : hideEyeIcon} alt="icons" />
                </Button>
                </div>
                

              </div>}

              {!this.state.password &&
                isSubmit &&
                <p className="redBoxErrorMsg center">
                  {authErrorMessage (authValidation.PASSWORD_BLANK)}
                </p>}
        
              {existEmail &&
                  <div className="forgotPasswodButton">
                    <Link to={routes.PASSWORD_FORGET}  data-rh="Forgot Password">
                      Forgot Password
                    </Link>
                  </div>}

              <div style={{textAlign: 'center'}}>
                {existEmail &&
                  <Button
                    className="mobileLoginNext"
                    type="submit"
                    onClick={this.onSubmit}
                    value="submit"
                   
                    data-rh="Login"
                    style={{marginTop:75}}
                  >
                    Login
                  </Button>
                }
                {!existEmail &&
                  <Button
                    className="mobileLoginNext"
                    onClick={() => this.doCheckEmail ()}
                    value="submit"
                    data-rh="Next"
                    style={{marginTop:75}}

                  >
                    Next
                  </Button>}
                 
     

                  
              </div>
              {existEmail &&
                (this.props.userinfo &&
                  this.props.userinfo.userExist &&
                  this.props.userinfo.userExist.status) &&
                <div style={{textAlign: 'center'}}>
                  <Button
                    className="mobilePasswordless"
                    // onClick={() => this.openModal ()}
                    onClick={() => this.truSonaLogin ()}
                    data-rh="Passwordless Authentication"
                  >
                    <span style={{opacity: 0.7}}>
                      Passwordless Authentication
                    </span>
                    </Button>
                </div>}
              <div className="Don’thave">
                Don’t have an account yet?
                {' '}
                <Link to={routes.SIGN_UP}  data-rh="Register"> Register here </Link>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userinfo: state.Authreducer,
    // dialogData: state.ShowDialog.data
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      login,
      updateFirebaseToken,
      registerDeviceToken,
      Loading,
      resendEmail,
      resetResetPasswordReducer,
      checkEmailExist,
      fetchStatusCode,
      signOut
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (LoginComponent);
