import _ from 'lodash';
import firebase from 'firebase';
import store from '../store';

import {
  UPDATE_MESSENGER,
  UPDATE_MESSENGER_RAW_LIST
} from './../reducers/MessengerReducer';
import {RECENT_MESSAGES} from './../reducers/Authreducer';
import {Loading} from './LoadingAction';

import {createNotification} from '../constants/notificationtoast';
import {
  sendUserNotification,
  addUserNotificationHistory,
} from '../services/Authservice';
import {
  encryptMessage,
} from '../utility/encryption';
import {ShowNameAccordingToLength} from '../utility/stringFunctions';
import * as routes from '../constants/routes';

import {
  messengerGetMutedNotificationsListFunc,
} from './../actions/notifications';
import axios from 'axios';
import {environmentUsed} from '../config/firebase'

const database = firebase.database ();

export const fetchMessengerList = key => {
  database.ref(`Users/${key}/chatList`).off("value");
  _.get(store.getState(),'messengerList.messengerRawList', []).map(async(info)=>{
    // console.log("info>>>>>>>>>>>messengerRawList", info)
    let nodeName = _.get(info,'chatType', '') == 'singleChat' 
      ? "Messages" 
      : _.get(info,'chatType', '') == 'groupChat'
        ? 'GroupMessages'
        : '';
    await database.ref(`${nodeName}/${_.get(info,'chatThread', '')}/metaData`).off('value');
  });

  return dispatch => {
    var returnArray = [];
    database.ref(`Users/${key}/chatList`)
    .orderByChild('timeStamp')
    .on('value', snapshot => {
      // console.log("snapshot>>>>each Messenger on hit >>>>>111111", snapshot.val())
      returnArray = [];
      let initialRender = true;
      snapshot.forEach(function (snap) {
        // console.log("snapshot>>>>each Messenger on hit >>>>>22222", snap.val())
        var item = snap.val();
        item.key = snap.key;
        returnArray.push(item);
      });    

      let finalArr = _.uniqBy([...returnArray], e => e.key)
            .filter(val => ((_.get(val, 'chatType') == 'singleChat') || _.get(val, 'chatType') == 'groupChat')).reverse();
            dispatch ({type: UPDATE_MESSENGER_RAW_LIST, payload: finalArr});
            finalArr.map(async(info)=>{
              let nodeName = _.get(info,'chatType', '') == 'singleChat' 
                ? "Messages" 
                : _.get(info,'chatType', '') == 'groupChat'
                  ? 'GroupMessages'
                  : '';
              await database.ref(`${nodeName}/${_.get(info,'chatThread', '')}/metaData`).on('value', (metadata)=>{
                console.log("metadata.val();>>>>>>>", metadata.val());
                let combinedObject = [{...info, ...metadata.val()}];
                let storeMessengerList = initialRender ? [] : store.getState().MessengerReducer.messenger;
                initialRender = false;
                let finalArray = _.uniqBy([...combinedObject, ...storeMessengerList], (val) => _.get(val,'chatThread'));
                let sortedArray = finalArray.sort(function(x, y){
                  return _.get(y,'timeStamp') - _.get(x,'timeStamp');
              })
              
              dispatch ({type: UPDATE_MESSENGER, payload: sortedArray});
              })
            });

      // let finalArr = _.uniqBy([...returnArray], e => e.chatThread)
      // .filter(val=> _.get(val,'chatType') === 'singleChat' || _.get(val,'chatType') === 'groupChat').reverse();
      
      // finalArr.map((val) => {
      //   val.isMute=false;
      //   let params = {
      //     "data": {
      //       "muteID":(_.get(val,'chatType') === 'singleChat' ? val.oppositeUserUid :val.chatThread)
      //     }
      //   }
      //  dispatch (messengerGetMutedNotificationsListFunc(params, (res) => {
      //     // console.log("messengerGetMutedNotificationsList>>>>> P2P result>>>>", res)
      //     if(res && res.muteID){
      //       if (_.get(res, 'muteID')) {
      //         if((_.get(val,'chatType') === 'singleChat' ? val.oppositeUserUid :val.chatThread)===_.get(res, 'muteID')){

      //           val.isMute=true;
      //           dispatch ({type: UPDATE_MESSENGER, payload: finalArr});
      //         }
      //       }
      //     }
          
      //   }));
      // })

      // console.log("finalArr>>>>>>> checking ", finalArr);
      dispatch ({type: UPDATE_MESSENGER, payload: finalArr});
    });
  };
};

export const updateNewMessengerList = list => {
  return dispatch => {
    dispatch ({type: UPDATE_MESSENGER, payload: list});
  };
};

export const createGroupDetails = ({key, name, image, members = []}) => {
  return async dispatch => {
    dispatch (Loading (true));
    // upload image to database

    const storage = firebase.storage ();
    // const imageStorageUrl = (await storage
    //   .ref (`/attachPics/${image.name}`)
    //   .put (image)).downloadURL;

      var imageStorageUrl='';
        try{
          await storage.ref(`/attachPics/${image.name}`).put(image).then(async (snapshot)=> {
            // console.log('Uploaded a blob or file!');
            this.props.Loading(false);
            await  snapshot.ref.getDownloadURL ().then (downloadURL => {
              imageStorageUrl=downloadURL;

            });
          })
        }catch (e) {
            this.props.Loading(false);
            createNotification("error", e.message);
            return;
        }
        if(!imageStorageUrl){
          this.props.Loading(false);

          createNotification("error", "Please try again");
          return;
        }



    let ref;
    try {
      const createGroupInfo = {
        groupIcon: imageStorageUrl,
        groupName: name,
        groupDescription: name,
        noOfUsers: members.length,
        groupCreatedAt: Math.floor (Date.now ()),
        groupAdminUid: key,
      };

      ref = await database.ref ('GroupChat').push ().key;
      await database.ref (`GroupChat/${ref}`).update (createGroupInfo);
      members.forEach (val =>
        database
          .ref (`GroupChat/${ref}/users`)
          .push ()
          .update (JSON.parse (JSON.stringify (val)))
      );
      // console.log(members);
      const adminConversationObj = {
        lastMsg: 'you created this group',
        groupIcon: imageStorageUrl,
        groupName: name,
        groupId: ref,
        groupCreatedAt: Math.floor (Date.now ()),
        groupAdmin: key,
        noOfUsers: members.length,
        groupDescription: name,
        lastTime: Math.floor (Date.now ()),
        read: true,
        chatType: 'groupChat',
      };
      members.forEach (async val => {
        let obj = {
          ...adminConversationObj,
          lastMsg: val.docID === key
            ? 'you created this group'
            : 'You have been added to this group',
        };

        await database.ref (`Users/${val.docID}/chatList/${ref}`).update (obj);
        members.map (user =>
          database
            .ref (`Users/${val.docID}/chatList/${ref}/users`)
            .push ()
            .update (JSON.parse (JSON.stringify (user)))
        );
      });
      // dispatch({
      //   type: ADD_CONVERSATION,
      //   payload: adminConversationObj,
      // });
    } catch (err) {
      console.log (err);
    } finally {
      dispatch (Loading (false));
    }
    return ref;
  };
};

export const deleteConversationDetails = ({
  conversationId,
  messengerKey,
  chatType,
  conversationList,
  currentuserid,
  chatID,
}) => {
  return async dispatch => {
    dispatch (Loading (true));

    let currentTimeStamp = Date.now (Math.floor (Date.now ()));
    const database = firebase.database ();

    // database
    // .ref (
    //   `Messages/${chatID}`
    // )
    // .on ('value', snapshot => {
    //   // console.log ('FireB ', snapshot);

    // // database.ref (`Messages/${chatID}`).on ('value', snapshot => {

    //   let getData = snapshot.val ();
    //   let keys = Object.keys (getData);
    //    keys.forEach(val => {
    //      if( new Date(getData[val].timeStamp)<currentTimeStamp){

    //      database.ref (`Messages/${chatID}/${val}`).on ('value', snapshot => {
    //       // console.log(snapshot.val().isDeletedBy);
    //       // let isDeleted={
    //       //   currentuserid:[{currentuserid:true,chatID: true}]
    //       // }
    //       // console.log(isDeleted);
    //       // return false;

    //       let updateChat= database.ref(`Messages/${chatID}/${val}/isDeletedBy/${currentuserid}`).update ({
    //         isDeleted:true
    //       })
    //       return false;
    //     })
    // }

    //    })

    // });

    // database
    //   .ref('Messages')
    //   .child(messengerKey)
    //   .remove()

    // database
    //   .ref(`Users/${conversationId}/chatList/${messengerKey}`)
    //   .remove()

    // database
    //   .ref(`Users/${currentuserid}/chatList/${messengerKey}`)
    //   .remove()
    //   dispatch({
    //     type: DELETE_CONVERSATION,
    //     payload: true
    //   });

    if (chatType !== 'singleChat') {
      var conversationRef = await database.ref (`GroupChat/${messengerKey}`);
      if (conversationRef) {
        await conversationRef.remove ();
        // remove conversation from list

        const filteredConversation = conversationList.filter (obj => {
          return !(obj.chatType === chatType &&
            (obj.groupId === conversationId ||
              obj.oppositeUserUid === conversationId));
        });
        dispatch ({
          type: UPDATE_MESSENGER,
          payload: filteredConversation,
        });
      } else {
        console.error (
          'No conversation found for conversation *** ',
          conversationRef
        );
      }
    }

    // if(Date.now(Math.floor(Date.now())) > currentTimeStamp){
    // alert("del")

    let deleteData = database
      .ref (`Users/${currentuserid}/chatList/${chatID}`)
      .update ({
        lastTime: currentTimeStamp,
        lastMsg: '',
        type: 'text',
        isDeletedTime: currentTimeStamp,
      });
    // return false;
    // }
    createNotification ('success', 'Successfully Deleted !');

    dispatch (Loading (false));
    return conversationId;
  };
};

export const sendnotification = (registerData,redirectURL) => {
  return dispatch => {
    sendUserNotification (registerData)
      .then (userData => {
        dispatch (Loading (false));
        if(redirectURL && redirectURL==='sellerlanding'){
          window.location.href= routes.SELLER_CONSOLE;
        }
        if(redirectURL && redirectURL==='buyerlanding'){
          window.location.href= routes.PLACED_ORDERS;
        }
      })
      .catch (err => {
        dispatch (Loading (false));
        throw err;
      });
  };
};

// export const addNotificationHistory = registerData => {
//   return dispatch => {
//     addUserNotificationHistory (registerData)
//       .then (userData => {
//         dispatch (Loading (false));

//       })
//       .catch (err => {
//         dispatch (Loading (false));
//         throw err;
//       });
//     console.log ('register is running in messenger');
//   };
// };
export const addNotificationHistory = registerData => {
  return dispatch => {
    addUserNotificationHistory (registerData)
      .then (userData => {
        // dispatch (Loading (false));

        let notiData = registerData.data;

        if (notiData.source === 'rdeemarketplace') {
          let loginUser = JSON.parse (localStorage.getItem ('loginuser'));

          let sendNotiRequest = {
            data: {
              sourceModule: notiData.source,
              sourceUID: loginUser.docID,
              title: notiData.titleName,
              body: notiData.body,
              targetUID: notiData.targetUID,
              disableSound: '',
              screen: 'marketplace',
              thread_id: '',
              messengerKey: '',
              profilePictureURL: notiData.imageURL,
              titleChat: '',
              oppositeUserUid: '',
              messengerGroupID: '',
              navigateToID: notiData.navigateToID,
              orderScreenType: notiData.orderScreenType,
            },
          };

          dispatch (sendnotification (sendNotiRequest));
        }
      })
      .catch (err => {
        dispatch (Loading (false));
        throw err;
      });
  };
};

export const sendNotificationForMarketplace = (registerData,redirectURL) => {
  return dispatch => {
        let notiData = registerData.data;
        if (notiData.source === 'rdeemarketplace') {
          let loginUser = JSON.parse (localStorage.getItem ('loginuser'));

          let sendNotiRequest = {
            data: {
              sourceModule: notiData.source,
              sourceUID: loginUser.docID,
              title: notiData.titleName,
              body: notiData.body,
              targetUID: notiData.targetUID,
              disableSound: '',
              screen: 'marketplace',
              thread_id: '',
              messengerKey: '',
              profilePictureURL: notiData.imageURL,
              titleChat: '',
              oppositeUserUid: '',
              messengerGroupID: '',
              navigateToID: notiData.navigateToID,
              orderScreenType: notiData.orderScreenType,
            },
          };
          dispatch (sendnotification (sendNotiRequest,redirectURL));
        }
   
  };
};

// delete single user conversation from messenger (remove user from messenger list )//

function removeMessenger (messengerKey, messengers, myUid, oppositeUserUid) {
  // console.log("messengerKey on delete", messengerKey, "messengers**", messengers, "myUid", myUid,"oppositeUid>>>",oppositeUserUid)
  return dispatch => {
    const database = firebase.database ();

    database.ref (`Users/${myUid}/chatList/${messengerKey}`).remove ();

    database
      .ref (`Messages/${messengerKey}/usersDetail`)
      .once ('value', val => {
        // console.log("val Messages/${messengerKey}/usersDetail>>>", val)
        let newArrayOfUsersDeletedChat = [];
        let usersWhoDeletedChatArray = _.get (val.val (), 'deletedBy', []);
        let objectTobeFound = usersWhoDeletedChatArray.find (
          o => o.uid === myUid
        );
        if (objectTobeFound == undefined) {
          // console.log("objectTobeFound>>>>>>>>>>", objectTobeFound)

          usersWhoDeletedChatArray.push ({
            uid: myUid,
            deletionOn: Math.floor (Date.now ()),
          });
        } else {
          newArrayOfUsersDeletedChat = usersWhoDeletedChatArray.map (val => {
            if (val.uid == myUid) {
              return {...val, deletionOn: Math.floor (Date.now ())};
            } else {
              return val;
            }
          });
        }

        // console.log("newArrayOfUsersDeletedChat>>>>>", newArrayOfUsersDeletedChat, "usersWhoDeletedChatArray>>>>>>>", usersWhoDeletedChatArray)

        let userArray = [];
        let allUsers = [...userArray, oppositeUserUid, myUid];
        let ObjTobeUpdated = {
          usersDetail: {
            users: allUsers,
            deletedBy: objectTobeFound !== undefined
              ? newArrayOfUsersDeletedChat
              : usersWhoDeletedChatArray,
          },
        };
        console.log (
          'ObjTobeUpdated>>>>>',
          ObjTobeUpdated,
          'allUsers>>>>',
          allUsers
        );
        firebase
          .database ()
          .ref (`Messages/${messengerKey}`)
          .update (ObjTobeUpdated);
      });
  };
  function update (messengers) {
    return {type: 'UPDATE_MESSENGERS', messengers: messengers};
  }
}

// delete conversation by group (remove group from messenger list )//
function removeMessengerGroupChat (
  messengerKey,
  messengers,
  myUid,
  oppositeUserUid,
  totalUsersOnGroupChat
) {
  // console.log("messengerKey on delete", messengerKey, "messengers**", messengers, "myUid", myUid,"oppositeUid>>>",oppositeUserUid, "totalUsersOnGroupChat>>>>>>",totalUsersOnGroupChat)
  return dispatch => {
    const database = firebase.database ();

    database.ref (`Users/${myUid}/chatList/${messengerKey}`).remove ();

    database
      .ref (`GroupMessages/${messengerKey}/usersDetail`)
      .once ('value', val => {
        let newArrayOfUsersDeletedChat = [];
        let usersWhoDeletedChatArray = _.get (val.val (), 'deletedBy', []);
        let objectTobeFound = usersWhoDeletedChatArray.find (
          o => o.uid === myUid
        );
        if (objectTobeFound == undefined) {
          usersWhoDeletedChatArray.push ({
            uid: myUid,
            deletionOn: Math.floor (Date.now ()),
          });
        } else {
          newArrayOfUsersDeletedChat = usersWhoDeletedChatArray.map (val => {
            if (val.uid == myUid) {
              return {...val, deletionOn: Math.floor (Date.now ())};
            } else {
              return val;
            }
          });
        }
        let allUsers = [...totalUsersOnGroupChat];
        let ObjTobeUpdated = {
          usersDetail: {
            users: allUsers,
            deletedBy: objectTobeFound !== undefined
              ? newArrayOfUsersDeletedChat
              : usersWhoDeletedChatArray,
          },
        };
        firebase
          .database ()
          .ref (`GroupMessages/${messengerKey}`)
          .update (ObjTobeUpdated);
      });
  };
  function update (messengers) {
    return {type: 'UPDATE_MESSENGERS', messengers: messengers};
  }
}

export const getChatRecentMessagesDashboard = key => {
  return dispatch => {
    var returnArray = [];
    const database = firebase.database ();
    try {
      database.ref (`Users/${key}/chatList`).on ('value', snapshot => {
        // console.log("snapshot>>>> MESSENGER ON ", snapshot)
        snapshot.forEach (function (snap) {
          // console.log("snapshot>>>>each Messenger on hit >>>>>", snap)
          var item = snap.val ();
          item.key = snap.key;
          if (snap.val ().users) {
            item.users = _.toArray (snap.val ().users);
          }

          returnArray.push (item);
        });

        returnArray.sort ((a, b) => {
          return b.timeStamp - a.timeStamp;
        });

        let finalArr = _.uniqBy (returnArray, function (e) {
          if (e && e.chatType && e.chatType == 'singleChat') {
            return e.oppositeUserUid;
          } else if (e && e.chatType && e.chatType == 'groupChat') {
            return e.groupId;
          }
        });
        // console.log(finalArr);

        // let filterUndefined = finalArr.filter((thread)=>{
        // if(thread && thread.chatType && (thread.chatType == 'singleChat' || thread.chatType == 'groupChat')){
        // return thread;
        // }
        // })

        // console.log("finalArr>>>>>>> messenger", finalArr)
        // dispatch({
        //   type: "UPDATE_MESSENGERS",
        //   messengers: finalArr
        // });
        let dashboardMessengerList = finalArr.filter (message => {
          if (!message.read) {
            return message;
          }
        });
        dispatch ({type: RECENT_MESSAGES, payload: dashboardMessengerList});
      });
    } catch (err) {
      console.log (err, ' error in firebase>>>>>');
    }
  };
};

// used to share the product from the market place
export const shareButtonClickP2P = (
  key,
  userOwnDetail,
  oppositeUserUid,
  profilePictureURL,
  oppositeUserDisplayName,
  secretKey,
  productDetail,
  userProductDes,
  props
) => {
  return async dispatch => {
    // console.log("chat link click>>>>>", key, userOwnDetail, oppositeUserUid, profilePictureURL, oppositeUserDisplayName, secretKey, text, productDetail)
    const database = firebase.database ();

    let oppositeUserPublicKey = await getOppositeUserPublicKey(oppositeUserUid) 
    let sendText =userProductDes?userProductDes: (_.get (productDetail, 'productCategoryType', '') === 'Radiology'
      ? _.get (productDetail, 'deviceSaleName', '').toString (): _.get (productDetail, 'productName', '').toString ()
      );

    const message = {
      sender: userOwnDetail.docID,
      senderName: _.get(userOwnDetail,'firstName',''),
      senderProfilePic: _.get(userOwnDetail,'profilePictureURL',null),
      text: encryptMessage (sendText.toString (), secretKey.toString ()),
      productDetail: productDetail,
      // dynamicLink: productDetail && productDetail.dynamicLink,
      type: 'productLink',
      timeStamp: Math.floor (Date.now ()),
      read: false,
      delivered: true,
      messageAddedInMainNode: false
    };

    let ref =  await database
        .ref('MessagesWeb')
        .child(userOwnDetail.docID)
        .child(key)
        .child("messages")
        .push().key;

    await database.ref(`MessagesWeb/${userOwnDetail.docID}/${key}/messages/${ref}`).update({...message, messageKey: ref})
    // sending the silent push notification to the connected mobile to update the original message node
    let reqObj = {
      mobilefirebaseToken: _.get(props.userInfo,'userDeviceData.FCMTokenForConnect', null),
      props: props,
      authToken: props.userInfo.authToken,
      messageData: {
        ...message,
        messageKey: ref,
        chatThread: key,

        // other keys than original message
        oppositeUserUid: oppositeUserUid,
        oppositeProfilePictureURL: profilePictureURL !== null ? profilePictureURL : '',
        oppositeUserDisplayName: oppositeUserDisplayName,
      },// this is message data
      oppositeUserPublicKey: oppositeUserPublicKey,
      
    }
    // console.log("44444", reqObj)
    sendDataToMobileWhenUsingConnect (reqObj)

  };
};

export const getOppositeUserPublicKey = (oppositeUserUid) => {
  const database = firebase.database ();
  return new Promise((resolve, reject) => {
     database.ref(`Users/${oppositeUserUid}/onlineStatus`).on("value", snapShot => {
      resolve (snapShot.val().publicKey)
    })
    
  })
}

export const shareButtonClickGroupChat = (
  addedContactsArray,
  messengerKey,
  userOwnDetail,
  groupName,
  groupIcon,
  secretKey,
  productDetail,
  groupId,
  userProductDes
) => {
  
  return async dispatch => {

  //   console.log(  "addedContactsArray"+ addedContactsArray)
  // console.log("messengerKey"+ messengerKey);
  // console.log("userOwnDetail"+ userOwnDetail);
  // console.log("groupName"+ groupName);
  // console.log("groupIcon"+ groupIcon);
  // // console.log("groupDescription"+ groupDescription);
  // console.log("secretKey"+ secretKey);
  // console.log("productDetail"+ productDetail);
  // console.log("groupId"+ groupId);
  // alert( userProductDes ? userProductDes :  'Product is shared with you');
  // return;

    let nameToShowInNotification = ShowNameAccordingToLength (
      userOwnDetail.firstName + ' ' + userOwnDetail.lastName
    );
    // console.log("_.get(productDetail,'productCategoryType','')>>>>", _.get(productDetail,'productCategoryType',''))
    let sendText = _.get (productDetail, 'productCategoryType', '') === 'Radiology'
      ?  _.get (productDetail, 'deviceSaleName', '').toString (): _.get (productDetail, 'productName', '').toString ();

    const database = firebase.database ();
    const message = {
      sender: userOwnDetail.docID,
      text: userProductDes ? encryptMessage (userProductDes.toString(),secretKey.toString ()) :  encryptMessage (sendText.toString (), secretKey.toString ()),
      productDetail: productDetail,
      type: 'productLink',
      timeStamp: Math.floor (Date.now ()),
      read: false,
      senderProfilePic: userOwnDetail.profilePictureURL,
      senderName: nameToShowInNotification,
    };

    await database.ref ('GroupMessages').child (messengerKey).child ('messages').push (message).key;
    await database.ref('GroupMessages').child(messengerKey).child('metaData').update({
      "lastMsg": `${userOwnDetail.personalDetails.firstName} has shared a product`,
      "sender":userOwnDetail.docID,
      "senderName": nameToShowInNotification,
      'timeStamp': message.timeStamp,
      "mediaType": '',
      "type": 'productLink',
      "chatThread": messengerKey,
      "chatType": 'groupChat',
      "readBy": {
        [`${userOwnDetail.docID}`]: {
          read: true
        }
      },
    });
  };
};




export const sendMessage = (
  key,
  senderId, // user own UID 
  text, // text
  oppositeUserUid, // opposite user uid
  profilePictureURL,// opposite user profilepic
  displayName,  // opposite user displayname
  type // two types : "text" and 'image'
) => {
  
  
  return async dispatch => {

  }}

  export const sendDataToMobileWhenUsingConnect = (obj) => {
    // console.log("sendDataToMobileWhenUsingConnect>>>>>>", obj)
    let authToken = _.get(obj,'authToken');
    let GOOGLE_FCM_KEY = environmentUsed.GOOGLE_FCM_KEY;
    let props = obj.props;
    let mobilefirebaseToken = _.get(obj,'mobilefirebaseToken');
    let oppositeUserPublicKey = _.get(obj,'oppositeUserPublicKey');
    let messageData = _.get(obj,'messageData');
    let userOwnId = _.get(props,'userinfo.user.Data.userDetails[0].personalDetails.docID',null)

      if (authToken != '') {
        const headers = {
          // Authorization: 'Bearer ' +  authToken,
          Authorization: `key=${GOOGLE_FCM_KEY}`,
          'Content-Type': 'application/json',
        }
        let objTostringify = {
          "to": mobilefirebaseToken,
          "data" : {
            "platformType": "webConnect",
              ...messageData,
             'opposite_user_public_key': oppositeUserPublicKey,
            },
          "content_available": true,
          "contentAvailable": true,
          "content-available": true,
          "priority": "high",
            // 'opposite_user_public_key': oppositeUserPublicKey,
        }
        const bodyToSend = JSON.stringify(objTostringify)
        // console.log("bodyToSend>>>>>>>", bodyToSend)
        try {
           axios({
            method: 'post',
            url: 'https://fcm.googleapis.com/fcm/send',
            headers: headers,
            data: bodyToSend,
          }).then(function (response) {
            // console.log("sendData response>>>>>>",response);
          })
        } catch (err) {
          console.log("err>>>>>>>sendDataToMobileWhenUsingConnect",err)
          return { err }
        }
      }    
  }
