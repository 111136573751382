
export function hexToRGB(hex, alpha) {
    if (!hex || [4, 7].indexOf(hex.length) === -1) {
        return; // throw new Error('Bad Hex');
    }

    hex = hex.substr(1);
    // if shortcuts (#F00) -> set to normal (#FF0000)
    if (hex.length === 3) { 
        hex = hex.split('').map(function(el){ 
              return el + el + '';
            }).join('');
    }

    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);

    if (alpha !== undefined) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
    }
}


export function getBrowserName() {

    if ( navigator.userAgent.indexOf("Edge") > -1 && navigator.appVersion.indexOf('Edge') > -1 ) {
        return 'Edge';
    }
    else if( navigator.userAgent.indexOf("Opera") !== -1 || navigator.userAgent.indexOf('OPR') !== -1 )
    {
        return 'Opera';
    }
    else if( navigator.userAgent.indexOf("Chrome") !== -1 )
    {
        return 'Chrome';
    }
    else if( navigator.userAgent.indexOf("Safari") !== -1)
    {
        return 'Safari';
    }
    else if( navigator.userAgent.indexOf("Firefox") !== -1 ) 
    {
        return 'Firefox';
    }
    else if( ( navigator.userAgent.indexOf("MSIE") !== -1 ) || (!!document.documentMode === true ) ) //IF IE > 10
    {
        return 'IE';
    }  
    else 
    {
        return 'unknown';
    }
}

export function getDeviceModal(){
   
    if (window.navigator.userAgent.indexOf("Windows NT 10.0")!== -1){
        return "Windows 10";
    } else if (window.navigator.userAgent.indexOf("Windows NT 6.2") !== -1){
        return "Windows 8";
    }
    else if (window.navigator.userAgent.indexOf("Windows NT 6.1") !== -1){
        return "Windows 7";
    }
    else if (window.navigator.userAgent.indexOf("Windows NT 6.0") !== -1){
        return "Windows Vista";
    }
    else if (window.navigator.userAgent.indexOf("Windows NT 5.1") !== -1){
        return "Windows XP";
    }
    else if (window.navigator.userAgent.indexOf("Windows NT 5.0") !== -1){
        return "Windows 2000";
    }
    else if (window.navigator.userAgent.indexOf("Mac")){
        return "Mac/iOS";
    }
    else if (window.navigator.userAgent.indexOf("X11")){
        return "UNIX";
    }
    else if (window.navigator.userAgent.indexOf("Linux")){
        return "Linux";
    }
    else 
    {
        return 'unknown';
    }
}

export const CapitalizeFirstLetter = (str) => {
    return str && str.length ? str.charAt(0).toUpperCase() + str.slice(1) : str
}


export const getBlobFromUrl = myImageUrl => {
    return new Promise ((resolve, reject) => {
      let request = new XMLHttpRequest ();
      request.open ('GET', myImageUrl, true);
      request.responseType = 'blob';
      request.onload = () => {
        resolve (request.response);
      };
      request.onerror = reject;
      request.send ();
    });
  };
export const getDataFromBlob = myBlob => {
    return new Promise ((resolve, reject) => {
      let reader = new FileReader ();
      reader.onload = () => {
        resolve (reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL (myBlob);
    });
  };


  export const arrayBufferToBase64= (buffer) =>{
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => binary += String.fromCharCode(b));
    return window.btoa(binary);
};