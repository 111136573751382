import {Loading} from '../actions/LoadingAction';
import {createNotification} from '../constants/notificationtoast';
import {
  ACCEPT_SERVICE_REQUEST,
  REJECT_SERVICE_REQUEST,
  AWARDED_SERVICE_REQUEST,
} from '../reducers/serviceRequestReducer';
import {
  serviceOnDemandAcceptRequest,
  serviceOnDemandRejectRequest,
  serviceOnDemandSearchUserRequests,
  serviceOnDemandAwardRequest,
} from '../services/serviceOnDemandService';

import {GetMyServiceRequests,GetMyRequestsRecieved} from '../actions/serviceOnDemandAction';

// export const loginService = request => {
//     return dispatch => {
//       dispatch (Loading (true));
//       loginService (request)
//         .then (res => {
//           if (
//             res &&
//             res.data &&
//             res.data.result &&
//             res.data.result.status === true
//           ) {
//             // Saving direct user Result instead of saving result key/value pair inside reducer
//             if(loginData.data.useTrusona){

//               firebase.auth().signInWithCustomToken(res.data.result.Data.customToken).then((result) => {
//                 console.log(result);
//                 if(result){
//                   if(result.pa){
//                     dispatch(updateFirebaseToken(result.pa));
//                     dispatch ({type: LOGIN, payload: res.data.result});
//                   }
//                   // console.log("user at firebase",res)
//                   // return <Redirect to={routes.HOME} />;
//                 } else {
//                   console.log('user signed out or still need to sign in', res);
//                   // return <Redirect to={routes.SIGN_IN} />;
//                 }

//               }).catch((e)=>{
//                   console.log("error",e)
//                   createNotification ('error', e.message);
//               })

//             }else{
//               dispatch ({type: LOGIN, payload: res.data.result});
//               dispatch (Loading (false));
//             }

//             const sendRequest = {
//               data: {
//                 getPersonalDetails: true,
//                 getProfessionalDetails: true,
//                 getEducationalDetails: true,
//                 getBusinessProfile: true,
//               },
//             };
//             dispatch (getUserDetail (sendRequest));

//           } else {
//             if (res && res.data && res.data.result) {
//               createNotification ('error', res.data.result.message);
//             }
//             dispatch (Loading (false));
//           }
//         })
//         .catch (err => {
//           dispatch (Loading (false));

//           console.log (err);
//           throw err;
//         });
//       console.log ('login is running');
//     };
// };

export const acceptServiceRequest = request => {
  return dispatch => {
    dispatch (Loading (true));
    serviceOnDemandAcceptRequest (request)
      .then (userData => {
        dispatch (Loading (false));
        if (userData && userData.data && userData.data.result.status === true) {
          dispatch ({
            type: ACCEPT_SERVICE_REQUEST,
            payload: userData.data.result.Data,
          });
          const sendRequest = {
            data: {
              docID: request.data.docID,
            },
          };
          
          dispatch (GetMyRequestsRecieved (sendRequest));
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch (err => {
        dispatch (Loading (false));
        throw err;
      });
  };
};

// export const acceptServiceRequest = request => {
//   return dispatch => {
//     dispatch (Loading (true));
//     serviceOnDemandAcceptRequest (request)
//       .then (userData => {
//         dispatch (Loading (false));
//         if (userData && userData.data && userData.data.result.status === true) {
//           dispatch ({
//             type: ACCEPT_SERVICE_REQUEST,
//             payload: userData.data.result.Data,
//           });
//         } else {
//           if (userData && userData.data && userData.data.result) {
//             createNotification ('error', userData.data.result.message);
//           }
//         }
//       })
//       .catch (err => {
//         dispatch (Loading (false));
//         throw err;
//       });
//   };
// };

export const rejectServiceRequest = request => {
  return dispatch => {
    dispatch (Loading (true));
    serviceOnDemandRejectRequest (request)
      .then (userData => {
        dispatch (Loading (false));
        if (userData && userData.data && userData.data.result.status === true) {
          dispatch ({
            type: REJECT_SERVICE_REQUEST,
            payload: userData.data.result.Data,
          });
          const sendRequest = {
            data: {
              docID: request.data.docID,
            },
          };
          
          dispatch (GetMyRequestsRecieved (sendRequest));
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch (err => {
        dispatch (Loading (false));
        throw err;
      });
  };
};

export const awardedServiceRequest = request => {
  return dispatch => {
    dispatch (Loading (true));
    serviceOnDemandAwardRequest (request)
      .then (userData => {
        dispatch (Loading (false));
        if (userData && userData.data && userData.data.result.status === true) {
          dispatch ({
            type: AWARDED_SERVICE_REQUEST,
            payload: userData.data.result.Data,
          });
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch (err => {
        dispatch (Loading (false));
        throw err;
      });
  };
};

//   export const acceptServiceRequest = data => {
//       console.log(data)
//     return ({type: ACCEPT_SERVICE_REQUEST, payload: data});
//    };
