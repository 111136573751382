import firebase from 'firebase';
import store,{history} from '../store';

// // function to generate messenger key
export const  generateMessengerKey = (userId, receiverId) => {
        let keyVal = "";
        if (receiverId > userId) {
          keyVal = `${receiverId}${userId}`;
        } else {
          keyVal = `${userId}${receiverId}`;
        }
        return keyVal;
}

export const checkLogin=()=>{
  const currentState = store.getState();
  const { Authreducer } = currentState;
  let isLogin= false;

  if(
    Authreducer &&
    Authreducer.authToken
  ) {
    isLogin =true;
  }else{
    isLogin =false;

  }
  return isLogin;
}

export const checkAutomation = async ()=>{

      const database = firebase.database();
      const automationRef = database.ref(`automation`);
      return new Promise((resolve, reject) => {
  
        automationRef.on("value", snapshot => {
          let response= snapshot.val();
          // console.log(response.web);
          if(response && response.web){
            resolve(response.web);
          }else{
            resolve(false);

          }
        })
      });
}

export const priceWithDecimal=(value)=>{
    let price=0;
    if(value){
      price= Number(value).toFixed(2);
    }
  return price;
}

export const formatFileSize= (bytes)=> {
  let exactSize;
  var _size = bytes;
  var fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
  i=0;
  while(_size>900){_size/=1024;i++;}
    return exactSize = (Math.round(_size*100)/100)+' '+fSExt[i];
}

export const removeWhiteSpace= (searchText)=> {
  var str = searchText;
  var replaced = str.replace(' ', '%');
  return replaced;

}



export const shippingTrackingURL=(selectShippingAgent)=>{
  let trackingNewurl='';
  if(selectShippingAgent==="FedEx"){
    trackingNewurl='https://www.fedex.com/apps/fedextrack/?tracknumbers='
  }
  if(selectShippingAgent==="DHL"){
    trackingNewurl='https://www.dhl.com/en/express/tracking.shtml?AWB='
  }
  if(selectShippingAgent==="UPS"){
    trackingNewurl='http://wwwapps.ups.com/WebTracking/processInputRequest?TypeOfInquiryNumber=T&InquiryNumber1='
  }
  if(selectShippingAgent==="Aramex"){
    trackingNewurl='https://www.aramex.com/us/en/track/results?mode=0&ShipmentNumber='
  }
  return trackingNewurl;
}


export const sortBynameCountry=()=>{
  return function (a, b) {
    var nameA = a.label.toLowerCase (),
      nameB = b.label.toLowerCase ();
    if (
      nameA < nameB //sort string ascending
    )
      return -1;
    if (nameA > nameB) return 1;
    return 0;
  };
}


