import { LOGOUT } from './Authreducer';

let initialState = {
    notifications:[],
};

export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';

export default (state=initialState,action) => {
    switch (action.type) {
        case UPDATE_NOTIFICATIONS:
            return {...state, notifications:action.payload};
        case LOGOUT:
            return {...initialState};
        default:
            return state;
    }
}