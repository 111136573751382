let initialState = {
  serviceProfile: {},
  authToken: '',
  registeruser: {},
};

export const GETSERVICEPROFILE = 'GETSERVICEPROFILE';
export const MY_SERVICE_REQUESTS = 'MY_SERVICE_REQUESTS';
export const MY_REQUESTS_RECIEVED = 'MY_REQUESTS_RECIEVED';
export const SEARCH_SERVICE_PROVIDERS = 'SEARCH_SERVICE_PROVIDERS';
export const CREATE_SERVICE_PROFILE = 'CREATE_SERVICE_PROFILE';
export const ADD_SERVICE_OFFERED = 'ADD_SERVICE_OFFERED';
export const CREATE_REQUEST = 'CREATE_REQUEST';
export const CHECK_SERVICE_PROFILE_EXISTS = 'CHECK_SERVICE_PROFILE_EXISTS';
export const SEARCH_PROVIDER_REQUEST = 'SEARCH_PROVIDER_REQUEST';
export const UPLOAD_ERROR_LOG = 'UPLOAD_ERROR_LOG';


export default (state = initialState, action) => {
  switch (action.type) {
    case GETSERVICEPROFILE:
      return {...state, serviceProfile: action.payload};
    case MY_SERVICE_REQUESTS:
      return {...state, myserviceRequest: action.payload};
    case MY_REQUESTS_RECIEVED:
      return {...state, myRequestRecieved: action.payload};
    case SEARCH_SERVICE_PROVIDERS:
      return {...state, searchServiceProvider: action.payload};
    case CREATE_SERVICE_PROFILE:
      return {...state, createServiceProfile: action.payload};
    case ADD_SERVICE_OFFERED:
      return {...state, AddServiceOffered: action.payload};
    case CREATE_REQUEST:
      return {...state, CreateRequest: action.payload};
    case CHECK_SERVICE_PROFILE_EXISTS:
      return {...state, checkServiceProfileExists: action.payload};
    case SEARCH_PROVIDER_REQUEST:
      return {...state, searchProviderFilter: action.payload};
      case UPLOAD_ERROR_LOG:
      return {...state, uploadErrorLog: action.payload};

    default:
      return state;
  }
};
