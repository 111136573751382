import React, {Component} from 'react';
import {
  Button,
} from 'reactstrap';
import ServiceOnDemandProfilePage
  from '../service-On-Demand-Profile/serviceOnDemandProfile';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import './serviceOnDemandMyRequest.css';
import ServiceOnDemandCommonComponent from '../Service-On-Demand-CommanComponent/Service-On-Demand-CommanComponent';
import * as routes from '../../constants/routes';

import requestimg from '../../images/serviceOnDemandMyRequest/requestimg.png';
import greyframe from '../../images/serviceOnDemandMyRequest/greyframe.png';
import greywatch from '../../images/serviceOnDemandMyRequest/greywatch.png';
import greygear from '../../images/serviceOnDemandMyRequest/greygear.png';
import greendot from '../../images/serviceOnDemandMyRequest/greendot.png';

import gridview from '../../images/serviceOnDemandMyRequest/gridview.png';
import listview from '../../images/serviceOnDemandMyRequest/listview.png';
import gridviewactive from '../../images/serviceOnDemandMyRequest/gridviewactive.png';
import listviewactive from '../../images/serviceOnDemandMyRequest/listviewactive.png';

import {
  GetMyServiceRequests,
  ResetGetServiceProviders
} from '../../actions/serviceOnDemandAction';


class serviceOnDemandMyRequestPage extends Component {
  constructor (props) {
    super (props);
    this.state = {
      gridView: true,
      loginUserID:'',
      myRequestsList:[],
      filterStatus:''
    };
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if (nextProps.service) {
        this.setState ({
          myRequestsList: nextProps.service.myserviceRequest,
        });
      }
     
    }
  }


  async componentDidMount(){
    let loginUser = await localStorage.getItem ('loginuser');


    if(loginUser){
      let getUser = JSON.parse(loginUser);
 
      this.setState({
        loginUserID: getUser.docID
      },()=>{
        this.fetchMyPlaceRequestServices('');
        
      })
    }
  }

  fetchMyPlaceRequestServices(status){
    let sendRequest = {
      data: {
        // docID: this.state.loginUserID,
        docID: '',
        status: status,
      },
    };
    this.setState({
      filterStatus: status
    })
    this.props.GetMyServiceRequests(sendRequest);
  }
  viewMode (status) {
    this.setState ({
      gridView: status,
    });
  };
  goDetails (item) {
    // this.props.history.push (routes.SERVICEONDEMANDMYREQUESTDETAIL);
    this.props.history.push (routes.SERVICEONDEMANDMYREQUESTDETAIL+`/${item.docID}`);
  }
  render () {
    const {filterStatus} = this.state;
    return (
      <div style={{display: 'flex', background: '#FFFFFF'}}>
        <div className="inlineFlex100">
          <div className="parentRowMyRequest">
            <div style={{width:230}}>
              <ServiceOnDemandCommonComponent/>
            </div>
            <div className="scrollBehaveXMyRequest">
            {/*========My Placed Requests ============*/}
              <div style={{width:800,display:'inline-flex'}}>
                <div style={{width:'60%',float:'left'}}>
                  <div className="MyPlacedRequests">
                    My Placed Requests
                  </div>
                </div>
                <div style={{width:'40%',float:'right'}}>
                  <div className="Rectangle19">
                    <div style={{display: 'flex'}}  onClick={()=>this.props.history.push(routes.SERVICEONDEMANDCREATESERVICEREQUEST)}>
                      <div>
                        <div className="GENERATEA">GENERATE A</div>
                        <div className="ServiceRequest"> Service Request</div>
                      </div>
                      <div className="Rectangle08">
                        <img alt="img" src={requestimg} style={{paddingTop: 8}} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
            {/*========Menu ============*/}
              
              <div className="buttonBarMyRequest">
                  <Button  onClick={()=>this.fetchMyPlaceRequestServices('')}  
                  className={'filterButtons ' + (filterStatus==='' ? 'activeButtonMyRequest':'')}
                  
                  >All</Button>
                  <Button onClick={()=>this.fetchMyPlaceRequestServices('draft')} 
                  className={'filterButtons ' + (filterStatus==='draft' ? 'activeButtonMyRequest':'')}>Draft</Button>
                  <Button onClick={()=>this.fetchMyPlaceRequestServices('awarded')} className={ "filterButtons " +(filterStatus==='awarded' ? 'activeButtonMyRequest':'')}>Placed</Button>
                  <Button onClick={()=>this.fetchMyPlaceRequestServices('accepted')} className={ "filterButtons " +(filterStatus==='accepted' ? 'activeButtonMyRequest':'')}>Awarded</Button>
                  <Button onClick={()=>this.fetchMyPlaceRequestServices('paid')} className={ "filterButtons " + (filterStatus==='paid' ? 'activeButtonMyRequest':'')}>Confirmed</Button>
                  <Button onClick={()=>this.fetchMyPlaceRequestServices('job started')} className={ "filterButtons " +(filterStatus==='job started' ? 'activeButtonMyRequest':'')}>Started</Button>
                  <Button onClick={()=>this.fetchMyPlaceRequestServices('job completed')} className={"filterButtons " +(filterStatus==='job completed' ? 'activeButtonMyRequest':'')} style={{width: 120}}>Job Complete </Button>
                  <Button onClick={()=>this.fetchMyPlaceRequestServices('Completed')} className={"filterButtons " +(filterStatus==='Completed' ? 'activeButtonMyRequest':'')}>Completed</Button>
                  
                  <div onClick={()=>this.viewMode(true)} style={{cursor: 'pointer',marginRight: 10}}>
                    <img alt="" src={this.state.gridView ? gridviewactive:gridview} style={{width:28}}/>
                  </div>
                  <div onClick={()=>this.viewMode(false)} style={{cursor: 'pointer'}}>
                    <img alt=""  src={!this.state.gridView ? listviewactive:listview} style={{width:30}}/>
                  </div>
              </div>
             {/*========Grid View ============*/}

             {this.state.myRequestsList && this.state.myRequestsList.length===0 &&<div className="noPlaceRequest">
             No Placed Requests
             </div>}

              {this.state.gridView &&
              <div className="serviceOnDemandPlaceRequestBoxes">
                {/*<div className="Rectangle212" onClick={() =>this.goDetails()}>
                    <div className="SiemensServicing">
                      Siemens Servicing Session
                    </div>
                    <div className="Request">
                      Request: #137512
                    </div>
                    <div style={{display: 'flex'}}>
                      <div>
                        <img alt="" src={nameframe} />
                      </div>
                      <div className="Lorenda">
                        Tanya Lorenda
                      </div>
                    </div>
                    <div style={{display: 'flex'}}>
                      <div>
                        <img alt="" src={watch} />
                      </div>
                      <div className="dateservice">
                        11/4/2019
                      </div>
                    </div>
                    <div style={{display: 'flex'}}>
                      <div >
                        <img alt="" src={gear} />
                      </div>
                      <div style={{paddingLeft: 9}}>
                        <img alt="" src={yellowdot} />
                        <span className="Awarded"> Awarded </span>
                      </div>
                    </div>
                    <div className="Rectangle00" />

              </div>*/}
                {/*-------------------*/}
                {this.state.myRequestsList && this.state.myRequestsList.length>0 && this.state.myRequestsList.map((item ,index)=>(<div key={index} 
                  className="Rectangle007" onClick={() =>this.goDetails(item)}>
                  <div className="GEServicing">
                  {item.title}
                  </div>
                  <div className="RequestNA">
                    Request:  {item.request_id ? item.request_id  :'N/A'}
                  </div>
                  <div style={{display: 'flex'}}>
                    <div>
                      <img alt="" src={greyframe} />
                    </div>
                    <div className="MartinJames">
                    {item.serviceProviderTitle} {item.serviceProviderDisplayName}
                    </div>
                  </div>
                  <div style={{display: 'flex'}}>
                    <div>
                      <img alt="" src={greywatch} />
                    </div>
                    <div className="date2">
                    {item.serviceDate}
                    </div>
                  </div>
                  <div style={{display: 'flex'}}>
                    <div>
                      <img alt="" src={greygear} />
                    </div>
                    <div style={{display: 'flex',paddingLeft: 10,alignItems: "center"}}>
                    <div className={item.status === "draft" ?'greencircle':''}></div>
                    <div className={item.status === "awarded" ?'yellowcircle':''}></div>
                    <div className={item.status === "accepted" ?'yellowcircle':''}></div>
                    <div className={item.status === "job completed" ?'orangecircle':''}></div>
                    <div className={item.status === "rejected" ?'orangecircle':''}></div>
                    <div className={item.status === "pending" ?'whitecircle':''}></div>
                    <div className={item.status === "confirmed" ?'lightgreen':''}></div>
                      <span className="Draft" style={{textTransform:'capitalize',paddingLeft:5}}> 
                      {item.status} </span>
                    </div>
                  </div>
                  <div className="Rectangle100" />
                </div>))}
                {/*-------------------*/}
                
              </div>}
             {/*========Grid View =======*/}

             {/*=========List View =======*/}
              {!this.state.gridView &&
                <div style={{width:800}}>
                
                {/*-----------Awarded-----------*/}
                  {/*<div className="rect">
                    <div>
                      <div className="Request137512"> Request: #137512</div>
                      <div className="SiemensServicing">
                        {' '}Siemens Servicing Session
                      </div>
                    </div>
                    <div>
                      <img alt="" src={avtarCard1} style={{width: 14}} />
                      <span className="tanyaa mLeft5">Tanya Lorenda</span>
                    </div>
                    <div>
                      <img alt="" src={watchCard1} style={{width: 14}} />
                      <span className="tanyaa mLeft5">11/4/2019</span>
                    </div>
                    <div>
                      <div className="inline">
                        <div className='AwardeddotMyRequest'></div>
                        <span className="AwardedText mLeft5">Awarded</span>
                      </div>
                      <div className="reclo" />
                    </div>
              </div>*/}
                {/*-----------Draft-----------*/}
                
                {this.state.myRequestsList && this.state.myRequestsList.length>0 && this.state.myRequestsList.map((item ,index)=>(<div className="rect2">
                    <div>
                      <div className="Requestttt">
                        Request: {item.request_id ? item.request_id  :'N/A'}
                      </div>
                      <div className="Sessionnn">
                        {item.title}
                      </div>
                    </div>
                    <div>
                      <img alt="" src={greyframe} style={{width: 14}} />
                      <span className="DrMartinJames mLeft5">
                      {item.serviceProviderTitle} {item.serviceProviderDisplayName}
                      </span>
                    </div>
                    <div>
                      <img alt="" src={greywatch} style={{width: 14}} />
                      <span className="dateee mLeft5">
                      {item.serviceDate}
                      </span>
                    </div>
                    <div>
                    <div className={item.status === "draft" ?'greencircle':''}></div>
                    <div className={item.status === "awarded" ?'yellowcircle':''}></div>
                    <div className={item.status === "accepted" ?'yellowcircle':''}></div>
                    <div className={item.status === "job completed" ?'orangecircle':''}></div>
                    <div className={item.status === "rejected" ?'orangecircle':''}></div>
                    <div className={item.status === "pending" ?'whitecircle':''}></div>
                    <div className={item.status === "confirmed" ?'lightgreen':''}></div>
                    <div className={item.status === "paid" ?'lightgreen':''}></div>
                      {/*<div className='CompleteLeaveFeedbackdotMyRequest'></div>
                      <div className='placeddotMyRequest'></div>
                      <div className='ConfirmeddotMyRequest'></div>
                      <div className='StarteddotMyRequest'></div>
            <div className='completeddotMyRequest'></div>*/}
                      <span className="Drafttt mLeft5"> {item.status}</span>
                      <div className="reclo" />
                    </div>
                  </div>))}
              
                
               

                 
              
                </div>}
              {/*=========List View =========*/}
          </div>
          </div>
          <div className="notificationCSSMyRequest" >
           
            <ServiceOnDemandProfilePage
            history={this.props.history}
            />
          </div>

        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    service: state.serviceOnDemandReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      GetMyServiceRequests
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  serviceOnDemandMyRequestPage
);
