const ukStates = [
  {name: 'Aberdeen City', code: 'Aberdeen City', region: 'Scotland'},
  {name: 'Aberdeenshire', code: 'Aberdeenshire', region: 'Scotland'},
  {name: 'Angus', code: 'Angus', region: 'Scotland'},
  {name: 'Antrim', code: 'Antrim', region: 'Northern Ireland'},
  {name: 'Argyll and Bute', code: 'Argyll and Bute', region: 'Scotland'},
  {name: 'Armagh', code: 'Armagh', region: 'Scotland'},
  {name: 'Avon', code: 'Avon', region: 'England'},
  {name: 'Bedfordshire', code: 'Bedfordshire', region: 'England'},
  {name: 'Berkshire', code: 'Berkshire', region: 'England'},
  {name: 'Blaenau Gwent', code: 'Blaenau Gwent', region: 'Wales'},
  {name: 'Borders', code: 'Borders', region: 'England'},
  {name: 'Bridgend', code: 'Bridgend', region: 'Wales'},
  {name: 'Bristol', code: 'Bristol', region: 'England'},
  {name: 'Buckinghamshire', code: 'Buckinghamshire', region: 'England'},
  {name: 'Caerphilly', code: 'Caerphilly', region: 'Wales'},
  {name: 'Cambridgeshire', code: 'Cambridgeshire', region: 'England'},
  {name: 'Cardiff', code: 'Cardiff', region: 'Wales'},
  {name: 'Carmarthenshire', code: 'Carmarthenshire', region: 'Scotland'},
  {name: 'Ceredigion', code: 'Ceredigion', region: 'Wales'},
  {name: 'Channel Islands', code: 'Channel Islands', region: 'England'},
  {name: 'Cheshire', code: 'Cheshire', region: 'England'},
  {name: 'Clackmannan', code: 'Clackmannan', region: 'Scotland'},
  {name: 'Cleveland', code: 'Cleveland', region: 'England'},
  {name: 'Conwy', code: 'Conwy', region: 'Wales'},
  {name: 'Cornwall', code: 'Cornwall', region: 'England'},
  {name: 'Cumbria', code: 'Cumbria', region: 'England'},
  {name: 'Denbighshire', code: 'Denbighshire', region: 'Wales'},
  {name: 'Derbyshire', code: 'Derbyshire', region: 'England'},
  {name: 'Devon', code: 'Devon', region: 'England'},
  {name: 'Dorset', code: 'Dorset', region: 'England'},
  {name: 'Down', code: 'Down', region: 'Northern Ireland'},
  {
    name: 'Dumfries and Galloway',
    code: 'Dumfries and Galloway',
    region: 'Scotland',
  },
  {name: 'Durham', code: 'Durham', region: 'England'},
  {name: 'East Ayrshire', code: 'East Ayrshire', region: 'Scotland'},
  {
    name: 'East Dunbartonshire',
    code: 'East Dunbartonshire',
    region: 'Scotland',
  },
  {name: 'East Lothian', code: 'East Lothian', region: 'Scotland'},
  {name: 'East Renfrewshire', code: 'East Renfrewshire', region: 'Scotland'},
  {
    name: 'East Riding of Yorkshire',
    code: 'East Riding of Yorkshire',
    region: 'England',
  },
  {name: 'East Sussex', code: 'East Sussex', region: 'England'},
  {name: 'Edinburgh City', code: 'Edinburgh City', region: 'Scotland'},
  {name: 'Essex', code: 'Essex', region: 'England'},
  {name: 'Falkirk', code: 'Falkirk', region: 'Scotland'},
  {name: 'Fermanagh', code: 'Fermanagh', region: 'Northern Ireland'},
  {name: 'Fife', code: 'Fife', region: 'Scotland'},
  {name: 'Flintshire', code: 'Flintshire', region: 'Wales'},
  {name: 'Glasgow', code: 'Glasgow', region: 'Scotland'},
  {name: 'Gloucestershire', code: 'Gloucestershire', region: 'England'},
  {name: 'Greater Manchester', code: 'Greater Manchester', region: 'England'},
  {name: 'Gwynedd', code: 'Gwynedd', region: 'Wales'},
  {name: 'Hampshire', code: 'Hampshire', region: 'England'},
  {name: 'Herefordshire', code: 'Herefordshire', region: 'England'},
  {name: 'Hertfordshire', code: 'Hertfordshire', region: 'England'},
  {name: 'Highland', code: 'Highland', region: 'Scotland'},
  {name: 'Humberside', code: 'Humberside', region: 'England'},
  {name: 'Inverclyde', code: 'Inverclyde', region: 'Scotland'},
  {name: 'Isle of Anglesey', code: 'Isle of Anglesey', region: 'Wales'},
  {name: 'Isle of Man', code: 'Isle of Man', region: 'England'},
  {name: 'Isle of Wight', code: 'Isle of Wight', region: 'England'},
  {name: 'Isles of Scilly', code: 'Isles of Scilly', region: 'England'},
  {name: 'Kent', code: 'Kent', region: 'England'},
  {name: 'Lancashire', code: 'Lancashire', region: 'England'},
  {name: 'Leicestershire', code: 'Leicestershire', region: 'England'},
  {name: 'Lincolnshire', code: 'Lincolnshire', region: 'England'},
  {name: 'London', code: 'London', region: 'England'},
  {name: 'Londonderry', code: 'Londonderry', region: 'Northern Ireland'},
  {name: 'Merseyside', code: 'Merseyside', region: 'England'},
  {name: 'Merthyr Tydfil', code: 'Merthyr Tydfil', region: 'Wales'},
  {name: 'Middlesex', code: 'Middlesex', region: 'England'},
  {name: 'Midlothian', code: 'Midlothian', region: 'Scotland'},
  {name: 'Monmouthshire', code: 'Monmouthshire', region: 'Wales'},
  {name: 'Moray', code: 'Moray', region: 'Scotland'},
  {name: 'Neath Port Talbot', code: 'Neath Port Talbot', region: 'Wales'},
  {name: 'Newport', code: 'Newport', region: 'Wales'},
  {name: 'Norfolk', code: 'Norfolk', region: 'England'},
  {name: 'North Ayrshire', code: 'North Ayrshire', region: 'Scotland'},
  {name: 'North Lanarkshire', code: 'North Lanarkshire', region: 'Scotland'},
  {name: 'North Yorkshire', code: 'North Yorkshire', region: 'England'},
  {name: 'Northamptonshire', code: 'Northamptonshire', region: 'England'},
  {name: 'Northumberland', code: 'Northumberland', region: 'England'},
  {name: 'Nottinghamshire', code: 'Nottinghamshire', region: 'England'},
  {name: 'Orkney', code: 'Orkney', region: 'Scotland'},
  {name: 'Oxfordshire', code: 'Oxfordshire', region: 'England'},
  {name: 'Pembrokeshire', code: 'Pembrokeshire', region: 'Wales'},
  {
    name: 'Perthshire and Kinross',
    code: 'Perthshire and Kinross',
    region: 'Scotland',
  },
  {name: 'Powys', code: 'Powys', region: 'Wales'},
  {name: 'Renfrewshire', code: 'Renfrewshire', region: 'Scotland'},
  {name: 'Rhondda Cynon Taff', code: 'Rhondda Cynon Taff', region: 'Wales'},
  {name: 'Roxburghshire', code: 'Roxburghshire', region: 'Scotland'},
  {name: 'Rutland', code: 'Rutland', region: 'England'},
  {name: 'Shetland', code: 'Shetland', region: 'Scotland'},
  {name: 'Shropshire', code: 'Shropshire', region: 'England'},
  {name: 'Somerset', code: 'Somerset', region: 'England'},
  {name: 'South Ayrshire', code: 'South Ayrshire', region: 'Scotland'},
  {name: 'South Lanarkshire', code: 'South Lanarkshire', region: 'Scotland'},
  {name: 'South Yorkshire', code: 'South Yorkshire', region: 'England'},
  {name: 'Staffordshire', code: 'Staffordshire', region: 'England'},
  {name: 'Stirling', code: 'Stirling', region: 'Scotland'},
  {name: 'Suffolk', code: 'Suffolk', region: 'England'},
  {name: 'Surrey', code: 'Surrey', region: 'England'},
  {name: 'Swansea', code: 'Swansea', region: 'Wales'},
  {
    name: 'The Vale of Glamorgan',
    code: 'The Vale of Glamorgan',
    region: 'Wales',
  },
  {name: 'Torfaen', code: 'Torfaen', region: 'Wales'},
  {name: 'Tyne and Wear', code: 'Tyne and Wear', region: 'England'},
  {name: 'Tyrone', code: 'Tyrone', region: 'Northern Ireland'},
  {name: 'Warwickshire', code: 'Warwickshire', region: 'England'},
  {
    name: 'West Dunbartonshire',
    code: 'West Dunbartonshire',
    region: 'Scotland',
  },
  {name: 'West Lothian', code: 'West Lothian', region: 'Scotland'},
  {name: 'West Midlands', code: 'West Midlands', region: 'England'},
  {name: 'West Sussex', code: 'West Sussex', region: 'England'},
  {name: 'West Yorkshire', code: 'West Yorkshire', region: 'England'},
  {name: 'Western Isles', code: 'Western Isles', region: 'Scotland'},
  {name: 'Wiltshire', code: 'Wiltshire', region: 'England'},
  {name: 'Worcestershire', code: 'Worcestershire', region: 'England'},
  {name: 'Wrexham', code: 'Wrexham', region: 'Wales'},
];

export default ukStates;
