import React, { Component } from 'react'
import { Button } from 'reactstrap'
import './pricing-plan.css'

class PlansList extends Component {
  render () {
    return (
      <div className='priceListcontainer'>
        <div className='priceListChildcontainer'>
          {/* <h5 className="text-center pricing-table-subtitle"> Upgrade Your Plan</h5> */}
          <h1 className='text-center pricing-table-title'>Choose Your Plan</h1>
          <div className='row pricingBoxAlignCenter'>
            <div className='priceCardBoxContainer'>
              <div className=' pricing-card pricing-plan-basic'>
                <div className='card-body'>
                  {/* <i className="mdi mdi-cube-outline pricing-plan-icon"></i> */}
                  <p className='pricing-plan-title'>R-DEE Freemium</p>
                  <h3 className='pricing-plan-cost ml-auto'>$0/ month</h3>
                  <ul className='pricing-plan-features'>
                    <li>Login</li>
                    <li>Registration</li>
                    <li>Connect</li>
                    <li>Setting</li>
                    <li>Dashboard</li>
                    <li>Contacts</li>
                    <li>Contact Support</li>
                  </ul>
                  <Button className='btn pricing-plan-purchase-btn'>
                    Free
                  </Button>
                </div>
              </div>
            </div>
            <div className='priceCardBoxContainer'>
              <div className='card pricing-card pricing-plan-enterprise'>
                <div className='card-body'>
                  {/* <i className="mdi mdi-wallet-giftcard pricing-plan-icon"></i> */}
                  <p className='pricing-plan-title'>R-DEE Pro</p>
                  <h3 className='pricing-plan-cost ml-auto'>$10/month</h3>
                  <ul className='pricing-plan-features'>
                    <li>Login</li>
                    <li>Registration</li>
                    <li>Connect</li>
                    <li>Setting</li>
                    <li>Dashboard</li>
                    <li>Contacts</li>
                    <li>Vault</li>
                    <li>SCAN</li>
                    <li>Contact Support</li>
                  </ul>
                  <Button className='btn pricing-plan-purchase-btn'>
                    Purchase
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PlansList
