import React, { Component } from 'react';
import { Button } from 'reactstrap';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import './verifyEmail.scss';
import {
  verifyAccountEmail,
  fetchStatusCode,
  resetResetPasswordReducer,
  updateFirebaseToken,
} from '../../../actions/Authaction';
import { Loading } from '../../../actions/LoadingAction';

import * as routes from '../../../constants/routes';

class VerifyEmail extends Component {
  //defining state
  constructor(props) {
    super(props);
    this.state = {
      isVerification: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.userinfo) {
      if (nextProps.userinfo.emailVerification) {
        this.setState({
          isVerification: nextProps.userinfo.emailVerification,
        });
      }
    }
  }

  componentDidMount() {
    localStorage.clear();
    const query = new URLSearchParams(this.props.location.search);
    const emailAddress = query.get('emailAddress');
    const verifyCode = query.get('verifyCode');
    this.props.fetchStatusCode();

    this.props.Loading(true);
    this.props.updateFirebaseToken('');
    this.props.resetResetPasswordReducer();

    let sendRequest = {
      data: {
        emailAddress: emailAddress,
        verifyCode: verifyCode,
      },
    };
    setTimeout(() => {
      this.props.verifyAccountEmail(sendRequest);
    }, 3000);
  }

  goBack() {
    this.props.history.push(routes.SIGN_IN);
  }

  render() {
    const { isVerification } = this.state;
    return (
      <div className='div-flex gradientColorPurpleBlue ' id='verifyEmail'>
        <div className='paymentStatusBoxContainer' id='verifyEmail1'>
          <div className='paymentBox' id='verifyEmail2'>
            <div className='verificationTitlesuccess' id='verifyEmail3'>
              <p id='verifyEmail4' className='VerifyEmailTitle'>
                Email Verification{' '}
              </p>
              {isVerification && isVerification.status === true && (
                <p className='verificationfailedDescription' id='verifyEmail5'>
                  Email verification completed successfully
                </p>
              )}
              {isVerification && !isVerification.status && (
                <p className='verificationfailedDescription' id='verifyEmail6'>
                  {isVerification && isVerification.message
                    ? isVerification.message
                    : ''}
                </p>
              )}
            </div>

            <Button
              className='returnRDEEButton'
              onClick={() => this.goBack()}
              id='verifyEmail7'
            >
              {' '}
              Login
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userinfo: state.Authreducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyAccountEmail,
      fetchStatusCode,
      resetResetPasswordReducer,
      updateFirebaseToken,
      Loading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
