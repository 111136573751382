import React, {useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {Row,Col,Button ,Input} from "reactstrap";
import ImageCrop from '../ImageCrop';
import seainputicon from '../../../images/icons/seainputicon.png';
import {authErrorMessage} from '../../../constants/localMessage/auth/authHelper';
import * as authValidation from '../../../constants/localMessage/auth/authHelper';
import profilePlaceholder from '../../../images/profile_placeholder.png';

import {
    IMAGE_TYPE_ERROR,
    GROUP_DETAIL_PHOTO_REQUIRED,
    GROUP_DETAIL_PHOTO_ERROR,
    GROUP_DETAIL_NAME_REQUIRED,
    GROUP_DETAIL_NAME_TEXT_LENGTH,
} from '../../../constants/errorMessages';
import {
    createNotification,
    NOTIFICATION_TYPE_WARNING,
    NOTIFICATION_TYPE_ERROR,
} from '../../../constants/notificationtoast';
import './GroupDetails.css'

const image = require('../../../images/Group.png');

const validate = (image, name) => {
    if (!image || (typeof image === "string" && image.length === 0)) {
        throw new Error(GROUP_DETAIL_PHOTO_REQUIRED);
    }
    if (image && typeof image !== "string") {
        // 1 byte size
        const megaByteSize = 1048576;
        // Get size of the blob
        const sizeInBytes = image.size;
        if (sizeInBytes > (5 * megaByteSize)) {
            throw new Error(GROUP_DETAIL_PHOTO_ERROR);    
        }
    }
    if (!name || name.trim().length === 0) {
        throw new Error(GROUP_DETAIL_NAME_REQUIRED);
    }
    if (name && name.trim().length > 30) {
        throw new Error(GROUP_DETAIL_NAME_TEXT_LENGTH);
    }
    
    return ({
        name,
        image,
    });
}

export default function GroupDetails({
    submitButtonText = 'Submit',
    onSubmitClick = (data) => console.log('Submit clicked', data),
    imageSrc = '',
    name = name,
    placeholder = 'Group Name*'
}) {
    const [croppedImage, setCroppedImage] = useState(null);
    const [nameVal, setName] = useState((name||''));
    const [loadedImage, setLoadedImage] = useState(null);
    const inputOpenFileRef = useRef(null);
    const img = croppedImage ? URL.createObjectURL(croppedImage) : (imageSrc || image);
    
    const onFileSelectButtonPress = event => {
        event.preventDefault();
        inputOpenFileRef.current.click()
    };
    const loadImage = e => {
        if (e.target.files && e.target.files.length > 0) {

            let files = e.target.files;
            let response = files[0].size / 1000000;
        
            if (response && response > 5) {
              createNotification (NOTIFICATION_TYPE_ERROR, GROUP_DETAIL_PHOTO_ERROR);
              return;
            }
      


            const file = e.target.files[0];
            const fileType = file['type'];
            const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
            // Check If file is not an image
            if (!validImageTypes.includes(fileType)) {
                createNotification(NOTIFICATION_TYPE_ERROR, IMAGE_TYPE_ERROR);
            } else {
                const reader = new FileReader();
                reader.addEventListener('load', () => setLoadedImage(reader.result));
                reader.readAsDataURL(e.target.files[0]);
            }
        }
    };
    const submit = () => {
        try {
            const values = validate((croppedImage||imageSrc), nameVal);
            onSubmitClick(values);
        } catch (error) {
            if (error.message) {
                createNotification(NOTIFICATION_TYPE_WARNING, error.message);
            }
        }
    }

    return (
       <div>

       <div style={{minWidth:395}}>
        <p className="grouonamwmkdhkj" id="manageGrpBox">Group name:</p>
        <div className="groupnameucbj" id="manageGrpBox1">
            <img
            alt=""
            src={seainputicon}
            style={{ width: 34, height: 22, marginLeft: 10 }}
            id="manageGrpBox3"
            />
            <Input
            className="searchicsdfnput"
            placeholder="30 characters max"
            value={nameVal}
            placeholder={placeholder}
            onChange={e => setName(e.target.value)}
            id="manageGrpBox4"
            />
            
            
        </div>
        <p className="maxNameMessage" id="manageGrpBox5">30 characters max</p>
        {!nameVal.trim() && <p style={{width: 327,marginLeft:30}} className="redBoxErrorMsg"> 
        {authErrorMessage (authValidation.CREATE_GROUP_NAME)}
    </p>}

    <div className="EditGroupImageSection" id="manageGrpBox6">

        <div style={{width:250,marginLeft:35}} id="manageGrpBox7">
            <div className="groupIconTitle" id="manageGrpBox8">
            Group Picture 
            <Button  className="group-image-upload-button" 
                id="manageGrpBox9"
            onClick={onFileSelectButtonPress} style={{marginLeft:25}}  data-rh="Change group image">Replace</Button>
            </div>
            <div className="groupIconMessage" id="manageGrpBox10">Display picture must be JPG/PNG
            with a maximum file size of 5MB.</div>
        </div>
        <div >
        <img src={img ? img: profilePlaceholder}  onError={e => {
            e.target.onerror = null;
            e.target.src = profilePlaceholder;
          }}  className="editGroupIConImg" id="manageGrpBox11"/>
        </div>
    </div>
    <div id="manageGrpBox12" style={{textAlign:'right',marginRight:20,marginTop:20}}>
    <Button id="manageGrpBox13"  data-rh="Save" className="SaveChangesButton" style={{cursor:!nameVal || nameVal.length>30?'unset':''}} disabled={!nameVal || nameVal.length>30 } onClick={submit} >Save Changes</Button>
    </div>

    {loadedImage && <ImageCrop  image={loadedImage} onSubmitClick={croppedImage =>{
        setCroppedImage(croppedImage);
        setLoadedImage(null);
    }}/>
}
<input
    ref={inputOpenFileRef} 
    type="file"
    style={{display:"none"}}
    onChange={loadImage}
/>

    </div>

      


       {/*
         <div style={{display: "inline-flex"}}>
         <Col className="colwidth">
     
       <div className="firsttext">
        Provide a group subject and group icon
       </div>
       <div className="inputdiv" >
       <input 
           type="text"
           onChange={e => setName(e.target.value)}
           placeholder={placeholder}
           value={nameVal}
           maxlength="30"
       />
       <div className="footer-text">Maximum 30 characters</div>
     </div>
   
       </Col>
       <Col className="colwidth">
       <Row>
       
       <div>
       <img src={img}  style={{width:100, borderRadius:100}}/>
       </div>
       <Col>
       <div className="grouppic">
       Group Picture*
       </div>
       <div className="picturewar">
        Display Picture must be of type 
        JPEG/PNG with a file size of 
        maximum 5 MB
       </div>
       <div style={{marginTop:17}}>
       <a
           className="group-image-upload-button" 
           href="" 
           onClick={onFileSelectButtonPress}
       >
           Upload
       </a>
   </div>
  

     </Col>
     <div className="buttondiv">
     <Button  onClick={submit} className="submitbutton">
     Next
     </Button>
     </div>
       </Row>
      
       </Col>
       {
        loadedImage && <ImageCrop image={loadedImage} onSubmitClick={croppedImage =>{
            setCroppedImage(croppedImage);
            setLoadedImage(null);
        }}/>
    }
    <input
        ref={inputOpenFileRef} 
        type="file"
        style={{display:"none"}}
        onChange={loadImage}
/>
 </div> 
*/}
      
       
       </div>
    );
}

GroupDetails.propTypes = {
    imageSrc: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    submitButtonText: PropTypes.string,
    onSubmitClick: PropTypes.func.isRequired,
}

