const Nationality = [
    {
        "label": "Afghanistan"
    },
    {
        "label": "Albania"
    },
    {
        "label": "Algeria"
    },
    {
        "label": "American Samoa"
    },
    {
        "label": "Andorra"
    },
    {
        "label": "Angola"
    },
    {
        "label": "Anguilla"
    },
    {
        "label": "Antarctica"
    },
    {
        "label": "Antigua and Barbuda"
    },
    {
        "label": "Argentina"
    },
    {
        "label": "Armenia"
    },
    {
        "label": "Aruba"
    },
    {
        "label": "Australia"
    },
    {
        "label": "Austria"
    },
    {
        "label": "Azerbaijan"
    },
    {
        "label": "Bahamas"
    },
    {
        "label": "Bahrain"
    },
    {
        "label": "Bangladesh"
    },
    {
        "label": "Barbados"
    },
    {
        "label": "Belarus"
    },
    {
        "label": "Belgium"
    },
    {
        "label": "Belize"
    },
    {
        "label": "Benin"
    },
    {
        "label": "Bermuda"
    },
    {
        "label": "Bhutan"
    },
    {
        "label": "Bolivia"
    },
    {
        "label": "Bosnia and Herzegovina"
    },
    {
        "label": "Botswana"
    },
    {
        "label": "Bouvet Island"
    },
    {
        "label": "Brazil"
    },
    {
        "label": "British Indian Ocean Territory"
    },
    {
        "label": "Brunei"
    },
    {
        "label": "Bulgaria"
    },
    {
        "label": "Burkina Faso"
    },
    {
        "label": "Burundi"
    },
    {
        "label": "Cambodia"
    },
    {
        "label": "Cameroon"
    },
    {
        "label": "Canada"
    },
    {
        "label": "Cape Verde"
    },
    {
        "label": "Cayman Islands"
    },
    {
        "label": "Central African Republic"
    },
    {
        "label": "Chad"
    },
    {
        "label": "Chile"
    },
    {
        "label": "China"
    },
    {
        "label": "Christmas Island"
    },
    {
        "label": "Cocos (Keeling) Islands"
    },
    {
        "label": "Colombia"
    },
    {
        "label": "Comoros"
    },
    {
        "label": "Congo"
    },
    {
        "label": "The Democratic Republic of Congo"
    },
    {
        "label": "Cook Islands"
    },
    {
        "label": "Costa Rica"
    },
    {
        "label": "Ivory Coast"
    },
    {
        "label": "Croatia"
    },
    {
        "label": "Cuba"
    },
    {
        "label": "Cyprus"
    },
    {
        "label": "Czech Republic"
    },
    {
        "label": "Denmark"
    },
    {
        "label": "Djibouti"
    },
    {
        "label": "Dominica"
    },
    {
        "label": "Dominican Republic"
    },
    {
        "label": "East Timor"
    },
    {
        "label": "Ecuador"
    },
    {
        "label": "Egypt"
    },
    {
        "label": "England"
    },
    {
        "label": "El Salvador"
    },
    {
        "label": "Equatorial Guinea"
    },
    {
        "label": "Eritrea"
    },
    {
        "label": "Estonia"
    },
    {
        "label": "Ethiopia"
    },
    {
        "label": "Falkland Islands"
    },
    {
        "label": "Faroe Islands"
    },
    {
        "label": "Fiji Islands"
    },
    {
        "label": "Finland"
    },
    {
        "label": "France"
    },
    {
        "label": "French Guiana"
    },
    {
        "label": "French Polynesia"
    },
    {
        "label": "French Southern territories"
    },
    {
        "label": "Gabon"
    },
    {
        "label": "Gambia"
    },
    {
        "label": "Georgia"
    },
    {
        "label": "Germany"
    },
    {
        "label": "Ghana"
    },
    {
        "label": "Gibraltar"
    },
    {
        "label": "Greece"
    },
    {
        "label": "Greenland"
    },
    {
        "label": "Grenada"
    },
    {
        "label": "Guadeloupe"
    },
    {
        "label": "Guam"
    },
    {
        "label": "Guatemala"
    },
    {
        "label": "Guernsey"
    },
    {
        "label": "Guinea"
    },
    {
        "label": "Guinea-Bissau"
    },
    {
        "label": "Guyana"
    },
    {
        "label": "Haiti"
    },
    {
        "label": "Heard Island and McDonald Islands"
    },
    {
        "label": "Holy See (Vatican City State)"
    },
    {
        "label": "Honduras"
    },
    {
        "label": "Hong Kong"
    },
    {
        "label": "Hungary"
    },
    {
        "label": "Iceland"
    },
    {
        "label": "India"
    },
    {
        "label": "Indonesia"
    },
    {
        "label": "Iran"
    },
    {
        "label": "Iraq"
    },
    {
        "label": "Ireland"
    },
    {
        "label": "Israel"
    },
    {
        "label": "Isle of Man"
    },
    {
        "label": "Italy"
    },
    {
        "label": "Jamaica"
    },
    {
        "label": "Japan"
    },
    {
        "label": "Jersey"
    },
    {
        "label": "Jordan"
    },
    {
        "label": "Kazakhstan"
    },
    {
        "label": "Kenya"
    },
    {
        "label": "Kiribati"
    },
    {
        "label": "Kuwait"
    },
    {
        "label": "Kyrgyzstan"
    },
    {
        "label": "Laos"
    },
    {
        "label": "Latvia"
    },
    {
        "label": "Lebanon"
    },
    {
        "label": "Lesotho"
    },
    {
        "label": "Liberia"
    },
    {
        "label": "Libyan Arab Jamahiriya"
    },
    {
        "label": "Liechtenstein"
    },
    {
        "label": "Lithuania"
    },
    {
        "label": "Luxembourg"
    },
    {
        "label": "Macao"
    },
    {
        "label": "North Macedonia"
    },
    {
        "label": "Madagascar"
    },
    {
        "label": "Malawi"
    },
    {
        "label": "Malaysia"
    },
    {
        "label": "Maldives"
    },
    {
        "label": "Mali"
    },
    {
        "label": "Malta"
    },
    {
        "label": "Marshall Islands"
    },
    {
        "label": "Martinique"
    },
    {
        "label": "Mauritania"
    },
    {
        "label": "Mauritius"
    },
    {
        "label": "Mayotte"
    },
    {
        "label": "Mexico"
    },
    {
        "label": "Micronesia, Federated States of"
    },
    {
        "label": "Moldova"
    },
    {
        "label": "Monaco"
    },
    {
        "label": "Mongolia"
    },
    {
        "label": "Montserrat"
    },
    {
        "label": "Montenegro"
    },
    {
        "label": "Morocco"
    },
    {
        "label": "Mozambique"
    },
    {
        "label": "Myanmar"
    },
    {
        "label": "Namibia"
    },
    {
        "label": "Nauru"
    },
    {
        "label": "Nepal"
    },
    {
        "label": "Netherlands"
    },
    {
        "label": "Netherlands Antilles"
    },
    {
        "label": "New Caledonia"
    },
    {
        "label": "New Zealand"
    },
    {
        "label": "Nicaragua"
    },
    {
        "label": "Niger"
    },
    {
        "label": "Nigeria"
    },
    {
        "label": "Niue"
    },
    {
        "label": "Norfolk Island"
    },
    {
        "label": "North Korea"
    },
    {
        "label": "Northern Ireland"
    },
    {
        "label": "Northern Mariana Islands"
    },
    {
        "label": "Norway"
    },
    {
        "label": "Oman"
    },
    {
        "label": "Pakistan"
    },
    {
        "label": "Palau"
    },
    {
        "label": "Palestine"
    },
    {
        "label": "Panama"
    },
    {
        "label": "Papua New Guinea"
    },
    {
        "label": "Paraguay"
    },
    {
        "label": "Peru"
    },
    {
        "label": "Philippines"
    },
    {
        "label": "Pitcairn"
    },
    {
        "label": "Poland"
    },
    {
        "label": "Portugal"
    },
    {
        "label": "Puerto Rico"
    },
    {
        "label": "Qatar"
    },
    {
        "label": "Reunion"
    },
    {
        "label": "Romania"
    },
    {
        "label": "Russian Federation"
    },
    {
        "label": "Rwanda"
    },
    {
        "label": "Saint Helena"
    },
    {
        "label": "Saint Kitts and Nevis"
    },
    {
        "label": "Saint Lucia"
    },
    {
        "label": "Saint Pierre and Miquelon"
    },
    {
        "label": "Saint Vincent and the Grenadines"
    },
    {
        "label": "Samoa"
    },
    {
        "label": "San Marino"
    },
    {
        "label": "Sao Tome and Principe"
    },
    {
        "label": "Saudi Arabia"
    },
    {
        "label": "Scotland"
    },
    {
        "label": "Senegal"
    },
    {
        "label": "Serbia"
    },
    {
        "label": "Seychelles"
    },
    {
        "label": "Sierra Leone"
    },
    {
        "label": "Singapore"
    },
    {
        "label": "Slovakia"
    },
    {
        "label": "Slovenia"
    },
    {
        "label": "Solomon Islands"
    },
    {
        "label": "Somalia"
    },
    {
        "label": "South Africa"
    },
    {
        "label": "South Georgia and the South Sandwich Islands"
    },
    {
        "label": "South Korea"
    },
    {
        "label": "South Sudan"
    },
    {
        "label": "Spain"
    },
    {
        "label": "SriLanka"
    },
    {
        "label": "Sudan"
    },
    {
        "label": "Suriname"
    },
    {
        "label": "Svalbard and Jan Mayen"
    },
    {
        "label": "Swaziland"
    },
    {
        "label": "Sweden"
    },
    {
        "label": "Switzerland"
    },
    {
        "label": "Syria"
    },
    {
        "label": "Tajikistan"
    },
    {
        "label": "Tanzania"
    },
    {
        "label": "Thailand"
    },
    {
        "label": "Timor-Leste"
    },
    {
        "label": "Togo"
    },
    {
        "label": "Tokelau"
    },
    {
        "label": "Tonga"
    },
    {
        "label": "Trinidad and Tobago"
    },
    {
        "label": "Tunisia"
    },
    {
        "label": "Turkey"
    },
    {
        "label": "Turkmenistan"
    },
    {
        "label": "Turks and Caicos Islands"
    },
    {
        "label": "Tuvalu"
    },
    {
        "label": "Uganda"
    },
    {
        "label": "Ukraine"
    },
    {
        "label": "United Arab Emirates"
    },
    {
        "label": "United Kingdom"
    },
    {
        "label": "United States"
    },
    {
        "label": "United States Minor Outlying Islands"
    },
    {
        "label": "Uruguay"
    },
    {
        "label": "Uzbekistan"
    },
    {
        "label": "Vanuatu"
    },
    {
        "label": "Venezuela"
    },
    {
        "label": "Vietnam"
    },
    {
        "label": "Virgin Islands, British"
    },
    {
        "label": "Virgin Islands, U.S."
    },
    {
        "label": "Wales"
    },
    {
        "label": "Wallis and Futuna"
    },
    {
        "label": "Western Sahara"
    },
    {
        "label": "Yemen"
    },
    {
        "label": "Yugoslavia"
    },
    {
        "label": "Zambia"
    },
    {
        "label": "Zimbabwe"
    }
]
    export default Nationality