import React, {Component} from 'react';
import {Button, Input} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import './userProfile.css';
import union from '../../../images/profile/union.png';
import userimg from '../../../images/profile/userimg.png';

class UserProfile extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
     isEdit:false
    };
  }
  openChangePass = () => {
    this.setState ({isEdit: !this.state.isEdit});
  };
  openPassSaved = () => {
    this.setState ({changePass: false, passSaved: true});
  };
  openEditPro = () => {
    this.setState ({isEdit: !this.state.isEdit});
  }
  render () {
    const {isEdit} = this.state;
    return (

     <div>
     {!isEdit &&
       <div style={{padding: 20, width: 470}}>
         <div className="accosetsidi">
           Account Settings
         </div>
         <div className="acuntsidero">
           <div>
             <img alt="" src={userimg} className="settinproim" />
           </div>
           <div>
             <button className="editprobutset" onClick={this.openEditPro}>
               Edit Profile
             </button>
           </div>
         </div>
         <div className="detailsdivset">
           <div style={{display: 'flex'}}>
             <div>
               <div className="titleserf">Title</div>
               <div className="setdetdr">Dr.</div>
             </div>
             <div>
               <div className="setfirstname">First Name</div>
               <div className="setjohndoe">John Doe</div>
             </div>
             <div>
               <div className="lastsetk">Last Name</div>
               <div className="sefrvrdcvfvb">Albert</div>
             </div>
           </div>
           <div className="detailsdividser" />
           <div>
             <div className="adddreseserf">Address</div>
             <div className="setaccdresdkm">
               674 Valley View Drive, 02114,
               Boston, Massachusetts, US State.
             </div>
           </div>
           <div className="detailsdividser" />
           <div>
             <div className="adddreseserf">Registered Email</div>
             <div className="setaccdresdkm">john.doe@gmail.com</div>
           </div>
           <div className="detailsdividser" />
           <div>
             <div className="adddreseserf">Registered Phone Number</div>
             <div className="setaccdresdkm">+60 12 3456789</div>
           </div>
           <div className="detailsdividser" />
           <div>
             <div className="adddreseserf">About Me</div>
             <div className="setaccdresdkm">
               I specialize in the care and
               surgical procedure for critically ill and injured
               neurological disorder patients.
             </div>
           </div>
           <div className="detailsdividser" />
           <div>
             <div className="adddreseserf">Services Offered</div>
             <div className="setaccdresdkm">
               Neurological Surgery, Treatment in CNS
               disorders and diseases, Neurological Consulting.
             </div>
           </div>
         </div>

       </div>}
     {isEdit &&
       <div style={{padding: 20}}>
         <div style={{display: 'flex'}}>
           <img alt="" src={union} style={{width: 25}}   onClick={this.openEditPro} className="handpointer"/>
           <div className="editaccsett"> Edit Account </div>
         </div>
         <div style={{display: 'flex', alignItems: 'center', marginTop: 60}}>
           <div>
             <img alt="" src={userimg} className="editproimg" />
           </div>
           <div>
             <div className="seteditjncd">Display Profle Picture*</div>
             <div className="editproncsdjk">
               Please upload a photo of type JPEG or PNG
             </div>
             <div><button className="uploadsetscr">Upload</button></div>
           </div>
         </div>
         <div style={{display: 'flex', marginTop: 24}}>
           <div>
             <input placeholder="Dr" className="edirproseim" />
           </div>
           <div>
             <input placeholder="John Doe" className="editnameset" />
           </div>
           <div>
             <input placeholder="Albert" className="edilastymeset" />
           </div>
         </div>
         <div style={{display: 'flex'}}>
           <div>
             <input
               placeholder="674 Valley View Drive"
               className="edirproseim"
             />
           </div>
           <div>
             <input placeholder="-" className="editnameset" />
           </div>
           <div>
             <input placeholder="United States" className="edilastymeset" />
           </div>
         </div>
         <div style={{display: 'flex'}}>
         <div>
           <input
             placeholder="Boston"
             className="editbostonpro"
           />
         </div>
         <div>
           <input placeholder="Massachusetts" className="editmassatonpro" />
         </div>
         <div>
           <input placeholder="02114" className="editnumatonpro" />
         </div>
       </div>
       <div style={{display: 'flex'}}>
       <div>
         <input
           placeholder="john.doe@gmail.com"
           className="editbostonpro"
         />
       </div>
       <div>
         <input placeholder="+ 60 12 3456789" className="ediatcountronpro" />
       </div>
      </div>
      <div className="aseboutmeset">About Me*</div>
      <div>
      <input 
      placeholder="I specialize in the care and 
      surgical procedure for critically ill and 
      injured neurological disorder patients." 
      className="aboutsetinm" />
      </div>
      <div className="aseboutmeset">Services Offered*</div>
      <div>
      <input 
      placeholder="Neurological Surgery, 
      Treatment in CNS disorders and diseases, Neurological Consulting." 
      className="aboutsetinm" />
      </div>
      <div style={{textAlign:"center"}}>
      <Button className="setedbubbut" onClick={this.openEditPro}>
      Submit
      </Button>
      </div>
       </div>}
     </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    // service: state.serviceOnDemandReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators ({}, dispatch);
};

export default connect (mapStateToProps, mapDispatchToProps) (UserProfile);
