import React, {Component} from 'react';
import moment from 'moment';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress
} from 'reactstrap';
import ServiceOnDemandProfile
  from '../../../service-On-Demand-Profile/serviceOnDemandProfile';
import ServiceOnDemandSidebarPage
  from '../../../../components/serviceOnDemandSidebar/serviceOnDemandSidebar';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import './requestRecieved.css';
import * as routes from '../../../../constants/routes';
import bluearrow from '../../../../images/bluearrow.png';
import greylist from '../../../../images/greylist.png';
import gridview from '../../../../images/serviceOnDemandMyRequest/gridview.png';
import bluelistview from '../../../../images/bluelist.png';
import bluegridview from '../../../../images/serviceOnDemandMyRequest/bluegridview.png';

import nameframe
  from '../../../../images/serviceOnDemandMyRequest/nameframe.png';
import watch from '../../../../images/serviceOnDemandMyRequest/watch.png';
import yellowdot
  from '../../../../images/serviceOnDemandMyRequest/yellowdot.png';
import greendot from '../../../../images/serviceOnDemandMyRequest/greendot.png';
import greyframe
  from '../../../../images/serviceOnDemandMyRequest/greyframe.png';
import greywatch
  from '../../../../images/serviceOnDemandMyRequest/greywatch.png';
import greygear from '../../../../images/serviceOnDemandMyRequest/greygear.png';
import settingCard1 from '../../../../images/serviceOnDemand/settingCard1.png';
import ServiceOnDemandCommonComponent from '../../../Service-On-Demand-CommanComponent/Service-On-Demand-CommanComponent';


import {
  GetMyRequestsRecieved,
} from '../../../../actions/serviceOnDemandAction';

class serviceOnDemandRequestRecievedPage extends Component {
  constructor (props) {
    super (props);
    this.state = {
      showListView: true,
      activeFilter: '',
    };
    this.showListView=this.showListView.bind(this);
  }
  showListView = (status) => {
    this.setState ({showListView: status});
  };
 
  async componentDidMount () {
    let loginUser = await localStorage.getItem ('loginuser');
    if (loginUser) {
      
      let getuserData = JSON.parse (loginUser);
      this.getAllReceivedRequest('');
  }
  }

  getAllReceivedRequest(status){
    let sendRequest = {
      data: {
        // docID: getuserData.docID,
        docID: '',
        status: status,
      },
    };
  
    this.props.GetMyRequestsRecieved (sendRequest);
  }
  componentWillReceiveProps (nextProps) {
  
    if (nextProps) {
      if (nextProps.service) {
       

        // this.setState({
        //   myRequestsRecievedList: nextProps.service.myserviceRequest
        // })
        this.setState ({
          myRequestsRecievedList: nextProps.service.myRequestRecieved,
        });
      }
    }
  }
  selectedFilter (item) {
    this.setState ({
      activeFilter: item,
    });
    if(item!=='Pending' && item!=='Completed'){
    this.getAllReceivedRequest(item);

    }
  }
  render () {
    const {myRequestsRecievedList, activeFilter} = this.state;
    return (
      <div
        style={{
          display: 'flex',
          background: '#FFFFFF',
          height: '100%',
        }}
      >
        <div style={{padding: 15}} className="requestReceiveContainer">
          <div style={{width:230}}>
            <ServiceOnDemandCommonComponent/>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 10,
            }}
          >
            <div>
              <div className="DASHBOARDPROVIDER">
                SERVICE PROVIDER DASHBOARD
              </div>
              <div className="Receivedxzscfg">Requests Received</div>
            </div>
            <div
              className="levbnm19"
              onClick={() =>
                this.props.history.push (
                  routes.SERVICEONDEMAND_CREATE_SERVICE_PROFILE
                )}
            >
              <div>
                <div className="TOMYPROFILE">
                  GO TO MY PROFILE
                </div>
                <div className="ddAervice">
                  Add A Service
                </div>
              </div>
              <div className="Rectangdxzle">
                <img alt="" src={bluearrow} />
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 19,
            }}
          >
            <div
              className={activeFilter === '' ? 'alzz' : 'cPendingsa'}
              onClick={() => this.selectedFilter ('')}
            >
              All
            </div>
            <div
              className={activeFilter === 'Pending' ? 'alzz' : 'cPendingsa'}
              onClick={() => this.selectedFilter ('Pending')}
            >
              Pending
            </div>
            <div
              className={activeFilter === 'accepted' ? 'alzz' : 'cPendingsa'}
              onClick={() => this.selectedFilter ('accepted')}
            >
              Awarded
            </div>
            <div
              className={activeFilter === 'Confirmed' ? 'alzz' : 'cPendingsa'}
              onClick={() => this.selectedFilter ('Confirmed')}
            >
              Confirmed
            </div>
            <div
              className={activeFilter === 'paid' ? 'alzz' : 'cPendingsa'}
              onClick={() => this.selectedFilter ('paid')}
            >
              Started
            </div>
            <div
              className={activeFilter === 'job completed' ? 'alzz' : 'cPendingsa'}
              onClick={() => this.selectedFilter ('job completed')}
            >
              Job Complete
            </div>
            <div
              className={activeFilter === 'Completed' ? 'alzz' : 'cPendingsa'}
              onClick={() => this.selectedFilter ('Completed')}
            >
              Completed
            </div>
            
            <div style={{display: 'flex'}}>
              <div onClick={()=>this.showListView(true)}>
                <img className="handpointer" alt=""  src={this.state.showListView? bluegridview: gridview} style={{paddingRight: 15,width:42}} />
              </div>
              <div onClick={()=>this.showListView(false)}>
                <img className="handpointer"  alt="" src={!this.state.showListView? bluelistview: greylist} />
              </div>
            </div>
          </div>

          {myRequestsRecievedList &&
            myRequestsRecievedList.length === 0 &&
            <div className="noServiceDataFound ">
              No Data Found
            </div>}
          {this.state.showListView &&
            <div style={{display: 'flex', marginTop: 21}}>
              {/*<div
                className="csxaergg"
                onClick={() =>
                  this.props.history.push (
                    routes.SERVICEONDEMAND_RECIEVED_REQUEST_DETAIL
                  )}
              >
                <div className="Szscxession">
                  GE Servicing Session
                </div>
                <div className="Rxzaequest">
                  Request: #137512
                </div>
                <div className="orenda">
                  <img alt="" src={nameframe} />
                  <div className="paddingl">Tanya Lorenda</div>
                </div>
                <div className="xttimee">
                  <img alt="" src={watch} />
                  <div className="paddingl">11/4/2019</div>
                </div>
                <div className="qAwarded">
                  <img alt="" src={settingCard1} style={{width: 20}} />
                  <div className="paddingl">
                    <img alt="" src={yellowdot} />
                    Awarded
                  </div>
                </div>
                <div className="xload" />
                  </div>*/}

              {myRequestsRecievedList &&
                myRequestsRecievedList.length > 0 &&
                myRequestsRecievedList.map ((item, index) => (
                  <div
                    className="mrexzzz"
                    key={index}
                    onClick={() =>
                      this.props.history.push (
                        routes.SERVICEONDEMAND_RECIEVED_REQUEST_DETAIL +
                          `/${item.docID}`
                      )}
                  >
                    <div className="xServicing">
                      {item.title}
                    </div>
                    <div className="qweRequest">
                      Request: #{item.request_id}
                    </div>
                    <div className="Lilyordan">
                      <img alt="" src={greyframe} />
                      <div className="paddingl">
                      {item.requestorTitle? item.requestorTitle: ''}
                      {' '}

                      {(item && item.requestorDisplayName ) ? item.requestorDisplayName  :  (item && item.requestorFirstName ? item.requestorFirstName:  '') + ' '+  (item && item.requestorLastName  ? item.requestorLastName:  '')}
                     
                      </div>
                    </div>
                    <div className="etimbc">
                      <img alt="" src={greywatch} />
                      <div className="paddingl">
                      {item && item.serviceDate
                        ? moment (item.serviceDate).format('DD/MM/YYYY' )
                        : ''}
                      </div>
                    </div>
                    <div className="rConfirmed">
                      <img alt="" src={greygear} style={{width: 20}} />
                      <div
                        className="paddingl"
                        style={{textTransform: 'capitalize'}}
                      >
                      {item.status === "draft"&&  <div className="greencircle"/>}
                      {(item.status === "awarded" || item.status === "accepted") &&  <div className="yellowcircle"/>}
                      {(item.status === "job completed" || item.status === "rejected")&&  <div className="orangecircle"/>}
                      {item.status === "pending"&&  <div className="whitecircle"/>}
                      {item.status === "confirmed"&&  <div className="lightgreen"/>}
                        {item.status}
                      </div>
                    </div>
                   
                    <Progress
                      value={
                        item && item.progressBar
                          ? item.progressBar
                          : ''
                      }
                      className="loadblue"
                    />
                  </div>
                ))}
            </div>}

          {!this.state.showListView&&
            <div>
              {/*<div
                className="xercvbd"
                onClick={() =>
                  this.props.history.push (
                    routes.SERVICEONDEMAND_RECIEVED_REQUEST_DETAIL
                  )}
              >
                <div>
                  <div className="xzRequest"> Request: #137512</div>
                  <div className="aSessionwe"> GE Servicing Session </div>
                </div>
                <div className="orenda">
                  <img alt="" src={nameframe} />
                  <div className="paddingl">Tanya Lorenda</div>
                </div>
                <div className="xttimee">
                  <img alt="" src={watch} />
                  <div className="paddingl">11/4/2019</div>
                </div>
                <div>
                  <div className="qAwarded">
                    <img alt="" src={settingCard1} style={{width: 20}} />
                    <div className="paddingl">
                      <img alt="" src={yellowdot} />
                      Awarded
                    </div>

                  </div>
                  <div className="whitload" />
                </div>
                  </div>*/}

              {myRequestsRecievedList &&
                myRequestsRecievedList.length > 0 &&
                myRequestsRecievedList.map ((item, index) => (
                  <div
                    key={index}
                    className="blackgss"
                    onClick={() =>
                      this.props.history.push (
                        routes.SERVICEONDEMAND_RECIEVED_REQUEST_DETAIL +
                          `/${item.docID}`
                      )}
                  >
                    <div>
                      <div className="redxa">Request: #{item.request_id}</div>
                      <div className="zxSiemens"> {item.title} </div>
                    </div>
                    <div className="Lilyordan">
                      <img alt="" src={greyframe} />
                      <div className="paddingl">
                        {item.requestorTitle? item.requestorTitle: ''}
                        {' '}

                        {(item && item.requestorDisplayName ) ? item.requestorDisplayName  :  (item && item.requestorFirstName ? item.requestorFirstName:  '') + ' '+  (item && item.requestorLastName  ? item.requestorLastName:  '')}
                       
                      </div>
                    </div>
                    <div className="etimbc">
                      <img alt="" src={greywatch} />
                      <div className="paddingl">{item && item.serviceDate
                        ? moment (item.serviceDate).format('DD/MM/YYYY' )
                        : ''}</div>
                    </div>
                    <div>
                      <div className="qwConfirmed">
                        <img alt="" src={greygear} style={{width: 20}} />
                        <div
                          className="paddingl"
                          style={{textTransform: 'capitalize'}}
                        >
                       {item.status === "draft"&&  <div className="greencircle"/>}
                       {(item.status === "awarded" || item.status === "accepted") &&  <div className="yellowcircle"/>}
                       {(item.status === "job completed" || item.status === "rejected")&&  <div className="orangecircle"/>}
                       {item.status === "pending"&&  <div className="whitecircle"/>}
                       {item.status === "confirmed"&&  <div className="lightgreen"/>}
                      
                          {item.status}
                        </div>
                      </div>
                      <div className="whitload" />
                    </div>
                  </div>
                ))}
            </div>}
        </div>
        <ServiceOnDemandSidebarPage history={this.props.history} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    service: state.serviceOnDemandReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      GetMyRequestsRecieved,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  serviceOnDemandRequestRecievedPage
);
