import React, {Component} from 'react';

import ServiceOnDemandProfilePage
  from '../service-On-Demand-Profile/serviceOnDemandProfile';
import ServiceOnDemandCommonComponent
  from '../Service-On-Demand-CommanComponent/Service-On-Demand-CommanComponent';
import {GetServiceProviders} from '../../actions/serviceOnDemandAction';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import './serviceOnDemandSearchPage.css';
import SODimg1 from '../../images/serviceOnDemandSearch/Frame.png';
import profilePlaceholder from '../../images/profile_placeholder.png';

import gear from '../../images/serviceOnDemandSearch/gear.png';
import FrameImage from '../../images/serviceOnDemand/Frame.png';
import * as routes from '../../constants/routes';
import StarRatingComponent from 'react-star-rating-component';
class serviceOnDemandSearchPage extends Component {
  constructor (props) {
    super (props);
    this.state = {
      serviceProviders: [],
      searchBy: [],
      rating: 5,
    };
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if (
        nextProps &&
        nextProps.GetServiceProvider &&
        nextProps.GetServiceProvider.searchServiceProvider
      ) {
        this.setState (
          {
            serviceProviders: nextProps.GetServiceProvider
              .searchServiceProvider,
            searchBy: nextProps.GetServiceProvider.searchProviderFilter,
          },
          () => {
           
          }
        );
      }
    }
  }
  componentDidMount () {
    if (this.props) {
      if (
        this.props &&
        this.props.GetServiceProvider &&
        this.props.GetServiceProvider.searchServiceProvider
      ) {
        this.setState (
          {
            serviceProviders: this.props.GetServiceProvider
              .searchServiceProvider,
            searchBy: this.props.GetServiceProvider.searchProviderFilter,
          },
          () => {
          
          }
        );
      }
    }
  }
  onStarClick = (nextValue, prevValue, name) => {
    this.setState ({rating: nextValue});
  };
  render () {
    const {searchBy} = this.state;
    return (
      <div style={{display: 'flex', height: '100%', background: '#FFFFFF'}}>
        <div className="serviceOnDemandSearchContainer">
          <div style={{width: 230}}>
            <ServiceOnDemandCommonComponent />
          </div>
          <div>
            <div style={{display: 'flex'}}>
              <div className="yousearch">
                YOU SEARCHED FOR
              </div>
              <div className="BACKTOSEARCH">
                <img alt="img" src={SODimg1} className="SODIMG1" />
                <span style={{paddingLeft: 11}}> BACK TO SEARCH</span>
              </div>

            </div>
            <div>
              <p className="Mar">
                {' '}
                {searchBy && searchBy.data && searchBy.data.searchString
                  ? searchBy.data.searchString
                  : ''}
                {' '}
              </p>
              <p className="resultsfor">
                {this.state.serviceProviders.length}
                {' '}
                results for
                {' '}
                {searchBy && searchBy.data && searchBy.data.searchString
                  ? searchBy.data.searchString
                  : ''}
              </p>
             
            </div>
            <div className="BoxouterDiv">
              {/* {this.state.serviceProviders && this.state.serviceProviders.length > 0 && 
            this.state.serviceProviders.map((item,index)=>(
            <div>  <div className="Rectangle21">
              <div style={{display: 'inline-flex'}}>
                <div key={index}>
                  <div className="JulieMartha"> 
                  {(item && item.providerDisplayName ) ? item.providerDisplayName :  
                    ((item && item.providerFirstName ) ? item.providerFirstName:  '' + ' '+  
                    (item && item.providerLastName ) ? item.providerLastName:  '')}
                    </div>
                  <div className="California"> {item && item.serviceArea ? item.serviceArea:"" } </div>
                </div>
                <div>
                  <img alt="" src={ item && item.providerProfilePictureURL ? item.providerProfilePictureURL:"" } className="roman" />
                </div>
              </div>
              <div className="Average">Average Rate:</div>
              <div className="perhour">${item && item.avgRate ? item.avgRate:""} per hour</div>
              <div
                style={{
                  display: 'inline-flex',
                  justifyContent: 'space-between',
                }}
              >
                <div>
                  <div className="Rating">Rating:</div>
                  <div className="number47"> 4.7</div>
                </div>
                <div>
                  <img alt="" src={star} style={{marginLeft: 90}} />
                </div>
              </div>
              <div style={{textAlign: 'center'}}>
                <img alt="" src={gear} />
                <span className="Services"> {item && item.totalServicesOffered ? item.totalServicesOffered:""} Services Provided</span>
              </div>
              </div></div>))}*/}

              {this.state.serviceProviders &&
                this.state.serviceProviders.length > 0 &&
                this.state.serviceProviders.map ((item, index) => (
                  <div
                    key={index}
                    onClick={() =>
                      this.props.history.push (
                        routes.SERVICEONDEMANDPROVIDERPROFILE + `/${item.docID}`
                      )}
                  >
                    {' '}<div className="Rectangle22">
                      <div style={{display: 'flex',justifyContent:'space-between'}}>
                        <div>
                          <div className="Martin">
                            {' '}
                            {item && item && item.providerDisplayName
                              ? item.providerDisplayName
                              : (item && item && item.providerFirstName
                                  ? item.providerFirstName
                                  : '') +
                                  ' ' +
                                  (item && item && item.providerLastName
                                    ? item.providerLastName
                                    : '')}
                          </div>
                          <div className="Angeles">
                            {' '}{' '}
                            {item && item && item.serviceArea
                              ? item.serviceArea
                              : ''}
                            {' '}
                          </div>
                        </div>
                        <div>
                          <img
                            alt=""
                            src={
                              item && item && item.providerProfilePictureURL
                                ? item.providerProfilePictureURL
                                : profilePlaceholder
                            }
                            className="roman"
                          />
                        </div>
                      </div>
                      <div className="Average2">Average Rate:</div>
                      <div className="per">
                        {item && item.avgRate
                          ? '$ ' + item.avgRate + ' per hour'
                          : 'N/A'}
                      </div>
                      <div style={{display: 'inline-flex'}}>
                        <div>
                          <div className="Rating2">Rating:</div>
                          <div className="number48"> 4.7</div>
                        </div>
                        <div>
                          <StarRatingComponent
                            name={'rating'}
                            starCount={5}
                            value={
                              item &&
                                item.serviceData &&
                                item.serviceData.siemens_servicing_total_score
                                ? item.serviceData.siemens_servicing_total_score
                                : 0
                            }
                            emptyStarColor={'#E4E4E4'}
                            starColor={'#F5D028'}
                            style={{fontSize: '80px'}}
                            // onStarClick={this.onStarClick}
                          />
                        </div>
                      </div>
                      <div style={{textAlign: 'center'}}>
                        <img alt="" src={gear} />
                        <span className="Services2">
                          {' '}
                          {item && item.totalServicesOffered
                            ? item.totalServicesOffered
                            : 0}
                          {' '}
                          Services Provided
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>

          </div>
         
        </div>
        <div>
        <img alt="" src={FrameImage} className="FrameImgIcon" />
      </div>

        <div>

          <ServiceOnDemandProfilePage
            history={this.props.history}
            myplacerequest={true}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {

  return {
    GetServiceProvider: state.serviceOnDemandReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      GetServiceProviders,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  serviceOnDemandSearchPage
);
