import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Button, Progress } from 'reactstrap'
import './businessRegister.css'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as routes from '../../constants/routes'
import whiteSearch from '../../images/icons/whiteSearch.png'
import previewFile from '../../images/icons/previewFile.png'

import {
  getFindBusiness,
  register,
  getProfileDashboard,
  ModifyPersonalInfo,
  resetUserReducer,
  sendBusinessInvite
} from '../../actions/Authaction'
import { createNotification } from '../../constants/notificationtoast'
import leftbackarrow from '../../images/icons/leftbackarrow.png'
import businessTypes from '../../constants/localJSON/businessTypes.json'
import { CapitalizeFirstLetter } from '../../constants/helper'
import SelectInput from '../InputCustom/selectInput/selectDropdown'
import { yearListing } from '../../constants/localJSON/yearDropdown'

class BusinessRegister extends Component {
  // defining state
  constructor (props) {
    super(props)
    this.state = {
      step: 1,
      searchString: '',
      registeredBusinessName: '',
      businessWebsiteUrl: '',
      businessEmailAddress: '',
      businessPhoneNumber: '',
      yearEstablished: '',
      isSecondSubmit: '',
      invalidEmailFormat: '',
      displayedBusinessName: '',
      businessType: '',
      businessSize: '',
      establishedYearDropdown: yearListing(),
      businessSizeoptions: [
        {
          label: '1',
          value: '1'
        },
        {
          label: '2-5',
          value: '2-5'
        },
        {
          label: '5-20',
          value: '5-20'
        },
        {
          label: '20-100',
          value: '20-100'
        },
        {
          label: '100-500',
          value: '100-500'
        },
        {
          label: '500+',
          value: '500+'
        }
      ],
      businessTypeoptions: businessTypes,
      acceptedTermsConditions: false,

      // businessData:[],
      businessID: '',
      profileCompletion: 0,
      searchByName: false,
      businessFileDocname: '',
      panFileDocname: '',
      businessAddress_1: '',
      businessAddress_2: '',
      businessCountry: '',
      businessCity: '',
      businessState: '',
      businessPostalCode: '',
      businessRegistration: '',
      businessTax: '',
      businessRegistrationFileType: '',
      businessTaxFileType: ''
    }
    this.goBack = this.goBack.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    // this.props.history.push(routes.INFORMATIONSUBMIT)

    // if (nextProps && nextProps.userinfo) {
    //   if (
    //     nextProps.userinfo.registeruser &&
    //     nextProps.userinfo.registeruser.result
    //   ) {
    //     if (nextProps.userinfo.registeruser.result.status) {
    //       this.props.history.push (routes.INFORMATIONSUBMIT);
    //     }
    //   }
    // }
    console.log(nextProps)
    // console.log(this.state.step);

    if (nextProps.userinfo && nextProps.userinfo.dashboardprofile) {
      this.setState(
        {
          personalProfile: nextProps.userinfo.dashboardprofile.personalDetails,
          profileCompletion:
            nextProps.userinfo.dashboardprofile.profileCompletion
        },
        () => {
          if (this.state.profileCompletion.businessProfileSetup) {
            // alert("submit")
             this.props.history.push(routes.PROFILE_DASHBOARD);
          }
        }
      )
    }

    if (nextProps) {
      if (
        nextProps.userinfo.findBusiness &&
        nextProps.userinfo.findBusiness.result &&
        nextProps.userinfo.findBusiness.result.status
      ) {
        this.setState({
          businessData: nextProps.userinfo.findBusiness.result.Data
        })
      }

      if (nextProps && nextProps.userinfo) {
        if (
          nextProps.userinfo.registeruser &&
          nextProps.userinfo.registeruser.result
        ) {
          if (nextProps.userinfo.registeruser.result.status) {
            this.setState({
              step: 3,
              isSecondSubmit: false
            })
            if (
              nextProps.userinfo.registeruser.result &&
              nextProps.userinfo.registeruser.result.Data &&
              nextProps.userinfo.registeruser.result.Data[0] &&
              nextProps.userinfo.registeruser.result.Data[0].documentID
            ) {
              this.setState({
                businessID:
                  nextProps.userinfo.registeruser.result.Data[0].documentID
              })
              if (
                this.state.step === 3 &&
                this.state.acceptedTermsConditions &&
                this.state.businessType
              ) {
                this.props.history.push(routes.PROFILE_DASHBOARD)
              }
              if (this.state.step === 5) {
                this.props.history.push(routes.PROFILE_DASHBOARD)
              }
              // alert(nextProps.userinfo.registeruser.result.Data[0].documentID);
            }
            // this.props.history.push (routes.INFORMATIONSUBMIT);
          }
        } else {
          if (
            this.state.step === 5 &&
            nextProps.userinfo &&
            nextProps.userinfo.isSendbusinessInvite
          ) {
            this.props.history.push(routes.PROFILE_DASHBOARD)
          }
        }
      }
    }
  }
  componentDidMount () {
    this.props.resetUserReducer()
    let sendRequestUserProfile = {
      data: {}
    }
    this.props.getProfileDashboard(sendRequestUserProfile)
  }
  nextScreen = () => {
    this.setState({ step: this.state.step + 1 })
  }
  handleChangeSearch () {
    const { searchString } = this.state
    if (this.state.searchString && this.state.searchString.length > 2) {
      // let sendRequest = {
      //   data: {
      //     nameSearchString: this.state.searchString,
      //     idSearchString: false,
      //     limit: 50,
      //   },
      // };

      let sendRequest = {
        data: {
          nameSearchString: this.state.searchByName ? searchString : false,
          idSearchString: !this.state.searchByName ? searchString : false,
          limit: 50
        }
      }

      this.setState({
        isSearch: true
      })
      this.props.getFindBusiness(sendRequest)
    }
  }
  onSelect = item => {
    if (item) {
      this.setState({
        isSearch: false,
        selectedBusiness: item,
        searchString: item.registeredBusinessName
          ? item.registeredBusinessName
          : ''
      })
    }
  }

  onSubmitNext () {
    const {
      error,
      showingAlert,
      indivisual,
      businessSize,
      businessType,
      agree,
      displayedBusinessName,
      registeredBusinessName,
      businessWebsiteUrl,
      businessEmailAddress,
      businessPhoneNumber,
      yearEstablished,
      isFirstSubmit,
      countrycode,
      businessAddress_1,
      businessAddress_2,
      businessCountry,
      businessCity,
      businessState,
      businessPostalCode
    } = this.state

    const isvalid2 =
      displayedBusinessName === '' ||
      businessAddress_1 === '' ||
      registeredBusinessName === '' ||
      businessPostalCode === '' ||
      businessState === '' ||
      businessCity === '' ||
      businessCountry === '' ||
      // businessWebsiteUrl === '' ||
      businessEmailAddress === '' ||
      businessPhoneNumber === '' ||
      yearEstablished === ''

    this.setState({
      isSecondSubmit: true
    })

    if (businessEmailAddress) {
      const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g
      const result = pattern.test(businessEmailAddress)

      if (!result) {
        this.setState({
          invalidEmailFormat: true
        })
        return false
      } else {
        this.setState({
          invalidEmailFormat: false
        })
      }
    }

    if (isvalid2) {
      return false
    }

    let sendRequest = {
      data: {
        businessID: '',
        businessAddress_1: businessAddress_1 || '',
        businessAddress_2: businessAddress_2 || '',
        businessCity: businessCity || '',
        businessCountry: businessCountry || '',
        businessEmailAddress: this.state.businessEmailAddress
          ? this.state.businessEmailAddress
          : '',
        businessPhoneNumber: this.state.businessPhoneNumber,
        businessPostalCode: businessPostalCode || '',
        businessState: businessState || '',
        businessWebsiteUrl: this.state.businessWebsiteUrl
          ? this.state.businessWebsiteUrl
          : '',
        displayedBusinessName: this.state.displayedBusinessName,
        registeredBusinessName: this.state.registeredBusinessName,
        representativeFirstName: '',
        representativeLastName: '',
        yearEstablished: this.state.yearEstablished
      }
    }
    this.props.register(sendRequest)
  }

  submitBusinessAdditional () {
    const {
      businessSize,
      businessType,
      acceptedTermsConditions,
      businessRegistrationFileType,
      businessTaxFileType
    } = this.state

    const isvalid2 =
      businessType === '' ||
      // businessSize === '' ||
      // businessRegistrationFileType === '' ||
      // businessTaxFileType === '' ||
      acceptedTermsConditions === false

    this.setState({
      isSecondSubmit: true
    })

    if (isvalid2) {
      return false
    }

    let sendRequest = {
      data: {
        acceptedTermsConditions: this.state.acceptedTermsConditions,
        businessType: this.state.businessType ? this.state.businessType : '',
        businessSize: this.state.businessSize ? this.state.businessSize : '',
        businessRegistrationFileType: this.state.businessRegistrationFileType
          ? this.state.businessRegistrationFileType
          : '',
        businessRegistration: this.state.businessRegistration
          ? this.state.businessRegistration
          : '',
        businessTaxFileType: this.state.businessTaxFileType
          ? this.state.businessTaxFileType
          : '',
        businessTax: this.state.businessTax ? this.state.businessTax : '',
        docID: this.state.businessID ? this.state.businessID : ''
      }
    }
    // console.log(sendRequest.data)
    this.props.register(sendRequest)
  }

  onChangeRegistration = e => {
    console.warn('warning function call details')
    let files = e.target.files
    if (files) {
      let response = files[0].size / 1000000

      if (response && response > 5) {
        this.setState({
          documentSizeError: true
        })
        return false
      }

      let fileType = files[0].name.split('.').pop()
      const fileTypeRegistration = files[0].type

      if (
        fileType.toLowerCase() === 'png' ||
        fileType.toLowerCase() === 'pdf' ||
        fileType.toLowerCase() === 'jpeg' ||
        fileType.toLowerCase() === 'jpg'
      ) {
        let reader = new FileReader()
        reader.readAsDataURL(files[0])
        reader.onload = e => {
          this.setState({
            businessRegistrationFileType: fileTypeRegistration,
            businessFileDocname: files[0].name,
            documentSizeError: false
          })
          this.setState({ businessRegistration: e.target.result })
        }
      } else {
        createNotification('error', 'Accept only pdf or jpeg')
      }
    }
  }

  onChangeDocument = e => {
    console.warn('warning function call details')
    let files = e.target.files
    if (files) {
      let response = files[0].size / 1000000
      if (response && response > 5) {
        this.setState({
          personalDocumentError: true
        })
        return false
      }

      let fileType = files[0].name.split('.').pop()
      const fileTypedoc = files[0].type

      if (
        fileType.toLowerCase() === 'png' ||
        fileType.toLowerCase() === 'pdf' ||
        fileType.toLowerCase() === 'jpeg' ||
        fileType.toLowerCase() === 'jpg'
      ) {
        let reader = new FileReader()
        reader.readAsDataURL(files[0])
        reader.onload = e => {
          this.setState({
            businessTaxFileType: fileTypedoc,
            panFileDocname: files[0].name,
            personalDocumentError: false
          })
          this.setState({ businessTax: e.target.result })
        }
      } else {
        // this.setState({
        //   personalDocumentError: true
        // })
        createNotification('error', 'Accept only pdf or jpeg')
      }
    }
  }
  doSubmitAddBusiness () {
    const { selectedBusiness } = this.state

    let loginUser = JSON.parse(localStorage.getItem('loginuser'))

    // let sendRequest = {
    //   data: {

    //     businessID:selectedBusiness.businessID,
    //     registeredBusinessName: selectedBusiness.registeredBusinessName,
    //     displayedBusinessName: selectedBusiness.displayedBusinessName,
    //     businessCity: selectedBusiness.businessCity,
    //     businessCountry: selectedBusiness.businessCountry,
    //     businessState: selectedBusiness.businessState,
    //     businessPostalCode: selectedBusiness.businessPostalCode,
    //     documentID: loginUser.docID

    //   },
    // };
    // let sendRequest = {
    //   data: {
    //     associatedBusiness: selectedBusiness.docID
    //   }
    // }
    let sendRequest = {
      data: {
        businessDocID: selectedBusiness.docID
      }
    }
   

    // this.props.register (sendRequest);
    this.props.sendBusinessInvite(sendRequest)
  }

  goBack () {
    if (this.state.step === 1) {
      this.props.history.goBack()
      return
    }
    if (this.state.step === 4) {
      this.setState({
        step: 1
      })
      return
    }
    this.setState({
      step: this.state.step - 1
    })
  }

  render () {
    const {
      businessData,
      isSearch,
      selectedBusiness,
      displayedBusinessName,
      registeredBusinessName,
      businessWebsiteUrl,
      businessEmailAddress,
      businessPhoneNumber,
      yearEstablished,
      isSecondSubmit,
      invalidEmailFormat,
      establishedYearDropdown,
      businessSizeoptions,
      businessSize,
      businessTypeoptions,
      businessType,
      agree,
      businessAddress_1,
      businessAddress_2,
      businessCountry,
      businessCity,
      businessState,
      businessPostalCode,
      acceptedTermsConditions,
      businessRegistration,
      businessTax,
      profileCompletion,
      panFileDocname,
      businessFileDocname
    } = this.state
    const businessCompleteBox = (
      <div className='CompleteBus'>
        <p>Add Business to My Profile</p>
        <Progress
          value={
            profileCompletion && profileCompletion.percentageComplete
              ? profileCompletion.percentageComplete
              : 0
          }
          className='businessRegisterProgressbar'
        />
      </div>
    )
    return (
      <div className='mainBusinessWrapper'>
        <div className='mainBusinessContainer'>
          {this.state.step > 1 && (
            <div
              className='mainBusinessGobackBox'
              style={{ textAlign: 'right', marginRight: 30 }}
            >
              <Button
                className='stepGoBackRegisterIcon'
                onClick={() => this.goBack()}
                data-rh='Go back'
              >
                <div>
                  <img src={leftbackarrow} alt='backIcon' />
                </div>
                Go <span> Back</span>
              </Button>
            </div>
          )}
          <div className='registerChildCenter'>
            {this.state.step === 1 && (
              <div className='registerFirstStep'>
                <div className='Doyouwant'>
                  Do you want to link your business?
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    className='findmybusbutton'
                    onClick={() => {
                      this.setState({ step: 4 })
                    }}
                    data-rh='Find business'
                  >
                    Find Business
                  </Button>
                </div>
                <div style={{ textAlign: 'center' }}>
                  <Button
                    className='regisretbutton'
                    onClick={this.nextScreen}
                    data-rh='Add new business'
                  >
                    Register New Business
                  </Button>
                </div>
                <div
                  className='businADDLAT'
                  onClick={() => this.props.history.goBack()}
                  data-rh='Add later'
                >
                  {' '}
                  ADD LATER
                </div>
              </div>
            )}
            {this.state.step === 2 && (
              <div>
                <div
                  style={{ display: 'inline-flex', justifyContent: 'center' }}
                >
                  <div className='mainbusinesregustr'>
                    {businessCompleteBox}
                    <div className='PRIMARY'>
                      <span>PRIMARY </span> DETAILS
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 30
                      }}
                    >
                      <div>
                        <p className='label1'>Registered Business Name</p>
                        <input
                          placeholder=''
                          className='businessInputBox'
                          value={CapitalizeFirstLetter(
                            this.state.registeredBusinessName
                          )}
                          onChange={e =>
                            this.setState({
                              registeredBusinessName: e.target.value
                            })
                          }
                        />
                        {isSecondSubmit && !registeredBusinessName && (
                          <p
                            className='validationerrorMessage'
                            style={{ textAlign: 'left' }}
                          >
                            Business name is required *
                          </p>
                        )}
                      </div>
                      <div>
                        <p className='label2'>Display Name As</p>
                        <input
                          placeholder=''
                          className='businessInputBox'
                          style={{ marginLeft: 20 }}
                          value={CapitalizeFirstLetter(displayedBusinessName)}
                          onChange={e =>
                            this.setState({
                              displayedBusinessName: e.target.value
                            })
                          }
                        />
                        {isSecondSubmit && !displayedBusinessName && (
                          <p
                            style={{ textAlign: 'left', marginLeft: 20 }}
                            className='validationerrorMessage'
                          >
                            Display business name is required *
                          </p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 27
                      }}
                    >
                      <div>
                        <p className='label1'>Year Established</p>
                        <div className='yearEstablishBox'>
                          <SelectInput
                            items={establishedYearDropdown}
                            value={yearEstablished}
                            className='addBusinessYearDropdown'
                            dropDownContainerClass='addBusinessYearContainerDropdown'
                            onChange={value => {
                              this.setState({
                                yearEstablished: value
                              })
                            }}
                          />
                        </div>
                        {isSecondSubmit && !yearEstablished && (
                          <p className='validationerrorMessage errorMessagePadding'>
                            Year established is required *
                          </p>
                        )}
                      </div>
                      <div>
                        <p className='label2'>Website</p>
                        <input
                          placeholder=''
                          className='inpit4'
                          style={{ marginLeft: 19 }}
                          value={businessWebsiteUrl}
                          onChange={e =>
                            this.setState({
                              businessWebsiteUrl: e.target.value
                            })
                          }
                        />
                        {/* {isSecondSubmit &&
                          !businessWebsiteUrl &&
                          <p className="validationerrorMessage errorMessagePadding">
                            Business web site Url is required *
                          </p>} */}
                      </div>
                      <div>
                        <p className='label3'>Business Email</p>
                        <input
                          placeholder=''
                          className='inpurt5'
                          style={{ marginLeft: 20 }}
                          value={businessEmailAddress}
                          onChange={e =>
                            this.setState({
                              businessEmailAddress: e.target.value
                            })
                          }
                        />
                        {isSecondSubmit && !businessEmailAddress && (
                          <p className='validationerrorMessage errorMessagePadding'>
                            Business Email is required *
                          </p>
                        )}
                        {isSecondSubmit && invalidEmailFormat && (
                          <p className='validationerrorMessage errorMessagePadding'>
                            {' '}
                            Invalid email format *
                          </p>
                        )}
                      </div>
                      <div>
                        <p className='label3'>Phone Number</p>
                        <input
                          placeholder=''
                          className='inpurt5'
                          style={{ marginLeft: 19 }}
                          value={businessPhoneNumber}
                          maxLength='10'
                          pattern='[0-9]*'
                          onChange={evt => {
                            const businessPhoneNumber = evt.target.validity
                              .valid
                              ? evt.target.value
                              : this.state.businessPhoneNumber
                            this.setState({
                              businessPhoneNumber
                            })
                          }}
                        />
                        {isSecondSubmit && !businessPhoneNumber && (
                          <p className='validationerrorMessage errorMessagePadding'>
                            Business phone number is required *
                          </p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 27
                      }}
                    >
                      <div>
                        <p className='label1'>Registered Address Line 1</p>
                        <input
                          placeholder=''
                          className='businessInputBox'
                          value={businessAddress_1}
                          onChange={e =>
                            this.setState({ businessAddress_1: e.target.value })
                          }
                        />
                        {isSecondSubmit && !businessAddress_1 && (
                          <p className='validationerrorMessage errorMessagePadding'>
                            Business address is required *
                          </p>
                        )}
                      </div>
                      <div>
                        <p className='label2'>Registered Address Line 2</p>
                        <input
                          placeholder=''
                          className='businessInputBox'
                          style={{ marginLeft: 20 }}
                          value={businessAddress_2}
                          onChange={e =>
                            this.setState({ businessAddress_2: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: 27
                      }}
                    >
                      <div>
                        <p className='label1'>Country of Establishment</p>
                        <input
                          placeholder=''
                          className='inputrd6'
                          value={businessCountry}
                          onChange={e =>
                            this.setState({ businessCountry: e.target.value })
                          }
                        />
                        {isSecondSubmit && !businessCountry && (
                          <p className='validationerrorMessage errorMessagePadding'>
                            Business country is required *
                          </p>
                        )}
                      </div>
                      <div>
                        <p className='label2'>City</p>
                        <input
                          placeholder=''
                          className='input7d'
                          style={{ marginLeft: 19 }}
                          value={businessCity}
                          onChange={e =>
                            this.setState({ businessCity: e.target.value })
                          }
                        />
                        {isSecondSubmit && !businessCity && (
                          <p className='validationerrorMessage errorMessagePadding'>
                            Business city is required *
                          </p>
                        )}
                      </div>
                      <div>
                        <p className='label3'>State</p>
                        <input
                          placeholder=''
                          className='inpurt5'
                          style={{ marginLeft: 20 }}
                          value={businessState}
                          onChange={e =>
                            this.setState({ businessState: e.target.value })
                          }
                        />
                        {isSecondSubmit && !businessState && (
                          <p className='validationerrorMessage errorMessagePadding'>
                            Business state is required *
                          </p>
                        )}
                      </div>
                      <div>
                        <p className='label3'>Postal Code</p>
                        <input
                          placeholder=''
                          className='input8'
                          style={{ marginLeft: 19 }}
                          value={businessPostalCode}
                          onChange={e =>
                            this.setState({
                              businessPostalCode: e.target.value
                            })
                          }
                        />
                        {isSecondSubmit && !businessPostalCode && (
                          <p className='validationerrorMessage errorMessagePadding'>
                            Business postal code is required *
                          </p>
                        )}
                      </div>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        className='businessreginet'
                        onClick={() => this.onSubmitNext()}
                        data-rh='Next'
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.step === 3 && (
              <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className='mainbusinesregustr'>
                    {businessCompleteBox}
                    <div className='additional'>ADDITIONAL DETAILS</div>
                    <div style={{ display: 'flex' }}>
                      <div>
                        <p className='label1'>Business Type</p>

                        <SelectInput
                          items={businessTypeoptions}
                          value={businessType}
                          className='addBusinessTypeDropdown'
                          dropDownContainerClass='addBusinessTypeContainerDropdown'
                          onChange={value => {
                            this.setState({
                              businessType: value
                            })
                          }}
                        />

                        {isSecondSubmit && !businessType && (
                          <p className='validationerrorMessage'>
                            Business Type is required *
                          </p>
                        )}
                      </div>
                      <div style={{ paddingLeft: 20 }}>
                        <p className='businessSizwlABEL'>Business Size</p>
                        <div className='yearEstablishBox'>
                          <SelectInput
                            items={businessSizeoptions}
                            value={businessSize}
                            className='addBusinessYearDropdown'
                            dropDownContainerClass='addBusinessYearContainerDropdown'
                            onChange={value => {
                              this.setState({
                                businessSize: value
                              })
                            }}
                          />
                        </div>
                        {/* {isSecondSubmit &&
                          !businessSize &&
                          <p className="validationerrorMessage">
                            Business size is required *
                          </p>} */}
                      </div>
                    </div>
                    <div style={{ display: 'grid' }}>
                      <div style={{ display: 'inline-flex' }}>
                        <div className='businessregidocu'>
                          Business Registration Document
                          <div className='PDForJPEG'>
                            PDF or JPEG only allowed
                          </div>
                          {this.state.documentSizeError && (
                            <div className='regiserbusineerror'>
                              The uploaded file is too large. Documents can only
                              be a maximum of 5 MB.
                            </div>
                          )}
                        </div>
                        <div style={{ display: 'grid' }}>
                          {businessFileDocname && (
                            <div
                              style={{
                                display: 'inline-flex',
                                justifyContent: 'space-between',
                                paddingTop: 30,
                                minWidth: 180
                              }}
                            >
                              <div>
                                <img
                                  style={{
                                    width: 41,
                                    height: 55,
                                    marginLeft: 15
                                  }}
                                  src={previewFile}
                                  alt='previewFile'
                                />
                              </div>
                              <div className='filenameText'>
                                {businessFileDocname}
                              </div>
                            </div>
                          )}
                          <div
                            style={{
                              display: 'inline-flex',
                              justifyContent: 'space-between',
                              paddingTop: 10,
                              width: 200
                            }}
                          >
                            <label
                              className='regisdocsupload'
                              style={{
                                marginTop: !businessRegistration ? 30 : 0
                              }}
                              data-rh='Select File'
                            >
                              <input
                                type='file'
                                name='profilePicture'
                                onChange={e => this.onChangeRegistration(e)}
                                accept='application/pdf,image/jpeg'
                              />
                              {this.state.businessRegistration
                                ? 'Replace'
                                : 'Upload'}
                            </label>
                            {businessFileDocname && (
                              <label
                                className='regisdocsDelete'
                                onClick={() =>
                                  this.setState({
                                    businessRegistration: '',
                                    businessFileDocname: '',
                                    documentSizeError: false
                                  })
                                }
                                data-rh='Delete'
                              >
                                Delete
                              </label>
                            )}
                          </div>
                        </div>
                      </div>

                      <div style={{ display: 'inline-flex' }}>
                        <div className='businessregidocu'>
                          PAN or TAX Document
                          <div className='PDForJPEG'>
                            PDF or JPEG only allowed
                          </div>
                          {this.state.personalDocumentError && (
                            <div className='regiserbusineerror'>
                              The uploaded file is too large. Documents can only
                              be a maximum of 5 MB.
                            </div>
                          )}
                        </div>
                        <div style={{ display: 'grid' }}>
                          {panFileDocname && (
                            <div
                              style={{
                                display: 'inline-flex',
                                justifyContent: 'space-between',
                                paddingTop: 30
                              }}
                            >
                              <div>
                                <img
                                  style={{
                                    width: 41,
                                    height: 55,
                                    marginLeft: 15
                                  }}
                                  src={previewFile}
                                  alt='previewFile'
                                />
                              </div>
                              <div className='filenameText'>
                                {panFileDocname}
                              </div>
                            </div>
                          )}
                          <div
                            style={{
                              display: 'inline-flex',
                              justifyContent: 'space-between',
                              paddingTop: 10,
                              width: 200
                            }}
                          >
                            <label
                              className='regisdocsupload'
                              style={{ marginTop: !businessTax ? 30 : 0 }}
                              data-rh='Upload File'
                            >
                              <input
                                type='file'
                                name='profilePicture'
                                onChange={e => this.onChangeDocument(e)}
                                accept='application/pdf,image/jpeg'
                              />
                              {this.state.businessTax ? 'Replace' : 'Upload'}
                            </label>
                            {panFileDocname && (
                              <label
                                className='regisdocsDelete'
                                onClick={() =>
                                  this.setState({
                                    businessTax: '',
                                    panFileDocname: '',
                                    personalDocumentError: false
                                  })
                                }
                                data-rh='Delete'
                              >
                                Delete
                              </label>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <label className='Iagreewi'>
                      <input
                        type='radio'
                        className='radioinput'
                        checked={acceptedTermsConditions}
                        value={acceptedTermsConditions}
                        onClick={() =>
                          this.setState({
                            acceptedTermsConditions: !acceptedTermsConditions
                          })
                        }
                      />
                      I agree with the{' '}
                      <Link
                        to={routes.TERMS_CONDITIONS}
                        data-rh='Privacy Policy & Terms and Conditions'
                      >
                        {' '}
                        &nbsp; Privacy Policy & Terms and Conditions
                      </Link>
                    </label>
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        className='submitresfgbutton'
                        disabled={
                          !acceptedTermsConditions

                          // || !businessRegistration ||
                          // !businessTax
                        }
                        onClick={() => this.submitBusinessAdditional()}
                        data-rh='Submit'
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.step === 4 && (
              <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className='mainbusinesregustr'>
                    {businessCompleteBox}
                    <div className='FINDBUSI'>
                      <span>FIND </span> BUSINESS
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <p className='textlabeljcd' style={{ marginBottom: 20 }}>
                        Search for your business in our database
                      </p>
                      <div className='searchinput'>
                        <img
                          alt='whiteSearch'
                          src={whiteSearch}
                          style={{ width: 25, marginLeft: 14 }}
                        />
                        <input
                          placeholder='Enter Search Text'
                          className='businesssearchinput'
                          value={this.state.searchString}
                          onChange={e =>
                            this.setState(
                              { searchString: e.target.value },
                              () => {
                                this.handleChangeSearch()
                              }
                            )
                          }
                        />
                      </div>
                      <div className='filterByText'>
                        Business lookup by
                        <label
                          onClick={() => {
                            this.setState({
                              searchByName: false
                            })
                          }}
                        >
                          <input
                            type='radio'
                            checked={!this.state.searchByName}
                          />{' '}
                          <span style={{ paddingLeft: 10 }}> Business ID </span>
                        </label>
                        <label
                          onClick={() => {
                            this.setState({
                              searchByName: true
                            })
                          }}
                        >
                          <input
                            type='radio'
                            checked={this.state.searchByName}
                          />{' '}
                          <span style={{ paddingLeft: 10 }}>
                            {' '}
                            Business Name{' '}
                          </span>
                        </label>
                      </div>
                      {businessData && businessData.length > 0 && isSearch && (
                        <div>
                          <p className='foundResults'>
                            <span>{businessData.length} </span> results found:
                          </p>
                          <div className='searchBusinessList'>
                            {businessData.map((item, index) => (
                              <div
                                key={index}
                                onClick={() => this.onSelect(item)}
                                style={{ paddingLeft: 10 }}
                                className='searchBusiness'
                              >
                                {item && item.registeredBusinessName
                                  ? item.registeredBusinessName
                                  : ''}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {isSearch &&
                        businessData &&
                        businessData.length === 0 && (
                        <div className='nomatchfounfd'>No match found.</div>
                      )}

                      {isSearch && businessData && businessData.length === 0 && (
                        <div style={{ textAlign: 'center' }}>
                          <Button
                            className='searchagainm'
                            onClick={() => {
                              this.setState({
                                isSearch: false,
                                searchString: ''
                              })
                            }}
                            data-rh='Search Again'
                          >
                            Search Again
                          </Button>
                        </div>
                      )}
                      {isSearch && businessData && businessData.length === 0 && (
                        <div style={{ textAlign: 'center' }}>
                          <Button
                            className='resigisrterbutton2'
                            onClick={() => {
                              this.setState({ step: 2 })
                            }}
                            data-rh='Add new'
                          >
                            Register New Business
                          </Button>
                        </div>
                      )}
                    </div>
                    <div style={{ textAlign: 'center', marginTop: 50 }}>
                      <Button
                        className='submitresfgbutton'
                        onClick={this.nextScreen}
                        disabled={!selectedBusiness}
                        data-rh='Next'
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.step === 5 && (
              <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <div className='mainbusinesregustr'>
                    {businessCompleteBox}
                    <div className='FINDBUSI'>
                      <span>FIND </span> BUSINESS
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <p className='textlabeljcd' style={{ marginBottom: 15 }}>
                        Selected business:
                      </p>
                      <div className='resultfounddiv'>
                        {selectedBusiness &&
                        selectedBusiness.registeredBusinessName
                          ? selectedBusiness.registeredBusinessName
                          : ''}
                      </div>
                      <div style={{ paddingLeft: 35 }}>
                        <div className='steredBusines'>
                          Registered Business Name
                        </div>
                        <div className='EasternBil'>
                          {selectedBusiness &&
                          selectedBusiness.registeredBusinessName
                            ? selectedBusiness.registeredBusinessName
                            : ''}
                        </div>
                        <div className='steredBusines'> Business ID</div>
                        <div className='EasternBil'>
                          {/* (selectedBusiness && selectedBusiness.businessID)
                    ? selectedBusiness.businessID
                    : 'N/A' */}
                        </div>
                        <div className='doubletextrowdiv'>
                          <div>
                            <div className='steredBusines'>Country</div>
                            <div className='EasternBil'>
                              {selectedBusiness &&
                              selectedBusiness.businessCountry
                                ? selectedBusiness.businessCountry
                                : 'N/A'}
                            </div>
                          </div>
                          <div>
                            <div className='steredBusines'>City</div>
                            <div className='EasternBil'>
                              {selectedBusiness && selectedBusiness.businessCity
                                ? selectedBusiness.businessCity
                                : 'N/A'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        className='searchagainm'
                        onClick={() => {
                          this.doSubmitAddBusiness()
                        }}
                        data-rh='Submit'
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    userinfo: state.Authreducer
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getFindBusiness,
      register,
      getProfileDashboard,
      ModifyPersonalInfo,
      resetUserReducer,
      sendBusinessInvite
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessRegister)
