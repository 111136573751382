import baseService from './Baseservice';


export const getAllUserContacts_v2Service = (request) => {
    return baseService.post('/getAllUserContacts_v2', request);
};

// export const searchUsersService = (request) => {
//     return baseService.post('/searchUsers', request);
// };
export const searchUsersService = (request) => {
    return baseService.post('/searchUsersAndBusinesses', request);
};


export const getUserDetailsService = (request) => {
    return baseService.post('/getUserDetails', request);
};

export const uploadUserProfileService = (request) => {
    return baseService.post('/uploadProfilePicture', request);
};

export const getSentUserContactRequestsService = (request) => {
    return baseService.post('/getSentUserContactRequests', request);
};
export const getUserPendingActionsService = (request) => {
    return baseService.post('/getUserPendingActions_v2', request);
};

// export const getBusinessDetailsService = (request) => {
//     return baseService.post('/getBusinessDetails', request);
// };

export const getBusinessDetailsService = (request) => {
    return baseService.post('/contactsGetBusinessDetails', request);
};











