import _ from 'lodash';
import { timeSince } from './convertTimeString'

// greenText: "#87DB6D",
// awayText: "#F2C94C",
// dndText: "#E1216F",
// invisibleGray: "#E0E0E0",

export const onlineStatus = (userOnlineData)=>{
    // console.log("userOnlineData>>>>", userOnlineData)
    // let arrStatus = ['Active', 'Away', 'Do Not Disturb', 'Invisible', 'Custom Status'];
    // const{isOnline, lastTimeOnline, loggedIn} = userOnlineData;
    let lastTimeOnline = _.get(userOnlineData,"lastTimeOnline", Math.floor(Date.now()) );
    let loggedIn = _.get(userOnlineData,'loggedIn',  false );
    let isOnline = _.get(userOnlineData,'isOnline', false );

    let statusIconColor = "#87DB6D";
    let statusMessageToShow = ''
    switch(_.get(userOnlineData,'statusMessage','').trim()){
        case 'Active':
            if(loggedIn){
                statusIconColor = !isOnline ? '#4AEA87' : "#4AEA87";
                statusMessageToShow = !isOnline 
                    ? (lastTimeOnline !== null && lastTimeOnline !== undefined) 
                        ? "Last Seen " +timeSince(lastTimeOnline) 
                        : ''
                    : 'Online'
            } else {
                statusIconColor = "#DDDDDD";
                statusMessageToShow = "Offline" 
            }
            break;

        case 'Away':
            statusIconColor = "#FFBF00";
            statusMessageToShow = "Away " +timeSince(userOnlineData.statusUpdatedAt) 
            break;

        case 'Do Not Disturb':
            statusIconColor = "#ff0b34";
            statusMessageToShow = "Do Not Disturb " +timeSince(userOnlineData.statusUpdatedAt)
            break;

        case 'Invisible':
            statusIconColor = "#4AEA87";
            statusMessageToShow = "Invisible " +timeSince(userOnlineData.statusUpdatedAt)
            break;
        default:
            statusIconColor = "";
            if(loggedIn){
                statusIconColor = !isOnline ? '#DDDDDD' : "#87DB6D";
                statusMessageToShow = !isOnline 
                    ? (lastTimeOnline !== null && lastTimeOnline !== undefined) 
                        ? "Last Seen " +timeSince(lastTimeOnline) 
                        : ''
                    : 'Online'
            } else {
                statusIconColor = "#DDDDDD";
                statusMessageToShow = "Offline" 
            }
    }
    
    return {
        statusIcon: statusIconColor,
        statusMessage: _.get(userOnlineData,'statusMessage','').trim(),
        statusUpdatedAt: _.get(userOnlineData,'statusUpdatedAt',1),
        lastTimeOnline: _.get(userOnlineData, 'lastTimeOnline', null),
        isUserOnline: _.get(userOnlineData, 'isOnline', false),
        loggedIn: _.get(userOnlineData, 'loggedIn', false),
        statusMessageToShow: statusMessageToShow
    }
}