import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Button, Input} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import queryString from 'query-string';
import {
  createPayment,
  createTransfer,
  resetTransactionList,
} from '../../../actions/Paymentaction';
import leftbackarrow from '../../../images/icons/leftbackarrow.png';
import {EncryptFunction} from '../../../utility/encryption';

import PinInput from 'react-pin-input';

import './withDraw.css';
// var WyrePmWidget = window;

class WithDraw extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      accountList: [],
      userBank: '',
      isPayment: false,
      selectedAccount: '',
      pinValue: '',
      amount: '',
      userPaymentInitiated: '',
    };
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps && nextProps.payment) {
    
      if (nextProps.payment.userPaymentBanks) {
      
        this.setState ({
          userBank: nextProps.payment.userPaymentBanks,
        });
      }

      if (nextProps.payment.userPaymentAccounts) {
        this.setState ({
          accountList: nextProps.payment.userPaymentAccounts,
        });
      }
      if (nextProps.payment.userPaymentInitiated) {
        this.setState ({
          userPaymentInitiated: nextProps.payment.userPaymentInitiated,
        });
      }

      // if(nextProps.payment.userPaymentStatus){
      //   if( nextProps.payment.userPaymentStatus.status==="COMPLETED"){
      //     this.setState({
      //      ispaymentStatus: true
      //     })
      //    }else{
      //      this.setState({
      //        ispaymentStatus: false
      //       })
      //    }
      // }
    }
  }

  componentDidMount () {
    const {location: {search}} = this.props;
    const {paymentToken} = queryString.parse (search);

    if (!paymentToken) {
      window.sendwyreCall ();
    }

    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
    if (loginUser) {
      this.setState (
        {
          userid: loginUser.docID,
          paymentToken: paymentToken,
        },
        () => {
          this.props.resetTransactionList ();
          if (paymentToken) {
            this.initializePayment ();
          }

          // else{
          //   window.sendwyreCall();
          // }
        }
      );
    }
  }

  initializePayment () {
    let sendRequest = {
      user_id: this.state.userid,
      public_token: this.state.paymentToken,
    };

    this.props.createPayment (sendRequest);
  }
  goBack () {
    this.setState ({
      isPayment: false,
    });
  }

  selectAccount (item) {
    this.setState ({
      isPayment: true,
      selectedAccount: item,
    });
  }

  goWithdraw () {
    const {selectedAccount, userid, pinValue, amount} = this.state;
    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));

    let hashKeyPIN = EncryptFunction (loginUser.emailAddress, pinValue);
    let sendRequest = {
      user_id: userid,
      passphrase: hashKeyPIN,
      ach_id: selectedAccount && selectedAccount.ach_id
        ? selectedAccount.ach_id
        : '',
      amount: Number (amount),
    };

    this.props.createTransfer (sendRequest);
  }

  render () {
    const {accountList, userBank, isPayment, userPaymentInitiated} = this.state;
    return (
      <div className="rdeePayBlue" id="settleWithDraw">
      <div className="bankListContainer " id="settleWithDraw1">
        {isPayment &&
          <div>

            <div style={{textAlign: 'right'}} id="settleWithDraw2">
              <Button
                className="stepGoBackIcon"
                onClick={() => this.goBack ()}
                data-rh="Go back"
                id="settleWithDraw3"
              >
                <div>
                  <img src={leftbackarrow} alt="backIcon" id="settleWithDraw4"/>
                </div>
                Go <span> Back</span>
              </Button>
            </div>

            {!userPaymentInitiated &&
              <div>
                <div>
                  <div className="titleText" id="settleWithDraw5">
                    Enter Amount <input
                      type="text"
                      placeholder="Enter amount"
                      value={this.state.amount}
                      // pattern="[0-9]*"
                      pattern="^\d*(\.\d{0,2})?$"
                      onChange={evt => {
                        const amount = evt.target.validity.valid
                          ? evt.target.value
                          : this.state.amount;
                        this.setState ({
                          amount,
                        });
                      }}
                      id="settleWithDraw6"
                    />

                  </div>
                </div>

                <div>
                  <p className="titleText" id="settleWithDraw7">
                    Enter your PIN to confirm sending money
                  </p>
                  <PinInput
                    length={4}
                    focus
                    secret
                    ref={p => (this.pin = p)}
                    type="numeric"
                    style={{
                      padding: '10px',
                      display: 'inline-flex',
                      width: 200,
                    }}
                    inputStyle={{
                      borderColor: 'transparent',
                      borderBottomColor: '#00D8A4',
                      borderBottomWidth: 2,
                      borderTopWidth: 0,
                      borderRadius: 0,
                      color: 'white',
                      fontSize: 30,
                    }}
                    inputFocusStyle={{borderBottomColor: '#4092B7'}}
                    // onChange={this.onChange}
                    onComplete={(value, index) => {
                      this.setState (
                        {
                          pinValue: value,
                        },
                        () => {}
                      );
                    }}
                    id="settleWithDraw8"
                  />

                </div>

                <Button
                  className="submitWithdrawButton"
                  onClick={() => this.goWithdraw ()}
                  data-rh="Withdraw payment"
                >
                  Submit
                </Button>

              </div>}

            {userPaymentInitiated &&
              <div>
                <div className="bankListTitle" id="settleWithDraw9"> Your Payment initiated</div>
                <div className="titleText" id="settleWithDraw10">
                  {' '}Payment id : {userPaymentInitiated.payment_id}
                </div>
                <div className="titleText" id="settleWithDraw11">
                  {' '}Amount : {userPaymentInitiated.amount}
                </div>
                <div className="titleText" id="settleWithDraw12">
                  {' '}Status : {userPaymentInitiated.status}
                </div>

              </div>}

          </div>}
        {!isPayment && userBank &&
          <div>
            <p className="bankListTitle" id="settleWithDraw13">Bank Details </p>
            {userBank &&
              <div className="accountList" id="settleWithDraw14">

                <div>{userBank.ach_name ? userBank.ach_name : ''}</div>
                <div>{userBank.ach_id ? userBank.ach_id : ''}</div>
              </div>}

            <div>
              <p className="bankListTitle" id="settleWithDraw15">Please select any account</p>

              {accountList &&
                accountList.length > 0 &&
                accountList.map ((item, i) => (
                  <div
                    id={`settleWithDrawaccountList${i}`}
                    onClick={() => {
                      if(item.status=== 'ACTIVE'){
                        this.selectAccount (item)
                      }
                    }}
                    className="accountList"
                    key={i}
                    style={{cursor: item.status=== 'ACTIVE'? 'pointer' :'',opacity: item.status!== 'ACTIVE'? 0.5:''}}
                  >
                    <div id={`settleWithDrawaccountListname${i}`}>{item.ach_name}</div>
                    <div id={`settleWithDrawaccountListbenificiary${i}`}>{item.ach_beneficiary_type}</div>
                    <div id={`settleWithDrawaccountListach_id${i}`}>{item.ach_id}</div>
                  </div>
                ))}

            </div>
          </div>}
      </div>
      </div>
      );
  }
}

const mapStateToProps = state => {
  return {
    payment: state.PaymentReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      createPayment,
      createTransfer,
      resetTransactionList,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (WithDraw);
