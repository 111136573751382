import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import deleteChat from '../../../images/deletechat.png';
import muthsound from '../../../images/muthsound.png';
import profilePlaceholder from '../../../images/profile_placeholder.png';
import videoCamera from '../../../images/icons/videoCamera.png';
import muteIcon from '../../../images/icons/muteIcon.png';
import menuDots from '../../../images/icons/messenger/menudots.png';
import { ShowNameAccordingToLength } from '../../../utility/stringFunctions';

import './Toolbar.css';

export default function Toolbar({
  title,
  imgSource,
  leftItems,
  rightItems,
  onlineStatus,
  showCrumbs = false,
  showMuteIcon = false,
  onClickVideoIcon,
  crumbOptions = [],
  onDeleteConversation,
  statusIcon,
  userInfo
}) {
  const [showDropDownItems, toggleDropDownView] = useState(false);
  const [isOpened, setisOpened] = useState(false);

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      toggleDropDownView(false)
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <div className="toolbar">
      {/*<div className="left-items">{leftItems}</div>*/}
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%'}}>
          <div className="chatUserTopHeader">
            <div style={{ display: 'inline-flex', width: 55 }}>
              <img className="avatarImg" src={imgSource ? imgSource : profilePlaceholder}
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = profilePlaceholder;
                }}
              />
              <div className="chatStatusCircle">
                <div className="ChatActiveStatus " style={{ background: statusIcon }} />
              </div>
            </div>
            <div style={{marginBottom:5}}>
              <div className={"muteIconContainer"}>
              <p className="username">
                {userInfo && userInfo.chatType === "singleChat" ? ShowNameAccordingToLength(title) : title}
              </p>
              {showMuteIcon && <img className={"muteIcon"} src={muteIcon} />}
              </div>
              
              <p className="lastSeenStatus">{onlineStatus}</p>
            </div>
          
          </div>

          {/*video icon dispayed here*/}
          <div className={'videoIconContainer'} onClick={onClickVideoIcon}>
            <img className={"videoIcon"} src={videoCamera} />
          </div>

        </div>

      </div>


      {
        (showCrumbs && (crumbOptions.length > 0)) && <div className="crumb-container" ref={ref}>
          <div
            className="crumb-text"
            // data-rh="Open menu"
            onClick={() => toggleDropDownView(!showDropDownItems)}
          ><img src={menuDots} style={{ width: 25 }} alt="menuIcons" /></div>
          <div
            id="dropdownmenu"
            className={`dropdownnewwrapperMessenger ${showDropDownItems ? '' : 'hide'}`}>
            <svg className="dropdownArrowGroupDetailChat" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path opacity="0.8" d="M9 0L0 18H18L9 0Z" fill="#17171D" />
            </svg>

            <div
              className={`dropdownGroupChat checkdropMenu`}
              isOpened={showDropDownItems}
              onClick={() => setisOpened(!showDropDownItems)}
            >

              {crumbOptions.map(obj => <div onClick={() => {
                toggleDropDownView(false)
                obj.onClick();
              }}>
                {obj.name}
              </div>

              )}

              {/* <div onClick={this.manageGroups}>
              Manage Groups
            </div> */}
            </div>
          </div>
        </div>
      }
      {/*<div
            className="crumb-text"
            onClick={() => toggleDropDownView(!showDropDownItems)}
          ><img src={menuDots} style={{ width: 25 }} alt="menuIcons" /></div>
          {
            (showDropDownItems && <div>
              {crumbOptions.map(obj => <div
                className="child"
                onClick={() => {
                  toggleDropDownView(false)
                  obj.onClick();
                }}
              >
                {obj.name}
              </div>)}
            </div>)
          }
        </div>*/}
    </div>
  );
}

Toolbar.propTypes = {
  title: PropTypes.string.isRequired,
  showCrumbs: PropTypes.bool,
  crumbOptions: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  })).isRequired,
};