import React, {Component} from 'react';
// import logo from '../../../images/icons/logo/whileLogo.png';
import {Button, Input, Modal, ModalBody} from 'reactstrap';
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch';

import productDetails
  from '../../../images/marketPlaceBuyer/productDetails.png';
import './productZoom.css';
import {priceWithDecimal} from '../../../utility/commonFunctions';
import zoomOutIcon from '../../../images/icons/zoomOut.svg';
import zoomInIcon from '../../../images/icons/zoomIn.svg';

import blackCross from '../../../images/icons/blackCross.png';

class ProductZoomDetail extends Component {
  constructor (props) {
    super (props);
    this.state = {
      isOpened: false,
    };
  }
  toggle = () => {
    this.setState ({
      isOpened: !this.state.isOpened,
    });
  };

  render () {
    const {productDetail, imgIndex, closeModal} = this.props;

    return (
      <div>

        <Modal
          size="xl"
          isOpen={true}
          centered={true}
          toggle={() => closeModal ()}
        >
          <ModalBody className="zoomProductModalBody" id="productimagePreviewModal">
            <div>
              <div>
                <Button
                  className="closeZoomButtonIcon"
                  onClick={() => closeModal ()}
                  id="productimagePreviewModal1"
                >
                  <img src={blackCross} alt="closeIcon" id="productimagePreviewModal2"/>
                </Button>
              </div>
              {productDetail &&
                productDetail.productImageList &&
                productDetail.productImageList.length > 0 &&
                <div id="productimagePreviewModal3">

                  <div id="productimagePreviewModal4" style={{display: 'inline-flex', flexWrap: 'wrap',width:'100%',borderRadius:15,overflow:'hidden'}}>

                    <div style={{width: '65%'}} id="productimagePreviewModal5">

                      <TransformWrapper
                      // defaultScale={1}
                      // defaultPositionX={200}
                      // defaultPositionY={100}
                      >
                        {({zoomIn, zoomOut, resetTransform, ...rest}) => (
                          <React.Fragment>
                            <div
                              style={{
                                display: 'inline-flex',
                                justifyContent: 'center',
                                width: '100%',
                              }}
                              id="productimagePreviewModal6"
                            >
                              <TransformComponent transformEnabled={false}>
                                <img
                                  // style={{width:'100%',maxHeight:'80vh'}}
                                  className="zoomImageArea"
                                  src={productDetail.productImageList[imgIndex]}
                                  alt="test"
                                  id="productimagePreviewModal7"
                                />

                                {/*
                      <img style={{width:'100%'}} src={"https://prc5.github.io/react-zoom-pan-pinch/static/media/example.25094e6d.jpg"} alt="test" />
                      
                      */}
                              </TransformComponent>
                            </div>
                            <div id="productimagePreviewModal8" style={{textAlign: 'center',position:'absolute',bottom:20,width:'65%'}}>
                              <Button
                                className="zoomActionButton"
                                onClick={zoomOut}
                                id="productimagePreviewModal9"
                              >

                                <img src={zoomOutIcon} alt="zoomOutIcon" id="productimagePreviewModal10"/>
                              </Button>
                              <Button
                                className="zoomActionButton"
                                onClick={zoomIn}
                                id="productimagePreviewModal11"
                              >
                                <img src={zoomInIcon} alt="zoomOutIcon" id="productimagePreviewModal12"/>
                              </Button>

                              {/*<Button
                                className="resetZoomButton"
                                onClick={resetTransform}
                              >
                                Reset
                              </Button>*/}
                            </div>

                          </React.Fragment>
                        )}
                      </TransformWrapper>
                    </div>
                    <div style={{margin: 20}} style={{width:'30%',paddingLeft:50}} id="productimagePreviewModal13">
                      <div style={{marginTop: 50}} id="productimagePreviewModal14"/>
                      <div className="productTitleName" id="productimagePreviewModal15">

                        {productDetail &&  productDetail.productCategoryType === 'Radiology' ? productDetail.deviceSaleName : (productDetail.productName?productDetail.productName:'N/A')}

                      </div>
                      <div className="productPriceText" id="productimagePreviewModal16">
                  
                      
                        {productDetail && productDetail.priceUSD
                          ? 'US $'+ priceWithDecimal (productDetail.priceUSD)
                          : ''}
                      </div>
                      {productDetail &&
                        productDetail.productCategoryType === 'Radiology'
                        ? <div>
                            <p className="productDeviceLocation" id="productimagePreviewModal20">
                              Device Type:

                              {productDetail && productDetail.deviceType
                                ? productDetail.deviceType
                                : ''}

                            </p>
                            <p className="productDeviceLocation" id="productimagePreviewModal21">
                              Location:
                              {' '}
                              {productDetail && productDetail.deviceType
                                ? productDetail.location
                                : ''}
                            </p>

                          </div>:<div id="productimagePreviewModal17">
                            <p className="productDeviceLocation" id="productimagePreviewModal18">
                              Product Type:

                              {productDetail &&
                                productDetail.productCategoryType
                                ? productDetail.productCategoryType
                                : ''}

                            </p>
                            <p className="productDeviceLocation" id="productimagePreviewModal19">
                              Category:
                              {' '}
                              {productDetail && productDetail.category
                                ? productDetail.category
                                : ''}
                            </p>

                          </div>
                        }

                      {productDetail &&
                        productDetail.productImageList &&
                        productDetail.productImageList.length > 0 &&
                        <div className="rightSideImgBox" id="productimagePreviewModal22">

                          {productDetail.productImageList.map ((item, i) => (
                            <div
                              key={i}
                              className={
                                'productListImg ' +
                                  (imgIndex === i ? 'activeBorderCircle' : ' ')
                              }
                              onClick={() => this.props.showImage (i)}
                              id={`productimagePreviewModalproductListImg${i}`}
                            >
                              <img
                                src={item ? item : productDetails}
                                onError={e => {
                                  e.target.onerror = null;
                                  e.target.src = productDetails;
                                }}
                                alt="products"
                                id={`productimagePreviewModalproductListImgproducts${i}`}
                              />

                            </div>
                          ))}
                        </div>}
                    </div>
                  </div>

                </div>}
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default ProductZoomDetail;
