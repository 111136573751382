import {getUserNotificationHistory,
  messengerGetMutedNotificationsList,
  messengerMuteNotifications,
  messengerUnMuteNotifications
  } from '../services/Authservice';
import {UPDATE_NOTIFICATIONS} from './../reducers/NotificationsReducer';
import {createNotification} from '../constants/notificationtoast';
import { Loading} from './../actions/LoadingAction';
import {getMessage} from '../constants/localMessage/errorMessage';

export const getUserNotificationHistoryFunc = data => {
  return dispatch => {
    // dispatch(Loading(true));
    getUserNotificationHistory(data)
      .then (res => {
        if (res && res.status === 200) {
          dispatch ({type: UPDATE_NOTIFICATIONS, payload: res.data.result.Data});
        } else {
          if (res && res.data && res.data.result) {
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch(Loading(false));
      })
      .catch (err => {
        dispatch(Loading(false));
        console.log (err);
        throw err;
      });
    
  };
};

// get list of muted  Muted chat
export const messengerGetMutedNotificationsListFunc = (data, cb) => {
  return dispatch => {
    // dispatch(Loading(true));
    messengerGetMutedNotificationsList(data)
      .then (res => {
        // console.log("messengerGetMutedNotificationsList>>>>>> ressss", res);
        if (res && res.status === 200) {
          cb(res.data.result.Data);
          // createNotification ('success',getMessage(res.data.result.responseCode));
          // dispatch ({type: UPDATE_NOTIFICATIONS, payload: res.data.result.Data});
        } else {
          if (res && res.data && res.data.result) {
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        // dispatch(Loading(false));
      })
      .catch (err => {
        // dispatch(Loading(false));
        console.log (err);
        throw err;
      });
    
  };
};

// Mute notifications
export const messengerMuteNotificationsFunc = (data, cb) => {
  return dispatch => {
    dispatch(Loading(true));
    messengerMuteNotifications(data)
      .then (res => {
        if (res && res.status === 200) {
          cb(res)
          createNotification ('success',getMessage(res.data.result.responseCode));
          // dispatch ({type: UPDATE_NOTIFICATIONS, payload: res.data.result.Data});
        } else {
          if (res && res.data && res.data.result) {
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch(Loading(false));
      })
      .catch (err => {
        dispatch(Loading(false));
        console.log (err);
        throw err;
      });
   
  };
};

// Unmute notifications
export const messengerUnMuteNotificationsFunc = (data, cb) => {
  return dispatch => {
    dispatch(Loading(true));
    messengerUnMuteNotifications(data)
      .then (res => {
        if (res && res.status === 200) {
          cb(res)
          createNotification ('success',getMessage(res.data.result.responseCode));
          // dispatch ({type: UPDATE_NOTIFICATIONS, payload: res.data.result.Data});
        } else {
          if (res && res.data && res.data.result) {
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch(Loading(false));
      })
      .catch (err => {
        dispatch(Loading(false));
        console.log (err);
        throw err;
      });
    
  };
};
