import React, {Component} from 'react';
import {
  Button,
  Progress
} from 'reactstrap';
import {MultiSelect} from 'primereact/multiselect';


import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import notiicon from './../../images/notiicon.png';
import searchGroup from './../../images/searchGroup.png';
import requesticons from './../../images/requesticons.png';
import work from './../../images/profile/work.svg';
import star from './../../images/serviceOnDemandProfile/star.png';
import roman from './../../images/serviceOnDemandSearch/roman.png';
import {GetServiceProfile , GetServiceProviders} from '../../actions/serviceOnDemandAction';
import * as routes from '../../constants/routes';
import profilePlaceholder from '../../images/profile_placeholder.png';
import StarRatingComponent from 'react-star-rating-component';
import moment from 'moment';

import './serviceOnDemandSidebar.css';
import serviceAreaList from '../../constants/localJSON/serviceAreaList.json';
import serviceOffered from '../../constants/localJSON/serviceOffered.json';

class ServiceOnDemandSidebarPage extends Component {
  constructor (props) {
    super (props);
    this.state = {
      ServiceAreaDropDown: false,
      userProfile: [],
      myRequestsList: [],
      businessDetails: [],
      Dropdownvalue:'',
      Dropdownvalue2:'',
      searchString:''
    };
  }
  componentWillReceiveProps (nextProps) {
  

    if (
      nextProps &&
      nextProps.serviceProfile &&
      nextProps.serviceProfile.myserviceRequest
    ) {
      this.setState (
        {
          myRequestsList: nextProps.serviceProfile.myserviceRequest.slice (
            0,
            4
          ),
        },
        () => {
        }
      );
    }
    if (
      nextProps &&
      nextProps.serviceProfile &&
      nextProps.serviceProfile.myRequestRecieved
    ) {
      this.setState ({
        myRequestsRecievedList: nextProps.serviceProfile.myRequestRecieved.slice (
          0,
          4
        ),
      });
    }

    if (
      nextProps &&
      nextProps.serviceProfile &&
      nextProps.serviceProfile.serviceProfile
    ) {
      this.setState ({
        userProfile: nextProps.serviceProfile.serviceProfile,
        businessDetails: nextProps.serviceProfile.businessDetails,
      });
    }
  }
  componentDidMount () {
    let loginUser = localStorage.getItem ('loginuser');
    if (loginUser) {
      let getuserInfo = JSON.parse (loginUser);
      const sendRequest = {
        data: {
          docID: getuserInfo.docID,
        },
      };

      this.props.GetServiceProfile (sendRequest);
    }
  }
  serviceArea () {
    this.setState ({
      ServiceAreaDropDown: !this.state.ServiceAreaDropDown,
    });
  }

  searchServiceProviders = () => {
    this.setState (
      {
        isSearch: true,
      },
      () => {
        let sendRequest = {
          data: {
            serviceArea: this.state.Dropdownvalue,
            servicesOffered: this.state.Dropdownvalue2,
            searchString: this.state.searchString,
          },
        };
        this.props.GetServiceProviders (sendRequest);
      }
    );
    // this.setState ({serviceArea: '', servicesOffered: '', searchString: ''});
  };

  render () {
    const {
      userProfile,
      businessDetails,
      myRequestsRecievedList,
      myRequestsList,
    } = this.state;
    return (
      <div>
        <div className="sersidefirres">
          <div className="sersidsecondro">
            <div style={{display: 'flex'}}>
              <div style={{paddingLeft: 43}}>
                <img alt="" src={notiicon} />
              </div>
              <div style={{paddingLeft: 12}}>
                <img alt="" src={requesticons} style={{width: 36}} />
              </div>
              <div style={{paddingLeft: 15}}>
                <Button
                  className="sersidebut"
                  onClick={() =>
                    this.props.history.push (
                      routes.SERVICEONDEMAND_SERVICE_PROFILE +
                        `/${userProfile.serviceProfile.docID}`
                    )}
                >
                  View Profile
                </Button>
              </div>
            </div>
            <div style={{paddingRight: 30}}>
              <img
                alt=""
                src={
                  userProfile && userProfile.profilePictureURL
                    ? userProfile.profilePictureURL
                    : profilePlaceholder
                }
                nError={e => {
                  e.target.onerror = null;
                  e.target.src = profilePlaceholder;
                }}
                className="sersiduimg"
              />
            </div>
          </div>
          <div className="sersidJohnDo">
            {userProfile && userProfile.title ? userProfile.title : 'N/A'}
            {' '}{' '}
            {' '}
            {userProfile && userProfile.displayName
              ? userProfile.displayName
              : (userProfile && userProfile.firstName
                  ? userProfile.firstName
                  : '') +
                  ' ' +
                  (userProfile && userProfile.lastName
                    ? userProfile.lastName
                    : '')}
          </div>
          <div className="sersidrtified">
            <img alt="" src={work} style={{width: 30, marginRight: 14}} />
            Certified Neurologists
          </div>
          <div style={{textAlign: 'center'}}>
           <StarRatingComponent
              name={'rating'}
              starCount={5}
              value={
                userProfile &&
                  userProfile.serviceProfile &&
                  userProfile.serviceProfile.totalServicesOffered
                  ? userProfile.serviceProfile.totalServicesOffered
                  : 0
              }
              emptyStarColor={'#E4E4E4'}
              starColor={'#F5D028'}
              style={{fontSize: '80px'}}
              // onStarClick={this.onStarClick}
            />
          </div>
          {/* <div style={{textAlign: 'center'}}>
            <img alt="" src={star} className="sersidratst" />
                </div>*/}
          <div className="sersidlin" />
          <div className="sersidmypl">
            My Placed Requests
          </div>
          <div style={{textAlign: '-webkit-center'}}>
            {myRequestsList.map ((item, i) => (
              <div className="sersidmyreqre" key={i}>
                <div>
                  <div className="sersidddare">
                    {item && item.serviceDate
                      ? moment (item.serviceDate).format ('DD/MM/YYYY')
                      : ''}
                  </div>
                  <div className="sersidseij">
                    {' '}{item && item.title ? item.title : 'N/A'}
                  </div>
                </div>
                <div>
                  <div>  <Progress value="45" style={{width: 113}} /></div>
                  <div className="sersidawadr">
                    {/*<img alt="" src={yellowdot} />*/}
                    <div className={item.status === "draft" ?'greencircle':''}></div>
                      <div className={item.status === "awarded" ?'yellowcircle':''}></div>
                      <div className={item.status === "accepted" ?'yellowcircle':''}></div>
                      <div className={item.status === "job completed" ?'orangecircle':''}></div>
                      <div className={item.status === "rejected" ?'orangecircle':''}></div>
                      <div className={item.status === "pending" ?'whitecircle':''}></div>
                      <div className={item.status === "confirmed" ?'lightgreen':''}></div>
                   <div style={{paddingLeft:5,textTransform:'capitalize'}}>  {item && item.status ? item.status : 'N/A'}</div>
                  </div>
                </div>
              </div>
            ))}

            <div>
              <Button className="sersideview">View All</Button>
            </div>
          </div>
          <div className="sersidchProv">
            Search Providers
          </div>
          <div style={{textAlign: 'center'}}>
          <div>
            <input
            className="sersidinpu"
            placeholder="Enter search term"
            name="searchString"
            value={this.state.searchString}
            onChange={e =>
              this.setState ({
                searchString: e.target.value
            })}
            style={{color: '#FFFFFF'}}
          />
          </div>

          <div className="multipleselect">
  

          <MultiSelect
                      maxSelectedLabels={1}
                      placeholder="Select Service Area"
                      value={this.state.Dropdownvalue}
                      options={serviceAreaList}
                      onChange={e => this.setState ({Dropdownvalue: e.value})}
                    />
        </div>
        <div className="multipleselect">

        <MultiSelect
                      maxSelectedLabels={1}
                      placeholder="Select Service Offered"
                      value={this.state.Dropdownvalue2}
                      options={serviceOffered}
                      onChange={e => this.setState ({Dropdownvalue2: e.value})}
                    />

  
        </div>

           

           
            <div style={{textAlign:'right'}}>
              <Button className="sersidebutsea" onClick={() => this.searchServiceProviders ()}>
                <img alt="" src={searchGroup} style={{width: 20}} />
                SEARCH
              </Button>
            </div>
          </div>
          <div className="sersidemyuESTF">
            MY LATEST FEEDBACK
          </div>
          <div style={{textAlign: '-webkit-center'}}>
            <div className="sersidefeed">
              <div style={{display: 'flex', paddingLeft: 10}}>
                <div>
                  {' '}<img alt="icon" src={roman} className="sersidefeedimg" />
                </div>
                <div>
                  <div className="sersideeve">Beverly Walsh</div>
                  <div className="sersidereqw">Request #100341</div>
                </div>
              </div>
              <div style={{paddingRight: 19}}>
                <div className="sersidemyda">11/24/19</div>
                <div className="sersidemyda">
                  <img alt="" src={star} className="sersidemyst" />
                </div>
              </div>
            </div>
            <div className="sersidefeed">
              <div style={{display: 'flex', paddingLeft: 10}}>
                <div>
                  {' '}<img alt="" src={roman} className="sersidefeedimg" />
                </div>
                <div>
                  <div className="sersideeve">Beverly Walsh</div>
                  <div className="sersidereqw">Request #100341</div>
                </div>
              </div>
              <div style={{paddingRight: 19}}>
                <div className="sersidemyda">11/24/19</div>
                <div className="sersidemyda">
                  <img alt="" src={star} className="sersidemyst" />
                </div>
              </div>
            </div>
            <div className="sersidefeed">
              <div style={{display: 'flex', paddingLeft: 10}}>
                <div>
                  {' '}<img alt="" src={roman} className="sersidefeedimg" />
                </div>
                <div>
                  <div className="sersideeve">Beverly Walsh</div>
                  <div className="sersidereqw">Request #100341</div>
                </div>
              </div>
              <div style={{paddingRight: 19}}>
                <div className="sersidemyda">11/24/19</div>
                <div className="sersidemyda">
                  <img alt="" src={star} className="sersidemyst" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    serviceProfile: state.serviceOnDemandReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      GetServiceProfile,
      GetServiceProviders
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  ServiceOnDemandSidebarPage
);
