import React, { Component } from 'react'
import { Button, Modal, ModalBody } from 'reactstrap'

import profilePlaceholder from '../../../images/profile_placeholder.png'
import evruslogobusi from '../../../images/icons/evruslogobusi.png'
import leftbackarrow from '../../../images/icons/leftbackarrow.png'

import searchIcon from '../../../images/icons/search.svg'
import { getUserDetail, getBusinessDetail } from '../../../actions/contacts'
import { register, uploadBusinessProfile } from '../../../actions/Authaction'

import businessAdmin from '../../../images/icons/yellowcrown.png'
import nonAdmin from '../../../images/icons/nonAdmin.png'

import crossIcon from '../../../images/whiteCloseIcon.svg'

import RightSideContainer from '../../../components/UI/rightSideContainer/rightSideContainer'
import UserContactListPage from '../../../components/contacts/usercontacts/userList'

import { CapitalizeFirstLetter } from '../../../constants/helper'
import { createNotification } from '../../../constants/notificationtoast'
import Resizer from 'react-image-file-resizer'
import ImageCrop from '../../UI/ImageCrop'
import { IMAGE_TYPE_ERROR } from '../../../constants/errorMessages'
import SelectInput from '../../InputCustom/selectInput/selectDropdown'
import { yearListing } from '../../../constants/localJSON/yearDropdown'

import { ShowNameAccordingToLength } from '../../../utility/stringFunctions'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import './businessDetailEdit.css'

class BusinessEditPage extends Component {
  // defining state
  constructor (props) {
    super(props)
    this.state = {
      businessId: '',
      establishedYearDropdown: yearListing(),
      registeredBusinessName: '',
      displayedBusinessName: '',
      businessWebsiteUrl: '',
      businessEmailAddress: '',
      businessPhoneNumber: '',
      yearEstablished: '',
      isSecondSubmit: false,
      invalidEmailFormat: false,
      businessAddress_1: '',
      businessAddress_2: '',
      businessPostalCode: '',
      businessState: '',
      businessCity: '',
      businessCountry: '',
      businessProfilePictureURL: '',
      loadedImage: '',
      memberList: [],
      adminAction: false,
      removeFromBusinessAction: false,
      memberInfo: '',
      searchString: ''
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if (nextProps.contacts && nextProps.contacts.businessDetail) {
        let getData = nextProps.contacts.businessDetail
        if (getData) {
          // console.log(getData);

          this.setState({
            businessAddress_1: getData.businessAddress_1,
            businessAddress_2: getData.businessAddress_2,
            businessCity: getData.businessCity,
            businessCountry: getData.businessCountry,
            businessEmailAddress: getData.businessEmailAddress,
            businessPhoneNumber: getData.businessPhoneNumber,
            businessPostalCode: getData.businessPostalCode,
            businessState: getData.businessState,
            businessWebsiteUrl: getData.businessWebsiteUrl,
            displayedBusinessName: getData.displayedBusinessName,
            registeredBusinessName: getData.registeredBusinessName,
            yearEstablished: getData.yearEstablished,

            businessProfilePictureURL: getData.businessProfilePictureURL,
            memberList: getData.memberList
          })
        }
      }
    }
  }

  componentDidMount () {
    let geturl = this.props.match.params
    if (geturl && geturl.businessId) {
      this.setState({
        businessId: geturl.businessId
      })

      let sendRequest = {
        data: {
          // getCompanyDetails: true,
          // getCompanyAddress:true,
          // getContactDetails:true,
          // getMemberList:true,
          // getAdditionalDetails: true,
          businessDocID: geturl.businessId
        }
      }
      this.props.getBusinessDetail(sendRequest)
    }
  }

  updateBusiness () {
    const {
      businessAddress_1,
      businessAddress_2,
      businessCountry,
      businessCity,
      businessState,
      businessPostalCode,
      businessEmailAddress,
      businessPhoneNumber,
      businessWebsiteUrl,
      displayedBusinessName,
      registeredBusinessName,
      yearEstablished,
      businessId
    } = this.state

    const isvalid2 =
      !displayedBusinessName ||
      !registeredBusinessName ||
      !businessWebsiteUrl ||
      !businessEmailAddress ||
      !businessPhoneNumber ||
      !yearEstablished ||
      !businessCity ||
      !businessState ||
      !businessPostalCode ||
      !businessCountry ||
      !businessAddress_1

    this.setState({
      isSecondSubmit: true
    })

    if (businessEmailAddress) {
      const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g
      const result = pattern.test(businessEmailAddress)

      if (!result) {
        this.setState({
          invalidEmailFormat: true
        })
      } else {
        this.setState({
          invalidEmailFormat: false
        })
      }
    }

    if (isvalid2) {
      return false
    }

    let sendRequest = {
      data: {
        docID: businessId,
        businessAddress_1: businessAddress_1,
        businessAddress_2: businessAddress_2,
        businessCity: businessCity,
        businessCountry: businessCountry,
        businessEmailAddress: businessEmailAddress,
        businessPhoneNumber: businessPhoneNumber,
        businessPostalCode: businessPostalCode,
        businessState: businessState,
        businessWebsiteUrl: businessWebsiteUrl,
        displayedBusinessName: displayedBusinessName,
        registeredBusinessName: registeredBusinessName,
        yearEstablished: yearEstablished
      }
    }

    this.props.register(sendRequest)
  }

  onChangebusinesProfile = e => {
    let files = e.target.files
    let response = files[0].size / 1000000
    if (response && response > 5) {
      createNotification(
        'warning',
        'The uploaded file is too large. Profile can only be a maximum of 5 MB.'
      )
      return
    }

    const file = e.target.files[0]
    const fileType = file['type']
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png']
    // Check If file is not an image
    if (!validImageTypes.includes(fileType)) {
      // console.log("rfreg")
      createNotification('warning', IMAGE_TYPE_ERROR)
    } else {
      const reader = new FileReader()
      reader.addEventListener('load', () => {
        this.setState({
          loadedImage: reader.result,
          filename: files[0].name
        })
      })
      reader.readAsDataURL(e.target.files[0])
    }
  }

  uploadUserImage (croppedImage) {
    Resizer.imageFileResizer(
      croppedImage,
      300,
      300,
      'JPEG',
      400,
      0,
      uri => {
        const { filename, businessId } = this.state
        this.setState(
          {
            base64Image: uri
          },
          () => {
            let sendRequest = {
              data: {
                businessProfilePicture: uri,
                businessProfilePictureFileName: filename,
                businessProfilePictureContentType: 'image/jpeg',
                docID: businessId
              }
            }
            this.props.uploadBusinessProfile(sendRequest)
          }
        )
      },
      'base64'
    )
  }

  doAdminaction (item) {
    this.setState({
      adminAction: true,
      memberInfo: item
    })
  }
  closeModal () {
    this.setState({
      adminAction: false,
      removeFromBusinessAction: false
    })
  }

  render () {
    const {
      businessAddress_1,
      businessAddress_2,
      businessCountry,
      businessCity,
      businessState,
      businessPostalCode,
      businessEmailAddress,
      businessPhoneNumber,
      businessWebsiteUrl,
      displayedBusinessName,
      registeredBusinessName,
      yearEstablished,
      establishedYearDropdown,
      businessProfilePictureURL,
      isSecondSubmit,
      invalidEmailFormat,
      loadedImage,
      memberList,
      adminAction,
      memberInfo,
      removeFromBusinessAction,
      searchString
    } = this.state

    let filterContacts = searchString
      ? memberList.filter(
        row =>
          (row &&
              ShowNameAccordingToLength(row.firstName + ' ' + row.lastName)
                .toLowerCase()
                .indexOf(searchString.toLowerCase()) > -1) ||
            (row &&
              row.searchHandle &&
              row.searchHandle
                .toLowerCase()
                .indexOf(searchString.toLowerCase()) > -1)
      )
      : memberList

    return (
      <div className='flex '>
        <div className='EditbusinessTopContainer'>
          <div className='editBusinessContainer'>
            <div style={{ textAlign: 'right', marginBottom: 30 }}>
              <Button
                className='editBusinessProfilebackbutton'
                onClick={() => this.props.history.goBack()}
                data-rh='Go back'
              >
                <div>
                  <img src={leftbackarrow} alt='editbackIcon' />
                </div>
                Go <span> Back</span>
              </Button>
            </div>
            <div className='EditBusinessBoxRow'>
              <div>
                <div className='editBusinessTitle'>Edit Business Profile</div>
                <div className='primaryBusinessSubTitle'>
                  <b>PRIMARY</b> DETAILS
                </div>
              </div>

              <div className='businessRightSideProfileBox'>
                <div className='profileImageDiv'>
                  <img
                    style={{ width: '100%', height: '100%', borderRadius: 86 }}
                    // src={profilePlaceholder}
                    src={
                      businessProfilePictureURL || evruslogobusi
                    }
                    onError={e => {
                      e.target.onerror = null
                      e.target.src = evruslogobusi
                    }}
                    alt='profilePlaceholder'
                  />
                </div>
                <div>
                  <div className='displayProfilePicTitle'>
                    Display Profile Picture
                  </div>
                  <div className='uploadFileTitle'>
                    Please upload a photo of type JPEG or PNG
                  </div>
                  <label className='imguseruploadButton' data-rh='Select File'>
                    <input
                      type='file'
                      name='profilePicture'
                      onChange={e => this.onChangebusinesProfile(e)}
                      accept='image/*'
                    />
                    Upload
                  </label>
                  {loadedImage && (
                    <ImageCrop
                      image={loadedImage}
                      onSubmitClick={croppedImage => {
                        // console.log(croppedImage);
                        this.setState({
                          loadedImage: ''
                        })

                        this.uploadUserImage(croppedImage)
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div style={{ padding: 10 }}>
              <div className='inlineEditBoxes'>
                <div>
                  <div className='businessFieldsLabel'>
                    Registered Business Name
                  </div>
                  <input
                    className='editBusinessNameInput'
                    type='text'
                    placeholder='Business Name'
                    value={CapitalizeFirstLetter(registeredBusinessName)}
                    onChange={e =>
                      this.setState({
                        registeredBusinessName: e.target.value
                      })
                    }
                  />
                  {isSecondSubmit && !registeredBusinessName && (
                    <p
                      className='validationerrorMessage'
                      style={{ textAlign: 'left' }}
                    >
                      Business name is required *
                    </p>
                  )}
                </div>

                <div>
                  <div className='businessFieldsLabel'>Display Name As</div>
                  <input
                    className='editBusinessNameInput'
                    type='text'
                    placeholder='Business Display Name'
                    value={CapitalizeFirstLetter(displayedBusinessName)}
                    onChange={e =>
                      this.setState({
                        displayedBusinessName: e.target.value
                      })
                    }
                  />
                  {isSecondSubmit && !displayedBusinessName && (
                    <p
                      style={{ textAlign: 'left' }}
                      className='validationerrorMessage'
                    >
                      Display business name is required *
                    </p>
                  )}
                </div>
              </div>

              <div className='inlineEditBoxes'>
                <div>
                  <div className='businessFieldsLabel'>Year Established</div>
                  <div className='editBusinessdropdown'>
                    <SelectInput
                      items={establishedYearDropdown}
                      value={yearEstablished}
                      className='editBusinessYearDropdown'
                      dropDownContainerClass='editBusinessYearContainerDropdown'
                      onChange={value => {
                        this.setState({
                          yearEstablished: value
                        })
                      }}
                    />
                    {isSecondSubmit && !yearEstablished && (
                      <p className='validationerrorMessage'>
                        Year established is required *
                      </p>
                    )}
                  </div>
                </div>

                <div>
                  <div className='businessFieldsLabel'>Website</div>

                  <input
                    style={{ width: 359 }}
                    className='editBusinessOtherInputs'
                    type='text'
                    placeholder='Website'
                    value={businessWebsiteUrl}
                    onChange={e =>
                      this.setState({
                        businessWebsiteUrl: e.target.value
                      })
                    }
                  />

                  {isSecondSubmit && !businessWebsiteUrl && (
                    <p className='validationerrorMessage'>
                      Business web site Url is required *
                    </p>
                  )}
                </div>
                <div>
                  <div className='businessFieldsLabel'>Business Email</div>

                  <input
                    style={{ width: 259 }}
                    className='editBusinessOtherInputs'
                    type='text'
                    placeholder='Business Email'
                    value={businessEmailAddress}
                    onChange={e =>
                      this.setState({
                        businessEmailAddress: e.target.value
                      })
                    }
                  />
                  {isSecondSubmit && !businessEmailAddress && (
                    <p className='validationerrorMessage'>
                      Business Email is required *
                    </p>
                  )}
                  {isSecondSubmit && invalidEmailFormat && (
                    <p className='validationerrorMessage'>
                      {' '}
                      Invalid email format *
                    </p>
                  )}
                </div>
                <div>
                  <div className='businessFieldsLabel'>Phone Number</div>

                  <input
                    style={{ width: 231 }}
                    className='editBusinessOtherInputs'
                    type='text'
                    placeholder='Business Phone Number'
                    value={businessPhoneNumber}
                    maxLength='10'
                    pattern='[0-9]*'
                    onChange={evt => {
                      const businessPhoneNumber = evt.target.validity.valid
                        ? evt.target.value
                        : this.state.businessPhoneNumber
                      this.setState({
                        businessPhoneNumber
                      })
                    }}
                  />
                  {isSecondSubmit && !businessPhoneNumber && (
                    <p className='validationerrorMessage'>
                      Business phone number is required *
                    </p>
                  )}
                </div>
              </div>

              <div className='inlineEditBoxes'>
                <div>
                  <div className='businessFieldsLabel'>
                    Registered Address Line 1
                  </div>
                  <input
                    className='editBusinessNameInput'
                    type='text'
                    placeholder='Registered Address Line 1'
                    value={businessAddress_1}
                    onChange={e =>
                      this.setState({
                        businessAddress_1: e.target.value
                      })
                    }
                  />
                  {isSecondSubmit && !businessAddress_1 && (
                    <p className='validationerrorMessage'>
                      Registered Address is required *
                    </p>
                  )}
                </div>

                <div>
                  <div className='businessFieldsLabel'>
                    Registered Address Line 2
                  </div>
                  <input
                    className='editBusinessNameInput'
                    type='text'
                    placeholder='Registered Address Line 2'
                    value={businessAddress_2}
                    onChange={e =>
                      this.setState({
                        businessAddress_2: e.target.value
                      })
                    }
                  />
                </div>
              </div>

              <div className='inlineEditBoxes'>
                <div>
                  <div className='businessFieldsLabel'>
                    Country of Establishment
                  </div>

                  <input
                    style={{ width: 284 }}
                    className='editBusinessOtherInputs'
                    type='text'
                    placeholder='Enter Country of Establishment'
                    value={businessCountry}
                    onChange={e =>
                      this.setState({
                        businessCountry: e.target.value
                      })
                    }
                  />
                  {isSecondSubmit && !businessCountry && (
                    <p className='validationerrorMessage'>
                      Country of Establishment is required *
                    </p>
                  )}
                  {/* <div className="editBusinessdropdown">

                    <Dropdown
                      style={{width: 284, marginLeft: 0, height: 49}}
                      className="dropdownBoxContainer"
                      options={establishedYearDropdown}
                      // onChange={e => this.setState ({degreeType: e.value})}
                    />
                    </div> */}
                </div>

                <div>
                  <div className='businessFieldsLabel'>City</div>

                  <input
                    style={{ width: 310 }}
                    className='editBusinessOtherInputs'
                    type='text'
                    placeholder='Enter City'
                    value={businessCity}
                    onChange={e =>
                      this.setState({
                        businessCity: e.target.value
                      })
                    }
                  />

                  {isSecondSubmit && !businessCity && (
                    <p className='validationerrorMessage'>City is required *</p>
                  )}
                </div>
                <div>
                  <div className='businessFieldsLabel'>State</div>

                  <input
                    style={{ width: 271 }}
                    className='editBusinessOtherInputs'
                    type='text'
                    placeholder='Enter State'
                    value={businessState}
                    onChange={e =>
                      this.setState({
                        businessState: e.target.value
                      })
                    }
                  />
                  {isSecondSubmit && !businessState && (
                    <p className='validationerrorMessage'>
                      State is required *
                    </p>
                  )}
                </div>
                <div>
                  <div className='businessFieldsLabel'>Postal Code</div>

                  <input
                    style={{ width: 120 }}
                    className='editBusinessOtherInputs'
                    type='text'
                    placeholder='Postal Code'
                    value={businessPostalCode}
                    onChange={e =>
                      this.setState({
                        businessPostalCode: e.target.value
                      })
                    }
                  />
                  {isSecondSubmit && !businessPostalCode && (
                    <p className='validationerrorMessage'>
                      Postal Code is required *
                    </p>
                  )}
                </div>
              </div>

              <div
                className='inlineEditBoxes'
                style={{ marginTop: 20, justifyContent: 'flex-end' }}
              >
                <Button
                  className='saveChangesBusinessButton'
                  onClick={() => this.updateBusiness()}
                >
                  Save Changes
                </Button>
              </div>

              <div className='businessMemberPannel'>
                <div className='businessMemberPannel_capsule'>
                  ADMINS & <b> &nbsp; MEMBERS</b>
                </div>
                <div style={{ padding: 20 }}>
                  <div style={{ marginLeft: 10 }}>
                    <div
                      className='businessSearchMemberlabel'
                      style={{ marginLeft: 15 }}
                    >
                      Search to Add New Members:
                    </div>
                    <div className='businessmemberSearchbox'>
                      <img
                        src={searchIcon}
                        alt='searchIcon'
                        style={{ marginLeft: 15 }}
                      />
                      <input
                        className='inputSearchBox'
                        type='text'
                        placeholder='Search by name or handle'
                        value={searchString}
                        onChange={e =>
                          this.setState(
                            { searchString: e.target.value },
                            () => {}
                          )
                        }
                      />
                    </div>
                    <div
                      className='businessSearchMemberlabel'
                      style={{ marginLeft: 15, marginTop: 10, fontSize: 18 }}
                    >
                      Current Business Members:
                    </div>
                  </div>
                  <div
                    className='EditbusinessMemberListingHeader'
                    style={{ border: 'none' }}
                  >
                    <div style={{ width: 365 }} />
                    <div style={{ width: 170 }}>
                      <div
                        style={{ width: 80 }}
                        className='editBusinessMemberAction'
                      >
                        {' '}
                        <b style={{ color: '#F5D028' }}>ADMIN </b> RIGHTS
                      </div>
                      <div
                        style={{ width: 90 }}
                        className='editBusinessMemberAction'
                      >
                        <span>
                          {' '}
                          <b>REMOVE </b> FROM{' '}
                        </span>{' '}
                        BUSINESS
                      </div>
                    </div>
                  </div>
                  {filterContacts &&
                    filterContacts.length > 0 &&
                    filterContacts.map((item, i) => (
                      <div className='EditbusinessMemberListingHeader' key={i}>
                        <div className='editBusinessMemberInfoBox'>
                          <div className='memberProfileImgDiv'>
                            <img
                              src={
                                item && item.profilePictureURL
                                  ? item.profilePictureURL
                                  : profilePlaceholder
                              }
                              onError={e => {
                                e.target.onerror = null
                                e.target.src = profilePlaceholder
                              }}
                              style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: 86
                              }}
                              alt='profilePlaceholder'
                            />
                          </div>
                          <div className='memberProfileNameInfo'>
                            <div className='userFullname'>
                              {item && item.firstName
                                ? ShowNameAccordingToLength(
                                  item.firstName + ' ' + item.lastName
                                )
                                : ''}
                            </div>
                            {item && item.professionalTitle && (
                              <div className='userProfessionData'>
                                {' '}
                                {item && item.professionalTitle
                                  ? item.professionalTitle
                                  : ''}{' '}
                              </div>
                            )}
                            {item &&
                              item.memberInfo &&
                              item.memberInfo.position && (
                              <div className='userPositionData'>
                                {' '}
                                {item &&
                                  item.memberInfo &&
                                  item.memberInfo.position
                                  ? item.memberInfo.position
                                  : ''}
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          style={{
                            width: 170,
                            display: 'inline-flex',
                            textAlign: 'center'
                          }}
                        >
                          <div style={{ width: 80 }}>
                            {item &&
                            item.memberInfo &&
                            item.memberInfo.isAdmin ? (
                              <Button
                                  className='editBusinessactionButtons'
                                  onClick={() => {
                                    this.doAdminaction(item)
                                  }}
                                >
                                  <img
                                  style={{ width: 24, height: 15 }}
                                  src={businessAdmin}
                                  alt='admin'
                                  />
                                </Button>
                              ) : (
                                <Button
                                  onClick={() => {
                                    this.doAdminaction(item)
                                  }}
                                  className='editBusinessactionButtons'
                                >
                                  <img
                                    style={{ width: 24, height: 15 }}
                                    src={nonAdmin}
                                    alt='admin'
                                  />
                                </Button>
                              )}
                          </div>
                          <div style={{ width: 90 }}>
                            <Button
                              onClick={() =>
                                this.setState({
                                  removeFromBusinessAction: true,
                                  memberInfo: item
                                })
                              }
                              className='editBusinessactionButtons'
                            >
                              <img
                                style={{ width: 32, height: 32 }}
                                src={crossIcon}
                                alt='crossIcon'
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <Modal
              size='sm'
              isOpen={adminAction}
              centered
              toggle={() => this.closeModal()}
              // backdropClassName="contactModalBackdrops"
            >
              <ModalBody className='businessMemberAddConfirmationModal'>
                <div>
                  <p className='confirmTitle'>
                    {memberInfo &&
                    memberInfo.memberInfo &&
                    memberInfo.memberInfo.isAdmin
                      ? 'Remove '
                      : 'Grant '}
                    Admin Rights
                  </p>
                  <p className='confirmmsg'>
                    Are you sure you want to{' '}
                    {memberInfo &&
                    memberInfo.memberInfo &&
                    memberInfo.memberInfo.isAdmin
                      ? ' Remove '
                      : ' Grant '}{' '}
                    administrative rights to &nbsp;
                    {memberInfo && memberInfo.firstName
                      ? ShowNameAccordingToLength(
                        memberInfo.firstName + ' ' + memberInfo.lastName
                      )
                      : ''}
                    ?
                  </p>
                  <div className='buttonDiv'>
                    <Button
                      className='buttons noButton'
                      onClick={() => this.closeModal()}
                    >
                      No
                    </Button>
                    <Button
                      className='buttons yesButton'
                      onClick={() => {
                        setTimeout(() => {
                          this.closeModal()
                        }, 2000)
                        // this.props.addUserContactFunc (this.state.sendRequestData);
                      }}
                    >
                      Yes
                    </Button>
                  </div>
                </div>
              </ModalBody>
            </Modal>

            <Modal
              size='sm'
              isOpen={removeFromBusinessAction}
              centered
              toggle={() => this.closeModal()}
              // backdropClassName="contactModalBackdrops"
            >
              <ModalBody className='businessMemberAddConfirmationModal'>
                <div>
                  <p className='confirmTitle'>Remove From Business</p>
                  <p className='confirmmsg'>
                    Are you sure you want to remove &nbsp;
                    {memberInfo && memberInfo.firstName
                      ? ShowNameAccordingToLength(
                        memberInfo.firstName + ' ' + memberInfo.lastName
                      )
                      : ''}{' '}
                    from this business?
                  </p>
                  <div className='buttonDiv'>
                    <Button
                      className='buttons noButton'
                      onClick={() => this.closeModal()}
                    >
                      No
                    </Button>
                    <Button
                      className='buttons yesButton'
                      onClick={() => {
                        setTimeout(() => {
                          this.closeModal()
                        }, 2000)
                        // this.props.addUserContactFunc (this.state.sendRequestData);
                      }}
                    >
                      Yes
                    </Button>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          </div>
          <RightSideContainer>
            <UserContactListPage history={this.props.history} />
          </RightSideContainer>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.ContactsReducer
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getUserDetail,
      getBusinessDetail,
      register,
      uploadBusinessProfile
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessEditPage)
