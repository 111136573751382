
import baseService from './Baseservice';


export const contentManagementGetAllHighlightsService = (payload) => {
    return baseService.post('contentManagementGetAllHighlights', payload);
};



