import React, {Component} from 'react';
import {Button, Input, Progress} from 'reactstrap';
// import * as routes from '../../constants/routes';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import './settingsPage.css';
import proiconset from '../../images/proiconset.png';
import lockset from '../../images/lockset.png';
import notiset from '../../images/notiset.png';
import searcset from '../../images/searcset.png';
import wallset from '../../images/wallset.png';
import appabset from '../../images/appabset.png';
import contactset from '../../images/contactset.png';
import logoutset from '../../images/logoutset.png';
import userimg from '../../images/profile/userimg.png';
import union from '../../images/profile/union.png';

import AuthSettings from './authSetting/authSettings';
import UserProfile from './userProfile/userProfile';
import {checkAvailableAccess} from '../../constants/localMessage/errorMessage';
class SettingsPage extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
     openMenu: '',
     getResponseData:''
    };
  }

  openDetail(status){
    this.setState({
      openMenu:status
    })
  }
 
  componentDidMount(){
    let checkAccess= false;
    let getData=checkAvailableAccess ("pacs");


    if (getData && getData.available===true &&  getData.comingSoon===false &&  getData.maintenance===false) {
      checkAccess= true;
    }
  
    this.setState (
      {
        isHideContent: checkAccess,
        getResponseData: getData
      },
      () => {
        const {isHideContent} = this.state;
        if(isHideContent){

        }})
  }
  render () {
    const {openMenu, isHideContent ,getResponseData} = this.state;
    return (
      <div>
      <div style={{display: 'flex'}} className="mainSettingContainer">
        <div style={{padding: 20}} className="mainLeftView">
          <div className="seetings">
            Settings
          </div>
          <div style={{display: 'flex', marginTop: 44}}>
            <div className="mainViewBox" onClick={()=>this.openDetail('profile')}>
                <img alt="" src={proiconset} />
              <div className="boxtitle">
                Account<br />
                Settings
              </div>
            </div>
            <div className="mainViewBox" onClick={()=>this.openDetail('authentication')}>
              
                <img alt="" src={lockset}  />
             
              <div className="boxtitle">
                Authentication
                Settings
              </div>
            </div>
            <div className="mainViewBox">
             
                <img alt="" src={notiset}  />
             
              <div className="boxtitle">
                Notification
                Settings
              </div>
            </div>
            <div className="mainViewBox">
             
                <img alt="" src={searcset} />
             
              <div className="boxtitle">
                Search
                Settings
              </div>
            </div>
            <div className="mainViewBox">
           
                <img alt="" src={wallset} />
             
              <div className="boxtitle">
                Wallet
                Settings
              </div>
            </div>
            <div className="mainViewBox">
             
                <img alt="" src={appabset}  />
            
              <div className="boxtitle">
                About and <br />
                App Version
              </div>
            </div>
          </div>
          <div>
            <button className="contactsupp">
              <img alt="" src={contactset} style={{float: 'left'}} />
              Contact Support
            </button>
          </div>
          <div>
            <button className="logoutsupp">
              <img alt="" src={logoutset} style={{float: 'left'}} />
              Logout
            </button>
          </div>
      <div></div>
        </div>

       {openMenu &&  <div className="settdivider" />}
       {openMenu &&<div className="rightSideBox">

       {openMenu==='profile' &&  <UserProfile />}
       {openMenu==='authentication' &&  <AuthSettings />}
       </div>}


       

      

       
      </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    // service: state.serviceOnDemandReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators ({}, dispatch);
};

export default connect (mapStateToProps, mapDispatchToProps) (SettingsPage);
