import React, {Component} from 'react';
import {Button} from 'reactstrap';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import './paymentSuccess.css';
import {
  createNewWallet,
  checkRdeePayCheckWalletExists,
  checkPaymentStatus,
} from '../../../actions/Paymentaction';
import successPayment from '../../../images/pay/successPayment.png';
import cancelPayment from '../../../images/pay/cancelPayment.png';

import * as routes from '../../../constants/routes';

class PaymentSuccess extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      ispaymentStatus: null,
      count: 1,
    };
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps && nextProps.payment) {
      // if(nextProps.payment.userPaymentStatus){
      //   if( nextProps.payment.userPaymentStatus.status==="COMPLETED"){
      //     this.setState({
      //      ispaymentStatus: true
      //     })
      //    }else{
      //      this.setState({
      //        ispaymentStatus: false
      //       })
      //    }
      // }
    }
  }

  componentDidMount () {
  
    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
    if (loginUser) {
      this.setState ({
        userid: loginUser.docID,
      });
    }
    let geturl = this.props.match.params;
    // console.log (geturl);
    if (geturl && geturl.status) {
      if (geturl.status === 'success') {
        this.setState ({
          ispaymentStatus: true,
        });
      }
      if (geturl.status === 'failed') {
        this.setState ({
          ispaymentStatus: false,
        });
      }
    }
  }

  goBack () {
    this.props.history.push (routes.PAYMENT);
  }

  onVideoDown () {
    alert ('rgergrtgrtgrtgt');
  }

  render () {
    const {ispaymentStatus} = this.state;
    return (
      <div className="div-flex gradientColorPurpleBlue " id="settleSuccess">

        <div className="paymentStatusBoxContainer" id="settleSuccess1">

          <div className="paymentBox" id="settleSuccess2">

            <div className="paymentTitlesuccess" id="settleSuccess3">
              {ispaymentStatus
                ? <img className="successIcon" src={successPayment} alt={successPayment} id="settleSuccess4" />
                : <img
                    className="failedPaymentIcon"
                    src={cancelPayment}
                    alt="failed payment"
                    id="settleSuccess5"
                  />}

              <p style={{marginTop: 30}} id="settleSuccess6">
                {ispaymentStatus
                  ? 'Payment Successful!'
                  : 'Sorry, Payment Failed'}
              </p>
              {!ispaymentStatus &&
                <p className="failedDescription" id="settleSuccess7">
                  Please try again. If the issue persists, please contact our support team.
                </p>}
            </div>
            <p className="paymentGobacksuccess" id="settleSuccess8">Go back to </p>
            <div
              style={{display: 'flex', justifyContent: 'center', marginTop: 5}}
            >

              <Button
                className="returnRDEEButton"
                onClick={() => this.goBack ()}
                id="settleSuccess9"
              >
                {' '}R-DEE Pay
              </Button>
            </div>

          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    payment: state.PaymentReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      createNewWallet,
      checkRdeePayCheckWalletExists,
      checkPaymentStatus,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (PaymentSuccess);
