import React from 'react';
import styled from 'styled-components';
// import Load from "./Gear.gif";

// import VerticalCenter from 'components/VerticalCenter';

const LoaderDialog = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: rgba(0,0,0,0.5);
  z-index: 99999999999;
  display: ${(props) => props.open ? 'block' : 'none'};
`;

const Loader = (props) => (
  <LoaderDialog
    title=""
    open={props.visible}
  >
    <div style={{width: "123px" ,margin:"0 auto", height:"100px", borderRadius:"50%",padding:"0px",justifyContent:"center"}}>
      {props && props.loaderImage &&<img style={{position: "absolute",top: "50%", left: "50%", transform: "translate(-50%,-50%)", width: 300}} src={props.loaderImage} alt="RDEE" />}
    </div>
  </LoaderDialog>
);

// Loader.propTypes = {
//   visible: React.PropTypes.bool,
// };

export default Loader;
