import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Input } from 'reactstrap';
import './authenticationSetting.css';
import {EncryptFunction} from '../../../utility/encryption';
import locksetnim from '../../../images/icons/locksetnim.png';
import authsetimgd from '../../../images/icons/authsetimgd.png';
import lockset2 from '../../../images/lockset2.png';
import _ from 'lodash'
import {userChangePassword,trusonaAuthEnabledSetting ,resetUserReducer} from '../../../actions/Authaction';

class AuthSetting extends Component {
  constructor (props) {
    super (props);
    this.state = {
      isSubmit: false,
      editDetailDiv: false,
      detailDiv: true,
      changePassDiv: false,
      PassChanged: false,
      passLessAuth: false,
      password: '',
      repeatPassword: '',
      passwordError:'',
      currentPassword:'',
      isPasscheck: false,
      trusonaAuthEnabled: false
    };
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if (nextProps.userInfo) {
        if (nextProps.userInfo.changePassword) {
            this.openPassChanged();
        }
      }
        if (
          nextProps.userInfo &&
          nextProps.userInfo.dashboardprofile &&
          nextProps.userInfo.dashboardprofile.userSettings
        ) {
          if (
            nextProps.userInfo.dashboardprofile.userSettings &&
            nextProps.userInfo.dashboardprofile.userSettings.authSettings
          ) {
            const getData = nextProps.userInfo.dashboardprofile.userSettings.authSettings;
            this.setState({
              trusonaAuthEnabled: getData.trusonaAuthEnabled
            })

            // this.setState ({
            //   rdeeMessenger: getData.rdeeMessenger|| getData.rdeeMessenger!=="undefined"?getData.rdeeMessenger: false,
            //   rdeeMarketplace: getData.rdeeMarketplace || getData.rdeeMarketplace!=="undefined"?getData.rdeeMarketplace:false,
            //   rdeePay: getData.rdeePay || getData.rdeePay!=="undefined"?getData.rdeePay:false,
            //   otherNotifications: getData.otherNotifications || getData.otherNotifications!=="undefined"?getData.otherNotifications: false,
            //   emailAlerts: getData.emailAlerts || getData.emailAlerts!=="undefined"?getData.emailAlerts: false,
            // });
          }
        }

    }
  }
  componentDidMount(){
    this.props.resetUserReducer();
  }
  openChangePassDiv = () => {
    this.setState ({
      changePassDiv: true,
      passLessAuth: false,
      currentPassword:'',
      password:'',
      repeatPassword:'',
      isSubmit:false
    });
    this.props.resetUserReducer();
  };
  openPassChanged = () => {
    this.setState ({
      PassChanged: true,
      changePassDiv: false,
    });
    
  };
  openPassLessAuth = () => {
    this.setState ({
      passLessAuth: true,
      PassChanged: false,
      changePassDiv: false,
      isSubmit:false
    });
    this.props.resetUserReducer();
  };


  
  


  updatePassword () {
    // let email = _.get(this.props,'userinfo.user.Data.userDetails[0].personalDetails.emailAddress','')
    
    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
    let email = loginUser.emailAddress;

    const { password, repeatPassword, currentPassword} = this.state;
    // console.log("currentPassword>>>", currentPassword, "new password>>>", password, "email>>>", email)
   
    // console.log(email);
    let hashKeyCurrentPassword = EncryptFunction(email,currentPassword)
    let hashKeyNewPassword = EncryptFunction(email,password)
    // console.log("hashKeyCurrentPassword>>", hashKeyCurrentPassword, "hashKeyNewPassword>>>", hashKeyNewPassword)

    const patternpass = /^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    const result = patternpass.test (password);
    this.setState({
      isSubmit: true
    },()=>{

      if(!password || !repeatPassword ||  !currentPassword){
        return;
      }
    if (!result) {
      this.setState ({
        isPasscheck: true,
        passwordError:''
        
      });
      return;
    } else {
      // checkMobile = false;
      this.setState ({
        isPasscheck: false,
      });
    }

    if (password !== repeatPassword) {
      this.setState ({
        passwordError: 'Invalid Confirm Password',
      });
      return;
    }

    let sendRequest = {
      data: {
        currentPassword: hashKeyCurrentPassword ,
        newPassword: hashKeyNewPassword
      },
    };
    this.props.userChangePassword (sendRequest);
  })

  }

  updateTrusona(){
    const {trusonaAuthEnabled}= this.state;

    const sendRequest = {
      data: {
          trusonaAuthEnabled  : trusonaAuthEnabled
      }
    }
    this.props.trusonaAuthEnabledSetting(sendRequest)
  }

  render () {
  
    const {
      changePassDiv,
      password,
      repeatPassword,
      PassChanged,
      passLessAuth,
      isSubmit,
      currentPassword,
      isPasscheck,
      trusonaAuthEnabled
    } = this.state;
    return (
      <div>

        <div style={{display: 'flex'}}>
          <div className="authsettindiv">
            <div className="aauthmhding">
              {' '}
              AUTHENTICATION SETTINGS
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div>
                <img
                  alt=""
                  src={locksetnim}
                  style={{height: 30, width: 28, marginLeft: 30}}
                />
              </div>
              <div style={{cursor: 'pointer'}} onClick={this.openChangePassDiv}>
                <p className="changepassdjk">Change Password</p>
                <p className="autschsetimg">Change your Account Password</p>
              </div>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <div>
                <img
                  alt="authsetimgd"
                  src={authsetimgd}
                  style={{height: 30, width: 28, marginLeft: 30}}
                />
              </div>

              <div style={{cursor: 'pointer'}} onClick={this.openPassLessAuth}>
                <p className="changepassdjk">
                  Password-less Authentication
                </p>
                <div style={{display:'inline-flex',paddingLeft:10}}>
                   <div style={{width:10,marginTop:5,height:10 ,borderRadius:50,backgroundColor:trusonaAuthEnabled? '#4AEA87' :'#A5A5A5'}}></div>
                  <span style={{paddingLeft:5}} className="autschsetimg">{trusonaAuthEnabled? 'Enabled':'Disabled'}</span>
                </div>
              </div>
            </div>
          </div>
          {changePassDiv &&
            <div className="setingchangepassdiv">
              <div className="aautchangehmhding">
                {' '}
                CHANGE PASSWORD
              </div>
              <p className="entersetinghjk">Enter your new password</p>
              
            <div style={{paddingLeft:25,paddingRight:25}}>
              <div>
                <p className="changepassAuthSetting">Current Password</p>
                <Input
                  className="paddwordjkcbinput"
                  type="password"
                  name="currentPassword"
                  id="currentPassword"
                  placeholder="Current Password"
                  value={currentPassword}
                  onChange={e => this.setState ({currentPassword: e.target.value})}
                />
                {!currentPassword &&
                  isSubmit &&
                  <p
                    className="changepassAuthSetting validationerrorMessage"
                 
                  >
                    {' '}Current password is required *{' '}
                  </p>}
              </div>
              <div>
                <p className="changepassAuthSetting">New Password</p>
                <Input
                  className="paddwordjkcbinput"
                  type="password"
                  name="password"
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={e => this.setState ({password: e.target.value})}
                />
                {!password &&
                  isSubmit &&
                  <p
                    className=" changepassAuthSetting validationerrorMessage"
                  
                  >
                    {' '}New Password is required *{' '}
                  </p>}
              </div>
              <div>
                <p className="changepassAuthSetting">Repeat Password</p>
                <Input
                  className="paddwordjkcbinput"
                  type="password"
                  name="repeatPassword"
                  id="repeatPassword"
                  placeholder="Repeat Password"
                  value={repeatPassword}
                  onChange={e =>
                    this.setState ({repeatPassword: e.target.value})}
                />
                {!repeatPassword &&
                  isSubmit &&
                  <p
                    className="validationerrorMessage changepassAuthSetting"
                    
                  >
                    {' '}Repeat Password is required *{' '}
                  </p>}
              </div>
              {isPasscheck && <div className="seterrorpassword">
                Your password is not secure. A password
                must be a contain at least one lower-case
                letter, upper-case letter, a number and a
                special character.
              </div>}
              {this.state.passwordError && <div className="seterrorpassword">
                {this.state.passwordError}
                </div>}
              <div style={{textAlign: 'center'}}>
                <Button
                  className="settsavenesbutton"
                  onClick={()=>this.updatePassword()}
                >
                  Save New Password{' '}
                </Button>
              </div>
              </div>

            </div>}
          {PassChanged &&
            <div className="passchangedmaindiv">
              <div className="aautchangehmhding">
                {' '}
                CHANGE PASSWORD
              </div>
              <div style={{textAlign: 'center'}}>
                <img
                  alt="lockset2"
                  src={lockset2}
                  style={{width: 78, height: 104, marginTop: 40}}
                />
              </div>
              <div className="newpassavefkj">New Password Saved!</div>
              <div className="Younewpasswor">
                You new password has been
                updated successfully.
              </div>
            </div>}
          {passLessAuth &&
            <div className="passlexxauthmainduv">
              <div className="apasslesshgehmhding">
                {' '}
                PASSWORD-LESS AUTHENTICATION

                 
              </div>

              <div>
                  <div className="passwordLessOFFON">
                    OFF<span>/ON</span>
                  </div>
                <div>
                  <div className="TrusonaAut">Trusona Authentication 
                  
                  
                  <label className="trusonaSwitch">
                    <input
                      type="checkbox"
                      value={trusonaAuthEnabled}
                      checked={trusonaAuthEnabled}
                      onChange={() => {
                        this.setState ({
                          trusonaAuthEnabled: !trusonaAuthEnabled,
                        });
                      }}
                    />
                    <span className="trusonalider round" />
                  </label>
                  
                  </div>
                </div>
                <div style={{textAlign: 'center'}}>
                  <Button className="sascdvecgbutton"  data-rh="Save" onClick={()=>this.updateTrusona()}>
                    Save Changes
                  </Button>
                </div>
              </div>

            </div>}
        </div>
      </div>
    );
  }
}

// export default connect () (AuthSetting);
const mapStateToProps = state => {
  return {
    userInfo: state.Authreducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      userChangePassword,
      resetUserReducer,
      trusonaAuthEnabledSetting
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (AuthSetting);
