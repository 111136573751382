import React, { Component } from 'react';
// DO NOT USE THIS COMPONENT - ALWAYS USE ROUTE 'forgot-password'
import { Link, withRouter } from 'react-router-dom';
import {
  // Container,
  // Row,
  // Col,
  // Button,
  // Form,
  // FormGroup,
  // Label,
  // Input,
  Alert,
} from 'reactstrap';
import logo from '../../../images/logo/radiologex-logo.svg';
import lock from '../../../images/lock.svg';
import * as routes from '../../../constants/routes';
import { auth, db } from '../../../firebase';

// Styles
import './password-successful.scss';

const PasswordSuccessfullPage = ({ history }) => (
  <div className='div-flex-password-successful gradientColorPurpleBlue'>
    <SignUpForm history={history} />
  </div>
);

//################### Sign Up Form ###################
const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  perfectSearch: '',
  error: null,
  showingAlert: false,
};

//A Higher order function with prop name as key and the value to be assigned to
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class SignUpForm extends Component {
  //defining state
  state = {
    ...INITIAL_STATE,
  };

  // onChange = (propName, value) => {
  //   this.setState({
  //     [propName]: value
  //   });
  // };

  onSubmit = (event) => {
    const { username, email, passwordOne } = this.state;
    const { history } = this.props;
    auth
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      //it the above functions resolves, reset the state to its initial state values, otherwise, set the error object
      .then((authUser) => {
        //creating a user in the database after the sign up through Firebase auth API
        db.doCreateUser(authUser.user.uid, username, email)
          .then(() => {
            this.setState({
              ...INITIAL_STATE,
            });
            history.push(routes.DASH); //redirects to Home Page
          })
          .catch((error) => {
            this.setState(byPropKey('error', error));
            this.timer(); //show alert message for some seconds
          });
      })
      .catch((err) => {
        this.setState(byPropKey('error', err));
        this.timer(); //show alert message for some seconds
      });

    event.preventDefault(); //prevents refreshing
  };

  timer = () => {
    this.setState({
      showingAlert: true,
    });

    setTimeout(() => {
      this.setState({
        showingAlert: false,
      });
    }, 4000);
  };

  render() {
    const {
      firstname,
      lastname,
      email,
      passwordOne,
      mobile,
      error,
      showingAlert,
      file,
      displayName,
      perfectSearch,
    } = this.state;
    //a boolen to perform validation
    const isInvalid =
      passwordOne === '' || email === '' || firstname === '' || mobile === '';

    return (
      <>
        <div className='logo'>
          <img src={logo} />
        </div>
        <div className='password-successful-container'>
          {showingAlert && (
            <Alert color='danger' onLoad={this.timer}>
              {error.message}
            </Alert>
          )}

          <div className='lock-icon'>
            <img src={lock} alt={lock} />
          </div>
          <div className='informationSubmitpClass'>
            <p>Password Saved!</p>
          </div>

          <div className='password-successful-text'>
            <p>You password is saved successfully. Please proceed to Login.</p>
          </div>

          <form>
            <Link to={routes.SIGN_IN}>
              <button
                /* disabled={isInvalid} */ type='submit'
                className='password-successful-button'
              >
                Proceed to Login
              </button>
            </Link>
          </form>
        </div>
      </>
    );
  }
}

//################### Sign Up Link ###################
//used in the sign in when the user don't have an account registered yet
const SignUpLink = () => (
  <p>
    Don't have an account yet? <Link to={routes.SIGN_UP}>Register here</Link>
  </p>
);

//exports
export default withRouter(PasswordSuccessfullPage); //using a HoC to get access to history
export { SignUpForm, SignUpLink };

// <form onSubmit={this.onSubmit}>
//   <input
//     value={username}
//     onChange={e => this.setState(byPropKey("username", e.target.value))}
//     // onChange={e => this.onChange("username", e.target.value)}
//     type="text"
//     placeholder="Full Name"
//   />
//   <input
//     value={email}
//     onChange={e => this.setState(byPropKey("email", e.target.value))}
//     type="text"
//     placeholder="Email Address"
//   />
//   <input
//     value={passwordOne}
//     onChange={e =>
//       this.setState(byPropKey("passwordOne", e.target.value))
//     }
//     type="password"
//     placeholder="Password"
//   />
//   <input
//     value={passwordTwo}
//     onChange={e =>
//       this.setState(byPropKey("passwordTwo", e.target.value))
//     }
//     type="password"
//     placeholder="Confirm Password"
//   />
//   <button disabled={isInvalid} type="submit">
//     Sign Up
//   </button>

//   {error && <p>{error.message}</p>}
// </form>
