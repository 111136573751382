import moment from 'moment';
import timezone from 'moment-timezone';
export const timeSince = (date) => {

    var seconds = Math.floor(((new Date().getTime()/1000) - date/1000)),
    interval = Math.floor(seconds / 31536000);

    if (interval > 1) return interval + " years ago";

    interval = Math.floor(seconds / 2592000);
    if (interval > 1) return interval + " months ago";

    interval = Math.floor(seconds / 86400);
    if (interval >= 1) return interval + " days ago";

    interval = Math.floor(seconds / 3600);
    if (interval >= 1) return interval + " hours ago";

    interval = Math.floor(seconds / 60);
    if (interval > 1) return interval + " minutes ago";

    return "recently";
  
  }

  export const dateTimeFormatForMessenger = (timeStamp)=>{
    const currentDate = new Date();
    let currentTime = currentDate.getTime();
    let formatedTime = ""
    if (currentTime - timeStamp < 1000 * 60 * 60 * 24) {
      formatedTime = moment(new Date(timeStamp)).format("hh:mm a")
    } else {
      formatedTime = moment(new Date(timeStamp)).format("ll")
    }
    return formatedTime;
  }

  export const dataTimeFormat = (date) =>{
    let zonename= Intl.DateTimeFormat().resolvedOptions().timeZone;
    let newData;


    if(date && date._seconds){
      const unixEpochTimeMS = date._seconds * 1000;
      const d = new Date(unixEpochTimeMS);
  
      newData = moment(d).tz(zonename).format('MMM DD, YYYY hh:mmA');

      // Careful, the string output here can vary by implementation...
      // const strDate = d.toLocaleString();
      // console.log(strDate);
    }else{
    if(date){
      // this one working fine

      // newData = moment(date).tz('Asia/Kolkata').format('MM/DD/YY hh:mmA');
      // newData = moment(date).format('MMM DD, YYYY hh:mmA');
      newData = moment(date).tz(zonename).format('MMM DD, YYYY hh:mmA');
    }

    }
    return newData;
}

export const notificationdataTimeFormat = (date) =>{
  let zonename= Intl.DateTimeFormat().resolvedOptions().timeZone;
  let newData;

  if(date){
    const unixEpochTimeMS = date._seconds * 1000;
    const d = new Date(unixEpochTimeMS);
    // console.log(d);
    newData = moment(date).tz(zonename).format('hh:mmA DD MMM ');
  }
  return newData;


}

export const dataFormat = (date) =>{
  let zonename= Intl.DateTimeFormat().resolvedOptions().timeZone;
  let newData;


  // console.log(moment.utc( date._seconds).local() + "stamp " + date._seconds);
  if(date && date._seconds){
    const unixEpochTimeMS = date._seconds * 1000;
    const d = new Date(unixEpochTimeMS);
    newData = moment(d).tz(zonename).format('MMM DD, YYYY');

  }else{
  if(date){
    newData = moment(date).tz(zonename).format('MMM DD, YYYY');
  }

  }
  return newData;
}



export const payTimeFormat = (date) =>{
  let zonename= Intl.DateTimeFormat().resolvedOptions().timeZone;
  let newData;


  // console.log(moment.utc( date._seconds).local() + "stamp " + date._seconds);
  if(date && date._seconds){
    const unixEpochTimeMS = date._seconds * 1000;
    const d = new Date(unixEpochTimeMS);
    // console.log(d);
    newData = moment(d).tz(zonename).format('hh:mmA');

    // Careful, the string output here can vary by implementation...
    // const strDate = d.toLocaleString();
    // console.log(strDate);
  }else{
  if(date){
    // this one working fine

    // newData = moment(date).tz('Asia/Kolkata').format('MM/DD/YY hh:mmA');
    // newData = moment(date).format('MMM DD, YYYY hh:mmA');
    newData = moment(date).tz(zonename).format('hh:mmA');
  }

  }
  return newData;
}



export const notificationDateFormat = (date) =>{
  let zonename= Intl.DateTimeFormat().resolvedOptions().timeZone;
  let newData;


  // // console.log(moment.utc( date._seconds).local() + "stamp " + date._seconds);
  // if(date && date._seconds){
  //   const unixEpochTimeMS = date._seconds * 1000;
  //   const d = new Date(unixEpochTimeMS);
  //   newData = moment(d).tz(zonename).format('MMM DD, YYYY');

  // }else{
  if(date){
    newData = moment(date).tz(zonename).format('DD/MM/YYYY');
  }

  // }
  return newData;
}

export const dateMonthYearFormat = (date) =>{
  let zonename= Intl.DateTimeFormat().resolvedOptions().timeZone;
  let newData;

  if(date){
    newData = moment(date).tz(zonename).format('DD/MM/YYYY');
  }
  return newData;
}


export const  dayTimeSince = (dateParam) => {

  let response
  let zonename= Intl.DateTimeFormat().resolvedOptions().timeZone;

  const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
  const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
  const today = new Date();
  const yesterday = new Date(today - DAY_IN_MS);
  const seconds = Math.round((today - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const isToday = today.toDateString() === date.toDateString();
  const isYesterday = yesterday.toDateString() === date.toDateString();
  const isThisYear = today.getFullYear() === date.getFullYear();


   if (isToday) {
     response = 'Today'; // Today at 10:20
  } else if (isYesterday) {
     response=  'Yesterday'; // Yesterday at 10:20
  } else{
    if(dateParam){
      response = moment(dateParam).tz(zonename).format('MMM DD, YYYY');
    }
  }
  

  return response; // 10. January 2017. at 10:20
}



export const vaultDataTimeFormat = (date) =>{
  let zonename= Intl.DateTimeFormat().resolvedOptions().timeZone;
  let newData;


  if(date && date._seconds){
    const unixEpochTimeMS = date._seconds * 1000;
    const d = new Date(unixEpochTimeMS);

    newData = moment(d).tz(zonename).format('MMM DD, YYYY hh:mma');

    // Careful, the string output here can vary by implementation...
    // const strDate = d.toLocaleString();
    // console.log(strDate);
  }else{
  if(date){
    // this one working fine

    // newData = moment(date).tz('Asia/Kolkata').format('MM/DD/YY hh:mmA');
    // newData = moment(date).format('MMM DD, YYYY hh:mmA');
    newData = moment(date).tz(zonename).format('MMM DD, YYYY hh:mma');
  }

  }
  return newData;
}