import React, { Fragment, useEffect, useState } from 'react';
// Dependencies
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import firebase from 'firebase';
// Components
import SideBar from '../SideBar';
import NavBar from '../NavBar';
import UserProfileHeaderDropDown from './../../../components/UI/UserProfileHeaderDropDown';
import SideBarLogo from '../../../components/UI/sideBarLogo/sideBarLogo';
// UI
import UserStatus from '../../UI/UserStatus';
import UserListPopover from '../../UI/userListPopover';
// Constants
import { SIGN_IN, PROFILE_DASHBOARD } from '../../../constants/routes';
// Actions
import { signOut, getProfileDashboard,fetchCheckingSubscription, userDeviceData, _handleTransportKey } from '../../../actions/Authaction';
import { updateUser } from '../../../actions/contacts';
// Utilities
import {
  checkActiveUrl,
  checkHeaderMenuColor,
  checkHideMessenger,
} from '../../../utility/checkActivePage';
import { ShowNameAccordingToLength } from '../../../utility/stringFunctions';
// Icons
import profilePlaceholder from '../../../images/profile_placeholder.png';
import requestIcon from '../../../images/icons/Group.svg';
import notificationIcon from '../../../images/icons/Noti_A.svg';
import defaultNotification from '../../../images/icons/defaultNotification.svg';
import defaultContactRequest from '../../../images/icons/defaultContactRequest.svg';
import defaultNotificationBlack from '../../../images/icons/defaultNotificationBlack.svg';
import defaultContactRequestBlack from '../../../images/icons/defaultContactRequestBlack.svg';
import requestIconBlack from '../../../images/icons/requestIconBlack.svg';
import notificationIconBlack from '../../../images/icons/notificationIconBlack.svg';
import messenger from '../../../images/icons/messenger.png';
import closeIcon from '../../../images/close_icon.png';
// Routes
import { Link } from 'react-router-dom';
import * as routes from '../../../constants/routes';
// Styles
import './AppContainer.scss';

function AppContainer({
  children,
  location,
  history,
  userInfo,
  signOut,
  updateUser,
  userprofile,
  fetchCheckingSubscription,
  userDeviceData,
  _handleTransportKey
}) {
  // Check if the reducer contains the user data
  // If no user data is present then reset the reducer and redirect to sign in page
  if (!userInfo || !userInfo.user || !userInfo.user.status) {
    history.push(SIGN_IN, { lastlocation: window.location.pathname });
    return null;
  }

  useEffect(() => {

    // this will store the transport key in the reducer
    if(!_.get(userInfo, 'transportKey')){
      _handleTransportKey()
    }
    // this will fire .on function for user data and save it in the reducer
    // userDeviceData(userInfo);

    let sendRequestUserProfile = {
      data: {},
    };
    getProfileDashboard(sendRequestUserProfile);
  }, []);

  // use effect in place of component did mount
  useEffect(() => {
    
    if (userInfo && userInfo.user && userInfo.user.status) {
      console.log("single useEffect in app container")

      // this will store the transport key in the reducer
      // if(!_.get(userInfo, 'transportKey')){
      //   _handleTransportKey()
      // }
      // this will fire .on function for user data and save it in the reducer
      // userDeviceData(userInfo);

      _hadlePublicKey();
      const database = firebase.database();
      let obj = {
        isOnline: true,
        lastTimeOnline: Math.floor(Date.now()),
        loggedIn: true,
      };
      let userID = _.get(
        userInfo,
        'user.Data.userDetails[0].personalDetails.docID',
        null
      );
      if (userID) {
        database.ref(`Users/${userID}/onlineStatus`).update(obj);
      }
    }

    return () => {
      console.log("cleaned up single useEffect app container");
    };
  },[]);

  window.addEventListener('beforeunload', (ev) => {
    if (userInfo && userInfo.user && userInfo.user.status) {
      _hadlePublicKey();
      const database = firebase.database();
      let obj = {
        isOnline: false,
        lastTimeOnline: Math.floor(Date.now()),
        loggedIn: false,
        // isWebConnectActive: false,
        // encryptedTextForWebToDecryptToStartConnect: null
      };
      let userID = _.get(
        userInfo,
        'user.Data.userDetails[0].personalDetails.docID',
        null
      );
      if (userID) {
        database.ref(`Users/${userID}/onlineStatus`).update(obj);
      }
    }
    // console.log ('addEventListener>>>>beforeunload');
  });

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const _hadlePublicKey = async () => {
    const database = firebase.database();
    let userID = _.get(
      userInfo,
      'user.Data.userDetails[0].personalDetails.docID',
      null
    );
    let dhAlicesKey = getRandomInt(100000000, 999999999);
    // console.log("dhAlicesKey(100000000, 999999999);>>", dhAlicesKey)
    var unsubscribeUserDetailonAppContainer = await database
      .ref(`Users/${userID}/onlineStatus`)
      .on('value', (snapShot) => {
        let userPublicKey = snapShot.child('publicKey').val();
        // console.log("snapShot.child('publicKey').val()>>>", snapShot.child('publicKey').val(), snapShot.val())
        let isPublicKeyExits = snapShot.child('publicKey').exists();
        if (!isPublicKeyExits) {
          let obj = {
            publicKey: dhAlicesKey,
          };
          database.ref(`Users/${userID}/onlineStatus`).update(obj);
          // this.props.dispatch({
          //   type: "UPDATE_PUBLIC_KEY",
          //   publicKey: dhAlicesKey
          // });
        } else {
          // this.props.dispatch({
          //   type: "UPDATE_PUBLIC_KEY",
          //   publicKey: userPublicKey
          // });
        }
        database.ref(`Users/${userID}/onlineStatus`).off('value', unsubscribeUserDetailonAppContainer);
      });
  };

  const _hadleUpdateStatus = async (status) => {
    setuserStatusValue(status);
    toggleMenu();
    const database = firebase.database();
    let userID = await _.get(
      userInfo,
      'user.Data.userDetails[0].personalDetails.docID',
      null
    );
    let obj = {
      isOnline: true,
      lastTimeOnline: Math.floor(Date.now()),
      statusMessage: status.trim(),
      statusUpdatedAt: Math.floor(Date.now()),
      loggedIn: true,
    };

    database
      .ref(`Users/${userID}/onlineStatus`)
      .update(obj)
      .then(() => database.ref(`Users/${userID}/onlineStatus`).once('value'))
      .then((snapshot) => {
        if (snapshot.val()) {
          const data = {
            data: {
              statusMessage: status.trim(),
            },
          };
          updateUser(data);
        }
      })
      .catch((error) => {
        console.log(error);
        // errorCode: error.code,
        // errorMessage: error.message
      });
  };
  // Function to be called when sign out is clicked on top nav bar
  const localSignOut = () => {
    const database = firebase.database();
    let obj = {
      isOnline: false,
      lastTimeOnline: Math.floor(Date.now()),
      loggedIn: false,
      isWebConnectActive: false,
      encryptedTextForWebToDecryptToStartConnect: null
    };
    let userID = _.get(
      userInfo,
      'user.Data.userDetails[0].personalDetails.docID',
      null
    );
    if (userID) {
      database.ref(`Users/${userID}/onlineStatus`).update(obj);
    }
    history.push(SIGN_IN, { lastlocation: PROFILE_DASHBOARD });
    signOut();
  };

  const { pathname } = location;

  const {
    user: {
      Data: { userDetails },
    },
    recentMessages,
  } = userInfo;
  const [userPersonalDetails] = userDetails;
  // const { personalDetails } = userPersonalDetails;

  const { personalDetails } =
    userprofile &&
    userprofile.profileDetail &&
    userprofile.profileDetail.length > 0
      ? userprofile.profileDetail[0]
      : userPersonalDetails;

  const {
    profilePictureURL,
    title,
    firstName,
    lastName,
    displayName,
    statusMessage,
  } = personalDetails
    ? personalDetails
    : {
        profilePictureURL: '',
        title: '',
        firstName: '',
        lastName: '',
        displayName: '',
      };

  // console.log(userprofile.contactDetail[0].personalDetails);

  // const { personalDetails} = userprofile && userprofile.contactDetail[0];
  // console.log(personalDetails);
  // // // const [ userPersonalDetails ] = userDetails;
  // // // const { personalDetails } = userPersonalDetails;
  //  const { profilePictureURL, title, firstName, lastName } = personalDetails;

  // const userName = `${firstName} ${lastName}`.trim ();
  // const userName = title + ' ' + (firstName ? firstName : firstName);

  const userName = ShowNameAccordingToLength(firstName + ' ' + lastName);
  const userStatus = statusMessage ? statusMessage : '';

  let walletAddress = localStorage.getItem('address');

  let loginUser = JSON.parse(localStorage.getItem('loginuser'));
  if (!loginUser) {
    signOut();
    history.push(SIGN_IN);
  }


  const toggleSidebar = () => {
    var element = document.getElementById('sidebarmenu');
    element.classList.add('menuDisplayHide');
    var element = document.getElementById('overlay');
    element.classList.remove('hideoverlay');
  };

  const [isOpened, setCollapse] = useState(false);

  const toggleMenu = () => setCollapse(!isOpened);

  const [isOpenSidebar, setisOpenSidebar] = useState(false);

  const [userStatusValue, setuserStatusValue] = useState(userStatus);

  const [openUserListPopover, setopenUserListPopover] = useState(false);
  const [joinBusinessInfo, setjoinBusinessInfo] = useState('');



  

  var sidebarelement = document.getElementById('collapseRightPanelButton');
  if (sidebarelement) {
    sidebarelement.addEventListener('click', function() {
      if (sidebarelement.matches('.checkOpenPannel')) {
        // console.log("match")
        setisOpenSidebar(false);
      } else {
        setisOpenSidebar(true);
      }
    });
  }

  useEffect(() => {
    return history.listen((location) => {
      setisOpenSidebar(false);
    });
  }, [history]);

  // if(isOpenSidebar){
  //   setisOpenSidebar(false);
  // }

  // blackBGWithImage
  let blackText = checkHeaderMenuColor();
  let checkHideMessengerIcon = checkHideMessenger();

  const closePopover = () => {
    setopenUserListPopover(false);
  };

  useEffect(() => {
    let loginUser = JSON.parse(localStorage.getItem('loginuser'));
    if(loginUser){
      fetchCheckingSubscription(loginUser.docID);
    }
   
  }, []);
  useEffect(() => {
    if(userInfo && userInfo.dashboardprofile && userInfo.dashboardprofile.assumedBusiness){
      setjoinBusinessInfo({assumedBusiness: userInfo.dashboardprofile.assumedBusiness,assumedBusinessName: userInfo.dashboardprofile.assumedBusinessName});
    }

  }, [userInfo.dashboardprofile]);
  

  return (
    <Fragment>
      <div
        id='rootContainerBG'
        className={
          'container-flex ' + checkActiveUrl()
          // (checkPurple ? 'notificationBg ' : ' ') +
          // (messageContainerPurple ? 'messengerPurple ' : ' ') +
          // (rdeePayClass ? 'rdeePayBlue' : '') +
          // (checkBlackBG ? 'blackBGWithImage' : '')
        }
        // style={{background: (window.location.pathname.includes('contentapps') || window.location.pathname.includes('pacs') || window.location.pathname.includes('serviceOnDemand'))? 'linear-gradient(133.61deg, #4453D6 22.8%, #571B65 79.61%)':' '}}
      >
        <div className='container-sidebar menuDisplayHide' id='sidebarmenu'>
          <SideBar joinBusinessInfo={joinBusinessInfo} history={history} activePage={pathname} />
        </div>

        <div className='container-body'>
          <SideBarLogo bg='default' />
          {
            <div
              id='topHeader'
              className={'topHeaderMenu topHeaderMenuContainer '}
            >
              {/*!window.location.pathname.includes ('profiledashboard') && */}
              <div className='topHeaderContianerBox'>
                <div>
                  <UserProfileHeaderDropDown
                    signOutFunction={localSignOut}
                    userName={userName ? userName : ''}
                    userImage={
                      profilePictureURL ? profilePictureURL : profilePlaceholder
                    }
                    dashboardprofile={userInfo.dashboardprofile}
                    isOpenSidebar={isOpenSidebar}
                    openUserListPopover={openUserListPopover}
                    iconHide={true}
                    userStatus={userStatus}
                    _hadleUpdateStatus={_hadleUpdateStatus}
                  />
                </div>
                <div
                  className='rightHeaderMenu'
                  // style={{ paddingRight: !checkHideMessengerIcon ? 25 : 0 }}
                >
                  <div className='rightSideIcons'>
                    <Link
                      to={routes.CONTACTREQUEST}
                      className='iconsPaddingTop'
                      data-rh='Contact request'
                    >
                      {blackText && !isOpenSidebar && !openUserListPopover ? (
                        <img
                          src={
                            userInfo &&
                            userInfo.dashboardprofile &&
                            userInfo.dashboardprofile.pendingContactRequests &&
                            userInfo.dashboardprofile.pendingContactRequests
                              .length > 0
                              ? requestIconBlack
                              : defaultContactRequestBlack
                          }
                          className='notificationIconCss '
                          alt='Notification'
                        />
                      ) : (
                        <img
                          src={
                            userInfo &&
                            userInfo.dashboardprofile &&
                            userInfo.dashboardprofile.pendingContactRequests &&
                            userInfo.dashboardprofile.pendingContactRequests
                              .length > 0
                              ? requestIcon
                              : defaultContactRequest
                          }
                          className='notificationIconCss '
                          alt='Notification'
                        />
                      )}
                    </Link>
                    <Link
                      to={routes.NOTIFICATION}
                      className='iconsPaddingTop'
                      data-rh='View notification'
                    >
                      {blackText && !isOpenSidebar && !openUserListPopover ? (
                        <img
                          src={
                            userInfo.dashboardprofile &&
                            userInfo.dashboardprofile.notifications &&
                            userInfo.dashboardprofile.notifications.length > 0
                              ? notificationIconBlack
                              : defaultNotificationBlack
                          }
                          className='notificationIconCss '
                          alt='Notification'
                        />
                      ) : (
                        <img
                          src={
                            userInfo.dashboardprofile &&
                            userInfo.dashboardprofile.notifications &&
                            userInfo.dashboardprofile.notifications.length > 0
                              ? notificationIcon
                              : defaultNotification
                          }
                          className='notificationIconCss '
                          alt='Notification'
                        />
                      )}
                    </Link>
                    {!checkHideMessengerIcon && (
                      <div>
                        {!openUserListPopover ? (
                          <div onClick={() => setopenUserListPopover(true)}>
                            {recentMessages && recentMessages.length > 0 && (
                              <div className='yellowCircle' />
                            )}
                            <div className='messageIconCircle'>
                              <img
                                src={messenger}
                                className='messengerIcon '
                                alt='message'
                              />
                            </div>
                          </div>
                        ) : (
                          <div onClick={() => setopenUserListPopover(false)}>
                            <div className='closePopoverCircle'>
                              <img src={closeIcon} alt='message' />
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/*}
                      {window.location.pathname.includes ('profiledashboard') &&*/}
              {/*<div
                  style={{
                    display: 'inline-flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >

                  <UserStatus
                    userStatus={userStatus}
                    _hadleUpdateStatus={_hadleUpdateStatus}
                    isOpenSidebarStatus={isOpenSidebar}
                  />

                  <UserProfileHeaderDropDown
                    signOutFunction={localSignOut}
                    userName={userName ? userName : ''}
                    userImage={
                      profilePictureURL ? profilePictureURL : profilePlaceholder
                    }
                    dashboardprofile={userInfo.dashboardprofile}
                    isOpenSidebar={isOpenSidebar}
                  />
                  </div>*/}

              {/* } */}

              {openUserListPopover && (
                <UserListPopover closePopover={closePopover} />
              )}
            </div>
          }

          <div
            className={
              'container-children ' +
              (window.location.pathname.includes('home')
                ? ' webmainContainer '
                : '')
            }
            onClick={() => toggleSidebar()}
          >
            {children}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    userInfo: state.Authreducer,
    userprofile: state.ContactsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signOut,
      updateUser,
      fetchCheckingSubscription,
      userDeviceData,
      _handleTransportKey,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
