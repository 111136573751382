let initialState = {
  payment: {},
  history: [],
  newcreatewallet: null,
  sendmoney: null,
  sendmoneyerror: null,
  askSendMoney: null,
  accountbalance: null,
  sentPinOTP: null,
  walletAddress:null
};

export const TRANSACTION = 'TRANSACTION';
export const TRANSACTION_SUCCESS = 'TRANSACTION_SUCCESS';
export const CREATE_WALLET = 'CREATE_WALLET';
export const CONTACT_LIST = 'CONTACT_LIST';
export const SEND_MONEY = 'SEND_MONEY';
export const SEND_MONEY_ERROR = 'SEND_MONEY_ERROR';
export const ASK_CREDIT_SEND_MONEY = 'ASK_CREDIT_SEND_MONEY';
export const USER_BALANCE = 'USER_BALANCE';
export const WALLET_CHANGE_PIN = 'WALLET_CHANGE_PIN';
export const PIN_OTP_SEND = 'PIN_OTP_SEND';
export const CHECK_USER_WALLET = 'CHECK_USER_WALLET';
export const USER_PAYMENT_SESSION = 'USER_PAYMENT_SESSION';
export const USER_PAYMENT_STATUS = 'USER_PAYMENT_STATUS';
export const USER_PAYMENT_BANK_LIST = 'USER_PAYMENT_BANK_LIST';
export const USER_PAYMENT_ACCOUNT_LIST = 'USER_PAYMENT_ACCOUNT_LIST';
export const CREATE_TRANSFER_SUCCESS = 'CREATE_TRANSFER_SUCCESS';






export default (state = initialState, action) => {
  switch (action.type) {
    case TRANSACTION_SUCCESS:
      return {
        ...state,
        history: action.payload,
        sendmoney: null,
        sendmoneyerror: null,
        askSendMoney: null,
      };
    case CREATE_WALLET:
      return {
        ...state,
        newcreatewallet: action.payload,
        sendmoneyerror: null,
        sendmoney: null,
        askSendMoney: null,
      };
    case CONTACT_LIST:
      return {
        ...state,
        contactList: action.payload,
        sendmoney: null,
        sendmoneyerror: null,
        askSendMoney: null,
      };
    case SEND_MONEY:
      return {
        ...state,
        sendmoney: action.payload,
        sendmoneyerror: null,
        askSendMoney: null,
      };
    case ASK_CREDIT_SEND_MONEY:
      return {...state, askSendMoney: action.payload, sendmoneyerror: null};
    case SEND_MONEY_ERROR:
      return {...state, sendmoneyerror: action.payload, askSendMoney: null};
    case USER_BALANCE:
      return {...state, accountbalance: action.payload, askSendMoney: null};
    case WALLET_CHANGE_PIN:
      return {...state, changePin: action.payload, askSendMoney: null,sentPinOTP:null};
    case PIN_OTP_SEND:
      return {...state, sentPinOTP: action.payload, askSendMoney: null ,changePin:null};
    case CHECK_USER_WALLET:
      return {...state, walletAddress: action.payload};

    case USER_PAYMENT_SESSION:
      return {...state, userPaymentSession: action.payload, userPaymentStatus:''};
    case USER_PAYMENT_STATUS:
      return {...state, userPaymentStatus: action.payload};
    case USER_PAYMENT_BANK_LIST:
      return {...state, userPaymentBanks: action.payload};
    case USER_PAYMENT_ACCOUNT_LIST:
      return {...state, userPaymentAccounts: action.payload};
    case CREATE_TRANSFER_SUCCESS:
      return {...state, userPaymentInitiated: action.payload};
     
      
      
    default:
      return state;
  }
};
