import React, {Component} from 'react';
import ServiceOnDemandProfilePage
  from '../service-On-Demand-Profile/serviceOnDemandProfile';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as routes from '../../constants/routes';
import ServiceOnDemandCommonComponent
  from '../Service-On-Demand-CommanComponent/Service-On-Demand-CommanComponent';
import './serviceOnDemandMyRequestDetail.css';
import provider1 from '../../images/provider1.png';
import greywatch from '../../images/serviceOnDemandMyRequest/greywatch.png';
import buttonframe from '../../images/buttonframe.png';
import roman from '../../images/serviceOnDemandSearch/roman.png';
import {Button, Modal, ModalBody, Progress} from 'reactstrap';
// import star from '../../images/serviceOnDemandSearch/star.png';
import close_icon from '../../images/close_icon.png';
import whitestar from '../../images/whitestar.png';
import pin from '../../images/pay/pin.png';
import PinInput from 'react-pin-input';

import {GetMyServiceRequests} from '../../actions/serviceOnDemandAction';
import profilePlaceholder from '../../images/profile_placeholder.png';
import StarRatingComponent from 'react-star-rating-component';
import moment from 'moment';
import {acceptServiceRequest,rejectServiceRequest} from '../../actions/serviceRequestAction';

class serviceOnDemandMyRequestDetailPage extends Component {
  constructor (props) {
    super (props);
    this.state = {
      listview: false,
      gridView: true,
      feedbackModal: false,
      payserviceModal: false,
      enterPinModal: false,
      paysuccessModal: false,
      jobCompleteModal: false,
      jobMarkedModal: false,
      myRequestsList: [],
    };
  }

  componentWillReceiveProps (nextProps) {
   
    if (nextProps) {
      if (
        nextProps.service &&
        nextProps.service.myserviceRequest &&
        nextProps.service.myserviceRequest.length > 0
      ) {
        this.setState ({
          myRequestsList: nextProps.service.myserviceRequest[0],
        });
      }
    }
  }

  componentDidMount () {
    let geturl = this.props.match.params;

    if (geturl && geturl.serviceId) {
      this.setState (
        {
          serviceId: geturl.serviceId,
        },
        () => {
          let sendRequest = {
            data: {
              status: '',
              docID: this.state.serviceId,
            },
          };
          this.props.GetMyServiceRequests (sendRequest);
        }
      );
    }
  }
  acceptRequest=()=>{
    let geturl = this.props.match.params;
    if (geturl && geturl.serviceId) {
      this.setState (
        {
          serviceId: geturl.serviceId,
        },
        () => {
          let sendRequest = {
            data: {
              docID: this.state.serviceId,
            },
          };
          this.props.acceptServiceRequest (sendRequest);
        }
      );
    }
  }

  rejectRequest=()=>{
    let geturl = this.props.match.params;
    if (geturl && geturl.serviceId) {
      this.setState (
        {
          serviceId: geturl.serviceId,
        },
        () => {
          let sendRequest = {
            data: {
              docID: this.state.serviceId,
            },
          };
          this.props.rejectServiceRequest (sendRequest);
        }
      );
    }
  }
  openfeedbackModal = () => {
    this.setState ({feedbackModal: true});
  };
  closefeedbackModal = () => {
    this.setState ({feedbackModal: false});
  };
  OpenjobCompleteModal = () => {
    this.setState ({jobCompleteModal: true});
  };
  openjobMarkedModal = () => {
    this.closejobCompleteModal ();
    this.setState ({jobMarkedModal: true});
  };
  openpayserviceModal = () => {
    this.setState ({payserviceModal: true});
  };
  closepayserviceModal = () => {
    this.setState ({payserviceModal: false});
  };
  openenterPinModal = () => {
    this.closepayserviceModal ();
    this.setState ({enterPinModal: true});
  };
  closeenterPinModal = () => {
    this.setState ({enterPinModal: false});
  };
  openpaysuccessModal = () => {
    this.closeenterPinModal ();
    this.setState ({paysuccessModal: true});
  };
  closepaysuccessModal = () => {
    this.setState ({paysuccessModal: false});
  };
  closejobCompleteModal = () => {
    this.setState ({jobCompleteModal: false});
  };
  closejobMarkedModal = () => {
    this.setState ({jobMarkedModal: false});
  };
  viewProfile () {
    this.props.history.push (routes.SERVICEONDEMANDPROVIDERPROFILE);
  }

  render () {
    const {myRequestsList} = this.state;
    return (
      <div style={{display: 'flex', background: '#FFFFFF'}}>
        <div className="inlineFlex100MyRequestDetails">
          <div className="parentRowMyRequestDetails">
            <div style={{width: 230}}>
              <ServiceOnDemandCommonComponent />
            </div>
            <div className="scrollBehaveXMyRequestDetail">
              <div>

                <Button
                  className="DetailgobackButtons"
                  onClick={() => this.props.history.goBack ()}
                >
                  <div>
                    <img alt="" src={provider1} />
                  </div>
                  <span> GO BACK </span>
                </Button>
                <div className="REQUESTsss">
                  REQUEST #{myRequestsList ? myRequestsList.request_id : 'N/A'}
                </div>
              </div>
              <div className="Servicinggg">

                {myRequestsList ? myRequestsList.title : 'N/A'}
              </div>
              <div className="inlineFlex100MyRequestDetails">
                <div style={{width: 600}}>
                  <div className="Description">
                    Description:
                  </div>
                  <div className="longtextttt">
                    {myRequestsList ? myRequestsList.description : 'N/A'}
                  </div>
                  <div style={{marginTop: 20}}>
                    <div className="JOBDETAILS">
                      JOB DETAILS
                    </div>
                  </div>
                  <div className="inlineFlex100MyRequestDetails">
                    <div style={{width: '60%'}}>
                      <div className="inlineFlex100MyRequestDetails mTop5">
                        <div className="Manufacturerr">
                          Manufacturer:
                        </div>
                        <div className="Sssiemens">
                          {myRequestsList ? myRequestsList.manufacturer : 'N/A'}
                        </div>
                      </div>
                      <div className="inlineFlex100MyRequestDetails mTop5">
                        <div className="Manufacturerr">
                          Modality:
                        </div>
                        <div className="Sssiemens">
                          {myRequestsList ? myRequestsList.modality : 'N/A'}
                        </div>
                      </div>
                      <div className="inlineFlex100MyRequestDetails mTop5">
                        <div className="Manufacturerr">
                          System Name:
                        </div>
                        <div className="Sssiemens">
                          {myRequestsList ? myRequestsList.systemName : 'N/A'}
                        </div>
                      </div>
                      <div className="inlineFlex100MyRequestDetails mTop5">
                        <div className="Manufacturerr">
                          Type of Service:
                        </div>
                        <div className="Sssiemens">
                          {myRequestsList
                            ? myRequestsList.typeOfService
                            : 'N/A'}
                        </div>
                      </div>
                      <div className="inlineFlex100MyRequestDetails mTop5">
                        <div className="Manufacturerr">
                          Expected Delivery Date:
                        </div>
                        <div className="Sssiemens">
                          {myRequestsList && myRequestsList.serviceDate
                            ? moment (myRequestsList.serviceDate).format (
                                'DD/MM/YYYY'
                              )
                            : 'N/A'}
                        </div>
                      </div>
                      <div className="inlineFlex100MyRequestDetails mTop5">
                        <div className="Manufacturerr">
                          Type of Call:
                        </div>
                        <div className="Sssiemens">
                          {myRequestsList && myRequestsList.callType
                            ? myRequestsList.callType
                            : 'N/A'}

                        </div>
                      </div>
                      <div className="inlineFlex100MyRequestDetails mTop5">
                        <div className="Manufacturerr">
                          Payment Offered:
                        </div>
                        <div className="Sssiemens">
                          {myRequestsList
                            ? myRequestsList.paymentOffered
                            : 'N/A'}
                        </div>
                      </div>

                      <div style={{marginTop: 20}}>
                        <Button className="revbu">
                          {' '}EDIT JOB DETAILS
                          <img alt="" src={buttonframe} className="mleft15" />
                        </Button>
                      </div>
                    </div>
                    <div style={{width: '40%'}}>
                      <div className="inlineFlex100MyRequestDetails mTop5">
                        <div className="Errorrr">
                          Error Code:{' '}
                        </div>
                        <div className="a5215">
                          {myRequestsList ? myRequestsList.errorLogName : 'N/A'}
                        </div>
                      </div>
                      <div className="inlineFlex100MyRequestDetails">
                        <div className="Errorrr">
                          Error Log:{' '}
                        </div>
                        <div className="a5215">
                          {myRequestsList ? myRequestsList.errorLogName : 'N/A'}
                        </div>
                      </div>
                      <div className="inlineFlex100MyRequestDetails">
                        <div className="Errorrr" />
                        <div className="a5215">
                          {myRequestsList &&
                            myRequestsList.errorLogURL &&
                            <a
                              href={myRequestsList.errorLogURL}
                              target="_blank"
                            >
                              <Button className="reccoo">Download Log</Button>
                            </a>}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="statusDiv">
                  <div className="Status">
                    Status:
                  </div>
                  <div>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      {/*<img alt="" src={yellowdot} />*/}

                      <div
                        className={
                          myRequestsList.status === 'draft' ? 'greencircle' : ''
                        }
                      />
                      <div
                        className={
                          myRequestsList.status === 'awarded'
                            ? 'yellowcircle'
                            : ''
                        }
                      />
                      <div
                        className={
                          myRequestsList.status === 'accepted'
                            ? 'yellowcircle'
                            : ''
                        }
                      />
                      <div
                        className={
                          myRequestsList.status === 'job completed'
                            ? 'orangecircle'
                            : ''
                        }
                      />
                      <div
                        className={
                          myRequestsList.status === 'rejected'
                            ? 'orangecircle'
                            : ''
                        }
                      />
                      <div
                        className={
                          myRequestsList.status === 'pending'
                            ? 'whitecircle'
                            : ''
                        }
                      />
                      <div
                        className={
                          myRequestsList.status === 'confirmed'
                            ? 'lightgreen'
                            : ''
                        }
                      />

                      <span className="Awarded22">
                        {myRequestsList && myRequestsList.status
                          ? myRequestsList.status
                          : ''}
                      </span>
                    </div>
                    <Progress
                      value={
                        myRequestsList && myRequestsList.progressBar
                          ? myRequestsList.progressBar
                          : ''
                      }
                      className="Rectangleeee"
                    />

                    <div>
                      <img alt="" src={greywatch} />
                      <span className="dateeee0">

                        {myRequestsList && myRequestsList.serviceDate
                          ? moment (myRequestsList.serviceDate).format (
                              'DD/MM/YYYY'
                            )
                          : 'N/A'}
                      </span>
                    </div>
                    {myRequestsList.status === 'job started' &&
                      <Button
                        className="ButtonStyleForTwo mTop15"
                        style={{background: '#4CA9B7'}}
                        // onClick={this.openpayserviceModal}
                      >
                        MARK JOB COMPLETE
                      </Button>}
                    {myRequestsList.status === 'draft' &&
                      <Button
                        className="ButtonStyleForTwo mTop15"
                        style={{background: '#4CA9B7'}}
                        // onClick={this.openpayserviceModal}
                      >
                        Assign to Service Provider
                      </Button>}
                      
                      {myRequestsList.status === 'awarded' && <div
                      style={{
                        display: 'flex',
                        marginTop: 9,
                        justifyContent: 'space-around',
                      }}
                    >
                     <div>
                        <Button
                          className="serREJECT"
                          // onClick={this.openRejectServiceModal}
                          onClick={this.rejectRequest}
                        >
                          REJECT
                        </Button>
                      </div>
                      <div>
                        <Button className="serACCEPT" 
                        onClick={this.acceptRequest}>
                          ACCEPT
                        </Button>
                      </div>
                    </div>}
                    {/*myRequestsList.status === 'awarded' &&
                      <Button
                        className="buttonStyleOne mTop15"
                        onClick={this.OpenjobCompleteModal}
                      >
                        WAITING FOR CONFIRMATION
                    </Button>*/}
                    {myRequestsList.status === 'rejected' &&
                      <Button className="buttonStyleOne mTop15">
                        FINAL STATUS
                      </Button>}
                    {myRequestsList.status === 'completed' &&
                      <Button className="buttonStyleOne mTop15">
                       SERVICE COMPLETED
                      </Button>}
                    {myRequestsList.status === 'job completed' &&
                      <Button
                        className="ButtonStyleForTwo mTop15"
                        style={{background: '#4CA9B7'}}
                        onClick={this.openfeedbackModal}
                      >
                        LEAVE FEEDBACK
                      </Button>}
                    {myRequestsList.status === 'accepted' &&
                      <Button
                        className="ButtonStyleForTwo mTop15"
                        style={{background: '#4CA9B7'}}
                        onClick={this.openpayserviceModal}
                      >
                        PAY FOR ESCROW
                      </Button>}
                  </div>
                  <div className="Rectangle0000 mTop15">
                    <div style={{display: 'flex'}}>
                      <div style={{width: 200}}>
                        <div className="Lorendaaa">

                          {myRequestsList.serviceProviderTitle
                            ? myRequestsList.serviceProviderTitle
                            : ''}
                          {' '}

                          {myRequestsList &&
                            myRequestsList.serviceProviderDisplayName
                            ? myRequestsList.serviceProviderDisplayName
                            : (myRequestsList &&
                                myRequestsList.serviceProviderFirstName
                                ? myRequestsList.serviceProviderFirstName
                                : '') +
                                ' ' +
                                (myRequestsList &&
                                  myRequestsList.serviceProviderLastName
                                  ? myRequestsList.serviceProviderLastName
                                  : '')}

                        </div>
                        <div className="Angeless">
                          Los Angeles, California
                        </div>
                        <div>
                          <Button
                            className="reccc"
                            onClick={() => this.viewProfile ()}
                          >
                            VIEW PROFILE
                            <img alt="" src={buttonframe} className="mleft5" />
                          </Button>
                        </div>
                      </div>
                      <div style={{paddingLeft: 5, textAlign: 'center'}}>
                        <img
                          alt=""
                          src={
                            myRequestsList &&
                              myRequestsList.serviceProviderProfilePictureURL
                              ? myRequestsList.serviceProviderProfilePictureURL
                              : profilePlaceholder
                          }
                          className="serviceProviderProfileImage"
                        />
                        <StarRatingComponent
                          name={'rating'}
                          starCount={5}
                          emptyStarColor={'#E4E4E4'}
                          starColor={'#F5D028'}
                          value={myRequestsList && myRequestsList.providerRating?myRequestsList.providerRating:""}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="notificationCSSMyRequestDetails">
            <ServiceOnDemandProfilePage
              history={this.props.history}
              isSearch={true}
            />
          </div>
          {/*}===========model======--*/}
          <Modal
            // size="sm"
            isOpen={this.state.feedbackModal}
            toggle={this.closefeedbackModal}
            centered={true}
          >
            <ModalBody className="feedmodal">
              <div onClick={this.closefeedbackModal}>
                <img
                  alt=""
                  src={close_icon}
                  style={{width: 25, float: 'right'}}
                />
              </div>
              <div className="Leave">
                Leave Feedback
              </div>
              <div className="experienceee">
                How was your experience with<br />
                [Service Provider Name]?
              </div>
              <div style={{textAlign: 'center'}}>
                <StarRatingComponent
                  name={'rating'}
                  starCount={5}
                  // value={
                  //   userProfile &&
                  //     userProfile.serviceProfile &&
                  //     userProfile.serviceProfile.totalServicesOffered
                  //     ? userProfile.serviceProfile.totalServicesOffered
                  //     : 0
                  // }
                  emptyStarColor={'#E4E4E4'}
                  starColor={'#F5D028'}
                  style={{fontSize: '80px'}}
                  // onStarClick={this.onStarClick}
                />
              </div>
              <div>
                <textarea
                  placeholder="Leave a review of your experience"
                  className="commentbox"
                />
              </div>
              <div>
                <Button
                  className="Rectangle08036"
                  onClick={this.closefeedbackModal}
                >
                  SUBMIT
                </Button>
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size="sm"
            isOpen={this.state.payserviceModal}
            toggle={this.closepayserviceModal}
            centered={true}
          >
            <ModalBody className="payforservice">

              <div onClick={this.closepayserviceModal}>
                <img
                  alt=""
                  src={close_icon}
                  style={{width: 25, float: 'right'}}
                />
              </div>
              <div className="Serviceforpay">
                Pay for Service
              </div>
              <div className="Wallet">
                Wallet Balance
              </div>
              <div className="US100000000">
                USD$ 1,000,000.00
              </div>
              <div className="RDEECredits">
                R-DEE Credits: 1,000,000 R-DEE
              </div>
              <div style={{display: 'flex', marginTop: 32}}>
                <div>
                  <img alt="" src={roman} />
                </div>
                <div style={{marginLeft: 19}}>
                  <div className="AlfredHerman">
                    Alfred Herman
                  </div>
                  <div className="Request137508">
                    Request #137508
                  </div>
                  <div className="SiemensServicingSessio">
                    Siemens Servicing Session
                  </div>
                </div>
              </div>
              <div className="PAYUS220000">
                PAY USD$ 220,000
              </div>
              <div className="RDEECreditsafte">
                R-DEE Credits after placing order:  780,000 R-DEE
              </div>
              <div>
                <Button
                  className="Rectangle9999"
                  onClick={this.openenterPinModal}
                >
                  SUBMIT
                </Button>
              </div>
            </ModalBody>
          </Modal>
          <Modal
            size="sm"
            isOpen={this.state.enterPinModal}
            toggle={this.closeenterPinModal}
            centered={true}
          >
            <ModalBody className="pinrect">
              <div onClick={this.closeenterPinModal}>
                <img
                  alt=""
                  src={close_icon}
                  style={{width: 25, float: 'right'}}
                />
              </div>
              <div className="EnterPIN">
                Enter PIN
              </div>
              <div
                style={{
                  textAlign: 'center',
                  marginTop: 30,
                }}
              >
                <img alt="" src={pin} style={{width: 69}} />
              </div>
              <div className="confirmsending">
                Enter your PIN to<br />
                confirm sending money.
              </div>
              <div
                style={{textAlign: 'center'}}
                onClick={this.openpaysuccessModal}
              >
                <PinInput
                  length={4}
                  focus
                  secret
                  ref={p => (this.pin = p)}
                  type="numeric"
                  style={{
                    padding: '10px',
                    display: 'inline-flex',
                  }}
                  inputStyle={{
                    borderColor: 'transparent',
                    borderBottomColor: '#4092B7',
                    borderBottomWidth: 2,
                    borderTopWidth: 0,
                    borderRadius: 0,
                  }}
                  inputFocusStyle={{borderBottomColor: '#4092B7'}}
                  // onChange={this.onChange}
                  onComplete={(value, index) => {
                    this.setState ({
                      step: 4,
                      newpin: 4,
                    });
                  }}
                />
              </div>
              <div className="enteredYouhave">
                You have entered your PIN incorrectly
                more than 5 times. Your PIN has been reset.
                Kindly email support@radiologex.com
                to proceed further.
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size="sm"
            isOpen={this.state.paysuccessModal}
            toggle={this.closepaysuccessModal}
            centered={true}
          >
            <ModalBody className="successRectangle">
              <div className="Successss">
                Success!
              </div>
              <div
                style={{
                  textAlign: 'center',
                  marginTop: 30,
                }}
              >
                <img alt="" src={pin} style={{width: 69}} />
              </div>
              <div className="Service-on-DemandRequest">
                You paid $220,000 to Alfred Herman for
                Service-on-Demand Request #137508.
              </div>
              <div
                style={{
                  textAlign: 'center',
                  paddingTop: 26,
                }}
              >
                <Button
                  className="backtorect"
                  onClick={this.closepaysuccessModal}
                >
                  BACK TO MY DASHBOARD
                </Button>
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size="sm"
            isOpen={this.state.jobCompleteModal}
            toggle={this.closejobCompleteModal}
            centered={true}
          >
            <ModalBody className="jobcompleterec">
              <div onClick={this.closejobCompleteModal}>
                <img
                  alt=""
                  src={close_icon}
                  style={{width: 25, float: 'right'}}
                />
              </div>
              <div className="JobComplete">
                Confirm Job Complete
              </div>
              <div className="iscomplete">
                Do you wish to mark that this service from
                [Service Provider Name] is complete?
              </div>
              <div className="twobutttt">
                <div>
                  <Button
                    className="butrecttt"
                    onClick={this.closejobCompleteModal}
                  >
                    NO
                  </Button>
                </div>
                <div>
                  <Button
                    className="yesreccc"
                    onClick={this.openjobMarkedModal}
                  >
                    YES
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <Modal
            size="sm"
            isOpen={this.state.jobMarkedModal}
            toggle={this.closejobMarkedModal}
            centered={true}
          >
            <ModalBody className="jobmarkedrecc">
              <div className="MarkedComplete">
                Job Marked Complete
              </div>
              <div className="Thiservice">
                This service from [Service Provider Name]
                has been marked as completed. Please
                rate your experience with [Service Provider
                Name].
              </div>
              <div
                style={{
                  textAlign: 'center',
                  paddingTop: 32,
                }}
              >
                <Button
                  className="buuurexxx"
                  onClick={this.closejobMarkedModal}
                >
                  COMPLETE FEEDBACK
                </Button>
              </div>
              <div className="toMyRequests" onClick={this.closejobMarkedModal}>
                Return to My Requests
              </div>
            </ModalBody>
          </Modal>
        </div>

        {/*<div className="Rectangle0000">
<div style={{display: 'flex'}}>
  <div>
    <div className="Lorendaaa">
      Tanya Lorenda
    </div>
    <div className="Angeless">
      Los Angeles, California
    </div>
    <div>
      <Button className="reccc">
        VIEW PROFILE
        <img alt="" src={buttonframe} />
      </Button>
    </div>
  </div>
  <div style={{paddingLeft: 5}}>
    <img alt="" src={roman} style={{height: 45, width: 45}} />
    <img alt="" src={star} style={{width: 60, height: 11}} />
  </div>
</div>
</div>*/}
      </div>
    );
  }
}
const mapStateToProps = state => {

  return {
    service: state.serviceOnDemandReducer,
    serviceRequest:state.serviceRequestReducer
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      GetMyServiceRequests,
      acceptServiceRequest,
      rejectServiceRequest
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  serviceOnDemandMyRequestDetailPage
);
