import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getAllUserContactsFunc } from '../../../actions/contacts';
import { createGroupDetails } from '../../../actions/Messenger';
import { MESSENGER } from '../../../constants/routes';
import Contacts from '../../UI/Contacts';
import GroupMembers from '../../UI/GroupMembers';
import GroupDetails from '../../UI/GroupDetails';
import './CreateGroup.css';

class CreateGroup extends Component {

  state = {
    selectedContacts: [],
    membersSelected: false,
  };

  componentDidMount() {
    this.props.getAllUserContactsFunc();
  }

  onContactClick = (id) => {
    const { selectedContacts } = this.state;
    let updatedContacts = [...selectedContacts];
    if (updatedContacts.indexOf(id) === -1) {
      updatedContacts.push(id);
      this.setState({
        selectedContacts: updatedContacts
      });
    }
  }

  onDeleteClick = (id) => {
    const { selectedContacts } = this.state;
    const elemIndex = selectedContacts.indexOf(id);
    if (elemIndex > -1) {
      const updatedContacts = selectedContacts.filter(contactdocID => contactdocID !== id);
      this.setState({
        selectedContacts: updatedContacts
      });
    }
  }

  onCreateGroup = async ({ name, image }) => {
    const { selectedContacts } = this.state;
    const { userInfo, createGroupDetails, contacts, history } = this.props;
    const userKey = userInfo.Data.userDetails[0].personalDetails.docID;
    let obj = {
      aboutMe: userInfo.Data.userDetails[0].personalDetails.aboutMe,
      displayName: userInfo.Data.userDetails[0].personalDetails.firstName,
      docID: userInfo.Data.userDetails[0].personalDetails.docID,
      firstName: userInfo.Data.userDetails[0].personalDetails.firstName,
      isAdded: true,
      lastName: userInfo.Data.userDetails[0].personalDetails.lastName,
      position: userInfo.Data.userDetails[0].personalDetails.position || userInfo.Data.userDetails[0].personalDetails.servicesOffered || '',
      profilePictureURL: userInfo.Data.userDetails[0].personalDetails.profilePictureURL,
      searchHandle: userInfo.Data.userDetails[0].personalDetails.searchHandle,
      title: userInfo.Data.userDetails[0].personalDetails.title,
    }
    
    const selectedContactsUserId = selectedContacts
      .map(selectedDocId => {
        const userObjArr = contacts.filter(obj => obj.contact.docID === selectedDocId);
        return userObjArr[0].contact;
      });

    let allMembersDetails = [...selectedContactsUserId]
    allMembersDetails.push(obj);
    const groupId = await createGroupDetails({
      key: userKey,
      name,
      image,
      members: allMembersDetails,
    });
    history.push(`${MESSENGER}?conversationId=${groupId}`);
  }

  generateMembersSelectView = () => {
    const { contacts } = this.props;
    const { selectedContacts } = this.state;
    const members = contacts
      .map(obj => obj.contact)
      .filter(obj => (selectedContacts.indexOf(obj.docID) > -1));
    return (<div className="create-group-container">
      <div className="group-info-container">
        <div className="group-info-header">
          Create New Group
                </div>
        <div className="group-info-body" style={{margin: (selectedContacts.length > 0) ?0 :'auto'}}>
          {
            (selectedContacts.length > 0) ?
              <GroupMembers
                header={'Added to Group'}
                onRemoveClick={this.onDeleteClick}
                submitButtonText={'Next'}
                members={members}
                onSubmitClick={() => this.setState({ membersSelected: true })}
              />
              :
              <div className="select-text">
                Select to add to Group
                        </div>
          }

        </div>
      </div>
      <div className="user-contact-container">
        <Contacts
          contacts={contacts.map(obj => obj.contact)}
          onContactClick={this.onContactClick}
          header={'Select Members'}
        />
      </div>
    </div>);
  }

  generateAddGroupInfoView = () => {

    const { contacts } = this.props;
    const { selectedContacts } = this.state;
    const members = contacts
      .map(obj => obj.contact)
      .filter(obj => (selectedContacts.indexOf(obj.docID) > -1));

    return (<div className="create-group-container">
      <div className="group-info-container">
        <div className="group-info-header">
          Create New Group
                </div>
        <div className="group-info-body">
          <GroupDetails
            onSubmitClick={data => this.onCreateGroup(data)}
            submitButtonText={'Next'}
          />
        </div>
      </div>
      <div className="user-contact-container">
        <Contacts
          contacts={members}
          onContactClick={() => console.log('Contact clicked')}
          header={'Group Members'}
          isAddIcon={false}
        />
      </div>
    </div>);
  }

  render() {
    const { membersSelected } = this.state;
    return (membersSelected ? this.generateAddGroupInfoView() : this.generateMembersSelectView());
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.ContactsReducer.contacts,
    userInfo: state.Authreducer.user
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getAllUserContactsFunc,
    createGroupDetails,
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateGroup);