import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button} from 'reactstrap';
import {checkAvailableAccess} from '../../../constants/localMessage/errorMessage';

import './notificationSetting.css';

import {
  getProfileDashboard,
  userSettingsModifyNotificationSettings,
} from '../../../actions/Authaction';

class NotificationSetting extends Component {
  constructor (props) {
    super (props);
    this.state = {
      rdeeMessenger: false,
      rdeeMarketplace: false,
      rdeePay: false,
      otherNotifications: false,
      emailAlerts: false,
    };
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if (
        nextProps.userInfo &&
        nextProps.userInfo.dashboardprofile &&
        nextProps.userInfo.dashboardprofile.userSettings
      ) {
        if (
          nextProps.userInfo.dashboardprofile.userSettings &&
          nextProps.userInfo.dashboardprofile.userSettings.notificationSettings
        ) {
          const getData =
            nextProps.userInfo.dashboardprofile.userSettings
              .notificationSettings;
          this.setState ({
            rdeeMessenger: getData.rdeeMessenger|| getData.rdeeMessenger!=="undefined"?getData.rdeeMessenger: false,
            rdeeMarketplace: getData.rdeeMarketplace || getData.rdeeMarketplace!=="undefined"?getData.rdeeMarketplace:false,
            rdeePay: getData.rdeePay || getData.rdeePay!=="undefined"?getData.rdeePay:false,
            otherNotifications: getData.otherNotifications || getData.otherNotifications!=="undefined"?getData.otherNotifications: false,
            emailAlerts: getData.emailAlerts || getData.emailAlerts!=="undefined"?getData.emailAlerts: false,
          });
        }
      }
    }
  }
  componentDidMount () {
    let sendRequestUserProfile = {
      data: {},
    };
    this.props.getProfileDashboard (sendRequestUserProfile);
  }

  submitNotificationProfile () {
    const {
      rdeeMessenger,
      rdeeMarketplace,
      rdeePay,
      otherNotifications,
      emailAlerts,
    } = this.state;
    const sendRequest = {
      data: {
        rdeeMessenger:rdeeMessenger ?rdeeMessenger:false,
        rdeeMarketplace:rdeeMarketplace?rdeeMarketplace:false,
        rdeePay:rdeePay?rdeePay:false,
        otherNotifications:otherNotifications?otherNotifications:false,
        emailAlerts:emailAlerts ?emailAlerts:false,
      },
    };
    this.props.userSettingsModifyNotificationSettings (sendRequest);
  }

  render () {
    const {
      rdeeMessenger,
      rdeeMarketplace,
      rdeePay,
      otherNotifications,
      emailAlerts,
    } = this.state;
    return (
      <div style={{display: 'flex'}}>
        <div className="notuingmaindiv">
          <div className="aaunotificmhding">
            {' '}
            NOTIFICATION SETTINGS
          </div>
          <div className="NotificationOFFON">
            OFF<span>/ON</span>
          </div>
          {checkAvailableAccess ("messenger") && checkAvailableAccess ("messenger")=== 'messenger' &&<div className="notiticationContetToggleBoxArea">
            <p className="RDEEPay">RDEE Connect</p>
            <label className="notificationswitch">
              <input
                type="checkbox"
                value={rdeeMessenger}
                checked={rdeeMessenger}
                onChange={() => {
                  this.setState ({
                    rdeeMessenger: !rdeeMessenger,
                  });
                }}
              />
              <span className="notificationslider round" />
            </label>
          </div>}
          {checkAvailableAccess ("rdeepay") && checkAvailableAccess ("rdeepay")=== 'rdeepay' && <div className="notiticationContetToggleBoxArea">
            <p className="RDEEPay">Settle</p>
            <label className="notificationswitch">
              <input
                type="checkbox"
                value={rdeePay}
                checked={rdeePay}
                onChange={() => {
                  this.setState ({
                    rdeePay: !rdeePay,
                  });
                }}
              />
              <span className="notificationslider round" />
            </label>
          </div>}
          {checkAvailableAccess ("marketplace") && checkAvailableAccess ("marketplace")=== 'marketplace' &&<div className="notiticationContetToggleBoxArea">
            <p className="rdemarketplaxdsv">R-DEE Marketplace</p>
            <label className="notificationswitch">
              <input
                type="checkbox"
                value={rdeeMarketplace}
                checked={rdeeMarketplace}
                onChange={() => {
                  this.setState ({
                    rdeeMarketplace: !rdeeMarketplace,
                  });
                }}
              />
              <span className="notificationslider round" />
            </label>
          </div>}
          <div className="notiticationContetToggleBoxArea">
            <p className="OtherNot">Other Notifications</p>
            <label className="notificationswitch">
              <input
                type="checkbox"
                value={otherNotifications}
                checked={otherNotifications}
                onChange={() => {
                  this.setState ({
                    otherNotifications: !otherNotifications,
                  });
                }}
              />
              <span className="notificationslider round" />
            </label>
          </div>
          <div className="notiticationContetToggleBoxArea">
            <p className="Emailaler">Email alerts</p>
            <label className="notificationswitch">
              <input
                type="checkbox"
                value={emailAlerts}
                checked={emailAlerts}
                onChange={() => {
                  this.setState ({
                    emailAlerts: !emailAlerts,
                  });
                }}
              />
              <span className="notificationslider round" />
            </label>
          </div>
          <div style={{textAlign: 'center'}}>
            <Button
              className="notificavecbuttin"
              onClick={() => this.submitNotificationProfile ()}
              data-rh="Save"
            >
              Save Changes
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    userInfo: state.Authreducer,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      userSettingsModifyNotificationSettings,
      getProfileDashboard,
    },
    dispatch
  );
};
export default connect (mapStateToProps, mapDispatchToProps) (
  NotificationSetting
);
