import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from 'reactstrap';
import logo from '../logo.svg';
import * as routes from '../../constants/routes';
import {auth, db} from '../../firebase';
import {getFindBusiness, register} from '../../actions/Authaction';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons';

//################### Sign Up Form ###################

//A Higher order function with prop name as key and the value to be assigned to
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class BusinessDatabasePage extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      showingAlert: false,
      error: false,
      searchBy: true,
      searchString: '',
      businessData: [],
      isSearch: false,
      selectedBusiness: [],
    };
  }
  // onChange = (propName, value) => {
  //   this.setState({
  //     [propName]: value
  //   });
  // };

  componentWillReceiveProps (nextProps) {
;
    // this.props.history.push(routes.INFORMATIONSUBMIT)

    if (nextProps && nextProps.userinfo) {
      if (
        nextProps.userinfo.registeruser &&
        nextProps.userinfo.registeruser.result
      ) {
        if (nextProps.userinfo.registeruser.result.status) {
          this.props.history.push (routes.INFORMATIONSUBMIT);
        }
      }
    }

    if (nextProps) {
      if (
        nextProps.userinfo.findBusiness &&
        nextProps.userinfo.findBusiness.result &&
        nextProps.userinfo.findBusiness.result.status
      ) {
     
        this.setState ({
          businessData: nextProps.userinfo.findBusiness.result.Data,
        });
      }
    }
  }

  async componentDidMount () {
    const bussinessAdd = await JSON.parse (
      localStorage.getItem ('RegisterData')
    );
    if (bussinessAdd) {
      this.setState ({
        isSearch: false,
        selectedBusiness: bussinessAdd,
      });
    }
  }

  handleChange () {
    let searchString = '';

    if (this.state.searchString) {
      searchString = this.state.searchString;
    }

    if (this.state.searchString && this.state.searchString.length > 2) {
      // let sendRequest= {
      //     data: {
      //         nameSearchString: this.state.searchBy? searchString: false,
      //         idSearchString: !this.state.searchBy? searchString: false,
      //         nameOffset: false
      //     }
      // }
      let sendRequest = {
        data: {
          nameSearchString: this.state.searchBy ? searchString : false,
          idSearchString: !this.state.searchBy ? searchString : false,
          limit: 5,
        },
      };
      this.setState ({
        isSearch: true,
      });
      this.props.getFindBusiness (sendRequest);
    }
    //  else{

    // }
  }
  onSelect = item => {
    this.setState ({
      isSearch: false,
      selectedBusiness: item,
    });
 

    localStorage.setItem ('RegisterData', JSON.stringify (item));
  };

  async onSubmit () {
    const {username, email, passwordOne, selectedBusiness} = this.state;
    const {history} = this.props;



    const userPersonalInfo = await JSON.parse (
      localStorage.getItem ('newUserInfoPersonal')
    );
 

    const newRegisterUserInfo = await JSON.parse (
      localStorage.getItem ('newUserInfo')
    );


    let sendRequest = {
      data: {
        businessID: "",
        acceptedTermsConditions: true,
        businessAddress_1: userPersonalInfo && userPersonalInfo.address1,
        businessAddress_2: userPersonalInfo && userPersonalInfo.address2,
        businessCity: selectedBusiness.businessCity,
        businessCountry: selectedBusiness.businessCountry,
        businessEmailAddress: newRegisterUserInfo && newRegisterUserInfo.emailAddress,
        businessPhoneNumber: newRegisterUserInfo && newRegisterUserInfo.phoneNumber,
        businessPostalCode: userPersonalInfo && userPersonalInfo.postalCode,
        businessRegistrationFileType: 'application/pdf',
        businessSize: '',
        businessState: userPersonalInfo && userPersonalInfo.state,
        businessTaxFileType: 'application/pdf',
        businessType: '',
        businessWebsiteUrl: '',
        displayedBusinessName: userPersonalInfo && userPersonalInfo.displayName,
        registeredBusinessName: userPersonalInfo && userPersonalInfo.displayName,
        representativeFirstName: userPersonalInfo && userPersonalInfo.firstName,
        representativeLastName: userPersonalInfo && userPersonalInfo.lastName,
        yearEstablished: '',
        businessRegistration: '',
      },
    };
    this.props.register (sendRequest);

    // event.preventDefault(); //prevents refreshing
  }

  timer = () => {
    this.setState ({
      showingAlert: true,
    });

    setTimeout (() => {
      this.setState ({
        showingAlert: false,
      });
    }, 4000);
  };

  render () {
    const {error, showingAlert, selectedBusiness} = this.state;
    //a boolen to perform validation
    const isInvalid = '';

    return (
      <div className="div-flex-education" style={{height:'100vh'}}>
        <div className="">

          <div className="innerScrollerAutoComplete">
            {showingAlert &&
              <Alert color="danger" onLoad={this.timer}>
                {error.message}
              </Alert>}
            <div className="text-center">
              <img src={logo} />
            </div>
            <div className="pClass">
              <p>
                Look up for your business from our database.
              </p>
            </div>
            <div >

              <Input
               
                type="text"
                name="search"
                id="search"
                placeholder={
                  this.state.searchBy ? ' Business Name *' : ' Business ID *'
                }
                value={this.state.searchString}
                onChange={e =>
                  this.setState (
                    byPropKey ('searchString', e.target.value),
                    () => {
                      this.handleChange ();
                    }
                  )}
              />
              {this.state.isSearch &&
                this.state.searchString.length > 0 &&
                <div
                  className="autocomplete-items"
                  style={{
                    maxHeight: 300,
                    width: 400,
                    overflow: 'hidder',
                    overflowY: 'scroll',
                    color: '#fff',
                  }}
                >
                  {this.state.businessData &&
                    this.state.businessData.length > 0 &&
                    this.state.businessData.map ((item, index) => (
                      <p
                        key={index}
                        onClick={() => this.onSelect (item)}
                        style={{paddingLeft: 10}}
                      >
                        {item && item.registeredBusinessName
                          ? item.registeredBusinessName
                          : ''}
                      </p>
                    ))}
                    {this.state.businessData &&
                      this.state.businessData.length > 0 &&
                      this.state.businessData.map ((item, index) => (
                        <p
                          key={index}
                          onClick={() => this.onSelect (item)}
                          style={{paddingLeft: 10}}
                        >
                          {item && item.registeredBusinessName
                            ? item.registeredBusinessName
                            : ''}
                        </p>
                      ))}
                </div>}

            </div>

            {this.state.searchString &&
              this.state.searchString.length > 2 &&
              this.state.businessData &&
              this.state.businessData.length == 0 &&
              <div>
                <p className="pClass" style={{color: '#FF3C3C', fontSize: 16}}>
                  No Businesse  Found
                </p>
              </div>}

            {this.state.selectedBusiness &&
              this.state.selectedBusiness &&
              <div className="pClass">
                <p style={{color: '#60FF52'}}>
                  One Result Found
                </p>
              </div>}
            {this.state.selectedBusiness &&
              this.state.selectedBusiness &&
              <div >
                <p style={{color: '#8C8C8C', margin: 15}}>

                  Business Name
                  <p style={{color: '#fff'}}>
                    {selectedBusiness && selectedBusiness.registeredBusinessName
                      ? selectedBusiness.registeredBusinessName
                      : ''}
                  </p>
                </p>
                <p style={{color: '#8C8C8C', margin: 15}}>
                  Business ID
                  <p style={{color: '#fff'}}>
                    {selectedBusiness && selectedBusiness.businessID
                      ? selectedBusiness.businessID
                      : ''}
                  </p>
                </p>
                <div style={{display:'flex'}}>
                <p style={{color: '#8C8C8C', margin: 15}}>
                  Country
                  <p style={{color: '#fff'}}>
                    {selectedBusiness && selectedBusiness.businessCountry
                      ? selectedBusiness.businessCountry
                      : ''}
                  </p>
                </p>
                <p style={{color: '#8C8C8C', margin: 15,marginLeft:70}}>
                  City
                  <p style={{color: '#fff'}}>
                    {selectedBusiness && selectedBusiness.businessCity
                      ? selectedBusiness.businessCity
                      : ''}
                  </p>
                </p>
                </div>
              </div>}

            <div className="text-center">

              <Button
                onClick={() => this.onSubmit ()}
                disabled={!selectedBusiness}
                /* disabled={isInvalid} */ type="submit"
                className="nextcommanButtonClass"
              >
                Next
              </Button>

            </div>
            <div
              className="text-center"
              onClick={() => {
                this.setState ({
                  searchBy: !this.state.searchBy,
                });
              }}
            >

              <p
                className="contentCenter"
                style={{ cursor: 'pointer',marginTop:10}}
              >
                {' '}
                Search with Business {this.state.searchBy ? 'ID' : 'Name'}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userinfo: state.Authreducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getFindBusiness,
      register,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  BusinessDatabasePage
);
