import React, {Component} from 'react';
import {
  Button,
  Modal, ModalHeader, ModalBody,Media
} from 'reactstrap';
import Vector4 from '../../images/Vector4.png';

import pacImagea from '../../images/pacs/3TImagea.png';
import pacImagea1 from '../../images/pacs/3TImagea1.png';
import addIcon from '../../images/pacs/plusAdd.png';
import checkIcon from '../../images/pacs/checkmark.png';
import closeIcon from '../../images/close_icon.png';

import packs1 from '../../images/pacs/pacs1.png';
import packs2 from '../../images/pacs/pacs2.png';
import packs3 from '../../images/pacs/pacs3.png';
import packs4 from '../../images/pacs/pacs4.png';
import packs5 from '../../images/pacs/pacs5.PNG';
import packs6 from '../../images/pacs/pacs6.PNG';
import packs7 from '../../images/pacs/pacs7.PNG';
// import * as routes from "../constants/routes";

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import './pacs.css';

import {createNewWallet } from '../../actions/Paymentaction';
import {DEV_SERVER} from '../../services/servicesConfig';


import pacscoming from '../../images/comingsoon/pacscoming.svg';
import {checkAvailableAccess} from './../../constants/localMessage/errorMessage';

import  CommonAccessContainer from './../../components/UI/commonAccessContainer/commonAccessContainer';


const packData= [{image : packs1,date : '12/08/19 - 5:12 pm', code :'#391371', key: 'Brain Tumor CT - Dr. John Doe Albert - 120819', URL: 'https://radiology-ff095.firebaseapp.com/?fileName=https://raw.githubusercontent.com/ivmartel/dwv/master/tests/data/nema-mr1_jply.dcm' }, 
{image : packs2, date : '12/08/19 - 5:12 pm', code :'#387425', key: 'Neck Fracture CT - Dr. John Doe Albert', URL: 'https://radiology-ff095.firebaseapp.com/?fileName=https://raw.githubusercontent.com/ivmartel/dwv/master/tests/data/bbmri-53323419.dcm' },

{image : packs3, date : '01/08/19 - 5:12 pm', code :'#391371', key: 'Vertebra Fracture CT - Dr. John Doe Albert - 010817; 0512pm-391371', URL: 'https://radiology-ff095.firebaseapp.com/?fileName=https://raw.githubusercontent.com/Parkulus/dicom/master/CAT-SCAN-CARDIAQUE/IM-0001-0001.dcm' },
{image : packs4, date : '27/07/19 - 10:35 am', code :'#376153', key: 'Brain Tumor CT-Dr.John Doe Albert-210719; 1035am - 376153', URL: 'https://radiology-ff095.firebaseapp.com/?fileName=https://raw.githubusercontent.com/Parkulus/dicom/master/IRM%20DE%20LA%20COLONNE%20CERVICO-DORSO-LOMBAIRE/IM-0001-0002.dcm' },
{image : packs5, date : '27/07/19 - 10:35 am', code :'#376153', key: 'Brain Tumor CT-Dr.John Doe Albert-210719; 1035am - 376153', URL: 'https://radiology-ff095.firebaseapp.com/?fileName=https://raw.githubusercontent.com/Parkulus/dicom/master/IRM%20DE%20LA%20COLONNE%20CERVICO-DORSO-LOMBAIRE/IM-0001-0004.dcm' },
{image : packs6, date : '27/07/19 - 10:35 am', code :'#376153', key: 'Brain Tumor CT-Dr.John Doe Albert-210719; 1035am - 376153', URL: 'https://radiology-ff095.firebaseapp.com/?fileName=https://raw.githubusercontent.com/Parkulus/dicom/master/IRM%20DE%20LA%20COLONNE%20CERVICO-DORSO-LOMBAIRE/IM-0001-0005.dcm' },
{image : packs7, date : '27/07/19 - 10:35 am', code :'#376153', key: 'Brain Tumor CT-Dr.John Doe Albert-210719; 1035am - 376153', URL: 'https://radiology-ff095.firebaseapp.com/?fileName=https://raw.githubusercontent.com/Parkulus/dicom/master/IRM%20DE%20LA%20COLONNE%20CERVICO-DORSO-LOMBAIRE/IM-0001-0006.dcm' }
]

class PacsPage extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      step: 1,
      addNew: false,
      successmodal: false,
      openLink: '',
      isAccess:false
    };
  }




  componentDidMount(){

    if(checkAvailableAccess ("pacs") && checkAvailableAccess ("pacs")=== 'pacs'){
      this.setState({
        isAccess: true
      })
    }else{
      this.setState({
        isAccess: false
      })
    }
   
  }

  

  doCreate(){
    let sendRequest={
        user_id: this.state.userid,
	      passphrase: this.state.newpin
    }
    this.props.createNewWallet(sendRequest);
  }
  modalToggle(){
    this.setState({
      successmodal: !this.state.successmodal
    })
  }
  openLink(link){
    // var win = window.open(link,'_self');
    // win.focus();
    this.setState({
      openLink: link
    })

  }

  openLinkNewTab(){
     var win = window.open(this.state.openLink,'_blank');
    win.focus();
  }

  render () {
    const {isAccess}= this.state;
    return (
      <div>
       
      {isAccess && <div className="flex ">
      <div className="mainContainerPacks">

      {!this.state.openLink && <p className="uploadImageTitle"> 

              {this.state.addNew ? 'Add New Image': 'Uploaded Images'}
              
              </p>}
              {this.state.openLink && <div className="iframeSizeDiv" style={{marginTop:20}}>

              <Button className="backButton" onClick={()=>this.openLink('')} style={{float:'left'}}> 
                 Back
              </Button>

              <Button className="backButton" onClick={()=>this.openLinkNewTab()} style={{float:'right'}}> 
                 Large View
              </Button>
              <div>

                <iframe  className="iframeSize" src={this.state.openLink} ></iframe>
              </div>

              </div>}
              {!this.state.addNew && !this.state.openLink && <div className="ListContainer">
              {packData.map ((item, index) => (<Media className="mediaListBackground" key={index} onClick={()=>this.openLink(item.URL)}>
                  <Media left >
                    <Media className="listImageIcon" object src={item.image} alt="Generic placeholder image" />
                  </Media>
                  <Media body>
                    <Media heading className="mediaTitle" >
                      <p style={{marginTop:15}}>{item.date}</p>
                      <p>{item.code}</p>
                    </Media>
                   <p className="mediaDescription">{item.key}</p>
                  </Media>
                </Media>))}
              

              </div>}

          
              {this.state.addNew && <div className="ListContainer">
              <div style={{marginLeft:50}}>

                <p className="pictureTitle">Pictures</p>
                <p className="pictureMsg">Please upload an image of DCM filetype (Maximum 50 MB)</p>

                <input type="file" name="file" id="file" className="inputfile" />
                <label className="labelButton" for="file"> <img alt="plusButtonImg" className="plusButtonImg" src={addIcon} /></label>

                <p className="errormessage">You have reached the maximum number of uploads per hour. Please wait for 30 minutes before uploading again.</p>


                <Button className="uploadButton" onClick={()=>this.modalToggle()}>
                   Upload
                </Button>
                </div>




                <Modal size="md" isOpen={this.state.successmodal} centered={true}>

                
                    <ModalBody
                      style={{ borderRadius: 10,textAlign:'center',marginTop:20}}
                    >
                    <div className="ModalContainer">
                    <Button className="closeModal" onClick={()=>this.modalToggle()}>
                    <img src={closeIcon} />
                    </Button>
                      <div className="blueBackground">
                       
                        <p className="modalTitle">Upload Successfull</p>

                        <img src={pacImagea} className="successImageICon"/>

                        <p className="successFullyUpload"> <img src={checkIcon} style={{width:15,marginRight:10}}/>Upload Successfull</p>

                        <div>
                          <p className="modalLabel">File Name</p>
                          <p className="modelBoldDescription">Brain Tumor CT - Dr. John - 120819; 0512pm- 391371</p>
                        </div>
                        <div>
                          <p  className="modalLabel">RDGX Secure Image View TX ID</p>
                          <p className="modelBoldDescription">011o8c4775yo23uxm0349j477293856y23j7d48y398</p>
                        </div>
                      </div>
                    <div style={{paddingBottom:30,paddingTop:10}}>

                        <Button className="uploadButton">
                        View Image
                    </Button>
                    <p className="uploadAnother" onClick={()=>this.modalToggle()}>Upload Another Image</p>
                    </div>
                 </div>


                     
                    </ModalBody>
                </Modal>



              
              </div>}
             {/*} <Button className="plusButton" onClick={()=>this.setState({
                addNew: true
              })}>
                  <img className="plusButtonImg" src={addIcon} />
            </Button>*/}

            
             
      </div>





     


      </div>

  }
  {!isAccess  && <CommonAccessContainer bgImage={pacscoming} message={checkAvailableAccess('pacs')} />}

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    payment: state.PaymentReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators ({
    createNewWallet
  }, dispatch);
};

export default connect (mapStateToProps, mapDispatchToProps) (PacsPage);
