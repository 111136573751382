import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from 'reactstrap';
import logo from './../../logo.svg';
import * as routes from '../../../constants/routes';
import {auth, db} from '../../../firebase';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import union from '../../../images/profile/union.png';

import {connect} from 'react-redux';
import {
  GetEducationalDetail,
  AddEducationalDetail,
  DeleteEducationalDetail,
} from '../../../actions/Authaction';
import {getUserDetail} from '../../../actions/contacts';

import {bindActionCreators} from 'redux';
import Ellipse from '../../../images/Ellipse1.png';
import Vector from '../../../images/Vector.png';

import './../profile.css';

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class EducationEditPage extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      yearCompleted: '',
      university: '',
      degreeType: '',
      courseMajor: '',
      error: null,
      showingAlert: false,
      isAddEducation: false,
      educationData: [],
    };
  }

  componentWillReceiveProps (nextProps) {
   
    if (nextProps) {
      //   if(nextProps.userinfo.educationDetails && nextProps.userinfo.educationDetails.result && nextProps.userinfo.educationDetails.result.status){
      //     this.setState({
      //   educationData: nextProps.userinfo.educationDetails.result.Data,
      //   isAddEducation: false,
      //   yearCompleted: '',
      //   university: '',
      //   degreeType: '',
      //   courseMajor: '',
      //   userid:''
      //     })
      //   }
      if (
        nextProps.contacts &&
        nextProps.contacts.contactDetail &&
        nextProps.contacts.contactDetail.length > 0
      ) {
        let getData = nextProps.contacts.contactDetail;
        this.setState ({
          educationData: getData[2].educationDetails,
          isAddEducation: false,
          yearCompleted: '',
          university: '',
          degreeType: '',
          courseMajor: '',
        });
      }
    }
  }
  componentDidMount () {
    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
    if (loginUser) {
      this.setState ({
        userid: loginUser.docID,
      });
      let sendRequest = {
        data: {
          getPersonalDetails: true,
          getProfessionalDetails: true,
          getEducationalDetails: true,
          getBusinessProfile: true,
          docID: loginUser.docID,
        },
      };
      this.props.getUserDetail (sendRequest);
    }

    // const sendRequest ={
    //   data:{}
    // }
    // this.props.GetEducationalDetail(sendRequest);
  }

  // onChange = (propName, value) => {
  //   this.setState({
  //     [propName]: value
  //   });
  // };

  onSubmit = event => {
    event.preventDefault (); //prevents refreshing
    const {history} = this.props;

    const sendRequest = {
      data: {
        yearCompleted: this.state.yearCompleted,
        university: this.state.university,
        degreeType: this.state.degreeType,
        courseMajor: this.state.courseMajor,
        // docID: this.state.userid,
      },
    };
    this.props.AddEducationalDetail (sendRequest);
  };
  Deletedetail = item => {
    const data = {
      data: {
        docID: item.docID,
      },
    };
    this.props.DeleteEducationalDetail (data,true);
  };
  timer = () => {
    this.setState ({
      showingAlert: true,
    });

    setTimeout (() => {
      this.setState ({
        showingAlert: false,
      });
    }, 4000);
  };

  render () {
    const {
      yearCompleted,
      university,
      degreeType,
      courseMajor,
    } = this.state;
    //a boolen to perform validation
    const isInvalid =
      yearCompleted === '' ||
      university === '' ||
      degreeType === '' ||
      courseMajor === '';

    return (
      <div className="editProfessionScroll div-flex-signup">
        {!this.state.isAddEducation &&
          <Row  style={{marginTop:110}}>
          <Button className="backButton" onClick={()=>this.props.history.goBack()} style={{position:'fixed',marginLeft:20,zIndex: 9999999}}>
          <img src={union}  style={{marginRight:10}}/> Profile
        </Button>
            
            <Col
              sm="12"
              md="12"
              lg="12"
              className="text-center"
            >
            
              <img src={logo} />
            </Col>
            <Col
              className="text-center"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              className="pClass"
            >

              {this.state.educationData.length == 0 &&
                <Col>
                  <p style={{fontSize: 20}}>
                    Add your Educational Details
                  </p>

                  <Col
                    className="text-center"
                    xs="12"
                    sm="12"
                    md={{size: 5, offset: 4}}
                    lg="4"
                    style={{
                      backgroundColor: '#0C0F1D',
                      marginTop: '30px',
                      borderRadius: '5px',
                      height: '60px',
                      justifyContent: 'center',
                    }}
                  >
                    <p
                      style={{
                        fontSize: 16,
                        lineHeight: '55px',
                        fontStyle: 'italic',
                      }}
                    >
                      {' '}No Education Details Added
                    </p>
                  </Col>
                </Col>}

            </Col>
            {this.state.educationData &&
              this.state.educationData.map (item => (
                <Col xs="12" sm="12" lg="12" md="12">

                  <Col
                    xs="12"
                    sm="12"
                    lg="6"
                    md={{size: 3, offset: 3}}
                    style={{backgroundColor: '#0C0F1D', borderRadius: 5}}
                  >

                    <div onClick={() => this.Deletedetail (item)}>
                      <img
                        src={Ellipse}
                        style={{float: 'right', marginTop: 21}}
                      />
                      <img
                        src={Vector}
                        style={{
                          float: 'right',
                          marginTop: 29,
                          position: 'relative',
                          left: 14,
                        }}
                      />
                    </div>

                    <p
                      style={{
                        color: '#FFFFFF',
                        fontSize: 14,
                        lineHeight: 3,
                        margin: 0,
                        fontFamily: 'Helvetica',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                      }}
                    >
                      {' '}{item.courseMajor} - {item.yearCompleted}
                    </p>
                    <p
                      style={{
                        color: '#FFFFFF',
                        fontfamily: 'Helvetica',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: '14px',
                        lineHeight: 3,
                        marginTop: '-23px',
                      }}
                    >
                      {item.university}
                    </p>

                  </Col>
                </Col>
              ))}
            <Col
              xs="12"
              sm="12"
              lg="4"
              md={{size: 4, offset: 4}}
              className="text-center"
              style={{marginTop: 10}}
            >
              <Button
                className="buttonClass"
                onClick={() =>
                  this.setState ({
                    isAddEducation: true,
                  })}
              >
                Add Educational Details
              </Button>

            </Col>
            

          </Row>}

        {this.state.isAddEducation &&
          <form onSubmit={this.onSubmit}>
            <Row>
              <Col sm="12" md="12" lg="12" className="text-center">
                <img src={logo} />
              </Col>
              <Col
                className="text-center"
                xs="12"
                sm="12"
                md={{size: 4, offset: 4}}
                lg="4"
                className="pClass"
              >
                <p>
                  Add Educational Details
                </p>
              </Col>
              <Col
                className="alignFormField"
                xs="12"
                sm="12"
                md={{size: 4, offset: 4}}
                lg="4"
              >

                <FormGroup className="formInputField">
                  <Input
                    type="select"
                    name="yearCompleted"
                    id="select"
                    className="inputSelect"
                    value={this.state.yearCompleted}
                    onChange={e =>
                      this.setState (
                        byPropKey ('yearCompleted', e.target.value)
                      )}
                  >
                    <option xs="12" lg="4" value="">
                    Year Of Completion
                    </option>
                    <option xs="12" lg="4" value="2013">2013</option>
                    <option xs="12" lg="4" value="2014">2014</option>
                    <option xs="12" lg="4" value="2014">2015</option>
                    <option xs="12" lg="4" value="2016">2016</option>
                  </Input>

                </FormGroup>
              </Col>
              <Col
                className="alignFormField"
                xs="6"
                sm="6"
                lg="4"
                md={{size: 4, offset: 4}}
              >
                <FormGroup className="formInputField">
                  <Input
                    type="text"
                    name="university"
                    id="university"
                    placeholder="University *"
                    value={this.state.university}
                    onChange={e =>
                      this.setState (byPropKey ('university', e.target.value))}
                  />
                </FormGroup>
              </Col>
              <Col
                className="alignFormField"
                xs="6"
                sm="6"
                lg="4"
                md={{size: 4, offset: 4}}
              >
                <FormGroup className="formInputField">
                  <Input
                    type="select"
                    name="degreeType"
                    id="select"
                    className="inputSelect"
                    value={this.state.degreeType}
                    onChange={e =>
                      this.setState (byPropKey ('degreeType', e.target.value))}
                  >
                    <option xs="12" lg="4" value="">Degree Type</option>
                    <option xs="12" lg="4" value="Associates">
                      Associates
                    </option>
                    <option xs="12" lg="4" value="MCA">MCA</option>
                    <option xs="12" lg="4" value="BCA">BCA</option>
                    <option xs="12" lg="4" value="BTECH">BTECH</option>
                    <option xs="12" lg="4" value="MBBS">MBBS</option>

                  </Input>

                </FormGroup>
              </Col>
              <Col
                className="alignFormField"
                xs="6"
                sm="6"
                lg="4"
                md={{size: 4, offset: 4}}
              >
                <FormGroup className="formInputField">
                  <Input
                    type="text"
                    name="Major courses"
                    id="courses"
                    placeholder="Major courses *"
                    value={this.state.courseMajor}
                    onChange={e =>
                      this.setState (byPropKey ('courseMajor', e.target.value))}
                  />
                </FormGroup>
              </Col>
              <Col
                className="text-center"
                xs="12"
                sm="12"
                lg="4"
                md={{size: 4, offset: 4}}
              >
                <Button
                  disabled={isInvalid}
                  className="buttonClass formInputField"
                >
                  Add{' '}
                </Button>
                { <Col xs="12" sm="12" lg="4" md={{ size: 4, offset: 4 }} className="text-center">
           
            <p onClick={()=>this.setState({  isAddEducation: false,})} style={{fontFamily: "Helvetica", marginTop:8, fontStyle: "normal", fontWeight: "bold", fontSize: 16, textAlign: "center", color: '#4092B7',cursor:'pointer'}}> 
                 Back
            </p> 
            </Col>}
                {/* <Button  style={{marginTop:20}} className="buttonClass" onClick={()=>this.setState({
              isAddEducation: false
            })}>
            Back
            </Button> */}

              </Col>
            </Row>

          </form>}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userinfo: state.Authreducer,
    contacts: state.ContactsReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getUserDetail,
      AddEducationalDetail,
      DeleteEducationalDetail,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  EducationEditPage
);
