import React, { Component } from 'react';
// import * as firebase from 'firebase';
import firebase from 'firebase';


// function App() {
class VerifyCaptcha extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.signInWithPhone();
  }

  signInWithPhone = () => {
    // this.props.Loading (false);
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'phoneVerify',
      {
        // 'size': 'invisible',
        callback: (recapchaToken) => {
          try {
            this.setState(
              {
                captchaToken: recapchaToken,
              },
              () => {
                this.props.tokenExpired(recapchaToken);

                // setTimeout()

                // this.props.doCallSendOTP();
              }
            );
          } catch (error) {
            console.log('ERORR = ' + error);
          }
        },
        'expired-callback': () => {
          try {
            // this.setState (
            //   {
            //     captchaToken: "",
            //   },()=>{
            //   });
            this.props.tokenExpired();
          } catch (error) {
            console.log('ERORR = ' + error);
          }
        },
      }
    );

    window.recaptchaVerifier.render().then(function(widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  };

  render() {
    return (
      <div>
        <div
          id='phoneVerify'
          style={{ padding: '5px 5px 0 5px', backgroundColor: '#fff' }}
        />
      </div>
    );
  }
}

export default VerifyCaptcha;
