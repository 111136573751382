import firebase from 'firebase';

export function getFileName(originalName) {
 // const ext = originalName.split('.').pop(); // Extract image extension
  // console.log("ext>>>>>>>>>ext", ext)
  // let stringWithoutSpace =  originalName.indexOf(' ') >= 0 ? originalName.split(' ').join('') : originalName;
  const re = /(?:\.([^.]+))?$/
  // return `${ext.toUpp}-${getFormattedTime()}-${originalName}`
  return `FILE-${getFormattedTime()}-${originalName}`
}

export function getFormattedTime() {
  var today = new Date()
  var y = today.getFullYear()
  // JavaScript months are 0-based.
  var m = today.getMonth() + 1
  var d = today.getDate()
  var h = today.getHours()
  var mi = today.getMinutes()
  var s = today.getSeconds()
  return y + '-' + m + '-' + d + '-' + h + '-' + mi + '-' + s
}

export const fileNameToBeSaved = (avatarSource) =>{
  // console.log("avatarSource>>>>>", avatarSource)
  
  // const ext = avatarSource.split('.').pop(); // Extract image extension
  // console.log("ext>>>>>>>>>ext", ext)
  let stringsArr =  avatarSource.split('/')
  // let lastNameToBeSaved = `${stringsArr[stringsArr.length -1]}.${ext}`
  let lastNameToBeSaved = `${stringsArr[stringsArr.length -1]}`;
  // let stringWithoutSpace =  lastNameToBeSaved.indexOf(' ') >= 0 ? lastNameToBeSaved.split(' ').join(''): lastNameToBeSaved;
  // console.log("stringWithoutSpace>>>>>", stringWithoutSpace, "lastNameToBeSaved>>>>>", lastNameToBeSaved)
  return lastNameToBeSaved
}

export const retrieveFileName = (url) =>{
  const storage = firebase.storage();
  let httpsReference = storage.refFromURL(url)
  return httpsReference.name
}

export const retrieveFileType = (url) =>{
  const storage = firebase.storage();
  let httpsReference = storage.refFromURL(url)
  const ext = httpsReference.name.split('.').pop(); // Extract image extension
  return ext
}


