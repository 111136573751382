
import firebase from 'firebase';
import { ShowNameAccordingToLength } from "../../utility/stringFunctions";
import { userSharedKey, CalculatedSharedSecret, encryptMessage } from '../../utility/encryption';
import _ from 'lodash';

// handle when group is edited then to show some message over chat
export const  _handleGroupInfoChanged = async (obj, props) => {
      const database = firebase.database();
      let messengerKey = obj.messengerKey,
      senderId = obj.senderId, // user own UID
      addedContactsArray = obj.addedContactsArray, // all group members
      text = obj.text, // this is text entered
      type = obj.type,
      secretKey = obj.secretKey,
      groupData = obj.groupData;  // this is type : 'text' or 'image'

      let senderDisplayName = ShowNameAccordingToLength(_.get(props,'userInfo.user.Data.userDetails[0].personalDetails.firstName','') + " " + _.get(props,'userInfo.user.Data.userDetails[0].personalDetails.lastName',''))
      let senderProfilePic = _.get(props,'userInfo.user.Data.userDetails[0].personalDetails.profilePictureURL', null)

      const message = {
        sender: senderId,
        text: encryptMessage(text.toString(), secretKey.toString()),
        type: type,
        timeStamp: Math.floor(Date.now()),
        read: false,
        sendType: 'normal', // forward, quote
        chatType: "groupChat",
        system: true,
        // showTopRightDropDown: false,
        senderProfilePic: senderProfilePic,
        senderName: props.userInfo.user.Data.userDetails[0].personalDetails
          .firstName,
        delivered: true,
      };
      if (text !== '' && (type == 'text' || type == 'videoCall')) {
        await database.ref('GroupMessages').child(messengerKey).child('messages').push(message).key;
        await database.ref('GroupMessages').child(messengerKey).child('metaData').update({
          "lastMsg": "Group info is changed",
          "sender": senderId,
          "senderName": senderDisplayName,
          'timeStamp': message.timeStamp,
          "mediaType": '',
          "type": type,
          "chatThread": messengerKey,
          "chatType": 'groupChat',
          "readBy": {
            [`${senderId}`]: {
              read: true
            }
          },
        });
      }

      let pushNotiFicationTargetsArr = []; // array whom to send notifications
      addedContactsArray.filter((val) => {
        if (val.docID !== senderId) {
          pushNotiFicationTargetsArr.push(val.docID)
        }
      })

      // sending notification on sending message 
    //   let objReq = {
    //     "data": {
    //       "sourceModule": "messenger", // "sourceModule" for push notifiaction and "source" for notification history
    //       // "sourceUID": "messengerKey",
    //       "MessengerGroupID": messengerKey,
    //       "messengerGroupID": messengerKey,   // ---> THIS IS FOR MUTES,
    //       "title": `R-DEE Connect`,
    //       "chatType": "groupChat",
    //       "body": `${senderDisplayName} sent a message`,
    //       "targetUID": pushNotiFicationTargetsArr,
    //       "titleChat" : groupData.groupName,
    //       "profilePictureURL" : `${groupData.groupIcon}`,
    //       "messengerKey": messengerKey, // for push notification body
    //       "screen": "groupChat" // // for push notification body
    //     }
    //   }

    //   props.sendnotification(objReq);
  }

// creating Group  Secret key
export const _handleGroupSecretKey = (groupPublicKey) => {

    return new Promise((resolve) => {
      let groupSharedKey = userSharedKey(groupPublicKey)
      let finalSecretKey = CalculatedSharedSecret(groupSharedKey, groupPublicKey)
        resolve(finalSecretKey);
      });

  }