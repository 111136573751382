import React, {useRef, Component} from 'react';
import {Button, Modal, ModalBody} from 'reactstrap';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import './Compose.css';
import inputVector from '../../../images/attachment_icon.png';
import attachmentIcon from '../../../images/icons/messenger/attachmentIcon.png';
import messageSend from '../../../images/icons/messenger/messageSend.png';
import blueCross from '../../../images/icons/messenger/blueCross.svg';

import voiceIcon from '../../../images/icons/messenger/voiceIcon.png';
import sendicon from '../../../images/icons/sendMoney.png';
import {getFileName} from '../../../utility/getFileName';
import whitePlayIcon from '../../../images/icons/vault/whitePlayIcon.svg';

import whiteFileIcon from '../../../images/icons/vault/whiteFileIcon.svg';
import whiteCameraIcon from '../../../images/icons/vault/whiteCameraIcon.svg';


import pptIcon from '../../../images/icons/messenger/pptIcon.svg';
import pdfIcon from '../../../images/icons/messenger/pdfIcon.svg';
import excelIcon from '../../../images/icons/messenger/excelIcon.svg';
import docIcon from '../../../images/icons/messenger/docIcon.svg';


import pacsIcon from '../../../images/icons/vault/pacsIcon.png';

import {formatFileSize} from '../../../utility/commonFunctions';


import {Loading} from '../../../actions/LoadingAction';

import {createNotification} from '../../../constants/notificationtoast';

import crossIcon from '../../../images/whiteCloseIcon.svg';
import firebase from 'firebase';

const database = firebase.database ();

class Compose extends Component {
  constructor (props) {
    super (props);
    this.state = {
      openPreview: false,
      showPreview: [],
      selectedFiles: [],
      captionText: '',
    };
    this.getFileType= this.getFileType.bind(this);
    this.getFileTypePreview= this.getFileTypePreview.bind(this);

  }

  componentDidMount(){
    this.props.Loading(false);

  }

  getFileType (file) {

    if (file.type.match ('image.*')) return 'image';

    if (file.type.match ('video.*')) return 'video';

    if (file.type.match ('audio.*')) return 'audio';
    if (file.type.match ('dicom.*')) return 'dicom';
    if (file.type.match ('pdf.*')) return 'pdf';


    if (!file.type.match ('image.*') && !file.type.match ('video.*') && !file.type.match ('audio.*') && !file.type.match ('dicom.*') && !file.type.match ('pdf.*')){
      let fileType = file.name.split ('.').pop ();
      if(fileType ==='ppt' || fileType ==='pot' || fileType ==='pps' || fileType ==='pptx' || fileType ==='potx' || fileType ==='ppsx' ||fileType ==='thmx'){
        return 'ppt';
      }

      if(fileType ==='xlsx' || fileType ==='xlsm' || fileType ==='xlsb' || fileType ==='xltx' || fileType ==='xltm' || fileType ==='xls' ||fileType ==='xlt' ||fileType ==='xml'||fileType ==='xlam'||fileType ==='xla'||fileType ==='xlr' || fileType ==='csv' ){
        return 'csv';
      }

      if(fileType ==='txt' || fileType ==='htm' || fileType ==='html' || fileType ==='doc' || fileType ==='docm' || fileType ==='docx' ||fileType ==='dot'){
        return 'doc';
      }
      
    }


    // etc...

    return 'other';
  }

  getFileTypePreview (file) {

    if (file.fileType.match ('image.*')) return file.base64;

    if (file.fileType.match ('video.*')) return whitePlayIcon;
    if (file.fileType.match ('audio.*')) return whitePlayIcon;
    if (file.fileType.match ('dicom.*')) return pacsIcon;
    if (file.fileType.match ('pdf.*')) return pdfIcon;



    if (!file.fileType.match ('image.*') && !file.fileType.match ('video.*') && !file.fileType.match ('audio.*') && !file.fileType.match ('dicom.*')  && !file.fileType.match ('pdf.*')){
      let fileType = file.filename.split ('.').pop ();
      if(fileType ==='ppt' || fileType ==='pot' || fileType ==='pps' || fileType ==='pptx' || fileType ==='potx' || fileType ==='ppsx' ||fileType ==='thmx'){
        return pptIcon;
      }

      if(fileType ==='xlsx' || fileType ==='xlsm' || fileType ==='xlsb' || fileType ==='xltx' || fileType ==='xltm' || fileType ==='xls' ||fileType ==='xlt' ||fileType ==='xml'||fileType ==='xlam'||fileType ==='xla'||fileType ==='xlr' || fileType ==='csv' ){
        return excelIcon;
      }

      if(fileType ==='txt' || fileType ==='htm' || fileType ==='html' || fileType ==='doc' || fileType ==='docm' || fileType ==='docx' ||fileType ==='dot'){
        return docIcon;
      }
      
    }


    // etc...

    return whiteFileIcon;
  }


  onSelectImage = async e => {
    let reseponse = this.setState ({imgSizeError: false, showPreview: []});
    // console.log (reseponse);
    if (!reseponse) {
      let self = this; // unsure if this is needed
      this.setState ({
        selectedFiles: e.target.files,
      });
      // console.log(e.target.files);
      for (let files of e.target.files) {
        let response = files.size / 1000000;

        if (response && response > 20) {
          this.setState ({
            imgSizeError: true,
          });
          createNotification ('error', 'File not acceptable more than 20 mb!');
          return;
        }

        this.setState ({
          openPreview: true,
        });

        let fileType = files.name.split ('.').pop ();

        // if (
        //   fileType.toLowerCase () === 'png' ||
        //   fileType.toLowerCase () === 'jpeg' ||
        //   fileType.toLowerCase () === 'jpg' ||
        //   fileType.toLowerCase () === 'pdf' ||
        //   fileType.toLowerCase () === 'mp4' ||
        //   fileType.toLowerCase () === 'm4p' ||
        //   fileType.toLowerCase () === 'M4v' ||
        //   fileType.toLowerCase () === 'avi' ||
        //   fileType.toLowerCase () === 'wmv' ||
        //   fileType.toLowerCase () === 'mpeg' ||
        //   fileType.toLowerCase () === 'mpe' ||
        //   fileType.toLowerCase () === 'mpv' ||
        //   fileType.toLowerCase () === 'mov' ||
        //   fileType.toLowerCase () === 'webm' ||
        //   fileType.toLowerCase () === 'mp3' ||
        //   fileType.toLowerCase () === 'ogg' ||
        //   fileType.toLowerCase () === 'aac' ||
        //   fileType.toLowerCase () === 'wma'
        // ) {
          // this.props.Loading(true);

          let reader = new FileReader ();
          let file = files;
          // var filename = new Date ().getTime ().toString () + files.name;
          // // getFileName(files.name)
          // filename = filename.replace (/\s/g, '');

          // console.log("updateImage>>>>>>>", files, filename)

          // getFileName()

          // console.log(file);

          // const storage = firebase.storage();
          // // const imageStorageUrl = (await storage.ref(`/attachPics/${filename}`).put(file)).downloadURL;
          // // console.log(imageStorageUrl);
          //   var imageStorageUrl;
          //   try{
          //        await storage.ref(`/attachPics/${filename}`).put(file).then(async (snapshot)=> {
          //           // console.log('Uploaded a blob or file!');

          //           this.props.Loading(false);
          //           await  snapshot.ref.getDownloadURL ().then (downloadURL => {
          //             imageStorageUrl=downloadURL;

          //           });
          //         })
          //   }catch (e) {
          //       this.props.Loading(false);
          //       createNotification("error", e.message);
          //       return;
          //   }
          //   if(!imageStorageUrl){
          //   this.props.Loading(false);

          //     createNotification("error", "Please try again");
          //     return;
          //   }

          //   //   console.log(imageStorageUrl);

          //   //   imageStorageUrl.snapshot.ref.getDownloadURL ().then (downloadURL => {
          //   //     console.log ('File available at', downloadURL);
          //   //   });
          //   //   this.props.Loading(false);

          //   //   return;
          //   // } catch (e) {
          //   //     this.props.Loading(false);
          //   //     createNotification("error", e.message);
          //   //     return;
          //   // }

          // let fileSenderType='image';
          // if(fileType.toLowerCase () === 'png' ||
          // fileType.toLowerCase () === 'jpeg' ||
          // fileType.toLowerCase () === 'jpg'){
          //   fileSenderType='image';
          // }

          // if( fileType.toLowerCase () === 'pdf'){
          //   fileSenderType='pdf';
          // }
          // if(fileType.toLowerCase () === 'mp4' ||
          //     fileType.toLowerCase () === 'm4p' ||
          //     fileType.toLowerCase () === 'M4v' ||
          //     fileType.toLowerCase () === 'avi' ||
          //     fileType.toLowerCase () === 'wmv' ||
          //     fileType.toLowerCase () === 'mpeg' ||
          //     fileType.toLowerCase () === 'mpe' ||
          //     fileType.toLowerCase () === 'mpv' ||
          //     fileType.toLowerCase () === 'mov' ||
          //     fileType.toLowerCase () === 'webm' ){
          //     fileSenderType='video';

          // }

          // if(fileType.toLowerCase () === 'mp3' ||
          // fileType.toLowerCase () === 'ogg' ||
          // fileType.toLowerCase () === 'aac' ||
          // fileType.toLowerCase () === 'wma' ){
          //   fileSenderType='audio';
          // }

          // this.props.updateImage(imageStorageUrl ,fileSenderType,files);
          // this.props.Loading(false);

          reader.onloadend = () => {
            var filename = new Date ().getTime ().toString () + files.name;
            filename = filename.replace (/\s/g, '');

            // console.log (reader.result);

            this.setState ({
              showPreview: this.state.showPreview.concat ({
                base64: reader.result,
                fileType: this.getFileType (file),
                filename: filename,
                filesize: file.size
              }),
            },()=>{
              // console.log(this.state.showPreview)
            });
          };

          reader.readAsDataURL (file);
        // } else {
        //   createNotification ('error', 'File not acceptable.Invalid format!');
        // }
      }
    }
  };

  handleKeyDown (e) {
    e.target.style.height = 'inherit';
    // e.target.style.height = `${e.target.scrollHeight}px`;
    // In case you have a limitation
    e.target.style.height = `${Math.min (e.target.scrollHeight < 50 ? 0 : e.target.scrollHeight, 100)}px`;
    // console.log (
    //   `${e.target.offsetHeight < 60 ? 47 : e.target.offsetHeight}px)`
    // );
    if (window.innerWidth < 1919) {
      document.getElementById (
        'message-list-single'
      ).style.height = `calc(100vh - 190px + 47px - ${e.target.offsetHeight < 60 ? 47 : e.target.offsetHeight}px)`;
    } else {
      // console.log (
      //   `${e.target.offsetHeight < 60 ? 47 : e.target.offsetHeight}px)`
      // );
      document.getElementById (
        'message-list-single'
      ).style.height = `calc(100vh - 215px + 47px - ${e.target.offsetHeight < 60 ? 47 : e.target.offsetHeight}px)`;
    }

    // document.getElementById("message-list-single")
    // .style.height = `calc(100vh - 215px + 47px - ${e.target.offsetHeight<60?47:e.target.offsetHeight}px)`;
    // console.log(`calc(100vh - 215px + 47px - ${e.target.offsetHeight<60?47:e.target.offsetHeight}px)`);
  }
  resetBoxHeight () {
    document.getElementById (
      'message-list-single'
    ).style.height =    window.innerWidth < 1919 ? `calc(100vh - 190px)` : `calc(100vh - 215px)`;
    document.getElementById (
      'textBoxArea'
    ).style.height = `30px`;
  }

  async uploadImage () {
    const {selectedFiles} = this.state;
    for (let files of selectedFiles) {
      let fileType = files.name.split ('.').pop ();
     
        this.props.Loading (true);

        let reader = new FileReader ();
        let file = files;
        var filename = new Date ().getTime ().toString () + files.name;
        // getFileName(files.name)
        filename = filename.replace (/\s/g, '');

        const storage = firebase.storage ();
        // const imageStorageUrl = (await storage.ref(`/attachPics/${filename}`).put(file)).downloadURL;
        // console.log(imageStorageUrl);
        var imageStorageUrl;
        try {
          await storage
            .ref (`/attachPics/${filename}`)
            .put (file)
            .then (async snapshot => {
              // console.log('Uploaded a blob or file!');

              this.props.Loading (false);
              await snapshot.ref.getDownloadURL ().then (downloadURL => {
                imageStorageUrl = downloadURL;
              });
            });
        } catch (e) {
          this.props.Loading (false);
          createNotification ('error', e.message);
          return;
        }
        if (!imageStorageUrl) {
          this.props.Loading (false);

          createNotification ('error', 'Please try again');
          return;
        }

        //   console.log(imageStorageUrl);

        //   imageStorageUrl.snapshot.ref.getDownloadURL ().then (downloadURL => {
        //     console.log ('File available at', downloadURL);
        //   });
        //   this.props.Loading(false);

        //   return;
        // } catch (e) {
        //     this.props.Loading(false);
        //     createNotification("error", e.message);
        //     return;
        // }

        // let fileSenderType = 'other';

        let fileSenderType = this.getFileType(files).toLowerCase();

        // if (
        //   fileType.toLowerCase () === 'png' ||
        //   fileType.toLowerCase () === 'jpeg' ||
        //   fileType.toLowerCase () === 'jpg'
        // ) {
        //   fileSenderType = 'image';
        // }

        // if (fileType.toLowerCase () === 'pdf') {
        //   fileSenderType = 'pdf';
        // }
        // if (
        //   fileType.toLowerCase () === 'mp4' ||
        //   fileType.toLowerCase () === 'm4p' ||
        //   fileType.toLowerCase () === 'M4v' ||
        //   fileType.toLowerCase () === 'avi' ||
        //   fileType.toLowerCase () === 'wmv' ||
        //   fileType.toLowerCase () === 'mpeg' ||
        //   fileType.toLowerCase () === 'mpe' ||
        //   fileType.toLowerCase () === 'mpv' ||
        //   fileType.toLowerCase () === 'mov' ||
        //   fileType.toLowerCase () === 'webm'
        // ) {
        //   fileSenderType = 'video';
        // }

        // if (
        //   fileType.toLowerCase () === 'mp3' ||
        //   fileType.toLowerCase () === 'ogg' ||
        //   fileType.toLowerCase () === 'aac' ||
        //   fileType.toLowerCase () === 'wma'
        // ) {
        //   fileSenderType = 'audio';
        // }

        this.setState ({
          openPreview: false,
          showPreview: [],
        });

        // console.log(imageStorageUrl);
        this.props.updateImage (
          imageStorageUrl,
          fileSenderType,
          files,
          this.state.captionText
        );
        this.setState({
          captionText: ''
        })
        this.props.Loading (false);
      }
    // }
  }

  removeFilesFromList(index){

    let newItems = [...this.state.showPreview];
    newItems.splice (index, 1);
    this.setState ({
      showPreview:newItems
    });
  }

  render () {
    // console.log(this.props)
    return (
      <div className="composeWrapper">
        <div className="compose">
          {/*<img alt="" src={inputVector} style={{width:20,margin:10}} />*/}
          <form
            onSubmit={this.props.onSendClick}
            style={{display: 'inline-flex', width: '100%'}}
          >
            <input
              type="file"
              name="file"
              id="file"
              className="inputfile"
              onChange={e => this.onSelectImage (e)}
              // accept="image/*,video/*,audio/*,.pdf"
              multiple
            />
            <label
              className="composelabelButton"
              for="file"
              data-rh="Select file"
            >
              <img alt="icon" src={attachmentIcon} />
            </label>
            <div className="compose-input-wrapper">
              <textarea
                id="textBoxArea"
                placeholder=" Type message..."
                type="text"
                className="compose-input"
                value={this.props.value}
                onFocus={this.props.onFocus}
                onBlur={this.props.onBlur}
                onChange={e => {
                  this.handleKeyDown (e);
                  return this.props.onChange (e);
                }}
                // size="100"
                // rows={4}
              />
              {/*
                    this.props.value == "" ? (
                    <div className="compose-input-placeholder">
                        Type message...
                    </div>
                  ) : (
                    <div className="compose-input-placeholder">

                    </div>
                  )*/}

            </div>
            {/* <Button   value="submit" className="sendButton">
        <img src={voiceIcon} alt="sendIcons"style={{width:20}}/>
      </Button> */}
            <Button
              data-rh="Send message"
              type="submit"
              value="submit"
              className="sendButton"
              onClick={() => this.resetBoxHeight ()}
            >
              <img src={messageSend} alt="sendIcons" style={{width: 30}} />
            </Button>
          </form>
          {/*
          this.props.rightItems
        */}
        </div>
        <Modal
          isOpen={this.state.openPreview}
          size="sm"
          centered={true}
          toggle={() =>
            this.setState ({
              openPreview: false,
            })}
        >
          <ModalBody className="filePreViewModal">
            <div
              style={{
                display: 'inline-flex',
                width: '100%',
                justifyContent: 'space-between',
                padding: 10,
                alignItems: 'center',
              }}
            >
              <div className="messageAttcahementTitle">MESSAGE ATTACHMENT</div>
              <div>
                <Button className="closePreviewActionButton" data-rh="Close" onClick={()=>{
                  this.setState ({
                    openPreview: false,
                  })
                }}>
                  <img src={crossIcon} alt="chatMessageIcon" />
                </Button>
              </div>

            </div>
            <div
              style={{
                display: 'inline-flex',
                width: '100%',
                justifyContent: 'space-between',
                padding: 5,
              }}
            >

              <div className="fileCaptionBox">
                <div className="radioButtonsBox">
                  {/*<div className="radioButtonItem">
                    <input type="radio" id="test1" name="radio-group" />
                    <label for="test1">Send as image</label>
                  </div>
                  <div className="radioButtonItem">
                    <input type="radio" id="sendFile" name="radio-group" />
                    <label for="sendFile">Send as file</label>
            </div>*/}

                  <div className="captionLabel">Caption</div>

                  <textarea
                    className="captionTextAreaInput"
                    onChange={event => {
                      this.setState ({
                        captionText: event.target.value,
                      });
                    }}
                    placeholder="Enter text...."
                  />
                </div>

              </div>
              <div style={{marginLeft: 20,marginTop: 30}} className="fileCaptionBox">

                <div  className="ComposeFilesScroller">
                  {this.state.showPreview &&  this.state.showPreview.length>0 && 
                    this.state.showPreview.length=== 1 &&
                    <img
                      src={this.getFileTypePreview(this.state.showPreview[0])}
                      alt="sendicon"
                      style={{width: '100%', height: '100%'}}
                    />}
                    {this.state.showPreview && this.state.showPreview.length>1 && this.state.showPreview.map((item,i)=>(
                      <div style={{position:'relative'}} key={i}>
                       
                      <div className="composefileListingNameBox"  style={{background: item.fileType==='dicom'  ? '#17171D' : '#333333',}}>
                      <div style={{width: 60, paddingLeft: 15}}>
                      <img
                          src={this.getFileTypePreview(item)}
                          alt="file type"
                          style={{width: 34, height: 34}}
                        />
                      </div>
                      <div className="fileNameWhite">
                     {item.filename ? item.filename:''}

                      <div className="fileSizeWhiteStatus">
                          
                    {item.filesize ? formatFileSize(item.filesize):''}

                          {' '}
                          
                          
                    </div>
                    </div>

                    
                    </div>
                    <Button className="removeFileButton" onClick={()=>this.removeFilesFromList(i)}> <img src={blueCross} alt="blueCross"/></Button>
                    </div>
                    ))}


                </div>
                <div style={{textAlign:'center'}}>

                <Button
                  className="sendAttachmentButton"
                  onClick={() => this.uploadImage ()}
                >
                  {' '}
                  <img
                    src={sendicon}
                    alt="sendicon"
                    style={{width: 31, height: 31, marginRight: 20}}
                  />
                  Send Attachment
                </Button>
                </div>
              </div>

            </div>

          </ModalBody>

        </Modal>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      Loading,
    },
    dispatch
  );
};

export default connect (null, mapDispatchToProps) (Compose);

// export default Compose;
