import React, {Component} from 'react';
import {Button, Input, Progress} from 'reactstrap';
import * as routes from '../../constants/routes';
import moment from 'moment';

import {MultiSelect} from 'primereact/multiselect';
import questionImage from '../../images/serviceOnDemand/question.png';
import plusImage from '../../images/serviceOnDemand/plus.png';
import AvtarCard2Image from '../../images/serviceOnDemand/avtarCard2.png';
import watchCard2Image from '../../images/serviceOnDemand/watchCard2.png';
import settingCard2Image from '../../images/serviceOnDemand/settingCard2.png';
import FrameImage from '../../images/serviceOnDemand/Frame.png';
import bluelist from '../../images/bluelist.png';

import bluegridview
  from '../../images/serviceOnDemandMyRequest/bluegridview.png';

import gridview from '../../images/serviceOnDemandMyRequest/gridview.png';
import gridviewactive
  from '../../images/serviceOnDemandMyRequest/gridviewactive.png';

import listviewactive
  from '../../images/serviceOnDemandMyRequest/listviewactive.png';
import listview from '../../images/serviceOnDemandMyRequest/listview.png';

import nameframe from '../../images/serviceOnDemandMyRequest/nameframe.png';
import watch from '../../images/serviceOnDemandMyRequest/watch.png';
import yellowdot from '../../images/serviceOnDemandMyRequest/yellowdot.png';
import dashsetimg from '../../images/dashsetimg.png';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import './serviceOnDemand.css';

import ServiceOnDemandProfilePage
  from '../service-On-Demand-Profile/serviceOnDemandProfile';
import ServiceOnDemandCommonComponent
  from '../Service-On-Demand-CommanComponent/Service-On-Demand-CommanComponent';

import {
  GetMyServiceRequests,
  GetMyRequestsRecieved,
  GetServiceProviders,
  CheckServiceProfileExists,
  ResetGetServiceProviders,
} from '../../actions/serviceOnDemandAction';
import serviceAreaList from '../../constants/localJSON/serviceAreaList.json';
import serviceOfferedList from '../../constants/localJSON/serviceOffered.json';
import {DEV_SERVER} from '../../services/servicesConfig';
import SODcoming from '../../images/comingsoon/servicecoming.svg';
import {checkAvailableAccess} from '../../constants/localMessage/errorMessage';
import  CommonAccessContainer from './../../components/UI/commonAccessContainer/commonAccessContainer';



const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class serviceOnDemandPage extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      step: 1,
      addNew: false,
      successmodal: false,
      openLink: '',
      dropdownOpen: true,
      serviceAreaDropDown: false,
      serviceOfferedDropDown: false,
      myRequestsList: [],
      myRequestsRecievedList: [],
      searchString: '',
      servicesOffered: '',
      serviceArea: '',
      myRequestsListView: false,
      myRequestsGridView: true,
      myRequestsRecievedListView: false,
      myRequestsRecievedGridView: true,
      profileStatus: false,
      loginUserID: '',
      receivedRequestFilter: '',
      filterStatus: '',
      isAccess:false
    };
  }

  componentDidMount () {

    if(checkAvailableAccess ("serviceondemand") && checkAvailableAccess ("serviceondemand")=== 'serviceondemand'){
      this.setState({
        isAccess:true
      },

      () => {
        // let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
            // let loginUser = localStorage.getItem ('loginuser');

            // if (loginUser) {
            //   let getUser = JSON.parse (loginUser);
            //   console.log (getUser);
            //   this.setState (
            //     {
            //       loginUserID: getUser.docID,
            //     },
            //     () => {
            //       this.fetchMyPlaceRequestServices ('');
            //       this.fetchReceivedRequestServices ('');
            //     }
            //   );

            //   // let sendRequest = {
            //   //   data: {
            //   //     docID: '',
            //   //     status: '',
            //   //   },
            //   // };
            //   //   let sendRequestData={
            //   //   data: {
            //   //     serviceArea: "",
            //   //     servicesOffered: "",
            //   //     searchString: ""
            //   //   }
            //   // }
            //   // this.props.GetMyServiceRequests (sendRequest);
            //   // this.props.GetMyRequestsRecieved (sendRequest);
            //   this.props.ResetGetServiceProviders ();
            //   // this.props.GetServiceProviders(sendRequestData);
            //   let sendRequestData = {
            //     data: {
            //       docID: getUser.docID,
            //     },
            //   };
            //   this.props.CheckServiceProfileExists (sendRequestData);
            // }
      // }

    })
  }else{
    this.setState({
      isAccess:false
    })
  }
  
    
  }

  fetchMyPlaceRequestServices (status) {
    let sendRequest = {
      data: {
        // docID: this.state.loginUserID,
        docID: '',
        status: status,
      },
    };
    this.setState ({
      filterStatus: status,
    });
    this.props.GetMyServiceRequests (sendRequest);
  }
  fetchReceivedRequestServices (status) {
    let sendRequest = {
      data: {
        // requestorID: this.state.loginUserID,
        docID: '',
        status: status,
      },
    };
    this.setState ({
      receivedRequestFilter: status,
    });
    this.props.GetMyRequestsRecieved (sendRequest);
  }

  componentWillReceiveProps (nextProps) {
   
    if (nextProps) {
      if (nextProps.service) {
        this.setState ({
          myRequestsList: nextProps.service.myserviceRequest,
        });
        this.setState ({
          myRequestsRecievedList: nextProps.service.myRequestRecieved,
        });
      }
      if (
        nextProps &&
        nextProps.service &&
        nextProps.service.checkServiceProfileExists
      ) {
        this.setState (
          {profileStatus: nextProps.service.checkServiceProfileExists.status},
          () => {
           
          }
        );
      }
      if (
        nextProps &&
        nextProps.service &&
        nextProps.service.searchServiceProvider &&
        nextProps.service.searchServiceProvider.length > 0 &&
        this.state.isSearch
      ) {
        this.props.history.push (routes.SERVICEONDEMANDSEARCH);
      }
    }
  }

  searchServiceProvider = () => {
    this.setState (
      {
        isSearch: true,
      },
      () => {
        let sendRequest = {
          data: {
            serviceArea: this.state.serviceArea,
            servicesOffered: this.state.servicesOffered,
            searchString: this.state.searchString,
          },
        };
        this.props.GetServiceProviders (sendRequest);
      }
    );
  };

  // doCreate(){
  //   let sendRequest={
  //       user_id: this.state.userid,
  //       passphrase: this.state.newpin
  //   }
  //   this.props.createNewWallet(sendRequest);
  // }

  serviceArea () {
    this.setState ({
      serviceAreaDropDown: !this.state.serviceAreaDropDown,
      serviceOfferedDropDown: false,
    });
  }
  serviceOffered () {
    this.setState ({
      serviceOfferedDropDown: !this.state.serviceOfferedDropDown,
      serviceAreaDropDown: false,
    });
  }

  openMyRequestsListView = value => {
    this.setState ({myRequestsListView: value});
  };

  openMyRequestsRecievedListView = item => {
    this.setState ({myRequestsRecievedListView: item});
  };

  onChange = e => {
    this.setState ({serviceArea: e.target.value});
  };
  render () {
    const {
      myRequestsList,
      myRequestsRecievedList,
      searchString,
      filterStatus,
      receivedRequestFilter,
      isAccess
    } = this.state;

    return (
      <div>
      {isAccess? <div className="flex ">
      
        <div className="mainContainerService">
          <div className="inlineFlex100">
            {/*--------first--------*/}
            <div className="parentRow">
              <div style={{width: 230}}>
                <ServiceOnDemandCommonComponent />
              </div>
              <div className="innerSearchBoxContent">
                <div style={{width: 600}}>

                  <div>
                    <p className="wellComeText">WELCOME TO</p>
                    <p className="serviceOnDemandText">Service-on-Demand </p>
                  </div>
                  <div className="inlineFlex">
                    <div
                      className="serviceRequestCard inlineFlex"
                      onClick={() =>
                        this.props.history.push (
                          routes.SERVICEONDEMANDCREATESERVICEREQUEST
                        )}
                    >
                      <div className="displyImgRequestCard ">
                        <img src={questionImage} className="displyImg" />
                      </div>
                      <div className="paddTop10">
                        <p className="generateTextCard">GENERATE A </p>
                        <p className="serviceTextCard">Service Request </p>
                      </div>
                    </div>
                    {this.state.profileStatus
                      ? <div>
                          <div
                            className="myserdasb"
                            onClick={() =>
                              this.props.history.push (
                                routes.SERVICEONDEMANDMYREQUEST
                              )}
                          >
                            <div className="mydashimagdi">
                              <img
                                src={dashsetimg}
                                style={{paddingLeft: 5, paddingTop: 5}}
                              />
                            </div>
                            <div>
                              <p className="mydasservte">MY SERVICE</p>
                              <p className="mydasdsast">Dashboard</p>
                            </div>
                          </div>

                        </div>
                      : <div
                          className="serviceCard inlineFlex"
                          onClick={() =>
                            this.props.history.push (
                              routes.SERVICEONDEMAND_CREATE_SERVICE_PROFILE
                            )}
                        >
                          <div className="displyImgRequestCard ">
                            <img src={plusImage} className="displyImg" />
                          </div>
                          <div className="paddTop10">
                            <p className="provideTextCard">PROVIDE A</p>
                            <p className="serviceTextCard2">Service </p>
                          </div>

                        </div>}
                  </div>

                  <div className="mTop30">
                    <p className="searchText">SEARCH FOR</p>
                    <p className="serviceProviderText">Service Providers</p>
                    <Input
                      className="searchItem"
                      type="text"
                      name="searchString"
                      placeholder="Enter Search Item"
                      value={searchString}
                      onChange={e =>
                        this.setState (
                          byPropKey ('searchString', e.target.value)
                        )}
                    />
                  </div>
                  <div className="inlineFlex DropDiv mTop10">
                    <MultiSelect
                      maxSelectedLabels={1}
                      placeholder="Select Service Area"
                      value={this.state.serviceArea}
                      options={serviceAreaList}
                      onChange={e => this.setState ({serviceArea: e.value})}
                    />

                    <MultiSelect
                      maxSelectedLabels={1}
                      placeholder="Select Service Offered"
                      value={this.state.servicesOffered}
                      options={serviceOfferedList}
                      onChange={e => this.setState ({servicesOffered: e.value})}
                    />

                    <Button
                      className="searchButton"
                      onClick={() => this.searchServiceProvider ()}
                    >
                      SEARCH
                    </Button>
                  </div>

                </div>
                <div style={{width: '100%'}}>
                  <img alt="" src={FrameImage} className="FrameImg" />
                </div>
              </div>
              <div className="mTop30">
                <p className="myRequestText">My Placed Requests</p>
              </div>

              <div className="buttonBar">
                <Button
                  onClick={() => this.fetchMyPlaceRequestServices ('')}
                  className={filterStatus === '' ? 'activeButton' : ''}
                >
                  All
                </Button>
                <Button
                  onClick={() => this.fetchMyPlaceRequestServices ('draft')}
                  className={filterStatus === 'draft' ? 'activeButton' : ''}
                >
                  Draft
                </Button>
                <Button
                  onClick={() => this.fetchMyPlaceRequestServices ('awarded')}
                  className={filterStatus === 'awarded' ? 'activeButton' : ''}
                >
                  Placed
                </Button>
                <Button
                  onClick={() => this.fetchMyPlaceRequestServices ('accepted')}
                  className={filterStatus === 'accepted' ? 'activeButton' : ''}
                >
                  Awarded
                </Button>
                <Button
                  onClick={() => this.fetchMyPlaceRequestServices ('paid')}
                  className={filterStatus === 'paid' ? 'activeButton' : ''}
                >
                  Confirmed
                </Button>
                <Button
                  onClick={() =>
                    this.fetchMyPlaceRequestServices ('job started')}
                  className={
                    filterStatus === 'job started' ? 'activeButton' : ''
                  }
                >
                  Started
                </Button>
                <Button
                  onClick={() =>
                    this.fetchMyPlaceRequestServices ('job completed')}
                  className={
                    filterStatus === 'job completed' ? 'activeButton' : ''
                  }
                >
                  Payment Completed
                </Button>
                <Button>Completed</Button>
                <div onClick={() => this.openMyRequestsListView (false)}>
                  <img
                    className="handpointer"
                    alt=""
                    src={
                      this.state.myRequestsListView ? gridview : gridviewactive
                    }
                    style={{width: 25, height: 25, marginRight: 15}}
                  />
                </div>
                <div onClick={() => this.openMyRequestsListView (true)}>
                  <img
                    className="handpointer"
                    alt=""
                    src={
                      !this.state.myRequestsListView ? listview : listviewactive
                    }
                    style={{width: 25, height: 25}}
                  />
                </div>
              </div>
              <div className="serviceOnDemandSearchBoxes ">

                {/* ------Second Card------÷ */}
                {!this.state.myRequestsListView &&
                  myRequestsList &&
                  myRequestsList.map ((item, index) => (
                    <div
                      className="myRequestCard1View "
                      key={index}
                      onClick={() =>
                        this.props.history.push (
                          routes.SERVICEONDEMANDMYREQUESTDETAIL +
                            `/${item.docID}`
                        )}
                    >
                      <p className="SiemensText">{item.title}</p>
                      <h5 className="RequestText">
                        Request: #{item.request_id}
                      </h5>
                      <div className="inlineFlex mTop10 w100">
                        <img src={AvtarCard2Image} className="iconClassCard2" />
                        <span className="card1Text">
                          {item.serviceProviderTitle
                            ? item.serviceProviderTitle
                            : ''}
                          {item && item.serviceProviderDisplayName
                            ? item.serviceProviderDisplayName
                            : (item && item.serviceProviderFirstName
                                ? item.serviceProviderFirstName
                                : '') +
                                ' ' +
                                (item && item.serviceProviderLastName
                                  ? item.serviceProviderLastName
                                  : '')}

                        </span>
                      </div>
                      <div className="inlineFlex  w100">
                        <img src={watchCard2Image} className="iconClassCard2" />
                        <span className="card1Text">
                          {item && item.serviceDate
                            ? moment (item.serviceDate).format ('DD/MM/YYYY')
                            : ''}
                        </span>
                      </div>
                      <div className="inlineFlex  w100">
                        <img
                          src={settingCard2Image}
                          className="iconClassCard2"
                        />
                        <div className="card1Text">

                          <div
                            className={
                              item.status === 'draft' ? 'greencircle' : ''
                            }
                          />
                          <div
                            className={
                              item.status === 'awarded' ? 'yellowcircle' : ''
                            }
                          />
                          <div
                            className={
                              item.status === 'accepted' ? 'yellowcircle' : ''
                            }
                          />
                          <div
                            className={
                              item.status === 'job completed'
                                ? 'orangecircle'
                                : ''
                            }
                          />
                          <div
                            className={
                              item.status === 'rejected' ? 'orangecircle' : ''
                            }
                          />
                          <div
                            className={
                              item.status === 'pending' ? 'whitecircle' : ''
                            }
                          />
                          <div
                            className={
                              item.status === 'confirmed' ? 'lightgreen' : ''
                            }
                          />

                          <div
                            style={{
                              textTransform: 'capitalize',
                              paddingLeft: 5,
                            }}
                          >
                            {item.status}
                          </div>
                        </div>
                      </div>
                      <Progress value={item && item.progressBar ? item.progressBar:""} />
                    </div>
                  ))}

                {this.state.myRequestsListView &&
                  <div>
                    {myRequestsList &&
                      myRequestsList.map ((item, index) => (
                        <div key={index} className="serviceonlistdiv">
                          <div>
                            <div className="erviceRequestd">
                              Request: #{item.request_id}
                            </div>
                            <div className="servisiemed">
                              {item.title}
                            </div>
                          </div>
                          <div className="servicetandi">
                            <img alt="" src={nameframe} />
                            <span style={{paddingLeft: 9}}>
                              {item.serviceProviderTitle
                                ? item.serviceProviderTitle
                                : ''}
                              {item && item.serviceProviderDisplayName
                                ? item.serviceProviderDisplayName
                                : (item && item.serviceProviderFirstName
                                    ? item.serviceProviderFirstName
                                    : '') +
                                    ' ' +
                                    (item && item.serviceProviderLastName
                                      ? item.serviceProviderLastName
                                      : '')}

                            </span>
                          </div>
                          <div className="servicetandi">
                            <img alt="" src={watch} />
                            <span style={{paddingLeft: 9}}>
                              {' '}
                              {item && item.serviceDate
                                ? moment (item.serviceDate).format (
                                    'DD/MM/YYYY'
                                  )
                                : ''}
                            </span>
                          </div>
                          <div>
                            <div className="serviceoawadd">


                              {(item.status === "draft"  ||  item.status === 'paid') &&  <div className="greencircle statusIconsSod"/>}
                              {(item.status === "awarded" || item.status === "accepted") &&  <div className="yellowcircle statusIconsSod" />}
                              {(item.status === "job completed" || item.status === "rejected")&&  <div className="orangecircle statusIconsSod"/>}
                              {item.status === "pending"&&  <div className="whitecircle statusIconsSod"/>}
                              {item.status === "confirmed" &&  <div className="lightgreen statusIconsSod"/>}

                              <span style={{paddingLeft: 5,textTransform:'capitalize'}}>
                                {item.status}
                              </span>
                            </div>
                            <Progress value={item && item.progressBar ? item.progressBar:""} style={{width: 160}} />
                          </div>
                        </div>
                      ))}
                  </div>}

              </div>
              <div className="mTop30">
                <p className="myRequestText">Requests Received</p>
              </div>
              <div className="buttonBar">

                <Button
                  onClick={() => this.fetchReceivedRequestServices ('')}
                  className={receivedRequestFilter === '' ? 'activeButton' : ''}
                >
                  All
                </Button>
                <Button
                  onClick={() => this.fetchReceivedRequestServices ('draft')}
                  className={
                    receivedRequestFilter === 'draft' ? 'activeButton' : ''
                  }
                >
                  Draft
                </Button>
                <Button
                  onClick={() => this.fetchReceivedRequestServices ('awarded')}
                  className={
                    receivedRequestFilter === 'awarded' ? 'activeButton' : ''
                  }
                >
                  Placed
                </Button>
                <Button
                  onClick={() => this.fetchReceivedRequestServices ('accepted')}
                  className={
                    receivedRequestFilter === 'accepted' ? 'activeButton' : ''
                  }
                >
                  Awarded
                </Button>
                <Button
                  onClick={() => this.fetchReceivedRequestServices ('paid')}
                  className={
                    receivedRequestFilter === 'paid' ? 'activeButton' : ''
                  }
                >
                  Confirmed
                </Button>
                <Button>Started</Button>
                <Button
                  onClick={() =>
                    this.fetchReceivedRequestServices ('job started')}
                  className={
                    receivedRequestFilter === 'job started'
                      ? 'activeButton'
                      : ''
                  }
                >
                  Job Complete
                </Button>
                <Button
                  onClick={() =>
                    this.fetchReceivedRequestServices ('job completed')}
                  className={
                    receivedRequestFilter === 'job completed'
                      ? 'activeButton'
                      : ''
                  }
                >
                  {' '}Completed
                </Button>
                <div
                  onClick={() => this.openMyRequestsRecievedListView (false)}
                >
                  <img
                    className="handpointer"
                    alt=""
                    src={
                      this.state.myRequestsRecievedListView
                        ? gridview
                        : bluegridview
                    }
                    style={{width: 25, height: 25, marginRight: 15}}
                  />
                </div>
                <div onClick={() => this.openMyRequestsRecievedListView (true)}>
                  <img
                    className="handpointer"
                    alt=""
                    src={
                      !this.state.myRequestsRecievedListView
                        ? listview
                        : bluelist
                    }
                    style={{width: 25, height: 25}}
                  />
                </div>

              </div>

              <div className="serviceOnDemandSearchBoxes ">
                {/* ------Second Card------÷ */}
                {!this.state.myRequestsRecievedListView &&
                  myRequestsRecievedList &&
                  myRequestsRecievedList.map ((item, index) => (
                   
                    <div
                      className="myRequestCard1View "
                      key={index}
                      onClick={() =>
                        this.props.history.push (
                          routes.SERVICEONDEMAND_RECIEVED_REQUEST_DETAIL +
                            `/${item.docID}`
                        )}
                    >
                      <p className="SiemensText">{item.title}</p>
                      <h5 className="RequestText">
                        Request: #{item.request_id}
                      </h5>
                      <div className="inlineFlex mTop10 w100">
                        <img
                          alt=""
                          src={AvtarCard2Image}
                          className="iconClassCard2"
                        />
                        <span className="card1Text">

                          {item.requestorTitle ? item.requestorTitle : ''}
                          {' '}

                          {item && item.requestorDisplayName
                            ? item.requestorDisplayName
                            : (item && item.requestorFirstName
                                ? item.requestorFirstName
                                : '') +
                                ' ' +
                                (item && item.requestorLastName
                                  ? item.requestorLastName
                                  : '')}

                        </span>
                      </div>
                      <div className="inlineFlex  w100">
                        <img
                          alt=""
                          src={watchCard2Image}
                          className="iconClassCard2"
                        />
                        <span className="card1Text">
                          {item && item.serviceDate
                            ? moment (item.serviceDate).format ('DD/MM/YYYY')
                            : ''}
                        </span>
                      </div>
                      <div className="inlineFlex  w100">
                        <img
                          alt=""
                          src={settingCard2Image}
                          className="iconClassCard2"
                        />

                        {item.status === "draft" &&  <div className="greencircle statusIconsSod"/>}
                        {(item.status === "awarded" || item.status === "accepted") &&  <div className="yellowcircle statusIconsSod" />}
                        {(item.status === "job completed" || item.status === "rejected")&&  <div className="orangecircle statusIconsSod"/>}
                        {item.status === "pending"&&  <div className="whitecircle statusIconsSod"/>}
                        {item.status === "confirmed"&&  <div className="lightgreen statusIconsSod"/>}

                        <span
                          className="card1Text"
                          style={{textTransform: 'capitalize'}}
                        >
                        
                          {item.status}
                        </span>
                      </div>
                          <Progress value={item && item.progressBar ? item.progressBar : 0} />
                    </div>
                  ))}
                {this.state.myRequestsRecievedListView &&
                  <div>
                    {myRequestsRecievedList &&
                      myRequestsRecievedList.map ((item, index) => (
                        
                        <div key={index} className="serviceonrecid">
                          <div>
                            <div className="serviceondltex">
                              Request: #{item.request_id}
                            </div>
                            <div className="serviceonGESe">
                              {item.title}
                            </div>
                          </div>
                          <div className="servicetandi">
                            <img alt="" src={nameframe} />
                            <span style={{paddingLeft: 9}}>
                              {' '}{' '}
                              {item.requestorTitle ? item.requestorTitle : ''}
                              {' '}

                              {item && item.requestorDisplayName
                                ? item.requestorDisplayName
                                : (item && item.requestorFirstName
                                    ? item.requestorFirstName
                                    : '') +
                                    ' ' +
                                    (item && item.requestorLastName
                                      ? item.requestorLastName
                                      : '')}
                            </span>
                          </div>
                          <div className="servicetandi">
                            <img alt="" src={watch} />
                            <span style={{paddingLeft: 9}}>
                              {' '}
                              {item && item.serviceDate
                                ? moment (item.serviceDate).format (
                                    'DD/MM/YYYY'
                                  )
                                : ''}
                            </span>
                          </div>
                          <div>
                            <div className="serviceoawadd">
                           
                              {item.status === "draft" &&  <div className="greencircle statusIconsSod"/>}
                              {(item.status === "awarded" || item.status === "accepted") &&  <div className="yellowcircle statusIconsSod" />}
                              {(item.status === "job completed" || item.status === "rejected")&&  <div className="orangecircle statusIconsSod"/>}
                              {item.status === "pending"&&  <div className="whitecircle statusIconsSod"/>}
                              {(item.status === "confirmed" ||  item.status === 'paid') &&  <div className="lightgreen statusIconsSod"/>}
                              <span style={{paddingLeft: 9,textTransform:'capitalize'}}>
                                {item.status}
                              </span>
                            </div>
                            <Progress value={item && item.progressBar ? item.progressBar : ""}
                              style={{width: 160}} />
                          </div>
                        </div>
                      ))}
                  </div>}
                {/* ------Third Card------÷ */}

              </div>
            </div>

            {/*--------first--------*/}
            {/*--------Second--------*/}

            <div className="notificationCSS">
              <ServiceOnDemandProfilePage
                history={this.props.history}
                latestactivity={true}
              />
            </div>
            {/*--------Second--------*/}

          </div>
        </div>
      </div>:
      
      <CommonAccessContainer bgImage={SODcoming} message={checkAvailableAccess('serviceondemand')} />
      }
      
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    service: state.serviceOnDemandReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      GetMyServiceRequests,
      GetMyRequestsRecieved,
      GetServiceProviders,
      CheckServiceProfileExists,
      ResetGetServiceProviders,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  serviceOnDemandPage
);
