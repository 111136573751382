import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {Button} from 'reactstrap';

import homeIcon from '../../images/icons/sidebar/dashboard.png';
import pacsIcon from '../../images/icons/sidebar/pacs.png';
import vaultIcon from '../../images/icons/sidebar/vaultIcon.png';
import chatIcon from '../../images/icons/sidebar/messenger.png';

import * as routes from '../../constants/routes';
import './welcomeScreen.css';

import {
  updateWelcomeStatus
} from '../../actions/Authaction';

class WelcomePage extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {

    };
  }


  componentDidMount () {
    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));

  }

  goNext(){
    let sendData={
      data:{}
    }
    this.props.updateWelcomeStatus(sendData);
    this.props.history.push(routes.PROFILE_DASHBOARD);
  }


  render () {
    return (
      <div className="flex">
         <div className="welcomeScreenContainer">
         <div className="WelcomeinnerBox">

            <div className="welComeMainTitle">Welcome to R-DEE!  </div>
            <div className="welcomeContentDescription">
            <p style={{marginTop:40}}>We’re excited to share our platform with you.</p>

            <p style={{marginTop:30}}>For this <b> usability testing </b> version of R-DEE, some modules—available under the Radiologex logo to the left—
            are unavailable as we finish perfecting their operation. We hope you enjoy the modules that are available:
            
            </p>
            <div className="welcomeinnerSmall">
            <div style={{width:650}}>
            
              <div className="categorydiv">
              <div className="categoryButtonsdiv">
                <Button className="titleBlackButton"> <img style={{width:28,height:28,marginRight:10}} src={homeIcon} alt="homeIcon" /> Dashboard</Button> 
                
                </div>
                <div className="welcomeSmalldescription">See all of your contacts, system alerts, pinned files and latest news.</div>
              </div>
              <div className="categorydiv">
              <div className="categoryButtonsdiv">
                <Button className="titleBlackButton"> <img style={{width:28,height:28,marginRight:10}} src={chatIcon} alt="homeIcon" /> Connect</Button> 
                </div>
                
                <div  className="welcomeSmalldescription" style={{paddingTop:5}}>End-to-end encrypted communication (text/voice/video) on any device. Built with full HIPPA
                compliance and industry-grade security exclusively for healthcare industry.</div>
              </div>
              <div className="categorydiv">
              <div  className="categoryButtonsdiv">
                <Button className="titleBlackButton"> <img style={{width:28,height:28,marginRight:10}} src={pacsIcon} alt="homeIcon" /> Scan</Button> 
                </div>
                
                <div  style={{paddingTop:5}} className="welcomeSmalldescription">A PACS (medical imaging) platform that features advanced image analysis and reporting 
                tools presented on a clean and intuitive user interface. </div>
              </div>
              <div className="categorydiv">
              <div className="categoryButtonsdiv">
                <Button className="titleBlackButton"> <img style={{width:28,height:28,marginRight:10}} src={vaultIcon} alt="homeIcon" /> Vault</Button> 
                </div>
                
                <div style={{paddingTop:5}}  className="welcomeSmalldescription">A fast, secure, end-to-end encrypted storage solution designed to meet 
                the data requirements of the healthcare industry. </div>
              </div>
            
              </div>
              </div>
            
            <div style={{marginTop:50}}>
            <b> Your feedback is welcome </b> - we look forward to making the system work even better for you!</div>
            <div style={{marginTop:20}}>
            Inside of R-DEE Connect, you will see an R-DEE Support user at the top of your chat list.
            <div>Please use this area to provide any feedback or suggestions you may have, 24/7.</div>
            <div>Our team will review all items in our Support chat regularly and</div>
            <div>integrate your feedback.</div>
            </div>
            <Button onClick={()=>this.goNext()} className="readyTogoButton">I’m Ready, Let’s Go!</Button>

            </div>
            <br/>
            <br/>
            <br/>



            </div>
          </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.Authreducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      updateWelcomeStatus,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (WelcomePage);
