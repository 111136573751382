import {history} from '../store';
import {getMessage} from '../constants/localMessage/errorMessage';
import {
  transactionService,
  createAccountService,
  getUserContactService,
  transferAmountService,
  askMoneyService,
  getUserBalanceService,
  changePinService,
  pinNumberResetGenerateOTPService,
  rdeePayCheckWalletExistsService,
  createSessionService,
  checkSessionStatusService,
  updateSessionService,
  createPaymentMethodService,
  getBanksListService,
  createTransferService
} from '../services/Paymentservice';
import {
  TRANSACTION_SUCCESS,
  CREATE_WALLET,
  CONTACT_LIST,
  SEND_MONEY,
  SEND_MONEY_ERROR,
  ASK_CREDIT_SEND_MONEY,
  USER_BALANCE,
  WALLET_CHANGE_PIN,
  PIN_OTP_SEND,
  CHECK_USER_WALLET,
  USER_PAYMENT_SESSION,
  USER_PAYMENT_STATUS,
  USER_PAYMENT_BANK_LIST,
  USER_PAYMENT_ACCOUNT_LIST,
  CREATE_TRANSFER_SUCCESS
} from '../reducers/Paymentreducer';
import {createNotification} from '../constants/notificationtoast';
import {Loading} from './LoadingAction';
import {addNotificationHistory} from '../actions/Messenger';

import {updateUser} from './contacts';
import * as routes from '../constants/routes';

export const createNewWallet = request => {
  return dispatch => {
    dispatch (Loading (true));
    createAccountService (request)
      .then (res => {
        if (res && res.data && res.data.status === 'success') {
          dispatch ({type: CREATE_WALLET, payload: res.data.data});

          localStorage.setItem ('address', res.data.data.address);
          let sendRequest = {
            data: {
              walletAddress: res.data.data.address,
            },
          };

          dispatch (updateUser (sendRequest));

          dispatch (Loading (false));
        } else {
          if (res && res.data && res.data.result) {
            // createNotification ('error', res.data.result.message);
            createNotification ('error',getMessage(res.data.result.responseCode));
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
    
  };
};

export const getTransactionHistory = request => {
  return dispatch => {
    dispatch (Loading (true));
    transactionService (request)
      .then (res => {


        if (res && res.data && res.data.result && res.data.result.status) {
          dispatch ({type: TRANSACTION_SUCCESS, payload: res.data.result.Data});
          dispatch (Loading (false));
        } else {
          if (res && res.data && res.data.result) {
            // createNotification ('error', res.data.result.message);
            // createNotification ('error',getMessage(res.data.result.responseCode));

          }
          dispatch (Loading (false));
        }
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
    
  };
};

export const getContactList = request => {
  return dispatch => {
    dispatch (Loading (true));
    getUserContactService (request)
      .then (res => {
       

        if (res && res.data && res.data.result && res.data.result.status) {
       
          dispatch ({type: CONTACT_LIST, payload: res.data.result.Data});
          dispatch (Loading (false));
        } else {
          if (res && res.data && res.data.result) {
            // createNotification ('error', res.data.result.message);
            createNotification ('error',getMessage(res.data.result.responseCode));

          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};

export const sendAmountTransaction = (request,receiverData) => {
  console.log(receiverData);
  return dispatch => {
    dispatch (Loading (true));
    transferAmountService (request)
      .then (res => {
      

        if (res && res.data && res.data.data && res.data.status == 'success') {
          console.log(res.data);
          dispatch ({type: SEND_MONEY, payload: res.data.data});

          // let paramsAddNotiHistory = {
          //   data: {
          //     "source": "rdeepay", // "sourceModule" for push notifiaction and "source" for notification history
          //     "subject": receiverData.subject,
          //     "body": receiverData.body,
          //     "imageURL":receiverData.imageURL,
          //     "targetUID": receiverData.targetUID,
          //     "titleName": receiverData.titleName,
          //     "sourceUID" :receiverData.sourceUID,
          //     "source":'rdeepay',
          //     "screen": 'rdeepay',
          //     "navigation": 'rdeedashboard'
          //   },
          // };
          dispatch (Loading (false));
          // dispatch (addNotificationHistory(paramsAddNotiHistory));

          // createNotification ('success', res.data.data.message);
        }
        if (
          res &&
          res.error &&
          (res.error.response.status === 500 ||
            res.error.response.status === 400)
        ) {
          if (
            res.error.response.data &&
            res.error.response.data.data &&
            res.error.response.data.data[0]
          ) {
            dispatch ({
              type: SEND_MONEY_ERROR,
              payload: res.error.response.data.data[0].msg,
            });
          }
        }
        
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log(err);
        throw err;
      });
  };
};

export const askFormoney = request => {
  return dispatch => {
    dispatch (Loading (true));
    askMoneyService (request)
      .then (res => {
       

        if (res && res.data && res.data.data && res.data.status == 'success') {
        
          dispatch ({type: ASK_CREDIT_SEND_MONEY, payload: res.data.data});
          dispatch (Loading (false));
          // createNotification ('success',res.data.data.message);
        }
        if (
          res &&
          res.error &&
          (res.error.response.status === 500 ||
            res.error.response.status === 400)
        ) {
          if (
            res.error.response.data &&
            res.error.response.data.data &&
            res.error.response.data.data[0]
          ) {
            dispatch ({
              type: SEND_MONEY_ERROR,
              payload: res.error.response.data.data[0].msg,
            });
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};



export const changeWalletPin = request => {
  return dispatch => {
    // dispatch ({type: WALLET_CHANGE_PIN, payload: request});
    dispatch (Loading (true));
    changePinService (request)
      .then (res => {

        if (res && res.data && res.data.data && res.data.status == 'success') {
        

          dispatch ({type: WALLET_CHANGE_PIN, payload: res.data.data});
          dispatch ({type: SEND_MONEY_ERROR, payload: ''});

          dispatch (Loading (false));
        }
        if (
          res &&
          res.error &&
          (res.error.response.status === 500 ||
            res.error.response.status === 400)
        ) {
          if (
            res.error.response.data &&
            res.error.response.data.data &&
            res.error.response.data.data[0]
          ) {
            dispatch ({type: WALLET_CHANGE_PIN, payload: ''});

            dispatch ({
              type: SEND_MONEY_ERROR,
              payload: res.error.response.data.data[0].msg,
            });
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};




export const pinGenerateSentOtp = request => {
  return dispatch => {
    dispatch (Loading (true));
    pinNumberResetGenerateOTPService (request)
      .then (res => {
      
       
        if (res && res.data && res.data.result && res.data.result.status) {
          
          createNotification ('success', res.data.result.message);
          dispatch ({type: PIN_OTP_SEND, payload: res.data.result.Data});
          dispatch (Loading (false));
        } else {
          if (res && res.data && res.data.result) {
            createNotification ('error', res.data.result.message);
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};





export const fetchUserBalance = request => {
  return dispatch => {
    dispatch (Loading (true));
    getUserBalanceService (request)
      .then (res => {
        if (res && res.data && res.data.data && res.data.status == 'success') {
      
          dispatch ({type: USER_BALANCE, payload: res.data.data});
          dispatch (Loading (false));
          // createNotification ('success',res.data.data.message);
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};

export const createPaymentSession = request => {
  return dispatch => {
    dispatch (Loading (true));
    createSessionService (request)
      .then (res => {
      
        if(res && res.status===200){
         
           dispatch ({type: USER_PAYMENT_SESSION, payload: res.data.session_id});
          // dispatch (Loading (false));
          let getData= res.data.session_id;
          // console.log(getData);

          window.location.href=res.data.payment_link;
          
          // let sendRequest={
          //   session_id: getData,
          //   destination_amount: 1.0
          // }
          // dispatch (updatePaymentSession(sendRequest))
          // let getData= "ee2ff517033468446a35412050731b96";
          //  window.location.href=`https://pay.testwyre.com/purchase?type=debitcard-hosted-dialog&dest=account:AC_H4PDUUXCZYV&destCurrency=USD&sourceAmount=${request.destination_amount}&redirectUrl=https://${window.location.hostname}${routes.PAYMENTSTATUS}/success&paymentMethod=debit-card&referenceId=${getData}&accountId=AC_H4PDUUXCZYV&failureRedirectUrl=https://${window.location.hostname}${routes.PAYMENTSTATUS}/failed`;
        }
       
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};


export const updatePaymentSession = request => {
  return dispatch => {
    dispatch (Loading (true));
    updateSessionService (request)
      .then (res => {
       
        // alert(`https://pay.testwyre.com/purchase?type=debitcard-hosted-dialog&dest=account:AC_H4PDUUXCZYV&destCurrency=USD&sourceAmount=${request.destination_amount}&redirectUrl=https://${window.location.hostname}${routes.PAYMENTSTATUS}/success&paymentMethod=debit-card&referenceId=${request.session_id}&accountId=AC_H4PDUUXCZYV&failureRedirectUrl=https://${window.location.hostname}${routes.PAYMENTSTATUS}/failed`);
        window.location.href=`https://pay.testwyre.com/purchase?type=debitcard-hosted-dialog&dest=account:AC_H4PDUUXCZYV&destCurrency=USD&sourceAmount=${request.destination_amount}&redirectUrl=https://${window.location.hostname}${routes.PAYMENTSTATUS}/success&paymentMethod=debit-card&referenceId=${request.session_id}&accountId=AC_H4PDUUXCZYV&failureRedirectUrl=https://${window.location.hostname}${routes.PAYMENTSTATUS}/failed`;
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};




export const checkPaymentStatus = request => {
  return dispatch => {
    dispatch (Loading (true));
    checkSessionStatusService (request)
      .then (res => {
       
           dispatch ({type: USER_PAYMENT_STATUS, payload: res.data});
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};




export const checkRdeePayCheckWalletExists = request => {
  return dispatch => {
    dispatch (Loading (true));
    rdeePayCheckWalletExistsService (request)
      .then (res => {

      
        if(res && res.data ){

        if (res && res.data  && res.data.result.status) {

          localStorage.setItem ('address', res.data.result.Data.walletAddress);
          // createNotification ('success',getMessage(res.data.result.responseCode));
          dispatch ({type: CHECK_USER_WALLET, payload: res.data.result.Data});
        }else{

          // createNotification ('error',getMessage(res.data.result.responseCode));

          localStorage.setItem ('address','');
          dispatch ({type: CHECK_USER_WALLET, payload: ''});
        }
      }

        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};





export const createPayment = request => {
  return dispatch => {
    dispatch (Loading (true));
    createPaymentMethodService (request)
      .then (res => {
        if(res && res.data){
            dispatch ({type: USER_PAYMENT_BANK_LIST, payload: res.data});

            if( res && res.data && res.data.user_id){
              let sendRequest={
                user_id: res.data.user_id
              }
      
              dispatch (getPaymentBanksList (sendRequest));
            }
          }


          if(res && res.error && res.error.response && res.error.response.data){
            dispatch (Loading (false))
            if(res.error.response.data.message){
              createNotification ('error',res.error.response.data.message);
            }
          }


       
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};

export const getPaymentBanksList = request => {
  return dispatch => {
    dispatch (Loading (true));
    getBanksListService (request)
      .then (res => {
      
        if(res && res.data){
          dispatch ({type: USER_PAYMENT_ACCOUNT_LIST, payload: res.data});

        }

           if(res && res.error && res.error.response && res.error.response.data){
            if(res.error.response.data.message){
              createNotification ('error',res.error.response.data.message);
            }
          }
        dispatch (Loading (false));
      })
      .catch (err => {
        dispatch (Loading (false));

        console.log (err);
        throw err;
      });
  };
};
export const createTransfer = request => {
  return dispatch => {
    dispatch (Loading (true));
    createTransferService (request)
      .then (res => {
       
        if(res && res.data){
          dispatch ({type: CREATE_TRANSFER_SUCCESS, payload: res.data});
        }

        if(res && res.error && res.error.response && res.error.response.data){
          if(res.error.response.data.message){
            createNotification ('error',res.error.response.data.message);
          }
        }
        dispatch (Loading (false));
      })
      .catch (err => {
        console.log (err);

        dispatch (Loading (false));

        throw err;
      });
  };
};






export const resetTransactionList = request => {
  return dispatch => {
    dispatch ({type: TRANSACTION_SUCCESS, payload: []});
    dispatch ({type: CREATE_TRANSFER_SUCCESS, payload: null});
    dispatch ({type: USER_PAYMENT_ACCOUNT_LIST, payload: null});
    dispatch ({type: USER_PAYMENT_BANK_LIST, payload: null});


    
  }
}


