// import * as firebase from "firebase/app";
import "firebase/messaging";
import firebase from './config/firebase';

import {getBrowserName} from './constants/helper';

// import  * as firebaseConfig from './config/firebase';


// const initializedFirebaseApp = firebase.initializeApp({
// 	// Project Settings => Add Firebase to your web app
// //   messagingSenderId: "648750072548",
// //   appId: "1:648750072548:web:28388a6b1ef1446d6fe2f1"

//     apiKey: "AIzaSyBMeCvEECTCr98nxgI5uhO2CcHAhuX8cpw",
//     authDomain: "radiologexapp.firebaseapp.com",
//     databaseURL: "https://radiologexapp.firebaseio.com",
//     projectId: "radiologexapp",
//     storageBucket: "radiologexapp.appspot.com",
//     messagingSenderId: "648750072548",
//     appId: "1:648750072548:web:aaaacb8a1f3f802f"
// });
const messaging = getBrowserName()!=='Safari' ?firebase.messaging():firebase;
// messaging.usePublicVapidKey(
// 	// Project Settings => Cloud Messaging => Web Push certificates
//   "BD6n7ebJqtOxaBS8M7xtBwSxgeZwX1gdS...6HkTM-cpLm8007IAzz...QoIajea2WnP8rP-ytiqlsj4AcNNeQcbes"
// );
export { messaging };