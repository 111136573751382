import React, { Component } from 'react'
import { Button } from 'reactstrap'
import Calendar from '../UI/calendar/calendar';
import {dateMonthYearFormat} from '../../utility/convertTimeString';
import SelectInput from '../InputCustom/selectInput/selectDropdown'

import './userinvoice.css'
const InvoiceTypes=[
  { label: 'Paid', value: 'Paid' },
  { label: 'Unpaid', value: 'Unpaid' },
]

class UserInvoices extends Component {
  constructor(props){
    super(props);
  this.state={
    invoiceListing:[],
    startDate:'',
    endDate:'',
    isOpenStartPicker: false,
    isOpenEndPicker: false,
    invoiceType:''

  }
  }
  componentDidMount(){

    let invoiceListing=[];
    for (let index = 0; index < 50; index++) {
      invoiceListing.push(index)
    }
   
    this.setState({
      invoiceListing:invoiceListing
    })
  }
  render () {
    const {invoiceListing,isOpenStartPicker ,endDate ,isOpenEndPicker,startDate,invoiceType} = this.state;
   
    return (
  
     <div className='inVoiceMaincontainer'>
        <div className='inVoiceChildcontainer1'> 
          <div className="inVoiceMainTitle">Invoice</div>


         


          <div className="invoiceSearchBoxContainer">

            <div className="searchInvoiceLabels">
              <div>Invoice</div> <input className="searchInvoiceInputField" placeholder="search by name"/>
            </div>
            <div className="searchInvoiceLabels">
              <div>From Date: </div> 
              <div  className="searchInvoiceDatepicker"  onClick={() =>
                      this.setState ({
                        isOpenStartPicker: true,
                      })}
                      >{this.state.startDate?dateMonthYearFormat(this.state.startDate):'Start Date'}</div>
                      
  <div style={{position:'absolute'}}>
              {isOpenStartPicker && <div style={{position:'absolute'}}>
                        <Calendar 
                          // minDate={new Date()} 
                          maxDate={(endDate?endDate: new Date())}
                         selectedDate={this.state.startDate} 
                        onSubmit={(value)=>{
                          this.setState({
                            isOpenStartPicker: false
                          })
                          if(value){
                            this.setState ({
                              startDate: value,
                              endDate: '',
                            });
                          }
                          }} 
                        />
                  </div>}
                  </div>
            </div>
            <div className="searchInvoiceLabels">
              <div>To Date: </div>

              <div  className="searchInvoiceDatepicker"  onClick={() =>
                      this.setState ({
                        isOpenEndPicker: true,
                      })}
                      >{this.state.endDate?dateMonthYearFormat(this.state.endDate):'End Date'}</div>

                      <div style={{position:'absolute'}}>
              {isOpenEndPicker && <div style={{position:'absolute'}}>
                        <Calendar 
                          minDate={startDate? startDate :new Date()} 
                          maxDate={(endDate?endDate: new Date())}
                         selectedDate={this.state.startDate} 
                        onSubmit={(value)=>{
                          this.setState({
                            isOpenEndPicker: false
                          })
                          if(value){
                            this.setState ({
                              endDate: value,
                            });
                          }
                          }} 
                        />
                  </div>}
                  </div>

                   
            </div>
            <div className="searchInvoiceLabels">
              <div>Status: </div>
  <div style={{marginLeft:20}}>
                   <SelectInput
                            items={InvoiceTypes}
                            value={invoiceType}
                            className='invoiceTypeDropdown'
                            dropDownContainerClass='invoiceTypeContainerDropdown'
                            onChange={value => {
                              this.setState({
                                invoiceType: value
                              })
                            }}
                            placeholder="Select"
                          />
                          </div>
            </div>

                            <div className="searchInvoiceLabels">

                              <Button className="searchInvoiceButton">Search</Button>
                            </div>
          </div>

         

            <div className="invoiceList">

              <div className="headerTitle invoiceListNameColumn">
              Invoice #
              </div>
              <div className="headerTitle invoiceListDateColumn">
              Date
              </div>
              <div className="headerTitle invoiceListDateColumn">
             Amount
              </div>
              <div className="headerTitle invoiceListDateColumn">
              Status
              </div>
              <div className="headerTitle invoiceListDateColumn">
             Plan
              </div>
              <div className="headerTitle invoiceListDateColumn">
             Action
              </div>


            </div>
          <div className="invoiceListContainer">

            {invoiceListing.length>0 && invoiceListing.map((item,i)=>(
            <div key={i} className="invoiceList">

                <div className=" invoiceListNameColumn">
                Invoice {i+1}
                </div>
                <div className=" invoiceListDateColumn">
                {dateMonthYearFormat(new Date())}
                </div>
                <div className=" invoiceListDateColumn">
               
                ${i%2==0 ? 10 : 0 }
                </div>
                <div className=" invoiceListDateColumn">
                {i%2==0 ? "Paid" : "Unpaid" }
                </div>
                <div className=" invoiceListDateColumn">
                {i%2==0 ? "Pro" : "Free" }
                </div>
                <div className=" invoiceListDateColumn">
                {i%2==0  && <Button className="downloadInvoiceButton">Download</Button>}
                </div>


                </div>))}


            
          </div>
         
         </div>
      </div>
    )
  }
}

export default UserInvoices
