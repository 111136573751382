import { getMessageList } from "./../api";
import { getMessage } from "./../api";
import { batchModify } from "./../api";
import { selectLabel } from "./sidebarActions";
import {Loading } from '../../../actions/LoadingAction';


import {GET_MESSAGES ,
  GET_MESSAGES_LOAD_IN_PROGRESS,
  GET_MESSAGES_FAILED,
  TOGGLE_SELECTED,
  MESSAGE_LOAD_IN_PROGRESS,
  MESSAGE_LOAD_SUCCESS,
  MESSAGE_LOAD_FAIL,
  EMPTY_MESSAGES,
  SET_PAGE_TOKENS,
  ADD_INITIAL_PAGE_TOKEN,
  CLEAR_PAGE_TOKENS,
  MODIFY_MESSAGES_SUCCESS,
  MODIFY_MESSAGES_FAILED,
  SET_SEARCH_QUERY
} from './../../../reducers/emailreducer/messageListReducer'



export const getLabelMessages = ({
  labelIds,
  q = "",
  pageToken
}) => (dispatch, getState) => {
  dispatch(setMessageListLoadInProgress());
  dispatch(Loading(true));

  const state = getState();
  const {searchQuery} = state;
  dispatch({ type: EMPTY_MESSAGES, payload: []});

  if (searchQuery !== "") {
    dispatch(selectLabel("-1"));
  }
  getMessageList({ labelIds, maxResults: 20, q: searchQuery, pageToken }).then(response => {
    dispatch({ type: GET_MESSAGES, payload: response});

    dispatch(setPageTokens({
      nextPageToken: response.nextPageToken || ""
    }));
  dispatch(Loading(false));

    
  }).catch(err => {
  dispatch(Loading(false));

    dispatch({ type: GET_MESSAGES, payload: []});
    dispatch({
      type: GET_MESSAGES_FAILED,
      payload: err
    })
  });
};

export const setSearchQuery = q => ({
  type: SET_SEARCH_QUERY,
  payload: q
})

export const setPageTokens = tokens => ({
  type: SET_PAGE_TOKENS,
  payload: tokens
});

export const emptyLabelMessages = () => ({
  type: EMPTY_MESSAGES
});

export const toggleSelected = (messageIds, selected) => ({
  type: TOGGLE_SELECTED,
  payload: {
    messageIds,
    selected
  }
});

export const getEmailMessage = messageId => dispatch => {
  dispatch(setMessageLoadInProgress());
  getMessage(messageId)
    .then(response => {
      // dispatch({  type: MESSAGE_LOAD_FAIL, payload: null});
      dispatch({ type: MESSAGE_LOAD_SUCCESS, payload: response  });
    })
    .catch(error => {
      dispatch({
        type: MESSAGE_LOAD_FAIL,
        payload: error
      });
    });
};

const setMessageLoadInProgress = () => ({
  type: MESSAGE_LOAD_IN_PROGRESS
});

const setMessageListLoadInProgress = () => ({
  type: GET_MESSAGES_LOAD_IN_PROGRESS
});

export const addInitialPageToken = token => ({
  type: ADD_INITIAL_PAGE_TOKEN,
  payload: token
})

export const clearPageTokens = () => ({
  type: CLEAR_PAGE_TOKENS
})

export const modifyMessages = ({ids, addLabelIds = [], removeLabelIds = []}) => dispatch => {
  batchModify({ids, addLabelIds, removeLabelIds})
    .then(modifiedIds => {
      dispatch({
       type: MODIFY_MESSAGES_SUCCESS,
       payload: {modifiedIds, addLabelIds, removeLabelIds}
      })
    })
    .catch(error => {
      dispatch({
       type: MODIFY_MESSAGES_FAILED
      })
    })
}

