import React from 'react';
import { connect } from 'react-redux';
// import { createStructuredSelector } from 'reselect';
// import { makeSelectLoaderStatus } from 'containers/App/selectors';
import {bindActionCreators} from 'redux';

import Loader from './loader';

class LoaderSpinner extends React.PureComponent { // eslint-disable-line react/prefer-stateless-function

  render() {
    const { visible } = this.props;
    return (
      <Loader visible={visible.loading} />
    );
  }
}



// export function mapDispatchToProps() {
//   return {
//   };
// }

// const mapStateToProps = createStructuredSelector({
//   visible: makeSelectLoaderStatus(),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(LoaderSpinner);

const mapStateToProps = (state) => {
  return {
    visible: state.LoadingReducer,
      // dialogData: state.ShowDialog.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(LoaderSpinner);
