import React, { Component } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";

import Messenger from '../Messenger';
import CreateGroup from '../CreateGroup';
import GroupDetails from '../GroupDetails';
import UserProfile from '../UserProfile';

import * as routes from '../../../constants/routes';

export default class App extends Component {
  render() {
    return (
      <Switch>
          <Route exact path={routes.MESSENGER} component={Messenger}/>
          <Route path={routes.MESSENGERCREATEGROUP} component={CreateGroup}/>
          <Route path={`${routes.GROUPDETAILS}`} component={GroupDetails}/>
          <Route path={`${routes.PROFILEDETAILS}`} component={UserProfile}/>
        
          
          <Redirect to={routes.MESSENGER}/>
      </Switch>
    );
  }
}