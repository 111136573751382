import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {SignInLink} from './SignIn';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from 'reactstrap';
import logo from './logo.svg';
import * as routes from '../constants/routes';
import {auth, db} from '../firebase';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {connect} from 'react-redux';
import {
  GetEducationalDetail,
  AddEducationalDetail,
  DeleteEducationalDetail,
} from '../actions/Authaction';
import {bindActionCreators} from 'redux';
import Ellipse from './../images/Ellipse1.png';
import Vector from './../images/Vector.png';
// const EducationDetailPage = ({ history }) => (
//       <div className="div-flex-signup">
//         <SignUpForm history={history} />
//       </div>
// );

//################### Sign Up Form ###################

//A Higher order function with prop name as key and the value to be assigned to
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class EducationDetailPage extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      yearCompleted: '',
      university: '',
      degreeType: '',
      courseMajor: '',
      error: null,
      showingAlert: false,
      isAddEducation: false,
      educationData: [],
      isSubmit: false
    };
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if (
        nextProps.userinfo.educationDetails &&
        nextProps.userinfo.educationDetails.result &&
        nextProps.userinfo.educationDetails.result.status
      ) {
        this.setState ({
          educationData: nextProps.userinfo.educationDetails.result.Data,
          isAddEducation: false,
          yearCompleted: '',
          university: '',
          degreeType: '',
          courseMajor: '',
          isSubmit: false
        });
      }
    }
  }
  componentDidMount () {
    const sendRequest = {
      data: {},
    };
    this.props.GetEducationalDetail (sendRequest);
  }

  // onChange = (propName, value) => {
  //   this.setState({
  //     [propName]: value
  //   });
  // };

  onSubmit = event => {
    event.preventDefault (); //prevents refreshing

    this.setState({
      isSubmit: true
    },()=>{



      const {
        yearCompleted,
        university,
        degreeType,
        courseMajor,
      } = this.state;
      //a boolen to perform validation
      const isInvalid =
        yearCompleted === '' ||
        university === '' ||
        degreeType === '' ||
        courseMajor === '' || 
        university.length > 100 || 
        courseMajor.length > 100;

        if(isInvalid){
        
          return false
        }

      
        const {history} = this.props;

        const sendRequest = {
          data: {
            yearCompleted: this.state.yearCompleted,
            university: this.state.university,
            degreeType: this.state.degreeType,
            courseMajor: this.state.courseMajor,
            docID: '',
          },
        };
        this.props.AddEducationalDetail (sendRequest);

    })
   
    

   
  };
  Deletedetail = item => {
    const data = {
      data: {
        docID: item.docID,
      },
    };
    this.props.DeleteEducationalDetail (data);
  };
  timer = () => {
    this.setState ({
      showingAlert: true,
    });

    setTimeout (() => {
      this.setState ({
        showingAlert: false,
      });
    }, 4000);
  };
//   handleChange(event){
//    if( university.length > 100){
//    this.setState({ error:"Feilds cannot be too long"})
//  }
//   this.setState ({ university : event.target.value})
// }
  render () {
    const {
      yearCompleted,
      university,
      degreeType,
      courseMajor,
      showingAlert,
      error,
      isSubmit
    } = this.state;


    return (
      <div className="div-flex-education">
      <div className="div-flex-businessScroll">
        {!this.state.isAddEducation &&
          <Row>
            {showingAlert &&
              <Alert color="danger" onLoad={this.timer}>
                {error.message}
              </Alert>}
            <Col sm="12" md="12" lg="12" className="text-center">
              <img alt="logo" src={logo} />
            </Col>
            <Col
              className="text-center"
              xs="12"
              sm="12"
              md="12"
              lg="12"
              className="pClass"
            >

              {this.state.educationData.length === 0 &&
                <Col>
                  <p style={{fontSize: 20}}>
                    Add your Educational Details
                  </p>

                  <Col
                    className="text-center"
                    xs="12"
                    sm="12"
                    md={{size: 5, offset: 4}}
                    lg="4"
                    style={{
                      backgroundColor: '#0C0F1D',
                      marginTop: '30px',
                      borderRadius: '5px',
                      height: '60px',
                      justifyContent: 'center',
                    }}
                  >
                    <p
                      style={{
                        fontSize: 16,
                        lineHeight: '55px',
                        fontStyle: 'italic',
                      }}
                    >
                      {' '}No Education Details Added
                    </p>
                  </Col>
                </Col>}

            </Col>
          
            <Col xs="12" sm="12" lg="12" md="12">
              {this.state.educationData &&
                this.state.educationData.length > 0 &&
                <Col
                  xs="12"
                  sm="12"
                  lg="4"
                  md={{size: 4, offset: 4}}
                  className="text-center"
                  style={{marginTop: 10}}
                >

                  <p className="headtext">Add Educational Details</p>

                </Col>}
               

              {this.state.educationData &&
                this.state.educationData.map ((item,index) => (
                  <Col
                  key={index}
                    xs="12"
                    sm="12"
                    lg="6"
                    md={{size: 3, offset: 3}}
                    style={{backgroundColor: '#0C0F1D', borderRadius: 5,}}
                  >

                    <div onClick={() => this.Deletedetail (item)} style={{cursor:'pointer'}}>
                      <img
                        alt="img"
                        src={Ellipse}
                        style={{float: 'right', marginTop: 21}}
                      />
                      <img
                        alt="img"
                        src={Vector}
                        style={{
                          float: 'right',
                          marginTop: 29,
                          position: 'relative',
                          left: 14,
                          
                        }}
                      />
                    </div>

                    <p
                      style={{
                        color: '#FFFFFF',
                        fontSize: 14,
                        lineHeight: 3,
                        margin: 0,
                        fontFamily: 'Helvetica',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                      }}
                    >
                      {' '}{item.courseMajor} - {item.yearCompleted}
                    </p>
                    <p
                      style={{
                        color: '#FFFFFF',
                        fontfamily: 'Helvetica',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: '14px',
                        lineHeight: 3,
                        marginTop: '-23px',
                      }}
                    >
                      {item.university}
                    </p>

                  </Col>
                ))}
            </Col>
            <Col
              xs="12"
              sm="12"
              lg="4"
              md={{size: 4, offset: 4}}
              className="text-center"
              style={{marginTop: 10}}
            >
            {this.state.educationData &&
              this.state.educationData.length > 0  ?  ( <Link to={routes.PROFESIONALDETAIL}>  <Button
              className="buttonClass"
              // onClick={() =>
              //   this.setState ({
              //     isAddEducation: true,
              //   })}
            >
             Next
            </Button></Link> ) : ( <Button
                className="buttonClass"
                onClick={() =>
                  this.setState ({
                    isAddEducation: true,
                  })}
              >
                Add Educational Details
              </Button> ) }
             {/* <Link to={routes.PROFESIONALDETAIL}>
                {this.state.educationData &&
                  this.state.educationData.length > 0 &&
                  <Button style={{marginTop: 20}} className="buttonClass">
                    NEXT
                  </Button>}
                </Link>*/}

              {this.state.educationData &&
                this.state.educationData.length > 0 ?  
               ( <div   onClick={() =>
                  this.setState ({
                    isAddEducation: true,
                  })} className="text-center linkRegiterClass" style={{  color: '#4092B7',cursor: 'pointer'}}>
                    ADD NEW
 
              </div>) : ( <div className="text-center linkRegiterClass">
                <Link to={routes.PROFESIONALDETAIL}>ADD LATER</Link>

              </div> )}

            </Col>
            {/* <Col xs="12" sm="12" lg="4" md={{ size: 4, offset: 4 }} className="text-center">
            <Link to={routes.PROFESIONALDETAIL}>
            <p style={{fontFamily: "Helvetica", marginTop:8, fontStyle: "normal", fontWeight: "bold", fontSize: 16, textAlign: "center", color: '#4092B7'}}> 
                ADD LATER 
            </p> 
            </Link>
            </Col> */}
          </Row>}

        {this.state.isAddEducation &&
          <form onSubmit={this.onSubmit}>
            <Row>
              <Col sm="12" md="12" lg="12" className="text-center">
                <img alt="img" src={logo} />
              </Col>
              <Col
                className="text-center"
                xs="12"
                sm="12"
                md={{size: 4, offset: 4}}
                lg="4"
                className="pClass"
              >
                <p>
                  Add Educational Details
                </p>
              </Col>
              <Col
                className="alignFormField"
                xs="12"
                sm="12"
                md={{size: 4, offset: 4}}
                lg="4"
              >

                <FormGroup className="formInputField">
                  <Input
                    type="select"
                    name="yearCompleted"
                    id="select"
                    className="inputSelect"
                    value={yearCompleted}
                    onChange={e =>
                      this.setState (
                        byPropKey ('yearCompleted', e.target.value)
                      )}
                  >
                    <option xs="12" lg="4" value="">
                      Year Of Completion
                    </option>
                    <option xs="12" lg="4" value="2013">2013</option>
                    <option xs="12" lg="4" value="2014">2014</option>
                    <option xs="12" lg="4" value="2014">2015</option>
                    <option xs="12" lg="4" value="2016">2016</option>
                    <option xs="12" lg="4" value="2016">2017</option>
                    <option xs="12" lg="4" value="2016">2018</option>
                    <option xs="12" lg="4" value="2016">2019</option>
                  </Input>
                  {(!yearCompleted && isSubmit) &&<p className="validationerrorMessage">Completion Year  is required *</p>}

                </FormGroup>
                
              </Col>
              <Col
                className="alignFormField"
                xs="6"
                sm="6"
                lg="4"
                md={{size: 4, offset: 4}}
              >
                <FormGroup className="formInputField">
                  <Input
                    type="text"
                    name="university"
                    maxLength="102"
                    id="university"
                    placeholder="University *"
                    value={this.state.university}
                    onChange={e =>{
                        this.setState (byPropKey ('university', e.target.value))}
                    }
                      
                  />
                  {(!university && isSubmit) &&<p className="validationerrorMessage">University name is required *</p>}
                  {(university && university.length > 100) && 
                    <p className="validationerrorMessage"> Maximum limit 100 characters</p>} 
                </FormGroup>
              </Col>
              <Col
                className="alignFormField"
                xs="6"
                sm="6"
                lg="4"
                md={{size: 4, offset: 4}}
              >
                <FormGroup className="formInputField">
                  <Input
                    type="select"
                    name="degreeType"
                    id="select"
                    className="inputSelect"
                    value={this.state.degreeType}
                    onChange={e =>
                      this.setState (byPropKey ('degreeType', e.target.value))}
                  >
                    <option xs="12" lg="4" value="">Degree Type</option>
                    <option xs="12" lg="4" value="Associates">
                      Associates
                    </option>
                    <option xs="12" lg="4" value="MCA">MCA</option>
                    <option xs="12" lg="4" value="BCA">BCA</option>
                    <option xs="12" lg="4" value="BTECH">BTECH</option>
                    <option xs="12" lg="4" value="MBBS">MBBS</option>

                  </Input>
                  {(!degreeType && isSubmit) &&<p className="validationerrorMessage"> Degree type is required *</p>}


                </FormGroup>
              </Col>
              <Col
                className="alignFormField"
                xs="6"
                sm="6"
                lg="4"
                md={{size: 4, offset: 4}}
              >
                <FormGroup className="formInputField">
                  <Input
                    type="text"
                    name="Major courses"
                    id="courses" 
                    maxLength="102"
                    placeholder="Major courses *"
                    value={this.state.courseMajor}
                    onChange={e =>
                      this.setState (byPropKey ('courseMajor', e.target.value))}
                  />
                  {(!courseMajor && isSubmit) &&<p className="validationerrorMessage">Major courses is required *</p>}
                  {(courseMajor && courseMajor.length > 100) &&
                   <p className="validationerrorMessage"> Maximum limit 100 characters</p>} 
                </FormGroup>
              </Col>
              <Col
                className="text-center"
                xs="12"
                sm="12"
                lg="4"
                md={{size: 4, offset: 4}}
              >
                <Button
                  className="buttonClass formInputField"
                >
                  Add{' '}
                </Button>

                <div className="text-center linkRegiterClass">
                  <span
                    style={{cursor: 'pointer'}}
                    onClick={() =>
                      this.setState ({
                        isAddEducation: false,
                      })}
                  >
                    Back
                  </span>

                </div>

              </Col>
            </Row>

          </form>}
      </div>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userinfo: state.Authreducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      GetEducationalDetail,
      AddEducationalDetail,
      DeleteEducationalDetail,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  EducationDetailPage
);
