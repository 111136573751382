



 import React, {Component} from 'react';
import {
  Button,
} from 'reactstrap';
// import collapseRightBox from '../../../images/icons/collapseRightBox.png';
import {checkRightPannelBg} from '../../../utility/checkActivePage';
import collapseRightBox from '../../../images/icons/closeSidebarIcon.png';
import openSidebarIcon from '../../../images/icons/openSidebarIcon.png';





import './rightSideContainer.css';
  

  

class RightSideContainer extends Component {
  constructor (props) {
    super (props);
    this.state={
      overlay:false,
      isCollapse:true
    }
  }

  //  toggleMenu = event => {
  //   if (event.target.matches ('.openmenu')) {
  //     var element = document.getElementById ('sidebarmenu');
  //     if (element.matches ('.menuDisplayHide')) {
  //       element.classList.remove ('menuDisplayHide');
  //     } else {
  //       element.classList.add ('menuDisplayHide');
  //     }
  //   }
  // };


  toggleMenu = event => {
    if (event.target.matches ('.openmenu')) {
      var element = document.getElementById ('sidebarmenu');
      setTimeout(()=>{
      if (element.matches ('.menuDisplayHide')) {
        element.classList.remove ('menuDisplayHide');
      } else {
          element.classList.add ('menuDisplayHide');
      }
    },200)
    }
  };
  openCloseCollapse(){
    this.setState({
      overlay: !this.state.overlay
    })
  }
  componentDidMount(){
    if(window.innerWidth<1920){
      this.setState({
        isCollapse: true
      })
    }else{
      this.setState({
        isCollapse: false
      })
    }
  }
  
  
  render () {
    const {overlay} = this.state;
    return (
      <div className={  " " + (overlay ? ' rightPanelContainerOverlay':' ')}>
         <div className="leftVerticleDiv">
          <Button id="collapseRightPanelButton"  style={{right: overlay ? 470 :' ' ,marginBottom: !overlay ? -100 :' '}} className={" panelCollapseButton " + (overlay ? ' checkOpenPannel':' ')} onClick={()=>this.openCloseCollapse()}> 
            <img src={overlay? collapseRightBox :openSidebarIcon} alt="collapseIcon"/>
          </Button>
        </div>
        <div className= { "openChildContainer " +  (checkRightPannelBg()) +(overlay ? ' showPanel ':' ')}>
        {this.props.children}
        </div>
      </div>
    
       
    );
  }
}


export default  RightSideContainer;