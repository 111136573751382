import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';


class PrivacyPolicy extends Component {
  render () {
    return (
      <div >
          <p className="termsTitle boldWhiteText">Privacy Policy</p>
          <p className="termsTitle boldWhiteText">Last updated: February 14, 2020</p>
          <div className="termsDescription">
            This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.
          </div>
          <div className="termsDescription" >
            We use Your Personal data to provide and improve the Service. By using the Service, You agree to the collection and use of information in accordance with this Privacy Policy.
          </div>

          <p className="termsTitle boldWhiteText">
            Interpretation and Definitions
          </p>
          <p className="termsTitle boldWhiteText">Interpretation</p>
          <div className="termsDescription">
            {' '}
            The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
          </div>

          <p className="termsTitle boldWhiteText"> Definitions</p>

          <div className="termsDescription">
            For the purposes of this Privacy Policy:
           <p>
            <span className="termsTitle boldWhiteText">•You </span>
            means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.
            <br />
            {' '}
            Under GDPR (General Data Protection Regulation), You can be referred to as the Data Subject or as the User as you are the individual using the Service.
            </p>
            <p>
            <span className="termsTitle boldWhiteText"> • Company </span>
            {' '}
            (referred to as either "the Company" , "We" , "Us" or "Our" in this Agreement) refers to Radiologex, 19540 Jamboree Rd, Suite 310, Irvine, CA 92612.
            <br />
            For the purpose of the GDPR, the Company is the Data Controller.
            </p>

            <p>
              <span className="termsTitle boldWhiteText"> • Application </span>
              {' '}
              means the software program provided by the Company downloaded by You on any electronic device, named R-DEE by Radiologex
            </p>
            <p>
              {' '}
              <span className="termsTitle boldWhiteText"> • Affiliate </span>
              {' '}
              means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.
            </p>
            <p>
              {' '}
              <span className="termsTitle boldWhiteText"> • Account </span>
              {' '}
              means a unique account created for You to access our Service or parts of our Service.
              {' '}
            </p>
            <p>
              {' '}
              <span className="termsTitle boldWhiteText"> • Website </span>
              {' '}
              refers to Radiologex, accessible from http://www.radiologex.com
            </p>
            <p>
              {' '}
              <span className="termsTitle boldWhiteText"> • Service </span>
              {' '}
              refers to the Application or the Website or both.
              {' '}
            </p>
            <p>
              {' '}
              <span className="termsTitle boldWhiteText"> • Country </span>
              {' '}
              refers to: California, United States
            </p>
            <p>
              {' '}
              <span className="termsTitle boldWhiteText">
                {' '}• Service Provider{' '}
              </span>
              {' '}
              means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.
              <br />
              {' '}
              For the purpose of the GDPR, Service Providers are considered Data Processors.
              <br />
            </p>
            <p>
              {' '}
              <span className="termsTitle boldWhiteText">
                {' '}• Third-party Social Media Service{' '}
              </span>
              {' '}
              refers to any website or any social network website through which a User can log in or create an account to use the Service.
            </p>
            <p>
              {' '}
              <span className="termsTitle boldWhiteText">
                {' '}• Personal Data{' '}
              </span>
              {' '}
              is any information that relates to an identified or identifiable individual.
              <br />
              {' '}
              For the purposes for GDPR, Personal Data means any information relating to You such as a name, an identification number, location data, online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity.
              <br />
              {' '}
              For the purposes of the CCPA, Personal Data means any information that identifies, relates to, describes or is capable of being associated with, or could reasonably be linked, directly or indirectly, with You.
            </p>
            <p>
              {' '}
              <span className="termsTitle boldWhiteText"> • Cookies </span>
              {' '}
              are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.
              {' '}
            </p>
            <p>
              {' '}
              <span className="termsTitle boldWhiteText"> • Device </span>
              {' '}
              means any device that can access the Service such as a computer, a cellphone or a digital tablet.
              {' '}
            </p>
            <p>
              {' '}
              <span className="termsTitle boldWhiteText"> • Usage Data </span>
              {' '}
              refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
              {' '}
            </p>
            <p>
              {' '}
              <span className="termsTitle boldWhiteText">
                {' '}• Data Controller,{' '}
              </span>
              {' '}
              for the purposes of the GDPR (General Data Protection Regulation), refers to the Company as the legal person which alone or jointly with others determines the purposes and means of the processing of Personal Data.
            </p>
            <p>
              {' '}
              <span className="termsTitle boldWhiteText"> • Do Not Track </span>
              {' '}
              (DNT) is a concept that has been promoted by US regulatory authorities, in particular the U.S. Federal Trade Commission (FTC), for the Internet industry to develop and implement a mechanism for allowing internet users to control the tracking of their online activities across websites.
            </p>
            <p>
              {' '}
              <span className="termsTitle boldWhiteText"> • Business </span>
              {' '}
              for the purpose of the CCPA (California Consumer Privacy Act), refers to the Company as the legal entity that collects Consumers' personal information and determines the purposes and means of the processing of Consumers' personal information, or on behalf of which such information is collected and that alone, or jointly with others, determines the purposes and means of the processing of consumers' personal information, that does business in the State of California.
            </p>

            <p>
              {' '}
              <span className="termsTitle boldWhiteText"> • Consumer </span>
              {' '}
              , for the purpose of the CCPA (California Consumer Privacy Act), means a natural person who is a California resident. A resident, as defined in the law, includes (1) every individual who is in the USA for other than a temporary or transitory purpose, and (2) every individual who is domiciled in the USA who is outside the USA for a temporary or transitory purpose.
            </p>

            <p>
              {' '}
              <span className="termsTitle boldWhiteText"> • Sale </span>
              {' '}
              , for the purpose of the CCPA (California Consumer Privacy Act), means selling, renting, releasing, disclosing, disseminating, making available, transferring, or otherwise communicating orally, in writing, or by electronic or other means, a Consumer’s Personal information to another business or a third party for monetary or other valuable consideration.
            </p>

            <p className="termsTitle boldWhiteText">
              {' '}Collecting and Using Your Personal Data{' '}
            </p>
            <p className="termsTitle boldWhiteText">
              {' '}Types of Data Collected{' '}
            </p>
            <p className="termsTitle boldWhiteText"> Personal Data </p>
            <p>
              {' '}
              While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:
            </p>
            <div>• Email address</div>
            <div>• First name and last name</div>
            <div>• Phone number</div>
            <div>• Address, State, Province, ZIP/Postal code, City</div>
            <div>
              • Bank account information in order to pay for products and/or services within the Service
            </div>
            <div>• Usage Data</div>
            <div>
              When You pay for a product and/or a service via bank transfer, We may ask You to provide information to facilitate this transaction and to verify Your identity. Such information may include, without limitation:
            </div>
            <div>• Date of birth</div>
            <div>• Passport or National ID card</div>
            <div>• Bank card statement</div>
            <div>• Other information linking You to an address</div>
            <div>• Date of birth</div>

            <div>
              <p className="termsTitle boldWhiteText"> Usage Data </p>
              <div>
                Usage Data is collected automatically when using the Service.
              </div>
              <div>
                Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.
              </div>
              <div>
                When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.
              </div>
              <div>
                We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.
              </div>

            </div>
            <div>
              <p className="termsTitle boldWhiteText">
                Information from Third-Party Social Media Services
              </p>
              <div>
                The Company allows You to create an account and log in to use the Service through the following Third-party Social Media Services:
              </div>

              <div>• Google</div>
              <div>• Facebook</div>
              <div>• Twitter</div>
              <div>
                If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We may collect Personal data that is already associated with Your Third-Party Social Media Service's account, such as Your name, Your email address, Your activities or Your contact list associated with that account.
              </div>
              <div>
                You may also have the option of sharing additional information with the Company through Your Third-Party Social Media Service's account. If You choose to provide such information and Personal Data, during registration or otherwise, You are giving the Company permission to use, share, and store it in a manner consistent with this Privacy Policy.
              </div>
            </div>

            <div>
              <p className="termsTitle boldWhiteText">
                Information Collected while Using the Application
              </p>
              <div>
                While using Our Application, in order to provide features of Our Application, We may collect, with your prior permission:
              </div>

              <div>• Information regarding your location</div>
              <div>
                • Information from your Device's phone book (contacts list)
              </div>
              <div>
                • Pictures and other information from your Device's camera and photo library
              </div>
              <div>
                We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it be simply stored on Your device.
              </div>
              <div>
                You can enable or disable access to this information at any time, through Your Device settings. You can also enable or disable location services when You use Our Service at any time, through Your Device settings.
              </div>
            </div>

            <div>
              <p className="termsTitle boldWhiteText">
                Tracking Technologies and Cookies
              </p>
              <div>
                We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.
              </div>
              <div>
                You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service.
              </div>
              <div>
                Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.
              </div>
              <div>
                We use both session and persistent Cookies for the purposes set out below:
              </div>
            </div>

            <div>
              <span className="termsTitle boldWhiteText">
                {' '}• Necessary / Essential Cookies{' '}
              </span>
            </div>
            <div>
              <p>Type: Session Cookies</p>
              <p>Administered by: Us</p>
              <p>
                Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.
              </p>

            </div>
            <div>
              <span className="termsTitle boldWhiteText">
                {' '}• Cookies Policy / Notice{' '}
              </span>
            </div>
            <div>
              <span className="termsTitle boldWhiteText">
                {' '}Acceptance Cookies
              </span>
              <p>Type: Persistent Cookies</p>
              <p>Administered by: Us</p>
              <p>
                Purpose: These Cookies identify if users have accepted the use of cookies on the Website.
              </p>
            </div>

            <div>
              <span className="termsTitle boldWhiteText">
                {' '}• Functionality Cookies
              </span>
            </div>
            <div>
              <p>Type: Persistent Cookies</p>
              <p>Administered by: Us</p>
              <p>
                Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.
              </p>
            </div>

            <div>
              <span className="termsTitle boldWhiteText">
                {' '}• Tracking and Performance Cookies
              </span>

              <p>Type: Persistent Cookies</p>
              <p>Administered by: Third-Parties</p>
              <p>
                Purpose: These Cookies are used to track information about traffic to the Website and how users use the Website. The information gathered via these Cookies may directly or indirectly identify you as an individual visitor. This is because the information collected is typically linked to a pseudonymous identifier associated with the device you use to access the Website. We may also use these Cookies to test new advertisements, pages, features or new functionality of the Website to see how our users react to them.
              </p>

            </div>

            <div>
              <span className="termsTitle boldWhiteText">
                {' '}• Targeting and Advertising Cookies
              </span>
              <p>Type: Persistent Cookies</p>
              <p>Administered by: Third-Parties</p>
              <p>
                Purpose: These Cookies track your browsing habits to enable Us to show advertising which is more likely to be of interest to You. These Cookies use information about your browsing history to group You with other users who have similar interests. Based on that information, and with Our permission, third party advertisers can place Cookies to enable them to show adverts which We think will be relevant to your interests while You are on third party websites.
              </p>
            </div>

            <div>

              For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy.
            </div>

            <div>

              <div className="termsTitle boldWhiteText">
                Use of Your Personal Data
              </div>
              <div>
                The Company may use Personal Data for the following purposes:
              </div>

              <div>
                <span className="termsTitle boldWhiteText">
                  {' '}• To provide and maintain our Service,
                </span>
                {' '}
                including to monitor the usage of our Service.
              </div>

              <div>
                <span className="termsTitle boldWhiteText">
                  {' '}• To manage Your Account:
                </span>
                {' '}
                to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.
              </div>

              <div>
                <span className="termsTitle boldWhiteText">
                  {' '}• For the performance of a contract:
                </span>
                {' '}
                the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.
              </div>

              <div>
                <span className="termsTitle boldWhiteText">
                  {' '}• To contact You:
                </span>
                {' '}
                To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.
              </div>

              <div>
                <span className="termsTitle boldWhiteText">
                  {' '}• To provide You{' '}
                </span>
                {' '}
                with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.
              </div>

              <div>
                <span className="termsTitle boldWhiteText">
                  {' '}• To manage Your requests:
                </span>
                {' '}
                To attend and manage Your requests to Us.
              </div>

              <div>
                We may share your personal information in the following situations:
              </div>
              <div>
                <span className="termsTitle boldWhiteText">
                  {' '}•With Service Providers:
                </span>
                {' '}
                TWe may share Your personal information with Service Providers to monitor and analyze the use of our Service, to show advertisements to You to help support and maintain Our Service, to contact You, to advertise on third party websites to You after You visited our Service or for payment processing.
              </div>

              <div>
                <span className="termsTitle boldWhiteText">
                  {' '}• For Business transfers:
                </span>
                {' '}
                We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.
              </div>

              <div>
                <span className="termsTitle boldWhiteText">
                  {' '}• With Affiliates:
                </span>
                {' '}
                We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.
              </div>

              <div>
                <span className="termsTitle boldWhiteText">
                  {' '}• With Business partners:
                </span>
                {' '}
                We may share Your information with Our business partners to offer You certain products, services or promotions.
              </div>
              <div>
                <span className="termsTitle boldWhiteText">
                  {' '}•With other users:
                </span>
                {' '}
                when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see Your name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.
              </div>

            </div>

            <div>
              <p className="termsTitle boldWhiteText">
                Retention of Your Personal Data
              </p>
              <p>
                The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.
              </p>
              <p>
                The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.
              </p>

              <p className="termsTitle boldWhiteText">
                Transfer of Your Personal Data
              </p>
              <p>
                Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.
              </p>
              <p>
                Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.
              </p>
              <p>
                The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.
              </p>
            </div>

            <div>
              <p className="termsTitle boldWhiteText">
                Disclosure of Your Personal Data
              </p>
              <p className="termsTitle boldWhiteText">Business Transactions</p>
              <p>
                If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.
              </p>
              <p className="termsTitle boldWhiteText">Law enforcement</p>
              <p>
                Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
              </p>

              <p className="termsTitle boldWhiteText">
                Other legal requirements
              </p>
              <p>
                The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
              </p>
              <div>• Comply with a legal obligation</div>
              <div>
                • Protect and defend the rights or property of the Company
              </div>
              <div>
                • Prevent or investigate possible wrongdoing in connection with the Service
              </div>
              <div>
                • Protect the personal safety of Users of the Service or the public
              </div>
              <div>• Protect against legal liability</div>

              <p className="termsTitle boldWhiteText">
                Security of Your Personal Data
              </p>
              <p>
                The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.
              </p>
              <p className="termsTitle boldWhiteText">
                Detailed Information on the Processing of Your Personal Data
              </p>
              <p>
                Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are obligated not to disclose or use it for any other purpose.
              </p>
              <p className="termsTitle boldWhiteText">Analytics</p>
              <p>
                We may use third-party Service providers to monitor and analyze the use of our Service.
              </p>

              <div>
                <span className="termsTitle boldWhiteText">
                  {' '}•Google Analytics
                </span>

                <p>
                  Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.
                </p>
                <p>
                  For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: https://policies.google.com/privacy? hl=en
                </p>

              </div>

              <div>
                <span className="termsTitle boldWhiteText"> • Firebase</span>

                <p>Firebase is an analytics service provided by Google Inc.</p>
                <p>
                  You may opt-out of certain Firebase features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy: https://policies.google.com/privacy? hl=en
                </p>
                <p>
                  We also encourage you to review the Google's policy for safeguarding your data: https://support.google.com/analytics/answ

                </p>
                <p>
                  For more information on what type of information Firebase collects, please visit the Google Privacy & Terms web page: https://policies.google.com/privacy? hl=en
                </p>

              </div>

              <div>
                <span className="termsTitle boldWhiteText"> • Matomo</span>
                <p>
                  Matomo is a web analytics service. You can visit their Privacy Policy page here: https://matomo.org/privacy-policy
                </p>

              </div>
              <div>
                <span className="termsTitle boldWhiteText"> • Clicky</span>
                <p>
                  Clicky is a web analytics service. Read the Privacy Policy for Clicky here: https://clicky.com/terms
                </p>

              </div>

            </div>
          </div>

          <div>
            <div className="termsTitle boldWhiteText"> Advertising</div>
            <div>
              We may use Service providers to show advertisements to You to help support and maintain Our Service.
            </div>
            <div className="termsDescription">
              <p className="termsTitle boldWhiteText">
                • Google AdSense & DoubleClick Cookie
              </p>
              <div>
                Google, as a third party vendor, uses cookies to serve ads on our Service. Google's use of the DoubleClick cookie enables it and its partners to serve ads to our users based on their visit to our Service or other websites on the Internet.
              </div>
              <div>
                You may opt out of the use of the DoubleClick Cookie for interest-based advertising by visiting the Google Ads Settings web page: http://www.google.com/ads/preferences
              </div>
            </div>

            <div  className="termsDescription">
              <p className="termsTitle boldWhiteText">• AdMob by Google</p>
              <div>AdMob by Google is provided by Google Inc.</div>
              <div>
                You can opt-out from the AdMob by Google service by following the instructions described by Google: https://support.google.com/ads/answer/2 hl=en
                {' '}
              </div>
              <div>
                For more information on how Google uses the collected information, please visit the "How Google uses data when you use our partners' sites or app" page: https://policies.google.com/technologies/p sites or visit the Privacy Policy of Google: https://policies.google.com/privacy
              </div>
            </div>

            <div  className="termsDescription">
              <p className="termsTitle boldWhiteText">• Bing Ads</p>
              <div>
                Bing Ads is an advertising service provided by Microsoft Inc.
              </div>
              <div>
                {' '}
                You can opt-out from Bing Ads by following the instructions on Bing Ads Opt-out page: https://advertise.bingads.microsoft.com/e us/resources/policies/personalizedads
              </div>
              <div>
                For more information about Bing Ads, please visit their Privacy Policy: https://privacy.microsoft.com/enus/PrivacyStatement
              </div>
            </div>

            <div  className="termsDescription"> 
              <p className="termsTitle boldWhiteText">• Flurry</p>
              <div>
                Flurry is provided by Yahoo! Inc.

                You can opt-out of the Flurry service and prevent it from using and sharing your information by visiting the Flurry Opt-out page: https://developer.yahoo.com/flurry/enduser-opt-out/
              </div>
              <div>
                {' '}
                For more information on the privacy practices policies of Yahoo!, please visit their Privacy Policy: https://policies.yahoo.com/xa/en/yahoo/p
              </div>

            </div>

            <div  className="termsDescription">
              <p className="termsTitle boldWhiteText">• InMobi</p>
              <div>InMobi is provided by InMobi Pte Ltd. </div>
              <div>
                You can opt-out from InMobi service by following the instructions as described on the InMobi Opt-out page: http://www.inmobi.com/page/opt-out/
              </div>
              <div>
                For more information on the privacy practices and policies of InMobi, please visit the InMobi Privacy Policy: http://www.inmobi.com/privacy-policy/
              </div>
            </div>

            <div  className="termsDescription">
              <p className="termsTitle boldWhiteText">• MoPub</p>
              <div>MoPub is provided by MobPub Inc. </div>
              <div>
                You can opt-out from MobPub service by following the instructions on MoPub Opt-out page:
                {' '}
                <p>
                  http://www.mopub.com/optout/

                  For more information on what information MobPub collects and how it is used, please read MoPub Privacy Policy: http://www.mopub.com/legal/privacy/
                </p>
              </div>
            </div>

            <div  className="termsDescription">
              <p className="termsTitle boldWhiteText">• StartApp</p>
              <div>StartApp is provided by StartApp Inc. </div>
              <div>
                You can opt-out from interest-based ads served by StartApp by following the instructions on their Privacy Policy, section "8. Your Choices and Controls":
                <p>
                  {' '}https://www.startapp.com/policy/privacypolicy/

                  For more information on what information StartApp collects and how it is used, please read their Privacy Policy: https://www.startapp.com/policy/privacypolicy/

                </p>
              </div>
            </div>

            <div  className="termsDescription">
              <p className="termsTitle boldWhiteText">• AdColony</p>
              <div>AdColony is provided by Jirbo Inc. </div>
              <div>
                You can opt-out from AdColony service by visiting the AdColony Opt-out page:
                <p>
                  https://www.adcolony.com/privacypolicy/

                  You can also opt-out from AdColony through the Digital Advertising Alliance in the USA http://www.aboutads.info/choices/ or opt-out using your mobile device settings.
                  <br />
                  For more information AdColony, please visit the Privacy Policy of AdColony: http://www.adcolony.com/privacypolicy/

                </p>
              </div>
            </div>
            <div  className="termsDescription">
              <p className="termsTitle boldWhiteText">• AppLovin</p>
              <div>
                <p>AppLovin is provided by the AppLovin Corporation.</p>
                <p>
                  If you'd like to opt-out from AppLovin service, please visit the AppLovin Opt-out page: https://www.applovin.com/optout
                </p>
                <p>
                  If you'd like to learn more about AppLovin, please visit the Privacy Policy page of AppLovin: https://www.applovin.com/privacy
                </p>
              </div>

            </div>

            <div  className="termsDescription">
              <p className="termsTitle boldWhiteText">• Vungle</p>
              <div>
                <p>Vungle is provided by Vungle Inc.</p>
                <p>
                  You can opt-out from Vungle service by following the instructions as described by Vungle on their Privacy Policy page: http://vungle.com/privacy/
                </p>
                <p>
                  For more information about Vungle, please visit Vungle Privacy Policy: http://vungle.com/privacy/
                </p>
              </div>

            </div>

            <div  className="termsDescription">
              <p className="termsTitle boldWhiteText">• AdButler</p>
              <div>
                <p>
                  AdButler is an advertising service provided by Sparklit Networks Inc.
                </p>
                <p>
                  For more information about AdButler, please visit their Privacy Policy: https://www.sparklit.com/agreements.spa agreement=privacy
                </p>
              </div>

            </div>

            <div  className="termsDescription">
              <p className="termsTitle boldWhiteText">• Unity Ads</p>
              <div>
                <p>Unity Ads is provided by Unity Technologies.</p>
                <p>
                  You can opt-out from Unity Ads service by following the instructions as described by Unity Technologies on their Privacy Policy page: https://unity3d.com/legal/privacypolicy
                </p>
                <p>
                  For more information about Unity Technologies, please visit Unity Technologies Privacy Policy: https://unity3d.com/legal/privacypolicy
                </p>
              </div>

            </div>

            <div  className="termsDescription">
              <p className="termsTitle boldWhiteText">Email Marketing</p>
              <div>
                <p>
                  We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and other information that may be of interest to You. You may opt-out of receiving any, or all, of these communications from Us by following the unsubscribe link or instructions provided in any email We send or by contacting Us.
                </p>
                <p>
                  We may use Email Marketing Service Providers to manage and send emails to You.
                </p>
              </div>

              <div  className="termsDescription">
                <p className="termsTitle boldWhiteText">• Mailchimp</p>
                <div>
                  Mailchimp is an email marketing sending service provided by The Rocket Science Group LLC. For more information on the privacy practices of Mailchimp, please visit their Privacy policy: https://mailchimp.com/legal/privacy/
                </div>
              </div>

              <div  className="termsDescription">
                <p className="termsTitle boldWhiteText">• Constant Contact</p>
                <div>
                  Constant Contact is an email marketing sending service provided by Constant Contact, Inc.

                  For more information on the privacy practices of Constant Contact, please visit their Privacy policy: https://www.constantcontact.com/forward center
                </div>
              </div>

              <div  className="termsDescription">
                <p className="termsTitle boldWhiteText">• AWeber</p>
                <div>
                  AWeber is an email marketing sending service provided by AWeber Communications.

                  For more information on the privacy practices of AWeber, please visit their Privacy policy: https://www.aweber.com/privacy.html
                </div>

              </div>

              <div  className="termsDescription">
                <p className="termsTitle boldWhiteText">• GetResponse</p>
                <div>
                  GetResponse is an email marketing sending service provided by GetResponse.

                  For more information on the privacy practices of GetResponse, please visit their Privacy policy: https://www.getresponse.com/legal/privacy.html
                </div>

              </div>

            </div>

            <div  className="termsDescription">
              <p className="termsTitle boldWhiteText">Behavioral Remarketing</p>
              <div>
                The Company uses remarketing services to advertise on third party websites to You after You visited our Service. We and Our third-party vendors use cookies to inform, optimize and serve ads based on Your past visits to our Service.
              </div>

              <div>
                <p className="termsTitle boldWhiteText">
                  • Google Ads (AdWords)
                </p>
                <div>
                  Google Ads (AdWords) remarketing service is provided by Google Inc.

                  You can opt-out of Google Analytics for Display Advertising and customise the Google Display Network ads by visiting the Google Ads Settings page: http://www.google.com/settings/ads

                  Google also recommends installing the Google Analytics Opt-out Browser Add-on https://tools.google.com/dlpage/gaoptout - for your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent their data from being collected and used by Google Analytics.
                </div>
                <p>
                  For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page: https://policies.google.com/privacy? hl=en
                </p>

              </div>

              <div  className="termsDescription">
                <p className="termsTitle boldWhiteText">
                  • Bing Ads Remarketing
                </p>
                <div>
                  Bing Ads remarketing service is provided by Microsoft Inc.
                  You can opt-out of Bing Ads interest- based ads by following their instructions: https://advertise.bingads.microsoft.com/e us/resources/policies/personalizedads
                  You can learn more about the privacy practices and policies of Microsoft by visiting their Privacy Policy page: https://privacy.microsoft.com/enus/PrivacyStatement
                </div>
              </div>

              <div  className="termsDescription">
                <p className="termsTitle boldWhiteText">• Twitter</p>
                <div>
                  Twitter remarketing service is provided by Twitter Inc.
                  You can opt-out from Twitter's interest-based ads by following their instructions: https://support.twitter.com/articles/201704 You can learn more about the privacy practices and policies of Twitter by visiting their Privacy Policy page: https://twitter.com/privacy
                </div>
              </div>

            </div>

            <div  className="termsDescription">
              <p className="termsTitle boldWhiteText">• Facebook</p>
              <div>
                Facebook remarketing service is provided by Facebook Inc.

                You can learn more about interestbased advertising from Facebook by visiting this page:
                https://www.facebook.com/help/164968693837950
              </div>
              <div>
                To opt-out from Facebook's interestbased ads, follow these instructions from Facebook: https://www.facebook.com/help/568137493302217
              </div>

              <div>
                Facebook adheres to the Self- Regulatory Principles for Online Behavioural Advertising established by the Digital Advertising Alliance. You can also opt-out from Facebook and other participating companies through the Digital Advertising Alliance in the USA http://www.aboutads.info/choices/, the Digital Advertising Alliance of Canada in Canada http://youradchoices.ca/ or the European Interactive Digital Advertising Alliance in Europe http://www.youronlinechoices.eu/, or opt-out using your mobile device settings.
              </div>
              <div>
                For more information on the privacy practices of Facebook, please visit Facebook's Data Policy: https://www.facebook.com/privacy/explanation
              </div>

            </div>

            <div  className="termsDescription">
              <p className="termsTitle boldWhiteText">• Pinterest</p>
              <div>
                Pinterest remarketing service is provided by Pinterest Inc.
                You can opt-out from Pinterest's interest-based ads by enabling the "Do Not Track" functionality of your web browser or by following Pinterest instructions: http://help.pinterest.com/en/articles/perso and-data

                You can learn more about the privacy practices and policies of Pinterest by visiting their Privacy Policy page: https://about.pinterest.com/en/privacypolicy
              </div>
            </div>

            <div  className="termsDescription">
              <p className="termsTitle boldWhiteText">• AdRoll</p>
              <div>
                AdRoll remarketing service is provided by Semantic Sugar, Inc.
                You can opt-out of AdRoll remarketing by visiting this AdRoll Advertising Preferences web page: http://info.evidon.com/pub_info/573? v=1&nt=1&nw=false

                For more information on the privacy practices of AdRoll, please visit the AdRoll Privacy Policy web page: http://www.adroll.com/about/privacy
              </div>
            </div>

            <div  className="termsDescription">
              <p className="termsTitle boldWhiteText">• Perfect Audience</p>
              <div>
                Perfect Audience remarketing service is provided by NowSpots Inc.

                You can opt-out of Perfect Audience remarketing by visiting these pages: Platform Opt-out (http://pixel.prfct.co/coo) and Partner Opt-out (http://ib.adnxs.com/optout).

                For more information on the privacy practices of Perfect Audience, please visit the Perfect Audience Privacy Policy & Opt-out web page:
                https://www.perfectaudience.com/privacy/index.html
              </div>
            </div>

            <div  className="termsDescription">
              <p className="termsTitle boldWhiteText">• AppNexus</p>
              <div>
                AppNexus remarketing service is provided by AppNexus Inc.

                You can opt-out of AppNexus remarketing by visiting the Privacy & the AppNexus Platform web page: https://www.appnexus.com/platformprivacy-policy#choices
                For more information on the privacy practices of AppNexus, please visit the AppNexus Platform Privacy Policy web page: https://www.appnexus.com/platformprivacy-policy
              </div>
            </div>

            <div  className="termsDescription">
              <p className="termsTitle boldWhiteText">Payments</p>
              <p>
                We may provide paid products and/or services within the Service. In that case, we may use third-party services for payment processing (e.g. payment processors).

                We will not store or collect Your payment card details. That information is provided directly to Our third-party payment processors whose use of Your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
              </p>
              <div>
                <p className="termsTitle boldWhiteText">
                  • Apple Store In-App Payments
                </p>
                <p>
                  Their Privacy Policy can be viewed at https://www.apple.com/legal/privacy/enww/
                </p>

              </div>
              <div>
                <p className="termsTitle boldWhiteText">
                  • Google Play In-App Payments
                </p>
                <p>
                  Their Privacy Policy can be viewed at  https://www.google.com/policies/privacy/
                </p>

              </div>

              <div>
                <p className="termsTitle boldWhiteText">• Stripe</p>
                <p>
                  Their Privacy Policy can be viewed at https://stripe.com/us/privacy
                </p>

              </div>
              <div>
                <p className="termsTitle boldWhiteText">• WePay</p>
                <p>
                  Their Privacy Policy can be viewed at https://go.wepay.com/privacy-policy
                </p>

              </div>
              <div>
                <p className="termsTitle boldWhiteText">• WorldPay</p>
                <p>
                  Their Privacy Policy can be viewed at https://www.worldpay.com/engb/privacy-policy
                </p>

              </div>

              <div>
                <p className="termsTitle boldWhiteText">• PayPal</p>
                <p>
                  Their Privacy Policy can be viewed at https://www.paypal.com/webapps/mpp/ua/privacy-full
                </p>

              </div>

              <div>
                <p className="termsTitle boldWhiteText">• PayPal</p>
                <p>
                  Their Privacy Policy can be viewed at https://www.braintreepayments.com/legal/braintree-privacy-policy
                </p>

              </div>
              <div>
                <p className="termsTitle boldWhiteText">• FastSpring</p>
                <p>
                  Their Privacy Policy can be viewed at http://fastspring.com/privacy/
                </p>

              </div>
              <div>
                <p className="termsTitle boldWhiteText">• Authorize.net</p>
                <p>
                  Their Privacy Policy can be viewed at https://www.authorize.net/company/privacy/
                </p>

              </div>
              <div>
                <p className="termsTitle boldWhiteText">• 2Checkout</p>
                <p>
                  Their Privacy Policy can be viewed at https://www.2checkout.com/policies/privacy-policy
                </p>

              </div>

              <div>
                <p className="termsTitle boldWhiteText">• Sage Pay</p>
                <p>
                  Their Privacy Policy can be viewed at https://www.sagepay.co.uk/policies
                </p>

              </div>

              <div>
                <p className="termsTitle boldWhiteText">• Square</p>
                <p>
                  Their Privacy Policy can be viewed at https://squareup.com/legal/privacyno-accounts
                </p>

              </div>

              <div>
                <p className="termsTitle boldWhiteText">• Go Cardless</p>
                <p>
                  Their Privacy Policy can be viewed at https://gocardless.com/eneu/legal/privacy/
                </p>

              </div>
              <div>
                <p className="termsTitle boldWhiteText">• Elavon</p>
                <p>
                  Their Privacy Policy can be viewed at https://www.elavon.com/privacypledge.html
                </p>

              </div>

              <div>
                <p className="termsTitle boldWhiteText">• Verifone</p>
                <p>
                  Their Privacy Policy can be viewed at https://www.verifone.com/en/us/legal
                </p>

              </div>
              <div>
                <p className="termsTitle boldWhiteText">• Moneris</p>
                <p>
                  Their Privacy Policy can be viewed at https://www.moneris.com/en/PrivacyPolicy
                </p>

              </div>

              <div>
                <p className="termsTitle boldWhiteText">• WeChat</p>
                <p>
                  Their Privacy Policy can be viewed at https://www.wechat.com/en/privacy_policy.html
                </p>

              </div>

              <div >
                <p className="termsTitle boldWhiteText">• Alipay</p>
                <p>
                  Their Privacy Policy can be viewed at https://render.alipay.com/p/f/agreementpages/alipayglobalprivacypolicy.html
                </p>

              </div>
            </div>

            <div  className="termsDescription">
              When You use Our Service to pay a product and/or service via bank transfer, We may ask You to provide information to facilitate this transaction and to verify Your identity.
            </div>

            <div>

            <p className="termsTitle boldWhiteText">Usage, Performance and Miscellaneous</p>
            <p  className="termsDescription">We may use third-party Service Providers to provide better improvement of our Service.</p>


            <div  className="termsDescription">
            <p className="termsTitle boldWhiteText">• Invisible reCAPTCHA</p>
            <p>We use an invisible captcha service named reCAPTCHA. reCAPTCHA is operated by Google.</p>
            <p>The reCAPTCHA service may collect information from You and from Your Device for security purposes.

            The information gathered by reCAPTCHA is held in accordance with the Privacy Policy of Google: https://www.google.com/intl/en/policies/privacy/</p>
            </div>

            <div  className="termsDescription">
            <p className="termsTitle boldWhiteText">• Mouseflow</p>
            <div>
            Mouseflow is a session replay and heatmap tool that shows how visitors click, move, scroll, browse, and pay attention on websites. The service is operated by ApS.
            </div>
            <div>
            Mouseflow service may collect information from Your device.</div>
            <div>The information gathered by Mouseflow is held in accordance with its Privacy Policy: https://mouseflow.com/privacy/</div>

            </div>

            <div  className="termsDescription">
            <p className="termsTitle boldWhiteText">• FreshDesk</p>
            <div>FreshDesk is a customer support software. The service is operated by Freshworks, Inc.

            FreshDesk service may collect information from Your Device.
            
            The information gathered by FreshDesk is held in accordance with its Privacy Policy: https://www.freshworks.com/privacy/</div>

            </div>


            <div  className="termsDescription">
            <p className="termsTitle boldWhiteText">• Google Places</p>
            <div>Google Places is a service that returns information about places using HTTP requests. It is operated by Google.</div>
            <div>Google Places service may collect information from You and from Your Device for security purposes.</div>
            <div>The information gathered by Google Places is held in accordance with the Privacy Policy of Google: https://www.google.com/intl/en/policies/privacy/</div>

            </div>


            </div>
            <div  className="termsDescription">

            <p className="termsTitle boldWhiteText">GDPR Privacy</p>
            <p className="termsTitle boldWhiteText">Legal Basis for Processing Personal Data under GDPR</p>
            <p>We may process Personal Data under the following conditions:</p>

            <div className="termsDescription">
            <span className="termsTitle boldWhiteText">• Consent :</span> You have given Your consent for processing Personal Data for one or more specific purposes.

            </div>
            <div className="termsDescription">
            <span className="termsTitle boldWhiteText">• Performance of a contract :</span>Provision of Personal Data is necessary for the performance of an agreement with You and/or for any pre-contractual obligations thereof.

            </div>
            <div className="termsDescription">
            <span className="termsTitle boldWhiteText">• Legal obligations :</span> Processing Personal Data is necessary for compliance with a legal obligation to which the Company is subject.
            </div>
            <div className="termsDescription">
            <span className="termsTitle boldWhiteText"> • Vital interests :</span> Processing Personal Data is necessary in order to protect Your vital interests or of another natural person.

            </div>

            <div className="termsDescription">
            <span className="termsTitle boldWhiteText">• Public interests :</span> Processing Personal Data is related to a task that is carried out in the public interest or in the exercise of official authority vested in the Company.

            </div>


            <div className="termsDescription">
            <span className="termsTitle boldWhiteText">• Legitimate interests :</span> Processing Personal Data is necessary for the purposes of the legitimate interests pursued by the Company.

            </div>
            <div className="termsDescription">In any case, the Company will gladly help to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Data is a statutory or contractual requirement, or a requirement necessary to enter into a contract.</div>

            </div>

            <div>

            <p className="termsTitle boldWhiteText">Your Rights under the GDPR</p>
            <div className="termsDescription">
            The Company undertakes to respect the confidentiality of Your Personal Data and to guarantee You can exercise Your rights.

You have the right under this Privacy Policy, and by law if You are within the EU, to:
            
            </div>
            <div className="termsDescription">
            <span className="termsTitle boldWhiteText">• Request access to Your Personal Data.</span> The right to access, update or delete the information We have on You. Whenever made possible, you can access, update or request deletion of Your Personal Data directly within Your account settings section. If you are unable to perform these actions yourself, please contact Us to assist You. This also enables You to receive a copy of the Personal Data We hold about You.

            </div>


            <div className="termsDescription">
            <span className="termsTitle boldWhiteText">• Request correction of the Personal Data that We hold about You. </span> You have the right to to have any incomplete or inaccurate information We hold about You corrected.
            </div>


            <div className="termsDescription">
            <span className="termsTitle boldWhiteText">• Object to processing of Your Personal Data. </span> This right exists where We are relying on a legitimate interest as the legal basis for Our processing and there is something about Your particular situation, which makes You want to object to our processing of Your Personal Data on this ground. You also have the right to object where We are processing Your Personal Data for direct marketing purposes.
            </div>


            <div className="termsDescription">
            <span className="termsTitle boldWhiteText">• Request erasure of Your Personal Data. </span> You have the right to ask Us to delete or remove Personal Data when there is no good reason for Us to continue processing it.
            </div>

            <div className="termsDescription">
            <span className="termsTitle boldWhiteText">• Request the transfer of Your Personal Data.</span> We will provide to You, or to a third-party You have chosen, Your Personal Data in a structured, commonly used, machine-readable format. Please note that this right only applies to automated information which You initially provided consent for Us to use or where We used the information to perform a contract with You.
            </div>

            <div className="termsDescription">
            <span className="termsTitle boldWhiteText">• Withdraw Your consent.</span> You have the right to withdraw Your consent on using your Personal Data. If You withdraw Your consent, We may not be able to provide You with access to certain specific functionalities of the Service.</div>
          
            
            
            </div>

            <div>

            <p className="termsTitle boldWhiteText"> Exercising of Your</p>
            <p className="termsTitle boldWhiteText"> GDPR Data Protection Rights</p>
            <div className="termsDescription">
            <p>You may exercise Your rights of access, rectification, cancellation and opposition by contacting Us. Please note that we may ask You to verify Your identity before responding to such requests. If You make a request, We will try our best to respond to You as soon as possible.</p>
            <p>You have the right to complain to a Data Protection Authority about Our collection and use of Your Personal Data. For more information, if You are in the European Economic Area (EEA), please contact Your local data protection authority in the EEA.</p>
            </div>

            
            </div>
            <div>

            <p className="termsTitle boldWhiteText"> CCPA Privacy</p>
            <p className="termsTitle boldWhiteText"> Your Rights under the CCPA</p>
            <div className="termsDescription">
            Under this Privacy Policy, and by law if You are a resident of California, You have the following rights:
            </div>


            <div className="termsDescription">
            <span className="termsTitle boldWhiteText">• The right to notice.</span> You must be properly notified which categories of Personal Data are being collected and the purposes for which the Personal Data is being used.</div>

            <div className="termsDescription">
            <span className="termsTitle boldWhiteText">• The right to access / the right to request.</span> The CCPA permits You to request and obtain from the Company information regarding the disclosure of Your Personal Data that has been collected in the past 12 months by the Company or its subsidiaries to a third-party for the third party’s direct marketing purposes.</div>


            <div className="termsDescription">
            <span className="termsTitle boldWhiteText">• The right to say no to the sale of Personal Data.</span> You also have the right to ask the Company not to sell Your Personal Data to third parties. You can submit such a request by visiting our "Do Not Sell My Personal Information" section or web page.</div>


            <div className="termsDescription">
            <span className="termsTitle boldWhiteText">• The right to know about Your Personal Data.</span>You have the right to request and obtain from the Company information regarding the disclosure of the following:
            <p> • The categories of Personal Data collected</p>
            <p> • The sources from which the Personal Data was collected</p>
            <p> • The business or commercial purpose for collecting or selling the Personal Data</p>
            <p> • Categories of third parties with whom We share Personal Data</p>
            <p> • The specific pieces of Personal Data we collected about You</p>

            
            
            </div>

            <div className="termsDescription">
            <span className="termsTitle boldWhiteText">• The right to delete Personal Data.</span>
            You also have the right to request the deletion of Your Personal Data that have been collected in the past 12 months.
            </div>

            <div className="termsDescription">
            <span className="termsTitle boldWhiteText">• The right not to be discriminated against.</span>
            You have the right not to be discriminated against for exercising any of Your Consumer's rights, including by:
            <p>• Denying goods or services to You</p>
            <p>• Charging different prices or rates for goods or services, including the use of discounts or other benefits or imposing penalties</p>
            <p>• Providing a different level or quality of goods or services to You</p>
            <p>• Suggesting that You will receive a different price or rate for goods or services or a different level or quality of goods or services.</p>

            </div>


            </div>

            <div >
            <p className="termsTitle boldWhiteText">Exercising Your CCPA Data Protection Rights</p>
            <div className="termsDescription">
            <p>In order to exercise any of Your rights under the CCPA, and if you are a California resident, You can email or call us or visit our "Do Not Sell My Personal Information" section or web page.</p>
            <p>The Company will disclose and deliver the required information free of charge within 45 days of receiving Your verifiable request. The time period to provide the required information may be extended once by an additional 45 days when reasonable necessary and with prior notice.</p>
            </div>

            <p className="termsTitle boldWhiteText">Do Not Sell My Personal Information</p>
            <div className="termsDescription">
            We do not sell personal information. However, the Service Providers we partner with (for example, our advertising partners) may use technology on the Service that "sells" personal information as defined by the CCPA law.
            <p>
            If you wish to opt out of the use of your personal information for interest-based advertising purposes and these potential sales as defined under CCPA law, you may do so by following the instructions below.
            </p>
            <p>Please note that any opt out is specific to the browser You use. You may need to opt out on every browser that you use.</p>
            </div>

            <p className="termsTitle boldWhiteText">Website</p>
            <div className="termsDescription">You can opt out of receiving ads that are personalized as served by our Service Providers by following our instructions presented on the Service:
            <p>• From Our "Cookie Consent" notice banner</p>
            <p>• Or from Our "CCPA Opt-out" notice banner</p>
            <p>• Or from Our "Do Not Sell My Personal Information" notice banner</p>
            <p>• Or from Our "Do Not Sell My Personal Information" link</p>
           

            </div>
            <p className="termsDescription">The opt out will place a cookie on Your computer that is unique to the browser You use to opt out. If you change browsers or delete the cookies saved by your browser, you will need to opt out again.</p>

            </div>

            <div>

            <p className="termsTitle boldWhiteText">Mobile Devices</p>

            <div className="termsDescription">
            Your mobile device may give you the ability to opt out of the use of information about the apps you use in order to serve you ads that are targeted to your interests:
            <p>• "Opt out of Interest-Based Ads" or "Opt out of Ads Personalization" on Android devices</p>
            <p>• "Limit Ad Tracking" on iOS devices</p>
            </div>
            <div className="termsDescription">You can also stop the collection of location information from Your mobile device by changing the preferences on your mobile device.</div>

            
            
            </div>

            <div>

            <p className="termsTitle boldWhiteText">"Do Not Track" Policy as Required by California Online Privacy Protection Act (CalOPPA)</p>

            <div className="termsDescription">
            
            <p>Our Service does not respond to Do Not Track signals.</p>
            <p>However, some third party websites do keep track of Your browsing activities. If You are visiting such websites, You can set Your preferences in Your web browser to inform websites that You do not want to be tracked. You can enable or disable DNT by visiting the preferences or settings page of Your web browser.</p>
            </div>

            </div>

            <div>

            <p className="termsTitle boldWhiteText">Children's Privacy</p>

            <div className="termsDescription">
            <p>The Service may contain content appropriate for children under the age of 13. As a parent, you should know that through the Service children under the age of 13 may participate in activities that involve the collection or use of personal information. We use reasonable efforts to ensure that before we collect any personal information from a child, the child's parent receives notice of and consents to our personal information practices.</p>
            <p>We also may limit how We collect, use, and store some of the information of Users between 13 and 18 years old. In some cases, this means We will be unable to provide certain functionality of the Service to these Users. If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
            <p>We may ask a User to verify its date of birth before collecting any personal information from them. If the User is under the age of 13, the Service will be either blocked or redirected to a parental consent process.</p>

            </div>
            </div>

            <div>

            <p className="termsTitle boldWhiteText">Information Collected from Children Under the Age of 13</p>

            <div className="termsDescription">
            <p>The Company may collect and store persistent identifiers such as cookies or IP addresses from Children without parental consent for the purpose of supporting the internal operation of the Service.</p>
            <p>We may collect and store other personal information about children if this information is submitted by a child with prior parent consent or by the parent or guardian of the child.</p>
            <div>
            The Company may collect and store the following types of personal information about a child when submitted by a child with prior parental consent or by the parent or guardian of the child:
            <p>• First and/or last name</p>
            <p>• Date of birth</p>
            <p>• Gender</p>
            <p>• Grade level</p>
            <p>• Email address</p>
            <p>• Telephone number</p>
            <p>• Parent's or guardian's name</p>
            <p>• Parent's or guardian's email address</p>


            </div>
            <div>
            For further details on the information We might collect, You can refer to the "Types of Data Collected" section of this Privacy Policy. We follow our standard Privacy Policy for the disclosure of personal information collected from and about children.
            </div>
            </div>
            </div>


            <div>

            <p className="termsTitle boldWhiteText">Parental Access</p>
            <div className="termsDescription">
            A parent who has already given the Company permission to collect and use his child personal information can, at any time:
            <p>• Review, correct or delete the child's personal information</p>
            <p>• Discontinue further collection or use of the child's personal information</p>
            
            </div>
            <div className="termsDescription">To make such a request, You can write to Us using the contact information provided in this Privacy Policy.</div>

            </div>

            <div>

            <p className="termsTitle boldWhiteText">Your California Privacy Rights (California Business and Professions Code Section 22581)</p>
            <div className="termsDescription">
          <p>California Business and Professions Code section 22581 allow California residents under the age of 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly posted.</p>
          <p>To request removal of such data, and if you are a California resident, You can contact Us using the contact information provided below, and include the email address associated with Your account.</p>
          <p>Be aware that Your request does not guarantee complete or comprehensive removal of content or information posted online and that the law may not permit or require removal in certain circumstances.</p>

            </div>
            </div>

            <div>

            <p className="termsTitle boldWhiteText">Links to Other Websites</p>
            <div className="termsDescription">
                <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
                <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
            </div>
            </div>

            <div>

            <p className="termsTitle boldWhiteText">Changes to this Privacy Policy</p>
            <div className="termsDescription">
              <p>We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
              <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</p>
              <p> You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page. </p>
              </div>
            </div>

            
            <div>

            <p className="termsTitle boldWhiteText">Contact Us</p>

            <div className="termsDescription">
            If you have any questions about this Privacy Policy, You can contact us:

            <p>• By email: launch@radiologex.com</p>
            <p>• By phone number: (800) 640-2198</p>
            
            </div>
            </div>



          </div>
        </div>
    );
  }
}
export default withRouter (PrivacyPolicy);
