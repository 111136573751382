import { LOGOUT } from './Authreducer';

let initialState = {
    messenger:[],
    messengerRawList: [], // it will be having messenger raw data , only 3-4 fields available
    messengerList: []
};

export const UPDATE_MESSENGER = 'UPDATE_MESSENGER';
export const UPDATE_MESSENGER_RAW_LIST = 'UPDATE_MESSENGER_RAW_LIST';
export const ADD_CONVERSATION = 'ADD_CONVERSATION';
export const DELETE_CONVERSATION = 'DELETE_CONVERSATION';


export default (state=initialState,action) => {
    switch (action.type) {
        case UPDATE_MESSENGER:
            return {...state, messenger:action.payload, deletechat:false};
        case UPDATE_MESSENGER_RAW_LIST:
            return {...state, messengerRawList:action.payload, deletechat:false};
        case DELETE_CONVERSATION:
            return {...state, deletechat:action.payload};
        case ADD_CONVERSATION:
            return {
                ...state,
                messenger: [...state.messenger, action.payload ],
                deletechat:false
            };
        case LOGOUT:
            return {...initialState};
        default:
            return state;
    }
};