import baseService from './Baseservice';

export const getServiceProfileService = (request) => {
    return baseService.post('/serviceOnDemandGetServiceProfile', request);
};


export const serviceOnDemandSearchUserRequests = (request) => {
    return baseService.post('/serviceOnDemandSearchUserRequests', request);
};
export const serviceOnDemandSearchProviderRequests = (request) => {
    return baseService.post('/serviceOnDemandSearchProviderRequests', request);
};
export const searchServiceProviders = (request) => {
    return baseService.post('/searchServiceProviders', request);
};
export const serviceOnDemandCreateProfile = (request) => {
    return baseService.post('/serviceOnDemandCreateProfile', request);
};
export const serviceOnDemandAddServiceOffered = (request) => {
    return baseService.post('/serviceOnDemandAddServiceOffered', request);
};
export const serviceOnDemandCreateRequest = (request) => {
    return baseService.post('/serviceOnDemandCreateRequest', request);
};
export const serviceOnDemandCheckServiceProfileExists = (request) => {
    return baseService.post('/serviceOnDemandCheckServiceProfileExists', request);
};
export const serviceOnDemandUploadErrorLog = (request) => {
    return baseService.post('/serviceOnDemandUploadErrorLog', request);
};
export const serviceOnDemandAcceptRequest = (request) => {
    return baseService.post('/serviceOnDemandAcceptRequest', request);
};
export const serviceOnDemandRejectRequest = (request) => {
    return baseService.post('/serviceOnDemandRejectRequest', request);
};
export const serviceOnDemandAwardRequest = (request) => {
    return baseService.post('/serviceOnDemandAwardRequest', request);
};

