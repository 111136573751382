const EMAIL_REQUIRED = 'E-mail is required*';
const EMAIL_FORMAT_REQUIRED = 'Enter valid email format *';
const EMAIL_NOT_FOUND_ERROR = 'Email not found';



const PROFILE_PHONENUMBER_OTP_REQUIRED= 'Verification otp is required*';
const PASSWORD_REQUIRED = 'Password is required*';
const CONFIRM_PASSWORD = 'Invalid Confirm password';
const FORMAT_PASSWORD =
  'Password must contain a minimum of 8 characters to include at least one number, one special character and one capitalized letter.';


const PROFILE_DOB_REQUIRED = 'Date of birth is required *';
const PROFILE_GENDER_REQUIRED = 'Gender is required *';
const PROFILE_PHONENUMBER_REQUIRED = 'Phone number is required *';
// const PROFILE_PHONENUMBER_FORMAT_REQUIRED = 'Please enter 10 digit phone number *';
const PROFILE_PHONENUMBER_FORMAT_REQUIRED = 'Please enter valid phone number *';

const PROFILE_PHONE_COUNTRYCODE_REQUIRED = 'Country code is required *';









const PROFILE_TITLE_REQUIRED = 'Title is required *';
const PROFILE_FIRSTNAME_REQUIRED = 'First name is required *';
const PROFILE_LASTNAME_REQUIRED = 'Last name is required *';
const PROFILE_PICTURE_REQUIRED = 'Profile Picture is required *';

const PROFILE_DISPLAYNAME_REQUIRED = 'Display Name is required *';
const PROFILE_PROFESSIONAL_TITLE_REQUIRED = 'Professional Title is required *';
const PROFILE_LOCATION_REQUIRED = 'Location is required *';
const PROFILE_TAGLINE_REQUIRED = 'Tagline is required *';



const PROFILE_SEARCHHANDLE_REQUIRED = 'Search Handle is required *';
const PROFILE_ABOUTME_REQUIRED = 'About Me is required *';
const MAX_100_CHARACTER_REQUIRED = 'Maximum limit 100 characters';

const EDUCATION_UNIVERSITY_REQUIRED = 'University name is required *';
const EDUCATION_YEAR_REQUIRED = 'Completion Year is required *';
const EDUCATION_DEGREE_REQUIRED = 'Degree type is required *';
const EDUCATION_COURSE_REQUIRED = 'Major courses is required *';

const PROFESSIONAL_FIRMNAME_REQUIRED = 'Name of Firm is required *';
const PROFESSIONAL_EXPERIENCETYPE_REQUIRED = 'Experience type is required *';
const PROFESSIONAL_POSITION_REQUIRED = 'Position is required *';
const PROFESSIONAL_STARTDATE_REQUIRED = 'Start Date is required *';
const PROFESSIONAL_ENDDATE_REQUIRED = 'End Date is required *';

const CREATE_GROUP_NAME_REQUIRED = 'Group name is required *';
const CREATE_GROUP_IMAGE_REQUIRED = 'Group image is required *';





export {
  EMAIL_REQUIRED,
  EMAIL_FORMAT_REQUIRED,
  PASSWORD_REQUIRED,
  PROFILE_TITLE_REQUIRED,
  PROFILE_FIRSTNAME_REQUIRED,
  PROFILE_LASTNAME_REQUIRED,
  PROFILE_PICTURE_REQUIRED,
  PROFILE_DISPLAYNAME_REQUIRED,
  PROFILE_PROFESSIONAL_TITLE_REQUIRED,
  PROFILE_LOCATION_REQUIRED,
  PROFILE_TAGLINE_REQUIRED,
  PROFILE_SEARCHHANDLE_REQUIRED,
  PROFILE_ABOUTME_REQUIRED,
  EDUCATION_UNIVERSITY_REQUIRED,
  EDUCATION_YEAR_REQUIRED,
  EDUCATION_DEGREE_REQUIRED,
  EDUCATION_COURSE_REQUIRED,
  MAX_100_CHARACTER_REQUIRED,
  PROFESSIONAL_FIRMNAME_REQUIRED,
  PROFESSIONAL_EXPERIENCETYPE_REQUIRED,
  PROFESSIONAL_POSITION_REQUIRED,
  PROFESSIONAL_STARTDATE_REQUIRED,
  PROFESSIONAL_ENDDATE_REQUIRED,
  CONFIRM_PASSWORD,
  FORMAT_PASSWORD,
  EMAIL_NOT_FOUND_ERROR,
  CREATE_GROUP_NAME_REQUIRED,
  CREATE_GROUP_IMAGE_REQUIRED,
  PROFILE_DOB_REQUIRED,
  PROFILE_GENDER_REQUIRED,
  PROFILE_PHONENUMBER_REQUIRED,
  PROFILE_PHONENUMBER_FORMAT_REQUIRED,
  PROFILE_PHONENUMBER_OTP_REQUIRED,
  PROFILE_PHONE_COUNTRYCODE_REQUIRED
};
