let initialState = {
  messages: [],
  messagesFailed: [],

};

export const GET_MESSAGES = "GET_MESSAGES";
export const GET_MESSAGES_LOAD_IN_PROGRESS = "GET_MESSAGES_LOAD_IN_PROGRESS";
export const GET_MESSAGES_FAILED = 'GET_MESSAGES_FAILED';
export const TOGGLE_SELECTED = "TOGGLE_SELECTED";
export const MESSAGE_LOAD_IN_PROGRESS = "MESSAGE_LOAD_IN_PROGRESS";
export const MESSAGE_LOAD_SUCCESS = "MESSAGE_LOAD_SUCCESS";
export const MESSAGE_LOAD_FAIL = "MESSAGE_LOAD_FAIL";
export const EMPTY_MESSAGES = "EMPTY_MESSAGES";
export const SET_PAGE_TOKENS = "SET_PAGE_TOKENS";
export const ADD_INITIAL_PAGE_TOKEN = "ADD_INITIAL_PAGE_TOKEN";
export const CLEAR_PAGE_TOKENS = "CLEAR_PAGE_TOKENS";
export const MODIFY_MESSAGES_SUCCESS = "MODIFY_MESSAGES_SUCCESS";
export const MODIFY_MESSAGES_FAILED = "MODIFY_MESSAGES_FAILED";
export const SET_SEARCH_QUERY = "SET_SEARCH_QUERY";





export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MESSAGES:
      return {...state, messages: action.payload};
    case EMPTY_MESSAGES:
      return {...state, messages: []};
    case GET_MESSAGES_FAILED:
      return {...state, messagesFailed: action.payload};
    case MESSAGE_LOAD_SUCCESS:
      return {...state, messagesDetail: action.payload};
    case MESSAGE_LOAD_FAIL:
      return {...state, messagesFailed: action.payload};
      
      
    default:
      return state;
  }
};
