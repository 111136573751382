// import React from 'react'
import React, { useRef, createRef, useState } from "react";
import './selectDropdown.css'
// import DropdownDownArrow from '../../../../images/inputIcons/dropdownDownArrow.svg'
import downWhiteArrow from '../../../images/icons/downwhiteArrow.png';
import downBlackArrow from '../../../images/icons/downBlackArrow.png'



class SelectInput extends React.Component {
  state = {
    items: this.props.items || [],
    showItems: false,
    selectedItem: this.props.value ? this.props.value : ''
  }
  constructor (props) {
    super(props)
    this.wrapperRef = React.createRef()
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }


  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }
  handleClickOutside (event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        showItems: false
      })
    }
  }

  dropDown = () => {
    if(!this.props.readOnly){
      this.setState(prevState => ({
        showItems: !prevState.showItems
      }))
    }

  }

  selectItem = item => {
    this.setState(
      {
        selectedItem: item.value,
        showItems: false
      },
      () => {
        this.props.onChange(item.value)
      }
    )
  }

  render () {
    const { className, dropDownContainerClass,placeholder ,labelClassName,labelName,value,isBlackArrow,readOnly} = this.props;


  
    return (
      <div ref={this.wrapperRef} className={'select-box--box '} id="selectInputBox1">
        {labelName && <div className={labelClassName} id="selectInputBox2">{labelName}</div>}
        <div className={'select-box--container '} id="selectInputBox3">
          <div
            className={`${'dropdownBg'}  ${className}  ${this.state.showItems &&
              ' dropdownBoxBorderBottomHide openDropdownActiveBg '}`}
            onClick={this.dropDown}
            id="selectInputBox4"
          >
            <div id="selectInputBox5" className='select-box--selected-item' style={{opacity :!this.state.selectedItem && 0.6}}>
              {/* {this.state.showItems? 'Select': (value? value:placeholder)} */}

              {this.state.showItems? 'Select': (value? 
              
              (this.props.items.findIndex(element => element.value === value) > -1 &&  this.props.items[this.props.items.findIndex(element => element.value === value)].label)
            :placeholder)}



            </div>
            {!readOnly &&<div id="selectInputBox6" className={`${this.state.showItems ? 'selectdropdownArrow' : ''}`}>
              
              <img
                className='dropdownArrowImg'
                src={isBlackArrow?downBlackArrow:downWhiteArrow}
                alt='DropdownDownArrow'
                id="selectInputBox7"
              />
            </div>}
          </div>

          <div
            style={{ display: this.state.showItems ? 'block' : 'none' }}
            className={'select-box--items ' + dropDownContainerClass}
            id="selectInputBox8"
          >
            {this.props.items.map((item,i) => (
              <div
                key={item.value}
                onClick={() => this.selectItem(item)}
                className={
                  value && (value === item.value ? ' selectedDropdownItem ' : '')
                }
                id={`selectInputBoxitemsList${i}`}
              >
                {item.label}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

export default SelectInput
