
import baseService from './Baseservice';


export const getProductService = (payload) => {
    return baseService.post('getMarketplaceProductListing', payload);
};
export const getMarketplaceProductSearchService = (payload) => {
    // return baseService.post('getMarketplaceProductListing', payload);
    return baseService.post('marketplaceSearchProducts', payload);
};

export const placeOrderService = (payload) => {
    return baseService.post('marketplaceOrderCreate', payload);
};

export const placeOrderListService = (payload) => {
    return baseService.post('getMarketplaceOrders', payload);
};

export const marketplaceOrderCancelStatusService = (payload) => {
    return baseService.post('marketplaceOrderCancelStatus', payload);
};

export const getUserMarketplaceProductList = (payload) => {
    return baseService.post('getUserMarketplaceProductList', payload);
};


export const getUserMarketplaceOrders = (payload) => {
    return baseService.post('getUserMarketplaceOrders', payload);
};

export const createMarketplaceProduct = (payload) => {
    return baseService.post('createMarketplaceProduct', payload);
};

export const modifyMarketplaceProduct = (payload) => {
    return baseService.post('modifyMarketplaceProduct', payload);
};



export const marketplaceOrderPaidStatus = (payload) => {
    return baseService.post('marketplaceOrderPaidStatus', payload);
};

export const marketplaceOrderSellerConfirmStatus = (payload) => {
    return baseService.post('marketplaceOrderSellerConfirmStatus', payload);
};

export const listMarketplaceProduct = (payload) => {
    return baseService.post('listMarketplaceProduct', payload);
};

export const delistMarketplaceProduct = (payload) => {
    return baseService.post('delistMarketplaceProduct', payload);
};

export const marketplaceUploadProductImage = (payload) => {
    return baseService.post('marketplaceUploadProductImage', payload);
};

export const marketplaceModifyProductImageList = (payload) => {
    return baseService.post('marketplaceModifyProductImageList', payload);
};

export const marketplaceGetLatestProductListService = (payload) => {
    return baseService.post('marketplaceGetLatestProductList', payload);
};


export const marketplaceAddRecentlyViewedProductService = (payload) => {
    return baseService.post('marketplaceAddRecentlyViewedProduct', payload);
};


export const marketplaceGetRecentlyViewedProductListService = (payload) => {
    return baseService.post('marketplaceGetRecentlyViewedProductList', payload);
};


export const marketplaceOrderShippedStatusService = (payload) => {
    return baseService.post('marketplaceOrderShippedStatus', payload);
};



export const marketplaceOrderDeliveredStatusService = (payload) => {
    return baseService.post('marketplaceOrderDeliveredStatus', payload);
};

export const marketplaceBuyerReviewingSellerService = (payload) => {
    return baseService.post('marketplaceBuyerReviewingSeller', payload);
};

export const marketplaceSellerReviewingBuyerService = (payload) => {
    return baseService.post('marketplaceSellerReviewingBuyer', payload);
};

export const marketplaceGetUserReviewsService = (payload) => {
    return baseService.post('marketplaceGetUserReviews', payload);
};
export const delistMarketplaceProductService = (payload) => {
    return baseService.post('delistMarketplaceProduct', payload);
};
export const deleteMarketplaceProductService = (payload) => {
    return baseService.post('deleteMarketplaceProduct', payload);
};

export const marketplaceOrderRefundedStatusService = (payload) => {
    return baseService.post('marketplaceOrderRefundedStatus', payload);
};

export const marketplaceOrderVerifiedPaidStatusService = (payload) => {
    return baseService.post('marketplaceOrderVerifiedPaidStatus', payload);
};

export const marketplaceUploadProductFile = (payload) => {
    return baseService.post('marketplaceUploadProductFile', payload);
};

export const marketplaceGetUploadProductFileHistory = (payload) => {
    return baseService.post('marketplaceGetUploadProductFileHistory', payload);
};

export const marketplaceOrderDisputeStatusService = (payload) => {
    return baseService.post('marketplaceOrderDisputeStatus', payload);
};

export const marketplaceOrderCloseDisputeStatusService = (payload) => {
    return baseService.post('marketplaceOrderCloseDisputeStatus', payload);
};
export const marketplaceOrderRMAStatusService = (payload) => {
    return baseService.post('marketplaceOrderRMAStatus', payload);
};
export const marketplaceOrderReturnedStatusService = (payload) => {
    return baseService.post('marketplaceOrderReturnedStatus', payload);
};

export const marketplaceOrderCompleteRefundStatusService = (payload) => {
    return baseService.post('marketplaceOrderCompleteRefundStatus', payload);
};
export const marketplaceGetCountryOfOriginListService = (payload) => {
    return baseService.post('marketplaceGetCountryOfOriginList', payload);
};











