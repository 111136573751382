import React, {Component} from 'react';
import './calendar.css';
import moment from 'moment';

import smallDownWhite from '../../../images/icons/vault/whiteDown.png';

const weekdayshort = moment.weekdaysShort ();

// function App() {
class Calendar extends Component {
  constructor (props) {
    super (props);
    this.state = {
      dateContext: (this.props && this.props.selectedDate)?moment(this.props.selectedDate): moment (),
      today: moment (),
      showMonthPopup: false,
      showYearPopup: false,
      selectedDay: null
    };
    this.width = props.width || '250px';
    this.style = props.style || {};
    this.style.width = this.width; // add this
  }
  weekdays = moment.weekdays (); //["Sunday", "Monday", "Tuesday", "Wednessday", "Thursday", "Friday", "Saturday"]
  weekdaysShort = moment.weekdaysShort (); // ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  months = moment.months ();

  year = () => {
    return this.state.dateContext.format ('Y');
  };
  month = () => {
    return this.state.dateContext.format ('MMMM');
  };
  daysInMonth = () => {
    return this.state.dateContext.daysInMonth ();
  };
  currentDate = () => {
    // console.log ('currentDate: ', this.state.dateContext.get ('date'));
    return this.state.dateContext.get ('date');
  };
  currentDay = () => {
    return this.state.dateContext.format ('D');
  };

  firstDayOfMonth = () => {
    let dateContext = this.state.dateContext;
    let firstDay = moment (dateContext).startOf ('month').format ('d'); // Day of week 0...1..5...6
    return firstDay;
  };

  setMonth = month => {
    let monthNo = this.months.indexOf (month);
    let dateContext = Object.assign ({}, this.state.dateContext);
    dateContext = moment (dateContext).set ('month', monthNo);
    this.setState ({
      dateContext: dateContext,
    });
  };

  nextMonth = () => {
    let dateContext = Object.assign ({}, this.state.dateContext);
    dateContext = moment (dateContext).add (1, 'month');
    this.setState ({
      dateContext: dateContext,
    });
    this.props.onNextMonth && this.props.onNextMonth ();
  };

  prevMonth = () => {
    let dateContext = Object.assign ({}, this.state.dateContext);
    dateContext = moment (dateContext).subtract (1, 'month');
    this.setState ({
      dateContext: dateContext,
    });
    this.props.onPrevMonth && this.props.onPrevMonth ();
  };

  PrevYear = () => {
    if(Number(this.state.dateContext.format ('Y')) > Number(1900)){
      let dateContext = Object.assign ({}, this.state.dateContext);
      dateContext = moment (dateContext).subtract (1, 'year');
      this.setState ({
        dateContext: dateContext,
      });
      this.props.onPrevYear && this.props.onPrevYear ();
    }
  };
  NextYear = () => {

    let currentYear= moment().format ('Y');
    if(Number(this.state.dateContext.format ('Y')) < Number(currentYear)){
        let dateContext = Object.assign ({}, this.state.dateContext);
          dateContext = moment (dateContext).add (1, 'year');
          this.setState ({
            dateContext: dateContext,
          });
          this.props.onNextYear && this.props.onNextYear ();
      }


    
  };
  

  onSelectChange = (e, data) => {
    this.setMonth (data);
    this.props.onMonthChange && this.props.onMonthChange ();
  };
  SelectList () {
    // console.log(props);
    // this.months
    
    let popup =  this.months.map (data => {
      return (
        <div key={data}>
          <div
            onClick={e => {
              this.onSelectChange (e, data);
            }}
          >
            {data}
          </div>
        </div>
      );
    });

    return (
      <div className="month-popup">
        {popup}
      </div>
    );
  };

  onChangeMonth = (e, month) => {
    this.setState ({
      showMonthPopup: !this.state.showMonthPopup,
      showYearNav: false
    });
  };

  MonthNav = () => {
    return (
      <span
        style={{marginLeft:10,paddingTop:0}}
        className="label-month"
        // onClick={e => {
        //   this.onChangeMonth (e, this.month ());
        // }}
      >
        {this.month ()}
        {this.state.showMonthPopup && this.SelectList()}
      </span>
    );
  };

  showYearEditor = () => {
    this.setState ({
      showYearNav: !this.state.showYearNav,
      showMonthPopup:false
    });
  };

  setYear = year => {
    let dateContext = Object.assign ({}, this.state.dateContext);
    dateContext = moment (dateContext).set ('year', year);
    this.setState ({
      dateContext: dateContext,
    });
  };
  onYearChange = e => {
    this.setYear (e.target.value);
    this.props.onYearChange && this.props.onYearChange (e, e.target.value);
  };
  onSelectYearChange = e => {
    this.setYear (e);
    this.props.onSelectYearChange && this.props.onSelectYearChange (e);
  };

  onKeyUpYear = e => {
    if (e.which === 13 || e.which === 27) {
      this.setYear (e.target.value);
      this.setState ({
        showYearNav: false,
      });
    }
  };

  YearNav = () => {
    

    return <div>
    
    {this.state.showYearNav && this.getYearsList()} 
    <span className="label-year"

      onClick={() => {
       
        this.showYearEditor ();
      }}
          // onDoubleClick={e => {
          //   this.showYearEditor ();
          // }}
        >
          {this.year() }
        </span>
        </div>

    // return this.state.showYearNav
    //   ? <input
    //       defaultValue={this.year ()}
    //       className="editor-year"
    //       ref={yearInput => {
    //         this.yearInput = yearInput;
    //       }}
    //       onKeyUp={e => this.onKeyUpYear (e)}
    //       onChange={e => this.onYearChange (e)}
    //       type="number"
    //       placeholder="year"
    //     />:<span
    //       className="label-year"
    //       onDoubleClick={e => {
    //         this.showYearEditor ();
    //       }}
    //     >
    //       {this.year ()}
    //     </span>;
  };

  onDayClick = (e, day) => {
    this.setState (
      {
        selectedDay: day,
      },
      () => {
        console.log ('SELECTED DAY: ', this.state.selectedDay);
      }
    );

    this.props.onDayClick && this.props.onDayClick (e, day);
  };

  onSubmit(){
      // let updatedDate= new Date(`${this.state.selectedDay ? this.state.selectedDay:this.currentDay ()}/${this.month()}/${this.year()}`);

      let index =  this.months.findIndex (
          items =>  items? items === this.month ():''
        );
    // console.log(index);
      let updatedDate= new Date(`${index+1}/${this.state.selectedDay ? this.state.selectedDay:this.currentDay()}/${this.year()}`)
      this.props.onSubmit(updatedDate);
  }
  onCancel(){
    console.log("closePicker")
    this.props.onSubmit('');
  }

  getYearsList(){
    let currentYear= moment().format ('Y');
    let allYear=[];
    var i=0;
    for (i = 1900; i <  Number(currentYear) + Number(1); i++) {
      allYear.push(i)
    }
    // console.log(allYear);

    let popup = allYear.sort((a, b) => a < b ? 1:-1).map (data => {
      return (
        <div key={data}>
          <div
            onClick={()=> {
              this.onSelectYearChange (data);
              this.setState({
                showYearNav:false
              })
            }}
          >
            {data}
          </div>
        </div>
      );
    });

    return (
      <div className="year-popup">
        {popup}
      </div>
    );
  }
  render () {
    const {selectedDate ,maxDate ,minDate} = this.props;
    // console.log(maxDate);
    // console.log(selectedDate);
    // Map the weekdays i.e Sun, Mon, Tue etc as <td>
    let weekdays = this.weekdaysShort.map (day => {
      return <div key={day} className="week-day">{day[0]}</div>;
    });

    let blanks = [];
    for (let i = 0; i < this.firstDayOfMonth (); i++) {
      blanks.push (
        <div key={i * 80} className="emptySlot">
          {''}
        </div>
      );
    }

    // console.log ('blanks: ', blanks);

    let daysInMonth = [];
    // console.log(this.daysInMonth());
    for (let d = 1; d <= this.daysInMonth (); d++) {
      // let className = d == this.currentDay () ? 'day current-day' : 'day';
      let className = d == this.currentDay () ? 'day ' : 'day';
      // console.log(d);
      // console.log(this.currentDay ());
      // console.log(new Date(this.state.dateContext).getTime())
      // console.log(new Date(maxDate).getTime());

      let disableDate= '';
      if(maxDate){
      if(Number(this.year()) <=maxDate.getFullYear()){
         let monthNo = this.months.indexOf (this.month());
        if(monthNo <= maxDate.getMonth() ){
          if(monthNo === maxDate.getMonth() &&  Number(this.year())===maxDate.getFullYear()){
            if((moment(maxDate).format ('D')<d)){
              disableDate=' disableOpacity ';
            }
          }
        }else{
          if( Number(this.year())===maxDate.getFullYear()){
            disableDate=' disableOpacity ';
          }
        }
       
      }
      else{
        disableDate=' disableOpacity ';
      }
    }
    if(minDate){
      if(Number(this.year()) >= minDate.getFullYear()){
         let monthNo = this.months.indexOf (this.month());
        if(monthNo >= minDate.getMonth() ){
          if(monthNo === minDate.getMonth() &&  Number(this.year())===minDate.getFullYear()){
            if((moment(minDate).format ('D') > d)){
              disableDate=' disableOpacity ';
            }
          }
        }else{
          if( Number(this.year())=== minDate.getFullYear()){
            disableDate=' disableOpacity ';
          }
        }
       
      }
      else{
        disableDate=' disableOpacity ';
      }
    }


      let selectedClass = d == (this.state.selectedDay ?this.state.selectedDay:  this.currentDay ()) ? ' selected-day ' : '';
      // let disableDate =  (new Date(maxDate) < new Date(this.state.dateContext)) ? (Number(moment(maxDate).format ('D'))<d ? ' disableOpacity ' : '') : ' disableOpacity ';
      
      daysInMonth.push (
        <div key={d} className={className + selectedClass + disableDate}>
          <span
            onClick={e => {
              if(!disableDate){
                this.onDayClick (e, d);

              }
            }}
          >
            {d}
          </span>
        </div>
      );
    }

    // console.log ('days: ', daysInMonth);

    var totalSlots = [...blanks, ...daysInMonth];
    let rows = [];
    let cells = [];

    totalSlots.forEach ((row, i) => {
      if (i % 7 !== 0) {
        cells.push (row);
      } else {
        let insertRow = cells.slice ();
        rows.push (insertRow);
        cells = [];
        cells.push (row);
      }
      if (i === totalSlots.length - 1) {
        let insertRow = cells.slice ();
        rows.push (insertRow);
      }
    });

    let trElems = rows.map ((d, i) => {
      return (
        <div className="inlineDayBoxes" key={i * 100}>
          {d}
        </div>
      );
    });

    let indexMonth =  this.months.findIndex (
      items =>  items? items === this.month ():''
    );
    let checkDisableSubmit = new Date(`${indexMonth+1}/${this.state.selectedDay ? this.state.selectedDay:this.currentDay()}/${this.year()}`)

    return (
      <div className="calendar-container" style={this.style}>
        <div className="calendar">
     
            <div  style={{height:40}} className="calendar-header">
              <div >
                
              {this.month ()}
              {' '}
              {this.state.selectedDay?this.state.selectedDay:this.currentDay()},
                {/*<this.MonthNav />*/}
                {' '}
                {this.year() }
                {/*<this.YearNav />*/}
              </div>
             {/*} <div colSpan="2" className="nav-month">
                <i
                  className="prev fa fa-fw fa-chevron-left"
                  onClick={e => {
                    this.prevMonth ();
                  }}
                />
                <i
                  className="prev fa fa-fw fa-chevron-right"
                  onClick={e => {
                    this.nextMonth ();
                  }}
                />

                </div>*/}
            </div>

            <div className="selectPickerValues">
               <div style={{position:'relative',display:'inline-flex',alignItems:'center'}} onClick={e => {
                this.onChangeMonth (e, this.month ());
              }}>
                
              {this.MonthNav()}  &nbsp; {' '} {this.year()} <button className="arrowButtons" > 
                <img style={{width:15,height:10}} src={smallDownWhite} alt="smallDownWhite" />
              </button>
              {' '}
              </div>
              <div style={{display:'inline-flex',alignItems:'center',position:'relative'}}>
            
                {/*<this.MonthNav />*/}
                {' '}<button className="arrowButtons" onClick={()=>this.PrevYear()}>
                <img style={{width:15,height:10,transform:'rotate(90deg)'}} src={smallDownWhite} alt="smallDownWhite" />
                </button>
                {this.YearNav()}
                
                <button className="arrowButtons" onClick={()=>this.NextYear()}> 
                <img style={{width:15,height:10,transform:'rotate(-90deg)'}} src={smallDownWhite} alt="smallDownWhite" />
                 </button> 
                {/*<this.YearNav />*/}
              </div>
            </div>
            <div className="inlineDayBoxes">
              {weekdays}
              </div>
              <div >
              {trElems}
            </div>
      
            <div style={{textAlign:'right'}}>
            <button className="cancelDateButton" onClick={()=>this.onCancel()}>Cancel</button>
            <button  disabled={minDate ? (checkDisableSubmit > minDate ? false:  true):false} className="submitDateButton" onClick={()=>{
              this.onSubmit()

            }}>Ok</button>
            
            </div>
    
        </div>
       
      </div>
    );
  }
}

export default Calendar;
