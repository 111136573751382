import { getMessage } from '../constants/localMessage/errorMessage'
import store from '../store'

import {
  loginService,
  registerService,
  modifyPersonalInfoService,
  modifyEducationDetailsService,
  getEducationDetailsService,
  deleteEducationDetailsService,
  getProfessionalDetailsService,
  deleteProfessionalDetailsService,
  modifyProfessionalInfoService,
  getFindBusinessService,
  registerDeviceTokenService,
  getUserDashboard,
  completeUserKYC,
  passwordResetGenerateOTP,
  passwordResetVerifyOTP,
  removeUserFromBusinessService,
  passwordResetChangePassword,
  resendEmailVerify,
  changeUserPasswordService,
  userSettingsModifySearchSettingsService,
  userSettingsModifyNotificationSettingsService,
  checkTrusonaEnabledService,
  sendPhoneNumberVerificationService,
  verifyPhoneNumberOTPService,
  rdeeEmailVerificationService,
  userSettingsToggleTrusonaAuthService,
  onfidoResumeChecks,
  onfidoGetSDKTokenForApplicant,
  setSeenWelcomeScreenService,
  uploadBusinessProfilePictureService,
  businessAccountsGetAccountsService,
  businessAccountsSetActiveAccountService,
  businessAccountsGetGroupsService,
  UpdatebusinessAccountsManageGroupsService,
  businessAccountsGetPendingInvitesService,
  sendBusinessInviteService,
  businessAccountsAcceptPendingInviteService,
  businessAccountsRejectPendingInviteService
} from '../services/Authservice'
import {
  LOGIN,
  LOGOUT,
  GETEDUCATIONDETAIL,
  REGISTER,
  GETPROFESSIONALDETAIL,
  FIND_BUSINESS_DETAIL,
  ADD_NEW_BUSINESS,
  UPDATE_AUTH_TOKEN,
  GET_DASHBOARD_PROFILE,
  SEND_RESET_PASSWORD,
  SEND_CONFIRM_PASSWORD_OTP,
  SEND_RESETPASSWORD_OTP,
  CHANGE_PASSWORD_SUCCESS,
  CHECK_EMAIL,
  ADD_ERROR_CODE,
  VERIFY_OTP,
  VERIFY_EMAIL,
  DELETE_EDUCATION_PROFILE,
  DELETE_PROFESSIONAL_PROFILE,
  CHECK_ENABLE_FEATURE,
  USER_KYC_SUCCESS,
  BUSINESS_PROFILE_CHECK,
  APPLICANT_TOKEN,
  UPDATE_KYC_DASHBOARD_PROFILE,
  USER_BUSINESS_LIST,
  UPDATE_NEW_BUSINESS,
  BUSINESS_ACCOUNT_GROUPS,
  UPDATE_BUSINESS_ACCOUNT_GROUPS,
  GET_BUSINESS_PENDING_INVITES,
  BUSINESS_SEND_INVITE,
  UPDATE_BUSINESS_DASHBOARD_PROFILE,
  BUSINESS_ACCEPT_INVITE,
  BUSINESS_REJECT_INVITE,
  SEND_MOBILE_OTP,
  SEND_MOBILE_OTP_ERROR
} from './../reducers/Authreducer'
import { BUSINESS_DETAIL_PROFILE } from '../reducers/ContactsReducer'
import { createNotification } from '../constants/notificationtoast'
import { Loading, CustomLoading } from './../actions/LoadingAction'
import {
  getUserDetail,
  getUserProfileDetail,
  uploadUserProfile
} from './../actions/contacts'
import firebase from '../config/firebase'
import trusonaLoader from '../components/globalLoader/passwordLess.gif'
import { userSharedKey, getRandomInt } from '../utility/encryption'

import _ from 'lodash'

export const checkEmailExist = request => {
  return dispatch => {
    checkTrusonaEnabledService(request)
      .then(userData => {
        console.log('checkTrusonaEnabledService>>>>>>>', userData)
        if (
          userData &&
          userData.data &&
          userData.data &&
          userData.data.result
        ) {
          if (
            userData &&
            userData.data &&
            userData.data &&
            userData.data.result &&
            userData.data.result.responseCode
          ) {
            var str2 = getMessage(userData.data.result.responseCode)
              ? getMessage(userData.data.result.responseCode).trim()
              : ''
            var splitstring = str2.split('+')
            if (splitstring && splitstring.length > 0) {
              splitstring[1] = request.data.emailAddress
            }
            userData.data.result.message = splitstring.join('')

            // userData.data.result.message=getMessage(userData.data.result.responseCode)+ ` ${request.data.emailAddress}`;
          }
          dispatch({ type: CHECK_EMAIL, payload: userData.data.result })
        }

        // if (userData && userData.data && userData.data.result.status === true) {

        //   console.log (userData.data);
        //   dispatch ({type: CHECK_EMAIL, payload: userData.data});
        //   // createNotification ('success', userData.data.result.message);
        // } else {
        //   dispatch ({type: CHECK_EMAIL, payload: userData.data});
        //   if (userData && userData.data && userData.data.result) {
        //     createNotification ('error', userData.data.result.message);
        //   }
        // }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const realTimeLogin = loginData => {
  return dispatch => {
    // console.log(loginData)
    if (loginData.uid) {
      let userID = loginData.uid
      const database = firebase.database()
      database.ref(`feature_screen_map/${userID}`).once('value', snapShot => {
        if (snapShot.val() && snapShot.val().featureToScreenMap) {
          dispatch({
            type: CHECK_ENABLE_FEATURE,
            payload: snapShot.val().featureToScreenMap
          })
        } else {
          dispatch({ type: CHECK_ENABLE_FEATURE, payload: [] })
        }
      })
      database.ref(`user_dashboard/${userID}`).once('value', async snapShot => {
        if (snapShot.exists()) {
          if (snapShot.val() && snapShot.val()) {
            let newResponse = snapShot.val()
            // console.log(newResponse)
            if (newResponse) {
              if (newResponse && newResponse.personalDetails) {
                localStorage.setItem(
                  'loginuser',
                  JSON.stringify(newResponse.personalDetails)
                )
                if (newResponse.personalDetails.walletAddress) {
                  localStorage.setItem(
                    'address',
                    newResponse.personalDetails.walletAddress
                  )
                } else {
                  localStorage.setItem('address', '')
                }
              }

              if (newResponse && newResponse.businessProfile) {
                if (newResponse.businessProfile.docID) {
                  dispatch({
                    type: BUSINESS_PROFILE_CHECK,
                    payload: newResponse.businessProfile
                  })
                }
              }

              dispatch({
                type: GET_DASHBOARD_PROFILE,
                payload: newResponse
              })
              let res = {
                result: {
                  status: true,
                  responseCode: 1164,
                  message: 'Login Successful',
                  Data: {
                    customToken: loginData.Aa,
                    lastLoginLocation: '',
                    uid: userID,
                    emailAddress: loginData.email ? loginData.email : false,
                    currentLoginLocation: {
                      uid: userID,
                      emailAddress: loginData.email ? loginData.email : false,
                      registrationStepsCompleted: newResponse.registrationStepsCompleted
                        ? newResponse.registrationStepsCompleted
                        : 3,
                      confBridgeCode: newResponse.confBridgeCode
                        ? newResponse.confBridgeCode
                        : '',
                      phoneNumber:
                        newResponse &&
                        newResponse.registrationStepsCompleted &&
                        newResponse.registrationStepsCompleted === 1
                          ? newResponse.userphone
                          : newResponse.phoneNumber,
                      countryCode:
                        newResponse &&
                        newResponse.registrationStepsCompleted &&
                        newResponse.registrationStepsCompleted === 1
                          ? newResponse.userphoneCountryCode
                          : newResponse.countryCode,
                      localPhoneNumber:
                        newResponse &&
                        newResponse.registrationStepsCompleted &&
                        newResponse.registrationStepsCompleted === 1
                          ? newResponse.userphone
                          : newResponse.phoneNumber,
                      firstName:
                        newResponse &&
                        newResponse.personalDetails &&
                        newResponse.personalDetails.firstName
                          ? newResponse.personalDetails.firstName
                          : '',
                      lastName:
                        newResponse &&
                        newResponse.personalDetails &&
                        newResponse.personalDetails.lastName
                          ? newResponse.personalDetails.lastName
                          : '',
                      // "associatedWithMultipleBusiness":true,
                      emailVerified: newResponse.emailVerified
                        ? newResponse.emailVerified
                        : ''
                    },
                    emailVerified: newResponse.emailVerified
                      ? newResponse.emailVerified
                      : '',
                    accountStatus: newResponse.accountStatus
                      ? newResponse.accountStatus
                      : '',
                    accountMessage: newResponse.accountMessage
                      ? newResponse.accountMessage
                      : '',
                    registrationStepsCompleted:
                      newResponse && newResponse.registrationStepsCompleted
                        ? newResponse.registrationStepsCompleted
                        : 3,
                    phoneNumber:
                      newResponse && newResponse.registrationStepsCompleted
                        ? newResponse.userphone
                        : newResponse.phoneNumber,
                    countryCode:
                      newResponse && newResponse.registrationStepsCompleted
                        ? newResponse.userphoneCountryCode
                        : newResponse.countryCode
                    // "localPhoneNumber":(newResponse && newResponse.registrationStepsCompleted) ?newResponse.userphone:  null ,
                  }
                }
              }
              let personalDetails = []
              if (newResponse && newResponse.personalDetails) {
                personalDetails.push({
                  personalDetails: newResponse.personalDetails
                })
              }
              res.result.Data.userDetails = personalDetails
              // dispatch ({type: LOGIN, payload: res.data.result});

              if (loginData && loginData.data && loginData.data.useTrusona) {
                firebase
                  .auth()
                  .signInWithCustomToken(loginData.Aa)
                  .then(result => {
                    if (result) {
                      console.log(result.user)
                      if (result.user.Aa) {
                        dispatch(updateFirebaseToken(result.user.Aa))
                        dispatch({ type: LOGIN, payload: res.result })
                      }
                    } else {
                      console.log(
                        'user signed out or still need to sign in',
                        res
                      )
                    }
                  })
                  .catch(e => {
                    console.log('error', e)
                    createNotification('error', e.message)
                  })
              } else {
                dispatch({ type: LOGIN, payload: res.result })
                dispatch(Loading(false))
              }
            }
          }
        } else {
          console.log('node not Exist new user')
          // dispatch (checkLoginUpdates(loginData));
        }
      })
    }
  }
}

export const login = loginData => {
  return dispatch => {
    if (loginData.data && loginData.data.useTrusona) {
      dispatch(CustomLoading({ loaderImage: trusonaLoader, visible: true }))
    } else {
      dispatch(Loading(true))
    }

    loginService(loginData)
      .then(async res => {
        console.log(res.data)
        if (
          res &&
          res.data &&
          res.data.result &&
          res.data.result.status === true
        ) {
          if (res.data) {
            let gerDataRes = res.data.result.Data

            console.log(gerDataRes)

            if (
              gerDataRes.currentLoginLocation &&
              gerDataRes.registrationStepsCompleted === 3
            ) {
              if (gerDataRes.currentLoginLocation.uid) {
                let userID = gerDataRes.currentLoginLocation.uid
                const database = firebase.database()
                database
                  .ref(`feature_screen_map/${userID}`)
                  .once('value', snapShot => {
                    if (snapShot.val() && snapShot.val().featureToScreenMap) {
                      dispatch({
                        type: CHECK_ENABLE_FEATURE,
                        payload: snapShot.val().featureToScreenMap
                      })
                    } else {
                      dispatch({ type: CHECK_ENABLE_FEATURE, payload: [] })
                    }
                  })
                database
                  .ref(`user_dashboard/${userID}`)
                  .once('value', async snapShot => {
                    if (snapShot.exists()) {
                      if (snapShot.val() && snapShot.val()) {
                        let newResponse = snapShot.val()
                        console.log(newResponse)
                        if (newResponse) {
                          if (newResponse && newResponse.personalDetails) {
                            localStorage.setItem(
                              'loginuser',
                              JSON.stringify(newResponse.personalDetails)
                            )
                            if (newResponse.personalDetails.walletAddress) {
                              localStorage.setItem(
                                'address',
                                newResponse.personalDetails.walletAddress
                              )
                            } else {
                              localStorage.setItem('address', '')
                            }
                          }
                          dispatch({
                            type: GET_DASHBOARD_PROFILE,
                            payload: newResponse
                          })
                          let personalDetails = []
                          personalDetails.push({
                            personalDetails: newResponse.personalDetails
                          })
                          res.data.result.Data.userDetails = personalDetails
                          // dispatch ({type: LOGIN, payload: res.data.result});

                          if (loginData.data.useTrusona) {
                            firebase
                              .auth()
                              .signInWithCustomToken(
                                res.data.result.Data.customToken
                              )
                              .then(result => {
                                if (result) {
                                  if (result.user.Aa) {
                                    dispatch(
                                      updateFirebaseToken(result.user.Aa)
                                    )
                                    dispatch({
                                      type: LOGIN,
                                      payload: res.data.result
                                    })
                                  }
                                } else {
                                  console.log(
                                    'user signed out or still need to sign in',
                                    res
                                  )
                                }
                              })
                              .catch(e => {
                                console.log('error', e)
                                createNotification('error', e.message)
                              })
                          } else {
                            dispatch({ type: LOGIN, payload: res.data.result })
                            dispatch(Loading(false))
                          }
                        }
                      }
                    } else {
                      console.log('node not Exist new user')
                      dispatch(checkLoginUpdates(res.data.result))
                    }
                  })
              }
            } else {
              dispatch({ type: LOGIN, payload: res.data.result })
            }
          }
        } else {
          // if(res){
          dispatch(Loading(false))

          if (
            res &&
            res.data &&
            res.data.result &&
            res.data.result.responseCode &&
            (res.data.result.responseCode !== 1146 &&
              res.data.result.responseCode !== 1865)
          ) {
            var str2 = getMessage(res.data.result.responseCode)
              ? getMessage(res.data.result.responseCode)
              : ''
            var splitstring = str2.split('+')
            if (splitstring && splitstring.length > 0) {
              splitstring[1] = ' ' + loginData.data.emailAddress
            }
            res.data.result.message = splitstring.join('')
          }
          if (
            res.data.result &&
            res.data.result.Data &&
            res.data.result.Data.registrationStepsCompleted &&
            res.data.result.Data.registrationStepsCompleted > 2
          ) {
            createNotification('error', res.data.result.message)
          }

          if (loginData.data.useTrusona) {
            if (res && res.data && res.data.result) {
              // createNotification ('error',res.data.result.message);
            }
          } else {
            dispatch({ type: LOGIN, payload: res.data.result })
          }

          dispatch(Loading(false))
        }
      })
      .catch(err => {
        dispatch(Loading(false))

        console.log(err)
        throw err
      })
  }
}

export const checkLoginUpdates = loginData => {
  return dispatch => {
    let sendRequestUserProfile = {
      data: {}
    }
    dispatch(getProfileDashboard(sendRequestUserProfile, loginData))

    // dispatch (Loading (true));
    // let sendBusinessrequest={
    //   data:{}
    // }
    // dispatch (businessAccountsGetAccounts(sendBusinessrequest));

    // const sendRequest = {
    //   data: {
    //     getPersonalDetails: true,
    //     getProfessionalDetails: true,
    //     getEducationalDetails: true,
    //     getBusinessProfile: true,
    //   },
    // };
    // dispatch (getUserDetail (sendRequest));
    // let sendRequestUserProfile = {
    //   data: {},
    // };
    // dispatch (getProfileDashboard (sendRequestUserProfile));
  }
}

export const register = registerData => {
  return dispatch => {
    dispatch(Loading(true))
    registerService(registerData)
      .then(userData => {
        dispatch(Loading(false))

        if (userData && userData.data && userData.data.result.status === true) {
          // history.push (routes.SIGN_IN);

          // history.push(routes.ONE_STEP);

          dispatch({ type: ADD_NEW_BUSINESS, payload: userData.data })
          // createNotification ('success', userData.data.result.message);
          createNotification(
            'success',
            getMessage(userData.data.result.responseCode)
          )
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
            // createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const uploadBusinessProfile = profilePicData => {
  return dispatch => {
    dispatch(Loading(true))
    uploadBusinessProfilePictureService(profilePicData)
      .then(userData => {
        dispatch(Loading(false))

        if (userData && userData.data && userData.data.result.status === true) {
          dispatch({
            type: BUSINESS_DETAIL_PROFILE,
            payload: userData.data.result.Data
          })
          createNotification(
            'success',
            getMessage(userData.data.result.responseCode)
          )
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
            // createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}
export const ModifyPersonalInfo = (registerData, sendRequest) => {
  return dispatch => {
    dispatch(Loading(true))
    modifyPersonalInfoService(registerData)
      .then(userData => {
        dispatch(Loading(false))
        if (userData && userData.data && userData.data.result.status === true) {
          // createNotification ('success', userData.data.result.message);
          createNotification(
            'success',
            getMessage(userData.data.result.responseCode)
          )
          dispatch({ type: REGISTER, payload: userData.data })
          if (sendRequest) {
            dispatch(uploadUserProfile(sendRequest))
          } else {
            const sendRequest = {
              data: {
                getPersonalDetails: true,
                getProfessionalDetails: true,
                getEducationalDetails: true,
                getBusinessProfile: true
              }
            }
            dispatch(getProfileDashboard(sendRequest))
          }
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
            // createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const DoRegisterModifyPersonalInfo = (registerData, sendRequest) => {
  return dispatch => {
    dispatch(Loading(true))
    modifyPersonalInfoService(registerData)
      .then(userData => {
        dispatch(Loading(false))
        if (userData && userData.data && userData.data.result.status === true) {
          // console.log(userData.data.result);

          if (userData.data.result.status) {
            let userID = userData.data.result.Data.docID

            let min = Math.ceil(100000000)
            let max = Math.floor(999999999)
            const database = firebase.database()
            let dhAlicesKey = Math.floor(Math.random() * (max - min + 1)) + min
            let obj = {
              isOnline: false,
              lastTimeOnline: Math.floor(Date.now()),
              loggedIn: false,
              publicKey: dhAlicesKey
              // isWebConnectActive: false,
              // FCMTokenForConnect: null,
            }
            database.ref(`Users/${userID}/onlineStatus`).update(obj)
          }

          // createNotification ('success', userData.data.result.message);
          createNotification(
            'success',
            getMessage(userData.data.result.responseCode)
          )

          dispatch({ type: REGISTER, payload: userData.data })
          if (sendRequest) {
            dispatch(uploadUserProfile(sendRequest))
          } else {
            const sendRequest = {
              data: {
                getPersonalDetails: true,
                getProfessionalDetails: true,
                getEducationalDetails: true,
                getBusinessProfile: true
              }
            }
            dispatch(getProfileDashboard(sendRequest))
          }
        } else {
          if (userData && userData.data && userData.data.result) {
            // createNotification ('error', userData.data.result.message);

            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const AddEducationalDetail = registerData => {
  return dispatch => {
    dispatch(Loading(true))
    modifyEducationDetailsService(registerData)
      .then(userData => {
        if (userData && userData.data && userData.data.result.status === true) {
          // dispatch ({type: GETEDUCATIONDETAIL, payload: userData.data});

          // const sendRequest = {
          //   data: {},
          // };
          // dispatch (GetEducationalDetail (sendRequest));

          // if (registerData.data.docID == '') {
          // const sendRequest = {
          //   data: {},
          // };
          // dispatch (GetEducationalDetail (sendRequest));
          // } else {
          // const sendRequest = {
          //   data: {
          //     getPersonalDetails: true,
          //     getProfessionalDetails: true,
          //     getEducationalDetails: true,
          //     getBusinessProfile: true,
          //     // docID: registerData.docID,
          //   },
          // };
          // dispatch (getUserProfileDetail (sendRequest));
          // }
          const sendRequest = {
            data: {
              getPersonalDetails: true,
              getProfessionalDetails: true,
              getEducationalDetails: true,
              getBusinessProfile: true
            }
          }
          dispatch(getProfileDashboard(sendRequest))
        } else {
          dispatch(Loading(false))
          if (userData && userData.data && userData.data.result) {
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
            // createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const GetEducationalDetail = request => {
  return dispatch => {
    dispatch(Loading(true))
    getEducationDetailsService(request)
      .then(userData => {
        dispatch(Loading(false))
        if (
          userData &&
          userData.data &&
          userData.data.result &&
          userData.data.result.status === true
        ) {
          dispatch({ type: GETEDUCATIONDETAIL, payload: userData.data })
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification('error', userData.data.result.message)
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const DeleteEducationalDetail = (request, status) => {
  return dispatch => {
    // dispatch (Loading (true));

    dispatch({ type: DELETE_EDUCATION_PROFILE, payload: request.data })

    deleteEducationDetailsService(request)
      .then(userData => {
        dispatch(Loading(false))
        if (userData && userData.data && userData.data.result.status === true) {
          // if(status){
          // const sendRequest = {
          //   data: {
          //     getPersonalDetails: true,
          //     getProfessionalDetails: true,
          //     getEducationalDetails: true,
          //     getBusinessProfile: true,
          //   },
          // };
          // dispatch (getUserProfileDetail (sendRequest));

          // const sendRequest = {
          //   data: {
          //     getPersonalDetails: true,
          //     getProfessionalDetails: true,
          //     getEducationalDetails: true,
          //     getBusinessProfile: true,
          //   },
          // };
          // dispatch (getProfileDashboard (sendRequest));

          // }else{
          //   const data = {
          //     data: {},
          //   };
          //   dispatch (GetEducationalDetail (data));

          // }

          createNotification(
            'success',
            getMessage(userData.data.result.responseCode)
          )

          // createNotification ('success', 'Successfully Deleted !');
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )

            // createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const AddProfessionalDetail = registerData => {
  return dispatch => {
    dispatch(Loading(true))
    modifyProfessionalInfoService(registerData)
      .then(userData => {
        // dispatch (Loading (false));
        if (userData && userData.data && userData.data.result.status === true) {
          // dispatch ({type: GETEDUCATIONDETAIL, payload: userData.data});
          // const sendRequest ={
          //   data:{}
          // }
          // dispatch (getProfessionalDetail(sendRequest));

          // if (registerData.data.userid) {
          //   const sendRequest = {
          //     data: {
          //       getPersonalDetails: true,
          //       getProfessionalDetails: true,
          //       getEducationalDetails: true,
          //       getBusinessProfile: true,
          //       docID: registerData.docID,
          //     },
          //   };
          //   dispatch (getUserProfileDetail (sendRequest));
          // } else {
          //   const sendRequest = {
          //     data: {},
          //   };
          //   dispatch (getProfessionalDetail (sendRequest));
          // }
          const sendRequest = {
            data: {
              getPersonalDetails: true,
              getProfessionalDetails: true,
              getEducationalDetails: true,
              getBusinessProfile: true
            }
          }
          dispatch(getProfileDashboard(sendRequest))
        } else {
          dispatch(Loading(false))
          if (userData && userData.data && userData.data.result) {
            // createNotification ('error', userData.data.result.message);
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const getProfessionalDetail = request => {
  return dispatch => {
    dispatch(Loading(true))
    getProfessionalDetailsService(request)
      .then(userData => {
        dispatch(Loading(false))
        if (userData && userData.data && userData.data.result.status === true) {
          dispatch({ type: GETPROFESSIONALDETAIL, payload: userData.data })
        } else {
          if (userData && userData.data && userData.data.result) {
            // createNotification ('error', userData.data.result.message);
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const DeleteProfessionalDetail = (request, status) => {
  return dispatch => {
    // dispatch (Loading (true));
    dispatch({ type: DELETE_PROFESSIONAL_PROFILE, payload: request.data })

    deleteProfessionalDetailsService(request)
      .then(userData => {
        dispatch(Loading(false))
        if (userData && userData.data && userData.data.result.status === true) {
          // const sendRequest = {
          //   data: {
          //     getPersonalDetails: true,
          //     getProfessionalDetails: true,
          //     getEducationalDetails: true,
          //     getBusinessProfile: true,
          //   },
          // };
          // dispatch (getProfileDashboard (sendRequest));

          // if (status) {
          //   const sendRequest = {
          //     data: {
          //       getPersonalDetails: true,
          //       getProfessionalDetails: true,
          //       getEducationalDetails: true,
          //       getBusinessProfile: true,
          //       docID: request.docID,
          //     },
          //   };
          //   dispatch (getUserProfileDetail (sendRequest));
          // } else {
          //   const data = {
          //     data: {},
          //   };
          //   dispatch (getProfessionalDetail (data));
          // }

          // createNotification ('success', 'Successfully Deleted !');
          createNotification(
            'success',
            getMessage(userData.data.result.responseCode)
          )
        } else {
          if (userData && userData.data && userData.data.result) {
            // createNotification ('error', userData.data.result.message);
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const getFindBusiness = request => {
  return dispatch => {
    getFindBusinessService(request)
      .then(userData => {
        dispatch(Loading(false))
        if (userData && userData.data && userData.data.result.status === true) {
          dispatch({ type: FIND_BUSINESS_DETAIL, payload: userData.data })
        } else {
          if (userData && userData.data && userData.data.result) {
            // createNotification ('error', userData.data.result.message);
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}
export const sendBusinessInvite = registerData => {
  return dispatch => {
    dispatch(Loading(true))
    sendBusinessInviteService(registerData)
      .then(userData => {
        dispatch(Loading(false))
        if (userData && userData.data && userData.data.result.status === true) {
          createNotification(
            'success',
            getMessage(userData.data.result.responseCode)
          )
          dispatch({
            type: BUSINESS_SEND_INVITE,
            payload: userData.data.result.Data
          })
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
            // createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const registerDeviceToken = request => {
  return dispatch => {
    registerDeviceTokenService(request)
      .then(userData => {
        dispatch(Loading(false))
        // console.log(userData);
        // if (userData && userData.data && userData.data.result.status === true) {
        //   dispatch ({type: FIND_BUSINESS_DETAIL, payload: userData.data});
        // } else {
        //   if (userData && userData.data && userData.data.result) {
        //     createNotification ('error', userData.data.result.message);
        //   }
        // }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const getProfileDashboard = (request, isNewuser) => {
  return dispatch => {
    // dispatch (Loading (true));
    getUserDashboard(request)
      .then(async userData => {
        // dispatch (Loading (false));
        if (userData && userData.data && userData.data.result.status === true) {
          if (isNewuser) {
            let newResponse = userData.data.result.Data
            if (newResponse && newResponse.personalDetails) {
              localStorage.setItem(
                'loginuser',
                JSON.stringify(newResponse.personalDetails)
              )
              if (
                newResponse &&
                newResponse.personalDetails &&
                newResponse.personalDetails.walletAddress
              ) {
                localStorage.setItem(
                  'address',
                  newResponse.personalDetails.walletAddress
                )
              } else {
                localStorage.setItem('address', '')
              }
            }

            let personalDetails = []
            personalDetails.push({
              personalDetails: newResponse.personalDetails
            })
            isNewuser.Data.userDetails = personalDetails
            if (
              userData.data.result.Data &&
              userData.data.result.Data.subscriptionInfo
            ) {
              if (
                userData.data.result.Data.subscriptionInfo &&
                userData.data.result.Data.subscriptionInfo.featureToScreenMap
              ) {
                dispatch({
                  type: CHECK_ENABLE_FEATURE,
                  payload:
                    userData.data.result.Data.subscriptionInfo
                      .featureToScreenMap
                })
              }
            }

            dispatch({ type: LOGIN, payload: isNewuser })

            dispatch({
              type: GET_DASHBOARD_PROFILE,
              payload: newResponse
            })
          }
          // if( userData.data.result.Data && userData.data.result.Data.modules){
          //   if(userData.data.result.Data.modules){
          //     dispatch ({type: CHECK_ENABLE_FEATURE, payload: userData.data.result.Data.modules});
          //   }
          // }

          // if( userData.data.result.Data && userData.data.result.Data.subscriptionInfo){
          //   if(userData.data.result.Data.subscriptionInfo && userData.data.result.Data.subscriptionInfo.featureToScreenMap){
          //     dispatch ({type: CHECK_ENABLE_FEATURE, payload: userData.data.result.Data.subscriptionInfo.featureToScreenMap});
          //   }
          // }
          if (userData.data.result.Data.personalDetails.docID) {
            dispatch(
              fetchCheckingSubscription(
                userData.data.result.Data.personalDetails.docID
              )
            )
          }
          if (userData.data.result.Data.personalDetails.docID) {
            dispatch(
              fetchRealtimeDashboardData(
                userData.data.result.Data.personalDetails.docID
              )
            )
          }

          if (
            userData.data.result.Data &&
            userData.data.result.Data.businessProfile
          ) {
            if (userData.data.result.Data.businessProfile.docID) {
              dispatch({
                type: BUSINESS_PROFILE_CHECK,
                payload: userData.data.result.Data.businessProfile
              })
            }
          }

          // dispatch ({
          //   type: GET_DASHBOARD_PROFILE,
          //   payload: userData.data.result.Data,
          // });

          // userData.data.result.Data.profileCompletion.kycSetup=false;
          // userData.data.result.Data.profileCompletion.percentageComplete=userData.data.result.Data.profileCompletion.percentageComplete-33;

          // if(userData.data.result.Data&& userData.data.result.Data.personalDetails&& userData.data.result.Data.personalDetails.docID){
          //   let userID= userData.data.result.Data.personalDetails.docID;
          //   const database = firebase.database();

          //   if(userID){
          //     await database.ref(`kycstate/${userID}`).on('value', snapshot => {
          //       if(snapshot.val()){
          //         // console.log(snapshot.val());
          //         if(snapshot.val().Userid){

          //           dispatch (updateProfileDashboardKYC(snapshot.val()));
          //         }
          //       }
          //     })
          //   }

          // }
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
            // createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const sendUserInfoKYC = request => {
  return dispatch => {
    dispatch(Loading(true))
    completeUserKYC(request)
      .then(userData => {
        // dispatch (Loading (false));
        let sendRequestUserProfile = {
          data: {}
        }

        // this.props.onfidoResumeChecksAction(sendRequestUserProfile)
        if (userData && userData.data && userData.data.result.status === true) {
          // createNotification ('success', userData.data.result.message);

          createNotification(
            'success',
            getMessage(userData.data.result.responseCode)
          )
          dispatch({
            type: USER_KYC_SUCCESS,
            payload: userData.data.result.Data
          })
          dispatch(onfidoResumeChecksAction(sendRequestUserProfile))
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
            // createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const onfidoResumeChecksAction = request => {
  return dispatch => {
    dispatch(Loading(true))
    onfidoResumeChecks(request)
      .then(userData => {
        dispatch(Loading(false))

        console.log(userData)
        // if (userData && userData.data && userData.data.result.status === true) {
        //   // createNotification ('success', userData.data.result.message);

        //   createNotification ('success',getMessage(userData.data.result.responseCode));
        //   dispatch ({type: USER_KYC_SUCCESS, payload: userData.data.result.Data});

        // } else {
        //   if (userData && userData.data && userData.data.result) {
        //     createNotification ('error',getMessage(userData.data.result.responseCode));
        //     // createNotification ('error', userData.data.result.message);
        //   }
        // }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const onfidoGetSDKTokenForApplicantAction = request => {
  return dispatch => {
    dispatch(Loading(true))
    onfidoGetSDKTokenForApplicant(request)
      .then(userData => {
        dispatch(Loading(false))

        console.log(userData)
        if (userData && userData.data && userData.data.result.status === true) {
          dispatch({
            type: APPLICANT_TOKEN,
            payload: userData.data.result.Data
          })
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
            // createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const sendResetPassword = request => {
  return dispatch => {
    dispatch(Loading(true))
    passwordResetGenerateOTP(request)
      .then(userData => {
        dispatch(Loading(false))
        if (userData && userData.data && userData.data.result.status === true) {
          dispatch({
            type: SEND_RESETPASSWORD_OTP,
            payload: userData.data.result.Data
          })
          // createNotification ('success', userData.data.result.message);
          createNotification(
            'success',
            getMessage(userData.data.result.responseCode)
          )
        } else {
          if (userData && userData.data && userData.data.result) {
            // createNotification ('error', userData.data.result.message);
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const confirmResetPasswordOTP = request => {
  return dispatch => {
    dispatch(Loading(true))
    passwordResetVerifyOTP(request)
      .then(userData => {
        dispatch(Loading(false))
        if (userData && userData.data && userData.data.result.status === true) {
          // if (request.data.password) {
          //   dispatch ({
          //     type: SEND_RESET_PASSWORD,
          //     payload: userData.data.result.Data,
          //   });
          // } else {
          dispatch({
            type: SEND_CONFIRM_PASSWORD_OTP,
            payload: userData.data.result.Data
          })
          // }

          // createNotification ('success', userData.data.result.message);
          createNotification(
            'success',
            getMessage(userData.data.result.responseCode)
          )
        } else {
          if (userData && userData.data && userData.data.result) {
            // createNotification ('error', userData.data.result.message);
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const updateResetPasswordOTP = request => {
  return dispatch => {
    dispatch(Loading(true))
    passwordResetChangePassword(request)
      .then(userData => {
        dispatch(Loading(false))
        if (userData && userData.data && userData.data.result.status === true) {
          dispatch({
            type: SEND_RESET_PASSWORD,
            payload: userData.data.result.Data
          })

          // createNotification ('success', userData.data.result.message);
          createNotification(
            'success',
            getMessage(userData.data.result.responseCode)
          )
        } else {
          if (userData && userData.data && userData.data.result) {
            // createNotification ('error', userData.data.result.message);
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const removeBusiness = (registerData, docID) => {
  return dispatch => {
    dispatch(Loading(true))
    removeUserFromBusinessService(registerData)
      .then(userData => {
        dispatch(Loading(false))
        if (userData && userData.data && userData.data.result.status === true) {
          const sendRequest = {
            data: {
              getPersonalDetails: true,
              getProfessionalDetails: true,
              getEducationalDetails: true,
              getBusinessProfile: true,
              docID: docID
            }
          }
          dispatch(getProfileDashboard(sendRequest))
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
            // createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const resendEmail = Data => {
  return dispatch => {
    dispatch(Loading(true))
    resendEmailVerify(Data)
      .then(userData => {
        // console.log (userData);
        dispatch(Loading(false))

        if (userData && userData.data && userData.data.result.status === true) {
          // createNotification ('success', userData.data.result.message);
          createNotification(
            'success',
            getMessage(userData.data.result.responseCode)
          )
        } else {
          if (userData && userData.data && userData.data.result) {
            // createNotification ('error', userData.data.result.message);
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const userChangePassword = Data => {
  return dispatch => {
    dispatch(Loading(true))
    changeUserPasswordService(Data)
      .then(userData => {
        // console.log (userData);
        dispatch(Loading(false))

        if (userData && userData.data && userData.data.result.status === true) {
          dispatch({
            type: CHANGE_PASSWORD_SUCCESS,
            payload: userData.data.result.Data
          })
          // createNotification ('success', userData.data.result.message);
          createNotification(
            'success',
            getMessage(userData.data.result.responseCode)
          )
        } else {
          if (userData && userData.data && userData.data.result) {
            // createNotification ('error', userData.data.result.message);
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const userSettingsModifySearchSettings = request => {
  return dispatch => {
    dispatch(Loading(true))
    userSettingsModifySearchSettingsService(request)
      .then(userData => {
        dispatch(Loading(false))
        if (userData && userData.data && userData.data.result.status === true) {
          // createNotification ('success', userData.data.result.message);
          createNotification(
            'success',
            getMessage(userData.data.result.responseCode)
          )
          const sendRequest = {
            data: {}
          }
          dispatch(getProfileDashboard(sendRequest))
        } else {
          if (userData && userData.data && userData.data.result) {
            // createNotification ('error', userData.data.result.message);
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const userSettingsModifyNotificationSettings = request => {
  return dispatch => {
    dispatch(Loading(true))
    userSettingsModifyNotificationSettingsService(request)
      .then(userData => {
        dispatch(Loading(false))
        if (userData && userData.data && userData.data.result.status === true) {
          // createNotification ('success', userData.data.result.message);
          createNotification(
            'success',
            getMessage(userData.data.result.responseCode)
          )
          const sendRequest = {
            data: {}
          }
          dispatch(getProfileDashboard(sendRequest))
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
            // createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const trusonaAuthEnabledSetting = request => {
  return dispatch => {
    dispatch(Loading(true))
    userSettingsToggleTrusonaAuthService(request)
      .then(userData => {
        dispatch(Loading(false))
        if (userData && userData.data && userData.data.result.status === true) {
          // createNotification ('success', userData.data.result.message);
          createNotification(
            'success',
            getMessage(userData.data.result.responseCode)
          )
          const sendRequest = {
            data: {}
          }
          dispatch(getProfileDashboard(sendRequest))
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
            // createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const sendOTP = request => {
  return dispatch => {
    dispatch(Loading(true))
    sendPhoneNumberVerificationService(request)
      .then(userData => {
        dispatch(Loading(false))
        if (userData && userData.data && userData.data.result.status === true) {
          // createNotification ('success', userData.data.result.message);
          dispatch({
            type: SEND_MOBILE_OTP_ERROR,
            payload: ''
          })
          dispatch({
            type: SEND_MOBILE_OTP,
            payload: userData.data.result.Data
          })

          createNotification(
            'success',
            getMessage(userData.data.result.responseCode)
          )
        } else {
          if (userData && userData.data && userData.data.result) {
            var str2 = getMessage(userData.data.result.responseCode)
              ? getMessage(userData.data.result.responseCode).trim()
              : ''

            var splitstring = str2.split('+')
            if (splitstring && splitstring.length > 0) {
              splitstring[1] = request.data.phoneNumber
            }
            userData.data.result.message = splitstring.join('')

            // createNotification ('error',getMessage(userData.data.result.responseCode));

            dispatch({
              type: SEND_MOBILE_OTP_ERROR,
              payload: userData.data.result.Data
            })
            createNotification('error', userData.data.result.message)
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const verifyOTP = request => {
  return dispatch => {
    dispatch(Loading(true))
    verifyPhoneNumberOTPService(request)
      .then(userData => {
        dispatch(Loading(false))

        if (userData && userData.data && userData.data.result.status === true) {
          // createNotification ('success', userData.data.result.message);
          dispatch({
            type: VERIFY_OTP,
            payload: userData.data.result.Data
          })

          createNotification(
            'success',
            getMessage(userData.data.result.responseCode)
          )
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification(
              'error',
              getMessage(userData.data.result.responseCode)
            )
            // createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const verifyAccountEmail = request => {
  return dispatch => {
    dispatch(Loading(true))
    rdeeEmailVerificationService(request)
      .then(userData => {
        dispatch(Loading(false))

        // userData.data.result.message= getMessage(userData.data.result.responseCode);

        if (userData) {
          dispatch({
            type: VERIFY_EMAIL,
            payload: userData.data.result
          })
          if (
            userData &&
            userData.data &&
            userData.data.result.status === true
          ) {
            // createNotification ('success', userData.data.result.message);
            // dispatch ({
            //   type: VERIFY_OTP,
            //   payload: userData.data.result.Data,
            // });

            // createNotification ('success',userData.data.result.message);
            createNotification(
              'success',
              getMessage(userData.data.result.responseCode)
            )

            // createNotification ('success',getMessage(userData.data.result.responseCode));
          } else {
            if (userData && userData.data && userData.data.result) {
              // createNotification ('error',userData.data.result.responseCode);
              createNotification(
                'error',
                getMessage(userData.data.result.responseCode)
              )
            }
          }
        }
      })
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const businessAccountsGetAccounts = request => {
  return dispatch => {
    // dispatch (Loading (true));
    businessAccountsGetAccountsService(request)
      .then(res => {
        if (
          res &&
          res.data &&
          res.data.result &&
          res.data.result.status === true
        ) {
          if (res.data.result.Data && res.data.result.Data.length > 0) {
            dispatch({
              type: USER_BUSINESS_LIST,
              payload: res.data.result.Data
            })
          } else {
            dispatch({ type: USER_BUSINESS_LIST, payload: [] })
          }
        } else {
          if (res && res.data && res.data.result && res.data.result) {
            // createNotification ('error',getMessage(res.data.result.responseCode));
            if (
              res.data.result.responseCode &&
              getMessage(res.data.result.responseCode)
            ) {
              createNotification(
                'error',
                getMessage(res.data.result.responseCode)
              )
            } else {
              createNotification('error', res.data.result.message)
            }
          }
        }
        // dispatch (Loading (false));
      })
      .catch(err => {
        dispatch(Loading(false))

        console.log(err)
        throw err
      })
  }
}
export const businessAccountsSetActiveAccount = request => {
  return dispatch => {
    dispatch(Loading(true))
    businessAccountsSetActiveAccountService(request)
      .then(res => {
        if (
          res &&
          res.data &&
          res.data.result &&
          res.data.result.status === true
        ) {
          dispatch({ type: UPDATE_NEW_BUSINESS, payload: true })
        } else {
          if (res && res.data && res.data.result && res.data.result) {
            // createNotification ('error',getMessage(res.data.result.responseCode));
            if (
              res.data.result.responseCode &&
              getMessage(res.data.result.responseCode)
            ) {
              createNotification(
                'error',
                getMessage(res.data.result.responseCode)
              )
            } else {
              createNotification('error', res.data.result.message)
            }
          }
        }
        // dispatch (Loading (false));s
      })
      .catch(err => {
        dispatch(Loading(false))

        console.log(err)
        throw err
      })
  }
}

export const businessAccountsGetGroups = request => {
  return dispatch => {
    dispatch(Loading(true))
    businessAccountsGetGroupsService(request)
      .then(res => {
        console.log(res)
        dispatch(getBusinessAccountsGetPendingInvites(request))
        if (
          res &&
          res.data &&
          res.data.result &&
          res.data.result.status === true
        ) {
          dispatch({
            type: BUSINESS_ACCOUNT_GROUPS,
            payload: res.data.result.Data
          })
        } else {
          if (res && res.data && res.data.result && res.data.result) {
            // createNotification ('error',getMessage(res.data.result.responseCode));

            if (
              res.data.result.responseCode &&
              getMessage(res.data.result.responseCode)
            ) {
              createNotification(
                'error',
                getMessage(res.data.result.responseCode)
              )
            } else {
              createNotification('error', res.data.result.message)
            }
          }
        }
        // dispatch (Loading (false));
      })
      .catch(err => {
        dispatch(Loading(false))

        console.log(err)
        throw err
      })
  }
}

export const updateBusinessAccountsGroups = request => {
  return dispatch => {
    dispatch(Loading(true))
    UpdatebusinessAccountsManageGroupsService(request)
      .then(res => {
        console.log(res)
        if (
          res &&
          res.data &&
          res.data.result &&
          res.data.result.status === true
        ) {
          dispatch({
            type: UPDATE_BUSINESS_ACCOUNT_GROUPS,
            payload: res.data.result.Data
          })

          if (
            res.data.result.responseCode &&
            getMessage(res.data.result.responseCode)
          ) {
            createNotification(
              'success',
              getMessage(res.data.result.responseCode)
            )
          } else {
            createNotification('success', res.data.result.message)
          }
        } else {
          if (res && res.data && res.data.result && res.data.result) {
            // createNotification ('error',getMessage(res.data.result.responseCode));
            if (
              res.data.result.responseCode &&
              getMessage(res.data.result.responseCode)
            ) {
              createNotification(
                'error',
                getMessage(res.data.result.responseCode)
              )
            } else {
              createNotification('error', res.data.result.message)
            }
          }
        }
        dispatch(Loading(false))
      })
      .catch(err => {
        dispatch(Loading(false))

        console.log(err)
        throw err
      })
  }
}

export const getBusinessAccountsGetPendingInvites = request => {
  return dispatch => {
    // dispatch (Loading (true));
    businessAccountsGetPendingInvitesService(request)
      .then(res => {
        if (
          res &&
          res.data &&
          res.data.result &&
          res.data.result.status === true
        ) {
          console.log(res.data.result.Data)
          dispatch({
            type: GET_BUSINESS_PENDING_INVITES,
            payload: res.data.result.Data
          })
        } else {
          if (res && res.data && res.data.result && res.data.result) {
            // createNotification ('error',getMessage(res.data.result.responseCode));
            if (
              res.data.result.responseCode &&
              getMessage(res.data.result.responseCode)
            ) {
              createNotification(
                'error',
                getMessage(res.data.result.responseCode)
              )
            } else {
              createNotification('error', res.data.result.message)
            }
          }
        }
        dispatch(Loading(false))
      })
      .catch(err => {
        dispatch(Loading(false))

        console.log(err)
        throw err
      })
  }
}

export const businessAccountsAcceptInvite = request => {
  return dispatch => {
    dispatch({ type: BUSINESS_ACCEPT_INVITE, payload: request.data })
    businessAccountsAcceptPendingInviteService(request)
      .then(res => {
        if (
          res &&
          res.data &&
          res.data.result &&
          res.data.result.status === true
        ) {
          console.log(res.data.result.Data)
          // dispatch ({ type: BUSINESS_ACCEPT_INVITE, payload: res.data.result.Data});
        } else {
          if (res && res.data && res.data.result && res.data.result) {
            // createNotification ('error',getMessage(res.data.result.responseCode));
            if (
              res.data.result.responseCode &&
              getMessage(res.data.result.responseCode)
            ) {
              createNotification(
                'error',
                getMessage(res.data.result.responseCode)
              )
            } else {
              createNotification('error', res.data.result.message)
            }
          }
        }
        dispatch(Loading(false))
      })
      .catch(err => {
        dispatch(Loading(false))

        console.log(err)
        throw err
      })
  }
}
export const businessAccountsRejectInvite = request => {
  return dispatch => {
    dispatch({ type: BUSINESS_REJECT_INVITE, payload: request.data })
    businessAccountsRejectPendingInviteService(request)
      .then(res => {
        if (
          res &&
          res.data &&
          res.data.result &&
          res.data.result.status === true
        ) {
          console.log(res.data.result.Data)
          // dispatch ({ type: BUSINESS_REJECT_INVITE, payload: res.data.result.Data});
        } else {
          if (res && res.data && res.data.result && res.data.result) {
            // createNotification ('error',getMessage(res.data.result.responseCode));
            if (
              res.data.result.responseCode &&
              getMessage(res.data.result.responseCode)
            ) {
              createNotification(
                'error',
                getMessage(res.data.result.responseCode)
              )
            } else {
              createNotification('error', res.data.result.message)
            }
          }
        }
        dispatch(Loading(false))
      })
      .catch(err => {
        dispatch(Loading(false))

        console.log(err)
        throw err
      })
  }
}

export const updateWelcomeStatus = request => {
  return dispatch => {
    setSeenWelcomeScreenService(request)
      .then(userData => {})
      .catch(err => {
        dispatch(Loading(false))
        throw err
      })
  }
}

export const resetResetPasswordReducer = request => {
  return dispatch => {
    dispatch({ type: SEND_RESET_PASSWORD, payload: '' })
    dispatch({ type: SEND_CONFIRM_PASSWORD_OTP, payload: '' })
    dispatch({ type: SEND_RESETPASSWORD_OTP, payload: '' })
    dispatch({ type: CHECK_EMAIL, payload: [] })
    dispatch({ type: VERIFY_OTP, payload: '' })
    dispatch({ type: REGISTER, payload: '' })

    dispatch({ type: LOGIN, payload: '' })
    dispatch({ type: USER_BUSINESS_LIST, payload: null })
    dispatch({ type: UPDATE_NEW_BUSINESS, payload: null })
  }
}

export const resetUserReducer = request => {
  return dispatch => {
    dispatch({ type: ADD_NEW_BUSINESS, payload: '' })
    dispatch({ type: CHANGE_PASSWORD_SUCCESS, payload: '' })
  }
}

export const resetWholeReducer = request => {
  return dispatch => {
    dispatch({ type: 'LOGOUT' })
  }
}

export const updateFirebaseToken = token => {
  return dispatch => {
    dispatch({ type: UPDATE_AUTH_TOKEN, payload: token })
  }
}

export const resetEducationDetail = () => {
  return dispatch => {
    dispatch({ type: GETEDUCATIONDETAIL, payload: [] })
  }
}

export const fetchStatusCode = () => {
  return dispatch => {
    const database = firebase.database()
    database.ref(`responseCodeMapping`).on('value', snapShot => {
      if (snapShot.val()) {
        dispatch({ type: ADD_ERROR_CODE, payload: snapShot.val() })
      } else {
        dispatch({ type: ADD_ERROR_CODE, payload: [] })
      }
    })
  }
}

export const fetchCheckingSubscription = userID => {
  return dispatch => {
    const database = firebase.database()
    database.ref(`feature_screen_map/${userID}`).on('value', snapShot => {
      if (snapShot.val() && snapShot.val().featureToScreenMap) {
        dispatch({
          type: CHECK_ENABLE_FEATURE,
          payload: snapShot.val().featureToScreenMap
        })
      } else {
        dispatch({ type: CHECK_ENABLE_FEATURE, payload: [] })
      }
    })
  }
}
export const fetchRealtimeDashboardData = userID => {
  return dispatch => {
    // alert("jj")
    const database = firebase.database()
    let nodeUrl = database.ref(`user_dashboard/${userID}`)
    nodeUrl.on('value', async snapShot => {
      if (snapShot.val() && snapShot.val()) {
        let newResponse = snapShot.val()
        console.log(newResponse)
        if (newResponse) {
          if (newResponse.businessProfile) {
            if (newResponse.businessProfile.docID) {
              dispatch({
                type: BUSINESS_PROFILE_CHECK,
                payload: newResponse.businessProfile
              })
            }
          }

          dispatch({
            type: GET_DASHBOARD_PROFILE,
            payload: newResponse
          })
          dispatch(Loading(false))

          if (
            newResponse &&
            newResponse.personalDetails &&
            newResponse.personalDetails.docID
          ) {
            let userID = newResponse.personalDetails.docID
            const database = firebase.database()

            if (userID) {
              await database.ref(`kycstate/${userID}`).on('value', snapshot => {
                if (snapshot.val()) {
                  if (snapshot.val().Userid) {
                    dispatch(updateProfileDashboardKYC(snapshot.val()))
                  }
                }
              })
              await database
                .ref(`busregstate/${userID}`)
                .on('value', snapshot => {
                  console.log(snapshot.val())
                  if (snapshot.val()) {
                    if (snapshot.val().Userid) {
                      dispatch(updateProfileDashboardBusiness(snapshot.val()))
                    }
                  }
                })
            }
          }
        }
      } else {
        // dispatch ({type: CHECK_ENABLE_FEATURE, payload: []});
      }
    })
  }
}

export const resetApplicantToken = () => {
  return dispatch => {
    dispatch({ type: APPLICANT_TOKEN, payload: null })
  }
}

export const resetProfileDashboard = () => {
  return dispatch => {
    dispatch({ type: GET_DASHBOARD_PROFILE, payload: null })
  }
}

export const updateProfileDashboardKYC = data => {
  return dispatch => {
    dispatch({ type: UPDATE_KYC_DASHBOARD_PROFILE, payload: data })
  }
}
export const updateProfileDashboardBusiness = data => {
  return dispatch => {
    dispatch({ type: UPDATE_BUSINESS_DASHBOARD_PROFILE, payload: data })
  }
}

// this will update the user data
export const userDeviceData = userInfo => {
  return dispatch => {
    let userID = _.get(
      userInfo,
      'user.Data.userDetails[0].personalDetails.docID',
      null
    )

    if (userID) {
      const database = firebase.database()

      database.ref(`Users/${userID}/onlineStatus`).on('value', snapShot => {
        // console.log("snapShot>>> web authContainer user device data", snapShot.val());
        dispatch({ type: 'USER_DEVICE_DATA', payload: snapShot.val() })
      })
    }
  }
}

export const userDeviceDataUpdated = userDeviceData => {
  return dispatch => {
    dispatch({ type: 'USER_DEVICE_DATA', payload: userDeviceData })
  }
}

// setting up a common secret between mobile and web
export const secretKeyForWebUpdated = secretKeyForWeb => {
  return dispatch => {
    dispatch({ type: 'UPDATE_SECRET_KEY_FOR_WEB', payload: secretKeyForWeb })
  }
}

export const _handleTransportKey = () => {
  let keyValue = getRandomInt(100000000, 999999999)
  let transportKey = userSharedKey(keyValue)
  return dispatch => {
    dispatch({ type: 'UPDATE_TRANSPORT_KEY', payload: transportKey })
  }
}

export const signOut = () => {
  const currentState = store.getState()
  return dispatch => {
    let obj = {
      isOnline: false,
      lastTimeOnline: Math.floor(Date.now()),
      loggedIn: false,
      isWebConnectActive: false,
      encryptedTextForWebToDecryptToStartConnect: null
    }
    let userID = _.get(
      currentState,
      'Authreducer.user.Data.userDetails[0].personalDetails.docID',
      null
    )
    if (userID) {
      const database = firebase.database()
      database.ref(`Users/${userID}/onlineStatus`).update(obj)
    }

    localStorage.clear()
    dispatch(Loading(true))
    dispatch({ type: LOGOUT })
    dispatch(Loading(false))
    window.location.reload()
  }
}
