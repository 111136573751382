let initialState = {
  labelslist: {},
};

export const GET_LABELS = 'GET_LABELS';
export const SELECT_LABEL = 'SELECT_LABEL';




export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LABELS:
      return {...state, labelslist: action.payload};
    default:
      return state;
  }
};
