import baseService from './PaymentBaseservice';
import baseService1 from './Baseservice';


export const transactionService = (payload) => {
    // return baseService1.post('/api/v0/history', payload);
    return baseService1.post('getRDEETransactionHistory', payload);
    // return baseService1.post('getUserTransactionHistoryAndBalance', payload);

    
};
export const createAccountService = (payload) => {
    return baseService.post('/api/v0/account/create', payload);
};

export const getUserContactService = (payload) => {
    return baseService1.post('getAllUserContacts', payload);
};

export const transferAmountService = (payload) => {
    return baseService.post('/api/v0/transfer', payload);
};

export const askMoneyService = (payload) => {
    return baseService.post('/api/v0/mint', payload);
};

export const getUserBalanceService = (payload) => {
    return baseService.get('/api/v0/balance?address='+payload);
};

export const payforOrderService = (payload) => {
    return baseService.post('/api/v0/order/init', payload);
};

export const completeOrderService = (payload) => {
    return baseService.post('/api/v0/order/complete', payload);
};
export const refundPaymentSellerOrderService = (payload) => {
    return baseService.post('/api/v0/order/cancelled-dispute', payload);
};

export const changePinService = (payload) => {
    return baseService.post('/api/v0/account/change-pin', payload);
};
export const pinNumberResetGenerateOTPService = (payload) => {
    return baseService1.post('pinNumberResetGenerateOTP', payload);
};

export const rdeePayCheckWalletExistsService = (payload) => {
    return baseService1.post('rdeePayCheckWalletExists', payload);
};

export const cancelledService = (payload) => {
    return baseService.post('/api/v0/order/cancelled', payload);
};

export const createSessionService = (payload) => {
    return baseService.post('testwyre/create-session', payload);
};
export const updateSessionService = (payload) => {
    return baseService.post('testwyre/update-session', payload);
};
export const checkSessionStatusService = (payload) => {
    return baseService.post('testwyre/check-session-status', payload);
};

export const createPaymentMethodService = (payload) => {
    return baseService.post('testwyre/create-payment-method', payload);
};
export const getBanksListService = (payload) => {
    return baseService.post('testwyre/get-payment-method', payload);
};

export const createTransferService = (payload) => {
    return baseService.post('testwyre/create-transfer', payload);
};









