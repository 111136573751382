import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Input, Button, Modal, ModalBody} from 'reactstrap';

import search from '../../images/icons/search.svg';
import blackmsgIcon from '../../images/icons/blackmsgIcon.png';
import chatMessageIcon from '../../images/icons/chatMessageIcon.svg';
import businessWhiteIcon from '../../images/icons/businessWhiteIcon.svg';

import crossIcon from '../../images/icons/crossIcon.svg';

import _ from 'lodash'

import profilePlaceholder from '../../images/profile_placeholder.png';


import ChatIcon from '../../images/sidebar/chat.png';
import InfoSyscoming from '../../images/comingsoon/InfoSyscoming.svg';
import leftbackarrow from '../../images/icons/leftbackarrow.png';


import './contacts.css';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Highlighter from "react-highlight-words";

import {
  getSearchUserList,
  addUserContactFunc,
  acceptContactFunc,
  resetSearchContactReducer,
  cancelSendUserContactRequest,
  deleteUserContactFunc
} from '../../actions/contacts';
import * as routes from '../../constants/routes';
import UserContactListPage from './usercontacts/userList';
import  RightSideContainer from './../../components/UI/rightSideContainer/rightSideContainer';
import { ShowNameAccordingToLength } from '../../utility/stringFunctions';
import {checkAvailableAccess} from './../../constants/localMessage/errorMessage';
import  CommonAccessContainer from './../../components/UI/commonAccessContainer/commonAccessContainer';

class ContactListPage extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      searchText: '',
      searchUserList: [],
      sendRequestmodal: false,
      sendRequestData: '',
      approveRequestmodal: false,
      deleteRequestmodal: false,
      isContactList: [],
      doSearching: false,
      isAccess: true,
      inContactListSearch:false,
      removeFromContact:false,
      globalSearchListing:[],
      loginuserID:''
    };
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if (
        nextProps.contact &&
        nextProps.contact.searchcontactList &&
        nextProps.contact.searchcontactList
      ) {
        let contactSearchData =
          nextProps.contact.searchcontactList;

         
        let isContactListing = [];
        let globalSearchListing = [];

        if (contactSearchData && contactSearchData.length > 0) {
          for (let item of contactSearchData) {
            if (item.inContactList === 'true') {
              isContactListing.push (item);
            }else{
              globalSearchListing.push (item);
            }
          }

          this.setState (
            {
              searchUserList: nextProps.contact.searchcontactList,
              isContactList: isContactListing,
              globalSearchListing:globalSearchListing
            },
            () => {
          
            }
          );
        }else{
          this.setState ({
            searchUserList: [],
            isContactList:[]
          });
        }
      } else {
        this.setState ({
          searchUserList: [],
          isContactList:[]
        });
        
      }
    }
  }
  componentWillUnmount () {
    
    if (this.state.serchItemCheck) {
      localStorage.setItem ('searchItem', this.state.serchItemCheck);
    } else {
      localStorage.removeItem ('searchItem');
      this.props.resetSearchContactReducer ();
    }
  }

  componentDidMount () {
    
    if(checkAvailableAccess('contacts') && checkAvailableAccess('contacts')==='contacts'){


    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
    // console.log(loginUser);
    this.setState({
      isAvailable:  true,
      loginuserID:loginUser.docID
    },()=>{
      if(this.state.isAvailable){
        let searchTextInput = localStorage.getItem ('searchItem');
        if (searchTextInput) {
          this.setState ({
            searchText: searchTextInput,
          });
        }
      }
    })
  }else{
    this.setState({
      isAccess: false
    })
  }

   
  }

  goToprofile (item) {
    this.setState (
      {
        serchItemCheck: this.state.searchText,
      },
      () => {
        this.props.history.push (routes.CONTACTUSERDETAIL + `/${item.docID}`);
      }
    );
  }

  changeText = event => {
    this.setState ({searchText: event.target.value});
  };

  doSearch () {
    const {inContactListSearch,searchText} = this.state;
    if(searchText.length<=0){
      return;
    }
    this.setState({
      doSearching:true
    })
    // if (value) {
    //   this.setState (
    //     {
    //       searchText: value,
    //     },
    //     () => {
    let sendRequest = {
      data: {
        searchString: this.state.searchText,
        offset: '',
        limit: 50,
        inContactListSearch: inContactListSearch
      },
    };

    if (this.state.searchText.length > 1) {
      this.props.getSearchUserList (sendRequest);
    }
    // }
    // );
    // } else {
    //   this.setState ({
    //     searchText: '',
    //   });
    // }
  }
  addNewContact (item) {
    let sendRequest = {
      data: {
        contactUID: item.docID,
      },
    };
    this.setState ({
      sendRequestmodal: true,
      sendRequestData: sendRequest,
    });

    // this.props.addUserContactFunc (this.state.sendRequestData);
  }

  goToChat (personalDetail) {
    let userChatInfo = {
      oppositeUserUid: personalDetail.docID,
      profilePictureURL: personalDetail && personalDetail.profilePictureURL
        ? personalDetail.profilePictureURL
        : profilePlaceholder,
        displayName: _.get(personalDetail,'firstName', '')+' '+ _.get(personalDetail,'lastName', '')
       
    };
    this.props.history.push (routes.MESSENGER, {userChatInfo: userChatInfo});
  }

  acceptRequest (item, status) {
    let sendRequest = {
      data: {
        messageID: item.pendingContactRequestMessageID,
        accepted: status,
      },
    };
    if (status) {
      this.setState ({
        approveRequestmodal: true,
        sendRequestData: sendRequest,
      });
    } else {
      this.setState ({
        deleteRequestmodal: true,
        sendRequestData: sendRequest,
      });
    }

    // this.props.acceptContactFunc(sendRequest);
  }

  doCancelRequest(item){
    let sendRequest={
      data:{
        messageID:item.sentContactRequestMessageID,
        targetUID:item.docID
      }
    }
    
    this.props.cancelSendUserContactRequest(sendRequest,item.docID);
  }

  removeFromContactAction(item){
    this.setState ({
      removeFromContact: true,
      sendRequestData: item,
    });
  }
  deleteFromContact () {
    const {sendRequestData,loginuserID} = this.state;
    // let sendRequest = {
    //   data: {
    //     contactUID: loginuserID,
    //     targetUID: sendRequestData.docID,

    //   },
    // };
    let sendRequest = {
      data: {
        targetUID: loginuserID,
        contactUID: sendRequestData.docID,

      },
    };
    this.props.deleteUserContactFunc (sendRequest);
  }
  

  closeModal () {
    this.setState ({
      sendRequestmodal: false,
      approveRequestmodal: false,
      deleteRequestmodal: false,
      removeFromContact:false
    });
  }

  goToBusinessprofile (businessDetail) {
    this.props.history.push (routes.BUSINESSDETAIL + `/${businessDetail.docID}`);
  }


  render () {
    const {searchUserList, searchText ,isContactList ,doSearching  ,isAccess,inContactListSearch,sendRequestData,globalSearchListing} = this.state;

    return (
      <div>
      {isAccess &&  <div className="contact-container" id="contactPage-container">
        <div className="searchContactContainer" id="contactPage-searchContactContainer">
          <div>
          <div style={{textAlign: 'right'}}>
          <Button
            className="searchContactbackbutton"
            onClick={() => this.props.history.goBack ()}
            data-rh="Go back"
            id="contactPage-searchContactbackbutton"
          >
            <div>
              <img src={leftbackarrow} alt="backIcon" />
            </div>

            Go <span> Back</span>
          </Button>
        </div>

            <div className="searchTitle"  id="contactPage-searchTitle">
              {' '}
              <span>SEARCH </span> FOR
              <span> CONTACTS</span>
            </div>
            <div className="contact-search">
              <div className="contactsearchBox">
                <div className="searchContactInputDiv" id="contactPage-searchContactInputDiv">
                <Input
                  id="contactPageSearchContacts"
                  type="text"
                  placeholder="Search by name or  handler"
                  value={this.state.searchText}
                  onChange={e => this.changeText (e)}
                  // onChange={e => this.doSearch (e.target.value)}
                />
                </div>


                <label className="isSearchContactLabel"  id="contactPageisSearchContactLabel">

                <input
                  type="radio"
                  style={{marginRight: 10}}
                  checked={inContactListSearch}
                  value={inContactListSearch}
                  onClick={e => {
                    this.setState (
                      {
                        inContactListSearch: !this.state.inContactListSearch,
                      },
                    );
                  }}
                />

                Search My Contacts Only

                </label>

                <Button
                  id="contactPagesearchButton"
                  // disabled={this.state.searchText.length <= 0}
                  className="searchButton"
                  onClick={() => this.doSearch ()}
                  data-rh="Search"
                >
                  {' '} <img src={search} alt="searchicon" style={{marginRight:28}}/> <div>Search</div>{' '}
                </Button>
              </div>
            </div>
            { searchText.length > 0 && searchUserList.length > 0
              ? <div className="searchContactViewScroll" id="contactPagesearchContactViewScroll">

              
              {!inContactListSearch && globalSearchListing.length>0&&  <div className="globalSearchBox">
              <Button className="globalSearchButton">
     
                <img
                  src={search}
                  alt="searchicon"
                />
                <div>GLOBAL <b>SEARCH</b></div> 
              </Button>
            {globalSearchListing.map ((item, index) => {
              return (
                (item.inContactList === 'false' || item.inContactList === 'pending' || item.isUser === false) &&
                <div className="searchcontactListing" key={index}  id={`contactPagesglobalContactList${index}`}>
                  <div className="searchcontactListing-image-container">
                  <div  className="searchcontactListing-imageDiv">
                    {item.isUser ?  <img
                        alt="profile Img"
                      src={
                        item && item.profilePictureURL
                          ? item.profilePictureURL
                          : profilePlaceholder
                      }
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = profilePlaceholder;
                      }}
                      className="searchcontactListing-image"
                    /> :
                    <img
                        alt="profile Img"
                      src={
                        item && item.businessRegistrationURL
                          ? item.businessRegistrationURL
                          : profilePlaceholder
                      }
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = profilePlaceholder;
                      }}
                      className="searchcontactListing-image"
                    />
                  
                  
                  }
                  </div>
                  

                  {item.isUser ?<div className="searchIscontactListing-businessiconBox">
                  {(item.statusMessage==="Active" || item.statusMessage==="active") &&<div className="userContactSearchStatusCircle" style={{background:'#4AEA87',marginTop:2}} />}
                  {item.statusMessage==="Away" &&<div className="userContactSearchStatusCircle" style={{background:'#FFBF00',marginTop:2}} />}
                  {item.statusMessage==="Do Not Disturb" &&<div className="userContactSearchStatusCircle" style={{background:'#ff0b34',marginTop:2}} />}
                  {item.statusMessage==="Invisible" &&<div className="userContactSearchStatusCircle" style={{border:'1px solid #4AEA87',marginTop:2}} />}
                 
                  </div>:
                    
                    
                    <div className="searchIscontactListing-businessiconBox">
                    <img src={businessWhiteIcon} alt="businessWhiteIcon" />
                  </div>}


                  </div>
                  {item.isUser ? <div
                    className="searchcontactListing-info"
                    id={`searchcontactsearchIscontactListing-info"${index}`}
                    onClick={() => this.goToprofile (item)}
                  >
                    <div className="searchcontactListing-name">

                      {item && item.title ? item.title +' ' : ''} {' '}
                      
                    <span style={{width:5}} />
                      <Highlighter
                      highlightClassName="lightBlueTextHiglight"
                      searchWords={[searchText]}
                      autoEscape={true}
                      textToHighlight={ShowNameAccordingToLength(item.firstName + ' ' + item.lastName)}
                    />

                      
                    </div>
                    {item &&
                      item.position &&
                      <div className="searchcontactListing-profile">
                        {item && item.position ? item.position : ''}
                      </div>}
                    {item &&
                      item.professionalTitle &&
                      <div className="searchcontactListing-moreInfo">
                        {item && item.professionalTitle ? item.professionalTitle : ''}
                        {' '}
                      </div>}
                  </div>:<div
                  className="searchcontactListing-info"
                  onClick={() => this.goToBusinessprofile (item)}
                >
                  <div className="searchcontactListing-name">

                    

                    <Highlighter
                      highlightClassName="lightBlueTextHiglight"
                      searchWords={[searchText]}
                      autoEscape={true}
                      textToHighlight={item && item.registeredBusinessName ? item.registeredBusinessName : ''}
                    />
                   
                  </div>
                  {item &&
                    item.searchHandle &&
                    <div className="searchcontactListing-profile">
                      {item && item.searchHandle ? item.searchHandle : ''}
                    </div>}
                </div>}
                  {item.isUser && item.inContactList === 'false' &&
                    item.pendingContactRequest === 'false' &&
                    <Button
                      className="addContactButtonList"
                      onClick={() => this.addNewContact (item)}
                      data-rh="Send request"
                      id={`contactPageSend_requestButton${index}`}
                    >
                      Request to Connect
                    </Button>}

                  {item.isUser && item.inContactList === 'pending' &&
                    item.pendingContactRequest === 'false' &&
                    <Button disabled={!item.sentContactRequestMessageID} 
                    id={`contactPagecancel_requestButton${index}`}
                    className="chatButton" onClick={()=>this.doCancelRequest(item)}>
                      Cancel Request
                    </Button>}

                  { item.isUser && item.inContactList === 'true' &&
                    item.pendingContactRequest === 'false' &&
                    <Button
                      className="chatButton"
                      onClick={() => this.goToChat (item)}
                      id={`contactPagechatButtonButton${index}`}
                    >
                      <img src={ChatIcon} alt="chat Iconss" />
                    </Button>}

                  {item.isUser && item.pendingContactRequest === 'true' &&
                    <div>
                      <Button
                        className="addContactButtonList"
                        onClick={() => this.acceptRequest (item, true)}
                        data-rh="Accept request"
                        id={`contactPageAcceptrequestButton${index}`}
                      >
                        Approve Contact{' '}
                      </Button>}

                      <Button
                        className="addContactButtonList"
                        onClick={() => this.acceptRequest (item, false)}
                        data-rh="Remove request"
                        id={`contactPageRemoverequestButton${index}`}

                      >
                        Remove Contact{' '}
                      </Button>
                    </div>}

                </div>
              );
            })}
          </div>}

        

                  {isContactList && isContactList.length>0 && <div className="mycontactSearchBox">
                      <div className="myContactSearchButton">
                          MY <b style={{marginLeft:10}}> CONTACTS</b>
                      </div>
                    {isContactList.map ((item, index) => {
                      return (
                        item.inContactList === 'true' &&
                        <div style={{borderBottom:isContactList.length > (index+1)?'1px solid rgba(51, 51, 51, 0.2)':0}} className="searchcontactListing" key={index}  id={`contactPagemyContactList${index}`}>
                          <div className="searchcontactListing-image-container">
                          <div  className="searchcontactListing-div" id={`searchcontactListing-div${index}`}>
                            <img
                              src={
                                item && item.profilePictureURL
                                  ? item.profilePictureURL
                                  : profilePlaceholder
                              }
                              onError={e => {
                                e.target.onerror = null;
                                e.target.src = profilePlaceholder;
                              }}
                              className="searchIscontactListing-image"
                            />
                            </div>
                          {item.isUser ?<div className="searchIscontactListing-businessiconBox">
                          {(item.statusMessage==="Active" || item.statusMessage==="active") &&<div className="userContactSearchStatusCircleSmall" style={{background:'#4AEA87',marginTop:2}} />}
                          {item.statusMessage==="Away" &&<div className="userContactSearchStatusCircleSmall" style={{background:'#FFBF00',marginTop:2}} />}
                          {item.statusMessage==="Do Not Disturb" &&<div className="userContactSearchStatusCircleSmall" style={{background:'#ff0b34',marginTop:2}} />}
                          {item.statusMessage==="Invisible" &&<div className="userContactSearchStatusCircleSmall" style={{border:'1px solid #4AEA87',marginTop:2}} />}
                         
                          </div>:
                            
                            
                            <div className="searchIscontactListing-businessiconBox">
                            <img src={businessWhiteIcon} alt="businessWhiteIcon" />
                          </div>}
                          </div>


                          <div
                          id={`searchcontact-searchIscontactListing-info"${index}`}
                            className="searchIscontactListing-info"
                            onClick={() => this.goToprofile (item)}
                          >
                            <div className="searchIscontactListing-name">

                              {item && item.title ? item.title +' ' : ''}
                              {' '}

                             

                              <Highlighter
                                highlightClassName="darkBlueTextHiglight"
                                searchWords={[searchText]}
                                autoEscape={true}
                                textToHighlight={ShowNameAccordingToLength(item.firstName + ' ' + item.lastName)}
                              />
                                      
                          
                            </div>
                            {item &&
                              item.position &&
                              <div className="searchIscontactListing-profile">
                                {item && item.position ? item.position : ''}
                              </div>}
                            {item &&
                              item.professionalTitle &&
                              <div className="searchIscontactListing-moreInfo">
                                {item && item.professionalTitle ? item.professionalTitle : ''}
                                {' '}
                              </div>}
                          </div>

                          <div
                            className="searchIscontactListing-info"
                            onClick={() => this.goToprofile (item)}
                          >
                          
                            {<div style={{marginTop:(item && (item.position   || item.professionalTitle))?  20 :0,textTransform:'capitalize'}} className="searchIscontactListing-moreInfo">
                              Status: <b> {item && item.statusMessage ? item.statusMessage : ''}</b>
                              </div>}
                            {item &&
                              item.taglineMessage &&
                              <div style={{marginTop:5}} className="searchIscontactListing-moreInfo">
                                {item && item.taglineMessage ? `“${item.taglineMessage}”` : ''}
                                {' '}
                              </div>}
                          </div>

                            

                            <Button
                              className="IsContactActionButton"
                              onClick={() => this.removeFromContactAction (item)}
                              data-rh="Remove"
                              id={`contactPageRemoveButton${index}`}

                            >
                            <img src={crossIcon} alt="chatMessageIcon" />
                            </Button>
                            <Button
                              className="IsContactActionButton"
                              onClick={() => this.goToChat (item)}
                              data-rh="Message"
                              id={`contactPageMessageButton${index}`}
                            >
                              <img src={chatMessageIcon} alt="chatMessageIcon" />
                            </Button>

                        </div>
                      );
                    })}
                  </div>}
</div>
              : <div className="no-searchcontactListing">
                  {' '}
                  {doSearching? 'No Data Found':''}
                </div>}
          </div>
         
        </div>
        <RightSideContainer>
        <UserContactListPage history={this.props.history} />
        </RightSideContainer>

        {/*<div className="contact-container-parent blueBg">
          <UserContactListPage history={this.props.history} />
                  </div>*/}

        <Modal
          size="sm"
          isOpen={this.state.sendRequestmodal}
          centered={true}
          toggle={() => this.closeModal ()}
          backdropClassName="contactModalBackdrops"
          id="contactPageRequestrequestModal"
        >
          <ModalBody className="requestConfirmationModal" id="contactPagerequestConfirmationModal">
            <div>

              <p className="confirmTitle"  id="contactPagerequestconfirmTitle">Send Request?</p>
              <p className="confirmmsg" id="contactPagerequestconfirmmsg">
                Do you wish to send a request to add this person to your Contacts?
              </p>
              <div className="buttonDiv">
                <Button
                  className="buttons noButton"
                  onClick={() => this.closeModal ()}
                  id="contactPagerequestConfirmationModalnoButton"
                >
                  No
                </Button>
                <Button
                 id="contactPagerequestConfirmationModalyesButton"

                  className="buttons yesButton"
                  onClick={() => {
                    setTimeout (() => {
                      this.closeModal ();
                    }, 2000);
                    this.props.addUserContactFunc (this.state.sendRequestData);
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size="sm"
          isOpen={this.state.approveRequestmodal}
          centered={true}
          toggle={() => this.closeModal ()}
          backdropClassName="contactModalBackdrops"
          id="contactPageRequestrequestModal1"
        >
          <ModalBody className="requestConfirmationModal" id="contactPagerequestConfirmationModal1" id="contactPagerequestconfirmmsg1">
            <div>

              <p className="confirmTitle"  id="contactPagerequestconfirmTitle1">Add Contact?</p>
              <p className="confirmmsg" id="contactPagerequestconfirmmsg1">
                Do you wish to add this person to your contact list?
              </p>
              <div className="buttonDiv">
                <Button
                  className="buttons noButton"
                  id="contactPagerequestConfirmationModalnoButton1"
                  onClick={() => this.closeModal ()}
                >
                  No
                </Button>
                <Button
                  id="contactPagerequestConfirmationModalyesButton1"
                  className="buttons yesButton"
                  onClick={() => {
                    setTimeout (() => {
                      this.closeModal ();
                    }, 2000);
                    this.props.acceptContactFunc (this.state.sendRequestData);
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size="sm"
          isOpen={this.state.deleteRequestmodal}
          centered={true}
          toggle={() => this.closeModal ()}
          backdropClassName="contactModalBackdrops"
          id="contactPageRequestrequestModal2"
        >
          <ModalBody className="requestConfirmationModal" id="contactPagerequestConfirmationModal2">
            <div>

              <p className="confirmTitle"  id="contactPagerequestconfirmTitle2">Remove Contact Request</p>
              <p className="confirmmsg" id="contactPagerequestconfirmmsg2">
                Do you wish to permanently remove this contact request?
              </p>
              <div className="buttonDiv">
                <Button
                id="contactPagerequestConfirmationModalnoButton2"

                  className="buttons noButton"
                  onClick={() => this.closeModal ()}
                >
                  No
                </Button>
                <Button
                  className="buttons yesButton"
                  id="contactPagerequestConfirmationModalyesButton2"
                  onClick={() => {
                    setTimeout (() => {
                      this.closeModal ();
                    }, 2000);
                    this.props.acceptContactFunc (this.state.sendRequestData);
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>



        <Modal
          size="sm"
          isOpen={this.state.removeFromContact}
          centered={true}
          toggle={() => this.closeModal ()}
          backdropClassName="contactModalBackdrops"
          id="contactPageRequestrequestModal3"
        >
          <ModalBody className="requestConfirmationModal" id="contactPagerequestConfirmationModal3">
            <div>

              <p className="confirmTitle"  id="contactPagerequestconfirmTitle3">Remove Contact</p>
              {sendRequestData && <p className="confirmmsg" id="contactPagerequestconfirmmsg3">
                Do you wish to permanently remove  {ShowNameAccordingToLength(sendRequestData.firstName + ' ' + sendRequestData.lastName)} from your Contacts?
              </p>}
              <div className="buttonDiv">
                <Button
                  id="contactPagerequestConfirmationModalnoButton3"

                  className="buttons noButton"
                  onClick={() => this.closeModal ()}
                >
                  No
                </Button>
                <Button
                  className="buttons yesButton"
                  id="contactPagerequestConfirmationModalyesButton3"
                  onClick={() => {
                    setTimeout (() => {
                      this.closeModal ();
                    }, 2000);
                    this.deleteFromContact ();
                  }}
                >
                  Yes
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

      </div>}

      
      {!isAccess  && <CommonAccessContainer bgImage={InfoSyscoming} message={checkAvailableAccess('contacts')} />}


      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    contact: state.ContactsReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getSearchUserList,
      addUserContactFunc,
      acceptContactFunc,
      resetSearchContactReducer,
      cancelSendUserContactRequest,
      deleteUserContactFunc
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (ContactListPage);
