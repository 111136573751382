import {LOGOUT} from './Authreducer';

let initialState = {
  filesList: [],
  filedownload: null,
};

export const GET_FILES = 'GET_FILES';
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS';
export const STARRED_SUCCESS = 'STARRED_SUCCESS';
export const CREATE_NEW_FOLDER = 'CREATE_NEW_FOLDER';
export const DELETED_FILE_DATA = 'DELETED_FILE_DATA';
export const GET_LATEST_FILES_DETAIL = 'GET_LATEST_FILES_DETAIL';
export const DELETED_BULK_FILE_DATA = 'DELETED_BULK_FILE_DATA';
export const EXTERNAL_FILE_SHARE = 'EXTERNAL_FILE_SHARE';
export const SHARED_FILE_SUCCESS = 'SHARED_FILE_SUCCESS';
export const SHARED_WITH_ME_FILE_SUCCESS = 'SHARED_WITH_ME_FILE_SUCCESS';







export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FILES:
      return {...state, filesList: action.payload, filedownload: null};
    case DOWNLOAD_SUCCESS:
      return {...state, filedownload: action.payload};
    case STARRED_SUCCESS:
      let updateListing;

      if (state.filesList && state.filesList) {
        for (let items of state.filesList) {
          for(let selectedItems of action.payload){
            if(items.docID ===selectedItems.docID){
              items.starred = selectedItems.star;
            }
          }
        }
      }
      updateListing = state.filesList;

      return {...state, filesList: state.filesList, filedownload: null};


      

      case SHARED_FILE_SUCCESS:
      let updateFileListing;

      if (state.filesList && state.filesList) {
        for (let items of state.filesList) {
          // for(let selectedItems of action.payload.docID){
            if(items.docID ===action.payload.docID){
              items.sharedUIDList = action.payload.userShareList;
            }
          // }
        }
      }
      updateListing = state.filesList;

      return {...state, filesList: state.filesList, filedownload: null};



    case CREATE_NEW_FOLDER:
      let newFolderListing;

      newFolderListing = state.filesList.concat (action.payload);

      return {...state, filesList: newFolderListing};

    case DELETED_FILE_DATA:
      let index = state.filesList.findIndex (
        item => item.docID === action.payload.docID
      );
      if (index > -1) {
        state.filesList.splice (index, 1);
      }
      return {...state, filesList: state.filesList};

      case DELETED_BULK_FILE_DATA:
      return {...state, filesList:  action.payload};
    case GET_LATEST_FILES_DETAIL:
    
      if (action.payload) {
        if(action.payload.filePath){

        let index = state.filesList.findIndex (
          items => items.fullPath === action.payload.filePath
        );
        if (index > -1) {
          state.filesList[index] = action.payload;
        }
      }
      if(action.payload.filePath){
        let index1 = state.filesList.findIndex (
          items => items.fullPath === action.payload.filePath
        );
        if (index1 > -1) {
          state.filesList[index1] = action.payload;
        }
      }
      }

      return {
        ...state,
        filesList: state.filesList,
        filedownload: null,
        newupdatedItems: action.payload,
      };

    case EXTERNAL_FILE_SHARE:
       
        if(action && action.payload && action.payload.docID){
          let indexFileStatus = state.filesList.findIndex (
            items => items.docID === action.payload.docID
          );
          if (indexFileStatus > -1) {
            state.filesList[indexFileStatus].shareFile = action.payload.shareFile;
          }
        }

      return {...state, externalSharing: action.payload , filesList: state.filesList};
      case SHARED_WITH_ME_FILE_SUCCESS:
      return {...state, sharedWithme: action.payload};

      
      
    case LOGOUT:
      return {...initialState};
    default:
      return state;
  }
};
