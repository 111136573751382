import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button } from 'reactstrap'
import * as routes from '../../../../constants/routes'
import './browseProduct.css'
import leftbackarrow from '../../../../images/icons/leftbackarrow.png'
import MarketPlaceSidemenu from '../marketplaceSidemenu/marketSideMenu'
import RightSideContainer from '../../../../components/UI/rightSideContainer/rightSideContainer'

import productDetails from '../../../../images/marketPlaceBuyer/productDetails.png'
import nonradioPlaceholder from '../../../../images/marketPlaceBuyer/nonradioPlaceholder.png'

import {
  getProductListing,
  fetchProductManufacturer,
  getSearchProductListing
} from '../../../../actions/marketplaceaction'
import { Dropdown } from 'primereact/dropdown'
import { priceWithDecimal } from '../../../../utility/commonFunctions'
import SelectInput from '../../../InputCustom/selectInput/selectDropdown'


const sortBy = [
  { label: 'Newest Arrivals', value: 'Newest Arrivals' },
  { label: 'Price: Low to High', value: 'Price: Low to High' },
  { label: 'Price: High to Low', value: 'Price: High to Low' }
]

class BrowseProduct extends Component {
  constructor (props) {
    super(props)
    this.state = {
      browsedeviceType: '',
      browselocation: '',
      browsemanufacture: '',
      browseprice: '',
      productListing: [],
      tempSaveProductList: [],
      sortByValue: '',
      priceItemCollection: [
        { label: '$0', value: '0' },
        { label: '$100', value: '100' },
        { label: '$1000', value: '1000' },
        { label: '$10000', value: '10000' },
        { label: '$50000', value: '50000' },
        { label: '$100000', value: '100000' },
        { label: '$200000', value: '200000' },
        { label: '$300000', value: '300000' },
        { label: '$400000', value: '400000' },
        { label: '$500000', value: '500000' },
        { label: '$600000', value: '600000' },
        { label: '$600000+', value: '600000+' }
      ],
      selectedMinIndex: 1,
      manufactureCollection: [],
      productCategoryTypeOptions: [],
      productCategoryType: '',
      manufactureItemInfo: [],
      browsemaxprice: '',
      manufactureOptions: [],
      deviceTypeOptions: [],
      locationOptions: []
    }
    this.doSorting = this.doSorting.bind(this)
  }
  goDetailPage = item => {
    this.props.history.push(routes.PRODUCT_DETAIL + `/${item.docID}`)
  }
  componentDidMount () {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.searchObject
    ) {
      this.props.fetchProductManufacturer()
      let getData = this.props.location.state.searchObject
      this.setState(
        {
          browsedeviceType: getData.productType ? getData.productType : '',
          browselocation: getData.location ? getData.location : '',
          browsemanufacture: getData.manufacturer ? getData.manufacturer : '',
          browseprice: getData.minPrice ? getData.minPrice : '',
          browsemaxprice: getData.maxPrice ? getData.maxPrice : '',
          productCategoryType: getData.productCategoryType
            ? getData.productCategoryType
            : ''
        },
        () => {
          this.doSearchItems()
        }
      )
    }
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      /* Product Dynamic dropdown values Start */
      if (nextProps.product && nextProps.product.productCategorisedValues) {
        const obj = nextProps.product.productCategorisedValues

        if (obj && obj['non-radiology']) {
          // console.log(obj['non-radiology']);
          let nonRadioItemValues = obj['non-radiology']
            ? obj['non-radiology']
            : ''
          if (nonRadioItemValues) {
            let countryOriginList = []
            let nonRadioCategoryList = []
            if (nonRadioItemValues.countryOfOrigin) {
              Object.entries(nonRadioItemValues.countryOfOrigin).forEach(
                ([key, value]) => {
                  // console.log(key, value);
                  countryOriginList.push({
                    label: value,
                    value: value
                  })
                }
              )
            }
            if (nonRadioItemValues.category) {
              Object.entries(nonRadioItemValues.category).forEach(
                ([key, value]) => {
                  // console.log(key, value);
                  nonRadioCategoryList.push({
                    label: value,
                    value: value
                  })
                }
              )
            }

            this.setState(
              {
                countryOriginList: countryOriginList,
                nonRadioCategoryList: nonRadioCategoryList
              },
              () => {
                // console.log(this.state.countryOriginList);
                // console.log(this.state.nonRadioItemConditions);
                // console.log(this.state.expirationProductOptions);
                // console.log(this.state.nonRadioCategoryList);
              }
            )
          }
        }
        if (obj && obj.radiology) {
          let productManufactureOptions = []
          let deviceTypeOptions = []
          let locationOptions = []
          if (obj.radiology.manufacturer) {
            Object.entries(obj.radiology.manufacturer).forEach(
              ([key, value]) => {
                // console.log(key, value);
                productManufactureOptions.push({
                  label: value,
                  value: value
                })
              }
            )
          }

          if (obj.radiology.deviceType) {
            Object.entries(obj.radiology.deviceType).forEach(([key, value]) => {
              // console.log(key, value);
              deviceTypeOptions.push({
                label: value,
                value: value
              })
            })
          }

          if (obj.radiology.location) {
            Object.entries(obj.radiology.location).forEach(([key, value]) => {
              // console.log(key, value);
              locationOptions.push({
                label: value,
                value: value
              })
            })
          }
          this.setState(
            {
              manufactureOptions: productManufactureOptions,
              deviceTypeOptions: deviceTypeOptions,
              locationOptions: locationOptions
            },
            () => {}
          )
        }
      }
      /* Product Dynamic dropdown values Close */

      if (nextProps.product && nextProps.product.manufactureItemValues) {
        const obj = nextProps.product.manufactureItemValues
        let productCategoryTypeOptions = []
        Object.keys(obj).map(key => {
          productCategoryTypeOptions.push({ label: key, value: key })
        })
        this.setState(
          {
            productCategoryTypeOptions: productCategoryTypeOptions
            // manufactureCollection:obj
          },
          () => {
            console.log(this.state.productCategoryTypeOptions)
          }
        )
      }

      if (
        nextProps &&
        nextProps.product &&
        nextProps.product.productlist &&
        nextProps.product.productlist
      ) {
        this.setState(
          {
            productListing: nextProps.product.productlist
          },
          () => {
            this.setState({
              tempSaveProductList: nextProps.product.productlist
            })
          }
        )
        // console.log(nextProps.product.productlist);
        // this.props.history.push (routes.MARKETPLACESEARCH ,{'issearch': true});
      }
    }
  }

  doSearchItems () {
    const {
      browsedeviceType,
      browselocation,
      browsemanufacture,
      browseprice,
      productCategoryType,
      browsemaxprice
    } = this.state

    // const sendRequest = {
    //   data: {
    //     productCategoryType:productCategoryType?productCategoryType:'',
    //     productType: browsedeviceType?browsedeviceType:'',
    //     manufacturer: browsemanufacture?browsemanufacture:'',
    //     location: browselocation?browselocation:'',
    //     minPrice: browseprice?browseprice:'',
    //     maxPrice: browsemaxprice?browsemaxprice:'',

    //   },
    // };

    // this.props.getProductListing (sendRequest);

    let sendRequest = {}
    if (
      productCategoryType &&
      productCategoryType.toLowerCase() === 'radiology'
    ) {
      sendRequest = {
        data: {
          productCategoryType: productCategoryType,
          productType: browsedeviceType ? [browsedeviceType] : '',
          manufacturer: browsemanufacture ? [browsemanufacture] : '',
          location: browselocation,
          minPrice: browseprice,
          maxPrice: browsemaxprice
        }
      }
    } else {
      sendRequest = {
        data: {
          productCategoryType: productCategoryType,
          category: browsedeviceType ? [browsedeviceType] : '',
          countryOriginValue: browselocation,
          minPrice: browseprice,
          maxPrice: browsemaxprice
        }
      }
    }
    // this.props.getProductListing (sendRequest);
    this.props.getSearchProductListing(sendRequest)
  }

  doSorting () {
    this.setState(
      {
        productListing: []
      },
      async () => {
        if (this.state.sortByValue === 'Newest Arrivals') {
          this.setState({
            productListing: this.state.tempSaveProductList.sort((a, b) => {
              if (a.productListedDateAtISO) {
                var dateA = new Date(a.productListedDateAtISO).getTime()
                var dateB = new Date(b.productListedDateAtISO).getTime()
                return dateB > dateA ? 1 : -1
                // return   new Date(b.productListedDateAtISO) - new Date(a.productListedDateAtISO)
              }
            })
          })
        }
        if (this.state.sortByValue === 'Price: Low to High') {
          this.setState({
            productListing: this.state.tempSaveProductList.sort(
              (a, b) => Number(a.priceUSD) - Number(b.priceUSD)
            )
          })
        }
        if (this.state.sortByValue === 'Price: High to Low') {
          this.setState({
            productListing: this.state.tempSaveProductList.sort(
              (a, b) => Number(b.priceUSD) - Number(a.priceUSD)
            )
          })
        }
      }
    )
  }

  render () {
    const {
      browsedeviceType,
      browselocation,
      browsemanufacture,
      browseprice,
      productListing,
      sortByValue,
      productCategoryType,
      browsemaxprice
    } = this.state

    return (
      <div className='productBrowseContainer'>
        <div>
          <div style={{ textAlign: 'right' }}>
            <Button
              className='marketproducbackbutton'
              onClick={() => this.props.history.goBack()}
            >
              <div>
                <img src={leftbackarrow} alt='backIcon' />
              </div>
              Go <span> Back</span>
            </Button>
          </div>
          <div className='maindivbrowsese'>
            <div className='Browsinxsdcg'>Browsing Marketplace</div>
            <div className="browseMarketPlaceFilters">
            <div className="browseMarketPlaceFiltersBoxes">
              <SelectInput
                items={this.state.productCategoryTypeOptions}
                value={this.state.productCategoryType}
                className='marketBrowseProductinputDropdown'
                dropDownContainerClass='marketBrowseProductinputContainerDropdown'
                onChange={value => {
                  this.setState({
                    productCategoryType: value,
                    browsedeviceType: '',
                    browsemanufacture: '',
                    browselocation: ''
                  })
                }}
                placeholder='Product Category'
              />
              </div>

              {this.state.productCategoryType && (
                 <div className="browseMarketPlaceFiltersBoxes">
                <SelectInput
                  items={
                    this.state.productCategoryType
                      ? this.state.productCategoryType === 'Radiology'
                        ? this.state.deviceTypeOptions
                        : this.state.nonRadioCategoryList
                      : []
                  }
                  value={browsedeviceType}
                  className='marketBrowseProductinputDropdown'
                  dropDownContainerClass='marketBrowseProductinputContainerDropdown'
                  onChange={value => {
                    this.setState(
                      {
                        browsedeviceType: value
                      },
                      () => {
                        this.doSearchItems()
                      }
                    )
                  }}
                  placeholder={
                    this.state.productCategoryType === 'Radiology'
                      ? 'Product Type'
                      : 'Category'
                  }
                />
                </div>
              )}

              {this.state.productCategoryType && (
                 <div className="browseMarketPlaceFiltersBoxes">
                <SelectInput
                  items={
                    this.state.productCategoryType
                      ? this.state.productCategoryType === 'Radiology'
                        ? this.state.locationOptions
                        : this.state.countryOriginList
                      : []
                  }
                  value={browselocation}
                  className='marketBrowseProductinputDropdown'
                  dropDownContainerClass='marketBrowseProductinputContainerDropdown'
                  onChange={value => {
                    this.setState(
                      {
                        browselocation: value
                      },
                      () => {
                        this.doSearchItems()
                      }
                    )
                  }}
                  placeholder={
                    this.state.productCategoryType &&
                    this.state.productCategoryType === 'Radiology'
                      ? 'Location'
                      : 'Country Of Origin'
                  }
                />
                </div>
              )}

              {this.state.productCategoryType &&
                this.state.productCategoryType === 'Radiology' && (
                  <div className="browseMarketPlaceFiltersBoxes">
                <SelectInput
                  items={
                    this.state.productCategoryType &&
                      this.state.productCategoryType === 'Radiology'
                      ? this.state.manufactureOptions
                      : []
                  }
                  value={browsemanufacture}
                  className='marketBrowseProductinputDropdown'
                  dropDownContainerClass='marketBrowseProductinputContainerDropdown'
                  onChange={value => {
                    this.setState(
                      {
                        browsemanufacture: value
                      },
                      () => {
                        this.doSearchItems()
                      }
                    )
                  }}
                  placeholder='Manufacturer'
                />
                </div>
              )}
 <div className="browseMarketPlaceFiltersBoxes">
              <SelectInput
                items={this.state.priceItemCollection.slice(0, 10)}
                value={browseprice}
                className='marketBrowseProductinputDropdown'
                dropDownContainerClass='marketBrowseProductinputContainerDropdown'
                onChange={value =>
                  this.setState({ browseprice: value }, () => {
                    var findItemindex = this.state.priceItemCollection
                      .map(img => {
                        return img.value
                      })
                      .indexOf(this.state.browseprice)
                    this.setState(
                      {
                        selectedMinIndex: findItemindex + 1
                      },
                      () => {
                        this.doSearchItems()
                      }
                    )
                  })
                }
                placeholder='Price (Min)'
              />
              </div>
              <div className="browseMarketPlaceFiltersBoxes">
              <SelectInput
                items={this.state.priceItemCollection.slice(
                  this.state.selectedMinIndex,
                  20
                )}
                value={browsemaxprice}
                className='marketBrowseProductinputDropdown'
                dropDownContainerClass='marketBrowseProductinputContainerDropdown'
                onChange={value =>
                  this.setState({ browsemaxprice: value }, () => {
                    this.doSearchItems()
                  })
                }
                placeholder='Price (Max)'
              />
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: 10,
                // width: 916,
                justifyContent: 'space-between'
              }}
              className="browseMarketPlaceFilters"
            >
              <div className='arketplaceresults'>
                {productListing && productListing.length} Marketplace results
                for
                {productCategoryType && (
                  <span> Product Category: {productCategoryType} ,</span>
                )}
                {browsedeviceType && (
                  <span>
                    {' '}
                    {this.state.productCategoryType &&
                    this.state.productCategoryType === 'Radiology'
                      ? 'Product Type'
                      : 'Category'}
                    : {browsedeviceType} ,
                  </span>
                )}
                {browsemanufacture &&
                  this.state.productCategoryType &&
                  this.state.productCategoryType === 'Radiology' && (
                  <span> Manufacturer: {browsemanufacture} ,</span>
                )}
                {browselocation && (
                  <span>
                    {' '}
                    {this.state.productCategoryType &&
                    this.state.productCategoryType === 'Radiology'
                      ? 'Location'
                      : 'Country of origin'}
                    : {browselocation} ,
                  </span>
                )}
                {browseprice && <span> Price(Min): {browseprice} ,</span>}
                {browsemaxprice && <span> Price(Max): {browsemaxprice}</span>}
              </div>
              <div>
            

                <SelectInput
                items={sortBy}
                value={sortByValue}
                className='marketBrowseProductSortByinput'
                dropDownContainerClass='marketBrowseProductSortByinputContainerDropdown'
                onChange={value =>
                  this.setState({ sortByValue: value }, () => {
                    console.log(value);
                    this.doSorting()
                  })
                }
                isBlackArrow={true}
                placeholder='Sort by: Newest Arrivals'
              />
              </div>
            </div>
            {productListing && productListing.length === 0 && (
              <div className='noBrowseData'>Product Not Found</div>
            )}
            <div className='productHorizontalListView'>
              {productListing &&
                productListing.length > 0 &&
                productListing.map((item, i) => (
                  <div
                    key={i}
                    className='markeimagebox'
                    onClick={() => this.goDetailPage(item)}
                  >
                    <div>
                      <img
                        src={
                          item &&
                          item.productImageList &&
                          item.productImageList.length > 0
                            ? item.productImageList[0]
                            : item &&
                              item.productCategoryType &&
                              item.productCategoryType === 'Radiology'
                              ? productDetails
                              : nonradioPlaceholder
                        }
                        onError={e => {
                          e.target.onerror = null
                          e.target.src =
                            item &&
                            item.productCategoryType &&
                            item.productCategoryType === 'Radiology'
                              ? productDetails
                              : nonradioPlaceholder
                        }}
                        alt={item.systemName}
                        style={{ height: 209, width: 209 }}
                      />
                    </div>
                    <div
                      style={{
                        background: '#4453D6',
                        borderBottomRightRadius: 10,
                        borderBottomLeftRadius: 10,
                        paddingBottom: 10
                      }}
                    >
                      <p className='siemenavantomarke'>
                        {item &&
                        item.productCategoryType &&
                        item.productCategoryType === 'Radiology'
                          ? item.deviceSaleName
                          : item.productName
                            ? item.productName
                            : ''}
                        <br />
                        <span>
                          {' '}
                          USD${' '}
                          {item && item.priceUSD
                            ? priceWithDecimal(item.priceUSD)
                            : ''}
                        </span>
                      </p>
                      <p
                        className='DeviceType'
                        id={`placeorderProductTempSliderBoxcontentProdTypeVal${
                          item.docID
                        }`}
                      >
                        Product Type:{' '}
                        {item && productCategoryType
                          ? item.productCategoryType
                          : 'N/A'}
                      </p>
                      <p className='DeviceType'>
                        Device Type:{' '}
                        {item && item.deviceType ? item.deviceType : ''}
                      </p>
                      <p className='LocCafornian'>
                        Location: {item && item.location ? item.location : ''}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <RightSideContainer>
          <MarketPlaceSidemenu />
        </RightSideContainer>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    product: state.MarketplaceReducer
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getProductListing,
      fetchProductManufacturer,
      getSearchProductListing
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrowseProduct)
