
import firebase from 'firebase'
import { userSharedKey, CalculatedSharedSecret, encryptMessage } from '../../utility/encryption';
import { capitalfirstLetter } from "../../utility/stringFunctions";
import _ from 'lodash';

// creating P2P  Secret key
export const  _handleP2PSecretKey = async (UserOwnDocID, oppoUserID) =>  {
    const database = firebase.database();
    return new Promise(async (resolve) => {
        await database.ref(`Users/${UserOwnDocID}/onlineStatus`).on("value", async  snapShot => {
            let userOwnPublicKey = snapShot.child('publicKey').val();
            await database.ref(`Users/${oppoUserID}/onlineStatus`).on("value", snapShot => {
              let oppositeUserPublicKey = snapShot.child('publicKey').val();
              let oppositeSharedKey = userSharedKey(oppositeUserPublicKey)
              let finalSecretKey = CalculatedSharedSecret(oppositeSharedKey, userOwnPublicKey)
              resolve(finalSecretKey);
            })
          })
    })

  }

  // creating Group  Secret key
  export const _handleGroupSecretKey = (groupPublicKey) => {

    return new Promise((resolve) => {
      let groupSharedKey = userSharedKey(groupPublicKey)
      let finalSecretKey = CalculatedSharedSecret(groupSharedKey, groupPublicKey)
        resolve(finalSecretKey);
      });

  }

  // fethcing group data from firebase 
  export const fetchGroupDataFromFirebase = async (groupId) => {

    const database = firebase.database();
    const conversationRef = database.ref(`GroupChatDetail/${groupId}`);
    return new Promise((resolve) => {
      // Get details for this conversation id
      conversationRef.on("value", snapshot => {
        resolve(snapshot.val());
        // console.log("snapshot.val()>>>>>fetchGroupDataFromFirebase", snapshot.val())
      })
    });
  }

  export const  onFocusTyping = async (obj) => {
      let messengerKey = obj.messengerKey,
       userDetail = obj.userDetail,
       text = obj.text,
       groupChat = obj.groupChat;
       let chatThread = groupChat ? 'GroupMessages' : "Messages"
    //   console.log("messengerKey>>>>>", messengerKey,"userDetail>>>>",userDetail,"text>>>>", text )
    const database = firebase.database();
    let displayName = userDetail.personalDetails.firstName;
    let senderId = userDetail.personalDetails.docID;
    if (text.length > 0) {
      await database.ref(`${chatThread}/${messengerKey}`).update({
        'isTyping': {
          "userName": capitalfirstLetter(displayName),
          'userTypingId': senderId,
          "isUserTyping": true
        }
      })
    } else {
        await database.ref(`${chatThread}/${messengerKey}`).update({
            'isTyping': {
              "userName": '',
              'userTypingId': "noUser",
              "isUserTyping": false
            }
          })
    }
  }

  export const  onBlurTyping = async (obj) => {
      let messengerKey = obj.messengerKey,
      groupChat = obj.groupChat;
      let chatThread = groupChat ? 'GroupMessages' : "Messages"

    const database = firebase.database();
    await database.ref(`${chatThread}/${messengerKey}`).update({
      'isTyping': {
        "userName": '',
        'userTypingId': "noUser",
        "isUserTyping": false
      }
    })
  }