import axios from 'axios';
import store,{history} from '../store';
import {LOGOUT} from "../reducers/Authreducer";
import { createNotification} from '../constants/notificationtoast';

import {RDEEAPI_URL } from './servicesConfig';



const baseService = axios.create({baseURL:RDEEAPI_URL});

baseService.interceptors.request.use((config)=>{
    const currentState = store.getState();
    const { Authreducer } = currentState;

    if(
        Authreducer &&
        Authreducer.authToken
    ) {
        config.headers['Authorization'] = "Bearer " + Authreducer.authToken;
    }else{
        delete config.headers['Authorization'];

    }
    if(config && config.url && config.url==="vaultCreateExternalShareLink"){
        delete config.headers['Authorization'];
    }
    return config;
}, error => {
    return Promise.reject(error);
});

baseService.interceptors.response.use((config)=>{
    return config;
},error => {
    if(error.response && error.response.status === 401){
       

        if(error.response.data && error.response.data.error){
            createNotification('error', error.response.data.error.message);
            localStorage.clear();
            setTimeout(()=>{
             window.location.reload();
            },1000)
        }
    }
});
export default baseService;