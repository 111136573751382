import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Input} from 'reactstrap';
import {
  getProfileDashboard,
  userSettingsModifySearchSettings,
} from '../../../actions/Authaction';

import './searchSetting.css';

class SearchSetting extends Component {
  constructor (props) {
    super (props);
    this.state = {
      searchHandle: false,
      phoneNumber: false,
      emailAddress: false,
      businessProfile: false,
    };
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if (
        nextProps.userInfo &&
        nextProps.userInfo.dashboardprofile &&
        nextProps.userInfo.dashboardprofile.userSettings
      ) {
        if (
          nextProps.userInfo.dashboardprofile.userSettings &&
          nextProps.userInfo.dashboardprofile.userSettings.searchSettings
        ) {
          const getData =
            nextProps.userInfo.dashboardprofile.userSettings.searchSettings;
          this.setState ({
            searchHandle: getData.searchHandle ||
              getData.searchHandle !== 'undefined'
              ? getData.searchHandle
              : false,
            phoneNumber: getData.phoneNumber ||
              getData.phoneNumber !== 'undefined'
              ? getData.phoneNumber
              : false,
            emailAddress: getData.emailAddress ||
              getData.emailAddress !== 'undefined'
              ? getData.emailAddress
              : false,
            businessProfile: getData.businessProfile ||
              getData.businessProfile !== 'undefined'
              ? getData.businessProfile
              : false,
          });
        }
      }
    }
  }
  componentDidMount () {
    let sendRequestUserProfile = {
      data: {},
    };
    this.props.getProfileDashboard (sendRequestUserProfile);
  }

  submitProfile () {
    const {
      searchHandle,
      phoneNumber,
      emailAddress,
      businessProfile,
    } = this.state;
    const sendRequest = {
      data: {
        searchHandle: searchHandle ? searchHandle : false,
        phoneNumber: phoneNumber ? phoneNumber : false,
        emailAddress: emailAddress ? emailAddress : false,
        businessProfile: businessProfile ? businessProfile : false,
      },
    };
    this.props.userSettingsModifySearchSettings (sendRequest);
  }

  render () {
    const {
      searchHandle,
      phoneNumber,
      emailAddress,
      businessProfile,
    } = this.state;
    return (
      <div style={{display: 'flex'}}>
        <div className="searchmainmaindiv">
          <div className="searcificmhding">
            {' '}
            SEARCH SETTINGS
          </div>
          <div className="Otheruser">
            Other users can search for you by:
          </div>
          <div className="OFFON">
            OFF<span>/ON</span>
          </div>
          <div className="contetToggleBoxArea">
            <p className="SearchHand">Search Handle</p>

            <label className="switch">
              <input
                type="checkbox"
                value={searchHandle}
                checked={searchHandle}
                onChange={() => {
                  this.setState ({
                    searchHandle: !searchHandle,
                  });
                }}
              />
              <span className="slider round" />
            </label>
          </div>
          <div className="contetToggleBoxArea">
            <p className="PhoneNumb">Phone Number</p>
            <label className="switch">
              <input
                type="checkbox"
                value={phoneNumber}
                checked={phoneNumber}
                onChange={() => {
                  this.setState ({
                    phoneNumber: !phoneNumber,
                  });
                }}
              />
              <span className="slider round" />
            </label>
          </div>
          <div className="contetToggleBoxArea">
            <p className="EmailID">Email ID</p>
            <label className="switch">
              <input
                type="checkbox"
                value={emailAddress}
                checked={emailAddress}
                onChange={() => {
                  this.setState ({
                    emailAddress: !emailAddress,
                  });
                }}
              />
              <span className="slider round" />
            </label>
          </div>
          <div className="contetToggleBoxArea">
            <p className="BusinessProfi">Business Profile</p>
            <label className="switch">
              <input
                type="checkbox"
                value={businessProfile}
                checked={businessProfile}
                onChange={() => {
                  this.setState ({
                    businessProfile: !businessProfile,
                  });
                }}
              />
              <span className="slider round" />
            </label>
          </div>

          <div style={{textAlign: 'center'}}>
            <Button
              className="searchanbutton"
              onClick={() => this.submitProfile ()}
              data-rh="Save"
            >
              Save Changes
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    userInfo: state.Authreducer,
    userprofile: state.ContactsReducer,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getProfileDashboard,
      userSettingsModifySearchSettings,
    },
    dispatch
  );
};
export default connect (mapStateToProps, mapDispatchToProps) (SearchSetting);
