import React, {Component} from 'react';
import {Modal, ModalBody, Button} from 'reactstrap';

import locIcon from '../../../images/loc.png';


import profilePlaceholder from './../../../images/profile_placeholder.png';
import evruslogobusi from './../../../images/icons/evruslogobusi.png';
import mail from '../../../images/profile/mail.png';
import call from '../../../images/profile/call.png';
import Arrow from '../../../images/profile/arrow.png';
import businessAdmin from '../../../images/icons/yellowcrown.png';
import leftbackarrow from '../../../images/icons/leftbackarrow.png';
import businessContactIcon from '../../../images/icons/businessContactIcon.svg';
import defaultContactRequest  from '../../../images/icons/defaultContactRequest.svg';


import {getBusinessDetail, addUserContactFunc ,cancelSendUserContactRequest} from '../../../actions/contacts';
import * as routes from '../../../constants/routes';
import UserContactListPage
  from '../../../components/contacts/usercontacts/userList';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import  RightSideContainer from '../../../components/UI/rightSideContainer/rightSideContainer';
import {ShowNameAccordingToLength} from '../../../utility/stringFunctions';
import './businessdetail.css';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";
import { compose } from "recompose";

var google = window.google;


const MapWithAMarker = compose(withScriptjs, withGoogleMap)(props => {
  return (
    <GoogleMap defaultZoom={2} 
    defaultCenter={{ lat: 0, lng: 0}}
    
    // defaultCenter={new google.maps.LatLng(0, 0)}
    // defaultCenter={props.center}

    // defaultAverageCenter={true}
    
    >
      {props && props.markers && props.markers.map(marker => {
        const onClick = props.onClick.bind(this, marker);
        if(marker &&  marker.lat){
        return (
          <Marker
            key={marker.id}
            onClick={onClick}
            position={{ lat: Number(marker.lat), lng: Number(marker.lng) }}
            // icon={ (marker.registrationSource && marker.registrationSource==="mobile" )?mobileDot :webDot}

            // icon={{
            //   url: (marker.registrationSource && marker.registrationSource==="mobile" )?mobileDot :webDot,
            //   scaledSize: new google.maps.Size(20, 20)
              
            // }}
            >
           
            {props.selectedMarker === marker &&
              <InfoWindow>
                <div>
                  <p>{marker.businessName}</p>
                  <p>{marker.businessCity} {' '} {marker.businessCountry}</p>


              
                </div>
            </InfoWindow>}
          </Marker>
        )
      }

      })}
      
    </GoogleMap>
  )
})

class BusinessDetailPage extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      personalDetail: [],
      educationDetail: [],
      companyAddress: '',
      contactDetail: '',
      memberList:[],
      businessDetail: '',
      ompanyDetails: '',
      businessid: '',
      checkuserChatStatus: '',
      companyAddressLocation:[],
      selectedMarker:null,
      loginUserId:'',
      sendRequestmodal:false,
      sendRequestData:''

    };
  }

  componentWillReceiveProps (nextProps) {
   
    if (nextProps) {
      if (
        nextProps.contacts &&
        nextProps.contacts.businessDetail
      ) {
        console.log(nextProps.contacts.businessDetail);
        let getData = nextProps.contacts.businessDetail;
        if(getData){

        this.setState (
          {
            companyDetails:getData,
            contactDetail: getData,
            companyAddress: getData,
            businessDetail: getData,
            memberList: getData.memberList,
          },
          () => {
            const {companyAddress ,companyDetails}= this.state;
            if(companyAddress && companyAddress.businessLocation && companyAddress.businessLocation.lat){
              this.setState({
                mapLat:companyAddress.businessLocation.lat,
                mapLng:companyAddress.businessLocation.lng,
              })

              let companyAddressLocation=[];
              companyAddressLocation.push({lat:companyAddress.businessLocation.lat,lng:companyAddress.businessLocation.lng,businessName:companyDetails.displayedBusinessName,businessCity:companyAddress.businessCity,businessCountry:companyAddress.businessCountry})
              this.setState({
                companyAddressLocation:companyAddressLocation
              })
            }
          }
        );
      }

      }
    }
  }

  componentDidMount () {

    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
    if(loginUser){
      this.setState({
        loginUserId:loginUser.docID
      })
    }
    // let keyVal = '';
    // let userId = loginUser.docID;


    let geturl = this.props.match.params;
    if (geturl && geturl.userId) {
      this.setState ({
        businessid: geturl.userId,
      });
      let sendRequest = {
        data: {
          // getCompanyDetails: true,
          // getCompanyAddress:true,
          // getContactDetails:true,
          // getMemberList:true,
          // getAdditionalDetails: true,
          businessDocID: geturl.userId,
        },
      };
      this.props.getBusinessDetail (sendRequest);
    }
  }

  _handleKeyVal (receiver) {
    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));
    let keyVal = '';
    let userId = loginUser.docID;
    if (receiver > userId) {
      keyVal = `${receiver}${userId}`;
    } else {
      keyVal = `${userId}${receiver}`;
    }
    return keyVal;
  }

  goToChat () {
    const {memberList ,businessDetail} = this.state;
    let userInfo;
    for(let item of memberList ){
      if(item.memberInfo){
        if(item.memberInfo.memberID === businessDetail.registeredBy){
          userInfo=item;
        } 
      }
    }
    if(userInfo){

      let userChatInfo = {
        oppositeUserUid: userInfo.docID,
        profilePictureURL: userInfo && userInfo.profilePictureURL
          ? userInfo.profilePictureURL
          : profilePlaceholder,
          displayName: ShowNameAccordingToLength(userInfo.firstName+" " + userInfo.lastName),
      };
  
      this.props.history.push (routes.MESSENGER, {userChatInfo: userChatInfo,messengerKey: this._handleKeyVal (this.state.loginUserId),});
    }
  
  }
  goToSendMessage (item) {
    if(item){

      let userChatInfo = {
        oppositeUserUid: item.docID,
        profilePictureURL: item && item.profilePictureURL
          ? item.profilePictureURL
          : profilePlaceholder,
          displayName: ShowNameAccordingToLength(item.firstName+" " + item.lastName),
      };
  
      this.props.history.push (routes.MESSENGER, {userChatInfo: userChatInfo,messengerKey: this._handleKeyVal (this.state.loginUserId),});
    }
  
  }

  addNewContact () {
    let sendRequest = {
      data: {
        contactUID: this.state.businessid,
      },
    };
    this.props.addUserContactFunc (sendRequest);
  }
  handleClick = (marker, event) => {
     this.setState({ selectedMarker: marker })
   }

   doCancelRequest(item){
    let sendRequest={
      data:{
        messageID:item.sentContactRequestMessageID,
        targetUID:item.docID
      }
    }
    
    this.props.cancelSendUserContactRequest(sendRequest,item.docID);
  }
  closeModal(){
    this.setState({
      sendRequestmodal: false
    })
  }

  goTobusinessManagement(){
    
    this.props.history.push (`${routes.BUSINESS_MANAGEMENT}/${this.state.businessid}`)
  }


  render () {
    const {
      personalDetail,
      professionalDetail,
      businessDetail,
      checkuserChatStatus,
      companyAddress,
      contactDetail,
      companyDetails,
      memberList,
      companyAddressLocation,
      loginUserId
    } = this.state;


    return (
      <div style={{display: 'inline-flex'}} className="businessTopContainer">
        <div>
          <div>
            <div style={{textAlign: 'right'}}>
              <Button
                className="backbuttonbusiness"
                onClick={() => this.props.history.goBack ()}
                data-rh="Go back"
              >
                <div>
                  <img src={leftbackarrow} alt="backIcon" />
                </div>

                Go <span> Back</span>
              </Button>
            </div>
          </div>
          <div className="businessprofiemaindiv">
            <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{display: 'inline-flex', alignItems: 'center',width:600}}>

            <div className="businessprofileDetailImageDiv">
            <img
              style={{width: '100%', height: '100%', borderRadius: 86}}
              src={
                businessDetail && businessDetail.businessProfilePictureURL
                  ? businessDetail.businessProfilePictureURL
                  : evruslogobusi
              }
              onError={e => {
                e.target.onerror = null;
                e.target.src = evruslogobusi;
              }}
              alt="profilePlaceholder"
            />
          </div>
              {/*<div>
                <img
                  alt="Business profile"
                  src={
                    businessDetail && businessDetail.businessProfilePictureURL
                      ? businessDetail.businessProfilePictureURL
                      : evruslogobusi
                  }
                  onError={e => {
                    e.target.onerror = null;
                    e.target.src = evruslogobusi;
                  }}
                  style={{height: 100, width: 100}}
                />
                </div>*/}
              <div className="Everustextf">
              {companyDetails && companyDetails.displayedBusinessName
                ? companyDetails.displayedBusinessName
                : ''}
              </div>
              </div>
              {businessDetail && businessDetail.registeredBy && (loginUserId === businessDetail.registeredBy) && <div style={{display: 'inline-flex',justifyContent:'flex-end',width:360}}>
                  <Button
                    className="businessprofileEditbutton"
                    onClick={() =>{
                      this.props.history.push (`${routes.EDIT_BUSINESS_DETAIL}/${this.state.businessid}`)
                    }
                    }
                      
                  >
                    Edit Business Profile
                  </Button>
                    </div>}
            </div>
            <div style={{display: 'flex', marginTop: 15}}>
              <div>
              <MapWithAMarker
        selectedMarker={this.state.selectedMarker}
        markers={companyAddressLocation}
        onClick={this.handleClick}
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyATjo8svRgN3k-NjT2yLYmoIn-AE34vUTU&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `210px`,width:`580px`,margin:'1px' ,overflow:'hidden',borderRadius:10}} />}
        mapElement={<div style={{ height: `100%`}} />}
              />
      
                {/*<img alt="" src={mapimg} style={{height: 209, width: 579}} />*/}
              </div>
              <div style={{marginLeft: 20,width:360}}>
                <div style={{display: 'flex'}}>
                  <img
                    alt=""
                    src={locIcon}
                    style={{
                      width: 21,
                      height: 21,
                    }}
                  />
                  <p className="Middleville">
                    {companyAddress && companyAddress.businessAddress_1
                      ? companyAddress.businessAddress_1
                      : ''}

                  </p>
                </div>
                <div style={{display: 'flex', marginTop: 10}}>
                  <img
                    alt="call"
                    src={call}
                    style={{
                      width: 21,
                      height: 21,
                    }}
                  />
                  <p className="Middleville">
                    {contactDetail && contactDetail.businessPhoneNumber
                      ? contactDetail.businessPhoneNumber
                      : ''}

                  </p>
                </div>
                <div style={{display: 'flex', marginTop: 10}}>
                  <img
                    alt="mail"
                    src={mail}
                    style={{
                      width: 21,
                      height: 21,
                    }}
                  />
                  <p className="Middleville" style={{color: '#4453D6',fontWeight:'bold'}}>
                    {contactDetail && contactDetail.businessEmailAddress
                      ? contactDetail.businessEmailAddress
                      : ''}

                  </p>
                </div>
                <div style={{display: 'flex', marginTop: 10}}>
                  <img
                    alt="Arrow"
                    src={Arrow}
                    style={{
                      width: 21,
                      height: 21,
                    }}
                  />
                  <p className="Middleville" style={{color: '#4453D6',fontWeight:'bold'}}>

                    {contactDetail && contactDetail.businessWebsiteUrl
                      ? contactDetail.businessWebsiteUrl
                      : ''}

                  </p>
                </div>
                <div style={{textAlign:"right"}}>
                  { businessDetail && businessDetail.registeredBy && (loginUserId !== businessDetail.registeredBy) && <Button className="sendmsgbuttoon" onClick={()=>this.goToChat()}>Send Message</Button>}
                </div>

              </div>
            </div>
            <div style={{display: 'flex'}}>
              <div className="registereddetaildiv">
                <div className="registreddetailhead">
                  REGISTERED <span>DETAILS</span>
                </div>
                <div className="businessInf0Box">
                <p className="Registeredbusines"> Registered Business Name</p>
                <p className="EverusbisimedsMedica">
                  {companyDetails && companyDetails.registeredBusinessName
                    ? companyDetails.registeredBusinessName
                    : ''}
                </p>
                <p className="displayedbusnieenam">
                  Displayed Business Name
                </p>
                <p className="EverusbisimedsMedica">
                  {companyDetails && companyDetails.displayedBusinessName
                    ? companyDetails.displayedBusinessName
                    : ''}

                </p>
                <div style={{display: 'flex'}}>
                  <div style={{width:140}}> 
                    <p className="displayedbusnieenam">Business Type</p>
                    <p className="EverusbisimedsMedica">
                      {businessDetail && businessDetail.businessType
                        ? businessDetail.businessType
                        : ''}

                    </p>
                  </div>
                  <div>
                    <p className="YearofEsta">Year of Establised</p>
                    <p className="year2008">
                      {companyDetails && companyDetails.yearEstablished
                        ? companyDetails.yearEstablished
                        : ''}

                    </p>
                  </div>
                </div>
                <p className="displayedbusnieenam"> Search Handle</p>
                <p className="EverusbisimedsMedica">
                  {companyDetails && companyDetails.displayedBusinessName? '@'+companyDetails.displayedBusinessName:''}

                </p>
                <div style={{display: 'flex'}}>
                  <div style={{minWidth:120}}>
                    <p className="displayedbusnieenam"> City</p>
                    <p className="EverusbisimedsMedica">
                      {companyAddress && companyAddress.businessCity
                        ? companyAddress.businessCity
                        : ''}

                    </p>
                  </div>
                  <div style={{minWidth:120}}>
                    <p className="bsuniessState">State</p>
                    <p className="year2008">
                      {companyAddress && companyAddress.businessState
                        ? companyAddress.businessState
                        : ''}

                    </p>
                  </div>
                  <div style={{minWidth:120}}>
                    <p className="businessCountry">Country</p>
                    <p className="yearcdscsd20">
                      {companyAddress && companyAddress.businessCountry
                        ? companyAddress.businessCountry
                        : ''}

                    </p>
                  </div>
                  </div>
                </div>
              </div>
              <div>

                        <div style={{display:'inline-flex',marginLeft:15,width:470,justifyContent:'space-between',marginTop:17}}>
                          <Button onClick={()=>this.goTobusinessManagement()} style={{background:'#333333'}} className="manageBusinessMemberButton"> <img style={{marginRight:5}} src={businessContactIcon} alt="businessContactIcon"/> Manage Members & Groups</Button>
                          <Button onClick={()=>this.goTobusinessManagement()} style={{background:'#4453D6'}}   className="manageBusinessMemberButton"> 
                          

                          <img style={{width:25,height:19,marginRight:10}} src={defaultContactRequest} alt="defaultContactRequest"/>

                          <b>NEW </b> Membership Requests</Button>
                        
                        
                        
                        </div>
              <div className="adminmemeredildiv">
                <div style={{display: 'flex', alignItems: 'baseline',justifyContent:'space-between',marginBottom:15}}>
                  <div className="registreddetailhead">
                    ADMINS & <span>MEMBERS</span>
                  </div>
                  <p className="membsccers5"><b>{(memberList && memberList.length) ? memberList.length :0} </b> members</p>
                </div>
                {memberList && memberList.length>0 && memberList.map((item ,i)=>(<div key={i}>
                  <div className="businessMemberList">
                  <div style={{display: 'flex',marginLeft:item && item.memberInfo && item.memberInfo.isAdmin? 0: 25}}> 
                  {item && item.memberInfo && item.memberInfo.isAdmin && <img className="adminIconImage" src={businessAdmin} alt="admin" />}
                  <img
                      alt="businessMemberProfile"
                      src={
                        item && item.profilePictureURL
                          ? item.profilePictureURL
                          : evruslogobusi
                      }
                      onError={e => {
                        e.target.onerror = null;
                        e.target.src = evruslogobusi;
                      }}
                    
                      className="businessMemberProfile"
                    />
                 
                  </div>
                    
                  <div style={{minWidth:250}}>
                    <p className="Amarclacrekfnv">{item &&item.firstName? ShowNameAccordingToLength(item.firstName+" " + item.lastName):'' }

                    
                    {' '}
                    {/*item && item.personalProfile &&item.personalProfile.lastName? item.personalProfile.lastName:'' */} </p>
                    <p className="CoFocdscvusijnunder">
                 
                    {item &&item.professionalTitle? item.professionalTitle:'' } 

                    </p>
                    <p className="Universscdscity">
     
                    {item  &&item.city? item.city:'' } 


                    </p>
                  </div>
                  {loginUserId !== item.docID && <div style={{marginRight:10}}>
                    {item.inContactList==='false' &&<Button className="connectBlueButton" data-rh="Connect" onClick={()=>{
                      this.setState({
                        sendRequestmodal:true,
                        sendRequestData:item
                      })
                    }}>Connect</Button>}
                    {item.inContactList==='true' &&<Button className="connectBlueButton" onClick={() => this.goToSendMessage (item)}
                    data-rh="Message">Message</Button>}

                    {item.inContactList === 'pending' &&
                    item.pendingContactRequest === 'false' &&
                    <Button disabled={!item.sentContactRequestMessageID} className="connectBlueButton" onClick={()=>this.doCancelRequest(item)}
                    data-rh="Cancel Request"
                    >
                      Cancel Request
                    </Button>}

                    {/*item.pendingContactRequest === 'true' &&<Button
                        className="addContactButtonList"
                        onClick={() => this.acceptRequest (item, true)}
                        data-rh="Accept request"
                      >
                        Approve Contact{' '}
                    </Button>*/}

                  </div>}
                  </div>
                  {((memberList.length -1) > i) && <div className="dbusniessivider" />}
                </div>))}
                
              </div>
              </div>
            </div>
          </div>
        </div>
        <RightSideContainer>
          <UserContactListPage history={this.props.history}/>
        </RightSideContainer>


        <Modal
        size="sm"
        isOpen={this.state.sendRequestmodal}
        centered={true}
        toggle={() => this.closeModal ()}
        backdropClassName="contactModalBackdrops"
      >
        <ModalBody className="requestConfirmationModal">
          <div>

            <p className="confirmTitle">Send Request?</p>
            <p className="confirmmsg">
              Do you wish to send a request to add this person to your Contacts?
            </p>
            <div className="buttonDiv">
              <Button
                className="buttons noButton"
                onClick={() => this.closeModal ()}
              >
                No
              </Button>
              <Button
                className="buttons yesButton"
                onClick={() => {
                  setTimeout (() => {
                    this.closeModal ();
                  }, 2000);
                  let sendRequest = {
                    data: {
                      contactUID: this.state.sendRequestData.docID,
                    },
                  };
                  this.props.addUserContactFunc (sendRequest);
                }}
              >
                Yes
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>

     

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.ContactsReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getBusinessDetail,
      addUserContactFunc,
      cancelSendUserContactRequest
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  BusinessDetailPage
);
