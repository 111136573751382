import React, { Component } from 'react';
// Dependencies
import { Link } from 'react-router-dom';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import DatePicker from 'react-datepicker';
import Resizer from 'react-image-file-resizer';
import parsePhoneNumber from 'libphonenumber-js';
// Data
import { auth, db } from '../../../firebase';
// import * as firebase from 'firebase';
import firebase from 'firebase';

// Utilities
import { EncryptFunction } from '../../../utility/encryption';
// Actions
import {
  register,
  updateFirebaseToken,
  // ModifyPersonalInfo,
  signOut,
  resendEmail,
  DoRegisterModifyPersonalInfo,
  fetchStatusCode,
  sendOTP,
  verifyOTP,
  resetResetPasswordReducer,
  resetUserReducer,
} from '../../../actions/Authaction';
import { Loading } from '../../../actions/LoadingAction';
import { uploadUserProfile } from '../../../actions/contacts';
// Components
import { Button, Form, Alert, Progress } from 'reactstrap';
// Routes
import * as routes from '../../../constants/routes';
// Constants
import { createNotification } from '../../../constants/notificationtoast';
import { authErrorMessage } from '../../../constants/localMessage/auth/authHelper';
import * as authValidation from '../../../constants/localMessage/auth/authHelper';
import {
  getBrowserName,
  // getDeviceModal,
  CapitalizeFirstLetter,
} from '../../../constants/helper';
import { IMAGE_TYPE_ERROR } from '../../../constants/errorMessages';
import countryFlags from '../../../constants/localJSON/countryList';
// Icons
import logo from '../../logo.svg';
import email from '../../../images/icons/email.svg';
import password from '../../../images/icons/password.svg';
import accountSuccess from '../../../images/icons/accountsuccess.svg';
import placeholderProfile from '../../../images/placeholder-profile.svg';
import leftbackarrow from '../../../images/icons/leftbackarrow.png';
import downWhiteArrow from '../../../images/icons/downwhiteArrow.svg';
// UI
import ImageCrop from '../../UI/ImageCrop';
import Calendar from '../../UI/calendar/calendar';
import SelectInput from '../../InputCustom/selectInput/selectDropdown'

// Styles
import './profile-completion.scss';

//A Higher order function with prop name as key and the value to be assigned to
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class ProfileCompletion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profilePicture: '',
      title: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      passwordOne: '',
      passwordAgain: '',
      phoneNumber: '',
      displayName: '',
      searchHandle: '',
      showingAlert: false,
      Loading: true,
      isSubmit: false,
      invalidEmailFormat: false,
      passwordCheck: false,
      step: 2,
      invalidConfirmPass: false,
      userudId: '',
      titleDropDopwn: [
        { label: 'Mr.', value: 'Mr.' },
        { label: 'Dr.', value: 'Dr.' },
        { label: 'Ms.', value: 'Ms.' },
        { label: 'Mrs.', value: 'Mrs.' },
      ],
      genderDropDopwn: [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
        { label: 'Other', value: 'Other' },
        { label: 'Prefer Not To Say', value: 'Prefer Not To Say' },
      ],
      agree: false,
      dateOfbirth: '',
      gender: '',
      // countryCode: '',
      countryCode: '+1',
      // countryFlag: "🇺🇸",
      countryFlag:
        countryFlags && countryFlags.length > 0
          ? countryFlags[234].countryFlag
          : '',

      otpValue: '',
      confirmOTPError: '',
      isChangePhone: false,
      filterCountry: '',
      time: {},
      seconds: 0,
      isCheckEnable: false,
      lat: '',
      lng: '',
      userEmail: '',
      loadedImage: '',
      isOpenDobPicker: false,
      captchaToken: '',
      mobileCheckvalidFormat: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.timer = 0;
    this.countDown = this.countDown.bind(this);
    this.openDobDatepicker = React.createRef();
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }
  InitiateTimer() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);
    this.setState({ time: timeLeftVar });
    this.startTimer();
  }
  startTimer() {
    if (this.timer == 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds == 0) {
      clearInterval(this.timer);
    }
  }

  handleClickOutside = (event) => {
    if (
      this.openDobDatepicker.current &&
      !this.openDobDatepicker.current.contains(event.target)
    ) {
      this.setState({
        isOpenDobPicker: false,
      });
    }
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  async componentDidMount() {
    // localStorage.clear ();
    document.addEventListener('mousedown', this.handleClickOutside);
    this.props.resetUserReducer();

    this.props.Loading(false);
    // this.props.fetchStatusCode ();
    console.log( this.props.location);
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.userEmail
    ) {
      this.setState({
        userEmail: this.props.location.state.userEmail,
        emailAddress: this.props.location.state.userEmail
      });
    }

    await navigator.geolocation.getCurrentPosition((position) => {
      this.setState({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps &&
      nextProps.userinfo &&
      nextProps.userinfo.user &&
      nextProps.userinfo.user.Data
    ) {
      console.log (nextProps.userinfo);
      if (this.state.step === 2) {
        let responseData = nextProps.userinfo.user.Data;
        console.log(responseData);
        // if (tempresponseData.emailRegistered === false) {
        //   responseData = nextProps.userinfo.user.Data;
        // } else {
        //   if (
        //     nextProps.userinfo.user.Data &&
        //     nextProps.userinfo.user.Data.userDetails &&
        //     nextProps.userinfo.user.Data.userDetails.length > 0 &&
        //     nextProps.userinfo.user.Data.userDetails[6]
        //   ) {
        //     responseData = nextProps.userinfo.user.Data.userDetails[6];
        //   }
        // }
        if (
          responseData &&
          responseData.registrationStepsCompleted &&
          responseData.registrationStepsCompleted === 1
        ) {
          // this.setState({
            // emailAddress: (responseData.currentLoginLocation && responseData.currentLoginLocation.emailAddress
            //   ? responseData.currentLoginLocation.emailAddress
            //   : this.state.userEmail),
          // });
          // if (responseData && !responseData.phoneNumber) {
          //   this.setState({
          //     isChangePhone: true,
          //     // phoneNumber: '',
          //     countryCode: '',
          //   });
          // } else {
            this.setState(
              {
                isChangePhone: false,
                phoneNumber: responseData.phoneNumber,
                countryCode: responseData.countryCode,
              },
              () => {
                for (let item of countryFlags) {
                  if (item.dial_code === this.state.countryCode) {
                    this.setState({
                      countryFlag: item.countryFlag,
                    });
                  }
                }

                if (!this.state.isCheckEnable) {
                  this.setState(
                    {
                      isCheckEnable: true,
                    },
                    () => {
                      this.signInWithPhone();
                    }
                  );
                }
              }
            );
          }
        // }

        if (
          responseData &&
          responseData.registrationStepsCompleted &&
          responseData.registrationStepsCompleted === 2
        ) {
          //  responseData = nextProps.userinfo.user.Data;
          this.setState({
            step: 3,
            phoneNumber: responseData.localPhoneNumber,
            countryCode: responseData.countryCode,
            // emailAddress: ((responseData.currentLoginLocation && responseData.currentLoginLocation.emailAddress)
            //   ? responseData.currentLoginLocation.emailAddress : this.state.userEmail)
          },()=>{
            // console.log(responseData)
            // console.log( responseData.currentLoginLocation && responseData.currentLoginLocation.emailAddress
            //   ? responseData.currentLoginLocation.emailAddress : this.state.userEmail)

            //     console.log(this.state.userEmail)
            //     console.log(this.state.emailAddress);
        });

        }
      }
    }
    // console.log (nextProps.userinfo.user);
    if (
      nextProps &&
      nextProps.userinfo &&
      nextProps.userinfo.sendVerifyMobileOtp &&
      nextProps.userinfo.sendVerifyMobileOtp
    ) {

      clearInterval(this.timer);
      this.timer = 0;
      this.setState(
        {
          time: {},
          seconds: 59,
        },
        () => {
          this.startTimer();

        })
    }
    
    if (
      nextProps &&
      nextProps.userinfo &&
      nextProps.userinfo.sendVerifyMobileOtpError &&
      nextProps.userinfo.sendVerifyMobileOtpError
    ) 
    {
      clearInterval(this.timer);
      this.timer = 0;
      this.setState(
        {
          time: {},
          seconds: 0,
          isSubmit: false,
          isChangePhone: false,
          captchaToken: '',
        },()=>{
          // this.signInWithPhone();
        })

        
  

    }


    if (
      nextProps &&
      nextProps.userinfo &&
      nextProps.userinfo.otpVerify &&
      nextProps.userinfo.otpVerify
    ) {
      this.setState({
        step: 3,
        isSubmit: false,
      });
    }

    // console.log (nextProps);
    if (
      nextProps.profileUpdated &&
      nextProps.profileUpdated.uploadUserImage &&
      nextProps.profileUpdated.uploadUserImage.profilePictureURL
    ) {
      if (this.state.step === 3) {
        // this.props.history.push(routes.SIGN_IN);

        if (
          nextProps &&
          nextProps.userinfo &&
          nextProps.userinfo.user &&
          nextProps.userinfo.user &&
          nextProps.userinfo.user.Data &&
          nextProps.userinfo.user.Data.emailVerified
        ) {
          this.props.history.push(routes.PROFILE_DASHBOARD);
        } else {
          this.setState({
            step: 4,
          });
          // this.props.history.push(routes.SIGN_IN);
        }
      }
    }
    // if (
    //   nextProps.userinfo &&
    //   nextProps.userinfo.newuser &&
    //   nextProps.userinfo.newuser.result
    // ) {
    //   console.log (nextProps.userinfo.newuser.result);

    //   if (this.state.step === 3) {
    //     this.props.history.push(routes.SIGN_IN);

    //   }
    // }
  }

  doCallSendOTP() {
    clearInterval(this.timer);
    this.timer = 0;
    this.setState(
      {
        time: {},
        seconds: 59,
      },
      () => {
        // this.startTimer();
        let sendRequest = {
          data: {
            emailAddress: this.state.emailAddress,
            phoneNumber: this.state.countryCode + this.state.phoneNumber,
            recapchaToken: this.state.captchaToken,
            countryCode: this.state.countryCode,
            localPhoneNumber: this.state.phoneNumber,
          },
        };
        this.props.sendOTP(sendRequest);
      }
    );
  }

  signInWithPhone = () => {
    this.props.Loading(false);
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'phoneVerify',
      {
        // 'size': 'invisible',
        callback: (recapchaToken) => {
          try {
            this.setState(
              {
                captchaToken: recapchaToken,
              },
              () => {
                if (this.state.phoneNumber) {
                  this.doCallSendOTP();
                }
              }
            );
          } catch (error) {
            console.log('ERORR = ' + error);
          }
        },
        'expired-callback': () => {
          try {
            this.setState(
              {
                captchaToken: '',
              },
              () => {}
            );
          } catch (error) {
            console.log('ERORR = ' + error);
          }
        },
      }
    );

    window.recaptchaVerifier.render().then(function(widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  };

  doConfirmOTP() {
    // var code = getCodeFromUserInput();
    this.setState(
      {
        isSubmit: true,
      },
      () => {
        var code = this.state.otpValue;
        // console.log (code);
        if (!code) {
          return;
        }

        let sendRequest = {
          data: {
            emailAddress: this.state.emailAddress,
            otp: this.state.otpValue,
          },
        };
        // console.log (sendRequest);

        this.props.verifyOTP(sendRequest);
      }
    );
  }
  goNext = () => {
    this.setState(
      {
        isSubmit: true,
      },
      () => {
        const {
          emailAddress,
          passwordOne,
          passwordAgain,
          firstName,
          agree,
          phoneNumber,
          countryCode,
        } = this.state;

        let checkEmail = false;
        let checkMobile = false;

        if (emailAddress) {
          const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          const result = pattern.test(emailAddress);

          if (!result) {
            checkEmail = true;
            this.setState({
              invalidEmailFormat: true,
            });
            return;
          } else {
            checkEmail = false;
            this.setState({
              invalidEmailFormat: false,
            });
          }
        }
        const patternpass = /^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
        const result = patternpass.test(passwordOne);

        if (!result) {
          this.setState({
            passwordCheck: true,
          });
          return;
        } else {
          // checkMobile = false;
          this.setState({
            passwordCheck: false,
          });
        }

        if (passwordOne !== passwordAgain) {
          this.setState({
            invalidConfirmPass: true,
          });
          return;
        }
        if (!phoneNumber || phoneNumber.length < 7 || phoneNumber.length > 10) {
          return;
        }
        if (!countryCode) {
          return;
        }
        if (!agree) {
          return;
        }

        this.props.Loading(true);

        let userEmail = emailAddress.toLowerCase();

        let hashKey = EncryptFunction(userEmail, passwordOne);

        auth
          .doCreateUserWithEmailAndPassword(userEmail, hashKey)
          .then((user) => {
            auth.AuthChange((user) => {
              if (user) {
                user.getIdToken().then((idToken) => {
                  // console.log (idToken);
                  // console.log ('auth user', user);
                  localStorage.setItem('authToken', idToken);
                  // localStorage.setItem (
                  //   'newUserInfo',
                  //   JSON.stringify (this.state)
                  // );
                  this.props.updateFirebaseToken(idToken);

                  // db.doCreateUser(user.uid, firstName, emailAddress)
                  //   .then((res) => {
                  //     // this.props.Loading (false);
                  //   })
                  //   .catch((error) => {
                  //     this.props.Loading(false);
                  //     this.setState(byPropKey('error', error));
                  //     // this.timer (); //show alert message for some seconds
                  //   });

                  this.setState(
                    {
                      step: 2,
                      isSubmit: false,
                      userudId: user.uid,
                    },
                    () => {
                      setTimeout(() => {
                        this.props.Loading (false);
                        this.signInWithPhone();
                      }, 1000);
                    }
                  );
                });
              }
            });
          })
          .catch((err) => {
            this.props.Loading(false);
            console.error('error!!', err.message);
            this.setState(byPropKey('error', err));
            createNotification('error', err.message);
            // this.timer (); //show alert message for some seconds
            //  Loading=false
          });
        //  history.push (routes.ONE_STEP);

        //prevents refreshing
      }
    );
  };
  onChange = (e) => {
    const file = e.target.files[0];
    const fileType = file['type'];
    const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    // Check If file is not an image
    if (!validImageTypes.includes(fileType)) {
      // console.log("rfreg")
      createNotification('warning', IMAGE_TYPE_ERROR);
    } else {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        this.setState({
          loadedImage: reader.result,
        });
      });
      reader.readAsDataURL(e.target.files[0]);
    }

    // let files = e.target.files;
    // let reader = new FileReader ();
    // reader.readAsDataURL (files[0]);
    // Resizer.imageFileResizer (
    //   files[0],
    //   300,
    //   300,
    //   'JPEG',
    //   400,
    //   0,
    //   uri => {
    //     // console.log(uri)

    //     this.setState (
    //       {
    //         profilePicture: uri,
    //       },
    //       () => {
    //         //  console.log(this.state.profilePicture)
    //       }
    //     );
    //   },
    //   'base64'
    // );

    // reader.onload = e => {
    //   this.setState (byPropKey ('profilePicture', e.target.result));
    // };
  };

  updateUserImage(croppedImage) {
    Resizer.imageFileResizer(
      croppedImage,
      300,
      300,
      'JPEG',
      400,
      0,
      (uri) => {
        // console.log(uri);
        // const {filename}= this.state;
        this.setState(
          {
            profilePicture: uri,
          },
          () => {}
        );
      },
      'base64'
    );
  }

  onSubmit = (event) => {
    event.preventDefault();

    this.setState(
      {
        isSubmit: true,
      },
      () => {
        const {
          profilePicture,
          title,
          firstName,
          lastName,
          emailAddress,
          passwordOne,
          phoneNumber,
          displayName,
          searchHandle,
          passwordAgain,
          gender,
          dateOfbirth,
        } = this.state;

        let checkEmail = false;
        let checkMobile = false;

        if (emailAddress) {
          // const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
          const pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

          const result = pattern.test(emailAddress);
          // console.log (result);
          if (!result) {
            checkEmail = true;
            this.setState({
              invalidEmailFormat: true,
            });
          } else {
            checkEmail = false;
            this.setState({
              invalidEmailFormat: false,
            });
          }
        }
        const patternpass = /^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
        const result = patternpass.test(passwordOne);

        if (!result) {
          checkMobile = true;

          this.setState({
            passwordCheck: true,
          });
        } else {
          checkMobile = false;
          this.setState({
            passwordCheck: false,
          });
        }

        const isInvalid =
          emailAddress === '' ||
          firstName === '' ||
          lastName === '' ||
          // phoneNumber === '' ||
          gender === '' ||
          dateOfbirth === '' ||
          profilePicture === '';
        // checkEmail === true ||
        // checkMobile === true
        if (isInvalid) {
          return false;
        }
    

        // const { history, register } = this.props;

        // {
          /*db
          .doCreateUser (this.state.userudId, firstName, emailAddress)
          .then (res => {
            // this.props.Loading (false);
            // console.log (res);
          })
          .catch (error => {
            // Loading = false;
            this.setState (byPropKey ('error', error));
            // this.timer (); //show alert message for some seconds
          });*/
        // }

        this.props.Loading(true);
        // this.setState({
        //   base64Image: e.target.result,
        // })
        let sendRequest = {
          data: {
            profilePicture: profilePicture,
            filename: new Date() + 'profile.png',
            contentType: 'image/jpeg',
          },
        };
        // this.props.uploadUserProfile (sendRequest);

        const userInfosendRequest = {
          data: {
            title: title,
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            dateOfBirth: moment(new Date(dateOfbirth)).format('YYYY/MM/DD'),
            gender: gender,
            // searchHandleNew: searchHandle,
            emailAddress: emailAddress.trim(),
            registrationSource: 'web',
            // deviceModel: getDeviceModal(),
            osType: '',
            browser: getBrowserName(),
            // deviceLongitude: this.state.lat,
            // deviceLatitude: this.state.lng,
            deviceLongitude: this.state.lng,
            deviceLatitude: this.state.lat,
          },
        };

        this.props.DoRegisterModifyPersonalInfo(
          userInfosendRequest,
          sendRequest
        );

        // setTimeout(()=>{
        //   this.props.Loading (true);
        // },2000)

        // history.push (routes.ONE_STEP);
        // setTimeout (() => {
        //   this.setState ({
        //     step: 3,
        //   });
        // }, 3000);
      }
    );
  };

  timer = () => {
    this.setState({
      showingAlert: true,
    });

    setTimeout(() => {
      this.setState({
        showingAlert: false,
      });
    }, 4000);
  };

  goBack() {
    if (this.state.step === 2 || this.state.step === 3) {
      this.props.history.goBack();
    } else {
      this.setState({
        step: this.state.step - 1,
      });
    }
  }

  emailResend = () => {
    let sendRequest = {
      data: {
        emailAddress: this.state.emailAddress,
      },
    };
    this.props.resendEmail(sendRequest);
  };

  handleButtonClick = () => {
    this.setState((state) => {
      return {
        open: !state.open,
      };
    });
  };

  doUpdatePhone() {
    this.setState(
      {
        isSubmit: true,
      },
      () => {
        const { phoneNumber, countryCode } = this.state;
        // if (
        //   !phoneNumber ||
        //   (phoneNumber.length < 7 || phoneNumber.length > 10)
        // ) {
        //   return;
        // }
        if (!phoneNumber) {
          return;
        }
        if (!countryCode) {
          return;
        }
        const checkPhoneNumber = parsePhoneNumber(countryCode + phoneNumber);

        if (
          phoneNumber.length > 0 &&
          checkPhoneNumber &&
          !checkPhoneNumber.isValid()
        ) {
          return;
        }
        this.setState(
          {
            isSubmit: false,
            isChangePhone: false,
            captchaToken: '',
          },
          () => {
            //   if(this.state.captchaToken){
            //     this.doCallSendOTP();
            //   }else{
            this.signInWithPhone();
            //   }
          }
        );
      }
    );
  }

  
  checkPhoneValidation() {
    const checkPhoneNumber = parsePhoneNumber(
      this.state.countryCode + this.state.phoneNumber
    );
    // console.log(this.state.countryCode+this.state.phoneNumber);
    if (checkPhoneNumber && this.state.phoneNumber.length > 0) {
      if (checkPhoneNumber.isValid()) {
        this.setState({
          mobileCheckvalidFormat: false,
        });
      } else {
        this.setState({
          mobileCheckvalidFormat: true,
        });
      }
    } else {
      this.setState({
        mobileCheckvalidFormat: true,
      });
    }
  }

  render() {
    const {
      profilePicture,
      title,
      firstName,
      lastName,
      emailAddress,
      passwordOne,
      phoneNumber,
      error,
      showingAlert,
      displayName,
      searchHandle,
      isSubmit,
      invalidEmailFormat,
      passwordCheck,
      passwordAgain,
      invalidConfirmPass,
      step,
      titleDropDopwn,
      genderDropDopwn,
      dateOfbirth,
      gender,
      countryCode,
      otpValue,
      isChangePhone,
      loadedImage,
      isOpenDobPicker,
    } = this.state;
    //a boolen to perform validation

    const lowercasedFilter = this.state.filterCountry.toLowerCase();
    const filteCountryData = countryFlags.filter((item) => {
      return Object.keys(item).some((key) =>
        item[key].toLowerCase().includes(lowercasedFilter)
      );
    });

    return (
      <div className='div-flex-signup gradientColorPurpleBlue'>
        <div className='maineSingupContaner'>
          <div>
            {this.state.step <= 3 && (
              <Button
                className='signupStepGoback'
                onClick={() => this.goBack()}
              >
                <div>
                  <img src={leftbackarrow} alt='backIcon' />
                </div>
                Go <span> Back</span>
              </Button>
            )}

            {/*<div id="phoneVerify" />*/}

            {this.state.step === 1 && (
              <div>
                <div>
                  <img src={logo} alt='logo' className='signUpFlogo' />
                </div>
                <div className='signupstep1'>
                  <div className='setupacc2'>Setup Account</div>
                  <div classname='progress-container'>
                    <Progress value={step * 33.3} className='profgress2' />
                  </div>
                  <div className='Createlo'>Create login credentials</div>
                  <div>
                    <p className='title2'>Email</p>
                    <div className='relative'>
                      <img
                        src={email}
                        alt='Email icon'
                        className='signUpinputIcons'
                      />
                      <input
                        className='step1inpu'
                        type='text'
                        placeholder='Email'
                        value={emailAddress}
                        onChange={(e) =>
                          this.setState(
                            byPropKey('emailAddress', e.target.value)
                          )
                        }
                      />
                    </div>
                    {!emailAddress && isSubmit && (
                      <p className='validationerrorMessage'>
                        {' '}
                        Email is required *{' '}
                      </p>
                    )}
                    {invalidEmailFormat && isSubmit && (
                      <p className='validationerrorMessage'>
                        {authErrorMessage(authValidation.EMAIL_FORMAT)}
                      </p>
                    )}
                  </div>
                  <div>
                    <p className='title2'>Password</p>
                    <div className='relative'>
                      <img
                        src={password}
                        alt='Email icon'
                        className='signUpinputIcons'
                      />

                      <input
                        type='password'
                        className='step1inpu'
                        placeholder='Password'
                        value={passwordOne}
                        onChange={(e) =>
                          this.setState(
                            byPropKey('passwordOne', e.target.value)
                          )
                        }
                      />
                    </div>

                    {!passwordOne && isSubmit && (
                      <p
                        className='validationerrorMessage'
                        style={{ paddingLeft: 50 }}
                      >
                        {' '}
                        Password is required *{' '}
                      </p>
                    )}
                    {passwordCheck && (
                      <div>
                        <p
                          className='Passwordmustcontain'
                          style={{ marginLeft: 15, marginRight: 15 }}
                        >
                          {authErrorMessage(
                            authValidation.INVALID_PASSWORD_FORMAT
                          )}
                        </p>
                      </div>
                    )}
                  </div>
                  <div>
                    <p className='title3'>Password again</p>
                    <div style={{ textAlign: 'center' }}>
                      <input
                        style={{ paddingLeft: 30 }}
                        className='step1inpu'
                        type='password'
                        name='passwordAgain'
                        placeholder='Confirm Password'
                        value={passwordAgain}
                        onChange={(e) =>
                          this.setState({
                            passwordAgain: e.target.value,
                            invalidConfirmPass: false,
                          })
                        }
                      />
                    </div>
                    {!passwordAgain && isSubmit && (
                      <p
                        className='validationerrorMessage'
                        style={{ paddingLeft: 50 }}
                      >
                        {' '}
                        Password again is required *{' '}
                      </p>
                    )}
                    {invalidConfirmPass && (
                      <p
                        className='validationerrorMessage'
                        style={{ paddingLeft: 50 }}
                      >
                        {authErrorMessage(
                          authValidation.INVALID_CONFIRM_PASSWORD
                        )}
                      </p>
                    )}
                  </div>

                  <div>
                    <p className='title3'>Phone Number</p>
                    <div
                      className='phoneInputContainer'
                      style={{ textAlign: 'center' }}
                    >
                      <div>
                        <Button
                          className='selectCountryButton'
                          onClick={this.handleButtonClick}
                          data-rh='Select Country'
                        >
                          {this.state.countryFlag ? this.state.countryFlag : ''}

                          {this.state.countryCode
                            ? this.state.countryCode
                            : 'Select Code'}
                          <img src={downWhiteArrow} alt='down' />
                        </Button>

                        {this.state.open && (
                          <div className='countryDropdown'>
                            <input
                              className='searchInput'
                              type='text'
                              placeholder='Search'
                              onChange={(e) =>
                                this.setState(
                                  {
                                    filterCountry: e.target.value,
                                  },
                                  () => {
                                    // console.log(this.state.filterCountry)
                                  }
                                )
                              }
                            />
                            {filteCountryData &&
                              filteCountryData.map((item, i) => (
                                <div
                                  key={i}
                                  onClick={() => {
                                    let countryCode = item.dial_code
                                      .split(' ')
                                      .join('');

                                    this.setState({
                                      countryCode: countryCode,
                                      countryFlag: item.countryFlag,
                                      open: false,
                                      filterCountry: '',
                                    });
                                  }}
                                >
                                  {item.countryFlag} {item.dial_code}
                                </div>
                              ))}
                          </div>
                        )}
                      </div>

                      <input
                        style={{ paddingLeft: 30 }}
                        className='phoneInput'
                        type='text'
                        placeholder='Phone Number *'
                        value={phoneNumber}
                        pattern='[0-9]*'
                        // maxLength="10"
                        onChange={(evt) => {
                          const phoneNumber = evt.target.validity.valid
                            ? evt.target.value
                            : this.state.phoneNumber;
                          this.setState({
                            phoneNumber,
                          });
                        }}
                      />
                    </div>
                    {!countryCode && isSubmit && (
                      <div
                        className='redBoxErrorMsg center'
                        style={{ padding: 10 }}
                      >
                        {authErrorMessage(
                          authValidation.PROFILE_PHONE_COUNTRYCODE
                        )}
                      </div>
                    )}

                    {!phoneNumber && isSubmit && (
                      <div
                        className='redBoxErrorMsg center'
                        style={{ padding: 10 }}
                      >
                        {authErrorMessage(authValidation.PROFILE_PHONENUMBER)}
                      </div>
                    )}
                    {phoneNumber &&
                      isSubmit &&
                      (phoneNumber.length <= 7 || phoneNumber.length > 10) && (
                        <div
                          className='redBoxErrorMsg center'
                          style={{ padding: 10 }}
                        >
                          {authErrorMessage(
                            authValidation.PROFILE_PHONENUMBER_FORMAT
                          )}
                        </div>
                      )}
                  </div>
                  <div className='termsandCondition'>
                    <input
                      checked={this.state.agree}
                      onClick={() =>
                        this.setState({ agree: !this.state.agree })
                      }
                      style={{ marginRight: 10, cursor: 'pointer' }}
                      type='checkbox'
                    />
                    I agree to the{' '}
                    <Link
                      to={routes.TERMS_CONDITIONS}
                      data-rh='Terms & Conditions, Privacy Policy and Cookies'
                    >
                      {' '}
                      Terms & Conditions, Privacy Policy and Cookies
                    </Link>
                    {!this.state.agree && isSubmit && (
                      <p
                        className='redBoxErrorMsg center'
                        style={{ padding: 10 }}
                      >
                        {' '}
                        Please agree to Terms & Conditions, Privacy Policy and
                        Cookies Policy to register{' '}
                      </p>
                    )}
                  </div>

                  <div
                    style={{
                      textAlign: 'center',
                      marginTop: 12,
                    }}
                  >
                    <Button
                      className='stepnextbu'
                      onClick={this.goNext}
                      style={{
                        cursor:
                          emailAddress === '' ||
                          passwordOne === '' ||
                          passwordAgain === ''
                            ? 'unset'
                            : '',
                      }}
                      disabled={
                        emailAddress === '' ||
                        passwordOne === '' ||
                        passwordAgain === ''
                      }
                      data-rh='Next'
                    >
                      Next
                    </Button>
                  </div>
                  <div className='Already'>
                    Already have an account?{' '}
                    <Link to={routes.SIGN_IN} data-rh='Login'>
                      Login here
                    </Link>
                  </div>
                </div>
              </div>
            )}

            {this.state.step === 2 && (
              <div style={{ padding: '1.9rem' }}>
                <div className='logo-container'>
                  <img src={logo} alt='logo' className='signUpFlogo' />
                </div>
                <div className='signupstep1'>
                  <div className='setupacc2'>Setup Account</div>
                  <div className='progress-container'>
                    <Progress value={step * 33.3} className='profgress2' />
                  </div>
                  {isChangePhone && (
                    <div style={{ width: '100%' }}>
                      <p className='title3'>Phone Number</p>

                      <div
                        className='phoneInputContainer'
                        style={{ textAlign: 'center' }}
                      >
                        <div className='phoneInputBox'>
                          <Button
                            className='selectCountryButton'
                            onClick={this.handleButtonClick}
                            id='registerselectCountry'
                          >
                            <img
                              alt='countryFlag'
                              style={{ width: 35, height: 30, marginRight: 10 }}
                              src={this.state.countryFlag}
                            />
                            {/*this.state.countryCode
                          ? this.state.countryCode
                        : 'Select Code'*/}
                            <img
                              style={{
                                transform: this.state.open
                                  ? 'rotateZ(180deg)'
                                  : '',
                              }}
                              src={downWhiteArrow}
                              alt='down'
                            />
                          </Button>

                          <input
                            className='phoneInput'
                            type='text'
                            placeholder='Phone Number *'
                            value={phoneNumber}
                            pattern='[0-9]*'
                            // maxLength="10"
                            onChange={(evt) => {
                              const phoneNumber = evt.target.validity.valid
                                ? evt.target.value
                                : this.state.phoneNumber;
                              this.setState(
                                {
                                  phoneNumber,
                                },
                                () => {
                                  this.checkPhoneValidation();
                                }
                              );
                            }}
                            id='registerselectphoneInput'
                          />
                        </div>
                      </div>

                      <div style={{ marginLeft: 0 }}>
                        {this.state.open && (
                          <div className='countryListDropdown'>
                            <div
                              className='searchInput'
                              style={{
                                height: 40,
                                background: '#17171D',
                                paddingTop: 10,
                              }}
                            >
                              <input
                                className='searchInputField'
                                type='text'
                                placeholder='Search...'
                                onChange={(e) =>
                                  this.setState(
                                    {
                                      filterCountry: e.target.value,
                                    },
                                    () => {
                                      // console.log(this.state.filterCountry)
                                    }
                                  )
                                }
                              />
                            </div>
                            <div className='countryListDropdownScroller'>
                              {filteCountryData &&
                                filteCountryData.map((item, i) => (
                                  <div
                                    key={i}
                                    onClick={() => {
                                      let countryCode = item.dial_code
                                        .split(' ')
                                        .join('');
                                      this.setState(
                                        {
                                          countryCode: countryCode,
                                          countryFlag: item.countryFlag,
                                          open: false,
                                          filterCountry: '',
                                        },
                                        () => {
                                          this.checkPhoneValidation();
                                        }
                                      );
                                    }}
                                  >
                                    <img
                                      alt='countryFlag'
                                      style={{ width: 35, height: 30 }}
                                      src={item.countryFlag}
                                    />
                                    <span
                                      style={{ fontSize: 16, marginLeft: 10 }}
                                    >
                                      {item.dial_code}
                                    </span>
                                    <span
                                      style={{ fontSize: 16, marginLeft: 5 }}
                                    >
                                      {item.name}
                                    </span>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )}
                      </div>
                      {!countryCode && isSubmit && (
                        <div
                          className='redBoxErrorMsg center'
                          style={{ padding: 10 }}
                        >
                          {authErrorMessage(
                            authValidation.PROFILE_PHONE_COUNTRYCODE
                          )}
                        </div>
                      )}
                      {!phoneNumber && isSubmit && (
                        <div
                          className='redBoxErrorMsg center'
                          style={{ padding: 10 }}
                        >
                          {authErrorMessage(authValidation.PROFILE_PHONENUMBER)}
                        </div>
                      )}
                      {/* {phoneNumber &&
                      isSubmit &&
                      (phoneNumber.length <= 7 || phoneNumber.length > 10) &&
                      <div
                        className="redBoxErrorMsg center"
                        style={{padding: 10}}
                      >

                        {authErrorMessage (
                          authValidation.PROFILE_PHONENUMBER_FORMAT
                        )}

                      </div>} */}
                      {phoneNumber &&
                        isSubmit &&
                        this.state.mobileCheckvalidFormat && (
                          <div
                            className='redBoxErrorMsg center'
                            style={{ padding: 10 }}
                          >
                            {authErrorMessage(
                              authValidation.PROFILE_PHONENUMBER_FORMAT
                            )}
                          </div>
                        )}

                      <div
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        <Button
                          // style={{cursor: this.state.captchaToken ? '' : 'unset'}}
                          className='stepnextbu'
                          onClick={() => this.doUpdatePhone()}
                        >
                          Next
                        </Button>
                      </div>
                    </div>
                  )}
                  {!isChangePhone && (
                    <div
                      style={{
                        margin: '20px 0',
                      }}
                    >
                      <p className='title3'>
                        We'll send an SMS with a 6-digit OTP code to{' '}
                        {this.state.countryCode}
                        {this.state.phoneNumber}. Please check the reCAPTCHA
                        below to proceed.
                      </p>

                      {this.state.captchaToken && (
                        <div
                          style={{
                            textAlign: 'center',
                            margin: '10px 0',
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          
                          <input
                            style={{ paddingLeft: 30 }}
                            className='step1inpu'
                            type='text'
                            placeholder='Enter 6 digit code'
                            value={otpValue}
                            onChange={(e) =>
                              this.setState({
                                otpValue: e.target.value,
                              })
                            }
                          />
                          <div className='otpMobileEllipse'>
                            <span style={{ fontWeight: 'bold' }}>
                              {' '}
                              {this.state.time.s}
                            </span>{' '}
                            s
                          </div>
                        </div>
                      )}

                      {this.state.time.s === 0 && this.state.captchaToken && (
                        <div style={{ marginTop: 20, marginLeft: 30 }}>
                          <Button
                            className='stepnextbu'
                            onClick={() => {
                              this.timer = 0;
                              this.setState(
                                {
                                  captchaToken: '',
                                },
                                () => {
                                  this.signInWithPhone();
                                }
                              );
                              //   this.doCallSendOTP();
                              // this.onSubmit ();
                            }}
                            data-rh=' Resend OTP'
                          >
                            Resend OTP
                          </Button>
                        </div>
                      )}
                      <div>
                        {!otpValue && isSubmit && (
                          <div
                            className='redBoxErrorMsg'
                            style={{ padding: 10 }}
                          >
                            {authErrorMessage(
                              authValidation.PROFILE_PHONENUMBER_OTP
                            )}
                          </div>
                        )}
                        {this.state.confirmOTPError && (
                          <div
                            className='redBoxErrorMsg'
                            style={{ padding: 10 }}
                          >
                            {this.state.confirmOTPError}
                          </div>
                        )}
                      </div>
                      {
                        <div
                          style={{
                            textAlign: 'center',

                            marginBottom: 15,
                          }}
                        >
                          <div
                            id='phoneVerify'
                            style={{
                              padding: 5,
                              backgroundColor: '#fff',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          />
                        </div>
                      }
                      {this.state.time.s > 0 && (
                        <div style={{ textAlign: 'center', marginTop: 12 }}>
                          <Button
                            style={{
                              cursor: this.state.captchaToken ? '' : 'unset',
                            }}
                            disabled={!this.state.captchaToken}
                            className='stepnextbu'
                            onClick={() => this.doConfirmOTP()}
                            data-rh='Next'
                          >
                            Next
                          </Button>
                          {/*<p
                          data-rh=" Change Phone Number "
                          className="changePhoneText"
                          onClick={() =>
                            this.setState ({
                              isChangePhone: true,
                            })}
                        >
                          Change Phone Number{' '}
                          </p>*/}
                        </div>
                      )}
                      {!this.state.captchaToken && (
                        <div style={{ textAlign: 'center', marginBottom: 10 }}>
                          <p
                            data-rh=' Change Phone Number '
                            className='changePhoneText'
                            onClick={() =>
                              this.setState({
                                isChangePhone: true,
                              })
                            }
                          >
                            Change Phone Number
                          </p>
                        </div>
                      )}

                      {!this.state.captchaToken && (
                        <div
                          style={{
                            textAlign: 'center',
                            paddingLeft: 30,
                            paddingRight: 30,
                          }}
                        >
                          <p className='cantchangePhoneText'>
                            After robot can't be change mobile number you have
                            to wait for 60 seconds to change the mobile number
                          </p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            {this.state.step === 3 && (
              <div className="profileCompletionBoxAlignment"> 
                <div>
                <div
                  style={{ width: '100%', textAlign: 'center', padding: 20 }}
                >
                  <img src={logo} alt='logo' className='logo' />
                </div>
                <Form onSubmit={this.onSubmit}>
                  <div className='signmaindiv'>
                    {/*showingAlert &&
                      <Alert color="danger" onLoad={this.timer}>
                        {error.message}
                    </Alert>*/}
                    <div className='setupacc'>Setup Account</div>
                    <div style={{ width: '46%', marginBottom: '25px' }}>
                      <Progress value={step * 33.3} className='profgress' />
                    </div>
                    <div className='insertpersod'>
                      Insert your{' '}
                      <span style={{ fontWeight: 'bold' }}>PERSONAL</span>{' '}
                      DETAILS
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '87%',
                        margin: ' 0 auto',
                        marginBottom: 25,
                      }}
                    >
                      <div style={{ textAlign: 'center' }}>
                        {!profilePicture && (
                          <div>
                            <img
                              src={placeholderProfile}
                              className='placeholder'
                            />
                          </div>
                        )}
                        {profilePicture && (
                          <div>
                            <img
                              src={profilePicture}
                              className='placeholder'
                              style={{
                                width: 100,
                                height: 100,
                                borderRadius: 100,
                              }}
                            />
                          </div>
                        )}
                        {!profilePicture && isSubmit && (
                          <p className='validationerrorMessage'>
                            {authErrorMessage(authValidation.PROFILE_PICTURE)}
                          </p>
                        )}
                      </div>
                      <div className='inlinePictureOrButton'>
                        <div style={{ marginLeft: 20 }}>
                          <div className='placetexj'>
                            Display Profle Picture
                          </div>
                          <div className='placetexj2'>
                            Please upload a photo of type JPEG or PNG
                          </div>
                        </div>
                        <div>
                          <label className='uploasscxd'>
                            <input
                              type='file'
                              name='profilePicture'
                              onChange={(e) => this.onChange(e)}
                              accept='image/*'
                            />
                            <i className='fa fa-cloud-upload' />
                            Upload
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className='signUpProfileBox'>
                      <div>
                        <p className='title'>Title</p>

                        {/* <Dropdown
                          className='drinpu'
                          style={{ width: 90, textAlign: 'center' }}
                          value={title}
                          options={titleDropDopwn}
                          onChange={(e) => this.setState({ title: e.value })}
                        /> */}

                        <SelectInput
                            items={titleDropDopwn}
                            value={title}
                            className='registerProfileCompleteTitleDropdown'
                            dropDownContainerClass='registerProfileCompleteTitleContainerDropdown'
                            onChange={value => {
                              this.setState({
                                title: value
                              })
                            }}
                          />

                        {!title && isSubmit && (
                          <p
                            className='validationerrorMessage'
                            style={{ paddingLeft: 10 }}
                          >
                            {authErrorMessage(authValidation.PROFILE_TITLE)}
                          </p>
                        )}
                      </div>
                      <div>
                        <p className='title'>First Name</p>
                        <input
                          className='nameinpu'
                          type='text'
                          name='firstname'
                          id='firstname'
                          // placeholder=" First Name*"
                          value={CapitalizeFirstLetter(firstName)}
                          onChange={(e) =>
                            this.setState(
                              byPropKey('firstName', e.target.value)
                            )
                          }
                        />
                        {!firstName && isSubmit && (
                          <p
                            className='validationerrorMessage'
                            style={{ paddingLeft: 20 }}
                          >
                            {authErrorMessage(authValidation.PROFILE_FIRSTNAME)}
                          </p>
                        )}
                      </div>
                      <div>
                        <p className='title'>Last Name</p>
                        <input
                          className='nameinpu'
                          type='text'
                          name='lastname'
                          id='lastname'
                          // placeholder="Last Name"
                          value={CapitalizeFirstLetter(lastName)}
                          onChange={(e) =>
                            this.setState(byPropKey('lastName', e.target.value))
                          }
                        />
                        {!lastName && isSubmit && (
                          <p
                            className='validationerrorMessage'
                            style={{ paddingLeft: 20 }}
                          >
                            {authErrorMessage(authValidation.PROFILE_LASTNAME)}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className='signUpProfileBoxDOBFields'>
                      <div
                        style={{ position: 'relative', flexGrow: '0.5' }}
                        ref={this.openDobDatepicker}
                      >
                        <p className='title'>Date of Birth</p>
                        <div
                          className='datePickerinput'
                          onClick={() =>
                            this.setState({
                              isOpenDobPicker: !isOpenDobPicker,
                            })
                          }
                        >
                          {this.state.dateOfbirth
                            ? moment(this.state.dateOfbirth).format(
                                'MMM DD, yyyy'
                              )
                            : 'MM/DD/YYYY'}
                        </div>
                        {isOpenDobPicker && (
                          <div style={{ position: 'absolute' }}>
                            <Calendar
                              // minDate={sendDate}
                              minDate={new Date('01/01/1900')}
                              maxDate={new Date()}
                              selectedDate={this.state.dateOfbirth}
                              onSubmit={(value) => {
                                this.setState({
                                  isOpenDobPicker: false,
                                });
                                if (value) {
                                  this.setState({
                                    dateOfbirth: value,
                                  });
                                }
                              }}
                            />
                          </div>
                        )}
                        {!dateOfbirth && isSubmit && (
                          <p
                            className='validationerrorMessage'
                            style={{ paddingLeft: 20 }}
                          >
                            {authErrorMessage(authValidation.PROFILE_DOB)}
                          </p>
                        )}

                        {/*<p className="title">Preferred Display Name</p>
                        <input
                          className="nameinpu2"
                          type="text"
                          name="displayName"
                          id="displayName"
                          // placeholder="Preferred Display Name"
                          value={displayName}
                          onChange={e =>
                            this.setState (
                              byPropKey ('displayName', e.target.value)
                            )}
                        />
                        {!displayName &&
                          isSubmit &&
                          <p className="validationerrorMessage" style={{paddingLeft:20}}>
                          
                            {authErrorMessage(authValidation.PROFILE_DISPLAYNAME)}
                          </p>}
                        */}
                      </div>
                      <div>
                        <p className='title'>Gender</p>

                        {/* <Dropdown
                          className='drinpu'
                          style={{ width: 300 }}
                          value={gender}
                          options={genderDropDopwn}
                          onChange={(e) => this.setState({ gender: e.value })}
                        /> */}

                            <SelectInput
                            items={genderDropDopwn}
                            value={gender}
                            className='registerProfileCompleteGenderDropdown'
                            dropDownContainerClass='registerProfileCompleteGenderContainerDropdown'
                            onChange={value => {
                              this.setState({
                                gender: value
                              })
                            }}
                          />
                        {!gender && isSubmit && (
                          <p
                            className='validationerrorMessage'
                            style={{ paddingLeft: 10 }}
                          >
                            {authErrorMessage(authValidation.PROFILE_GENDER)}
                          </p>
                        )}
                        {/*<p className="title">Preferred Search Handle</p>
                        <input
                          className="nameinpu2"
                          // type="text"
                          name="searchHandle"
                          id="searchHandle"
                          placeholder="@"
                          value={searchHandle}
                          onChange={e =>
                            this.setState (
                              byPropKey ('searchHandle', e.target.value)
                            )}
                        />
                        {!searchHandle &&
                          isSubmit &&
                          <p className="validationerrorMessage" style={{paddingLeft:20}}>
                            {authErrorMessage(authValidation.PROFILE_SEARCHHANDLE)}
                          
                        </p>}*/}
                      </div>
                    </div>
                    <div style={{ textAlign: 'center', width: '100%' }}>
                      <Button
                        type='submit'
                        className='nextsignu'
                        onClick={this.onSubmit}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </Form>
              </div>
              </div>

            )}

            {this.state.step === 4 && (
              <div>
                <div
                  style={{ marginTop: 50, width: '100%', textAlign: 'center' }}
                >
                  <img src={logo} alt='logo' className='logo' />
                </div>
                <div className='step3maindi'>
                  <div className='setupacc2'>Setup Account</div>
                  <div className='progress-container'>
                    <Progress value={step * 33.3} className='profgress2' />
                  </div>
                  <div className='successAccount'>
                    <img src={accountSuccess} alt='success' />

                    <div className='AccountCr'>Account Created!</div>

                    <div className='Thankyoumsg'>
                      <p> To log into your account, </p>
                      <p>Lets verify your email.</p>

                      <p
                        style={{
                          paddingLeft: 20,
                          paddingRight: 20,
                          paddingTop: 20,
                        }}
                      >
                        We’ve sent an email with instructions to verify your
                        email address to <span>{this.state.emailAddress} </span>{' '}
                        Click the URL link in the email message within 24 hours.
                      </p>
                    </div>
                  </div>
                  <div
                    style={{ textAlign: 'center' }}
                    className='successAccountFooter'
                  >
                    <Button
                      className='step3buttonl'
                      onClick={() => this.props.history.push(routes.SIGN_IN)}
                    >
                      Proceed to Login
                    </Button>

                    <div className='Thankyoumsg'>
                      <Button
                        className='resendConfirmation'
                        onClick={() => this.emailResend()}
                      >
                        <p>Resend confirmation email</p>
                      </Button>
                    </div>

                    <Button
                      className='startMeetingAuthButtons'
                      onClick={() =>
                        (window.location.href =
                          'http://connect.radiologex.com/')
                      }
                      data-rh='Start meeting'
                    >
                      Start a Meeting
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>

          {loadedImage && (
            <ImageCrop
              image={loadedImage}
              onSubmitClick={(croppedImage) => {
                this.setState({
                  loadedImage: '',
                });

                this.updateUserImage(croppedImage);
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userinfo: state.Authreducer,
    profileUpdated: state.ContactsReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      register,
      Loading,
      updateFirebaseToken,
      uploadUserProfile,
      DoRegisterModifyPersonalInfo,
      signOut,
      resendEmail,
      fetchStatusCode,
      sendOTP,
      verifyOTP,
      resetResetPasswordReducer,
      resetUserReducer,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCompletion);
