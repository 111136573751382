import React, { Component } from 'react';
import './InputCustom.css';
import chevronDown from "../../images/icons/chevron-down.svg";


export default class InputCustom extends Component {
    constructor(props) {
        super(props);

        // this.props.options[1].name = "GAY";
    }

    createList = () => {
        let list = [];
        for (let i = 0; i < this.props.options.length; i++) {
            list.push(
                <li className="select-box-list" key={i}>
                    <label className="select-box__option" htmlFor={i} aria-hidden="aria-hidden" value={this.props.options[i].value}>
                        {this.props.options[i].name}
                    </label>
                </li>
            )
        }
        return list;
    }

    createContent = () => {
        let content = [];
        for (let i = 0; i < this.props.options.length; i++) {
            content.push(
                <div className="select-box__value" key={i}>
                    <input
                        className="select-box__input"
                        type="radio"
                        id={i}
                        // value={i + 1}
                        value={this.props.options[i].value}
                        name={this.props.options[i].name}
                        onChange={this.handleChange}
                        checked={(this.props.value == this.props.options[i].value)} />
                    <p className="select-box__input-text">{this.props.options[i].name}</p>
                </div>
            )
        }
        return content;
    }

    handleChange = (event) => {

        this.props.onSelect(event.target.value);
        // this.props.onSelect(this.props.options[event.target.value-1].value);
    }

    render() {
        return(
            <div className="select-box">
                <div className="select-box__current" tabIndex="1">
                    {this.createContent()}
                    <img className="select-box__icon" src={chevronDown} alt="Arrow Icon" aria-hidden="true" />
                </div>
                <ul className="select-box__list">
                    {this.createList()}
                </ul>
            </div>
        ); 
    }

}