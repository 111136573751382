import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from 'reactstrap';
import './../css/login.css';
import rightArrow from '../images/icons/rightArrow.png';
import leftbackarrow from '../images/icons/leftbackarrow.png';

import PrivacyPolicy from './termsandConditions/privacyPolicy';
import CookiesPolicy from './termsandConditions/cookiesPolicy';
import AboutAppPolicy from './termsandConditions/aboutApp';
import TermsConditionPolicy from './termsandConditions/termsConditions';
import logo from './logo.svg';

class TermsConditionPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeView: '',
    };
  }

  openView(value) {
    this.setState({
      activeView: value,
    });
  }

  componentDidMount() {}

  render() {
    const { activeView } = this.state;
    return (
      <div className='gradientColorPurpleBlue div-flex mainContinerterms'>
        <div className='innerTermsBox'>
          {!activeView && (
            <div className='itemListBox'>
              <img alt='' src={logo} className='logo' />

              <div>
                <div className='itemTitle' onClick={() => this.openView(1)}>
                  <div>
                    About and App Versions
                    <p>Radiologex version 1.1</p>
                  </div>
                  <div>
                    <img src={rightArrow} alt='rightarrow' />
                  </div>
                </div>
              </div>
              <div>
                <div className='itemTitle' onClick={() => this.openView(2)}>
                  <div>
                    Cookies Policy
                    <p>Read Cookies Policy</p>
                  </div>
                  <div>
                    <img src={rightArrow} alt='rightarrow' />
                  </div>
                </div>
              </div>
              <div>
                <div className='itemTitle' onClick={() => this.openView(3)}>
                  <div>
                    Terms and Conditions
                    <p>Read T&C</p>
                  </div>
                  <div>
                    <img src={rightArrow} alt='rightarrow' />
                  </div>
                </div>
              </div>
              <div>
                <div className='itemTitle' onClick={() => this.openView(4)}>
                  <div>
                    Privacy Policy
                    <p>Read Privacy Policy</p>
                  </div>
                  <div>
                    <img src={rightArrow} alt='rightarrow' />
                  </div>
                </div>
              </div>
            </div>
          )}
          {activeView && (
            <div>
              <div className='termsFixHeader'>
                <div style={{ textAlign: 'center' }}>
                  <img alt='' src={logo} className='logo' />
                </div>
                <div style={{ textAlign: 'right' }}>
                  <Button
                    className='termsbackbutton'
                    onClick={() => this.setState({ activeView: '' })}
                  >
                    <div>
                      <img src={leftbackarrow} alt='backIcon' />
                    </div>
                    Go <span> Back</span>
                  </Button>
                </div>
              </div>
              <div className='scrollContainerTerms'>
                {activeView === 1 && <AboutAppPolicy />}
                {activeView === 2 && <CookiesPolicy />}
                {activeView === 3 && <TermsConditionPolicy />}
                {activeView === 4 && <PrivacyPolicy />}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(TermsConditionPage);
