import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Input, Modal, ModalBody, textarea } from 'reactstrap'
import moment from 'moment'
import plusicon from '../../../../../images/pay/plus.png'
import deleteicon from '../../../../../images/icons/deleteicon.png'

// import deleteicon from '../../../../../images/icons/deleteicon.png';

import './sellerAddProduct.css'
import leftbackarrow from '../../../../../images/icons/leftbackarrow.png'

import productActionIcon from '../../../../../images/icons/productActionIcon.png'
import marketPlaceCart from '../../../../../images/icons/marketPlaceCart.png'

import crossButtonMarketPlacesuccess from '../../../../../images/icons/crossButtonMarketPlacesuccess.png'

import SellerConsoleSidemenu from '../../sellerConsole/sellerSidemenu/sellerConsoleSidemenu'
import {
  createNewProduct,
  resetdoOrderValue,
  getSellerInventory,
  modifyProduct,
  deListMarketPlace,
  deleteMarketPlace,
  marketplaceGetCountryOfOriginList,
  fetchProductManufacturer
} from '../../../../../actions/marketplaceaction'
import * as routes from '../../../../../constants/routes'
import RightSideContainer from '../../../../../components/UI/rightSideContainer/rightSideContainer'
import SelectInput from '../../../../InputCustom/selectInput/selectDropdown'
// import CategoryList
//   from '../../../../../constants/localJSON/productCategory.json';

import { createNotification } from '../../../../../constants/notificationtoast'
import { CapitalizeFirstLetter } from '../../../../../constants/helper'

import Calendar from '../../../../../components/UI/calendar/calendar'

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
})
// const manufactureOptions = [
//   {label: 'Siemens Servicing', value: 'Siemens Servicing'},
//   {label: 'Shimadzu Servicing', value: 'Shimadzu Servicing'},
//   {label: 'GE Servicing', value: 'GE Servicing'},
// ];
// const location = [{label: 'California', value: 'California'}];
// const workingConditionOptions = [
//   {label: 'Fully-operational', value: 'Fully-operational'},
//   {label: 'Faulty', value: 'Faulty'},
// ];
// const condition = [
//   {label: '1 - worst condition', value: '1'},
//   {label: '2', value: '2'},
//   {label: '3', value: '3'},
//   {label: '4', value: '4'},
//   {label: '5', value: '5'},
//   {label: '6', value: '6'},
//   {label: '7', value: '7'},
//   {label: '8', value: '8'},
//   {label: '9', value: '9'},
//   {label: '10 - mint condition', value: '10'},
// ];
// const logisticslocaltionType = [
//   {label: 'Ground', value: 'Ground'},
//   {label: 'Higher-floor', value: 'Higher-floor'},
// ];
// const logisticsaccessibilityOptions = [
//   {label: 'Street-side', value: 'Street-side'},
//   {label: 'Deeper Location', value: 'Deeper Location'},
// ];
// const logisticsRigging = [
//   {label: 'Not applicable', value: 'Not applicable'},
//   {label: 'Standard', value: 'Standard'},
//   {label: 'Advanced', value: 'Advanced'},
// ];
// const Ownership = [
//   {label: 'Owned by seller', value: 'Owned by seller'},
//   {label: 'Owned by third-party', value: 'Owned by third-party'},
// ];

// const titleAvailabilityOptions = [
//   {label: 'Available', value: 'Available'},
//   {label: 'Not Available', value: 'Not Available'},
// ];

// const LoanEquipment = [
//   {label: 'Yes', value: 'Yes'},
//   {label: 'No', value: 'No'},
// ];
// const deviceType = [{label: 'MRI', value: 'MRI'}, {label: 'CT', value: 'CT'}];
// const logisticsReporttypeOptions = [{label: 'Self-declared', value: 'Self-declared'}];
// const expirationProductOptions = [
//   {label: 'Yes', value: 'Yes'},
//   {label: 'No', value: 'No'},
// ];
// const countryOrigin = [
//   {label: 'Canada', value: 'Canada'},
//   {label: 'Mexico', value: 'Mexico'},
//   {label: 'United States of America', value: 'United States of America'},
// ];

class SellerAddProduct extends Component {
  constructor (props) {
    super(props)
    this.state = {
      productDetail: [],
      productImageList: [],
      selectedImages: [],
      deviceName: '',
      devicePrice: '',
      deviceTypeValue: '',
      deviceLocation: '',
      manufactureValue: '',
      systemname: '',
      manufacturedate: null,
      workingcondition: '',
      conditionValue: '',
      consoletype: '',
      logisticslocaltionTypeValue: '',
      logisticsaccessibilityValue: '',
      logisticsRiggingValue: '',
      ownershipValue: '',
      titleAvailabilityValue: '',
      LoanEquipmentValue: '',
      inspectionReportFileName: '',
      logisticsreporttypeValue: '',
      inspectionReportFileType: '',
      inspectionReport: '',
      orderQuantity: [],
      itemQuantity: 0,
      isNext: false,
      imgSizeError: false,
      productId: '',
      delistConfirm: false,
      deleteConfirm: false,
      successModels: false,
      saveDraftConfirm: false,
      savePublishConfirm: false,
      productType: '',
      productDescription: '',
      productCategory: '',
      sellerSKU: '',
      brandName: '',
      newProductId: '',
      expirationProductData: '',
      manufactureName: '',
      catalogNumber: '',
      genericKeyword: '',
      importerName: '',
      countryOriginValue: '',
      specificProduct: '',
      itemCondition: '',
      conditionNote: '',
      productSize: '',
      productColor: '',
      productWeight: '',
      titlesearch: '',
      invalidPriceValue: false,
      isOpenManufacturePicker: false,
      countryOriginList: [],
      manufactureOptions: [],
      workingConditionOptions: [],
      deviceTypeOptions: [],
      conditionOptions: [],
      locationOptions: [],
      logisticslocaltionTypeOptions: [],
      logisticsaccessibilityOptions: [],
      logisticsRiggingOptions: [],
      ownershipOptions: [],
      titleAvailabilityOptions: [],
      loanEquipmentOptions: [],
      nonRadioItemConditions: [],
      expirationProductOptions: [],
      nonRadioCategoryList: []
    }
    this.onSelectImage = this.onSelectImage.bind(this)

    this.openManufactureDatepicker = React.createRef()
  }
  componentWillReceiveProps (nextProps) {
    // console.log(nextProps);
    if (nextProps) {
      /* Product Dynamic dropdown values Start */
      if (
        nextProps.addProduct &&
        nextProps.addProduct.productCategorisedValues
      ) {
        const obj = nextProps.addProduct.productCategorisedValues
        console.log(obj)

        // countryOriginList

        if (obj && obj['non-radiology']) {
          // console.log(obj['non-radiology']);
          let nonRadioItemValues = obj['non-radiology']
            ? obj['non-radiology']
            : ''
          if (nonRadioItemValues) {
            let countryOriginList = []
            let nonRadioItemConditions = []
            let expirationProductOptions = []
            let nonRadioCategoryList = []
            if (nonRadioItemValues.countryOfOrigin) {
              Object.entries(nonRadioItemValues.countryOfOrigin).forEach(
                ([key, value]) => {
                  // console.log(key, value);
                  countryOriginList.push({
                    label: value,
                    value: value
                  })
                }
              )
            }
            if (nonRadioItemValues.itemCondition) {
              Object.entries(nonRadioItemValues.itemCondition).forEach(
                ([key, value]) => {
                  // console.log(key, value);
                  nonRadioItemConditions.push({
                    label: value,
                    value: value
                  })
                }
              )
            }
            if (nonRadioItemValues.productExpires) {
              Object.entries(nonRadioItemValues.productExpires).forEach(
                ([key, value]) => {
                  // console.log(key, value);
                  expirationProductOptions.push({
                    label: value,
                    value: value
                  })
                }
              )
            }
            if (nonRadioItemValues.category) {
              Object.entries(nonRadioItemValues.category).forEach(
                ([key, value]) => {
                  // console.log(key, value);
                  nonRadioCategoryList.push({
                    label: value,
                    value: value
                  })
                }
              )
            }

            this.setState(
              {
                countryOriginList: countryOriginList,
                nonRadioItemConditions: nonRadioItemConditions,
                expirationProductOptions: expirationProductOptions,
                nonRadioCategoryList: nonRadioCategoryList
              },
              () => {
                // console.log(this.state.countryOriginList);
                // console.log(this.state.nonRadioItemConditions);
                // console.log(this.state.expirationProductOptions);
                // console.log(this.state.nonRadioCategoryList);
              }
            )
          }
        }
        if (obj && obj.radiology) {
          let productManufactureOptions = []
          let workingConditionOptions = []
          let deviceTypeOptions = []
          let conditionOptions = []
          let locationOptions = []
          let logisticslocaltionTypeOptions = []
          let logisticsaccessibilityOptions = []
          let logisticsRiggingOptions = []
          let ownershipOptions = []
          let titleAvailabilityOptions = []
          let loanEquipmentOptions = []
          let logisticsReporttypeOptions = []
          if (obj.radiology.manufacturer) {
            Object.entries(obj.radiology.manufacturer).forEach(
              ([key, value]) => {
                // console.log(key, value);
                productManufactureOptions.push({
                  label: value,
                  value: value
                })
              }
            )
          }
          if (obj.radiology.workingCondition) {
            Object.entries(obj.radiology.workingCondition).forEach(
              ([key, value]) => {
                // console.log(key, value);
                workingConditionOptions.push({
                  label: value,
                  value: value
                })
              }
            )
          }
          if (obj.radiology.deviceType) {
            Object.entries(obj.radiology.deviceType).forEach(([key, value]) => {
              // console.log(key, value);
              deviceTypeOptions.push({
                label: value,
                value: value
              })
            })
          }
          if (obj.radiology.condition) {
            Object.entries(obj.radiology.condition).forEach(([key, value]) => {
              // console.log(key, value);
              conditionOptions.push({
                label: value,
                value: value
              })
            })
          }
          if (obj.radiology.location) {
            Object.entries(obj.radiology.location).forEach(([key, value]) => {
              // console.log(key, value);
              locationOptions.push({
                label: value,
                value: value
              })
            })
          }
          if (obj.radiology.logisticsLocation) {
            Object.entries(obj.radiology.logisticsLocation).forEach(
              ([key, value]) => {
                // console.log(key, value);
                logisticslocaltionTypeOptions.push({
                  label: value,
                  value: value
                })
              }
            )
          }
          if (obj.radiology.accessibility) {
            Object.entries(obj.radiology.accessibility).forEach(
              ([key, value]) => {
                // console.log(key, value);
                logisticsaccessibilityOptions.push({
                  label: value,
                  value: value
                })
              }
            )
          }
          if (obj.radiology.rigging) {
            Object.entries(obj.radiology.rigging).forEach(([key, value]) => {
              // console.log(key, value);
              logisticsRiggingOptions.push({
                label: value,
                value: value
              })
            })
          }
          if (obj.radiology.ownershipStatus) {
            Object.entries(obj.radiology.ownershipStatus).forEach(
              ([key, value]) => {
                // console.log(key, value);
                ownershipOptions.push({
                  label: value,
                  value: value
                })
              }
            )
          }
          if (obj.radiology.titleAvailability) {
            Object.entries(obj.radiology.titleAvailability).forEach(
              ([key, value]) => {
                // console.log(key, value);
                titleAvailabilityOptions.push({
                  label: value,
                  value: value
                })
              }
            )
          }
          if (obj.radiology.equipmentLiensLoans) {
            Object.entries(obj.radiology.equipmentLiensLoans).forEach(
              ([key, value]) => {
                // console.log(key, value);
                loanEquipmentOptions.push({
                  label: value,
                  value: value
                })
              }
            )
          }
          if (obj.radiology.type) {
            Object.entries(obj.radiology.type).forEach(([key, value]) => {
              // console.log(key, value);
              logisticsReporttypeOptions.push({
                label: value,
                value: value
              })
            })
          }
          this.setState(
            {
              manufactureOptions: productManufactureOptions,
              workingConditionOptions: workingConditionOptions,
              deviceTypeOptions: deviceTypeOptions,
              conditionOptions: conditionOptions,
              locationOptions: locationOptions,
              logisticslocaltionTypeOptions: logisticslocaltionTypeOptions,
              logisticsaccessibilityOptions: logisticsaccessibilityOptions,
              logisticsRiggingOptions: logisticsRiggingOptions,
              ownershipOptions: ownershipOptions,
              titleAvailabilityOptions: titleAvailabilityOptions,
              loanEquipmentOptions: loanEquipmentOptions,
              logisticsReporttypeOptions: logisticsReporttypeOptions
            },
            () => {
              // console.log(this.state.manufactureOptions);
              // console.log(this.state.workingConditionOptions);
              // console.log(this.state.deviceTypeOptions);
              // console.log(this.state.conditionOptions);
              // console.log(this.state.locationOptions);
              // console.log(this.state.logisticslocaltionTypeOptions);
              // console.log(this.state.logisticsaccessibilityOptions);
              // console.log(this.state.logisticsRiggingOptions);
              // console.log(this.state.ownershipOptions);
              // console.log(this.state.titleAvailabilityOptions);
              // console.log(this.state.loanEquipmentOptions);
              // console.log(this.state.logisticsReporttypeOptions);
            }
          )
        }
      }
      /* Product Dynamic dropdown values Close */

      if (nextProps.addProduct && nextProps.addProduct.successModels) {
        this.setState({
          successModels: true
        })
      }
      // if (nextProps.addProduct && nextProps.addProduct.countryList) {
      //   // console.log( nextProps.addProduct.countryList);
      //   this.setState({
      //     countryOriginList:nextProps.addProduct.countryList
      //   })
      // }
      if (nextProps.addProduct && nextProps.addProduct.createorder) {
        if (this.state.addConfirmation) {
          this.props.resetdoOrderValue()
        }
        // this.props.history.push (routes.SELLER_CONSOLE);
        this.setState({
          addConfirmation: false,
          step: 0,
          submitSuccess: true
          // selectedImages: [],
        })
      }
      if (
        nextProps.addProduct &&
        nextProps.addProduct.sellerorders &&
        nextProps.addProduct.sellerorders.length > 0
      ) {
        this.setState(
          {
            productDetail: nextProps.addProduct.sellerorders[0]
          },
          () => {
            const { productDetail, productType } = this.state
            if (productType === 'Radiology') {
              this.setState({
                deviceTypeValue: productDetail.deviceType,
                manufactureValue: productDetail.manufacturer,
                deviceLocation: productDetail.location,
                workingcondition: productDetail.workingCondition
                  ? productDetail.workingCondition
                  : '',
                conditionValue: productDetail.condition
                  ? productDetail.condition
                  : '',
                logisticslocaltionTypeValue: productDetail.logisticsLocation
                  ? productDetail.logisticsLocation
                  : '',
                logisticsaccessibilityValue: productDetail.accessibility
                  ? productDetail.accessibility
                  : '',
                logisticsRiggingValue: productDetail.rigging
                  ? productDetail.rigging
                  : '',
                logisticsreporttypeValue: productDetail.inspectionReportType,
                manufacturedate: productDetail.dateOfManufacture
                  ? new Date(productDetail.dateOfManufacture)
                  : null,
                ownershipValue: productDetail.ownershipStatus,
                titleAvailabilityValue: productDetail.titleAvailability,
                LoanEquipmentValue: productDetail.equipmentLiensLoans,
                deviceName: productDetail.deviceSaleName,
                systemname: productDetail.systemName
                  ? productDetail.systemName
                  : '',
                consoletype: productDetail.consoleType
                  ? productDetail.consoleType
                  : '',
                devicePrice: productDetail.priceUSD,
                titlesearch: productDetail.titleSearchLink,
                // inspectionReportFileType:'',
                // inspectionReport:  '',
                inspectionReportFileName: productDetail.inspectionReportName,
                productImageList: productDetail.productImageList,
                itemQuantity: productDetail.inventoryQuantity,
                productDescription: productDetail.description
                // logisticsreporttypeValue:productDetail.reportType,
              })
            } else {
              this.setState({
                deviceName: productDetail.productName,
                sellerSKU: productDetail.productSku,
                brandName: productDetail.brand,
                newProductId: productDetail.productID,
                expirationProductData: productDetail.productExpires,
                manufactureName: productDetail.manufacturer,
                catalogNumber: productDetail.catalogNumber
                  ? productDetail.catalogNumber
                  : '',
                genericKeyword: productDetail.genericKeywords
                  ? productDetail.genericKeywords
                  : '',
                importerName: productDetail.importerName
                  ? productDetail.importerName
                  : '',
                countryOriginValue: productDetail.countryOfOrigin
                  ? productDetail.countryOfOrigin
                  : '',
                specificProduct: productDetail.specificProductUse
                  ? productDetail.specificProductUse
                  : '',
                itemCondition: productDetail.itemCondition
                  ? productDetail.itemCondition
                  : '',
                conditionNote: productDetail.conditionNote
                  ? productDetail.conditionNote
                  : '',
                productSize: productDetail.size ? productDetail.size : '',
                productColor: productDetail.color ? productDetail.color : '',
                productWeight: productDetail.weight ? productDetail.weight : '',
                productDescription: productDetail.description,
                itemQuantity: productDetail.inventoryQuantity,
                devicePrice: productDetail.priceUSD,
                productCategory: productDetail.category,
                productImageList: productDetail.productImageList
              })
            }
          }
        )
      }
    }
  }

  handleClickOutside = event => {
    if (
      this.openManufactureDatepicker.current &&
      !this.openManufactureDatepicker.current.contains(event.target)
    ) {
      this.setState({
        isOpenManufacturePicker: false
      })
    }
  }
  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
    this.props.resetdoOrderValue()

    let orderQuantity = []
    let count = 1
    for (var i = 0; i < 100; i++) {
      let countValue = count++
      orderQuantity.push({ label: countValue, value: countValue })
    }
    this.setState(
      {
        orderQuantity: orderQuantity
      },
      () => {
        // let sendRequest={
        //   data:{}
        // }
        // this.props.marketplaceGetCountryOfOriginList(sendRequest);
        // console.log(this.state.orderQuantity);
        this.props.fetchProductManufacturer()
      }
    )

    if (this.props.match.params && this.props.match.params.productType) {
      // if(this.props.match.params.productType==='Radiology' || this.props.match.params.productType==='Non-Radiology' ){
      // console.log(this.props.match.params.productType);
      this.setState({
        productType: this.props.match.params.productType
      })
      // }
    }

    // if (this.props.location.state.productType === 'Radiology & Equipment') {
    //   productType = 'Radiology';
    // }
    // if (this.props.location.state.productType === 'Products & PPE') {
    //   productType = 'Products';
    // }
    // this.setState ({
    //   productType: productType,
    // });

    if (
      this.props.match.params &&
      this.props.match.params.productId !== 'create'
    ) {
      let sendInventoryRequest = {
        data: {
          docID: this.props.match.params.productId
        }
      }
      this.setState({
        productId: this.props.match.params.productId
      })
      this.props.getSellerInventory(sendInventoryRequest)
    } else {
      if (
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.productType
      ) {
      }
      this.props.resetdoOrderValue()
    }
  }
  closeModal () {
    this.setState({
      delistConfirm: false,
      deleteConfirm: false,
      successModels: false,
      saveDraftConfirm: false,
      savePublishConfirm: false
    })
  }
  onSelectImage = e => {
    let reseponse = this.setState({ imgSizeError: false, imgMaxError: false })

    // if (
    //   (e.target.files &&
    //     e.target.files.length +
    //       this.state.selectedImages.length +
    //       this.state.productImageList.length >
    //       1) ||
    //   e.target.files.length > 1
    // ) {
    // console.log(e.target.files.length);

    if (e.target.files && e.target.files.length > 5) {
      this.setState({
        imgMaxError: true
      })
      createNotification('warning', "Can't upload more than 5 images")
      return
    }
    if (
      e.target.files.length +
        this.state.selectedImages.length +
        this.state.productImageList.length >
      5
    ) {
      createNotification('warning', "Can't upload more than 5 images")
      return
    }

    if (!reseponse) {
      let self = this // unsure if this is needed

      for (let files of e.target.files) {
        let response = files.size / 1000000

        if (response && response > 5) {
          self.setState({
            imgSizeError: true
          })
          return
        }

        let fileType = files.name.split('.').pop()

        if (
          fileType.toLowerCase() === 'png' ||
          fileType.toLowerCase() === 'jpeg' ||
          fileType.toLowerCase() === 'jpg'
        ) {
          let reader = new FileReader()
          let file = files

          reader.onloadend = () => {
            var filename = new Date().getTime().toString() + files.name
            filename = filename.replace(/\s/g, '')

            self.setState({
              selectedImages: self.state.selectedImages.concat({
                base64: reader.result,
                fileType: 'image/jpeg',
                filename: filename
              })

              // tempImage.push({
              //   base64: reader.result,
              //   fileType: 'image/jpeg',
              //   filename: filename,
              // })
              // console.log(tempImage);

              // self.setState ({
              //   selectedImages: self.state.selectedImages.concat ({
              //     base64: reader.result,
              //     fileType: 'image/jpeg',
              //     filename: filename,
              //   },()=>{
              //       console.log(self.state.selectedImages)
              //   }),
            })
          }

          reader.readAsDataURL(file)
        }
      }
    }
  }
  removeItem (index, status) {
    if (status === 0) {
      let newItems = this.state.productImageList
      newItems.splice(index, 1)
      this.setState({ productImageList: newItems, isRemoveImage: true })
    } else {
      let newItems = this.state.selectedImages
      newItems.splice(index, 1)
      this.setState({ selectedImages: newItems })
    }
  }
  removeFile () {
    this.setState({
      inspectionReport: ''
    })
  }
  onSelectReport = e => {
    let files = e.target.files[0]

    // let fileType = files.name.split ('.').pop ();
    // console.log (fileType);

    // var filename = new Date ().getTime ().toString () + files.name;
    // filename = filename.replace (/\s/g, '');

    let fileType = files.name.split('.').pop()
    let tempFilename = files.name
      .split('.')
      .slice(0, -1)
      .join('.')

    var filename =
      tempFilename +
      moment(new Date()).format('_DDMMYYYY_') +
      new Date().getTime().toString() +
      '.' +
      fileType
    filename = filename.replace(/\s/g, '')
    // console.log (filename);

    if (
      fileType.toLowerCase() === 'png' ||
      fileType.toLowerCase() === 'jpeg' ||
      fileType.toLowerCase() === 'jpg' ||
      fileType.toLowerCase() === 'pdf'
    ) {
      let reader = new FileReader()
      reader.readAsDataURL(files)
      reader.onload = e => {
        this.setState(
          {
            inspectionReportFileType:
              fileType === 'pdf' ? 'application/pdf' : 'image/jpeg',
            inspectionReport: e.target.result,
            inspectionReportFileName: filename
          },
          () => {}
        )
      }
    }
  }
  checkValidation (status) {
    this.setState(
      {
        isNext: true,
        invalidPriceValue: false
      },
      () => {
        const {
          manufactureValue,
          workingcondition,
          conditionValue,
          logisticslocaltionTypeValue,
          logisticsaccessibilityValue,
          logisticsRiggingValue,
          logisticsreporttypeValue,
          selectedImages,
          manufacturedate,
          ownershipValue,
          titleAvailabilityValue,
          LoanEquipmentValue,
          deviceName,
          systemname,
          consoletype,
          devicePrice,
          titlesearch,
          inspectionReportFileType,
          inspectionReport,
          inspectionReportFileName,
          productDetail,
          productImageList,
          isRemoveImage,
          deviceTypeValue,
          deviceLocation,
          itemQuantity,
          productDescription,
          productCategory,
          sellerSKU,
          brandName,
          newProductId,
          expirationProductData,
          manufactureName,
          catalogNumber,
          genericKeyword,
          importerName,
          countryOriginValue,
          specificProduct,
          itemCondition,
          conditionNote,
          productType,
          productSize,
          productColor,
          productWeight
        } = this.state

        let inValid = false
        if (productType === 'Radiology') {
          inValid =
            deviceName === '' ||
            devicePrice === '' ||
            deviceTypeValue === '' ||
            deviceLocation === '' ||
            manufactureValue === '' ||
            // systemname === '' ||
            // manufacturedate === null ||
            workingcondition === '' ||
            // conditionValue === '' ||
            // consoletype === '' ||
            // logisticslocaltionTypeValue === '' ||
            // logisticsaccessibilityValue === '' ||
            // logisticsRiggingValue === '' ||
            ownershipValue === '' ||
            titleAvailabilityValue === '' ||
            LoanEquipmentValue === '' ||
            productDescription === '' ||
            titlesearch === '' ||
            itemQuantity === 0 ||
            selectedImages.length + productImageList.length === 0
        } else {
          inValid =
            deviceName === '' ||
            devicePrice === '' ||
            productCategory === '' ||
            sellerSKU === '' ||
            brandName === '' ||
            newProductId === '' ||
            expirationProductData === '' ||
            manufactureName === '' ||
            // catalogNumber === '' ||
            // genericKeyword === '' ||
            // importerName === '' ||
            // countryOriginValue === '' ||
            // specificProduct === '' ||
            // itemCondition === '' ||
            // conditionNote === '' ||
            productDescription === '' ||
            // productSize === '' ||
            // productColor === '' ||
            // productWeight === '' ||
            itemQuantity === 0 ||
            selectedImages.length + productImageList.length === 0
        }

        if (inValid) {
          return false
        }

        let sendRequest = {
          data: {}
        }

        if (productType === 'Radiology') {
          sendRequest.data = {
            deviceSaleName: deviceName,
            deviceType: deviceTypeValue,
            priceUSD: Number(devicePrice),
            location: deviceLocation,
            manufacturer: manufactureValue,
            systemName: systemname || '',
            dateOfManufacture: manufacturedate || '',
            workingCondition: workingcondition,
            condition: conditionValue || '',
            consoleType: consoletype || '',
            logisticsLocation: logisticslocaltionTypeValue || '',
            accessibility: logisticsaccessibilityValue || '',
            rigging: logisticsRiggingValue || '',
            reportType: logisticsreporttypeValue,
            ownershipStatus: ownershipValue,
            titleAvailability: titleAvailabilityValue,
            equipmentLiensLoans: LoanEquipmentValue,
            titleSearchLink: titlesearch,
            inspectionReportType: logisticsreporttypeValue,
            inspectionReportFileName: inspectionReportFileName,
            inspectionReportFileType: inspectionReportFileType,
            inspectionReport: inspectionReport,
            inventoryQuantity: itemQuantity,
            docID: this.state.productId ? this.state.productId : '',
            description: productDescription
          }
        } else {
          sendRequest.data = {
            description: productDescription,
            inventoryQuantity: itemQuantity,
            priceUSD: Number(devicePrice),
            manufacturer: manufactureName,
            productCategoryType: productType,
            category: productCategory,
            productSku: sellerSKU,
            brand: brandName,
            productID: newProductId,
            productName: deviceName,
            productExpires: expirationProductData,
            catalogNumber: catalogNumber || '',
            importerName: importerName || '',
            genericKeywords: genericKeyword || '',
            size: productSize || '',
            color: productColor || '',
            weight: productWeight || '',
            countryOfOrigin: countryOriginValue || '',
            specificProductUse: specificProduct || '',
            itemCondition: itemCondition || '',
            conditionNote: conditionNote || '',
            docID: this.state.productId ? this.state.productId : ''
          }
        }

        if (inValid) {
          return false
        }

        const patternpass = /^(0|[1-9]\d*)(\.\d+)?$/
        const result = patternpass.test(this.state.devicePrice)

        if (!result || this.state.devicePrice <= 0) {
          this.setState({
            invalidPriceValue: true
          })
          return
        }

        if (status === 'draft') {
          this.setState({ saveDraftConfirm: true })
        } else {
          this.setState({ savePublishConfirm: true })
        }
      }
    )
  }
  addProduct = isOrderstatus => {
    this.setState(
      {
        isNext: true,
        invalidPriceValue: false
      },
      () => {
        const {
          manufactureValue,
          workingcondition,
          conditionValue,
          logisticslocaltionTypeValue,
          logisticsaccessibilityValue,
          logisticsRiggingValue,
          logisticsreporttypeValue,
          selectedImages,
          manufacturedate,
          ownershipValue,
          titleAvailabilityValue,
          LoanEquipmentValue,
          deviceName,
          systemname,
          consoletype,
          devicePrice,
          titlesearch,
          inspectionReportFileType,
          inspectionReport,
          inspectionReportFileName,
          productDetail,
          productImageList,
          isRemoveImage,
          deviceTypeValue,
          deviceLocation,
          itemQuantity,
          productDescription,
          productCategory,
          sellerSKU,
          brandName,
          newProductId,
          expirationProductData,
          manufactureName,
          catalogNumber,
          genericKeyword,
          importerName,
          countryOriginValue,
          specificProduct,
          itemCondition,
          conditionNote,
          productType,
          productSize,
          productColor,
          productWeight
        } = this.state
        let inValid = false
        if (productType === 'Radiology') {
          inValid =
            deviceName === '' ||
            devicePrice === '' ||
            deviceTypeValue === '' ||
            deviceLocation === '' ||
            manufactureValue === '' ||
            // systemname === '' ||
            // manufacturedate === null ||
            workingcondition === '' ||
            // conditionValue === '' ||
            // consoletype === '' ||
            // logisticslocaltionTypeValue === '' ||
            // logisticsaccessibilityValue === '' ||
            // logisticsRiggingValue === '' ||
            ownershipValue === '' ||
            titleAvailabilityValue === '' ||
            LoanEquipmentValue === '' ||
            productDescription === '' ||
            titlesearch === '' ||
            itemQuantity === 0 ||
            selectedImages.length + productImageList.length === 0
        } else {
          inValid =
            deviceName === '' ||
            devicePrice === '' ||
            productCategory === '' ||
            sellerSKU === '' ||
            brandName === '' ||
            newProductId === '' ||
            expirationProductData === '' ||
            manufactureName === '' ||
            // catalogNumber === '' ||
            // genericKeyword === '' ||
            // importerName === '' ||
            // countryOriginValue === '' ||
            // specificProduct === '' ||
            // itemCondition === '' ||
            // conditionNote === '' ||
            productDescription === '' ||
            // productSize === '' ||
            // productColor === '' ||
            // productWeight === '' ||
            itemQuantity === 0 ||
            selectedImages.length + productImageList.length === 0
        }
        if (inValid) {
          return false
        }

        const patternpass = /^(0|[1-9]\d*)(\.\d+)?$/
        const result = patternpass.test(this.state.devicePrice)

        if (!result || this.state.devicePrice <= 0) {
          this.setState({
            invalidPriceValue: true
          })
          return
        }

        let sendRequest = {
          data: {}
        }

        if (productType === 'Radiology') {
          sendRequest.data = {
            deviceSaleName: deviceName,
            deviceType: deviceTypeValue,
            priceUSD: Number(devicePrice),
            location: deviceLocation,
            manufacturer: manufactureValue,
            systemName: systemname || '',
            dateOfManufacture: manufacturedate || '',
            workingCondition: workingcondition,
            condition: conditionValue || '',
            consoleType: consoletype || '',
            logisticsLocation: logisticslocaltionTypeValue || '',
            accessibility: logisticsaccessibilityValue || '',
            rigging: logisticsRiggingValue || '',
            reportType: logisticsreporttypeValue,
            ownershipStatus: ownershipValue,
            titleAvailability: titleAvailabilityValue,
            equipmentLiensLoans: LoanEquipmentValue,
            titleSearchLink: titlesearch,
            inspectionReportType: logisticsreporttypeValue,
            inspectionReportFileName: inspectionReportFileName,
            inspectionReportFileType: inspectionReportFileType,
            inspectionReport: inspectionReport,
            inventoryQuantity: itemQuantity,
            docID: this.state.productId ? this.state.productId : '',
            description: productDescription
          }
        } else {
          sendRequest.data = {
            description: productDescription,
            inventoryQuantity: itemQuantity,
            priceUSD: Number(devicePrice),
            manufacturer: manufactureName,
            productCategoryType: productType,
            category: productCategory,
            productSku: sellerSKU,
            brand: brandName,
            productID: newProductId,
            productName: deviceName,
            productExpires: expirationProductData,
            catalogNumber: catalogNumber || '',
            importerName: importerName || '',
            genericKeywords: genericKeyword || '',
            size: productSize || '',
            color: productColor || '',
            weight: productWeight || '',
            countryOfOrigin: countryOriginValue || '',
            specificProductUse: specificProduct || '',
            itemCondition: itemCondition || '',
            conditionNote: conditionNote || '',
            docID: this.state.productId ? this.state.productId : ''
          }
        }

        if (!this.state.productId) {
          delete sendRequest.data.docID

          this.props.createNewProduct(
            sendRequest,
            selectedImages,
            isOrderstatus
          )
        } else {
          if (!inspectionReportFileType) {
            delete sendRequest.data.inspectionReportFileName
            delete sendRequest.data.inspectionReportFileType
            delete sendRequest.data.inspectionReport
          }
          let sendImageModify = {
            data: {
              productID: productDetail.docID,
              productImageList: productImageList
            }
          }

          this.props.modifyProduct(
            sendRequest,
            selectedImages,
            isRemoveImage ? sendImageModify : null,
            isOrderstatus,
            this.state.selectedImages.length +
              this.state.productImageList.length
          )
        }
      }
    )
  }
  doDelistProduct () {
    let sendRequest = {
      data: {
        docID: this.state.productId
      }
    }
    this.props.deListMarketPlace(sendRequest)
  }
  doDeleteProduct () {
    let sendRequest = {
      data: {
        docID: this.state.productId
      }
    }
    this.props.deleteMarketPlace(sendRequest)
  }

  render () {
    const {
      deviceName,
      devicePrice,
      deviceLocation,
      productImageList,
      selectedImages,
      productDetail,
      deviceTypeValue,
      isNext,
      manufactureValue,
      titleAvailabilityValue,
      systemname,
      manufacturedate,
      workingcondition,
      conditionValue,
      consoletype,
      logisticslocaltionTypeValue,
      logisticsaccessibilityValue,
      logisticsRiggingValue,
      ownershipValue,
      LoanEquipmentValue,
      logisticsreporttypeValue,
      inspectionReport,
      inspectionReportFileName,
      imgSizeError,
      orderQuantity,
      itemQuantity,
      productDescription,
      productType,
      productCategory,
      sellerSKU,
      brandName,
      newProductId,
      expirationProductData,
      manufactureName,
      catalogNumber,
      genericKeyword,
      importerName,
      countryOriginValue,
      specificProduct,
      itemCondition,
      conditionNote,
      productSize,
      productColor,
      productWeight,
      titlesearch,
      invalidPriceValue,
      isOpenManufacturePicker,
      manufactureOptions,
      workingConditionOptions,
      deviceTypeOptions,
      conditionOptions,
      locationOptions,
      logisticslocaltionTypeOptions,
      logisticsaccessibilityOptions,
      logisticsRiggingOptions,
      ownershipOptions,
      titleAvailabilityOptions,
      loanEquipmentOptions,
      logisticsReporttypeOptions,
      nonRadioItemConditions,
      expirationProductOptions,
      nonRadioCategoryList
    } = this.state

    console.log(orderQuantity)

    const PickerCustomInput = ({ value, onClick }) => (
      <div className='consoletypeinpt' onClick={onClick}>
        {value || 'Select Date'}
      </div>
    )
    return (
      <div className='selleraddDEtailContainer' id='sellerAddnew1'>
        <div>
          <div style={{ textAlign: 'right' }} id='sellerAddnew2'>
            <Button
              className='selleradddsbackbutton'
              onClick={() => this.props.history.goBack()}
              data-rh='Go back'
              id='sellerAddnew3'
            >
              <div>
                <img src={leftbackarrow} alt='backIcon' />
              </div>
              Go <span> Back</span>
            </Button>
          </div>
          <div className='selleradddetailmaindiv' id='sellerAddnew4'>
            <div>
              <div className='selleraddSmensAvapro' id='sellerAddnew5'>
                Seller Console
                <p id='sellerAddnew6'>
                  {' '}
                  {this.state.productId ? 'Edit ' : 'Add New '} Product
                </p>
              </div>
              <div className='sellerGENERAL' id='sellerAddnew7'>
                <span>GENERAL</span> DETAILS
              </div>
              <div>
                <p className='inpursellrlabel' id='sellerAddnew8'>
                  {' '}
                  {productType === 'Radiology'
                    ? 'Device Sale Name'
                    : 'Product Name'}
                </p>
                <Input
                  type='text'
                  // name="deviceName"
                  placeholder='Enter product title'
                  className='sellerinppuaddt'
                  value={CapitalizeFirstLetter(deviceName)}
                  onChange={e =>
                    this.setState(byPropKey('deviceName', e.target.value))
                  }
                  id='sellerAddnew9'
                />
                {!deviceName && isNext && (
                  <p className='validationerrorMessage' id='sellerAddnew10'>
                    {' '}
                    Device sale name is required *{' '}
                  </p>
                )}
              </div>
              <div style={{ display: 'inline-flex' }} id='sellerAddnew11'>
                <div id='sellerAddnew12'>
                  <p className='inpursellrlabel' id='sellerAddnew13'>
                    Price in USD$
                  </p>
                  <Input
                    type='text'
                    name='devicePrice'
                    placeholder=''
                    className='selaionrfduaddt'
                    value={devicePrice}
                    // pattern="[0-9]*"
                    pattern='^\d*(\.\d{0,2})?$'
                    onChange={evt => {
                      const devicePrice = evt.target.validity.valid
                        ? evt.target.value
                        : this.state.devicePrice
                      this.setState({
                        devicePrice
                      })
                    }}
                    id='sellerAddnew14'
                  />
                  {!devicePrice && isNext && (
                    <p className='validationerrorMessage' id='sellerAddnew15'>
                      {' '}
                      Device Price is required *{' '}
                    </p>
                  )}
                  {invalidPriceValue && (
                    <p className='validationerrorMessage' id='sellerAddnew16'>
                      {' '}
                      Enter valid price amount *{' '}
                    </p>
                  )}
                </div>
                <div style={{ marginLeft: 20 }} id='sellerAddnew17'>
                  <p className='inpursellrlabel' id='sellerAddnew18'>
                    {' '}
                    Quantity
                  </p>
                  <div className='sellerdropcopeinpt1' id='sellerAddnew19'>
                    <SelectInput
                      items={orderQuantity}
                      value={this.state.itemQuantity}
                      className='sellerQunatityDropdown'
                      dropDownContainerClass='sellerQunatityContainerDropdown'
                      onChange={value => {
                        this.setState({
                          itemQuantity: value
                        })
                      }}
                      // placeholder='Select Quantity'
                      // labelName="Title"
                      // labelClassName="titlelabel"
                    />

                    {!itemQuantity && isNext && (
                      <p
                        id='sellerAddnew21'
                        className='validationerrorMessage'
                        style={{ marginTop: 20 }}
                      >
                        {' '}
                        Quantity is required *{' '}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {productType === 'Radiology' ? (
                <div id='sellerAddnew22'>
                  <p id='sellerAddnew23' className='inpursellrlabel'>
                    Location
                  </p>
                  <div id='sellerAddnew24' className='sellerdropcopeinpt1'>
                    <SelectInput
                      items={locationOptions}
                      value={deviceLocation}
                      className='sellerCountryLocationInputDropdown'
                      dropDownContainerClass='sellerCountryLocationInputContainerDropdown'
                      onChange={value => {
                        this.setState({
                          deviceLocation: value
                        })
                      }}
                    />

                    {!deviceLocation && isNext && (
                      <p className='validationerrorMessage' id='sellerAddnew26'>
                        {' '}
                        Device Location is required *{' '}
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                <div>
                  <p className='inpursellrlabel' id='sellerAddnew27'>
                    Category
                  </p>
                  <div className='sellerdropcopeinpt1' id='sellerAddnew28'>
                    <SelectInput
                      items={nonRadioCategoryList}
                      value={productCategory}
                      className='sellerCountryLocationInputDropdown'
                      dropDownContainerClass='sellerCountryLocationInputContainerDropdown'
                      onChange={value => {
                        this.setState({
                          productCategory: value
                        })
                      }}
                      placeholder='Category'
                    />
                    {!productCategory && isNext && (
                      <p className='validationerrorMessage' id='sellerAddnew30'>
                        {' '}
                        Category is required *{' '}
                      </p>
                    )}
                  </div>
                </div>
              )}
              <div>
                <p className='inpursellrlabel' id='sellerAddnew31'>
                  Product Description
                </p>
                <textarea
                  maxLength='300'
                  type='text'
                  placeholder='Enter product description (300 character max)'
                  className='addProductTextArea'
                  value={CapitalizeFirstLetter(productDescription)}
                  onChange={e =>
                    this.setState({ productDescription: e.target.value })
                  }
                  id='sellerAddnew32'
                />
                {!productDescription && isNext && (
                  <p className='validationerrorMessage' id='sellerAddnew33'>
                    {' '}
                    Product description is required *{' '}
                  </p>
                )}
              </div>
              <div className='sellerGENERAL' id='sellerAddnew34'>
                PRODUCT <span>IMAGES</span>
              </div>
              <p className='selleriunpolodimg' id='sellerAddnew35'>
                Upload single or multiple JPG/PNG (Maximum 5 MB)
              </p>
              <div
                style={{ display: 'inline-flex', alignItems: 'center' }}
                id='sellerAddnew36'
              >
                <div className='previewImageListing' id='sellerAddnew37'>
                  <div id='sellerAddnew38'>
                    <input
                      type='file'
                      name='file'
                      id='file'
                      multiple
                      onChange={e => this.onSelectImage(e)}
                      accept='image/*'
                      disabled={
                        this.state.selectedImages.length +
                          this.state.productImageList.length ==
                        5
                      }
                    />
                    <label
                      id='sellerAddnew40'
                      className='selleruploadimgbox'
                      htmlFor='file'
                      data-rh='Select Image'
                      style={{
                        opacity:
                          this.state.selectedImages.length +
                            this.state.productImageList.length ==
                          5
                            ? 0.5
                            : ''
                      }}
                    >
                      <img
                        alt='plusicon'
                        src={plusicon}
                        className='psellerlusimg'
                      />
                    </label>
                    {!selectedImages && isNext && (
                      <p className='validationerrorMessage' id='sellerAddnew41'>
                        {' '}
                        Images are required *{' '}
                      </p>
                    )}
                  </div>
                  {productImageList &&
                    productImageList.length > 0 &&
                    productImageList.map((item, idx) => (
                      <div
                        key={idx}
                        className='sellerpriviewimgBox'
                        id={`sellerAddnewproductImageList${idx}`}
                      >
                        <img
                          src={item}
                          alt={item}
                          className='sellerpriviewimg'
                          id={`sellerAddnewproductImageListimg${idx}`}
                        />
                        <Button
                          className='removeImageIConsButton'
                          onClick={() => this.removeItem(idx, 0)}
                          data-rh='Remove'
                          id={`sellerAddnewproductImageListremove${idx}`}
                        >
                          <img
                            src={deleteicon}
                            alt='close Icon'
                            id={`sellerAddnewproductImageListremoveicon${idx}`}
                          />
                        </Button>
                      </div>
                    ))}
                  {this.state.selectedImages &&
                    this.state.selectedImages.length > 0 &&
                    this.state.selectedImages.map((item, idx) => (
                      <div
                        key={idx}
                        className='sellerpriviewimgBox'
                        id={`sellerAddnewproductSelectedImageList${idx}`}
                      >
                        <img
                          src={item.base64}
                          alt={item.filename}
                          className='sellerpriviewimg'
                          id={`sellerAddnewproductSelectedImageListimg${idx}`}
                        />
                        <Button
                          className='removeImageIConsButton'
                          onClick={() => this.removeItem(idx, 1)}
                          data-rh='Remove'
                          id={`sellerAddnewproductSelectedImageListremove${idx}`}
                        >
                          <img
                            src={deleteicon}
                            alt='close Icon'
                            id={`sellerAddnewproductSelectedImageListremoveicon${idx}`}
                          />
                        </Button>
                      </div>
                    ))}
                </div>
              </div>
              {imgSizeError && (
                <div className='imgwerrormsdiv' id='sellerAddnew42'>
                  The uploaded photo is too large. Please upload a photo of size
                  less than 5MB.
                </div>
              )}

              {this.state.selectedImages.length +
                this.state.productImageList.length ===
                0 &&
                isNext && (
                <p className='validationerrorMessage' id='sellerAddnew43'>
                  {' '}
                    Product Images is required *{' '}
                </p>
              )}
            </div>
            <div
              style={{ marginLeft: 50, marginRight: 50 }}
              id='sellerAddnew44'
            >
              {productDetail && productDetail.status === 'active' && (
                <div style={{ display: 'flex' }} id='sellerAddnew45'>
                  {/* this.state.productId &&
                    <Button
                      className="addprosavebutton"
                      onClick={() => this.setState ({deleteConfirm: true})}
                    >
                      Delete Item
                  </Button> */}
                  {this.state.productId && (
                    <Button
                      className='unlistSubmit'
                      onClick={() => this.setState({ delistConfirm: true })}
                      data-rh='Unlist Item'
                      id='sellerAddnew46'
                    >
                      Unlist Item
                    </Button>
                  )}
                  {this.state.productId && (
                    <Button
                      className='updateListingbutton'
                      onClick={() =>
                        this.setState({ savePublishConfirm: true })
                      }
                      data-rh='Update'
                      id='sellerAddnew47'
                    >
                      Update Listing
                    </Button>
                  )}
                </div>
              )}
              {productDetail && productDetail.status === 'inactive' && (
                <div style={{ display: 'flex' }}>
                  <Button
                    className='addprosavebutton'
                    onClick={() => this.checkValidation('draft')}
                    data-rh={
                      this.state.productId
                        ? 'Update Saved Draft '
                        : 'Save As Draft'
                    }
                    id='sellerAddnew48'
                  >
                    {this.state.productId ? 'Update Saved' : 'Save As'} Draft
                  </Button>
                </div>
              )}
              {((productDetail && productDetail.status === 'draft') ||
                !this.state.productId) && (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  {this.state.productId && (
                    <Button
                      className='addprosavebutton'
                      onClick={() => this.setState({ deleteConfirm: true })}
                      data-rh='Delete'
                      id='sellerAddnew49'
                    >
                      Delete Item
                    </Button>
                  )}

                  <Button
                    className='addprosavebutton'
                    onClick={() => this.checkValidation('draft')}
                    data-rh={
                      this.state.productId
                        ? 'Update Saved Draft '
                        : 'Save As Draft'
                    }
                    id='sellerAddnew50'
                  >
                    {this.state.productId ? 'Update Saved' : 'Save As'} Draft
                  </Button>
                  <Button
                    className='addsubmittomarket'
                    onClick={() => this.checkValidation('publish')}
                    data-rh='Submit'
                    id='sellerAddnew51'
                  >
                    Submit to Marketplace
                  </Button>
                </div>
              )}
              <div className='dropdoowndicaddpro'>
                {productType === 'Radiology' ? (
                  <div>
                    <div className='speciaddatisheading' id='sellerAddnew52'>
                      SPECIFICATIONS
                    </div>

                    <div className='listItems' id='sellerAddnew53'>
                      <p
                        className='addproductManuurer'
                        style={{ paddingTop: 10 }}
                        id='sellerAddnew54'
                      >
                        Manufacturer
                      </p>
                      <div>
                        <SelectInput
                          items={manufactureOptions}
                          value={this.state.manufactureValue}
                          className='sellectSpecificationDropdowns'
                          dropDownContainerClass='sellectSpecificationDropdownsContainer'
                          onChange={value => {
                            this.setState({
                              manufactureValue: value
                            })
                          }}
                          placeholder='Select'
                        />

                        {!manufactureValue && isNext && (
                          <p
                            className='validationerrorMessage'
                            id='sellerAddnew56'
                          >
                            {' '}
                            Manufacture Value is required *{' '}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className='listItems' id='sellerAddnew57'>
                      <p className='addproductManuurer' id='sellerAddnew58'>
                        Device Type
                      </p>
                      <div>
                        <SelectInput
                          items={deviceTypeOptions}
                          value={this.state.deviceTypeValue}
                          className='sellectSpecificationDropdowns'
                          dropDownContainerClass='sellectSpecificationDropdownsContainer'
                          onChange={value => {
                            this.setState({
                              deviceTypeValue: value
                            })
                          }}
                          placeholder='Select'
                        />

                        {!deviceTypeValue && isNext && (
                          <p
                            className='validationerrorMessage'
                            id='sellerAddnew60'
                          >
                            {' '}
                            Device Type Value is required *{' '}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className='listItems' id='sellerAddnew61'>
                      <p className='addproductManuurer' id='sellerAddnew62'>
                        {' '}
                        System Name
                      </p>
                      <div style={{ marginBottom: 5 }} id='sellerAddnew63'>
                        <Input
                          className='consoletypeinpt'
                          id='systemname'
                          name='systemname'
                          placeholder='Enter system Name '
                          value={CapitalizeFirstLetter(this.state.systemname)}
                          onChange={e =>
                            this.setState({ systemname: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    <div className='listItems' id='sellerAddnew65'>
                      <p className='addproductManuurer' id='sellerAddnew66'>
                        Date of Manufacture
                      </p>
                      <div
                        id='sellerAddnew67'
                        style={{ marginBottom: 5, position: 'relative' }}
                        ref={this.openManufactureDatepicker}
                      >
                        {/* <DatePicker
                          className="sellerdropcopeinpt"

                            maxDate={this.state.manufacturedate}
                            placeholderText="Date of Manufacture"
                            selected={this.state.manufacturedate}
                            onChange={date =>
                              this.setState ({manufacturedate: date})}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            closeOnScroll={true}
                            isClearable

                            customInput={<PickerCustomInput />}
                            /> */}

                        <div
                          id='sellerAddnew68'
                          className='sellerdropcopeinpt manufactureDatePicker'
                          onClick={() =>
                            this.setState({
                              isOpenManufacturePicker: !isOpenManufacturePicker
                            })
                          }
                        >
                          {this.state.manufacturedate
                            ? moment(this.state.manufacturedate).format(
                              'DD/MM/YYYY'
                            )
                            : 'DD/MM/YYYY'}
                        </div>

                        {isOpenManufacturePicker && (
                          <div
                            style={{ position: 'absolute' }}
                            id='sellerAddnew69'
                          >
                            <Calendar
                              // minDate={sendDate}
                              maxDate={new Date()}
                              selectedDate={this.state.manufacturedate}
                              onSubmit={value => {
                                this.setState({
                                  isOpenManufacturePicker: false
                                })
                                if (value) {
                                  this.setState({
                                    manufacturedate: value
                                  })
                                }
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className='listItems' id='sellerAddnew70'>
                      <p className='addproductManuurer' id='sellerAddnew71'>
                        {' '}
                        Working Condition
                      </p>
                      <div>
                        <SelectInput
                          items={workingConditionOptions}
                          value={this.state.workingcondition}
                          className='sellectSpecificationDropdowns'
                          dropDownContainerClass='sellectSpecificationDropdownsContainer'
                          onChange={value => {
                            this.setState({
                              workingcondition: value
                            })
                          }}
                          placeholder='Select'
                        />

                        {!workingcondition && isNext && (
                          <p
                            className='validationerrorMessage'
                            id='sellerAddnew73'
                          >
                            {' '}
                            Working Condition is required *{' '}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className='listItems' id='sellerAddnew74'>
                      <p className='addproductManuurer' id='sellerAddnew75'>
                        {' '}
                        Condition
                      </p>
                      <div>
                        <SelectInput
                          items={conditionOptions}
                          value={this.state.conditionValue}
                          className='sellectSpecificationDropdowns'
                          dropDownContainerClass='sellectSpecificationDropdownsContainer'
                          onChange={value => {
                            this.setState({
                              conditionValue: value
                            })
                          }}
                          placeholder='Select'
                        />
                      </div>
                    </div>
                    <div className='listItems' id='sellerAddnew77'>
                      <p className='addproductManuurer' id='sellerAddnew78'>
                        {' '}
                        Console Type
                      </p>
                      <div>
                        {' '}
                        <Input
                          className='consoletypeinpt'
                          id='consoletype'
                          name='consoletype'
                          placeholder='Enter console type'
                          value={CapitalizeFirstLetter(this.state.consoletype)}
                          onChange={e =>
                            this.setState({ consoletype: e.target.value })
                          }
                        />
                      </div>
                    </div>
                    {/* <div
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    marginTop: 10,
                  }}
                >
                  <p className="addproductManuurer"> Quantity</p>
                  <div>
                    <Dropdown
                      className="sellerdropcopeinpt"
                      placeholder="Select"
                      value={this.state.itemQuantity}
                      options={orderQuantity}
                      onChange={e => this.setState ({itemQuantity: e.value})}
                    />
                    {!itemQuantity &&
                      isNext &&
                      <p className="validationerrorMessage">
                        {' '} Quantity  is required *{' '}
                      </p>}
                  </div>
                    </div> */}
                    <div className='logisticssheading' id='sellerAddnew80'>
                      LOGISTICS
                    </div>
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        marginTop: 10
                      }}
                    >
                      <p className='addproductManuurer' id='sellerAddnew81'>
                        Location
                      </p>
                      <div>
                        <SelectInput
                          items={logisticslocaltionTypeOptions}
                          value={this.state.logisticslocaltionTypeValue}
                          className='sellectSpecificationDropdowns'
                          dropDownContainerClass='sellectSpecificationDropdownsContainer'
                          onChange={value => {
                            this.setState({
                              logisticslocaltionTypeValue: value
                            })
                          }}
                          placeholder='Select'
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        marginTop: 10
                      }}
                    >
                      <p className='addproductManuurer' id='sellerAddnew83'>
                        Accessibility
                      </p>
                      <div>
                        <SelectInput
                          items={logisticsaccessibilityOptions}
                          value={this.state.logisticsaccessibilityValue}
                          className='sellectSpecificationDropdowns'
                          dropDownContainerClass='sellectSpecificationDropdownsContainer'
                          onChange={value => {
                            this.setState({
                              logisticsaccessibilityValue: value
                            })
                          }}
                          placeholder='Select'
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        marginTop: 10
                      }}
                      id='sellerAddnew85'
                    >
                      <p className='addproductManuurer' id='sellerAddnew86'>
                        Rigging
                      </p>
                      <div>
                        <SelectInput
                          items={logisticsRiggingOptions}
                          value={this.state.logisticsRiggingValue}
                          className='sellectSpecificationDropdowns'
                          dropDownContainerClass='sellectSpecificationDropdownsContainer'
                          onChange={value => {
                            this.setState({
                              logisticsRiggingValue: value
                            })
                          }}
                          placeholder='Select'
                        />
                      </div>
                    </div>
                    <div className='titleinfossheading' id='sellerAddnew88'>
                      TITLE INFORMATION
                    </div>

                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        marginTop: 10
                      }}
                    >
                      <p className='addproductManuurer' id='sellerAddnew89'>
                        {' '}
                        Ownership Status
                      </p>
                      <div>
                        <SelectInput
                          items={ownershipOptions}
                          value={this.state.ownershipValue}
                          className='sellectSpecificationDropdowns'
                          dropDownContainerClass='sellectSpecificationDropdownsContainer'
                          onChange={value => {
                            this.setState({
                              ownershipValue: value
                            })
                          }}
                          placeholder='Select'
                        />

                        {!ownershipValue && isNext && (
                          <p
                            className='validationerrorMessage'
                            id='sellerAddnew91'
                          >
                            {' '}
                            Ownership Status is required *{' '}
                          </p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        marginTop: 10
                      }}
                      id='sellerAddnew92'
                    >
                      <p className='addproductManuurer' id='sellerAddnew93'>
                        Title Availability
                      </p>
                      <div>
                        <SelectInput
                          items={titleAvailabilityOptions}
                          value={this.state.titleAvailabilityValue}
                          className='sellectSpecificationDropdowns'
                          dropDownContainerClass='sellectSpecificationDropdownsContainer'
                          onChange={value => {
                            this.setState({
                              titleAvailabilityValue: value
                            })
                          }}
                          placeholder='Select'
                        />

                        {!titleAvailabilityValue && isNext && (
                          <p
                            className='validationerrorMessage'
                            id='sellerAddnew95'
                          >
                            {' '}
                            Title Availability is required *{' '}
                          </p>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        marginTop: 10
                      }}
                    >
                      <p className='addproductManuurer' id='sellerAddnew96'>
                        {' '}
                        Liens/Loans on Equipment
                      </p>
                      <div>
                        <SelectInput
                          items={loanEquipmentOptions}
                          value={this.state.LoanEquipmentValue}
                          className='sellectSpecificationDropdowns'
                          dropDownContainerClass='sellectSpecificationDropdownsContainer'
                          onChange={value => {
                            this.setState({
                              LoanEquipmentValue: value
                            })
                          }}
                          placeholder='Select'
                        />

                        {!LoanEquipmentValue && isNext && (
                          <p
                            className='validationerrorMessage'
                            id='sellerAddnew98'
                          >
                            {' '}
                            Liens/Loans on Equipment is required *{' '}
                          </p>
                        )}
                      </div>
                    </div>

                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        marginTop: 10
                      }}
                    >
                      <p className='addproductManuurer' id='sellerAddnew99'>
                        {' '}
                        Title Search link
                      </p>
                      <div>
                        <Input
                          className='consoletypeinpt'
                          placeholder='Enter  title search'
                          value={titlesearch}
                          onChange={e =>
                            this.setState({ titlesearch: e.target.value })
                          }
                          id='sellerAddnewProduct1'
                        />
                        {!titlesearch && isNext && (
                          <p
                            className='validationerrorMessage'
                            id='sellerAddnewProduct2'
                          >
                            {' '}
                            Title search is required *{' '}
                          </p>
                        )}
                      </div>
                    </div>
                    <div
                      className='titleinfossheading'
                      id='sellerAddnewProduct3'
                    >
                      INSPECTION REPORT
                    </div>
                    <div
                      style={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        marginTop: 10
                      }}
                    >
                      <p
                        className='addproductManuurer'
                        id='sellerAddnewProduct4'
                      >
                        {' '}
                        Type
                      </p>
                      <div>
                        <SelectInput
                          items={logisticsReporttypeOptions}
                          value={this.state.logisticsreporttypeValue}
                          className='sellectSpecificationDropdowns'
                          dropDownContainerClass='sellectSpecificationDropdownsContainer'
                          onChange={value => {
                            this.setState({
                              logisticsreporttypeValue: value
                            })
                          }}
                          placeholder='Select'
                        />
                      </div>
                    </div>
                    <div
                      style={{ display: 'inline-flex', alignItems: 'center' }}
                    >
                      <p
                        className='addproductManuurer'
                        id='sellerAddnewProduct6'
                      >
                        {' '}
                        Upload Report (PDF/jpg)
                      </p>

                      <div>
                        <input
                          type='file'
                          name='file'
                          id='filereport'
                          multiple
                          onChange={e => this.onSelectReport(e)}
                          accept=' image/jpeg, image/png, application/pdf'
                        />

                        {!inspectionReportFileName ? (
                          <label
                            htmlFor='filereport'
                            className='uploadfileypeinpt'
                            data-rh='Select file'
                            id='sellerAddnewProduct7'
                          >
                            Upload Report
                          </label>
                        ) : (
                          <div style={{ width: 250 }}>
                            <div
                              style={{
                                display: 'inline-flex',
                                alignItems: 'center',
                                marginTop: 15,
                                wordBreak: 'break-all'
                              }}
                              id='sellerAddnewProduct8'
                            >
                              {inspectionReportFileName}
                              <Button
                                className='sellerremovefilebutton'
                                onClick={() => this.removeFile()}
                                id='sellerAddnewProduct9'
                              >
                                <img
                                  alt=''
                                  src={crossButtonMarketPlacesuccess}
                                  style={{
                                    width: 14,
                                    margin: 'auto'
                                  }}
                                />
                              </Button>
                            </div>
                            <div
                              style={{ textAlign: 'right' }}
                              id='sellerAddnewProduct10'
                            >
                              <label
                                htmlFor='filereport'
                                className='replaceReport'
                                data-rh='Select file'
                                id='sellerAddnewProduct11'
                              >
                                Replace Report
                              </label>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      className='overViewheading'
                      id='sellerAddnewProduct12'
                      style={{ width: 111 }}
                    >
                      OVERVIEW
                    </div>

                    <div className='listItems' id='sellerAddnewProduct13'>
                      <p
                        className='addproductManuurer'
                        id='sellerAddnewProduct14'
                      >
                        {' '}
                        Seller SKU
                      </p>
                      <div
                        style={{ marginBottom: 5 }}
                        id='sellerAddnewProduct15'
                      >
                        <Input
                          className='consoletypeinpt'
                          placeholder='Enter SKU'
                          value={sellerSKU}
                          onChange={e =>
                            this.setState({ sellerSKU: e.target.value })
                          }
                          id='sellerAddnewProduct16'
                        />
                        {!sellerSKU && isNext && (
                          <p
                            className='validationerrorMessage'
                            id='sellerAddnewProduct17'
                          >
                            {' '}
                            Seller SKU is required *{' '}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className='listItems' id='sellerAddnewProduct18'>
                      <p
                        className='addproductManuurer'
                        id='sellerAddnewProduct19'
                      >
                        {' '}
                        Brand
                      </p>
                      <div style={{ marginBottom: 5 }}>
                        <Input
                          className='consoletypeinpt'
                          placeholder='Enter brand name'
                          value={CapitalizeFirstLetter(brandName)}
                          onChange={e =>
                            this.setState({ brandName: e.target.value })
                          }
                          id='sellerAddnewProduct20'
                        />
                        {!brandName && isNext && (
                          <p
                            className='validationerrorMessage'
                            id='sellerAddnewProduct21'
                          >
                            {' '}
                            Brand name is required *{' '}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className='listItems' id='sellerAddnewProduct22'>
                      <p
                        className='addproductManuurer'
                        id='sellerAddnewProduct23'
                      >
                        {' '}
                        Product ID
                      </p>
                      <div
                        style={{ marginBottom: 5 }}
                        id='sellerAddnewProduct24'
                      >
                        <Input
                          className='consoletypeinpt'
                          placeholder='Enter valid UPC or EAN'
                          value={newProductId}
                          onChange={e =>
                            this.setState({ newProductId: e.target.value })
                          }
                          id='sellerAddnewProduct25'
                        />
                        {!newProductId && isNext && (
                          <p
                            className='validationerrorMessage'
                            id='sellerAddnewProduct26'
                          >
                            {' '}
                            Product ID is required *{' '}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className='listItems' id='sellerAddnewProduct27'>
                      <p
                        className='addproductManuurer'
                        style={{ paddingTop: 10 }}
                        id='sellerAddnewProduct28'
                      >
                        Expiration Date on Product?
                      </p>
                      <div>
                        <SelectInput
                          items={expirationProductOptions}
                          value={this.state.expirationProductData}
                          className='sellectSpecificationDropdowns'
                          dropDownContainerClass='sellectSpecificationDropdownsContainer'
                          onChange={value => {
                            this.setState({
                              expirationProductData: value
                            })
                          }}
                          placeholder='Select'
                        />

                        {!expirationProductData && isNext && (
                          <p
                            className='validationerrorMessage'
                            id='sellerAddnewProduct30'
                          >
                            {' '}
                            Expiration is required *{' '}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className='listItems' id='sellerAddnewProduct31'>
                      <p
                        className='addproductManuurer'
                        id='sellerAddnewProduct32'
                      >
                        {' '}
                        Manufacturer
                      </p>
                      <div
                        style={{ marginBottom: 5 }}
                        id='sellerAddnewProduct33'
                      >
                        <Input
                          className='consoletypeinpt'
                          placeholder='Enter manufacturer name'
                          value={CapitalizeFirstLetter(manufactureName)}
                          onChange={e =>
                            this.setState({ manufactureName: e.target.value })
                          }
                          id='sellerAddnewProduct34'
                        />
                        {!manufactureName && isNext && (
                          <p
                            className='validationerrorMessage'
                            id='sellerAddnewProduct35'
                          >
                            {' '}
                            Manufacturer name is required *{' '}
                          </p>
                        )}
                      </div>
                    </div>

                    <div
                      className='overViewheading'
                      style={{ width: 120 }}
                      id='sellerAddnewProduct36'
                    >
                      OTHER INFO
                    </div>
                    <div className='listItems' id='sellerAddnewProduct37'>
                      <p
                        className='addproductManuurer'
                        id='sellerAddnewProduct38'
                      >
                        {' '}
                        Catalog Number
                      </p>
                      <div style={{ marginBottom: 5 }}>
                        <Input
                          className='consoletypeinpt'
                          placeholder='Enter catalog number'
                          value={catalogNumber}
                          onChange={e =>
                            this.setState({ catalogNumber: e.target.value })
                          }
                          id='sellerAddnewProduct39'
                        />
                      </div>
                    </div>
                    <div className='listItems' id='sellerAddnewProduct40'>
                      <p
                        id='sellerAddnewProduct41'
                        className='addproductManuurer'
                      >
                        {' '}
                        Generic Keywords
                      </p>
                      <div
                        id='sellerAddnewProduct42'
                        style={{ marginBottom: 5 }}
                      >
                        <Input
                          className='consoletypeinpt'
                          placeholder='keywords separated by comma'
                          value={genericKeyword}
                          onChange={e =>
                            this.setState({ genericKeyword: e.target.value })
                          }
                          id='sellerAddnewProduct43'
                        />
                      </div>
                    </div>
                    <div className='listItems' id='sellerAddnewProduct44'>
                      <p
                        className='addproductManuurer'
                        id='sellerAddnewProduct46'
                      >
                        {' '}
                        Importer Name
                      </p>
                      <div
                        style={{ marginBottom: 5 }}
                        id='sellerAddnewProduct47'
                      >
                        <Input
                          className='consoletypeinpt'
                          placeholder='Enter importer name'
                          value={CapitalizeFirstLetter(importerName)}
                          onChange={e =>
                            this.setState({ importerName: e.target.value })
                          }
                          id='sellerAddnewProduct48'
                        />
                      </div>
                    </div>

                    <div
                      className='overViewheading'
                      id='sellerAddnewProduct49'
                      style={{ width: 120 }}
                    >
                      DIMENSIONS
                    </div>
                    <div className='listItems' id='sellerAddnewProduct50'>
                      <p
                        className='addproductManuurer'
                        id='sellerAddnewProduct51'
                      >
                        {' '}
                        Size
                      </p>
                      <div style={{ marginBottom: 5 }}>
                        <Input
                          className='consoletypeinpt'
                          placeholder='Enter Size'
                          value={productSize}
                          onChange={e =>
                            this.setState({ productSize: e.target.value })
                          }
                          id='sellerAddnewProduct52'
                        />
                      </div>
                    </div>
                    <div className='listItems' id='sellerAddnewProduct53'>
                      <p
                        className='addproductManuurer'
                        id='sellerAddnewProduct54'
                      >
                        {' '}
                        Color
                      </p>
                      <div style={{ marginBottom: 5 }}>
                        <Input
                          className='consoletypeinpt'
                          placeholder='Enter Color'
                          value={CapitalizeFirstLetter(productColor)}
                          onChange={e =>
                            this.setState({ productColor: e.target.value })
                          }
                          id='sellerAddnewProduct55'
                        />
                      </div>
                    </div>
                    <div className='listItems' id='sellerAddnewProduct56'>
                      <p
                        className='addproductManuurer'
                        id='sellerAddnewProduct57'
                      >
                        Weight
                      </p>
                      <div style={{ marginBottom: 5 }}>
                        <Input
                          className='consoletypeinpt'
                          placeholder='Enter Weight'
                          value={productWeight}
                          onChange={e =>
                            this.setState({ productWeight: e.target.value })
                          }
                          id='sellerAddnewProduct58'
                        />
                      </div>
                    </div>

                    <div
                      className='overViewheading'
                      id='sellerAddnewProduct59'
                      style={{ width: 120 }}
                    >
                      COMPLIANCE
                    </div>
                    <div className='listItems' id='sellerAddnewProduct60'>
                      <p
                        className='addproductManuurer'
                        style={{ paddingTop: 10 }}
                        id='sellerAddnewProduct61'
                      >
                        Country of Origin
                      </p>
                      <div>
                        <SelectInput
                          items={this.state.countryOriginList}
                          value={countryOriginValue}
                          className='sellectSpecificationDropdowns'
                          dropDownContainerClass='sellectSpecificationDropdownsContainer'
                          onChange={value => {
                            this.setState({
                              countryOriginValue: value
                            })
                          }}
                          placeholder='Select'
                        />
                      </div>
                    </div>
                    <div className='listItems' id='sellerAddnewProduct63'>
                      <p
                        className='addproductManuurer'
                        id='sellerAddnewProduct64'
                      >
                        {' '}
                        Specific Product Use
                      </p>
                      <div
                        style={{ marginBottom: 5 }}
                        id='sellerAddnewProduct65'
                      >
                        <Input
                          className='consoletypeinpt'
                          placeholder='Specific Product Use'
                          value={CapitalizeFirstLetter(specificProduct)}
                          onChange={e =>
                            this.setState({ specificProduct: e.target.value })
                          }
                          id='sellerAddnewProduct66'
                        />
                      </div>
                    </div>

                    <div
                      className='overViewheading'
                      style={{ width: 120 }}
                      id='sellerAddnewProduct67'
                    >
                      CONDITION
                    </div>
                    <div className='listItems' id='sellerAddnewProduct68'>
                      <p
                        className='addproductManuurer'
                        style={{ paddingTop: 10 }}
                        id='sellerAddnewProduct69'
                      >
                        Item Condition
                      </p>
                      <div>
                        <SelectInput
                          items={nonRadioItemConditions}
                          value={itemCondition}
                          className='sellectSpecificationDropdowns'
                          dropDownContainerClass='sellectSpecificationDropdownsContainer'
                          onChange={value => {
                            this.setState({
                              itemCondition: value
                            })
                          }}
                          placeholder='Select'
                        />
                      </div>
                    </div>
                    <div className='listItems' id='sellerAddnewProduct71'>
                      <p
                        className='addproductManuurer'
                        id='sellerAddnewProduct72'
                      >
                        {' '}
                        Condition Note
                      </p>
                      <div style={{ marginBottom: 5 }}>
                        <textarea
                          className='consoletypeinpt conditionNote'
                          placeholder='Enter note on condition (300 character max)'
                          value={CapitalizeFirstLetter(conditionNote)}
                          onChange={e =>
                            this.setState({ conditionNote: e.target.value })
                          }
                          id='sellerAddnewProduct73'
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <br />
              <br />
            </div>
          </div>

          <Modal
            size='sm'
            isOpen={this.state.delistConfirm}
            centered
            toggle={() => this.closeModal()}
          >
            <ModalBody
              className='AddProductConfirmOrderModal'
              id='sellerAddnewProduct74'
            >
              <div>
                <p className='confirmTitle' id='sellerAddnewProduct75'>
                  Remove this listing?
                </p>
                <p className='confirmmsg' id='sellerAddnewProduct76'>
                  This will remove your item from the Marketplace but remain in
                  your inventory.{' '}
                </p>
                <div className='buttonDiv' id='sellerAddnewProduct77'>
                  <Button
                    className='buttons'
                    onClick={() => this.closeModal()}
                    id='sellerAddnewProduct78'
                  >
                    No
                  </Button>
                  <Button
                    className='buttons yesButton'
                    onClick={() => this.doDelistProduct()}
                    id='sellerAddnewProduct79'
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size='sm'
            isOpen={this.state.deleteConfirm}
            centered
            toggle={() => this.closeModal()}
          >
            <ModalBody
              className='AddProductConfirmOrderModal'
              id='sellerAddnewProduct80'
            >
              <div>
                <p className='confirmTitle' id='sellerAddnewProduct81'>
                  Delete Item?
                </p>
                <p className='confirmmsg' id='sellerAddnewProduct82'>
                  This will permanently delete this product from your Seller
                  Console.{' '}
                </p>
                <div className='buttonDiv' id='sellerAddnewProduct83'>
                  <Button
                    className='buttons'
                    onClick={() => this.closeModal()}
                    id='sellerAddnewProduct84'
                  >
                    No
                  </Button>
                  <Button
                    className='buttons yesButton'
                    onClick={() => this.doDeleteProduct()}
                    id='sellerAddnewProduct85'
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size='sm'
            isOpen={this.state.saveDraftConfirm}
            centered
            toggle={() => this.closeModal()}
          >
            <ModalBody
              className='AddProductConfirmOrderModal'
              id='sellerAddnewProduct86'
            >
              <div>
                <p className='confirmTitle' id='sellerAddnewProduct87'>
                  {' '}
                  Save Item Edits
                </p>
                <p className='confirmmsg' id='sellerAddnewProduct88'>
                  Do you want to save changes to this product information?{' '}
                </p>
                <div className='buttonDiv' id='sellerAddnewProduct89'>
                  <Button
                    className='buttons'
                    onClick={() => this.closeModal()}
                    data-rh='Cancel'
                    id='sellerAddnewProduct90'
                  >
                    No
                  </Button>
                  <Button
                    className='buttons yesButton'
                    onClick={() => this.addProduct(false)}
                    data-rh='Submit'
                    id='sellerAddnewProduct91'
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size='sm'
            isOpen={this.state.savePublishConfirm}
            centered
            toggle={() => this.closeModal()}
          >
            <ModalBody
              className='AddProductConfirmOrderModal'
              id='sellerAddnewProduct92'
            >
              <div>
                <p className='confirmTitle' id='sellerAddnewProduct93'>
                  Ready to Submit?
                </p>
                <p className='confirmmsg' id='sellerAddnewProduct94'>
                  Do you want to make this available for purchase within the
                  Marketplace?{' '}
                </p>
                <div className='buttonDiv' id='sellerAddnewProduct95'>
                  <Button
                    className='buttons'
                    onClick={() => this.closeModal()}
                    id='sellerAddnewProduct96'
                  >
                    No
                  </Button>
                  <Button
                    className='buttons yesButton'
                    onClick={() => this.addProduct(true)}
                    id='sellerAddnewProduct97'
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>

          <Modal
            size='sm'
            isOpen={this.state.successModels}
            centered
            toggle={() => {
              this.closeModal()
              this.setState({
                selectedImages: []
              })
              window.location.reload()
            }}
          >
            <ModalBody className='AddSuccessModal' id='sellerAddnewProduct98'>
              <div>
                <Button
                  className='closeButton'
                  onClick={() => this.closeModal()}
                  id='sellerAddnewProduct99'
                >
                  <img
                    alt='crossButtonMarketPlacesuccess'
                    src={crossButtonMarketPlacesuccess}
                  />
                </Button>
                {this.state.delistConfirm && (
                  <div>
                    <p className='heading' id='sellerAddnewProductdelist1'>
                      Listing Removed?
                    </p>

                    <div
                      style={{ textAlign: 'center' }}
                      id='sellerAddnewProductdelist2'
                    >
                      <img
                        alt='productActionIcon'
                        src={productActionIcon}
                        className='boxImage'
                        id='sellerAddnewProductdelist3'
                      />
                    </div>
                    <p
                      className='subtitle'
                      style={{ marginTop: 10 }}
                      id='sellerAddnewProductdelist4'
                    >
                      Changes Saved
                    </p>
                    <p
                      className='description'
                      style={{ marginTop: 10 }}
                      id='sellerAddnewProductdelist5'
                    >
                      Your item has been removed from the Marketplace.
                    </p>
                  </div>
                )}
                {this.state.saveDraftConfirm && (
                  <div>
                    <p className='heading' id='sellerAddnewProductdelist6'>
                      Item Saved
                    </p>

                    <div
                      style={{ textAlign: 'center' }}
                      id='sellerAddnewProductdelist7'
                    >
                      <img
                        alt='productActionIcon'
                        src={productActionIcon}
                        className='boxImage'
                        id='sellerAddnewProductdelist8'
                      />
                    </div>
                    <p
                      className='subtitle'
                      style={{ marginTop: 10 }}
                      id='sellerAddnewProductdelist9'
                    >
                      Changes Saved
                    </p>
                    <p
                      className='description'
                      style={{ marginTop: 10 }}
                      id='sellerAddnewProductdelist10'
                    >
                      Your edits to this item have been updated.
                    </p>
                  </div>
                )}

                {this.state.deleteConfirm && (
                  <div>
                    <p className='heading' id='sellerAddnewProductdelist11'>
                      Item Delete
                    </p>

                    <div
                      style={{
                        textAlign: 'center',
                        marginTop: 30,
                        marginBottom: 20
                      }}
                    >
                      <img
                        alt='marketPlaceCart'
                        src={marketPlaceCart}
                        className='cartImage'
                        id='sellerAddnewProductdelist12'
                      />
                    </div>
                    <p
                      className='subtitle'
                      style={{ marginTop: 10 }}
                      id='sellerAddnewProductdelist13'
                    >
                      Item Deleted
                    </p>
                    <p
                      className='description'
                      style={{ marginTop: 10 }}
                      id='sellerAddnewProductdelist14'
                    >
                      This item has been removed from your Marketplace
                      inventory.
                    </p>
                  </div>
                )}

                {this.state.savePublishConfirm && (
                  <div>
                    <p className='heading' id='sellerAddnewProductdelist15'>
                      Submitted to Marketplace
                    </p>

                    <div
                      style={{
                        textAlign: 'center',
                        marginTop: 30,
                        marginBottom: 20
                      }}
                    >
                      <img
                        alt='marketPlaceCart'
                        src={marketPlaceCart}
                        className='cartImage'
                        id='sellerAddnewProductdelist16'
                      />
                    </div>
                    <p
                      className='subtitle'
                      style={{ marginTop: 10 }}
                      id='sellerAddnewProductdelist17'
                    >
                      Item Submitted!
                    </p>
                    <p
                      className='description'
                      style={{ marginTop: 10 }}
                      id='sellerAddnewProductdelist18'
                    >
                      Your item is now publicly available in the Marketplace.
                    </p>
                  </div>
                )}
                <div>
                  <Button
                    className='returnSellerConsole'
                    onClick={() => {
                      this.closeModal()
                      this.props.history.push(routes.SELLER_CONSOLE)
                    }}
                    id='sellerAddnewProductdelist19'
                  >
                    Return to Seller Console
                  </Button>
                  <Button
                    className='backDahboard'
                    onClick={() => {
                      this.closeModal()
                      this.props.history.push(routes.PROFILE_DASHBOARD)
                    }}
                    data-rh='Dashboard'
                    id='sellerAddnewProductdelist20'
                  >
                    Back to Dashboard
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
        <RightSideContainer>
          <SellerConsoleSidemenu />
        </RightSideContainer>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    addProduct: state.MarketplaceReducer
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      createNewProduct,
      resetdoOrderValue,
      getSellerInventory,
      modifyProduct,
      deListMarketPlace,
      deleteMarketPlace,
      marketplaceGetCountryOfOriginList,
      fetchProductManufacturer
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SellerAddProduct)
