import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as routes from '../../../constants/routes';
import './DefaultScreen.css';
import {Button, Modal, ModalBody, Progress, Input} from 'reactstrap';
import seainputicon from '../../../images/icons/seainputicon.png';
import profileDefault from '../../../images/profile/profileDefault.png';
import notisetimg from '../../../images/accountSetting/notisetimg.png';
import photosicon from '../../../images/icons/photosicon.png';
import fileicon from '../../../images/icons/fileicon.png';
import iconjfbf from '../../../images/icons/iconjfbf.png';
import linkicond from '../../../images/icons/linkicond.png';
import yellowcrown from '../../../images/icons/yellowcrown.png';
class DefaultScreen extends Component {
  constructor (props) {
    super (props);
    this.state = {
      modal: false,
      groupInfoDiv: false,
      createGroupStep: Number (1),
      showDropDown: false,
      manageGroups: false,
    
    };
  }
  toggleDropDown = () => {
    this.setState ({showDropDown: !this.state.showDropDown});
  };
  nextStep = () => {
    this.setState ({createGroupStep: this.state.createGroupStep + Number (1)});
  };
  closeModal = () => {
    this.setState ({modal: false});
  };
  openManage = () => {
    this.setState ({
      
      groupInfoDiv: false,
      manageGroups: true,

    });
  };
  render () {
    const {
      
      groupInfoDiv,
      createGroupStep,
      manageGroups,
    } = this.state;
    return (
      <div className="screen-container" id="defaultScreen">

       <div className="block-container"  id="defaultScreenBox">
          <div className="text-container"  id="defaultScreenText" style={{color: "#FFFFFF", fontStyle: 'italic'}}>

            Select a contact to begin a conversation

          </div>

        </div>
        </div>
    );
  }
}
// DefaultScreen.propTypes = {
//   history: PropTypes.object.isRequired,
// };

const mapStateToProps = state => {
  return {
    // contacts: state.ContactsReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators ({}, dispatch);
};

export default connect (mapStateToProps, mapDispatchToProps) (DefaultScreen);
