import React, {Component} from 'react';
import {Button} from 'reactstrap';
import ServiceOnDemandProfilePage
  from '../service-On-Demand-Profile/serviceOnDemandProfile';
import ServiceOnDemandCommonComponent
  from '../Service-On-Demand-CommanComponent/Service-On-Demand-CommanComponent';
import StarRatingComponent from 'react-star-rating-component';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import './serviceOnDemandProviderProfile.css';
import provider1 from '../../images/provider1.png';
import work from '../../images/work.png';
import build from '../../images/build.png';
import loc from '../../images/loc.png';
import buttonframe from '../../images/buttonframe.png';
import star from '../../images/serviceOnDemandSearch/star.png';
import roman from '../../images/serviceOnDemandSearch/roman.png';
import settingCard2 from '../../images/serviceOnDemand/settingCard2.png';
import greygear from '../../images/serviceOnDemandMyRequest/greygear.png';
import acivity from '../../images/serviceOnDemandProfile/acivity.png';
import {GetServiceProfile} from '../../../src/actions/serviceOnDemandAction';
class serviceOnDemandProvierProfilePage extends Component {
  constructor (props) {
    super (props);
    this.state = {
      reviewDiv: false,
      providerProfileData: '',
      serviceId: '',
      serviceOffered: [],
      professionDetail: '',
      isOpen: '',
      businessDetail: '',
    };
    this.openreviewDiv = this.openreviewDiv.bind (this);
  }
  componentWillReceiveProps (nextProps) {
   
    if (nextProps) {
      if (
        nextProps &&
        nextProps.providerProfile &&
        nextProps.providerProfile.serviceProfile &&
        nextProps.providerProfile.serviceProfile.serviceProfile
      ) {
      
        this.setState (
          {
            providerProfileData: nextProps.providerProfile.serviceProfile,
            professionDetail: nextProps.providerProfile.serviceProfile
              .professionalDetails,
            serviceOffered: nextProps.providerProfile.serviceProfile
              .servicesOffered,
            businessDetail: nextProps.providerProfile.serviceProfile
              .businessDetails,
          },
          () => {
          
          }
        );
      }
    }
  }

  componentDidMount () {
    let geturl = this.props.match.params;
  
    if (geturl && geturl.providerId) {
      this.setState (
        {
          providerId: geturl.providerId,
        },
        () => {
          let sendRequest = {
            data: {
              docID: this.state.providerId,
            },
          };
          this.props.GetServiceProfile (sendRequest);
        }
      );
    }
  }

  // componentDidMount () {
  //   let geturl= this.props.match.params;
  //   let sendRequest = {
  //     data: {
  //       docID: '',
  //     },
  //   };
  //   this.props.GetServiceProfile (sendRequest);
  //   console.log (this.props);
  //   // if (this.props) {
  //   //   if (
  //   //     this.props &&
  //   //     this.props.providerProfile &&
  //   //     this.props.providerProfile.searchServiceProvider
  //   //   ) {
  //   //     this.setState (
  //   //       {
  //   //         providerProfileData: this.props.providerProfile
  //   //           .searchServiceProvider[0],
  //   //           // searchBy:  this.props.GetServiceProvider.searchProviderFilter
  //   //       },
  //   //       () => {
  //   //         console.log (this.state.providerProfileData);
  //   //       }
  //   //     );
  //   //   }
  //   // }
  // }
  openreviewDiv () {
    this.setState ({reviewDiv: true});
  }
  closereviewDiv = () => {
    this.setState ({reviewDiv: false});
  };
  render () {
 
    const {
      providerProfileData,
      professionDetail,
      serviceOffered,
      businessDetail,
    } = this.state;
    return (
      <div style={{display: 'flex', background: '#FFFFFF', height: '100%'}}>
        <div className="inlineFlex100ProviderProfile">
          {/*------------First----------*/}
          <div className="parentRowProviderProfile">
            <div style={{width: 230}}>
              <ServiceOnDemandCommonComponent />
            </div>
            <div className="scrollBehaveXMyProviderProfile">
              <div className="inline">
                {/*}=========Service Provider==========*/}
                <div style={{width: 560}}>
                  <div className="SERVICEPROVIDER">
                    SERVICE PROVIDER PROFILE FOR
                  </div>
                  <div>

                    <div className="Martha">
                      {providerProfileData &&
                        providerProfileData &&
                        providerProfileData.displayName
                        ? providerProfileData.displayName
                        : providerProfileData && providerProfileData
                            ? (providerProfileData.firstName
                                ? providerProfileData.firstName
                                : '') +
                                ' ' +
                                (providerProfileData.lastName
                                  ? providerProfileData.lastName
                                  : '')
                            : ''}

                    </div>

                    <div>
                      <img alt="" src={work} />
                      <span className="Co-Founder">

                        {professionDetail &&
                          professionDetail &&
                          professionDetail.position
                          ? professionDetail.position
                          : ''}
                      </span>
                    </div>
                    <div>
                      <img alt="" src={build} />
                      <span className="Everus">
                        {professionDetail &&
                          professionDetail &&
                          professionDetail.firmName
                          ? professionDetail.firmName
                          : ''}
                      </span>
                    </div>
                    <div>
                      <img alt="" src={loc} />
                      <span className="ElMonte">
                        {businessDetail && businessDetail.businessAddress_1
                          ? businessDetail.businessAddress_1
                          : ''}
                      </span>
                    </div>
                  </div>
                  <div>
                    <div className="ABOUTME">ABOUT ME</div>
                    <div className="longtext">
                      <p style={{margin: 0}}>

                        {providerProfileData &&
                          providerProfileData.serviceProfile &&
                          providerProfileData.serviceProfile.aboutMe
                          ? providerProfileData.serviceProfile.aboutMe
                          : ''}

                      </p>
                    </div>
                    <div className="ServiceArea">
                      Location (Service Area)
                    </div>
                    <div className="Francisco">
                      {providerProfileData &&
                        providerProfileData.serviceProfile &&
                        providerProfileData.serviceProfile.serviceArea
                        ? providerProfileData.serviceProfile.serviceArea
                        : ''}
                    </div>

                  </div>
                </div>
                {/*}=========Reviews==========*/}
                <div style={{width: 400, textAlign: 'right'}}>
                  <div onClick={this.closereviewDiv} style={{marginBottom: 10}}>
                    <img alt="" src={provider1} />
                    <span className="GOBACK"> GO BACK </span>
                  </div>
                  <div style={{display: 'inline-flex'}}>
                    <div style={{textAlign: 'center'}}>
                      <div className="rate">
                        4.7 / 5.0{' '}
                      </div>
                      <div style={{textAlign: 'center'}}>
                        <StarRatingComponent
                          name={'rating'}
                          starCount={5}
                          // value={
                          //   userProfile &&
                          //     userProfile.serviceProfile &&
                          //     userProfile.serviceProfile.totalServicesOffered
                          //     ? userProfile.serviceProfile.totalServicesOffered
                          //     : 0
                          // }
                          emptyStarColor={'#E4E4E4'}
                          starColor={'#F5D028'}
                          style={{fontSize: '80px'}}
                          // onStarClick={this.onStarClick}
                        />
                      </div>
                      <div className="Reviews4">
                        4 Reviews
                      </div>
                    </div>
                    <div>
                      <img
                        alt=""
                        src={
                          providerProfileData &&
                            providerProfileData[0] &&
                            providerProfileData[0].providerProfilePictureURL
                            ? providerProfileData[0].providerProfilePictureURL
                            : ''
                        }
                        style={{width: 100, height: 100, borderRadius: 100}}
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <Button className="viewpro">
                        VIEW R-DEE PROFILE
                        <img
                          alt=""
                          src={buttonframe}
                          style={{marginLeft: 10}}
                        />
                      </Button>
                    </div>
                    <div>
                      <Button className="viewpro2">
                        PLACE REQUEST
                        <img
                          alt=""
                          src={buttonframe}
                          style={{marginLeft: 10}}
                        />
                      </Button>
                    </div>
                  </div>
                </div>

              </div>
              {/*===========Multipule View=========*/}
              <div style={{marginTop: 50}}>
                {/*------------List View----------*/}
                {!this.state.reviewDiv &&
                  <div>
                    <div className="inline">
                      <div style={{width: 560}}>
                        <div className="OFFERED">
                          SERVICES OFFERED
                        </div>
                      </div>
                      <div style={{width: 400}}>
                        <div className="RatingsReviews">
                          Ratings & Reviews
                        </div>
                      </div>

                    </div>

                    <div>
                      {serviceOffered &&
                        serviceOffered.length > 0 &&
                        serviceOffered.map ((item, index) => (
                          <div
                            key={index}
                            onClick={item => {
                              this.setState ({
                                isOpen: index,
                              });
                            }}
                          >
                            <div className="Rectangle082ViewAll">
                              <div className="GEServici">
                                {item && item.serviceOffered
                                  ? item.serviceOffered
                                  : ''}

                              </div>
                              <div className="USDperhou">
                                USD $
                                {item && item.priceRate ? item.priceRate : ' '}
                                {' '}
                                per hour
                                {' '}
                              </div>
                              <div>
                                <img
                                  alt=""
                                  src={settingCard2}
                                  style={{height: 25}}
                                />
                                <span className="Provided29">
                                  {providerProfileData &&
                                    providerProfileData[0] &&
                                    providerProfileData[0].totalServicesOffered
                                    ? providerProfileData[0]
                                        .totalServicesOffered
                                    : ''}
                                  {' '}
                                  Services Provided
                                </span>
                              </div>
                              <div className="ratingno">
                                4.7 / 5.0
                              </div>
                              <div>
                                <div style={{textAlign: 'center'}}>
                                  <StarRatingComponent
                                    name={'rating'}
                                    starCount={5}
                                    // value={
                                    //   userProfile &&
                                    //     userProfile.serviceProfile &&
                                    //     userProfile.serviceProfile.totalServicesOffered
                                    //     ? userProfile.serviceProfile.totalServicesOffered
                                    //     : 0
                                    // }
                                    emptyStarColor={'#E4E4E4'}
                                    starColor={'#F5D028'}
                                    style={{fontSize: '80px'}}
                                    // onStarClick={this.onStarClick}
                                  />
                                </div>
                              </div>
                              <div
                                className="ViewAll"
                                onClick={this.openreviewDiv}
                              >
                                View All
                              </div>

                            </div>

                            {this.state.isOpen === index &&
                              <div style={{width: 400}}>
                                {/*----------------Card01--------*/}
                                <div className="Servicecard1">
                                  <div style={{display: 'flex'}}>
                                    <div>
                                      <img
                                        alt=""
                                        src={acivity}
                                        style={{width: 44}}
                                      />
                                    </div>
                                    <div style={{paddingLeft: 10}}>
                                      <div className="LouisWen">
                                        Louis Wen
                                      </div>
                                      <div className="time">
                                        06/08/2019; 4:32pm
                                      </div>
                                    </div>
                                    <div style={{textAlign: 'center'}}>
                                      <StarRatingComponent
                                        name={'rating'}
                                        starCount={5}
                                        // value={
                                        //   userProfile &&
                                        //     userProfile.serviceProfile &&
                                        //     userProfile.serviceProfile.totalServicesOffered
                                        //     ? userProfile.serviceProfile.totalServicesOffered
                                        //     : 0
                                        // }
                                        emptyStarColor={'#E4E4E4'}
                                        starColor={'#F5D028'}
                                        style={{fontSize: '80px'}}
                                        // onStarClick={this.onStarClick}
                                      />
                                    </div>
                                  </div>
                                  <div className="S-o-D">
                                    Ms.Julie is very responsive on S-o-D. Whenever I
                                    needed guide, she always replies within 1 hours
                                    time. Her explaination is clear and detailed
                                    enough to guide you in your problem!{' '}
                                  </div>
                                </div>
                                {/*----------------Card02--------*/}
                                <div className="Servicecard1">
                                  <div style={{display: 'flex'}}>
                                    <div>
                                      <img
                                        alt=""
                                        src={acivity}
                                        style={{width: 44}}
                                      />
                                    </div>
                                    <div style={{paddingLeft: 10}}>
                                      <div className="LouisWen">
                                        Louis Wen
                                      </div>
                                      <div className="time">
                                        06/08/2019; 4:32pm
                                      </div>
                                    </div>
                                    <div style={{textAlign: 'center'}}>
                                      <StarRatingComponent
                                        name={'rating'}
                                        starCount={5}
                                        // value={
                                        //   userProfile &&
                                        //     userProfile.serviceProfile &&
                                        //     userProfile.serviceProfile.totalServicesOffered
                                        //     ? userProfile.serviceProfile.totalServicesOffered
                                        //     : 0
                                        // }
                                        emptyStarColor={'#E4E4E4'}
                                        starColor={'#F5D028'}
                                        style={{fontSize: '80px'}}
                                        // onStarClick={this.onStarClick}
                                      />
                                    </div>
                                  </div>
                                  <div className="S-o-D">
                                    Ms.Julie is very responsive on S-o-D. Whenever I
                                    needed guide, she always replies within 1 hours
                                    time. Her explaination is clear and detailed
                                    enough to guide you in your problem!{' '}
                                  </div>
                                </div>
                              </div>}

                          </div>
                        ))}
                    </div>

                    <div className="inline">
                      <div style={{width: 560}}>
                        <div style={{marginTop: 10}}>
                          {/*-------------Grid01-----------*/}
                          {/* <div className="rec">
                            <div className="Shimadzu">
                              Shimadzu Servicing
                            </div>
                            <div className="usd85">
                              USD $82 per hour
                            </div>
                            <div>
                              <img alt="" src={greygear} />
                              <span className="ser">21 Services Provided</span>
                            </div>
                  </div>*/}

                          {/*-------------Grid02-----------*/}
                          {/* <div className="rec" style={{marginTop: 12}}>
                            <div className="Shimadzu">
                              Shimadzu Servicing
                            </div>
                            <div className="usd85">
                              USD $80 per hour
                            </div>
                            <div>
                              <img alt="" src={greygear} />
                              <span className="ser">27 Services Provided</span>
                            </div>
                </div>*/}

                          {/*-------------Grid03-----------*/}
                          {/* <div className="rec" style={{marginTop: 12}}>
                            <div className="Shimadzu">
                              Shimadzu Servicing
                            </div>
                            <div className="usd85">
                              USD $80 per hour
                            </div>
                            <div>
                              <img alt="" src={greygear} />
                              <span className="ser">27 Services Provided</span>
                            </div>
              </div>*/}
                        </div>
                      </div>
                      <div style={{width: 400}}>
                        {/*----------------Card01--------*/}

                        {/*----------------Card02--------*/}

                      </div>

                    </div>

                  </div>}

                {/*------------Grid View----------*/}
                {this.state.reviewDiv &&
                  <div>
                    <div className="allreviews"> ALL REVIEWS</div>
                    <div style={{display: 'flex'}}>
                      {/*------------ServiceCard 01 ----------*/}
                      <div className="Servicecard1" style={{margin: 10}}>
                        <div style={{display: 'flex'}}>
                          <div>
                            <img alt="" src={acivity} style={{width: 44}} />
                          </div>
                          <div style={{paddingLeft: 10}}>
                            <div className="LouisWen">
                              Louis Wen
                            </div>
                            <div className="time">
                              06/08/2019; 4:32pm
                            </div>
                          </div>
                          <div style={{textAlign: 'center'}}>
                            <StarRatingComponent
                              name={'rating'}
                              starCount={5}
                              // value={
                              //   userProfile &&
                              //     userProfile.serviceProfile &&
                              //     userProfile.serviceProfile.totalServicesOffered
                              //     ? userProfile.serviceProfile.totalServicesOffered
                              //     : 0
                              // }
                              emptyStarColor={'#E4E4E4'}
                              starColor={'#F5D028'}
                              style={{fontSize: '80px'}}
                              // onStarClick={this.onStarClick}
                            />
                          </div>
                        </div>
                        <div className="S-o-D">
                          Ms.Julie is very responsive on S-o-D. Whenever I
                          needed guide, she always replies within 1 hours
                          time. Her explaination is clear and detailed
                          enough to guide you in your problem!{' '}
                        </div>
                      </div>

                      {/*------------ServiceCard 02 ----------*/}
                      <div className="Servicecard1" style={{margin: 10}}>
                        <div style={{display: 'flex'}}>
                          <div>
                            <img alt="" src={acivity} style={{width: 44}} />
                          </div>
                          <div style={{paddingLeft: 10}}>
                            <div className="LouisWen">
                              Louis Wen
                            </div>
                            <div className="time">
                              06/08/2019; 4:32pm
                            </div>
                          </div>
                          <div>
                            <img
                              alt=""
                              src={star}
                              style={{width: 97, height: 17, marginLeft: 77}}
                            />
                          </div>
                        </div>
                        <div className="S-o-D">
                          Ms.Julie is very responsive on S-o-D. Whenever I
                          needed guide, she always replies within 1 hours
                          time. Her explaination is clear and detailed
                          enough to guide you in your problem!{' '}
                        </div>
                      </div>

                    </div>
                  </div>}
              </div>
            </div>
          </div>
          {/*------------First----------*/}

          {/*------------Second----------*/}
          <div className="notificationCSSProviderProfile">
            <ServiceOnDemandProfilePage
              history={this.props.history}
              myplacerequest={true}
            />
          </div>
          {/*------------Second----------*/}

        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  // console.log(state)
  return {
    providerProfile: state.serviceOnDemandReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      GetServiceProfile,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  serviceOnDemandProvierProfilePage
);
