//this is going to store Firebase realtime database API code
import { db } from "./firebase";

//##########3 user API


// update user_dashboard node  and store it at user_dashboard/id path (it's an asynchronous func)
export const doUpdateUserDashboard = (id, value) =>
  db.ref(`user_dashboard/${id}`).set(value);


//create an user and store it at users/id path (it's an asynchronous func)
// export const doCreateUser = (id, username, email) =>
//   db.ref(`users/${id}`).set({
//     username,
//     email
//   });

//returns all users from firebase realtime db
export const onceGetUsers = () => db.ref("users").once("value");

export const doGetAnUnser = uid => db.ref(`users/${uid}`).once("value");

// other APIs could come below
