import React, {Component} from 'react';
import {Row, Col, Button, Modal, ModalBody} from 'reactstrap';
import ScrollMenu from 'react-horizontal-scrolling-menu';

import contactIcon from '../../images/icons/messenger.png';
import rdeePay from '../../images/icons/wallet.png';

import profilePlaceholder from '../../images/profile_placeholder.png';
import marketplaceIcon from '../../images/icons/marketPlace.png';
import systemIcon from '../../images/sidebar/system.png';

import listGrayIcon from '../../images/icons/listIcongray.png';
import gridWhiteIcon from '../../images/icons/gridwhiteicon.png';

import listwhiteIcon from '../../images/icons/listwhiteIcon.png';
import grayGridIcon from '../../images/icons/grayGridIcon.png';
import leftbackarrow from '../../images/icons/leftbackarrow.png';

import * as routes from '../../constants/routes';

import close_icon from '../../images/close_icon.png';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import AllnotificationPage from './allnotification/allnotification';
import './notification.css';

import {
  getUserNotifications,
  deleteNotifications,
  readUserNotifications,
} from '../../actions/contacts';
import { ShowNameAccordingToLength } from '../../utility/stringFunctions';
import {notificationdataTimeFormat} from '../../utility/convertTimeString';

import {checkAvailableAccess} from '../../constants/localMessage/errorMessage';
import InfoSyscoming from '../../images/comingsoon/InfoSyscoming.svg';
import  CommonAccessContainer from './../../components/UI/commonAccessContainer/commonAccessContainer';

import nextWhiteArrow from '../../images/icons/carousel/nextWhiteArrow.svg';
import previousWhiteArrow from '../../images/icons/carousel/previousWhiteArrow.svg';

const Arrow = ({ text, className }) => {
  return (
    <div
      className={className}
    ><img className={className} src={text} alt="arrow"/></div>
  );
};
const ArrowLeft = Arrow({ text: previousWhiteArrow, className: 'arrow-prev arrowStyle' });
const ArrowRight = Arrow({ text: nextWhiteArrow, className: 'arrow-next arrowStyle' });

class notificationPage extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      notificationList: [],
      messengerNotification: [],
      pacs: [],
      system: [],
      marketplace: [],
      payNotification: [],
      serviceOndemand: [],
      modal: false,
      notificationdetail: '',
      activeGrid: true,
      isAccess:true

    };
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps && nextProps.contacts) {
      if (nextProps.contacts.notifications) {
          const messengerMsg = [];
          const messengerRDPay = [];
          const pacsMsg = [];
          const RDmarketplace = [];
          const serviceOndemand = [];
          const systemMsg = [];
        if (nextProps.contacts.notifications.length > 0) {

          for (let items of nextProps.contacts.notifications) {
            // items.notificationdate = items.updatedAtISO
            //   ? moment (items.updatedAtISO).format ('HH:ss DD MMM')
            //   : '';
              items.notificationdate =items.updatedAtISO?notificationdataTimeFormat(items.updatedAtISO):''
              
              if (items.source === 'messenger' || items.source === 'contacts') {
                messengerMsg.push (items);
              }
              if (items.source === 'rdeepay') {
                messengerRDPay.push (items);
              }
              if (items.source === 'pacs') {
                pacsMsg.push (items);
              }
              if (items.source === 'rdeemarketplace') {
                RDmarketplace.push (items);
              }
              if (items.source === 'serviceondemand') {
                serviceOndemand.push (items);
              }
              if (items.source === 'system') {
                systemMsg.push (items);
              }
            }
      }

          this.setState ({
            messengerNotification: this.sortByDate(messengerMsg).slice (0, 5),
            payNotification: this.sortByDate(messengerRDPay),
            serviceOndemand: this.sortByDate(serviceOndemand),
            system: this.sortByDate(systemMsg),
            marketplace: this.sortByDate(RDmarketplace),
            pacs: this.sortByDate(pacsMsg),
            modal: false,
          });
        }
        // this.setState ({
        //   notificationList: nextProps.contacts.notifications,
        // });
      }
  }

  sortByDate(lising){

    lising.sort(function(a,b){
      return Number(new Date(b.updatedAtISO)) - Number(new Date(a.updatedAtISO));
    });
    return lising;
  }

  componentDidMount () {
    // let loginUser = JSON.parse (localStorage.getItem ('loginuser'));


    if(checkAvailableAccess ("notifications") && checkAvailableAccess ("notifications")=== 'notifications'){
      this.setState({
        isAccess:true
      })

        const sendRequest = {
          data: {},
        };

        this.props.getUserNotifications (sendRequest);
      }else{
        this.setState({
          isAccess:false
        })
      }
  }

  closeModal () {
    this.setState ({
      modal: false,
    });
  }
  openModal (item) {
    this.setState ({
      modal: true,
      notificationdetail: item,
    });
  }

  deleteNotification () {
    let sendRequest = {
      data: {
        messageID: this.state.notificationdetail.messageID,
      },
    };

    this.props.deleteNotifications (sendRequest);
  }

  selectViewMode (status) {
    this.setState ({
      activeGrid: status,
    });
  }

  _handleKeyVal (receiver) {
    let keyVal = '';
    let userId = this.props.userInfo.user.Data.userDetails[0].personalDetails
      .docID;
    if (receiver > userId) {
      keyVal = `${receiver}${userId}`;
    } else {
      keyVal = `${userId}${receiver}`;
    }
    return keyVal;
  }

  goToDetail (personDetail) {
    if (personDetail.status === 'unread') {
      let sendRequest = {
        data: {
          messageID: personDetail.messageID,
        },
      };
      this.props.readUserNotifications (sendRequest);
    }
    if (personDetail.source === 'messenger') {

      if(personDetail.chatType==="singleChat"){
      let personalDetail = personDetail;
      let userChatInfo = {
        oppositeUserUid: personalDetail.senderUID,
        profilePictureURL: personalDetail &&
          personalDetail.senderProfilePictureURL
          ? personalDetail.senderProfilePictureURL
          : profilePlaceholder,
        displayName: personalDetail && personalDetail.senderFirstName
          ? personalDetail.senderFirstName
          : '' ,
      };
      this.props.history.push (routes.MESSENGER, {
        userChatInfo: userChatInfo,
        messengerKey: this._handleKeyVal (personalDetail.docID),
      });
    }
        else{

          let userChatInfo={
            chatType: "groupChat",
            displayName: personDetail.displayName,
            groupIcon: personDetail.groupIcon,
            groupId: personDetail.messengerGroupID,
            key: personDetail.messengerGroupID,
          }




          this.props.history.push (routes.MESSENGER, {
            userChatInfo: userChatInfo});
        }
      
    }

    if (personDetail.source === 'contacts') {
      this.props.history.push (routes.CONTACTUSERDETAIL + `/${personDetail.senderUID}`);

    }

    if (personDetail.source === 'rdeemarketplace') {
      if (personDetail.navigateToID) {
        if (personDetail.orderScreenType === 'buyer') {
          this.props.history.push (
            routes.PLACED_ORDERS_DETAILS + `/${personDetail.navigateToID}`
          );
        }
        if (personDetail.orderScreenType === 'seller') {
          this.props.history.push (
            routes.SELLER_ORDER_DETAIL + `/${personDetail.navigateToID}`
          );
        }
      }
    }
  }
  render () {
    const {
      messengerNotification,
      payNotification,
      marketplace,
      system,
      activeGrid,
      isAccess
    } = this.state;
    return (
      <div className="flex">
         <div className="notificationContainer" id="notificationContainerList1">
         {isAccess &&<div id="notificationContainerList2">
            <div className="notificationText" id="notificationContainerList3">
              My <span style={{fontWeight: 'bold'}} id="notificationContainerList4">Notifications</span>
              {/*} <Button
                className="viewAllnotificationButton"
                onClick={() => this.props.history.push (routes.ALLNOTIFICATION)}
              >
                {' '}View All As List{' '}
    </Button>*/}

              <Button
                className="viewButton"
                onClick={() => this.selectViewMode (true)}
                style={{marginLeft: 50}}
                data-rh="Grid view"
                id="notificationContainerList5"
              >
                {activeGrid
                  ? <img
                      src={gridWhiteIcon}
                      alt="listicon"
                      style={{width: 27}}
                      id="notificationContainerList6"
                    />
                  : <img
                      src={grayGridIcon}
                      alt="listicon"
                      style={{width: 27}}
                      id="notificationContainerList7"
                    />}
              </Button>
              <Button
                className="viewButton"
                onClick={() => this.selectViewMode (false)}
                data-rh="List view"
                id="notificationContainerList8"
              >
                {activeGrid
                  ? <img
                      src={listGrayIcon}
                      alt="listicon"
                      style={{width: 30}}
                      id="notificationContainerList9"
                    />
                  : <img
                      src={listwhiteIcon}
                      alt="listicon"
                      style={{width: 30}}
                      id="notificationContainerList10"
                    />}
              </Button>

              <Button
                className="notibackbutton"
                onClick={() => this.props.history.goBack ()}
                data-rh="Go back"
                id="notificationContainerList11"
              >
                <div>
                  <img src={leftbackarrow} alt="backIcon" id="notificationContainerList12"/>
                </div>

                Go <span> Back</span>
              </Button>
            </div>
            {activeGrid &&
              <div className="rootNotificationContainer" id="notificationContainerList13">
                {/* ------My Messages----- */}

                <div className="mainCardDiv" id="notificationContainerList14">
                  <div>
                    <div className="categoryBg" id="notificationContainerList15">
                      <img alt="contactIcon" src={contactIcon} className="headingIcon" id="notificationContainerList16"/>
                      <span className="cardHeadingText" id="notificationContainerList17">Connect</span>
                    </div>
                  </div>
                  <div className="mTop5 horizontalScroll" id="notificationContainerList18">
                    {messengerNotification.length == 0 &&
                      <p className="noNotificationFound" id="notificationContainerList19">No Notifications </p>}
                      <ScrollMenu
                      alignCenter={false}
                      data={
                      messengerNotification.map ((item, index) => (
                        <div
                          key={index}
                          id={`notificationGridMymessage${index}`}
                          
                          className="notificationMessagescontainer"
                          style={{
                            background: item.status === 'unread'
                              ? '#17171D'
                              : '#333333',
                          }}
                        >
                          <div  id={`notificationGridMymessageBox${index}`} className="topTitleHeader" style={{justifyContent:'space-between'}}>
                            <div id={`notificationGridMymessageBoxhead${index}`}  className="displayInlineFlex headingContainer" onClick={() => this.goToDetail (item)}>
                              {item.status === 'unread' &&
                                <div id={`notificationGridMymessageBoxunread${index}`} className="redColorEllipse" />}
                              <span id={`notificationGridMymessageBoxSource${index}`} className="messageDate">
                              {item.source=== "contacts"? 'Contact' :'Message'} • {item.notificationdate}

                              </span>
                            </div>
                            <div>
                              <Button
                                className="removeButton"
                                onClick={() => this.openModal (item)}
                                data-rh="Delete"
                                id={`notificationGridMymessageBoxDelete${index}`}
                              >
                                <img alt="close_icon" src={close_icon} />
                              </Button>
                            </div>
                          </div>
                          <div id={`notificationGridMymessageBoxContent${index}`} className="bottomContent" onClick={() => this.goToDetail (item)}>
                            <div className="notification-image" id={`notificationGridMymessageBoximage${index}`}>
                              <img
                                src={
                                  item.senderProfilePictureURL
                                    ? item.senderProfilePictureURL
                                    : profilePlaceholder
                                }
                                onError={e => {
                                  e.target.onerror = null;
                                  e.target.src = profilePlaceholder;
                                }}
                                className="profileIMGCss"
                                alt="profileIMGCss"

                                id={`notificationGridMymessageBoxProfile${index}`}
                              />
                            </div>
                            <div className="notification-info " id={`notificationGridMymessageBoxNotiinfo${index}`}>
                              <p className="notification-heading" id={`notificationGridMymessageBoxnotiinfohead${index}`}>
                              
                                {item? ShowNameAccordingToLength(item.senderFirstName+" "+ item.senderLastName):''}
                              </p>
                              <p className="notification-body" id={`notificationGridMymessageBoxnotibody${index}`}>
                                {item.body}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                      arrowLeft={ArrowLeft}
                  arrowRight={ArrowRight}
                />

                  </div>

                </div>
                {/* ------My Messages----- */}

                {/* ------RDEE Wallet----- */}
                <div className="mainCardDiv" id="notificationGridRDEEBox">
                  <div>
                    <div className=" categoryBg" id="notificationGridRDEEBox1">
                      <img src={rdeePay} className="headingIcon" alt="headingIcon" id="notificationGridRDEEBox2"/>
                      <span className="cardHeadingText" id="notificationGridRDEEBox3">Settle</span>
                    </div>
                  </div>
                  <div className="mTop5 horizontalScroll" id="notificationGridRDEEBox4">
                    {payNotification.length === 0 &&
                      <p className="noNotificationFound">No Notifications </p>}

                      <ScrollMenu
                      alignCenter={false}
                      data={
                      payNotification.map ((item, index) => (

                        <div
                          key={index}
                          // onClick={() => this.goToDetail (item)}
                          className="notificationMessagescontainer"
                          id={`notificationGridRDEEPayBox${index}`}
                          style={{
                            background: item.status === 'unread'
                              ? '#17171D'
                              : '#333333',
                          }}
                        >
                          <div  id={`notificationGridRDEEPayBoxhead${index}`} className="topTitleHeader" style={{justifyContent:'space-between'}}>
                            <div  id={`notificationGridRDEEPayBoxcont${index}`} className="displayInlineFlex headingContainer" onClick={() => this.goToDetail (item)}>
                              {item.status === 'unread' &&
                                <div  id={`notificationGridRDEEPayBoxunread${index}`} className="redColorEllipse" />}
                              <span className="messageDate">
                              R-DEE Pay • {item.notificationdate}
                              </span>
                            </div>
                            <div>
                              <Button
                                className="removeButton"
                                onClick={() => this.openModal (item)}
                                data-rh="Delete"
                                id={`notificationGridRDEEPayBoxRemove${index}`}
                              >
                                <img src={close_icon}  alt="close_icon"/>
                              </Button>
                            </div>
                          </div>
                          <div  id={`notificationGridRDEEPayBoxContent${index}`} className="bottomContent" onClick={() => this.goToDetail (item)}>
                         
                            <div  id={`notificationGridRDEEPayBoxinfo${index}`} className="notification-info ">
                              <p  id={`notificationGridRDEEPayBoxhead${index}`} className="notification-heading">
                                {item.subject}
                              </p>
                              <p  id={`notificationGridRDEEPayBoxbody${index}`} className="notification-body">
                               {item.updatedAtEpoch ? notificationdataTimeFormat(item.updatedAtEpoch) :''}{' '} {item.body}
                              </p>
                            </div>
                          </div>
                        </div>


                        
                      ))}
                      arrowLeft={ArrowLeft}
                      arrowRight={ArrowRight}
                    />
    
                  </div>
                </div>
                {/* ------RDEE Wallet----- */}

                {/* ------Marketplace----- */}

                <div className="mainCardDiv"  id="notificationGridMarketplaceBox">
                  <div>
                    <div className="categoryBg"  id="notificationGridMarketplaceBox1">
                      <img alt="marketplaceIcon" src={marketplaceIcon} className="headingIcon"  id="notificationGridMarketplaceBox2"/>
                      <span  id="notificationGridMarketplaceBox3" className="cardHeadingText">R-DEE Marketplace</span>
                    </div>
                  </div>
                  <div className=" mTop5 horizontalScroll"  id="notificationGridMarketplaceBox4">
                    {marketplace.length === 0 &&
                      <p className="noNotificationFound">No Notifications </p>}

                      <ScrollMenu
                      alignCenter={false}
                      data={marketplace.map ((item, index) => (

                        <div
                          key={index}
                          // onClick={() => this.goToDetail (item)}
                          className="notificationMessagescontainer"
                          style={{
                            background: item.status === 'unread'
                              ? '#17171D'
                              : '#333333',
                          }}
                          id={`notificationGridMarketplaceBoxinner${index}`}
                        >
                          <div  id={`notificationGridMarketplaceBoxinnerhead${index}`} className="topTitleHeader" style={{justifyContent:'space-between'}}>
                            <div  id={`notificationGridMarketplaceBoxinnerheade${index}`} className="displayInlineFlex headingContainer" onClick={() => this.goToDetail (item)}>
                              {item.status === 'unread' &&
                                <div  id={`notificationGridMarketplaceBoxinnerunread${index}`} className="redColorEllipse" />}
                              <span className="messageDate">
                              R-DEE Marketplace  • {item.notificationdate}
                              </span>
                            </div>
                            <div>
                              <Button
                                className="removeButton"
                                onClick={() => this.openModal (item)}
                                data-rh="Delete"
                                id={`notificationGridMarketplaceBoxinnerDelete${index}`}
                              >
                                <img src={close_icon} alt="close_icon"/>
                              </Button>
                            </div>
                          </div>
                          <div  id={`notificationGridMarketplaceBoxinnerContent${index}`} className="bottomContent" onClick={() => this.goToDetail (item)}>
                         
                            <div  id={`notificationGridMarketplaceBoxinnerinfo${index}`} className="notification-info ">
                              <p  id={`notificationGridMarketplaceBoxinnerTitle${index}`} className="notification-heading">
                                {item.subject}
                              </p>
                              <p   id={`notificationGridMarketplaceBoxinnerBody${index}`} className="notification-body">
                                {item.body}
                              </p>
                            </div>
                          </div>
                        </div>
                        
                      ))}
                      arrowLeft={ArrowLeft}
                      arrowRight={ArrowRight}
                    />
    
                  </div>
                </div>
                {/* ------Marketplace----- */}

                {/* ------Service-on-Demand----- */}

                {/*} <div className="mainCardDiv">
              <div>
                <div className="categoryBg">
                  <img src={serviceondemandIcon} className="headingIcon" />
                  <span className="cardHeadingText">Service-on-Demand</span>
                </div>
              </div>
              <div className="displayInlineFlex mTop5 horizontalScroll">
                {serviceOndemand.length == 0 &&
                  <p className="noNotificationFound">No Notifications </p>}

                {serviceOndemand &&
                  serviceOndemand.length > 0 &&
                  serviceOndemand.map ((item, index) => (
                    <div className="notificationMessagescontainer" key={index} style={{background:item.status === 'unread' ? '#17171D': '#333333'}}>

                      <Row>

                        <Col xs="10" className="displayInlineFlex">
                          {item.status === 'unread' &&
                            <div className="redColorEllipse" />}
                          <span className="messageDate">
                            Service-on-Demand • {item.notificationdate}
                          </span>
                        </Col>
                        <Col xs="2">
                          <Button
                            className="removeButton"
                            onClick={() => this.openModal (item)}
                          >
                            <img src={close_icon} />
                          </Button>
                        </Col>
                      </Row>
                      <Row className="mTop5">
                        <Col xs="12">
                          <p className="titleName">{item.subject}</p>
                          <p className="discription">
                            {item.body}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  ))}
              </div>
                  </div>*/}
                {/* ------Service-on-Demand----- */}

                {/* ------PACS----- */}

                {/* <div className="mainCardDiv">
              <div>
                <div className="categoryBg">
                  <img src={pacsIcon} className="headingIcon" />
                  <span className="cardHeadingText">PACS</span>
                </div>
              </div>
              <div className="displayInlineFlex mTop5 horizontalScroll">
                {pacs.length == 0 &&
                  <p className="noNotificationFound">No Notifications </p>}

                {pacs &&
                  pacs.length > 0 &&
                  pacs.map ((item, index) => (
                    <div className="notificationMessagescontainer" key={index} style={{background:item.status === 'unread' ? '#17171D': '#333333'}}>

                      <Row>

                        <Col xs="10" className="displayInlineFlex">
                          {item.status === 'unread' &&
                            <div className="redColorEllipse" />}
                          <span className="messageDate">
                            PACS • {item.notificationdate}
                          </span>
                        </Col>
                        <Col xs="2">
                          <Button
                            className="removeButton"
                            onClick={() => this.openModal (item)}
                          >
                            <img src={close_icon} />
                          </Button>
                        </Col>
                      </Row>
                      <Row className="mTop5">
                        <Col xs="12">
                          <p className="titleName">{item.subject}</p>
                          <p className="discription">
                            {item.body}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  ))}
              </div>
                  </div>*/}
                {/* ------PACS----- */}

                {/* ------PACS----- */}

                <div className="mainCardDiv"  id="notificationGridSystemBox">
                  <div>
                    <div className="categoryBg" id="notificationGridSystemBox1">
                      <img src={systemIcon}  alt="systemIcon" className="headingIcon" id="notificationGridSystemBox2" />
                      <span className="cardHeadingText" id="notificationGridSystemBox3">System</span>
                    </div>
                  </div>
                  <div className="mTop5 horizontalScroll" id="notificationGridSystemBox4">
                    {system.length === 0 &&
                      <p className="noNotificationFound" id="notificationGridSystemBox5">No Notifications </p>}

                      <ScrollMenu
                      alignCenter={false}
                      data={
                      system.map ((item, index) => (
                        <div
                          className="notificationMessagescontainer"
                          key={index}
                          style={{
                            background: item.status === 'unread'
                              ? '#17171D'
                              : '#333333',
                          }}
                          id={`notificationGridSystemBoxIneer${index}`}
                        >
                          {/*<Row>

                        <Col xs="10" className="displayInlineFlex">
                          {item.status === 'unread' &&
                            <div className="redColorEllipse" />}
                          <span className="messageDate">
                            System • {item.notificationdate}
                          </span>
                        </Col>
                        <Col xs="2">
                          <Button
                            className="removeButton"
                            onClick={() => this.openModal (item)}
                          >
                            <img src={close_icon} />
                          </Button>
                        </Col>
                  </Row>*/}
                          <div  id={`notificationGridSystemBoxInnerContent${index}`} className="topTitleHeader" style={{justifyContent:'space-between'}}>
                            <div id={`notificationGridSystemBoxInnerContents${index}`}  className="displayInlineFlex headingContainer">
                              {item.status === 'unread' &&
                                <div  id={`notificationGridSystemBoxInnerContentunread${index}`} className="redColorEllipse" />}
                              <span className="messageDate" id={`notificationGridSystemBoxInnerContentDate${index}`}>
                                Message • {item.notificationdate}
                              </span>
                            </div>
                            <div>
                              <Button
                                className="removeButton"
                                onClick={() => this.openModal (item)}
                                data-rh="Delete"
                                id={`notificationGridSystemBoxInnerContentDelete${index}`}
                              >
                                <img src={close_icon}  alt="close_icon"/>
                              </Button>
                            </div>
                          </div>
                          <Row className="mTop5" id={`notificationGridSystemBoxInnerContentRow${index}`}>
                            <Col xs="12" id={`notificationGridSystemBoxInnerContentcol${index}`}>
                              <p className="titleName" id={`notificationGridSystemBoxInnerContenttitle${index}`}>{item.subject}</p>
                              <p className="discription" id={`notificationGridSystemBoxInnerContentDesc${index}`}>
                                {item.body}
                              </p>
                            </Col>
                          </Row>
                        </div>
                      ))}
                      arrowLeft={ArrowLeft}
          arrowRight={ArrowRight}
        />
                  </div>
                </div>
                {/* ------PACS----- */}
              </div>}
            {!activeGrid &&
              <AllnotificationPage history={this.props.history} />}
          </div>}
          {!isAccess &&<CommonAccessContainer bgImage={InfoSyscoming} message={checkAvailableAccess('notifications')} />}

        </div>



        <div>
          <Modal
            size="sm"
            isOpen={this.state.modal}
            centered={true}
            toggle={() => this.closeModal ()}
          >
            <ModalBody className="deleteModal" id="notificationDeleteConfirm">
              <div>

                <p className="confirmTitle" id="notificationDeleteConfirm1">Delete Notification?</p>
                <p className="confirmmsg"  id="notificationDeleteConfirm2">
                  Are you sure you wish to remove this
                  from your notifications?
                </p>
                <div className="buttonDiv"  id="notificationDeleteConfirm3">
                  <Button
                    className="buttons noButton"
                    onClick={() => this.closeModal ()}
                    id="notificationDeleteConfirm4"
                  >
                    No
                  </Button>
                  <Button
                    className="buttons yesButton"
                    onClick={() => this.deleteNotification ()}
                    id="notificationDeleteConfirm5"
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.ContactsReducer,
    userInfo: state.Authreducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getUserNotifications,
      deleteNotifications,
      readUserNotifications,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (notificationPage);
