import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {Button, Modal, ModalBody} from 'reactstrap';

import { saveAs } from 'file-saver';
// import deleteicon from '../../../../../images/icons/deleteicon.png';
import './bulkUploadProduct.css';
import leftbackarrow from '../../../../../images/icons/leftbackarrow.png';
import marketPlaceCart from '../../../../../images/icons/marketPlaceCart.png';

import productActionIcon
  from '../../../../../images/icons/productActionIcon.png';
import crossButtonMarketPlacesuccess
  from '../../../../../images/icons/crossButtonMarketPlacesuccess.png';

import SellerConsoleSidemenu
  from '../sellerSidemenu/sellerConsoleSidemenu';
import {
  marketplaceUploadProductFileFunction,
  uploadProductHistory,
  resetdoOrderValue,
  fetchProductManufacturer
} from '../../../../../actions/marketplaceaction';
import * as routes from '../../../../../constants/routes';
import RightSideContainer
  from '../../../../UI/rightSideContainer/rightSideContainer';
import {createNotification} from '../../../../../constants/notificationtoast';
import {dataTimeFormat} from '../../../../../utility/convertTimeString';


class BulkUploadAddProduct extends Component {
  constructor (props) {
    super (props);
    this.state = {
      productCategoryType:'',
      uploadList:[],
      selectedFile:'',
      selectedfileName:'',
      uploadErrorList:[],
      selectedfileType:'',
      uploadFile:false,
      savePublishConfirm:false,
      successModels:false,
      productCategoryTypeOptions:[]
    };
  }
  componentWillReceiveProps (nextProps) {
    if (nextProps) {
      if(nextProps.addProduct && nextProps.addProduct.productCategories){
        const obj= nextProps.addProduct.productCategories;
        let productCategoryTypeOptions=[];
        Object.entries(obj).forEach(([key, value]) => {
          productCategoryTypeOptions.push({
            label:value.label,value:value.value,templateURL:value.templateURL
          })
        });
        this.setState({
          productCategoryTypeOptions:productCategoryTypeOptions,
        },()=>{
          console.log(this.state.productCategoryTypeOptions)
        })
      }

      if (nextProps.addProduct && nextProps.addProduct.uploadError) {
          if(nextProps.addProduct.uploadError && nextProps.addProduct.uploadError.rejectedLines &&nextProps.addProduct.uploadError.rejectedLines.length>0){
            let errorArray=[];
            let getResponse= nextProps.addProduct.uploadError.rejectedLines;
            for(let item of getResponse){
              if(item){
                let childArray=[];
                for(let childItem of Object.keys(item)){
                  for(let mainsubchildItem of item[childItem]){
                      for(let subchildItem of Object.keys(mainsubchildItem)){
                        childArray.push({errorName:subchildItem,errorValue:mainsubchildItem[subchildItem]})
                      }
                  }
                  errorArray.push({lineError:childItem,errorRes:childArray});
                }
              }
            }


            this.setState({
              uploadErrorList:errorArray,
              // uploadErrorList:nextProps.addProduct.uploadError.rejectedLines,
              uploadFile:false,
            },()=>{
              console.log(this.state.uploadErrorList);
            })
          }else{
            this.setState({
              uploadFile:true,
              uploadErrorList:[]
            })
          }
          
      }
      if(nextProps.addProduct.uploadSuccess && nextProps.addProduct.uploadSuccess.fileUploadURL){


        this.setState({
          savePublishConfirm: false,
          successModels:true
        })

      }
    }
    if(nextProps.addProduct.uploadedHistory && nextProps.addProduct.uploadedHistory.length>0){

        this.setState({
          uploadList: nextProps.addProduct.uploadedHistory
        })

      
    }

    
  }

  componentDidMount () {
    this.props.resetdoOrderValue ();
  
      let sendRequest={
        data: {
            limit: '',
            offset: ''
           
        }
    }
    this.props.uploadProductHistory(sendRequest);
    this.props.fetchProductManufacturer();
  }
  selelectProductCat(value){
    if(this.state.uploadFile){
      return;
    } 
    this.setState({
      productCategoryType:value,
    })
  }
  downloadFile(){
    const {  productCategoryType,productCategoryTypeOptions} = this.state;
    // window.open(productCategoryType==='Radiology'?`${process.env.PUBLIC_URL}/`+'./RadiologyProducts.xlsx':'./NonRadiologyProducts.xlsx', 'Download');

    // var FileSaver = require('file-saver');

    for(let item of productCategoryTypeOptions){
      if(item && item.value && item.value===productCategoryType){
        saveAs(item.templateURL);
      }
    }
    // saveAs(productCategoryType==='Radiology'?require('./RadiologyProducts.xlsx'):require('./NonRadiologyProducts.xlsx'), productCategoryType==='Radiology'?'RadiologyProducts.xlsx':'NonRadiologyProducts.xlsx');

  }
  downloadUploadedFile(item){
    saveAs(item.fileUploadURL, item.fileUploadName)
  }

  onChangeUploadFile = e => {
    if(e && e.target &&  e.target.files){

    let files = e.target.files;
    if(files && files[0] && files[0].size){
      // console.log(files);

    let response = files[0].size / 1000000;
    // console.log(Math.floor(response));

    if (response && response > 5) {
      createNotification (
        'error',
        'The uploaded file is too large. Profile can only be a maximum of 5 MB.'
      );
      return false;
    }

    const reader = new FileReader();
        reader.addEventListener('load', () => {
        this.setState({
          selectedFile:reader.result,
          selectedfileName:files[0].name,
          selectedfileType:files[0].type,
          uploadFile: false,
          uploadErrorList:[]

          
        })});
      reader.readAsDataURL(e.target.files[0]);
    }
  }



      }



      checkFileValidation(){

        const {selectedfileName ,productCategoryType,selectedFile,selectedfileType} =this.state;
          let sendRequest={
            data: {
              productCategoryType: productCategoryType,
              productFileName: selectedfileName,
              productFileType: selectedfileType,
              // productFileType:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              productFile:selectedFile,
              checkOnly: true
            }
        }
        this.props.marketplaceUploadProductFileFunction(sendRequest);
      }

      

      uploadProduct(){
        // this.setState({
        //   successModels:true
        // })
        const {selectedfileName ,productCategoryType,selectedFile,selectedfileType} =this.state;
          let sendRequest={
            data: {
              productCategoryType: productCategoryType,
              productFileName: selectedfileName,
              productFileType: selectedfileType,
              productFile:selectedFile
            }
        }
        this.props.marketplaceUploadProductFileFunction(sendRequest);
      }

      closeModal(){
        this.setState({
          savePublishConfirm: false,
          successModels: false

        })
      }
    
 

  render () {
    const {  uploadList,productCategoryType,selectedFile,selectedfileName,uploadErrorList ,uploadFile ,savePublishConfirm ,successModels,productCategoryTypeOptions} = this.state;
    return (
      <div >
        <div>
          <div style={{textAlign: 'right'}}>
            <Button
              className="Bulkadddsbackbutton"
              onClick={() => this.props.history.goBack ()}
              data-rh="Go back"
            >
              <div>
                <img src={leftbackarrow} alt="backIcon" />
              </div>

              Go <span> Back</span>
            </Button>
          </div>
          <div className="addBulkContainer">
            <div>
                  
                <div className="bulkAddsellerTitle">Seller Console</div>
                <p className="bulkUploadTitle"> Bulk Upload Products</p>
              </div>

              <div className="inlineBulkContent">
              <div style={{width:450}}>

              <div>
                <div className="stepOneBulk">STEP <span> ONE</span></div>
                <p className="downloadListText">Download product listing template.</p>

                <div className="productTypeTitle">What type of products are you adding to the R-DEE Marketplace today?</div>

                <div className="categoryTypeButton">
                {productCategoryTypeOptions && productCategoryTypeOptions.length>0 && productCategoryTypeOptions.map((item,i)=>(
                <label key={i} id={`categoryidslabel${i}`} className="categoryTypeSelection" onClick={()=>this.selelectProductCat(item.value)}> 
                  
                
                  <div className="radioSelection " id={`categoryidslabelsection${i}`}>
                      {productCategoryType && productCategoryType===item.value && <div className="checkRadio" id={`categoryidsradio${i}`}/>}
                  </div>
                  
                  
                  {item && item.label?item.label:''}
                  </label>))}

                  {/* <label className="categoryTypeSelection" onClick={()=>this.selelectProductCat('Radiology')}> 
                  
                
                  <div className="radioSelection ">
                      {productCategoryType==='Radiology' && <div className="checkRadio"/>}
                  </div>
                  
                  Radiology & Equipment</label>
                  <label className="categoryTypeSelection"   onClick={()=>this.selelectProductCat('Non-Radiology')}>  
                  <div className="radioSelection ">
                  {productCategoryType!=='Radiology' &&<div className="checkRadio"/>}
                    </div> Products & PPE
          </label> */}
                 </div>
                 <div>
                 <Button disabled={!productCategoryType} className="downloadTemplateButton" onClick={()=>this.downloadFile()}>Download Template</Button>
                 <p className="downloadListText">Please download the template before uploading products data</p>
                 </div>
                
              </div>


              <div style={{marginTop:15}}>
                <div className="stepOneBulk">STEP <span>  TWO</span></div>
                <div className="downloadListText">Upload your product file.</div>
                <div style={{display:'inline-flex',alignItems:'center',paddingLeft: selectedFile ? 0:30}}>
                  {selectedFile && <Button className="selectedFileButton">{selectedfileName}</Button>}
                     <label style={{opacity:!productCategoryType?0.7:'',cursor:productCategoryType?'pointer':'initial'}} className="downloadTemplateButton overleySelectButton"> <input accept=".xlsx,.csv" className="hideFile" type="file"   onChange={e => this.onChangeUploadFile (e)} disabled={!productCategoryType}/> Select File</label>
               </div>
              
            </div>
            {selectedfileName && <div style={{marginTop:15}}>
                <div className="stepOneBulk">STEP <span>  THREE</span></div>
                <div className="downloadListText">Check your file for common listing errors.</div>
                {!uploadFile &&<div>
                <Button className="downloadTemplateButton" onClick={()=>this.checkFileValidation()}>Check My File</Button>
               </div>}
               <div>
               {uploadErrorList && uploadErrorList.length>0 &&<div>
               <div>
                <Button className="fileErrorButton">File Errors</Button>
               </div>

               <div style={{marginTop:10}}>
                <div className="productTypeTitle">Please review your file for the following errors and upload again.</div>
                <div className="downloadListText"  style={{marginTop:15}}>
                  <div>ERRORS:</div>
                  {uploadErrorList.map((item,i)=>(
                    <div key={i}>
                    <div style={{fontWeight:'bold',paddingLeft:20}}> Product line number : {item && item.lineError?item.lineError:''}</div>
                     {item && item.errorRes.length>0 && item.errorRes.map((childItem,j)=>(<div key={j}>
                    
                     <ul>
                       <li> 
                           {childItem && childItem.errorName? childItem.errorName:''} :  {childItem && childItem.errorValue? childItem.errorValue:''}
                       </li>
                   </ul>
                   </div>
                  ))}
                    
                    </div>

                  ))}
                  </div>
               </div>
               </div>}
               {uploadFile &&<div> <Button className="fileSuccessButton"> Success!</Button></div>}
               </div>
               
            </div>}
            </div>

            <div>
              <div className="whitleGridBox">
                <div className="monitorStatusbutton">File Upload History</div>
                <div style={{margin:20}}>
                {uploadList && uploadList.length>0 ? <div>
                  <div className="bulkGridHeader">
                  
                    <div>Date/Time of Upload</div>
                    <div>File </div>
                  </div>

                  {uploadList && uploadList.map((item,i)=><div style={{ backgroundColor: (i % 2 == 0) ? 'rgb(60 76 214 / 20%)' : '#FFFFFF' }} key={i} className="bulkGridItem">
                    <div>{item.lastUpdatedAtISO ? dataTimeFormat(item.lastUpdatedAtISO) :'N/A'}</div>
                    <div> 

                    <Button className="downloadFileButton" onClick={()=>this.downloadUploadedFile(item)}>Download</Button>
                    
                    </div>
                  </div>)}
                  </div>:
                  <div className="noHistory">
                    No history found

                  </div>}
                
                </div>
              
              </div>
            
            
            </div>

            </div>

            <div style={{marginTop:15}}>
            {!uploadFile && <Button style={{cursor:'unset'}} disabled className="submitTomarketPlace">Submit to Marketplace</Button>}
            {uploadFile && <Button className="submitTomarketPlaceNext" onClick={()=>this.setState({savePublishConfirm: true})}>Submit to Marketplace</Button>}
            </div>
            
    

              
        </div>
        </div>
        <RightSideContainer>
          <SellerConsoleSidemenu />
        </RightSideContainer>


        <Modal
            size="sm"
            isOpen={savePublishConfirm}
            centered={true}
            toggle={() => this.closeModal ()}
          >
            <ModalBody className="AddProductConfirmOrderModal">
              <div>

                <p className="confirmTitle">Ready to Submit?</p>
                <p className="confirmmsg">
                  Do you want to make these products available for purchase within the R-DEE Marketplace?
                  {' '}

                </p>
                <div className="buttonDiv">
                  <Button
                    className="buttons"
                    onClick={() => this.closeModal ()}
                  >
                    No
                  </Button>
                  <Button
                    className="buttons yesButton"
                    onClick={() => this.uploadProduct ()}
                  >
                    Yes
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>


          <Modal
            size="sm"
            isOpen={successModels}
            centered={true}
            toggle={() => this.closeModal ()}
          >
            <ModalBody className="AddSuccessModal">
            <div>
              <div>
                <Button
                  className="closeButton"
                  onClick={() => this.closeModal ()}
                >
                  <img alt="crossButtonMarketPlacesuccess" src={crossButtonMarketPlacesuccess} />
                </Button>
                </div>

                <div>

                    <p className="heading">Submitted to Marketplace</p>

                    <div
                      style={{
                        textAlign: 'center',
                        marginTop: 30,
                        marginBottom: 20,
                      }}
                    >
                      <img alt="marketPlaceCart" src={marketPlaceCart} className="cartImage" />
                    </div>
                    <p className="subtitle" style={{marginTop: 10}}>
                      Item Submitted!

                    </p>
                    <p className="description" style={{marginTop: 10}}>

         
                        Your items are now publicly 
                        available in the R-DEE Marketplace.
                    </p>
                  </div>

                  <div>
                  <Button
                    className="returnSellerConsole"
                    onClick={() => {
                      this.closeModal ();
                      this.props.history.push (routes.SELLER_CONSOLE);
                    }}
                  >
                    Return to Seller Console
                  </Button>
                  <Button
                    className="backDahboard"
                    onClick={() => {
                      this.closeModal ();
                      this.props.history.push (routes.PROFILE_DASHBOARD);
                    }}
                    data-rh="Dashboard"
                  >
                    Back to Dashboard
                  </Button>
                </div>

                </div>

                </ModalBody>
              </Modal>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    addProduct: state.MarketplaceReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      resetdoOrderValue,
      uploadProductHistory,
      marketplaceUploadProductFileFunction,
      fetchProductManufacturer
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (BulkUploadAddProduct);
