import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
  
  Row,
  Col,
  Button,
  
  FormGroup,
  Label,
  Input,
  Alert,
} from 'reactstrap';
import logo from '../logo.svg';
import * as routes from '../../constants/routes';
import {auth, db} from '../../firebase';
import {getFindBusiness, register} from '../../actions/Authaction';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {createNotification} from '../../constants/notificationtoast';
import businessTypes from '../../constants/localJSON/businessTypes.json';


// import coutryflag from '../../images/coutryflag.png';

import PhoneInput from 'react-phone-number-input';

//################### Sign Up Form ###################

//A Higher order function with prop name as key and the value to be assigned to
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class FindBusinessPage extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      showingAlert: false,
      error: false,
      addNew: false,
      businessType: '',
      businessSize: '',
      businessTaxFileType: '',
      businessRegistration: '',
      businessRegistrationFileType: '',
      businessSizeoptions: [
        {
          label: '1',
          value: '1',
        },
        {
          label: '2-5',
          value: '2-5',
        },
        {
          label: '5-20',
          value: '5-20',
        },
        {
          label: '20-100',
          value: '20-100',
        },
        {
          label: '100-500',
          value: '100-500',
        },
        {
          label: '500+',
          value: '500+',
        },
      ],
      businessTypeoptions: businessTypes,
      secondStep: false,
      displayedBusinessName: '',
      registeredBusinessName: '',
      businessWebsiteUrl: '',
      businessEmailAddress: '',
      businessPhoneNumber: '',
      yearEstablished: '',
      documentSizeError: false,
      personalDocumentError: false,
      isFirstSubmit: false,
      isSecondSubmit: false,
      businessTax: '',
      invalidEmailFormat: false,
      countrycode: '',
      // addNew: false,
      // secondStep: true,
      // isSecondSubmit: false
    };
  }
  componentWillReceiveProps (nextProps) {
   

    if (nextProps && nextProps.userinfo) {
      if (
        nextProps.userinfo.registeruser &&
        nextProps.userinfo.registeruser.result
      ) {
        if (nextProps.userinfo.registeruser.result.status) {
       
          localStorage.setItem (
            'RegisterData',
            JSON.stringify (nextProps.userinfo.registeruser.result.Data)
          );
          this.props.history.push (routes.BUSINESSCONTACTS);
          // this.props.history.push (routes.INFORMATIONSUBMIT);
        }
      }
    }
  }

  // onChange = (propName, value) => {
  //   this.setState({
  //     [propName]: value
  //   });
  // };

  onSubmit = () => {
    const {indivisual, businessSize, agree} = this.state;

    const isInvalid =
      indivisual === '' || businessSize === '' || agree === false;
    this.setState ({
      isFirstSubmit: true,
    });

    if (isInvalid) {
      return false;
    }

    this.setState ({
      addNew: false,
      secondStep: true,
      isSecondSubmit: false,
    });

    // event.preventDefault(); //prevents refreshing
  };
  onSubmitNext () {
    const {
      error,
      showingAlert,
      indivisual,
      businessSize,
      businessType,
      agree,
      displayedBusinessName,
      registeredBusinessName,
      businessWebsiteUrl,
      businessEmailAddress,
      businessPhoneNumber,
      yearEstablished,
      isFirstSubmit,
      countrycode,
    } = this.state;

    const isvalid2 =
      displayedBusinessName === '' ||
      registeredBusinessName === '' ||
      businessWebsiteUrl === '' ||
      businessEmailAddress === '' ||
      businessPhoneNumber === '' ||
      yearEstablished === '';

    this.setState ({
      isSecondSubmit: true,
    });

    if (businessEmailAddress) {
      const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
      const result = pattern.test (businessEmailAddress);
  
      if (!result) {
        this.setState ({
          invalidEmailFormat: true,
        });
      } else {
        this.setState ({
          invalidEmailFormat: false,
        });
      }
    }

    if (isvalid2) {
      return false;
    }

    let sendRequest = {
      data: {
        businessID: "",
        acceptedTermsConditions: true,
        businessAddress_1: '',
        businessAddress_2: '',
        businessCity: '',
        businessCountry: '',
        businessEmailAddress: this.state.businessEmailAddress,
        businessPhoneNumber: this.state.businessPhoneNumber,
        businessPostalCode: '',
        businessRegistrationFileType: this.state.businessRegistrationFileType,
        businessSize: this.state.businessSize,
        businessState: '',
        businessTaxFileType: this.state.businessTaxFileType,
        businessType: this.state.businessType,
        businessWebsiteUrl: this.state.businessWebsiteUrl,
        displayedBusinessName: this.state.displayedBusinessName,
        registeredBusinessName: this.state.registeredBusinessName,
        representativeFirstName: '',
        representativeLastName: '',
        yearEstablished: this.state.yearEstablished,
        businessRegistration: this.state.businessRegistration,
        businessTax: this.state.businessTax,
      },
    };

    localStorage.setItem ('registerBusiness', JSON.stringify (sendRequest));

    this.props.register (sendRequest);
  }

  timer = () => {
    this.setState ({
      showingAlert: true,
    });

    setTimeout (() => {
      this.setState ({
        showingAlert: false,
      });
    }, 4000);
  };

  onChangeRegistration = e => {
    console.warn ('warning function call details');
    let files = e.target.files;
    let response = files[0].size / 1000000;


    if (response && response > 5) {
      this.setState ({
        documentSizeError: true,
      });
      return false;
    }

    let fileType = files[0].name.split ('.').pop ();


    if (
      fileType.toLowerCase () === 'png' ||
      fileType.toLowerCase () === 'pdf' ||
      fileType.toLowerCase () === 'jpeg' ||
      fileType.toLowerCase () === 'jpg'
    ) {
      let reader = new FileReader ();
      reader.readAsDataURL (files[0]);
      reader.onload = e => {
        this.setState (
          byPropKey (
            'businessRegistrationFileType',
            fileType === 'pdf' ? 'application/pdf' : 'application/pdf'
          )
        );
        this.setState (byPropKey ('businessRegistration', e.target.result));
      };
    } else {
      createNotification ('error', 'Accept only pdf or jpeg');
    }
  };

  onChangeDocument = e => {
    console.warn ('warning function call details');
    let files = e.target.files;

    let fileType = files[0].name.split ('.').pop ();
  

    if (
      fileType.toLowerCase () === 'png' ||
      fileType.toLowerCase () === 'pdf' ||
      fileType.toLowerCase () === 'jpeg' ||
      fileType.toLowerCase () === 'jpg'
    ) {
      let reader = new FileReader ();
      reader.readAsDataURL (files[0]);
      reader.onload = e => {
        this.setState (
          byPropKey (
            'businessTaxFileType',
            fileType === 'pdf' ? 'application/pdf' : 'application/pdf'
          )
        );
        this.setState (byPropKey ('businessTax', e.target.result));
      };
    } else {
      this.setState ({
        personalDocumentError: true,
      });
      createNotification ('error', 'Accept only pdf or jpeg');
    }
  };
  onSelectFlag (countryCode) {
    console.log (countryCode);
  }

  render () {
    const {
      error,
      showingAlert,
      indivisual,
      businessSize,
      businessType,
      agree,
      displayedBusinessName,
      registeredBusinessName,
      businessWebsiteUrl,
      businessEmailAddress,
      businessPhoneNumber,
      yearEstablished,
      isFirstSubmit,
      isSecondSubmit,
      invalidEmailFormat,
      countrycode,
    } = this.state;
    // //a boolen to perform validation
    // const isInvalid =
    //   passwordOne === "" ||
    //   email === "" ||
    //   firstname === "" ||
    //   mobile === "";

    // const isInvalid=  indivisual ==='' || businessSize==='' || agree ===false;
    // const isvalid2= displayedBusinessName ==='' ||
    // registeredBusinessName==='' ||
    // businessWebsiteUrl==='' ||
    // businessEmailAddress==='' ||
    // businessPhoneNumber==='' ||
    // yearEstablished==='' ;

    return (
      <div className="div-flex-education">
        <div className="div-flex-businessScroll">

          {this.state.addNew &&
            <Row>
              {showingAlert &&
                <Alert color="danger" onLoad={this.timer}>
                  {error.message}
                </Alert>}
              <Col sm="12" md="12" lg="12" className="text-center">
                <img src={logo} />
              </Col>
              <Col xs="12" sm="12" md="12" lg="12" className="pClass">
                <p>
                  Insert Business Additional Details
                </p>
              </Col>

              <Col xs="12" sm="12" md="4" lg="4" md={{size: 4, offset: 2}}>
                <FormGroup className="formInputField f_right">

                  <Input
                    type="select"
                    name="businessType"
                    id="businessType"
                    className="inputSelect"
                    value={businessType}
                    onChange={e =>
                      this.setState (
                        byPropKey ('businessType', e.target.value)
                      )}
                  >
                    <option xs="12" lg="4" value="">
                      Business Type*
                    </option>
                    {this.state.businessTypeoptions.map ((item, index) => (
                      <option key={index} xs="12" lg="4" value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Input>

                  {isFirstSubmit &&
                    !businessType &&
                    <p className="validationerrorMessage" style={{}}>
                      Business type  is required*
                    </p>}

                </FormGroup>
              </Col>
              <Col xs="6" sm="6" md="4" lg="4">
                <FormGroup className="formInputField ">
                  <Input
                    type="select"
                    name="businessSize"
                    id="businessSize"
                    className="inputSelect"
                    value={businessSize}
                    onChange={e =>
                      this.setState (
                        byPropKey ('businessSize', e.target.value)
                      )}
                  >
                    <option xs="12" lg="4" value="">
                      BusinessSize*
                    </option>
                    {this.state.businessSizeoptions.map ((item, index) => (
                      <option key={index} xs="12" lg="4" value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Input>
                  {isFirstSubmit &&
                    !businessSize &&
                    <p className="validationerrorMessage">
                      Business Size  is required*
                    </p>}
                </FormGroup>
              </Col>

              <Col xs="12" sm="12" md="4" lg="4" md={{size: 4, offset: 2}}>
                <Row
                  style={{
                    float: 'right',
                    width: 350,
                    justifyContent: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    md={{size: 1, offset: 0}}
                  >

                    <label
                      className="custom-file-upload"
                      style={{float: 'right'}}
                    >
                      <input
                        type="file"
                        name="Registration"
                        onChange={e => this.onChangeRegistration (e)}
                      />
                      <i className="fa fa-cloud-upload" />Upload
                    </label>
                    <p style={{color: '#fff'}} className="no_margin">
                      Business Registration Document
                    </p>
                    <p style={{color: '#ddd', opacity: 0.5, fontSize: 12}}>
                      Only PDF and JPEG File Accept
                    </p>

                  </Col>
                  {this.state.documentSizeError &&
                    <p
                      className="validationerrorMessage"
                      style={{textAlign: 'center'}}
                    >
                      The uploaded file is too large. Documents can only be a maximum of 5 MB.
                    </p>}
                </Row>
              </Col>
              <Col xs="6" sm="6" md="4" lg="4">
                <Row
                  style={{
                    float: 'left',
                    width: 350,
                    justifyContent: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}
                >
                  <Col
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    md={{size: 1, offset: 0}}
                  >

                    <label
                      className="custom-file-upload"
                      style={{float: 'right'}}
                    >
                      <input
                        type="file"
                        name="Document"
                        onChange={e => this.onChangeDocument (e)}
                      />
                      <i className="fa fa-cloud-upload" />Upload
                    </label>
                    <p style={{color: '#fff'}} className="no_margin">
                      PAN or Tax Document
                    </p>
                    <p style={{color: '#fff', opacity: 0.5, fontSize: 12}}>
                      Only PDF and JPEG File Accept
                    </p>
                  </Col>
                  {this.state.personalDocumentError &&
                    <p
                      className="validationerrorMessage"
                      style={{textAlign: 'center'}}
                    >
                      Invalid file type. Documents of only PDF and JPEG
                      are supported.
                    </p>}

                </Row>
              </Col>
              <Label
                xs="6"
                sm="6"
                md="4"
                lg="4"
                md={{size: 4, offset: 4}}
                style={{marginTop: 15}}
              >
                <FormGroup>
                  <Input
                    className="agreeCheckbox"
                    type="checkbox"
                    name="agree"
                    id="agree"
                    placeholder="agree*"
                    value={agree}
                    onChange={e => this.setState (byPropKey ('agree', !agree))}
                    style={{marginTop: 6}}
                  />
                  <p className="agreeWithText">
                    I agree with
                    <span style={{color: '#4092B7'}}> Privacy Policy </span>
                    &
                    <span style={{color: '#4092B7'}}>
                      {' '}Terms and Conditions{' '}
                    </span>

                  </p>
                </FormGroup>
              </Label>

              <Col
                xs="12"
                sm="12"
                lg="4"
                md={{size: 4, offset: 4}}
                className="text-center"
              >
                <FormGroup>
                  <Button
                    onClick={() => this.onSubmit ()}
                    className="buttonClass"
                  >
                    Submit
                  </Button>

                  <div className="text-center linkRegiterClass">
                    <Link to={routes.SIGN_UP}>Go Back</Link>

                  </div>
                </FormGroup>
              </Col>
            </Row>}

          {this.state.secondStep &&
            <Row>
              {showingAlert &&
                <Alert color="danger" onLoad={this.timer}>
                  {error.message}
                </Alert>}
              <Col sm="12" md="12" lg="12" className="text-center">
                <img src={logo} />
              </Col>
              <Col xs="12" sm="12" md="12" lg="12" className="pClass">
                <p>
                  Insert your Business Primary Details
                </p>
              </Col>
              <Col
                xs="12"
                sm="12"
                lg="4"
                md="4"
                md={{size: 4, offset: 2}}
                className="text-center"
              >

                <FormGroup>
                  <Input
                    type="text"
                    name="registeredBusinessName"
                    id="registeredBusinessName"
                    placeholder="Registered Business Name *"
                    value={this.state.registeredBusinessName}
                    onChange={e =>
                      this.setState (
                        byPropKey ('registeredBusinessName', e.target.value)
                      )}
                    className="inputClass"
                  />

                  {isSecondSubmit &&
                    !registeredBusinessName &&
                    <p
                      className="validationerrorMessage"
                      style={{textAlign: 'left'}}
                    >
                      Business name is required *
                    </p>}

                </FormGroup>
              </Col>
              <Col xs="12" sm="12" lg="4" md="4" className="text-center">
                <FormGroup>
                  <Input
                    type="text"
                    name="displayedBusinessName"
                    id="displayedBusinessName"
                    placeholder="Display Business Name *"
                    value={this.state.displayedBusinessName}
                    onChange={e =>
                      this.setState (
                        byPropKey ('displayedBusinessName', e.target.value)
                      )}
                    className="inputClass"
                  />
                  {isSecondSubmit &&
                    !displayedBusinessName &&
                    <p
                      style={{textAlign: 'left'}}
                      className="validationerrorMessage"
                    >
                      Display business name is required *
                    </p>}
                </FormGroup>
              </Col>
              <Col xs="12" sm="12" lg="4" md="4" md={{size: 4, offset: 2}}>
                <FormGroup>
                  {/*<Select
              options={this.state.options}
              value={country}
              onChange={e => console.log(e.target.value)}
            />*/}
                  <Input
                    type="select"
                    name="yearEstablished"
                    id="yearEstablished"
                    className="inputSelect"
                    value={this.state.yearEstablished}
                    onChange={e =>
                      this.setState (
                        byPropKey ('yearEstablished', e.target.value)
                      )}
                  >
                    <option xs="12" lg="4" value="">
                      Year Established *
                    </option>
                    <option xs="12" lg="4" value="2001">2001</option>
                    <option xs="12" lg="4" value="2002">2002</option>
                    <option xs="12" lg="4" value="2003">2003</option>

                  </Input>

                  {isSecondSubmit &&
                    !yearEstablished &&
                    <p className="validationerrorMessage">
                      Year established is required *
                    </p>}
                </FormGroup>
              </Col>
              <Col xs="12" sm="12" lg="4" md="4">
                <FormGroup>
                  <Input
                    type="text"
                    name="businessWebsiteUrl"
                    id="businessWebsiteUrl"
                    placeholder="WebsiteUrl *"
                    value={this.state.businessWebsiteUrl}
                    onChange={e =>
                      this.setState (
                        byPropKey ('businessWebsiteUrl', e.target.value)
                      )}
                    className="inputClass"
                  />
                  {isSecondSubmit &&
                    !businessWebsiteUrl &&
                    <p className="validationerrorMessage">
                      Business web site Url is required *
                    </p>}
                </FormGroup>
              </Col>
              <Col xs="12" sm="12" lg="4" md="4" md={{size: 4, offset: 2}}>
                <FormGroup>
                  <Input
                    type="text"
                    name="businessEmailAddress"
                    id="businessEmailAddress"
                    placeholder="Business Email Address *"
                    value={this.state.businessEmailAddress}
                    onChange={e =>
                      this.setState (
                        byPropKey ('businessEmailAddress', e.target.value)
                      )}
                    className="inputClass"
                  />
                  {isSecondSubmit &&
                    !businessEmailAddress &&
                    <p className="validationerrorMessage">
                      Business Email  is required *
                    </p>}
                  {isSecondSubmit &&
                    invalidEmailFormat &&
                    <p className="validationerrorMessage">
                      {' '}Invalid email format *
                    </p>}

                </FormGroup>
              </Col>
              <Col xs="12" sm="12" lg="4" md="4">
                <FormGroup className="businessFlagDiv">

                  {/*<img src={coutryflag} alt="Country flag" />
                  <Input
                  style={{width:70,color:'#FFFFFF'}}
                  type="select"
                  name="yearEstablished"
                  id="yearEstablished"
                  className="inputSelect"
                  value={this.state.countrycode}
                  onChange={e =>
                    this.setState (byPropKey ('countrycode', e.target.value))
                  }
                >
                  <option xs="12" lg="4" value="">
                  Country code *
                  </option>
                  <option xs="12" lg="4" value="+91">+91</option>
                  <option xs="12" lg="4" value="+92">+92</option>
                  <option xs="12" lg="4" value="+93">+93</option>

                </Input>*/}
                  {/*<ReactFlagsSelect
                defaultCountry="US" 
                blackList={true} 
                onSelect={this.onSelectFlag} 
                />*/}
                  <PhoneInput
                    defaultCountry="us"
                    autoFormat={true}
                    className="countryinputClass"
                    placeholder="Business Phone Number *"
                    value={this.state.businessPhoneNumber}
                    onChange={value => {
                      this.setState (byPropKey ('businessPhoneNumber', value));
                    }}
                  />
                  )

                  {/*<Input
                    type="text"
                    name="businessPhoneNumber"
                    id="businessPhoneNumber"
                    placeholder="Business Phone Number *"
                    value={this.state.businessPhoneNumber} 
                    onChange={e =>
                      this.setState (byPropKey ('businessPhoneNumber', e.target.value))
                    }
                    className="inputClass"
                  />*/}
                </FormGroup>
                {/*{(isSecondSubmit && !countrycode) &&<p className="validationerrorMessage">Country code  is required *</p>}*/}

                {isSecondSubmit &&
                  !businessPhoneNumber &&
                  <p className="validationerrorMessage">
                    Business phone number  is required *
                  </p>}

              </Col>

              <Col
                xs="12"
                sm="12"
                lg="4"
                md={{size: 4, offset: 4}}
                className="text-center"
              >
                <FormGroup>
                  <Button
                    // disabled={isvalid2}
                    onClick={() => this.onSubmitNext ()}
                    className="buttonClass"
                  >
                    Next
                  </Button>

                  <div
                    className="text-center linkRegiterClass"
                    onClick={() => {
                      this.setState ({
                        addNew: true,
                        secondStep: false,
                      });
                    }}
                  >
                    Go Back

                  </div>
                </FormGroup>
              </Col>
            </Row>}

          {!this.state.addNew &&
            !this.state.secondStep &&
            <div>
              {showingAlert &&
                <Alert color="danger" onLoad={this.timer}>
                  {error.message}
                </Alert>}
              <div className="text-center">
                <img src={logo} />
              </div>
              <Col className="pClass">
                <p>
                  Do you want to link your business?
                </p>
              </Col>
              <div className="">

                <div style={{textAlign: 'center'}}>
                  <Link to={routes.BASINESSDATABASE}>
                    <Button
                      /* disabled={isInvalid} */ type="submit"
                      className="findbusfbutton"
                    >
                      Find Business
                    </Button>
                  </Link>
                </div>
                <div style={{textAlign: 'center'}}>
                  <Button
                    style={{marginTop: 20}}
                    className="findbusfbutton"
                    onClick={() => {
                      this.setState ({
                        addNew: true,
                      });
                    }}
                  >
                    Register New Business
                  </Button>
                </div>

              </div>
              <div className="text-center" />
              <div className="">
                <p
                className="findbusaddlat"
                  // style={{
                  //   cursor: 'pointer',
                  //   fontFamily: 'Helvetica',
                  //   fontStyle: 'normal',
                  //   fontWeight: 'bold',
                  //   fontSize: 16,
                  //   textAlign: 'center',
                  //   color: '#4092B7',
                  // }}
                  onClick={() =>
                    this.props.history.push (routes.INFORMATIONSUBMIT)}
                >
                  {' '}
                  ADD LATER{' '}
                </p>
              </div>
            </div>}
        </div>
      </div>
    );
  }
}

//################### Sign Up Link ###################
//used in the sign in when the user don't have an account registered yet
const SignUpLink = () => (
  <p>
    Don't have an account yet? <Link to={routes.SIGN_UP}>Register here</Link>
  </p>
);

const mapStateToProps = state => {
  return {
    userinfo: state.Authreducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getFindBusiness,
      register,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (FindBusinessPage);

// <form onSubmit={this.onSubmit}>
//   <input
//     value={username}
//     onChange={e => this.setState(byPropKey("username", e.target.value))}
//     // onChange={e => this.onChange("username", e.target.value)}
//     type="text"
//     placeholder="Full Name"
//   />
//   <input
//     value={email}
//     onChange={e => this.setState(byPropKey("email", e.target.value))}
//     type="text"
//     placeholder="Email Address"
//   />
//   <input
//     value={passwordOne}
//     onChange={e =>
//       this.setState(byPropKey("passwordOne", e.target.value))
//     }
//     type="password"
//     placeholder="Password"
//   />
//   <input
//     value={passwordTwo}
//     onChange={e =>
//       this.setState(byPropKey("passwordTwo", e.target.value))
//     }
//     type="password"
//     placeholder="Confirm Password"
//   />
//   <button disabled={isInvalid} type="submit">
//     Sign Up
//   </button>

//   {error && <p>{error.message}</p>}
// </form>
