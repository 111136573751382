import React from 'react';
import PropTypes from 'prop-types';

import Contact from './Contact';
import './Contacts.css';

export default function Contacts({
    contacts = [],
    onContactClick = () => console.log('Contact clicked'),
    header = 'Contacts',
    headerRight,
    showEditOnContact = false,
    isAddIcon=true,
    bgColor='',
    gradientBg='',
    userOwnUid= ''

   
}) {
    return <div className={ 'contacts-container  '+ bgColor}>
        <div className="contacts-header">
            <div className="contact-header-content">{header}</div>
            {headerRight}
        </div>
        <div className={ 'contacts-body  '+ gradientBg}>
            {
                (contacts.length > 0) ? 
                <div className="contacts">
                    {contacts.map((obj) => <Contact
                        key={obj.docID}
                        imageSrc={obj.profilePictureURL}
                        name={userOwnUid == obj.docID ? 'You' :obj.displayName}
                        
                        profile={obj.position}
                        instituteName={obj.firmName}
                        userId={obj.docID}
                        onClick={()=> onContactClick(obj)}
                        showEditOnContact={showEditOnContact}
                        isAddIcon={isAddIcon}
                      
                    />)}
                </div>    
                :
                <div className="no-contacts">
                    There are no contacts to show.
                </div> 
            }
        </div>
    </div>
}

Contacts.propTypes = {
    contacts: PropTypes.array.isRequired,
    onContactClick: PropTypes.func.isRequired,
    header: PropTypes.string,
}