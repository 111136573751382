import React, { Component } from 'react';
// Dependencies
import { Link } from 'react-router-dom';
import { auth } from '../firebase';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// Components
import { Button, Row, Col, Alert } from 'reactstrap';
import { EncryptFunction } from '../utility/encryption';
// Actions
import {
  sendResetPassword,
  confirmResetPasswordOTP,
  resetResetPasswordReducer,
  updateResetPasswordOTP,
} from '../actions/Authaction';
// Icons
import logo from './logo.svg';
import lockset2 from './../images/lock.svg';
import emailIcon from './../images/icons/email.svg';
// Routes
import * as routes from '../constants/routes';

//it resets your password. It doesn’t matter if you are authenticated or not
// const PasswordForgetPage = () => (
//   <div className="div-flex gradientColorPurpleBlue">
//     <div style={{width: 470}}>
//       <Row>
//         <Col sm="12" md="12" lg="12" className="text-center">
//           <img src={logo} />
//         </Col>
//       </Row>

//       <PasswordForgetForm />
//     </div>
//   </div>
// );

//################### PasswordForget Form ###################

class PasswordForgetPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: null,
      showingAlert: false,
      step: 1,
      time: {},
      seconds: 0,
      otp: '',
      password: '',
      confirmPassword: '',
      confirmPasswordError: '',
      passwordCheck: false,
    };
    this.timer = 0;
    this.countDown = this.countDown.bind(this);
  }

  secondsToTime(secs) {
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
    };
    return obj;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.userinfo) {
        if (nextProps.userinfo.sendOtp) {
          this.setState(
            {
              step: 2,
              time: {},
              seconds: 59,
            },
            () => {
              this.startTimer();
            }
          );
        }
        if (nextProps.userinfo.confirmOtp) {
          this.setState({
            step: 3,
          });
        }
        if (nextProps.userinfo.resetPassword) {
          this.setState({
            step: 4,
          });
        }
      }
    }
  }
  componentDidMount() {
    let timeLeftVar = this.secondsToTime(this.state.seconds);

    this.setState({ time: timeLeftVar });

    this.props.resetResetPasswordReducer();
    this.startTimer();
  }
  startTimer() {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  }
  countDown() {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: this.secondsToTime(seconds),
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds == 0) {
      clearInterval(this.timer);
    }
  }

  onSubmit() {
    const { email } = this.state;

    let sendRequest = {
      data: {
        emailAddress: email,
      },
    };
    this.props.sendResetPassword(sendRequest);
  }

  verifyOTP() {
    const { email, otp } = this.state;

    let sendRequest = {
      data: {
        emailAddress: email,
        otp: otp,
      },
    };
    this.props.confirmResetPasswordOTP(sendRequest);
  }

  updatePassword() {
    const { email, otp, password, confirmPassword } = this.state;

    const patternpass = /^(?=.*[A-Z])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    const result = patternpass.test(password);

    if (!result) {
      this.setState({
        passwordCheck: true,
      });
      return;
    } else {
      // checkMobile = false;
      this.setState({
        passwordCheck: false,
      });
    }

    if (password !== confirmPassword) {
      this.setState({
        confirmPasswordError: 'Invalid Confirm password',
      });
      return;
    }

    let hashKeyCurrentPassword = EncryptFunction(email, password);
    let sendRequest = {
      data: {
        emailAddress: email,
        otp: otp,
        password: hashKeyCurrentPassword,
      },
    };
    this.props.updateResetPasswordOTP(sendRequest);
  }

  timer = () => {
    this.setState({
      showingAlert: true,
    });

    setTimeout(() => {
      this.setState({
        showingAlert: false,
      });
    }, 4000);
  };
  goForward = () => {
    this.setState({ step: this.state.step + 1 });
  };
  render() {
    const {
      email,
      error,
      showingAlert,
      step,
      otp,
      password,
      confirmPassword,
      confirmPasswordError,
      passwordCheck,
    } = this.state;

    return (
      <div className='div-flex gradientColorPurpleBlue' id='forgotpassPage1'>
        <div className='forgotPassBox'>
          <div className='text-center' id='forgotpassPage2'>
            <img src={logo} alt='logo' className='logo' id='forgotpassPage3' />
          </div>

          <div className='text-center forgetButtonDiv' id='forgotpassPage4'>
            {step === 1 && (
              <div className='relative' id='forgotpassPage5'>
                <div className='forgetTextDiv' id='forgotpassPage6'>
                  <p className='forgetcd ' id='forgotpassPage7'>
                    Forgot your password? Insert your E-mail below and we will
                    send you an OTP to reset your password.
                  </p>
                </div>
                <div className='forgotPassFieldTitle' id='forgotpassPage8'>
                  Email
                </div>
                <div style={{ marginBottom: '35px' }}>
                  <div className='emalinppu relative'>
                    <img
                      src={emailIcon}
                      alt='Email icon'
                      className='signUpinputIcons'
                      style={{ left: '100' }}
                      id='forgotpassPage9'
                    />
                    <input
                      type='text'
                      name='email'
                      // id="exampleEmail"
                      placeholder='Email'
                      value={email}
                      onChange={(event) =>
                        this.setState({ email: event.target.value })
                      }
                      id='forgotpassPage10'
                    />
                  </div>
                </div>

                <Button
                  type='submit'
                  className='forpanexutton '
                  disabled={!email}
                  onClick={() => this.onSubmit()}
                  data-rh='Next'
                  // onClick={() =>
                  //   this.setState ({
                  //     step: this.state.step + 1,
                  //   })}
                  id='forgotpassPage11'
                >
                  Next
                </Button>
              </div>
            )}
            {step === 2 && (
              <div>
                <div className='forgetTextDiv' id='forgotpassPage12'>
                  <p className='forgetcd ' id='forgotpassPage13'>
                    Forgot your password? Insert your E-mail below and we will
                    send you an OTP to reset your password.
                  </p>
                </div>
                <div className='forgotPassCountdown' id='forgotpassPage14'>
                  <div className='pwfordde' id='forgotpassPage15'>
                    Email
                  </div>
                  <div id='forgotpassPage16'>
                    <input
                      className='emailinpupwf'
                      placeholder='johndoe@gmail.com'
                      value={email}
                      readOnly
                      id='forgotpassPage17'
                    />
                  </div>
                  <div className='pwfordde' id='forgotpassPage18'>
                    Code
                  </div>
                  <div className={'emailinpupwf2-container'}>
                    <input
                      className='emailinpupwf2'
                      placeholder='Code'
                      value={otp}
                      onChange={(event) =>
                        this.setState({ otp: event.target.value })
                      }
                      id='forgotpassPage19'
                    />
                    <div className='otpellipse' id='forgotpassPage20'>
                      <span style={{ fontWeight: 'bold' }}>
                        {' '}
                        {this.state.time.s}
                      </span>{' '}
                      s
                    </div>
                  </div>
                  <div id='forgotpassPage21'>
                    {this.state.time.s === 0 && (
                      <Button
                        className='restpasbut'
                        onClick={() => {
                          this.timer = 0;
                          this.onSubmit();
                        }}
                        data-rh='Resend OTP'
                        id='forgotpassPage22'
                      >
                        Resend OTP
                      </Button>
                    )}
                    {this.state.time.s > 0 && (
                      <Button
                        className='restpasbut'
                        disabled={!otp}
                        onClick={() => this.verifyOTP()}
                        data-rh='Reset Password'
                        id='forgotpassPage23'
                      >
                        Reset Password
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )}
            {step === 3 && (
              <div className='forget-button-inner-div'>
                <div className='Enteryou' id='forgotpassPage24'>
                  Enter your new Password
                </div>
                <div className='pwfordde' id='forgotpassPage25'>
                  Password
                </div>
                <div className='textLeft' id='forgotpassPage26'>
                  <input
                    className='emailinpupwf'
                    type='password'
                    placeholder='Password'
                    value={password}
                    onChange={(event) =>
                      this.setState({ password: event.target.value })
                    }
                    id='forgotpassPage27'
                  />
                </div>
                <div className='pwfordde' id='forgotpassPage28'>
                  Repeat Password
                </div>
                <div className='textLeft' id='forgotpassPage29'>
                  <input
                    type='password'
                    className='emailinpupwf'
                    placeholder='Repeat Password'
                    value={confirmPassword}
                    onChange={(event) =>
                      this.setState({ confirmPassword: event.target.value })
                    }
                    id='forgotpassPage30'
                  />
                </div>
                {passwordCheck && (
                  <div>
                    <p className='Passwordmustcontain' id='forgotpassPage31'>
                      Password must contain a minimum of 8 characters to
                      <br />
                      include at least one number and capitalized letter.{' '}
                    </p>
                  </div>
                )}
                {confirmPasswordError && (
                  <p className='validationerrorMessage' id='forgotpassPage32'>
                    {' '}
                    {confirmPasswordError}{' '}
                  </p>
                )}
                <div className='textLeft' id='forgotpassPage33'>
                  <Button
                    className='restpasbut'
                    disabled={!password || !confirmPassword}
                    onClick={() => {
                      this.updatePassword();
                    }}
                    data-rh='Reset Password'
                    id='forgotpassPage34'
                  >
                    Reset Password
                  </Button>
                </div>
              </div>
            )}
            {step === 4 && (
              <div style={{ width: '100%' }}>
                <div id='forgotpassPage35'>
                  <img
                    alt='lock'
                    src={lockset2}
                    style={{ width: 78, height: 104 }}
                  />
                </div>
                <div className='Passwordsa' id='forgotpassPage36'>
                  Password Saved!
                </div>
                <div className='savedsucc' id='forgotpassPage37'>
                  {' '}
                  Your password is saved successfully.
                  <br />
                  Please proceed to Login.
                </div>

                <div>
                  <Link className='forgotClass' to={routes.SIGN_IN}>
                    <Button
                      className='restpasbut'
                      data-rh='Go to login'
                      id='forgotpassPage38'
                    >
                      Proceed to Login
                    </Button>
                  </Link>

                  <Button
                    onClick={() =>
                      (window.location.href = 'http://connect.radiologex.com/')
                    }
                    className='restpassStartMeeting'
                    data-rh='Start meeting'
                    id='forgotpassPage39'
                  >
                    Start a Meeting
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userinfo: state.Authreducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      sendResetPassword,
      confirmResetPasswordOTP,
      resetResetPasswordReducer,
      updateResetPasswordOTP,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForgetPage);
