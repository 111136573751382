import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Input } from 'reactstrap';
import * as routes from '../../../../constants/routes';
import { Carousel } from 'primereact/carousel';
import './sellerConsole.css';
import leftbackarrow from '../../../../images/icons/leftbackarrow.png';
import SellerConsoleSidemenu from '../sellerConsole/sellerSidemenu/sellerConsoleSidemenu';
import productDetails from '../../../../images/marketPlaceBuyer/productDetails.png';
import nonradioPlaceholder from '../../../../images/marketPlaceBuyer/nonradioPlaceholder.png';
import {
  getSellerOrder,
  getSellerInventory,
  orderSellerConfirmStatus,
  publishOrder,
  deleteProductOrder,
  cancelPlaceOrder,
  resetdoOrderValue,
  getSellerActiveOrder,
} from '../../../../actions/marketplaceaction';
import { dataTimeFormat } from '../../../../utility/convertTimeString';
import RightSideContainer from '../../../../components/UI/rightSideContainer/rightSideContainer';
import { priceWithDecimal } from '../../../../utility/commonFunctions';

const responsiveOptions = [
  {
    breakpoint: '1024px',
    numVisible: 4,
    numScroll: 4,
  },
  {
    breakpoint: '768px',
    numVisible: 3,
    numScroll: 3,
  },
  {
    breakpoint: '560px',
    numVisible: 3,
    numScroll: 3,
  },
];
class SellerConsole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sellerProductList: [],
      sellerOrderHistory: [],
      sellerActiveOrder: [],
    };
    this.myOrderTemplate = this.myOrderTemplate.bind(this);
    this.myProductTemplate = this.myProductTemplate.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.product && nextProps.product.sellerorders) {
        // console.log (nextProps.product.sellerorders);
        this.setState(
          {
            sellerProductList: nextProps.product.sellerorders,
            noDataFound: true,
          },
          () => {
            // console.log (this.state.sellerProductList);
          }
        );
      }
      if (nextProps.product && nextProps.product.sellerordershistory) {
        this.setState(
          {
            sellerOrderHistory: nextProps.product.sellerordershistory,
            noDataFound: true,
          },
          () => {
            // console.log (this.state.sellerOrderHistory);
          }
        );
      }

      if (nextProps.product && nextProps.product.sellerActiveOrders) {
        this.setState(
          {
            sellerActiveOrder: nextProps.product.sellerActiveOrders,
            noDataFound: true,
          },
          () => {}
        );
      }

      // if (nextProps.product && nextProps.product.confirmOrder) {
      //   this.setState ({
      //     selleConfirmmodal: false,
      //     orderDetail: '',
      //   });
      // }

      // if (nextProps.product && nextProps.product.publishorder) {
      //   this.setState ({
      //     selleConfirmmodal: false,
      //     publishConfirmmodal: false,
      //     publishSuccess: true,
      //     //  orderDetail:[]
      //   });
      // }

      // if (nextProps.product && nextProps.product.deleteproduct) {
      //   this.setState ({
      //     deleteConfirmmodal: false,
      //     deleteSuccessmodal: true,
      //   });
      // }

      // if (nextProps.product.cancelOrder) {
      //   if (this.state.cancelConfirmation) {
      //     this.setState ({
      //       orderCancelModal: true,
      //       cancelConfirmation: false,
      //     });
      //   }
      // }
    }
  }

  componentDidMount() {
    let sendInventoryRequest = {
      data: {
        docID: '',
      },
    };
    this.props.getSellerInventory(sendInventoryRequest);

    // let sendSellerOrderRequest = {
    //   data: {
    //     status: '',
    //   },
    // };

    // let sendSellerOrderRequest={
    //   "data":{
    //     "limit":10,
    //     "offset":0,
    //     // "orderType":"active"
    //     "orderType":"actionRequired"

    //   }}

    let sendSellerOrderRequest = {
      data: {
        orderType: 'completed',
      },
    };
    this.props.getSellerOrder(sendSellerOrderRequest);

    let activeSellerOrderRequest = {
      data: {
        orderType: 'active',
      },
    };

    this.props.getSellerActiveOrder(activeSellerOrderRequest);

    this.props.resetdoOrderValue();
  }

  myOrderTemplate(item) {
    return (
      <div
        onClick={() =>
          this.props.history.push(routes.SELLER_ORDER_DETAIL + `/${item.docID}`)
        }
      >
        <div
          className="p-grid p-nogutter SliderBox3"
          id={`sellerConsolePage${item.docID}`}
        >
          <div className="p-col-12" id={`sellerConsolePageCol${item.docID}`}>
            <img
              className="sliderProductImg"
              src={
                item.products[0] &&
                item.products[0] &&
                item.products[0].productCategoryType
                  ? item &&
                    item.products &&
                    item.products[0] &&
                    item.products[0].productImageList &&
                    item.products[0].productImageList[0]
                    ? item.products[0].productImageList[0]
                    : item.products[0].productCategoryType === 'Radiology'
                    ? productDetails
                    : nonradioPlaceholder
                  : productDetails
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  item &&
                  item.products &&
                  item.products[0] &&
                  item.products[0] &&
                  item.products[0].productCategoryType
                    ? item.products[0].productCategoryType === 'Radiology'
                      ? productDetails
                      : nonradioPlaceholder
                    : productDetails;
              }}
              alt="productImg"
              id={`sellerConsolePagesliderProductImg${item.docID}`}
            />
          </div>
          {
            <div
              style={{
                background: '#F5D028',
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
                display: 'flex',
                flexDirection: 'column',
                slignItems: 'center',
                padding: 10,
                height: 180,
              }}
            >
              {/*item.orderStatus=== "ordered" && <Button className="confirmOrderButton">
                <span />
                Confirm Order
          </Button>*/}

              {item.orderStatus === 'payment verified' && (
                <Button
                  className="confirmOrderButton"
                  id={`sellerConsolePageconfirmOrderButtonordered${item.docID}`}
                >
                  <span style={{ backgroundColor: '#4AEA87' }} />
                  Confirm Shipment
                </Button>
              )}
              {item.orderStatus === 'seller confirmed' && (
                <Button
                  className="confirmOrderButton"
                  id={`sellerConsolePageconfirmOrderButton${item.docID}`}
                >
                  <span style={{ backgroundColor: '#4AEA87' }} />
                  Order Confirm
                </Button>
              )}
              {item.orderStatus === 'paid' && (
                <Button
                  className="confirmOrderButton"
                  id={`sellerConsolePageconfirmOrderButtonpaid${item.docID}`}
                >
                  <span style={{ backgroundColor: '#4AEA87' }} />
                  Confirm Delivery
                </Button>
              )}

              {item.orderStatus === 'ordered' && (
                <Button
                  className="confirmOrderButton"
                  id={`sellerConsolePageconfirmOrderButtonordered${item.docID}`}
                >
                  <span style={{ backgroundColor: '#C4C4C4' }} />
                  Awaiting Confirmation
                </Button>
              )}

              {item.orderStatus === 'shipped' && (
                <Button
                  className="confirmOrderButton"
                  id={`sellerConsolePageconfirmOrderButtonshipped${item.docID}`}
                >
                  <span style={{ backgroundColor: '#4AEA87' }} />
                  Leave Feedback
                </Button>
              )}

              {item.orderStatus === 'returned' && (
                <Button
                  className="confirmOrderButton"
                  id={`sellerConsolePageconfirmOrderButtonshipped${item.docID}`}
                >
                  <span style={{ backgroundColor: '#4AEA87' }} />
                  Refund
                </Button>
              )}

              <p
                className="siemencscmarke"
                id={`sellerConsolePageconfirmOrderButtonProdName${item.docID}`}
              >
                {item && item.products && item.products[0] && item.products[0]
                  ? item &&
                    item.products[0].productCategoryType &&
                    item.products[0].productCategoryType === 'Radiology'
                    ? item.products[0].deviceSaleName
                    : item.products[0] && item.products[0].productName
                    ? item.products[0].productName
                    : 'N/A'
                  : 'N/A'}
              </p>
              <p
                className="siemencscmarke"
                id={`sellerConsolePageProdPrice${item.docID}`}
              >
                <span>
                  {' '}
                  USD${' '}
                  {item && item.totalOrderPrice
                    ? priceWithDecimal(item.totalOrderPrice)
                    : 0}
                </span>
              </p>

              <p
                className="DeviseleerceType"
                id={`sellerConsolePageProdDeviceTypeQuantity${item.docID}`}
              >
                Order ID : {item && item.orderID ? item.orderID : 'N/A'}
              </p>
              <p
                className="DeviseleerceType"
                id={`sellerConsolePageProdDeviceTypeQuantity${item.docID}`}
              >
                Quantity:{' '}
                {item && item.productQuantity ? item.productQuantity : 0}
              </p>
              {item &&
              item.products[0].productCategoryType &&
              item.products[0].productCategoryType === 'Radiology' ? (
                <div>
                  <p
                    className="DeviseleerceType"
                    id={`sellerConsolePageProdDeviceTypeItem${item.docID}`}
                  >
                    Device Type:{' '}
                    {item &&
                    item.products &&
                    item.products[0] &&
                    item.products[0].deviceType
                      ? item.products[0].deviceType
                      : 'N/A'}
                  </p>
                  <p
                    className="Locselllerornian"
                    id={`sellerConsolePageProdLocaltion${item.docID}`}
                  >
                    Location:{' '}
                    {item &&
                    item.products &&
                    item.products[0] &&
                    item.products[0].location
                      ? item.products[0].location
                      : 'N/A'}
                  </p>
                </div>
              ) : (
                <div>
                  <p
                    className="DeviseleerceType"
                    style={{ textTransform: 'capitalize' }}
                    id={`sellerConsolePageProdTypeItem${item.docID}`}
                  >
                    Product Type:{' '}
                    {item &&
                    item.products &&
                    item.products[0] &&
                    item.products[0].productCategoryType
                      ? item.products[0].productCategoryType
                      : ''}
                  </p>
                  <p
                    className="Locselllerornian"
                    id={`sellerConsolePageProdCat${item.docID}`}
                  >
                    Category:{' '}
                    {item &&
                    item.products &&
                    item.products[0] &&
                    item.products[0].category
                      ? item.products[0].category
                      : 'N/A'}
                  </p>
                </div>
              )}
            </div>
          }
        </div>
      </div>
    );
  }
  myProductTemplate(item) {
    return (
      <div className="" id={`sellerConsolePageMyProd${item.docID}`}>
        <div
          className="p-grid p-nogutter SliderBox3"
          onClick={() => {
            let productType = item.productCategoryType;
            // if(item.productCategoryType==='Radiology'){
            //   productType='Radiology';
            // }
            if (item.status === 'draft') {
              // console.log(routes.SELLER_ADD_PRODUCT +`/${productType}/${item.docID}`)

              this.props.history.push(
                routes.SELLER_ADD_PRODUCT + `/${productType}/${item.docID}`
              );
            } else {
              this.props.history.push(
                routes.SELLER_VIEW_LISTING + `/${item.docID}`
              );
            }
          }}
          id={`sellerConsolePageMyProdContainer${item.docID}`}
        >
          <div
            className="p-col-12"
            id={`sellerConsolePageMyProdinner${item.docID}`}
          >
            <img
              className="sliderProductImg"
              src={
                item && item.productCategoryType
                  ? item &&
                    item &&
                    item.productImageList &&
                    item.productImageList[0]
                    ? item.productImageList[0]
                    : item.productCategoryType === 'Radiology'
                    ? productDetails
                    : nonradioPlaceholder
                  : productDetails
              }
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  item && item.productCategoryType
                    ? item.productCategoryType === 'Radiology'
                      ? productDetails
                      : nonradioPlaceholder
                    : productDetails;
              }}
              alt="productImg"
              id={`sellerConsolePageMyProdsliderProductImg${item.docID}`}
            />
          </div>
          {
            <div
              className="p-col-12"
              style={{
                padding: 10,
                background: '#333333',
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
                height: 100,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
              }}
            >
              {item.status === 'draft' && (
                <Button
                  className="draftProductButton"
                  id={`sellerConsolePageMyProddraft${item.docID}`}
                >
                  Not for Sale: Draft
                </Button>
              )}

              <p
                className="siementomarke ellipsisProductName"
                id={`sellerConsolePageMyProdProductName${item.docID}`}
              >
                {item.productCategoryType === 'Radiology'
                  ? item.deviceSaleName
                  : item.productName
                  ? item.productName
                  : ''}
              </p>
              <p
                className="siementomarke"
                id={`sellerConsolePageMyProdProductPriceUsd${item.docID}`}
              >
                <span>
                  {' '}
                  USD${' '}
                  {item && item.priceUSD
                    ? priceWithDecimal(item.priceUSD)
                    : 'N/A'}
                </span>
              </p>
            </div>
          }
        </div>
      </div>
    );
  }

  render() {
    const {
      sellerProductList,
      sellerOrderHistory,
      sellerActiveOrder,
    } = this.state;
    return (
      <div // style={{
        //   background: 'linear-gradient(270deg, #4453D6 0%, #571B65 100%)',
        //   height: '100vh',
        //   display: 'inline-flex',
        // }}
        className="mainContainerSellerconsole"
        id="sellerConsolePage"
      >
        <div>
          <div style={{ textAlign: 'right' }} id="sellerConsolePage1">
            <Button
              className="markesellerbackbutton"
              onClick={() => this.props.history.goBack()}
              data-rh="Go back"
              id="sellerConsolePage2"
            >
              <div>
                <img src={leftbackarrow} alt="backIcon" />
              </div>
              Go <span> Back</span>
            </Button>
          </div>
          <div className="maindivsellerconsole" id="sellerConsolePage3">
            <div className="Markvetsellerpmylace" id="sellerConsolePage4">
              My Marketplace
            </div>
            <div className="Placedsellerfbh" id="sellerConsolePage5">
              Seller Console
            </div>
            <div className="marketplacedseelerovox" id="sellerConsolePage6">
              <div>
                <div className="placedoedcdrhesoihjcl" id="sellerConsolePage7">
                  <span> ACTIVE </span> ORDERS
                </div>
              </div>
              <div style={{ marginTop: 22 }} id="sellerConsolePage8">
                {sellerActiveOrder && sellerActiveOrder.length === 0 && (
                  <div className="noSellerData" id="sellerConsolePage9">
                    No Active Orders
                  </div>
                )}
                {sellerActiveOrder && sellerActiveOrder.length > 0 && (
                  <Carousel
                    value={sellerActiveOrder}
                    itemTemplate={this.myOrderTemplate}
                    responsiveOptions={responsiveOptions}
                    numVisible={4}
                    numScroll={4}
                    circular={true}
                  />
                )}
              </div>
            </div>
            <div className="mselllermyproduct" id="sellerConsolePage10">
              <div>
                <div className="myprodyctshmcdcd" id="sellerConsolePage11">
                  MY PRODUCTS
                </div>
              </div>
              <div style={{ marginTop: 22 }} id="sellerConsolePage12">
                {sellerProductList && sellerProductList.length === 0 && (
                  <div
                    className="noSellerData"
                    style={{ color: '#000000' }}
                    id="sellerConsolePage13"
                  >
                    {' '}
                    My Products Not Found
                  </div>
                )}

                {sellerProductList && sellerProductList.length > 0 && (
                  <Carousel
                    value={sellerProductList}
                    itemTemplate={this.myProductTemplate}
                    responsiveOptions={responsiveOptions}
                    numVisible={4}
                    numScroll={4}
                    circular={true}
                  />
                )}
              </div>
            </div>
            <div className="sellerorderhistoryBox" id="sellerConsolePage14">
              <div
                className="sellerOrderHistoryTitleBox"
                id="sellerConsolePage15"
              >
                ORDER HISTORY
              </div>
              {sellerOrderHistory && sellerOrderHistory.length === 0 && (
                <div
                  className="noSellerData"
                  style={{ marginTop: 20 }}
                  id="sellerConsolePage16"
                >
                  {' '}
                  No Order History
                </div>
              )}
              <div style={{ display: 'inline-grid' }} id="sellerConsolePage17">
                {sellerOrderHistory &&
                  sellerOrderHistory.length > 0 &&
                  sellerOrderHistory.map((item, i) => (
                    <div
                      key={i}
                      id={`sellerConsolePagebuyerOrderHistoryList${i}`}
                      className="buyerOrderHistoryList"
                      onClick={() =>
                        this.props.history.push(
                          routes.SELLER_ORDER_DETAIL + `/${item.docID}`
                        )
                      }
                    >
                      <div
                        style={{ display: 'inline-flex' }}
                        id={`sellerConsolePagebuyerOrderHistoryListIneer${i}`}
                      >
                        <div
                          className="orderDate"
                          id={`sellerConsolePagebuyerOrderHistoryOrderDate${i}`}
                        >
                          {item && item.orderDate
                            ? dataTimeFormat(item.orderDate)
                            : 'N/A'}
                        </div>
                        <div
                          className="orderID"
                          id={`sellerConsolePagebuyerOrderHistoryListORderid${i}`}
                        >
                          Order # {item && item.orderID ? item.orderID : 'N/A'}
                        </div>
                        <div
                          className="orderName"
                          id={`sellerConsolePagebuyerOrderHistoryListOrdername${i}`}
                        >
                          {item &&
                          item.products &&
                          item.products[0] &&
                          item.products[0].productCategoryType &&
                          item.products[0].productCategoryType === 'Radiology'
                            ? item.products[0].deviceSaleName
                            : item &&
                              item.products &&
                              item.products[0] &&
                              item.products[0].productName
                            ? item.products[0].productName
                            : 'N/A'}
                        </div>
                      </div>
                      <div style={{ display: 'inline-flex' }}>
                        <div
                          className="orderPrice"
                          id={`sellerConsolePagebuyerOrderHistoryListOrderQty${i}`}
                        >
                          Qty:{' '}
                          {item && item.productQuantity
                            ? item.productQuantity
                            : 0}
                        </div>

                        <div
                          className="orderPrice"
                          id={`sellerConsolePagebuyerOrderHistoryListOrderPrice${i}`}
                        >
                          USD${' '}
                          {item && item.totalOrderPrice
                            ? priceWithDecimal(item.totalOrderPrice)
                            : 0}
                        </div>
                        <div
                          className="orderStatus"
                          id={`sellerConsolePagebuyerOrderHistoryListOrderStatus${i}`}
                        >
                          {item &&
                            item.orderStatus &&
                            (item.orderStatus === 'canceled' ||
                              item.orderStatus === 'refunded') && (
                              <div
                                id={`sellerConsolePagebuyerOrderHistoryListOrderStatuscanceled${i}`}
                                style={{ background: '#FD4B4B' }}
                              />
                            )}
                          {item &&
                            item.orderStatus &&
                            (item.orderStatus === 'completed' ||
                              item.orderStatus === 'delivered') && (
                              <div
                                style={{ background: '#C4C4C4' }}
                                id={`sellerConsolePagebuyerOrderHistoryListOrderStatuscompleted${i}`}
                              />
                            )}
                          {item &&
                          item.orderStatus &&
                          (item.orderStatus === 'completed' ||
                            item.orderStatus === 'delivered')
                            ? 'Completed'
                            : 'Cancelled'}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <RightSideContainer>
          <SellerConsoleSidemenu />
        </RightSideContainer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    product: state.MarketplaceReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSellerOrder,
      getSellerInventory,
      resetdoOrderValue,
      getSellerActiveOrder,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SellerConsole);
