const chinaStates = [
  {name: 'Anhui', code: 'AH'},
  {name: 'Beijing', code: 'BJ'},
  {name: 'Chongqing', code: 'CQ'},
  {name: 'Fujian', code: 'FJ'},
  {name: 'Gansu', code: 'GS'},
  {name: 'Guangdong', code: 'GD'},
  {name: 'Guangxi', code: 'GX'},
  {name: 'Guizhou', code: 'GZ'},
  {name: 'Hainan', code: 'HI'},
  {name: 'Hebei', code: 'HE'},
  {name: 'Heilongjiang', code: 'HL'},
  {name: 'Henan', code: 'HA'},
  {name: 'Hong Kong', code: 'HK'},
  {name: 'Hubei', code: 'HB'},
  {name: 'Hunan', code: 'HN'},
  {name: 'Inner Mongolia', code: 'NM'},
  {name: 'Jiangsu', code: 'JS'},
  {name: 'Jiangxi', code: 'JX '},
  {name: 'Jilin', code: 'JL'},
  {name: 'Liaoning', code: 'LN'},
  {name: 'Macau', code: 'MC'},
  {name: 'Ningxia', code: 'NX'},
  {name: 'Qinghai', code: 'QH'},
  {name: 'Shaanxi', code: 'SN'},
  {name: 'Shandong', code: 'SD'},
  {name: 'Shanghai', code: 'SH'},
  {name: 'Shanxi', code: 'SX'},
  {name: 'Sichuan', code: 'SC'},
  {name: 'Taiwan', code: 'TW'},
  {name: 'Tianjin', code: 'TJ'},
  {name: 'Tibet', code: 'XZ'},
  {name: 'Xinjiang', code: 'XJ'},
  {name: 'Yunnan', code: 'YN'},
  {name: 'Zhejiang', code: 'ZJ'},
];

export default chinaStates;
