import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
// import { SignInLink } from "./SignIn";
import leftbackarrow from '../../images/icons/leftbackarrow.png';
import {Button, Input} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import accsetimg from '../../images/accountSetting/accsetimg.png';
import authsetimg from '../../images/accountSetting/authsetimg.png';
import notisetimg from '../../images/accountSetting/notisetimg.png';
import searsetimg from '../../images/accountSetting/searsetimg.png';
import walsetimg from '../../images/accountSetting/walsetimg.png';
import contsetimg from '../../images/accountSetting/contsetimg.png';
import contactimgWhite from '../../images/accountSetting/contactimgWhite.png';

import logoutimg from '../../images/accountSetting/logoutimg.png';
import appabset from '../../images/appabset.png';
import appaboutBlack from '../../images/accountSetting/appaboutBlack.png';

import accsetrimg2 from '../../images/accountSetting/accsetrimg2.png';
import authsetimg2 from '../../images/accountSetting/authsetimg2.png';
import wallsetimg2 from '../../images/accountSetting/wallsetimg2.png';
import UserAccountSetting from './userAccountSetting/userAccountSetting';
import AuthSetting from './authenticationSetting/authenticationSetting';
import notisetimg2 from '../../images/accountSetting/notisetimg2.png';
import searcsetimg2 from '../../images/accountSetting/searcsetimg2.png';
import NotificationSetting from './notificationSetting/notificationSetting';
import SearchSetting from './searchSetting/searchSetting';
import AboutAndAppVersion from './aboutAndAppVersion/aboutAndAppVersion';
import WalletSetting from './walletSettings/walletSettings';
import ContactSupport from './contactSupport/contactSupport';
import './accountSettings.css';
import {signOut} from '../../actions/Authaction';

import * as routes from '../../constants/routes';
import {checkAvailableAccess} from '../../constants/localMessage/errorMessage';
import InfoSyscoming from '../../images/comingsoon/InfoSyscoming.svg';
import  CommonAccessContainer from './../../components/UI/commonAccessContainer/commonAccessContainer';

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class AccountSettings extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      detailDiv: true,
      editDetailDiv: false,
      authenticationSetting: false,
      changePassDiv: false,
      openMenu: '',
      title: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      aboutMe: '',
      registeredEmail: '',
      address: '',
      password: '',
      repeatPassword: '',
      isSubmit: false,
      isAccess: true
    };
  }

  componentDidMount () {
    if(checkAvailableAccess ('settings') && checkAvailableAccess ('settings')==='settings'){
      this.openDetail ('accountSetting')
    }else{
      this.setState({
        isAccess: false
      })
    }
  }

  openAccountSetting = () => {
    this.setState ({
      detailDiv: true,
      editDetailDiv: false,
      authenticationSetting: false,
    });
  };
  openAuthenticationSetting = () => {
    this.setState ({
      authenticationSetting: true,
      detailDiv: false,
      editDetailDiv: false,
    });
  };

  openDetail (status) {
    this.setState ({
      openMenu: status,
    });
  }

  render () {
    const {openMenu, isAccess} = this.state;
    return (
      <div className="rootmainsscettingdivs">
        {isAccess &&
          <div className="mainsscettingdivs">
            <div style={{marginLeft: 64}}>
              <div className="accccSETTINGS">
                SETTINGS
              </div>
              <div>
                <Button
                  className={
                    openMenu === 'accountSetting'
                      ? 'accsettingsbutton'
                      : 'accsettingsbutton2'
                  }
                  onClick={() => this.openDetail ('accountSetting')}
                  data-rh="Account setting"
                >
                  <img
                    alt=""
                    src={
                      openMenu === 'accountSetting' ? accsetimg : accsetrimg2
                    }
                  />
                  Account Settings
                </Button>
              </div>
              <div>
                <Button
                  className={
                    openMenu === 'authSetting'
                      ? 'authsebutton2'
                      : 'authsebutton'
                  }
                  onClick={() => this.openDetail ('authSetting')}
                  data-rh="Authentication setting"
                >
                  <img
                    alt=""
                    src={openMenu === 'authSetting' ? authsetimg2 : authsetimg}
                  />
                  Authentication Settings
                </Button>
              </div>
              <div>
                <Button
                  className={
                    openMenu === 'notificationSetting'
                      ? 'notisetbton2'
                      : 'notisetbutton'
                  }
                  data-rh="Notification setting"
                  onClick={() => this.openDetail ('notificationSetting')}
                >
                  <img
                    alt=""
                    src={
                      openMenu === 'notificationSetting'
                        ? notisetimg2
                        : notisetimg
                    }
                  />
                  Notification Settings
                </Button>
              </div>
              <div>
                <Button
                  className={
                    openMenu === 'searchSetting'
                      ? 'searbutton2'
                      : 'searcsetbutton'
                  }
                  onClick={() => this.openDetail ('searchSetting')}
                  data-rh="Search setting"
                >
                  <img
                    alt=""
                    src={
                      openMenu === 'searchSetting' ? searcsetimg2 : searsetimg
                    }
                  />
                  Search Settings
                </Button>
              </div>
              <div>
                <Button
                  className={
                    openMenu === 'walletSetting'
                      ? 'walactivetbutton'
                      : 'wallsetbutton'
                  }
                  data-rh="Wallet setting"
                  onClick={() => {
                    let checkWallet = localStorage.getItem ('address');
                    if (checkWallet) {
                      this.openDetail ('walletSetting');
                    } else {
                      this.props.history.push (routes.PAYMENT);
                    }
                  }}
                >
                  <img
                    alt=""
                    src={openMenu === 'walletSetting' ? wallsetimg2 : walsetimg}
                  />
                  Wallet Settings
                </Button>
              </div>
              <div>
                <Button
                  className={
                    openMenu === 'aboutAndAppVersion'
                      ? 'apabutton2'
                      : 'apabsetbutton'
                  }
                  data-rh="About and App Version"
                  onClick={() => this.openDetail ('aboutAndAppVersion')}
                >

                  <img
                    alt=""
                    src={
                      openMenu === 'aboutAndAppVersion'
                        ? appaboutBlack
                        : appabset
                    }
                  />

                  About and App Version
                </Button>
              </div>
              <div>
                <Button
                  className="contsetbutton"
                  className={
                    openMenu === 'contact_support'
                      ? 'apabutton2'
                      : 'apabsetbutton'
                  }
                  onClick={() => this.openDetail ('contact_support')}
                  data-rh="Contact Support"
                >
                  {openMenu === 'contact_support'
                    ? <img alt="" src={contsetimg} width={30} />
                    : <img alt="" src={contactimgWhite} width={25} />}

                  Contact Support
                </Button>
              </div>
              <div>
                <Button
                  className="logsetbutton"
                  onClick={() => {
                    this.props.signOut ();
                  }}
                  data-rh="Logout"
                >
                  <img alt="" src={logoutimg} />
                  Logout
                </Button>
              </div>

            </div>
            <div className="scrollSettingContent">
              {openMenu === 'accountSetting' && <UserAccountSetting />}
              {openMenu === 'authSetting' && <AuthSetting />}
              {openMenu === 'notificationSetting' && <NotificationSetting />}
              {openMenu === 'searchSetting' && <SearchSetting />}
              {openMenu === 'aboutAndAppVersion' && <AboutAndAppVersion />}
              {openMenu === 'walletSetting' && <WalletSetting />}
              {openMenu === 'contact_support' && <ContactSupport />}

            </div>
            {openMenu &&
              <div style={{textAlign: 'right'}}>
                <Button
                  className="accountbackbutton"
                  onClick={() => this.props.history.goBack ()}
                >
                  <div>
                    <img src={leftbackarrow} alt="backIcon" />
                  </div>

                  Go <span> Back</span>
                </Button>
              </div>}
          </div>}

          {!isAccess  && <CommonAccessContainer bgImage={InfoSyscoming} message={checkAvailableAccess('settings')} />}

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    // contacts: state.ContactsReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      signOut,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (AccountSettings);
