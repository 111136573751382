import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from 'reactstrap';
// import Select from 'react-select';
import logo from '../logo.svg';
import * as routes from '../../constants/routes';
import {connect} from 'react-redux';
import {ModifyPersonalInfo,register} from '../../actions/Authaction';
import {bindActionCreators} from 'redux';
import countryList from 'react-select-country-list';

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class BusinessContactPage extends Component {
  constructor (props) {
    super (props);
    this.options = countryList ().getData ();
 
    this.state = {
      address1: '',
      address2: '',
      country: '',
      state: '',
      city: '',
      businessdocId:'',
      docID:'',
      postalCode: '',
      error: null,
      showingAlert: false,
      options: this.options,
      isSubmit: false,
      businessInfo:[]
    };
    this.onSubmit = this.onSubmit.bind (this);
  }

  componentWillReceiveProps (nextProps) {
   

    if (nextProps && nextProps.userinfo) {
      if (
        nextProps.userinfo.registeruser &&
        nextProps.userinfo.registeruser.result
      ) {
        if (nextProps.userinfo.registeruser.result.status) {
          // console.log (nextProps.userinfo.registeruser.result);
          if(nextProps.userinfo.registeruser.result.Data.length>2 && nextProps.userinfo.registeruser.result.Data[2] && nextProps.userinfo.registeruser.result.Data[2].contactDetails && nextProps.userinfo.registeruser.result.Data[2].contactDetails.businessEmailAddress ){
            this.props.history.push (routes.INFORMATIONSUBMIT);

          }
         
        }
      }
    }
  }
  componentDidMount(){

  }

  onSubmit = event => {
    event.preventDefault ();
    this.setState (
      {
        isSubmit: true,
      },
      () => {
        const {
          perfectSearch,
          address1,
          address2,
          country,
          state,
          city,
          postalCode,
          businessdocId,
          docID
        } = this.state;

        const isInvalid =
          country === '' ||
          address1 === '' ||
          state === '' ||
          city === '' ||
          postalCode === '';

      
        if (isInvalid) {
          return false;
        }

        const {history} = this.props;

       
      //  let userInfo =  JSON.parse(localStorage.getItem("RegisterData"));
      //  console.log(userInfo);

        //creating a user in the database after the sign up through Firebase auth API
      

        const sendRequest = JSON.parse(localStorage.getItem("registerBusiness"));
        sendRequest.data.businessAddress_1= address1;
        sendRequest.data.businessAddress_2= address2;
        sendRequest.data.businessCity= city;
        sendRequest.data.businessCountry= country;
        sendRequest.data.businessState= state;
        // sendRequest.data.businessID= this.props.userinfo.registeruser.result.Data[0].documentID;
        sendRequest.data.docID= this.props.userinfo.registeruser.result.Data[0].documentID;

        // const sendRequest = {
        //   data: {
        //     businessAddress_1: address1,
        //     businessAddress_2: address2,
        //     businessCity: city,
        //     businessCountry: country,
        //     businessPostalCode: postalCode,
        //     businessState: state,
        //     businessID: this.state.businessInfo[0].documentID,
        //     registeredBusinessName:this.state.businessInfo[1].companyDetails.displayedBusinessName,
        //     docID:docID,

        //   },
        // };
        console.log (sendRequest);

        this.props.register (sendRequest);
        
        // this.props.history.push (routes.INFORMATIONSUBMIT);

        // localStorage.setItem (
        //   'newUserInfoPersonal',
        //   JSON.stringify (sendRequest.data)
        // );


        // this.props.ModifyPersonalInfo (sendRequest);
        //       });
        //     }
        //   });
        // })
        // .catch (err => {
        //   console.error ('error!!', err.message);
        //   this.setState (byPropKey ('error', err));
        //   createNotification ('error', err.message);
        //   this.timer (); //show alert message for some seconds
        // });
      }
    );
  };

  timer = () => {
    this.setState ({
      showingAlert: true,
    });

    setTimeout (() => {
      this.setState ({
        showingAlert: false,
      });
    }, 4000);
  };

  render () {
    const {
      error,
      showingAlert,
      address1,
      address2,
      country,
      state,
      city,
      postalCode,
      isSubmit,
    } = this.state;

    return (
      <div className="div-flex-signup">
        <Row>
          {showingAlert &&
            <Alert color="danger" onLoad={this.timer}>
              {error.message}
            </Alert>}
          <Col sm="12" md="12" lg="12" className="text-center">
            <img src={logo} />
          </Col>
          <Col xs="12" sm="12" md="12" lg="12" className="pClass">
            <p>
              Insert your Business Address Details
            </p>
          </Col>
          <Col xs="12" sm="12" md="4" lg="4" md={{size: 4, offset: 2}}>
            <FormGroup className="formInputField f_right">
              <Input
                type="text"
                name="address1"
                id="exampleaddress1"
                placeholder="Registered Address Line 1*"
                value={address1}
                onChange={e =>
                  this.setState (byPropKey ('address1', e.target.value))}
              />
              {!address1 &&
                isSubmit &&
                <p className="validationerrorMessage">
                  Address Line 1 is required *{' '}
                </p>}

            </FormGroup>
          </Col>
          <Col xs="6" sm="6" md="4" lg="4">
            <FormGroup className="formInputField ">

              <Input
                type="text"
                name="address2"
                id="exampleAddress2"
                placeholder="Registered Address Line 2"
                value={address2}
                onChange={e =>
                  this.setState (byPropKey ('address2', e.target.value))}
              />
            </FormGroup>
          </Col>
          <Col xs="6" sm="6" md="4" lg="4" md={{size: 4, offset: 2}}>
            <FormGroup className="formInputField f_right">
              <Input
                type="select"
                name="country"
                id="select"
                className="inputSelect"
                value={country}
                onChange={e =>
                  this.setState (byPropKey ('country', e.target.value))}
              >
                <option xs="12" lg="4" value="">
                  Country of Establishment*
                </option>
                {this.state.options.map (item => (
                  <option xs="12" lg="4" value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Input>
              {!country &&
                isSubmit &&
                <p className="validationerrorMessage">
                  Country is required *{' '}
                </p>}

            </FormGroup>
          </Col>
          <Col xs="6" sm="6" md="4" lg="4">
            <FormGroup className="formInputField ">
              <Input
                type="text"
                name="city"
                id="city"
                placeholder="City*"
                value={city}
                onChange={e =>
                  this.setState (byPropKey ('city', e.target.value))}
              />
              {!city &&
                isSubmit &&
                <p className="validationerrorMessage">City is required * </p>}

            </FormGroup>
          </Col>
          <Col xs="6" sm="6" md="4" lg="4" md={{size: 4, offset: 2}}>
            <FormGroup className="formInputField f_right">
              <Input
                type="text"
                name="state"
                id="exampleState"
                placeholder="State*"
                value={state}
                onChange={e =>
                  this.setState (byPropKey ('state', e.target.value))}
              />
              {!state &&
                isSubmit &&
                <p className="validationerrorMessage">State is required * </p>}

            </FormGroup>
          </Col>
          <Col xs="6" sm="6" md="4" lg="4">
            <FormGroup className="formInputField ">
              <Input
                type="text"
                name="postalCode"
                id="examplepostalCode"
                placeholder="Postal Code*"
                value={postalCode}
                onChange={e =>
                  this.setState (byPropKey ('postalCode', e.target.value))}
              />
              {!postalCode &&
                isSubmit &&
                <p className="validationerrorMessage">
                  PostalCode is required *{' '}
                </p>}

            </FormGroup>
          </Col>
          <Col
            xs="12"
            sm="12"
            lg="4"
            md={{size: 4, offset: 4}}
            className="text-center"
          >
            <FormGroup>
              <Button
                // disabled={isInvalid}
                onClick={this.onSubmit}
                type="submit"
                className="buttonClass"
              >
                Next
              </Button>

              {/*<div className="text-center linkRegiterClass">
                <Link to={routes.SIGN_UP}>Go Back</Link>

              </div>*/}
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userinfo: state.Authreducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      register,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  BusinessContactPage
);
