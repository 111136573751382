import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {Button, Modal,ModalBody} from 'reactstrap';

import leftbackarrow from '../../../images/icons/leftbackarrow.png';
import ProfileIcon from '../../../images/icons/placeholderProfile.png';
import topupAdminIcon from '../../../images/icons/pay/topupAdmin.png';

import PaymentDashBoard from '../dashboard/index';
import {
  getTransactionHistory,
  resetTransactionList
} from '../../../actions/Paymentaction';
import {dataTimeFormat ,dataFormat ,payTimeFormat} from '../../../utility/convertTimeString';
import  RightSideContainer from '../../../components/UI/rightSideContainer/rightSideContainer';
import { ShowNameAccordingToLength } from '../../../utility/stringFunctions';
import {priceWithDecimal} from '../../../utility/commonFunctions';


import './transactionHistory.css';

class TransactionHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateFilter: 'Filter By Date',
      typeFilter: 'Transaction Type',
      dateDropDopwn: [
        { label: 'FilterByDate', value: 'Filter By Date' },
        { label: 'Today', value: 'Today' },
        { label: 'Yesterday', value: 'Yesterday' },
        { label: 'This Week', value: 'This Week' },
        { label: 'This Month', value: 'This Month' },
        { label: 'Last Month', value: 'Last Month' },
        { label: 'This Year', value: 'This Year' },
      ],
      typeDropDopwn: [
        { label: 'Transaction Type', value: 'Transaction Type' },
        { label: 'Sent', value: 'Sent' },
        { label: 'Recevied', value: 'Recevied' },
        { label: 'TopUp', value: 'TopUp' },
      ],
      searching: '',
      transactionHistory:[],
      showDetail:false,
      showUserInfo:[],
      startPage: 10,

    };
  }

  UNSAFE_componentWillReceiveProps(nextProps){
    if(nextProps && nextProps.payment){
      // console.log(nextProps.payment);
      // console.log(nextProps.payment.history.transaction_history);
      if(nextProps.payment.history && nextProps.payment.history.transaction_history){
        let tempData = this.state.transactionHistory.concat (
          nextProps.payment.history.transaction_history
        );

        this.setState (
          {
            // transactionHistory:tempData
            transactionHistory: this.sortByDate(this.removeDuplicates (
              tempData,
              'transaction_id'
            )),
          },
          () => {
          }
        ); 
      }
    }

  }


  removeDuplicates (originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push (lookupObject[i]);
    }
    return newArray;
  }

  sortByDate(lising){

    lising.sort(function(a,b){
      return Number(new Date(b.transaction_date)) - Number(new Date(a.transaction_date));
    });
    return lising;
  }

  loadMore () {
    this.setState (
      {
        startPage: this.state.startPage + Number (10),
      },
      () => {
        let sendRequest = {
          data: {
            // limit: 10,
            // offset:  1,
            // txid:''
            // txid: '',
            limit: this.state.startPage,
            // offset:  this.state.startPage - Number (this.state.startPage-1),
            offset: this.state.startPage === 10
              ? this.state.startPage - Number (10)
              : this.state.startPage - Number (9),
          },
        };
        this.props.getTransactionHistory (sendRequest);
      }
    );
  }
  manualRefresh(){
    this.props.resetTransactionList();
    this.setState({
      startPage: 0,
      transactionHistory:[]
    },()=>{
      this.loadMore();
    })

  }

  openDetail(item){
    this.setState({
      showDetail:true,
      showUserInfo:item
    })
  }


  render() {
    const { dateFilter, typeFilter,transactionHistory,showUserInfo } = this.state;
    return (
      <div style={{ display: "flex" }} id="settleDash">
        <div className="stepGoBackDiv" id="settleDash1">
          <div style={{textAlign:'right'}} id="settleDash2">
            <Button id="settleDash3" className="historyGoback" onClick={()=>this.props.history.goBack()} data-rh="Go back">
              <div>
                <img src={leftbackarrow} alt="backIcon" id="settleDash4"/>
              </div>
              Go <span> Back</span>
            </Button>
          </div>
          <div className="historyHistorymaindiv" id="settleDash5">
            {/*<div className="pendingreqdiv">
              <img alt="" src={pendingclose} className="pendingcloseicon" />
              <div className="pendingreqheading">
                PENDING REQUESTS
              </div>
              <div style={{ display: "flex" }}>
                <div className="payrequestdiv">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="circlered" />
                      <div className="notidot">
                        Request Received
                      </div>
                    </div>
                    <div>
                      <img alt="" src={profile} style={{ height: 40, width: 40, marginLeft: 10, marginTop: 10 }} />
                    </div>
                  </div>
                  <div className="notidotName">
                    Alfired Herman
                  </div>
                  <div className="notidotDate">
                    <span>02/03/2020 4:12PM</span>
                  </div>
                  <div className='amountPendingRequestRed notidot'>
                    <span>-USD$ 1,000,00</span>
                  </div>
                </div>

                <div className="payrequestdiv">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="circlepositive" />
                      <div className="notidot">
                        Request Received
                      </div>
                    </div>
                    <div>
                      <img alt="" src={profile} style={{ height: 40, width: 40, marginLeft: 10, marginTop: 10 }} />
                    </div>
                  </div>
                  <div className="notidotName">
                    Alfired Herman
                  </div>
                  <div className="notidotDate">
                    <span>02/03/2020 4:12PM</span>
                  </div>
                  <div className='amountPendingRequestPositive notidot'>
                    <span>+USD$ 231,000,00</span>
                  </div>
                </div>

                <div className="payrequestdiv">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="circlered" />
                      <div className="notidot">
                        Request Received
                      </div>
                    </div>
                    <div>
                      <img alt="" src={profile} style={{ height: 40, width: 40, marginLeft: 10, marginTop: 10 }} />
                    </div>
                  </div>
                  <div className="notidotName">
                    Alfired Herman
                  </div>
                  <div className="notidotDate">
                    <span>02/03/2020 4:12PM</span>
                  </div>
                  <div className='amountPendingRequestRed notidot'>
                    <span>-USD$ 1,000,00</span>
                  </div>
                </div>

                <div className="payrequestdiv">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="circlepositive" />
                      <div className="notidot">
                        Request Received
                      </div>
                    </div>
                    <div>
                      <img alt="" src={profile} style={{ height: 40, width: 40, marginLeft: 10, marginTop: 10 }} />
                    </div>
                  </div>
                  <div className="notidotName">
                    Alfired Herman
                  </div>
                  <div className="notidotDate">
                    <span>02/03/2020 4:12PM</span>
                  </div>
                  <div className='amountPendingRequestPositive notidot'>
                    <span>+USD$ 231,000,00</span>
                  </div>
                </div>
              </div>
              <div className="viewAllRequest">
                VIEW ALL REQUESTS
              </div>

    </div>*/}

            <div className="historyContainer" id="settleDash6">
              <div className='transactionHistoryMainDiv' id="settleDash7">
              <Button id="settleDash8" className="refreshButton" onClick={()=>this.manualRefresh()} data-rh="Refresh">
               Refresh
              </Button>
                <div className='headerDiv' style={{marginBottom:15}} id="settleDash9">
                  <div className='transactionHistoryTextDiv' id="settleDash10">
                    <p className='transactionHistoryText' id="settleDash11">Transaction History</p>

                  </div>

                 
                   {/* <div className='transactionFiltersDiv transactionsearchDiv'>
                <div>
                    <div >
                      <Dropdown
                        className="historyFilters"
                        value={dateFilter}
                        options={this.state.dateDropDopwn}
                        onChange={e => this.setState({ dateFilter: e.value })}
                      />
                      <Dropdown
                        className="historyFilters"
                        value={typeFilter}
                        options={this.state.typeDropDopwn}
                        onChange={e => this.setState({ typeFilter: e.value })}
                      />
                    </div>

                    <div className='historySearch '>
                      <img
                        src={searchIcon}
                        alt="Search"
                        className="searchContactIcon"
                      />
                      <input
                        type="text"
                        className="searchInput"
                        placeholder="Search"
                        value={this.state.searching}
                        onChange={e => this.setState({ searching: e.target.value })}
                      />
                    </div>
                  </div>
                  {this.state.searching && <div className='transactionsearchDiv'>
                    <div className="mLeft10">
                      Search result for "{this.state.searching}"
                </div>

                    <div className=' textRight'>
                      <div className='clearSearch'
                        onClick={() =>
                          this.setState({
                            searching: '',
                          })}>
                        <span>Clear Search  <img src={closeIcon} alt="backIcon" style={{ width: 30 }} /></span>
                      </div>

                    </div>
                  </div>}
                  <div className='transactionDotBoldDiv'>
                  </div>
                        </div>*/}

                </div>
                <div className="historyResultDiv" id="settleDash12">
                  {transactionHistory.length===0 && <div className="textCenter" id="settleDash13">
                    <p className="noTransactionYetText">No Transaction yet</p>
                  </div>}

                  {transactionHistory && transactionHistory.map ((item, index) => (<div key={index}>
                    <div className="transactionHistoryWrapper" id={`settleDashhistory${index}`}>
                      <div className="displayFlex nameTransactionHistoryDiv mLeft10">
                        
                        <div >
                        <img id={`settleDashhistoryuserimg${index}`} className="transactionUserImg" src={ item.type==="topup" || item.type==='withdrawal'? topupAdminIcon : item.profilePictureURL?item.profilePictureURL:ProfileIcon}    onError={e => {
                          e.target.onerror = null;
                          e.target.src = ProfileIcon;
                        }} alt="settleDashhistoryuserimg"/>
                        </div>
                        
                        <div>
                          <p className='nameTransactionHistory' id={`settleDashhistoryNamebox${index}`}>
                          {item && item.type!=='withdrawal' ? ShowNameAccordingToLength(item.firstName+' '+ item.lastName):'' }

                          {item && item.type==='withdrawal' ? item.from_address :'' }
                         
                          </p>
                          <p className='postTransactionHistory' id={`settleDashhistorypostTransactionHistory${index}`}> {item.userPosition?item.userPosition:'' }</p>

                        </div>

                      </div>
                      <div className="dateTransactionHistoryDiv"  id={`settleDashhistorydateTransactionHistoryDiv${index}`}>
                        <div className='dateTransactionHistory'  id={`settleDashhistorydateTransactionHistory${index}`}>
                        {dataTimeFormat(item.transaction_date)}

                        {/*item && item.transaction_date? moment (new Date ( item.transaction_date)).format ('DD/MM/YYYY - h:MM A' ):''*/}
                        
                        </div>
                      </div>
                      <div className="dateTransactionHistoryDiv"  id={`settleDashhistorydateTransactionHistoryinner${index}`}>
                        <div className='amountTransactionHistory'>{item.type=== "deposit" || item.type==="topup"?'+':'-'}{item.currency?item.currency:''}$ {item.total_amount? priceWithDecimal(item.total_amount):0}</div>
                      </div>
                      <div className="detailsTransactionHistory"  id={`settleDashhistorydateTransactionHistoryDetail${index}`}>
                        <div className='detailsView' onClick={()=>this.openDetail(item)} data-rh="View detail">
                          <span>Details</span>
                        </div>
                      </div>
                    </div>
                    <div className='transactionDotDiv'/>
                        </div>))}

                        {transactionHistory.length>0 &&<div style={{textAlign:'center'}}>
                        
                          <Button  id="settleDashloadMore" className="loadMoreButton" onClick={()=>this.loadMore()} data-rh="Load more">Load More</Button>
                        </div>}



                </div>
              </div>
            </div>
          </div>
        </div>
        <RightSideContainer>
        <PaymentDashBoard history={this.props.history} />
        </RightSideContainer>
        
        

        <Modal size="md" isOpen={this.state.showDetail} centered={true} toggle={()=>this.setState({
          showDetail: false
        })} style={{width:400}}>
        {/* <ModalHeader>Received</ModalHeader> */}

        {this.state.showUserInfo &&
          <ModalBody
            style={{backgroundColor: '#FFFFFF', borderRadius: 10}}
            id="settleDashShowDetailModal1"
          >
            <div style={{width:341,margin:'auto',paddingTop:20}} id="settleDashShowDetailModal2">
              {/*<div>
                <img
                  src={closeIcon}
                  className="closeimg"
                  onClick={() => this.closemodal ()}
                />
              </div>*/}
              <div className="detailBorder" id="settleDashShowDetailModal3"/>
              <p className="detailModalTitle" id="settleDashShowDetailModal4">TRANSACTION RECEIPT</p>

              <div className="detailBorder" id="settleDashShowDetailModal5"/>
              

              <div className="detailModalDate" id="settleDashShowDetailModal6">
                  <div>
                  {showUserInfo && showUserInfo.transaction_date?dataFormat( showUserInfo.transaction_date):''}

                  </div>
                  <div>
                  {showUserInfo && showUserInfo.transaction_date?payTimeFormat( showUserInfo.transaction_date):''}

                  </div>
              </div>
              <div className="detailBorder" id="settleDashShowDetailModal7"/>

              <div style={{paddingTop:15}}>
              <p className="detaillableTitle" id="settleDashShowDetailModal8">Funds  {showUserInfo.type=== "deposit" || showUserInfo.type=== "topup"?' Received From':' Transfer To'}:</p>
              <div className="detailProfileBox" id="settleDashShowDetailModal9">
                <div className="imageBox" id="settleDashShowDetailModal10">
                  <img src={ showUserInfo.type==="topup"? topupAdminIcon : showUserInfo.profilePictureURL?showUserInfo.profilePictureURL:ProfileIcon}  onError={e => {
                    e.target.onerror = null;
                    e.target.src = ProfileIcon;
                  }}
                  alt="topupimg"/>
                </div>
                <div>
                  <p className="usernameTitle" id="settleDashShowDetailModal11">
                  {showUserInfo && showUserInfo.firstName ? ShowNameAccordingToLength(showUserInfo.firstName+' '+ showUserInfo.lastName):'' }
                  {' '}
                  {/*showUserInfo.lastName?showUserInfo.lastName:'' */}

                  </p>
                  <p className="position" id="settleDashShowDetailModal12">{showUserInfo.userPosition?showUserInfo.userPosition:'' } </p>

              </div>
              </div>
              <p id="settleDashShowDetailModal13" className="detaillableTitle" style={{paddingTop:10}}>Amount: <span>
              {showUserInfo.type=== "deposit" || showUserInfo.type=== "topup"?'+':'-'}{showUserInfo.currency?showUserInfo.currency:''}$ {showUserInfo.total_amount?priceWithDecimal(showUserInfo.total_amount):0}
              </span></p>
              <p id="settleDashShowDetailModal14" className="detaillableTitle">Purpose:</p>
              <p id="settleDashShowDetailModal15" className="detailModaldescription">{showUserInfo.transaction_purposes?showUserInfo.transaction_purposes:'' }</p>

              </div>

              
              </div>
          
           
          </ModalBody>}
      </Modal>
  
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    payment: state.PaymentReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getTransactionHistory,
      resetTransactionList
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(TransactionHistory);
