import React, {Component} from 'react';
import {Button, Modal, ModalBody} from 'reactstrap';
import * as routes from '../../constants/routes';
import ServiceOnDemandProfilePage
  from '../service-On-Demand-Profile/serviceOnDemandProfile';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import './serviceonDemandCreateServiceRequestDetail.css';
import Frame from '../../images/serviceOnDemandSearch/Frame.png';
import roman from '../../images/serviceOnDemandSearch/roman.png';
// import star from '../../images/serviceOnDemandSearch/star.png';
import StarRatingComponent from 'react-star-rating-component';

import greygear from '../../images/serviceOnDemandMyRequest/greygear.png';
import requestimg from '../../images/serviceOnDemandMyRequest/requestimg.png';
import close_icon from '../../images/close_icon.png';
import ServiceOnDemandCommonComponent
  from '../Service-On-Demand-CommanComponent/Service-On-Demand-CommanComponent';
import {Dropdown} from 'primereact/dropdown';
import manufactureList from '../../constants/localJSON/manufacture.json';
import systemList from '../../constants/localJSON/system.json';

import {
  CreateRequest,
  GetServiceProfile,
  sodUploadErrorLog,
} from '../../../src/actions/serviceOnDemandAction';
import DatePicker from 'react-datepicker';

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});
class serviceonDemandCreateServiceRequestDetailPage extends Component {
  constructor (props) {
    super (props);
    this.state = {
      placeServiceModal: false,
      placeServiceSuccessModal: false,
      ManufacturerDropDown: false,
      systemNameDropDown: false,
      Dropdownvalue: '',
      Dropdownvalue2: '',
      title: '',
      description: '',
      modality: '',
      typeOfService: '',
      errorCode: '',
      serviceDate: '',
      callType: '',
      paymentOffered: '',
      providerProfile: '',
      docid: '',
      isSubmit: false,
      redirectStatus: '',
      // errorLogFilename:"",
      // errorLogContentType:""
    };
    this.openplaceServiceSuccessModal = this.openplaceServiceSuccessModal.bind (
      this
    );
  }
  componentWillReceiveProps (nextProps) {
   
    if (nextProps) {
      if (
        nextProps &&
        nextProps.createServiceRequest &&
        nextProps.createServiceRequest.serviceProfile
      ) {
        if (this.state.redirectStatus) {
          if (this.state.redirectStatus === 'draft') {
            this.props.history.push (routes.SERVICEONDEMANDMYREQUEST);
          } else {
            this.openplaceServiceSuccessModal ();
          }
        }

        this.setState (
          {providerProfile: nextProps.createServiceRequest.serviceProfile},
          () => {
          
          }
        );
        this.setState (
          {
            docid: nextProps.createServiceRequest.serviceProfile.serviceProfile
              .docID,
          },
          () => {
          
          }
        );
      }
    }
  }
  componentDidMount () {
    let geturl = this.props.match.params;
  
    if (geturl && geturl.providerId) {
      this.setState (
        {
          providerId: geturl.providerId,
        },
        () => {
          let sendRequest = {
            data: {
              docID: this.state.providerId,
            },
          };
          this.props.GetServiceProfile (sendRequest);
        }
      );
    }
  }
  openplaceServiceModal = () => {
    this.setState ({placeServiceModal: true});
  };
  closeplaceServiceModal = () => {
    this.setState ({placeServiceModal: false});
  };
  openplaceServiceSuccessModal = () => {
    this.setState ({placeServiceSuccessModal: true});
    this.setState ({placeServiceModal: false});
  };
  closeplaceServiceSuccessModal = () => {
    this.setState ({placeServiceSuccessModal: false});
  };

  detailPage = () => {
    this.props.history.push (routes.SERVICEONDEMANDCREATESERVICEREQUEST);
  };
  Manufacturer () {
    this.setState ({
      ManufacturerDropDown: !this.state.ManufacturerDropDown,
      systemNameDropDown: false,
    });
  }
  systemName () {
    this.setState ({
      systemNameDropDown: !this.state.systemNameDropDown,
      ManufacturerDropDown: false,
    });
  }
  changeValue = e => {
    this.setState ({Dropdownvalue: e.currentTarget.textContent});
  };
  changeValue2 = e => {
    this.setState ({Dropdownvalue2: e.currentTarget.textContent});
  };
  CreateRequest = () => {
    this.setState ({isSubmit: true}, () => {
      const {
        title,
        description,
        modality,
        typeOfService,
        errorCode,
        serviceDate,
        callType,
        paymentOffered,
        Dropdownvalue2,
        Dropdownvalue,
      } = this.state;

      const isInvalid =
        title === '' ||
        description === '' ||
        description.length > 100 ||
        modality === '' ||
        typeOfService === '' ||
        errorCode === '' ||
        serviceDate === '' ||
        callType === '' ||
        paymentOffered === '' ||
        Dropdownvalue === '' ||
        Dropdownvalue2 === '';

      if (isInvalid) {
        return false;
      }

      this.setState ({
        placeServiceModal: true,
      });
    });
  };
  doSubmitCreateRequest (status) {
    let sendRequest = {
      data: {
        title: this.state.title,
        description: this.state.description,
        manufacturer: this.state.Dropdownvalue,
        modality: this.state.modality,
        systemName: this.state.Dropdownvalue2,
        typeOfService: this.state.typeOfService,
        errorCode: this.state.errorCode,
        serviceDate: this.state.serviceDate,
        callType: this.state.callType,
        paymentOffered: this.state.paymentOffered,
        serviceProviderUID: this.state.docid,
        errorLogFilename: this.state.errorLogFilename,
        errorLogContentType: this.state.errorLogContentType,
        errorLog: this.state.errorLog,
      },
    };
  
    this.setState ({
      redirectStatus: status,
    });

    this.props.CreateRequest (sendRequest);
  }
  onChangeModality = e => {
    this.setState (
      {
        modality: e,
      },
      () => {
        
      }
    );
  };
  onChangeTypeOfService = e => {
    this.setState (
      {
        typeOfService: e,
      },
      () => {
       
      }
    );
  };
  onChangeTypeofCall = e => {
    this.setState (
      {
        callType: e,
      },
      () => {
       
      }
    );
  };
  gonext = () => {
    alert ('fgg');
    this.props.history.push (
      routes.SERVICEONDEMANDPROVIDERPROFILE + `/${this.state.docid}`
    );
  };

  onSelectFile = e => {
    let files = e.target.files[0];

    // let fileType = files.name.split ('.').pop ();
    // console.log (fileType);

    // var filename = new Date ().getTime ().toString () + files.name;
    // filename = filename.replace (/\s/g, '');

    let fileType = files.name.split ('.').pop ();
    let tempFilename = files.name.split ('.').slice (0, -1).join ('.');
    var filename = tempFilename + '.' + fileType;
    // var filename =
    //   tempFilename +
    //   moment (new Date ()).format ('_DDMMYYYY_') +
    //   new Date ().getTime ().toString () +
    //   '.' +
    //   fileType;
    filename = filename.replace (/\s/g, '');
    // console.log (filename);

    if (
      fileType.toLowerCase () === 'jpeg' ||
      fileType.toLowerCase () === 'jpg' ||
      fileType.toLowerCase () === 'pdf'
    ) {
      let reader = new FileReader ();
      reader.readAsDataURL (files);
      reader.onload = e => {
        this.setState (
          {
            errorLogContentType: fileType === 'pdf'
              ? 'application/pdf'
              : 'image/jpeg',
            errorLog: e.target.result,
            errorLogFilename: filename,
          },
          () => {
            // console.log (this.state);
          }
        );
      };
    }
  };
  render () {
    const {
      title,
      description,
      modality,
      typeOfService,
      errorCode,
      serviceDate,
      callType,
      paymentOffered,
      providerProfile,
      isSubmit,
      Dropdownvalue2,
      Dropdownvalue,
    } = this.state;

    return (
      <div
        className="maindivvvv"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          background: '#FFFFFF',
        }}
      >

        <div className="createServiceLeftContainer">
          <div style={{width: 230}}>
            <ServiceOnDemandCommonComponent />
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div className="GENERATEAAA">
              GENERATE A
            </div>
            <div>
              <img alt="" src={Frame} />
              <span className="GOBACKkk">GO BACK</span>
            </div>
          </div>
          <div className="erviceRequest">
            Service Request
          </div>

          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div>
              <div className="ServiceDetails">
                Service Details:
              </div>
              <div>
                <div className="Title">
                  Title*
                </div>
                <div>
                  <input
                    className="Rectangleinp"
                    placeholder="Enter Request Title"
                    name="title"
                    value={this.state.title}
                    onChange={e =>
                      this.setState (byPropKey ('title', e.target.value))}
                  />
                  {!title &&
                    isSubmit &&
                    <p className="validationerrorMessage">
                      Title is  required *
                    </p>}
                </div>
              </div>
              <div style={{marginTop: 17}}>
                <div className="Description">
                  Description*
                </div>
                <div>
                  <textarea
                    type="textarea"
                    className="Rectangleinpii"
                    placeholder="(100 Characters)"
                    name="description"
                    maxLength={101}
                    value={this.state.description}
                    onChange={e =>
                      this.setState (byPropKey ('description', e.target.value))}
                  />
                  {!description &&
                    isSubmit &&
                    <p className="validationerrorMessage">
                      Description is  required *
                    </p>}
                  {description.length > 100 &&
                    <p className="validationerrorMessage">
                      100 Characters  required *
                    </p>}
                </div>
              </div>
              <div
                className="myCreateServiceRequestDetail"
                style={{
                  display: 'flex',
                  marginTop: 15,
                }}
              >
                <div>
                  <Dropdown
                    placeholder="Manufacturer"
                    value={this.state.Dropdownvalue}
                    options={manufactureList}
                    onChange={e => this.setState ({Dropdownvalue: e.value})}
                  />
                  {!Dropdownvalue &&
                    isSubmit &&
                    <p className="validationerrorMessage">
                      Manufacturer is  required *
                    </p>}
                </div>
                <div>
                  <Dropdown
                    placeholder=" System Name"
                    value={this.state.Dropdownvalue2}
                    options={systemList}
                    onChange={e => this.setState ({Dropdownvalue2: e.value})}
                  />
                  {!Dropdownvalue2 &&
                    isSubmit &&
                    <p className="validationerrorMessage">
                      System Name is  required *
                    </p>}
                </div>
                {/* <input className="manurecc" placeholder="Manufacturer" />
                  <input className="manurecc" style={{marginLeft:20}} placeholder="System Name" /> */}
              </div>
              <div className="Modality">
                Modality*
              </div>
              <div className="CTtt">
                <div>
                  <input
                    value={this.state.modality}
                    onChange={() => this.onChangeModality ('CT')}
                    className="Ellipserad"
                    type="radio"
                    id="CT"
                    name="Modalityradio"
                    style={{position: 'inherit'}}
                  />
                  <label for="CT">
                    CT
                  </label>

                </div>
                <div>
                  <input
                    value={this.state.modality}
                    onChange={() => this.onChangeModality ('MR')}
                    className="Ellipserad"
                    id="MR"
                    type="radio"
                    name="Modalityradio"
                    style={{position: 'inherit', marginLeft: 10}}
                  />
                  <label for="MR">
                    MR
                  </label>

                </div>
                <div>
                  <input
                    value={this.state.modality}
                    className="Ellipserad"
                    type="radio"
                    id="XR"
                    name="Modalityradio"
                    style={{position: 'inherit', marginLeft: 10}}
                    onChange={() => this.onChangeModality ('XR')}
                  />
                  <label for="XR">
                    XR
                  </label>

                </div>
                <div>
                  <input
                    value={this.state.modality}
                    className="Ellipserad"
                    id="Other"
                    type="radio"
                    name="Modalityradio"
                    style={{position: 'inherit', marginLeft: 10}}
                    onChange={() => this.onChangeModality ('Other')}
                  />
                  <label for="Other">
                    Other
                  </label>
                </div>

              </div>
              {!modality &&
                isSubmit &&
                <p className="validationerrorMessage">
                  Modality is  required *
                </p>}
              <div className="Modality">
                Type of Service*
              </div>
              <div className="CTtt">
                <div>
                  <input
                    value={this.state.typeOfService}
                    className="Ellipserad"
                    type="radio"
                    id="Apps Training"
                    name="Serviceradio"
                    style={{position: 'inherit'}}
                    onChange={() =>
                      this.onChangeTypeOfService ('Apps Training')}
                  />
                  <label for="Apps Training">
                    Apps Training
                  </label>

                </div>
                <div>
                  <input
                    value={this.state.typeOfService}
                    className="Ellipserad"
                    type="radio"
                    id="Install/Unistall"
                    name="Serviceradio"
                    style={{position: 'inherit', marginLeft: 10}}
                    onChange={() =>
                      this.onChangeTypeOfService ('Install/Unistall')}
                  />
                  <label for="Install/Unistall">
                    Install/Unistall
                  </label>

                </div>
                <div>
                  <input
                    value={this.state.typeOfService}
                    className="Ellipserad"
                    type="radio"
                    id="Cryo"
                    name="Serviceradio"
                    style={{position: 'inherit', marginLeft: 10}}
                    onChange={() => this.onChangeTypeOfService ('Cryo')}
                  />
                  <label for="Cryo">
                    Cryo
                  </label>

                </div>
              </div>
              <div className="CTtt">
                <div>
                  <input
                    value={this.state.typeOfService}
                    className="Ellipserad"
                    type="radio"
                    id="Parts Inquiry"
                    name="Serviceradio"
                    style={{position: 'inherit'}}
                    onChange={() =>
                      this.onChangeTypeOfService ('Parts Inquiry')}
                  />
                  <label for="Parts Inquiry">
                    Parts Inquiry
                  </label>

                </div>
                <div>
                  <input
                    value={this.state.typeOfService}
                    className="Ellipserad"
                    type="radio"
                    id="PM"
                    name="Serviceradio"
                    style={{position: 'inherit', marginLeft: 10}}
                    onChange={() => this.onChangeTypeOfService ('PM')}
                  />
                  <label for="PM">
                    PM
                  </label>

                </div>
                <div>
                  <input
                    value={this.state.typeOfService}
                    className="Ellipserad"
                    type="radio"
                    id="Service Call"
                    name="Serviceradio"
                    style={{position: 'inherit', marginLeft: 10}}
                    onChange={() => this.onChangeTypeOfService ('Service Call')}
                  />
                  <label for="Service Call">
                    Service Call
                  </label>

                </div>

              </div>
              {!typeOfService &&
                isSubmit &&
                <p className="validationerrorMessage">
                  TypeOfService is  required *
                </p>}
              <div style={{display: 'flex'}}>
                <div>
                  <div className="ErrorCode">
                    Error Code
                  </div>
                  <div>
                    <input
                      className="Rectangle01256"
                      placeholder="(if any)"
                      name="errorCode"
                      value={this.state.errorCode}
                      onChange={e =>
                        this.setState (byPropKey ('errorCode', e.target.value))}
                    />
                    {!errorCode &&
                      isSubmit &&
                      <p className="validationerrorMessage">
                      ErrorCode is  required *
                      </p>}
                  </div>
                </div>
                <div>
                  {/* {this.state.errorLog && <img style={{width:200}} src={this.state.errorLog} />}*/}
                  <div className="ErrorLog">
                    Error Log{' '}
                  </div>
                  <div className="PDFJPGonly">
                    PDF/JPG only

                    <div>

                      <input
                        type="file"
                        name="file"
                        id="logfile"
                        multiple
                        onChange={e => this.onSelectFile (e)}
                        accept=" image/jpeg,  image/png, application/pdf"
                      />

                      <label for="logfile" className="re190">
                        UPLOAD

                      </label>
                      {this.state.errorLog &&
                        <p>{this.state.errorLogFilename}</p>}
                    </div>
                    {/* <Button className="re190" onClick={this.uploadErrorLog}>
                      UPLOAD
                      </Button>*/}
                  </div>
                </div>

              </div>
              <div />
              <div>
                <Button className="Resssctangle" onClick={this.CreateRequest}>
                  Submit Request
                  <div className="butondivvv">
                    <img alt="img" src={requestimg} />
                  </div>
                </Button>
              </div>

            </div>

            <div style={{marginLeft: 25}}>
              {this.state.providerProfile &&
                <div className="Rectangle2002">
                  <div
                    style={{display: 'flex', justifyContent: 'space-between'}}
                    onClick={() => this.gonext ()}
                  >
                    <div>

                      <div className="MsJulieMartha">
                        {providerProfile && providerProfile.title
                          ? providerProfile.title
                          : ''}
                        {providerProfile &&
                          providerProfile &&
                          providerProfile.displayName
                          ? providerProfile.displayName
                          : providerProfile && providerProfile
                              ? (providerProfile.firstName
                                  ? providerProfile.firstName
                                  : '') +
                                  ' ' +
                                  (providerProfile.lastName
                                    ? providerProfile.lastName
                                    : '')
                              : ''}

                      </div>
                      <div className="Californiaaaa">
                        {providerProfile &&
                          providerProfile.serviceProfile &&
                          providerProfile.serviceProfile &&
                          providerProfile.serviceProfile.serviceArea
                          ? providerProfile.serviceProfile.serviceArea
                          : ''}
                      </div>
                    </div>
                    <div>
                      <img
                        alt=""
                        src={
                          providerProfile && providerProfile.profilePictureURL
                            ? providerProfile.profilePictureURL
                            : ''
                        }
                        style={{width: 50, height: 50, borderRadius: 50}}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="AverageRate">Average Rate:</div>
                    <div className="s85perhour">
                      $
                      {providerProfile &&
                        providerProfile.serviceProfile &&
                        providerProfile.serviceProfile &&
                        providerProfile.serviceProfile.avgRate
                        ? providerProfile.serviceProfile.avgRate
                        : ''}
                      per hour
                    </div>
                  </div>
                  <div
                    style={{display: 'flex', justifyContent: 'space-between'}}
                  >
                    <div>
                      <div className="AverageRate">Rating:</div>
                      <div className="s85perhour">4.7</div>
                    </div>
                    <div style={{textAlign: 'center'}}>

                    <StarRatingComponent
                      name={'rating'}
                      starCount={5}
                      // value={
                      //   userProfile &&
                      //     userProfile.serviceProfile &&
                      //     userProfile.serviceProfile.totalServicesOffered
                      //     ? userProfile.serviceProfile.totalServicesOffered
                      //     : 0
                      // }
                      emptyStarColor={'#E4E4E4'}
                      starColor={'#F5D028'}
                      style={{fontSize: '80px'}}
                      // onStarClick={this.onStarClick}
                    />
                  </div>
                  </div>
                  <div style={{textAlign: 'center'}}>
                    <img alt="" src={greygear} />
                    <span className="ServicesProvided21">
                      {providerProfile &&
                        providerProfile.serviceProfile &&
                        providerProfile.serviceProfile &&
                        providerProfile.serviceProfile.totalServicesOffered
                        ? providerProfile.serviceProfile.totalServicesOffered
                        : ''}
                      Services Provided
                    </span>
                  </div>
                  {providerProfile &&
                    providerProfile.serviceProfile &&
                    providerProfile.serviceProfile.servicesOffered &&
                    providerProfile.serviceProfile.servicesOffered.length > 0 &&
                    providerProfile.serviceProfile.servicesOffered.map (
                      (item, index) => (
                        <div key={index}>
                          <div className="geRectangle">
                            {item}
                          </div>
                        </div>
                      )
                    )}
                  {/* <div className="geRectangle">
                  Shimadzu Servicing
                </div>
                <div className="geRectangle">
                  Siemens Servicing
                </div>*/}
                </div>}
              <div className="Scheduleand">
                Schedule and Payment:
              </div>
              <div className="Expecteddd">
                Expected Date of Delivery*
              </div>
              <div>

                <DatePicker
                  className="reccc2012"
                  // disabled={this.state.currentlyWorking}
                  // maxDate={new Date()}
                  // minDate={this.state.startDate}
                  name="serviceDate"
                  // id="enddate"
                  placeholder="Enter Date"
                  selected={this.state.serviceDate}
                  onChange={date =>
                    this.setState (byPropKey ('serviceDate', date))}
                  style={{
                    height: 49,
                    background: '#c4c4c4',
                    border: '1px solid #c4c4c4',
                    borderRadius: 10,
                  }}
                />
                {/* <input
                  className="reccc2012"
                  placeholder="Enter Date"
                  name="serviceDate"
                  value={this.state.serviceDate}
                  onChange={e =>
                    this.setState (byPropKey ('serviceDate', e.target.value))}
                  />*/}
                {!serviceDate &&
                  isSubmit &&
                  <p className="validationerrorMessage">
                    ServiceDate is  required *
                  </p>}
              </div>
              <div className="Modality">
                Type of Call*
              </div>
              <div className="CTtt">
                <input
                  className="Ellipserad"
                  type="radio"
                  id="Video"
                  name="Callradio"
                  style={{position: 'inherit'}}
                  value={this.state.callType}
                  onChange={() => this.onChangeTypeofCall ('Video')}
                />
                <label for="Video">
                  Video
                </label>

                <input
                  className="Ellipserad"
                  type="radio"
                  id="Audio"
                  name="Callradio"
                  style={{position: 'inherit', marginLeft: 10}}
                  value={this.state.callType}
                  onChange={() => this.onChangeTypeofCall ('Audio')}
                />

                <label for="Audio">
                  Audio
                </label>
                <input
                  className="Ellipserad"
                  type="radio"
                  id="In-Person"
                  name="Callradio"
                  style={{position: 'inherit', marginLeft: 10}}
                  value={this.state.callType}
                  onChange={() => this.onChangeTypeofCall ('In-Person')}
                />
                <label for="In-Person">
                  In-Person
                </label>

              </div>
              {!callType &&
                isSubmit &&
                <p className="validationerrorMessage">
                  CallType is  required *
                </p>}
              <div className="Expecteddd">
                Payment Offered*
              </div>
              <div>
                <input
                  pattern="[0-9]*"
                  className="reccc2012"
                  placeholder="USD$"
                  name="paymentOffered"
                  value={this.state.paymentOffered}
                  onChange={evt => {
                    const paymentOffered = evt.target.validity.valid
                      ? evt.target.value
                      : this.state.paymentOffered;
                    this.setState ({
                      paymentOffered,
                    });
                  }}

                  // onChange={e =>
                  //   this.setState (
                  //     byPropKey ('paymentOffered', e.target.value)
                  //   )}
                />
                {!paymentOffered &&
                  isSubmit &&
                  <p className="validationerrorMessage">
                    PaymentOffered is  required *
                  </p>}
              </div>
            </div>
          </div>
          <Modal
            size="sm"
            isOpen={this.state.placeServiceModal}
            toggle={this.closeplaceServiceModal}
            centered={true}
          >
            <ModalBody className="placemodal">
              <div onClick={this.closeplaceServiceModal}>
                <img
                  alt=""
                  src={close_icon}
                  style={{width: 25, float: 'right'}}
                />
              </div>
              <div className="Rssequestddd">
                Place Service Request
              </div>
              <div className="placethisservic">
                Do you wish to place this service request
                with
                {' '}
                {providerProfile && providerProfile.title
                  ? providerProfile.title
                  : ''}
                {providerProfile &&
                  providerProfile &&
                  providerProfile.displayName
                  ? providerProfile.displayName
                  : providerProfile && providerProfile
                      ? (providerProfile.firstName
                          ? providerProfile.firstName
                          : '') +
                          ' ' +
                          (providerProfile.lastName
                            ? providerProfile.lastName
                            : '')
                      : ''}
                {' '}
                ?
              </div>
              <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                <div>
                  <Button
                    className="buuueefcv"
                    onClick={() => this.doSubmitCreateRequest ('draft')}
                    // onClick={() =>
                    //   this.props.history.push (routes.SERVICEONDEMANDMYREQUEST)}
                  >
                    SAVE AS DRAFT
                  </Button>
                </div>
                <div>
                  <Button
                    className="PLACENOWButton"
                    onClick={() => this.doSubmitCreateRequest ('placenow')}
                    // onClick={this.openplaceServiceSuccessModal}
                  >
                    PLACE NOW
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <Modal
            size="sm"
            isOpen={this.state.placeServiceSuccessModal}
            toggle={this.closeplaceServiceSuccessModal}
            centered={true}
          >
            <ModalBody className="placesucmod">
              <div className="lacedSuccessfully">
                Request Placed Successfully
              </div>
              <div className="yuiosubmitted">
                Your request has been submitted to the service provider for review. You can contact the service provider on RDEE Messenger for further clarification.
              </div>
              <div style={{textAlign: 'center'}}>
                <Button
                  className="rdfseturn"
                  onClick={() =>
                    this.props.history.push (routes.SERVICEONDEMANDMYREQUEST)}
                  // onClick={()=>{this.closeplaceServiceSuccessModal}
                >
                  RETURN TO MY REQUESTS
                </Button>
              </div>
              <div
                className="PlaceAno"
                onClick={() =>
                  this.props.history.push (
                    routes.SERVICEONDEMANDCREATESERVICEREQUEST
                  )}
              >
                Place Another Request
              </div>
            </ModalBody>
          </Modal>
        </div>

        <div style={{width: 480}}>
          <ServiceOnDemandProfilePage   history={this.props.history} isSearch={true}/>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    createServiceRequest: state.serviceOnDemandReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      CreateRequest,
      GetServiceProfile,
      sodUploadErrorLog,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  serviceonDemandCreateServiceRequestDetailPage
);
