import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
// import { SignInLink } from "./SignIn";
import StarRatingComponent from 'react-star-rating-component';
import UserContactListPage from '../contacts/usercontacts/userList';
import leftbackarrow from '../../images/icons/leftbackarrow.png';
import profilePlaceholder from './../../images/profile_placeholder.png';
import localtion from '../../images/profile/location.svg';
import building from '../../images/profile/building.svg';
import work from '../../images/profile/work.svg';
import {Button} from 'reactstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import './reputationDetails.css';
import {
  getAllUserContacts_v2,
  getUserDetail,
  addUserContactFunc,
} from '../../actions/contacts';
import * as routes from '../../constants/routes';
import  RightSideContainer from '../../components/UI/rightSideContainer/rightSideContainer';

class ReputationDetails extends Component {
  //defining state
  constructor (props) {
    super (props);
    this.state = {
      personalDetail: [],
      educationDetail: [],
      professionalDetail: [],
      checkuserChatStatus: '',
      ordersCompletedTab: true,
      servicesProvidedTab: false,
      userid: '',
    };
  }

  componentWillReceiveProps (nextProps) {
   

    if (nextProps) {
      if (
        nextProps.contacts &&
        nextProps.contacts.contactDetail &&
        nextProps.contacts.contactDetail.length > 0
      ) {
        let getData = nextProps.contacts.contactDetail;
        this.setState (
          {
            personalDetail: getData[0].personalDetails,
            professionalDetail: getData[1].professionalDetails,
            educationDetail: getData[2].educationDetails,
            businessDetail: getData[3].businessProfile &&
              getData[3].businessProfile.length > 0 &&
              getData[3].businessProfile[0].businessDetails
              ? getData[3].businessProfile[0].businessDetails
              : '',
            checkuserChatStatus: getData[4].inContactList,
          },
          () => {
           
          }
        );
      }
    }
  }

  componentDidMount () {


    let geturl = this.props.match.params;

    let loginUser = JSON.parse (localStorage.getItem ('loginuser'));

   

    if (geturl && geturl.userId) {
      this.setState ({
        userid: loginUser.docID,
      });
      let sendRequest = {
        data: {
          getPersonalDetails: true,
          getProfessionalDetails: true,
          getEducationalDetails: true,
          getBusinessProfile: true,
          docID: geturl.userId,
        },
      };
      this.props.getUserDetail (sendRequest);
    }
  }
  openOrdersCompletedTab = () => {
    this.setState ({ordersCompletedTab: true});
    this.setState ({servicesProvidedTab: false});
  };
  openServicesProvidedTab = () => {
    this.setState ({ordersCompletedTab: false});
    this.setState ({servicesProvidedTab: true});
  };
  render () {
    const {
      personalDetail,
      checkuserChatStatus,
      professionalDetail,
    } = this.state;
    return (
      <div className=" ">
        <div className="ordermaninContainer">
          <div className="ordersprofileContent">
            <div style={{textAlign: 'right'}}>
              <Button
                className="ordersProfilebackbutton"
                onClick={() => this.props.history.goBack ()}
              >
                <div>
                  <img src={leftbackarrow} alt="backIcon" />
                </div>

                Go <span> Back</span>
              </Button>
            </div>
            <div className="ordersinnerContainer">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div style={{display: 'flex', width: 765}}>
                  <div>
                    <img
                      className="orderprofileImage"
                      alt=""
                      src={
                        personalDetail && personalDetail.profilePictureURL
                          ? personalDetail.profilePictureURL
                          : profilePlaceholder
                      }
                    />
                  </div>
                  <div>
                    <div className="searchahndle"  style={{display:'inline-flex'}}>
                      {personalDetail && personalDetail.searchHandle
                        ? personalDetail.searchHandle
                        : ''}

                        {personalDetail && personalDetail.statusMessage && <div style={{display:'inline-flex',marginLeft:50}}>
                              {personalDetail.statusMessage==="Active" &&<div className="userActiveStatusCircle" style={{background:'#4AEA87',marginTop:15}} />}
                              {personalDetail.statusMessage==="Away" &&<div className="userActiveStatusCircle" style={{background:'#FFBF00',marginTop:15}} />}
                              {personalDetail.statusMessage==="Do Not Disturb" &&<div className="userActiveStatusCircle" style={{background:'#ff0b34',marginTop:15}} />}
                              {personalDetail.statusMessage==="Invisible" &&<div className="userActiveStatusCircle" style={{border:'1px solid #4AEA87',marginTop:15}} />}
                              Status: { personalDetail.statusMessage}
                              </div>}

                    </div>
                    <div className="johndohds">
                      {personalDetail && personalDetail.title
                        ? personalDetail.title
                        : ''}
                      {' '}
                      {personalDetail && personalDetail.displayName
                        ? personalDetail.displayName
                        : personalDetail && personalDetail.firstName
                            ? personalDetail.firstName
                            : 'N/A' +
                                ' ' +
                                (personalDetail && personalDetail.lastName)
                                ? personalDetail.lastName
                                : 'N/A'}
                    </div>

                    <p className="ordersworkplace">
                      {' '}<img src={work} />
                      {' '}
                      {professionalDetail &&
                        professionalDetail[0] &&
                        professionalDetail[0].position
                        ? professionalDetail[0].position
                        : 'N/A'}

                    </p>
                    <p className="orderuniverfr">
                      {' '}
                      <img src={building} />
                      {' '}
                      {professionalDetail &&
                        professionalDetail[0] &&
                        professionalDetail[0].firmName
                        ? professionalDetail[0].firmName
                        : 'N/A'}
                    </p>
                    <p className="orderslocaltion">
                      {' '}
                      <img src={localtion} />
                      {' '}
                      {personalDetail && personalDetail.address1
                        ? personalDetail.address1
                        : 'N/A'}
                      {' '}
                    </p>
                  </div>
                </div>
                <div>
                  {personalDetail && personalDetail.docID === this.state.userid
                    ? <Button className="editproorderbutton">
                        Edit My Profile
                      </Button>
                    : <Button className="editproorderbutton">
                        Add Contact
                      </Button>}
                </div>
              </div>
              <div>
                <Button className="returntoprofile" onClick={()=>this.props.history.goBack()}>
                  Return to Profile
                </Button>
              </div>
              <div className="reputationbox">
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div style={{display: 'flex'}}>
                    <div className="ordersreputationHeading">
                      {personalDetail &&
                        personalDetail.docID === this.state.userid &&
                        'MY '}
                      <span>REPUTATION</span>
                    </div>
                    <div>
                      <Button className="ordertopjvgsjdfsy"> TOP 10%</Button>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className="ratingjhcgsd">4.7 / 5.0</div>
                      <div style={{margin: 0, marginLeft: 36}}>
                        <StarRatingComponent
                          name={'rating'}
                          starCount={5}
                          // value={
                          //   userProfile &&
                          //     userProfile.serviceProfile &&
                          //     userProfile.serviceProfile.totalServicesOffered
                          //     ? userProfile.serviceProfile.totalServicesOffered
                          //     : 0
                          // }
                          emptyStarColor={'#E4E4E4'}
                          starColor={'#F5D028'}
                          style={{fontSize: '80px', margin: 0}}
                          // onStarClick={this.onStarClick}
                        />
                        <p className="reviews4gj">4 Reviews</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="detailstabc">
                  <div style={{display: 'flex'}}>
                    <Button
                      className={
                        this.state.ordersCompletedTab
                          ? 'activebuttonclassk'
                          : 'OrdersComple'
                      }
                      onClick={this.openOrdersCompletedTab}
                    >
                      Orders Completed (2)
                    </Button>
                    <Button
                      className={
                        this.state.servicesProvidedTab
                          ? 'activebuttonclassk'
                          : 'OrdersComple'
                      }
                      style={{marginLeft: 10}}
                      onClick={this.openServicesProvidedTab}
                    >
                      Services Provided (2)
                    </Button>
                  </div>
                  {this.state.ordersCompletedTab
                    ? <div>
                        <div className="dividerfjvk" />
                        <div style={{display: 'flex', marginTop: 20}}>
                          <div style={{display: 'flex'}}>
                            <img
                              alt=""
                              src={profilePlaceholder}
                              style={{width: 50, height: 50}}
                            />
                            <div style={{width: 243}}>
                              <p className="laurenwalchkyc">Laura Walsh</p>
                              <p className="orderdatesjsabcdJ"> 10/07/2019</p>
                            </div>
                          </div>
                          <div style={{width: 435}}>
                            <p className="ugksdsdsf">
                              Dr. John bought an item from Laura Walsh on RDEE
                              <br />{' '}

                              {' '}
                              Marketplace.
                            </p>
                            <p className="Goodbuyer">
                              Good buyer. Very prompt to make payment<br />
                              and mark the delivery complettion of item.<br />
                              Will sell again to him.

                            </p>
                          </div>
                          <div>
                            <StarRatingComponent
                              name={'rating'}
                              starCount={5}
                              // value={
                              //   userProfile &&
                              //     userProfile.serviceProfile &&
                              //     userProfile.serviceProfile.totalServicesOffered
                              //     ? userProfile.serviceProfile.totalServicesOffered
                              //     : 0
                              // }
                              emptyStarColor={'#E4E4E4'}
                              starColor={'#F5D028'}
                              style={{fontSize: '80px', margin: 0}}
                              // onStarClick={this.onStarClick}
                            />
                          </div>
                        </div>

                      </div>
                    : <div>
                        <div className="dividerfjvk" />
                        <div style={{display: 'flex', marginTop: 20}}>
                          <div style={{display: 'flex'}}>
                            <img
                              alt=""
                              src={profilePlaceholder}
                              style={{width: 50, height: 50}}
                            />
                            <div style={{width: 243}}>
                              <p className="laurenwalchkyc">Laura Walsh</p>
                              <p className="orderdatesjsabcdJ"> 10/07/2019</p>
                            </div>
                          </div>
                          <div style={{width: 435}}>
                            <p className="ugksdsdsf">
                              Dr. John bought an item from Laura Walsh on RDEE
                              <br />{' '}

                              {' '}
                              Marketplace.
                            </p>
                            <p className="Goodbuyer">
                              Good buyer. Very prompt to make payment<br />
                              and mark the delivery complettion of item.<br />
                              Will sell again to him.

                            </p>
                          </div>
                          <div>
                            <StarRatingComponent
                              name={'rating'}
                              starCount={5}
                              // value={
                              //   userProfile &&
                              //     userProfile.serviceProfile &&
                              //     userProfile.serviceProfile.totalServicesOffered
                              //     ? userProfile.serviceProfile.totalServicesOffered
                              //     : 0
                              // }
                              emptyStarColor={'#E4E4E4'}
                              starColor={'#F5D028'}
                              style={{fontSize: '80px', margin: 0}}
                              // onStarClick={this.onStarClick}
                            />
                          </div>
                        </div>

                      </div>}

                </div>
              </div>
            </div>

          </div>
          <RightSideContainer>
            <UserContactListPage history={this.props.history} />
          </RightSideContainer>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contacts: state.ContactsReducer,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      getAllUserContacts_v2,
      getUserDetail,
      addUserContactFunc,
    },
    dispatch
  );
};

export default connect (mapStateToProps, mapDispatchToProps) (
  ReputationDetails
);
