import {
  getServiceProfileService,
  serviceOnDemandSearchUserRequests,
  serviceOnDemandSearchProviderRequests,
  searchServiceProviders,
  serviceOnDemandCreateProfile,
  serviceOnDemandAddServiceOffered,
  serviceOnDemandCreateRequest,
  serviceOnDemandCheckServiceProfileExists,
  serviceOnDemandUploadErrorLog,
} from './../services/serviceOnDemandService';
import {
  GETSERVICEPROFILE,
  MY_SERVICE_REQUESTS,
  MY_REQUESTS_RECIEVED,
  SEARCH_SERVICE_PROVIDERS,
  CREATE_SERVICE_PROFILE,
  ADD_SERVICE_OFFERED,
  CREATE_REQUEST,
  CHECK_SERVICE_PROFILE_EXISTS,
  SEARCH_PROVIDER_REQUEST,
  UPLOAD_ERROR_LOG,
} from './../reducers/serviceOnDemandReducer';
import {Loading} from './LoadingAction';
import {createNotification} from '../constants/notificationtoast';
import {awardedServiceRequest} from './serviceRequestAction';

export const GetServiceProfile = request => {
  return dispatch => {
    dispatch (Loading (true));
    getServiceProfileService (request)
      .then (userData => {
        dispatch (Loading (false));
        if (userData && userData.data && userData.data.result.status === true) {
          dispatch ({
            type: GETSERVICEPROFILE,
            payload: userData.data.result.Data,
          });
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch (err => {
        dispatch (Loading (false));
        throw err;
      });
  };
};

export const GetMyServiceRequests = request => {
  return dispatch => {
    dispatch (Loading (true));
    serviceOnDemandSearchUserRequests (request)
      .then (userData => {
        dispatch (Loading (false));
        if (userData && userData.data && userData.data.result.status === true) {
          dispatch ({
            type: MY_SERVICE_REQUESTS,
            payload: userData.data.result.Data,
          });
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch (err => {
        dispatch (Loading (false));
        throw err;
      });
  };
};

export const GetMyRequestsRecieved = request => {
  return dispatch => {
    dispatch (Loading (true));
    serviceOnDemandSearchProviderRequests (request)
      .then (userData => {
        dispatch (Loading (false));
        if (userData && userData.data && userData.data.result.status === true) {
          dispatch ({
            type: MY_REQUESTS_RECIEVED,
            payload: userData.data.result.Data,
          });
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch (err => {
        dispatch (Loading (false));
        throw err;
      });
  };
};
export const GetServiceProviders = request => {
  return dispatch => {
    dispatch (Loading (true));
    searchServiceProviders (request)
      .then (userData => {
        dispatch (Loading (false));
        dispatch ({type: SEARCH_PROVIDER_REQUEST, payload: request});
        if (userData && userData.data && userData.data.result.status === true) {
          dispatch ({
            type: SEARCH_SERVICE_PROVIDERS,
            payload: userData.data.result.Data,
          });
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch (err => {
        dispatch (Loading (false));
        throw err;
      });
  };
};
export const CreateServiceProfile = request => {
  return dispatch => {
    dispatch (Loading (true));
    serviceOnDemandCreateProfile (request)
      .then (userData => {
        dispatch (Loading (false));

        if (userData && userData.data && userData.data.result.status === true) {
          // dispatch ({
          //   type: CREATE_SERVICE_PROFILE,
          //   payload: userData.data.result,
          // });
          createNotification ('success', userData.data.result.message);
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch (err => {
        dispatch (Loading (false));
        throw err;
      });
  };
};
export const AddServiceOffered = (request, docId) => {
  return dispatch => {
    dispatch (Loading (true));
    serviceOnDemandAddServiceOffered (request)
      .then (userData => {
        dispatch (Loading (false));
        if (userData && userData.data && userData.data.result.status === true) {
          dispatch ({type: ADD_SERVICE_OFFERED, payload: userData.data.result});

          let sendRequest = {
            data: {
              docID: docId,
            },
          };
          dispatch (GetServiceProfile (sendRequest));
          createNotification ('success', userData.data.result.message);
          // history.push(routes.SERVICEONDEMANDSEARCH);
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch (err => {
        dispatch (Loading (false));
        throw err;
      });
  };
};
export const CreateRequest = request => {
  return dispatch => {
    dispatch (Loading (true));
    serviceOnDemandCreateRequest (request)
      .then (userData => {
        dispatch (Loading (false));
        if (userData && userData.data && userData.data.result.status === true) {
          dispatch ({type: CREATE_REQUEST, payload: userData.data.result});
          // history.push(routes.SERVICEONDEMANDSEARCH);
          let id = userData.data.result.Data.docID;
          const sendRequest = {
            data: {
              docID: id,
            },
          };
          dispatch (awardedServiceRequest (sendRequest));
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch (err => {
        dispatch (Loading (false));
        throw err;
      });
  };
};
export const CheckServiceProfileExists = request => {
  return dispatch => {
    dispatch (Loading (true));
    serviceOnDemandCheckServiceProfileExists (request)
      .then (userData => {
        dispatch (Loading (false));
        if (userData && userData.data && userData.data.result.status === true) {
          dispatch ({
            type: CHECK_SERVICE_PROFILE_EXISTS,
            payload: userData.data.result,
          });
          // history.push(routes.SERVICEONDEMANDSEARCH);
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch (err => {
        dispatch (Loading (false));
        throw err;
      });
  };
};
export const sodUploadErrorLog = request => {
  return dispatch => {
    dispatch (Loading (true));
    serviceOnDemandUploadErrorLog (request)
      .then (userData => {
        dispatch (Loading (false));
        if (userData && userData.data && userData.data.result.status === true) {
          dispatch ({
            type: UPLOAD_ERROR_LOG,
            payload: userData.data.result,
          });
          // history.push(routes.SERVICEONDEMANDSEARCH);
        } else {
          if (userData && userData.data && userData.data.result) {
            createNotification ('error', userData.data.result.message);
          }
        }
      })
      .catch (err => {
        dispatch (Loading (false));
        throw err;
      });
  };
};

export const ResetGetServiceProviders = () => {
  return dispatch => {
    dispatch ({
      type: SEARCH_SERVICE_PROVIDERS,
      payload: [],
    });
    dispatch ({type: SEARCH_PROVIDER_REQUEST, payload: []});
  };
};
