import React, {useState} from 'react';
import ReactCrop from 'react-image-crop';
import PropTypes from 'prop-types';

import 'react-image-crop/dist/ReactCrop.css';
import './ImageCrop.css';

const getCroppedImg = async (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');
  
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
  
    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');
  
    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        blob.name = fileName;
        resolve(blob);
      }, 'image/jpeg', 1);
    });
}

export default function ImageCrop({
    image,
    crop,
    circularCrop = true,
    onSubmitClick = crop => console.log('Image Cropped', crop)
}) {
    const [imageCropRef, setImageCropRef] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [cropVal, setCrop] = useState((crop || {aspect: 1}));
    const onCropComplete = async crop => {
        if (imageCropRef && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(
                imageCropRef,
                crop,
                'new-file.jpeg'
            );
            setCroppedImage(croppedImageUrl);
        }
    };

    return (
        <div className="layout-view"  id="imgCrop1">
            <div className="crop-view" id="imgCrop2">
                <ReactCrop
                    circularCrop={circularCrop}
                    crop={cropVal}
                    src={image}
                    onComplete={onCropComplete}
                    onImageLoaded={image => setImageCropRef(image)}
                    onChange = {crop => setCrop(crop)} 
                />
                {croppedImage && <div
                    className="submit-button"
                    id="imgCrop3"
                    onClick={() => onSubmitClick(croppedImage)}
                >
                    Crop
                </div>}
            </div>
        </div>
    );
}

ImageCrop.propTypes = {
    image: PropTypes.string.isRequired,
    crop: PropTypes.object,
    circularCrop: PropTypes.bool,
    onSubmitClick: PropTypes.func.isRequired,
}