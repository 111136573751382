

import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';

import './videoCalling.css';

import {Loading} from '../../../actions/LoadingAction';
import { ShowNameAccordingToLength } from '../../../utility/stringFunctions';
import profilePlaceholder from '../../../images/profile_placeholder.png';
import * as routes from '../../../constants/routes';

const jitsiContainerStyle = {
  width: window.innerWidth < 1500 ?(window.innerWidth-100): (window.innerWidth - 300),
  height:(window.innerHeight- 150),
}


var JitsiMeetExternalAPI = window.JitsiMeetExternalAPI;


 class VideoCalling extends Component {
  
  constructor(props){
    super(props);
    this.state={
      conversationId:'',
      username:'R-DEE',
      roomName:'R-DEE Meet',
      profilePictureURL:''
    }

  }
  componentDidMount(){
    // alert("rf")
    // console.log(jitsiContainerStyle);
    // this.props
    if(this.props && this.props.match && this.props.match.params && this.props.match.params.conversationId){
      // alert(this.props.match.params.conversationId);

      const {location: {search}} = this.props;

      if(search){
        const {roomname} = queryString.parse (search);
        this.setState({
          roomName:roomname.replace(/_/g, " ")
        })
      }
      this.setState({
        conversationId: this.props.match.params.conversationId
      })
    }

    if(this.props && this.props.userInfo){
    const { user: { Data: { userDetails } } } =  this.props.userInfo;
      const [userPersonalDetails] = userDetails;
 
      if(userPersonalDetails && userPersonalDetails.personalDetails ){
        let userData=userPersonalDetails.personalDetails ;
        this.setState({
          username: ShowNameAccordingToLength(userData.firstName + ' ' + userData.lastName),
          profilePictureURL:userData.profilePictureURL
        },()=>{
          if(window.JitsiMeetExternalAPI){
            //   console.log(window.JitsiMeetExternalAPI);
              this.startConference();
      
            }
        })
      }
    }
    

  }

 startConference() {

  // https://meet.radiologex.com/


  // https://meet.jit.si/gQ8GoJ5LVaQJ83mzLafCXbp7ggl2LN7qodu2Lbg0VM4mN3iJPBy7RvQ2


  // https://meet.radiologex.com/RDEEDailyStandupat1530hrs
      try {
      //  const domain = 'meet.radiologex.com';
       const domain = 'connect.radiologex.com';

       const options = {
        roomName: this.state.conversationId,
        // height: jitsiContainerStyle.height,
        // width: jitsiContainerStyle.width,
        hight: '100%',
        width:  '100%',
        parentNode: document.getElementById('jitsi-container'),
        userInfo: {
          displayName: "R-DEE",
          avatarURL:  this.state.profilePictureURL
        },
        // displayName:'RDEE',
        interfaceConfigOverwrite: {
          APP_NAME: 'Radiologex Meet',
        //  filmStripOnly: false,
        //  SHOW_JITSI_WATERMARK: false,
        //  AUDIO_LEVEL_PRIMARY_COLOR: 'rgba(255,255,255,0.1)',
        // AUDIO_LEVEL_SECONDARY_COLOR: 'rgba(255,255,255,0.1)',
        },
        configOverwrite: {
          startWithAudioMuted: false ,
          // enableDisplayNameChange: true,
        //  disableSimulcast: false,
        },
        filmstripDisplayChanged:{
          visible: false
        }
       };
    
       
       const api = new JitsiMeetExternalAPI(domain, options);
       api.executeCommand('subject', this.state.roomName);
       api.addEventListener('videoConferenceJoined', () => {
        // console.log('Local User Joined');
        api.executeCommand('displayName', this.state.username);
        api.executeCommand('avatarUrl', this.state.profilePictureURL);
       });
      api.on('readyToClose', () => {
            

            if(window.location.pathname.includes ('R-DEE-Meet') ){
              window.location.href=routes.MESSENGER;
            }else{
              window.location.href=routes.PROFILE_DASHBOARD;
            }

            
      });
        
      } catch (error) {
       console.error('Failed to load Jitsi API', error);
      }
    
  }
  componentWillUnmount() {
    // const domain = 'meet.radiologex.com';
    const domain = 'connect.radiologex.com';
    const options = {
        roomName: this.state.conversationId,
        height: jitsiContainerStyle.height,
        width: jitsiContainerStyle.width,
        parentNode: document.getElementById('jitsi-container'),
      }
    const api = new JitsiMeetExternalAPI(domain, options);
    api.removeEventListener('videoConferenceJoined')
    
  }
  
  render() {
    // const roomName = 'my-super-secret-meeting-123e4567-e89b-12d3-a456-426655440000'
    // const userFullName = 'shubham';
    const {conversationId ,username}= this.state;

    return (
      <div className="videoBoxContainer">
     {<div
    id="jitsi-container"
    style={jitsiContainerStyle}
     />}

    {/*conversationId && <Jitsi domain="meet.radiologex.com"  roomName={conversationId} displayName={username}   config={configJIT}/>*/}
      {/*conversationId && <Jitsi  roomName={conversationId} displayName={username}  containerStyle={jitsiContainerStyle} />*/}

      
       
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.Authreducer,
  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators (
    {
      Loading,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoCalling);

// export default Compose;