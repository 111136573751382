import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from 'reactstrap';
import logo from '../logo.svg';
import * as routes from '../../constants/routes';
import { auth, db } from '../../firebase';

const ForgotResetPage = ({ history }) => (
  <div className='div-flex-signup .gradientColorPurpleBlue'>
    <SignUpForm history={history} />
  </div>
);

//################### Sign Up Form ###################
const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  perfectSearch: '',
  error: null,
  showingAlert: false,
};

//A Higher order function with prop name as key and the value to be assigned to
const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value,
});

class SignUpForm extends Component {
  //defining state
  state = {
    ...INITIAL_STATE,
  };

  // onChange = (propName, value) => {
  //   this.setState({
  //     [propName]: value
  //   });
  // };

  onSubmit = (event) => {
    const { username, email, passwordOne } = this.state;
    const { history } = this.props;
    auth
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      //it the above functions resolves, reset the state to its initial state values, otherwise, set the error object
      .then((authUser) => {
        //creating a user in the database after the sign up through Firebase auth API
        db.doCreateUser(authUser.user.uid, username, email)
          .then(() => {
            this.setState({
              ...INITIAL_STATE,
            });
            history.push(routes.DASH); //redirects to Home Page
          })
          .catch((error) => {
            this.setState(byPropKey('error', error));
            this.timer(); //show alert message for some seconds
          });
      })
      .catch((err) => {
        this.setState(byPropKey('error', err));
        this.timer(); //show alert message for some seconds
      });

    event.preventDefault(); //prevents refreshing
  };

  timer = () => {
    this.setState({
      showingAlert: true,
    });

    setTimeout(() => {
      this.setState({
        showingAlert: false,
      });
    }, 4000);
  };

  render() {
    const {
      firstname,
      lastname,
      email,
      passwordOne,
      mobile,
      error,
      showingAlert,
      file,
      displayName,
      perfectSearch,
      otpcode,
    } = this.state;
    //a boolen to perform validation
    const isInvalid =
      passwordOne === '' ||
      email === '' ||
      firstname === '' ||
      mobile === '' ||
      otpcode === '';

    return (
      <div>
        <Row>
          {showingAlert && (
            <Alert color='danger' onLoad={this.timer}>
              {error.message}
            </Alert>
          )}
          <Col sm='12' md='12' lg='12' className='text-center'>
            <img src={logo} alt='logo' />
          </Col>
        </Row>
        <div className='forgetTextDiv'>
          <h4 className='forgetPageText '>
            Forgot your password? Insert your E-mail below and we will send you
            an OTP to reset your password.
          </h4>
        </div>
        <div className='textAlignCenter'>
          <FormGroup>
            <Input
              className='commanButtonClass emailForgotPassword'
              type='email'
              name='email'
              id='examplePassword1'
              placeholder='john.doe@gmail.com'
              value={email}
              onChange={(e) =>
                this.setState(byPropKey('email', e.target.value))
              }
            />
          </FormGroup>
        </div>
        <div className='textAlignCenter positionRel'>
          <FormGroup>
            <Input
              className='commanButtonClass emailForgotPassword'
              type='password'
              name='otpcode'
              id='examplePassword1'
              placeholder='OTP Code*'
              value={otpcode}
              onChange={(e) =>
                this.setState(byPropKey('otpcode', e.target.value))
              }
            />
          </FormGroup>
          <div className='secDiv'>
            <h1 className='secText'>60 ms</h1>
          </div>
        </div>
        <div className='textAlignCenter'>
          <FormGroup>
            <Link to={routes.NEWPASSWORD}>
              <Button type='submit' className='commanButtonClass'>
                Reset Password
              </Button>
            </Link>
          </FormGroup>
        </div>
      </div>
    );
  }
}

//################### Sign Up Link ###################
//used in the sign in when the user don't have an account registered yet
const SignUpLink = () => (
  <p>
    Don't have an account yet? <Link to={routes.SIGN_UP}>Register here</Link>
  </p>
);

//exports
export default withRouter(ForgotResetPage); //using a HoC to get access to history
export { SignUpForm, SignUpLink };

// <form onSubmit={this.onSubmit}>
//   <input
//     value={username}
//     onChange={e => this.setState(byPropKey("username", e.target.value))}
//     // onChange={e => this.onChange("username", e.target.value)}
//     type="text"
//     placeholder="Full Name"
//   />
//   <input
//     value={email}
//     onChange={e => this.setState(byPropKey("email", e.target.value))}
//     type="text"
//     placeholder="Email Address"
//   />
//   <input
//     value={passwordOne}
//     onChange={e =>
//       this.setState(byPropKey("passwordOne", e.target.value))
//     }
//     type="password"
//     placeholder="Password"
//   />
//   <input
//     value={passwordTwo}
//     onChange={e =>
//       this.setState(byPropKey("passwordTwo", e.target.value))
//     }
//     type="password"
//     placeholder="Confirm Password"
//   />
//   <button disabled={isInvalid} type="submit">
//     Sign Up
//   </button>

//   {error && <p>{error.message}</p>}
// </form>
