import * as messageType from './authMessage';

export const EMAIL_BLANK = 'EMAIL';
export const EMAIL_FORMAT = 'EMAIL_FORMAT';
export const EMAIL_NOT_FOUND = 'EMAIL_NOT_FOUND';



export const PROFILE_DOB = 'PROFILE_DOB';
export const PROFILE_GENDER = 'PROFILE_GENDER';
export const PROFILE_PHONENUMBER = 'PROFILE_PHONENUMBER';
export const PROFILE_PHONE_COUNTRYCODE = 'PROFILE_PHONE_COUNTRYCODE';

export const PROFILE_PHONENUMBER_FORMAT = 'PROFILE_PHONENUMBER_FORMAT';

export const PROFILE_PHONENUMBER_OTP = 'PROFILE_PHONENUMBER_OTP';




export const PASSWORD_BLANK = 'PASSWORD_ERROR';
export const PROFILE_TITLE = 'PROFILE_TITLE';
export const PROFILE_DISPLAYNAME = 'PROFILE_DISPLAYNAME';
export const INVALID_CONFIRM_PASSWORD = 'INVALID_CONFIRM_PASSWORD';
export const INVALID_PASSWORD_FORMAT = 'INVALID_PASSWORD_FORMAT';

export const PROFILE_PROFESSIONAL_TITLE = 'PROFILE_PROFESSIONAL_TITLE';
export const PROFILE_FIRSTNAME = 'PROFILE_FIRSTNAME';
export const PROFILE_LASTNAME = 'PROFILE_LASTNAME';
export const PROFILE_PICTURE = 'PROFILE_PICTURE';

export const PROFILE_LOCATION = 'PROFILE_LOCATION';
export const PROFILE_TAGLINE = 'PROFILE_TAGLINE';

export const PROFILE_SEARCHHANDLE = 'PROFILE_SEARCHHANDLE';
export const PROFILE_ABOUTME = 'PROFILE_ABOUTME';

export const EDUCATION_UNIVERSITY = 'EDUCATION_UNIVERSITY';
export const EDUCATION_YEAR = 'EDUCATION_YEAR';
export const EDUCATION_DEGREE = 'EDUCATION_DEGREE';
export const EDUCATION_COURSE = 'EDUCATION_COURSE';
export const MAX_100_CHAR = 'MAX_100_CHAR';

export const PROFESSIONAL_FIRMNAME = 'PROFESSIONAL_FIRMNAME';
export const PROFESSIONAL_EXPERIENCETYPE = ' PROFESSIONAL_EXPERIENCETYPE';
export const PROFESSIONAL_POSITION = 'PROFESSIONAL_POSITION';
export const PROFESSIONAL_STARTDATE = 'PROFESSIONAL_STARTDATE';
export const PROFESSIONAL_ENDDATE = 'PROFESSIONAL_ENDDATE';


export const CREATE_GROUP_NAME = 'CREATE_GROUP_NAME';
export const CREATE_GROUP_IMAGE = 'CREATE_GROUP_IMAGE';



export const authErrorMessage = status => {
  let error;
  if (EMAIL_BLANK === status) {
    error = messageType.EMAIL_REQUIRED;
  }
  if (EMAIL_FORMAT === status) {
    error = messageType.EMAIL_FORMAT_REQUIRED;
  }
  if (PASSWORD_BLANK === status) {
    error = messageType.PASSWORD_REQUIRED;
  }
  if (INVALID_CONFIRM_PASSWORD === status) {
    error = messageType.CONFIRM_PASSWORD;
  }

  if (INVALID_PASSWORD_FORMAT === status) {
    error = messageType.FORMAT_PASSWORD;
  }

  if (PROFILE_TITLE === status) {
    error = messageType.PROFILE_TITLE_REQUIRED;
  }
  if (PROFILE_FIRSTNAME === status) {
    error = messageType.PROFILE_FIRSTNAME_REQUIRED;
  }
  if (PROFILE_LASTNAME === status) {
    error = messageType.PROFILE_LASTNAME_REQUIRED;
  }
  if (PROFILE_PICTURE === status) {
    error = messageType.PROFILE_PICTURE_REQUIRED;
  }
  if (PROFILE_DISPLAYNAME === status) {
    error = messageType.PROFILE_DISPLAYNAME_REQUIRED;
  }
  if (PROFILE_PROFESSIONAL_TITLE === status) {
    error = messageType.PROFILE_PROFESSIONAL_TITLE_REQUIRED;
  }
  if (PROFILE_LOCATION === status) {
    error = messageType.PROFILE_LOCATION_REQUIRED;
  }
  if (PROFILE_TAGLINE === status) {
    error = messageType.PROFILE_TAGLINE_REQUIRED;
  }

  
  if (PROFILE_SEARCHHANDLE === status) {
    error = messageType.PROFILE_SEARCHHANDLE_REQUIRED;
  }
  if (PROFILE_ABOUTME === status) {
    error = messageType.PROFILE_ABOUTME_REQUIRED;
  }

  if (EDUCATION_UNIVERSITY === status) {
    error = messageType.EDUCATION_UNIVERSITY_REQUIRED;
  }
  if (EDUCATION_YEAR === status) {
    error = messageType.EDUCATION_YEAR_REQUIRED;
  }
  if (EDUCATION_DEGREE === status) {
    error = messageType.EDUCATION_DEGREE_REQUIRED;
  }
  if (EDUCATION_COURSE === status) {
    error = messageType.EDUCATION_COURSE_REQUIRED;
  }

  if (MAX_100_CHAR === status) {
    error = messageType.MAX_100_CHARACTER_REQUIRED;
  }

  if (PROFESSIONAL_FIRMNAME === status) {
    error = messageType.PROFESSIONAL_FIRMNAME_REQUIRED;
  }
  if (PROFESSIONAL_EXPERIENCETYPE === status) {
    error = messageType.PROFESSIONAL_EXPERIENCETYPE_REQUIRED;
  }
  if (PROFESSIONAL_STARTDATE === status) {
    error = messageType.PROFESSIONAL_STARTDATE_REQUIRED;
  }
  if (PROFESSIONAL_POSITION === status) {
    error = messageType.PROFESSIONAL_POSITION_REQUIRED;
  }

  if (PROFESSIONAL_ENDDATE === status) {
    error = messageType.PROFESSIONAL_ENDDATE_REQUIRED;
  }

  if (EMAIL_NOT_FOUND === status) {
    error = messageType.EMAIL_NOT_FOUND_ERROR;
  }
  if(CREATE_GROUP_NAME === status){
    error = messageType.CREATE_GROUP_NAME_REQUIRED;

    
  }
  if(CREATE_GROUP_IMAGE === status){
    error = messageType.CREATE_GROUP_IMAGE_REQUIRED;

    
  }

  



  if (PROFILE_DOB === status) {
    error = messageType.PROFILE_DOB_REQUIRED;
  }
  if (PROFILE_GENDER === status) {
    error = messageType.PROFILE_GENDER_REQUIRED;
  }
  if (PROFILE_PHONENUMBER === status) {
    error = messageType.PROFILE_PHONENUMBER_REQUIRED;
  }
  if (PROFILE_PHONENUMBER_FORMAT === status) {
    error = messageType.PROFILE_PHONENUMBER_FORMAT_REQUIRED;
  }

  if (PROFILE_PHONENUMBER_OTP === status) {
    error = messageType.PROFILE_PHONENUMBER_OTP_REQUIRED;
  }

  if (PROFILE_PHONE_COUNTRYCODE === status) {
    error = messageType.PROFILE_PHONE_COUNTRYCODE_REQUIRED;
  }


  

  


  


  

  return error;
};
