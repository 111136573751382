import React, { Component } from 'react';
import firebase from 'firebase';
import moment from 'moment';
import { Button, Modal, ModalBody } from 'reactstrap';
import Compose from '../Compose';
import Toolbar from '../Toolbar';
import ToolbarButton from '../ToolbarButton';
import Message from '../Message';
import MyModal from '../../UI/MyModal';
import { updateNewMessengerList } from '../../../actions/Messenger';
import _ from 'lodash';
import { ShowNameAccordingToLength } from "../../../utility/stringFunctions";
import { userSharedKey, CalculatedSharedSecret, encryptMessage } from '../../../utility/encryption'
import { fetchMessages, getUserChatDeleteDetail, handleUserTypingInfo } from "../../../utility/Chat/fetchData";
import * as routes from '../../../constants/routes';
import { _handleGroupSecretKey, fetchGroupDataFromFirebase, onFocusTyping, onBlurTyping } from '../../../utility/Chat/secretKey'

import './MessageList.css';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  addNotificationHistory,
  sendnotification,
} from '../../../actions/Messenger';

import {
  messengerGetMutedNotificationsListFunc,
  messengerMuteNotificationsFunc,
  messengerUnMuteNotificationsFunc
} from '../../../actions/notifications';

import blueDownScroll from '../../../images/icons/messenger/blueDownScroll.svg';

const MY_USER_ID = 'apple';
const NOTIFICATION_HOUR_VALUE = '8 hours';
const NOTIFICATION_WEEK_VALUE = '1 week';
const NOTIFICATION_FOREVER_VALUE = 'Forever';

// window.addEventListener("beforeunload", (ev) => 
// {  
//   this.onBlurTyping()
// });

class MessageListGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // secretKey
      secretKey: '',
      attachSource: '',

      messages: [],
      messageText: '',
      userOwnUid: this.props.userinfo.user.Data.userDetails[0].personalDetails.docID,
      messengerKey: this.props.messengerKey || '',
      userChatInfo: this.props.userChatInfo,
      groupMembers: [],

      // used for user is typing functionality
      userTypingInfo: {
        userTypingId: null,
        isUserTyping: false,
        userName: "noUser"
      },

      gotMuteResponse: false, // will be true after getting response
      muted: false,

      // chat deletion states
      isUserInDeleteChatList: false, // if user ever deleted the chat , then his uid will come
      usersWhoDeletedChatArray: [],
      deletionOnVal: null,
      totalUsersInChatArray: [],
      urlLocalImg: null,

      limitToFetchMessages: 20, // first time message fetching limit // after limit will inc by 20
      refreshing: false,  // refress loader will be there in place or load more button
      loadMoreOptionClick: false, //  this will handle the scroll to end or not in flatlist
      notLoadMoreMessages: false, // Load more button to SHOW or NOT

      showDeletePopUp: false,
      showNotificationPopUp: false,
      showNotificationPopUpConfirmation: false,
      showLeaveGroupPopUpConfirmation: false,
      showUserCurrentAdmin: false,
      selectedNotificationVal: NOTIFICATION_HOUR_VALUE,
      groupData: null,
      addedContactsArray: [],
      isOpenMessage: null,
      downScrollButtonIndicator: false,
      deletedChatCallCount: 0
    };
    this.openMessage = this.openMessage.bind(this);
  }

  handleScroll = e => {
    let element = e.target;
    if ((element.scrollHeight - element.scrollTop) <= element.clientHeight) {
      this.setState({
        downScrollButtonIndicator: false
      })
      // do something at end of scroll
    }else{
      this.setState({
        downScrollButtonIndicator: true
      })
    }
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: 'smooth' });
    this._handleReadStatus()
  }

  componentDidMount = async () => {
    this._handleDataToLoadEveryTime()
  };

  // this will upload data every time , when user click on messenger list
  async _handleDataToLoadEveryTime() {
    let UserOwnDocID = _.get(this.props.userinfo, 'user.Data.userDetails[0].personalDetails.docID', null)
    const groupData = await fetchGroupDataFromFirebase(this.props.userChatInfo.key);
    // console.log("groupData>>>>>>>>>", groupData)
    this.setState({
      groupData,
      groupMembers: _.toArray(groupData.users)
    }, async () => {
      let groupSecretKey = await _handleGroupSecretKey(groupData.groupPublicKey);
      this.setState({
        secretKey: groupSecretKey
      });
    });

    // get User Chat Delete Detail 
    let reqObj = {
      messengerKey: this.state.messengerKey,
      userUid: UserOwnDocID,
      groupChat: true
    }
    let getUserChatDeleteDetailVar = await getUserChatDeleteDetail(reqObj, (usersArray) => {
      this.setState({ usersWhoDeletedChatArray: usersArray })
    }, (userDetail) => {
      this._handleDeleteDetailsFunc(userDetail);
    });

    // handle any user is typing in the chat FIREBASE ON method is used and 
    // getting unsubscribe in willUnmount method
    let userTypingObj = {
      messengerKey: this.state.messengerKey,
      groupChat: true
    }

    await handleUserTypingInfo(userTypingObj, (res) => {
      if (res !== undefined && res !== null) {
        this.setState({ userTypingInfo: res })
      }
    });

    // this._handleDontShowDeletedMessages(this.state.messengerKey); // to check deleted messages , they need not to show on board
    this.firstTimePullMsg(this.state.messengerKey);// this will retrieve all messages
    this._handleGetAllGroupsAndMutedList();
  }

  // this will be called on every call when user deletes data
  _handleDeleteDetailsFunc(getUserChatDeleteDetailVar){
    this.setState({
      isUserInDeleteChatList: _.get(getUserChatDeleteDetailVar, 'deletionOn', null) ? true : false,
      deletionOnVal: _.get(getUserChatDeleteDetailVar, 'deletionOn', null),
      messages: [],
      deletedChatCallCount: this.state.deletedChatCallCount + 1
    }, async ()=>{
      // console.log("this.state.deletedChatCallCount)>>>>>>>>>>", this.state.deletedChatCallCount)
      if( this.state.deletedChatCallCount > 1){
        await firebase.database().ref(`GroupMessages/${this.state.messengerKey}`).off('child_added');
        await firebase.database().ref(`GroupMessages/${this.state.messengerKey}`).off('child_changed');
        await this.firstTimePullMsg(this.state.messengerKey);
      }
    })
  }

  componentWillUnmount() {
    let database = firebase.database();
    database.ref(`GroupMessages/${this.state.messengerKey}/metaData/deleteChatDetail`).off('value');
    database.ref(`GroupMessages/${this.state.messengerKey}/isTyping`).off('value');
    database.ref(`GroupMessages/${this.state.messengerKey}/messages`).off('child_added');
    database.ref(`GroupMessages/${this.state.messengerKey}/messages`).off('child_changed');
    let reqObj = {
      messengerKey: this.props.messengerKey,
      groupChat: true
    }
    onBlurTyping(reqObj);
  }

  // used to get muted groups and single chat users list
  _handleGetAllGroupsAndMutedList() {
    // console.log("_handleGetAllGroupsAndMutedList")
    let params = {
      "data": {
        // "limit": '',
        "muteID": _.get(this.state, 'groupData.groupId', null)
      }
    }
    // console.log("")
    this.props.messengerGetMutedNotificationsListFunc(params, (res) => {
      // console.log("messengerGetMutedNotificationsList>>>>> result>>>>", res, this.state.groupData.groupId)

      if (_.get(res, 'muteID')) {
        this.setState({
          muted: true
        })
      }
      this.setState({
        gotMuteResponse: true
      })
    })
  }

  // used to MUTE the chat user
  _handleMuteMemberOrGroup() {
    this.setState({
      showNotificationPopUpConfirmation: false
    })
    let params = {
      "data": {
        "sourceUID": "",
        "messengerGroupID": this.state.messengerKey
      }
    }
    this.props.messengerMuteNotificationsFunc(params, (res) => {
      // console.log("res res mute>>>>>",res )
      if (res.status) {
        this.setState({
          muted: true,
          // showNotificationPopUpConfirmation: false
        })
      }
    })
  }

  // used to UNMUTE the chat user
  _handleUnMuteMemberOrGroup() {
    this.setState({
      showNotificationPopUpConfirmation: false
    })
    let params = {
      "data": {
        "sourceUID": "",
        "messengerGroupID": this.state.messengerKey
      }
    }
    this.props.messengerUnMuteNotificationsFunc(params, (res) => {
      // console.log("res res UNMUTE>>>>>",res )
      if (res.status) {
        this.setState({
          muted: false,
          // showNotificationPopUpConfirmation: false
        })
      }
    })
  }

  async _handleDontShowDeletedMessages(messengerKey) {
    const database = firebase.database();

    // to get delete chatdata
    // console.log("this.state.messengerKey>>>>in group chat", messengerKey)
    let ObjectOfDeleteDetail = {};
    await database
      .ref(`GroupMessages/${messengerKey}/metaData/deleteChatDetail`)
      .on('value', async val => {
        // console.log("val>>>>>>>once userDetail in group chat", val.val())
        let usersWhoDeletedChatArray = _.get(val.val(), 'deletedBy', [])
        this.setState({ usersWhoDeletedChatArray }, async () => {
          let objectTobeFound = await usersWhoDeletedChatArray.find(o => o.uid === this.state.userOwnUid);
          if (objectTobeFound !== undefined) {
            ObjectOfDeleteDetail = objectTobeFound
          }
          let deletionOnVal = await _.get(ObjectOfDeleteDetail, 'deletionOn', null);
          await this.setState({
            isUserInDeleteChatList: deletionOnVal !== null ? true : false,
            deletionOnVal
          })
        })
      })/// delete chat ends here
  }

  static getDerivedStateFromProps(nextProps) {
    return {
      messengerKey: nextProps.messengerKey,
      userChatInfo: nextProps.userChatInfo,
    };
  }

  componentDidUpdate = async prevProps => {
    // Typical usage (don't forget to compare props):
    // console.log("componentDidUpdate>>>.this.props.userChatInfo group chat", this.props.userChatInfo)
    if (this.props.messengerKey !== prevProps.messengerKey) {
      let database = firebase.database();
      database.ref(`GroupMessages/${prevProps.messengerKey}/metaData/deleteChatDetail`).off('value');
      database.ref(`GroupMessages/${prevProps.messengerKey}/isTyping`).off('value');
      database.ref(`GroupMessages/${prevProps.messengerKey}/messages`).off('child_added');
      database.ref(`GroupMessages/${prevProps.messengerKey}/messages`).off('child_changed');
      let reqObj = {
        messengerKey: prevProps.messengerKey,
        groupChat: true
      }
      onBlurTyping(reqObj);
      this.setState(
        {
          messages: [],
        },
        () => {
          this._handleDataToLoadEveryTime();
        }
      );
    }
    // this.scrollToBottom();
  };

  firstTimePullMsg = async key => {

    let messages = [];

    let reqObj = {
      threadKey: key,
      deletionOnVal: this.state.deletionOnVal,
      limitToFetchMessages: this.state.limitToFetchMessages,
      groupChat: true
    }

    fetchMessages(reqObj, this.props,
      (item, isChildChanged) => {
        // console.log("item>>>>>>", item);
        if (isChildChanged) { // if message data is changed like read status// then this will run
          // console.log("child changed>>>>>>>>>",item)
          let index = _.findIndex(messages, { key: item.key });
          messages[index] = item;

          var messagesArr = messages.filter((val) => {
            return val.key !== "isTyping" && val.key !== 'usersDetail'
          })

          let uniqueMessages = _.uniqBy([...messagesArr, ...this.state.messages], (val) => {
            return val.key
          })
          this.setState({ messages: uniqueMessages, refreshing: false, isLoading: false }, () => {
            this.scrollToBottom()
          })
        } else { // it will render on every message is added
          messages.push(item);
          var finalArr = messages.filter((val) => {
            return val.key !== "isTyping" && val.key !== 'usersDetail'
          })

          // it will filter the message which are double in the message list
          let uniqueMessages = _.uniqBy([...finalArr, ...this.state.messages], (val) => {
            return val.key
          })
          // console.log("uniqueMessages>>>>>>>>>>>", uniqueMessages)
          this.setState({ messages: uniqueMessages, refreshing: false, isLoading: false }, () => {
            this.scrollToBottom()
          })
        }

      },
      () => {
        // console.log("not pushing into message>>>>>")
        this.setState({
          notLoadMoreMessages: true
        })
      }
    )

  };

  // this will be called on load more button press
  HandleShowmore = async (key) => {
    const database = firebase.database()
    let messagesLength = this.state.messages.length;
    if (messagesLength > 0) {
      if (!this.state.notLoadMoreMessages && _.get(this.state.messages[0], 'timeStamp')) {

        let lastMessageKey = _.get(this.state.messages[0], 'key')
        let dataFirstItemkey = null
        let messages = [];

        // *******to check the first node key of the chat **///
        const snapShotFirstObject = await database
          .ref('GroupMessages')
          .child(key)
          .limitToFirst(1).once('value')
        // console.log("snapShotFirstObject", snapShotFirstObject)

        snapShotFirstObject.forEach(item => {
          // console.log("first itemmmm", item)
          dataFirstItemkey = item.key   //  this will check the first object of the node from firebase
        })
        //*********////// */

        let isMoreMessage;
        const snapshot = await database
          .ref('GroupMessages')
          .child(key)
          .orderByChild('timeStamp')
          .endAt(this.state.messages[0].timeStamp)
          .limitToLast(this.state.limitToFetchMessages)
          .once('value');

        isMoreMessage = snapshot.hasChildren(); //  true if more entries are there
        // console.log("numChildren>>>>numChildren group chat", snapshot.numChildren())
        if (isMoreMessage) {
          snapshot.forEach(message => {

            // it checks whether any key matches with first key
            // if matched then hide the load more button
            if (dataFirstItemkey == message.key) {
              this.setState({
                notLoadMoreMessages: true
              })
            }
            ////////***********///// */

            var item = message.val();
            item.key = message.key;

            if (this.state.deletionOnVal !== null) {
              let timestampOfMessage = _.get(item, 'timeStamp', null);
              if (timestampOfMessage !== null && timestampOfMessage > this.state.deletionOnVal) {
                messages.push(item);
              } else if (timestampOfMessage !== null && timestampOfMessage < this.state.deletionOnVal) {
                this.setState({
                  notLoadMoreMessages: true
                })
              }
            } else {
              messages.push(item);
            }
          })
        }

        //  let uniqueMessageList =  _.uniqBy(...this.state.messages, finalArr)
        var finalArr = messages.filter((val) => {
          // console.log("invalid is typing >>>>>>", val)
          return lastMessageKey !== val.key && val.key !== "isTyping" && val.key !== 'usersDetail'
        })
        // console.log("finalArr>>>>>>>finalArr",finalArr)
        this.setState({ messages: [...finalArr, ...this.state.messages], refreshing: false })
      } else {
        this.setState({
          refreshing: false
        })
      }


    }
  }

  // this function is used to delete the group conversation for current user,
  //but chat will be availble for all other user and in the firebase for lifetime
  async _handleDeleteConversation() {
    let userIdWhoDelete = this.state.userOwnUid;
    let chatDeletedUsersArray = this.state.usersWhoDeletedChatArray;
    let newArrayOfUsersDeletedChat = [];
    if (chatDeletedUsersArray.length > 0 && this.state.isUserInDeleteChatList) {
      newArrayOfUsersDeletedChat = chatDeletedUsersArray.map((val) => {
        if (val.uid == userIdWhoDelete) {
          return { ...val, deletionOn: Math.floor(Date.now()) }
        } else {
          return val
        }
      })
    } else {
      chatDeletedUsersArray.push(
        { uid: userIdWhoDelete, deletionOn: Math.floor(Date.now()) }
      )
    }

    let ObjTobeUpdated = {
      usersDetail: {
        users: this.state.totalUsersInChatArray,
        deletedBy: newArrayOfUsersDeletedChat.length > 0
          ? newArrayOfUsersDeletedChat
          : chatDeletedUsersArray
      }
    }
    await firebase.database().ref(`GroupMessages/${this.state.messengerKey}/metaData`).update(ObjTobeUpdated);
    this.setState({ messages: [], isUserInDeleteChatList: true, showDeletePopUp: false, deletionOnVal: Math.floor(Date.now()) }, async () => {
      await firebase.database().ref(`GroupMessages/${this.state.messengerKey}/messages`).off('child_added');
      await firebase.database().ref(`GroupMessages/${this.state.messengerKey}/messages`).off('child_changed');
      await firebase.database().ref(`GroupMessages/${this.state.messengerKey}/metaData`).update({
        lastMsg: ''
      });
      await this.firstTimePullMsg(this.state.messengerKey);
    })

    // await firebase.database().ref(`Users/${userIdWhoDelete}/chatList/${this.state.messengerKey}/lastMsg`).set('');
  }

  // if user himself in a chat box then mark all messages read
  _handleReadStatus() {
    const database = firebase.database()
    if (this.state.messages.length > 0) {
      setTimeout(() => {
        database
          .ref(`Users/${_.get(this.props.userinfo, 'user.Data.userDetails[0].personalDetails.docID', null)}/chatList/${this.state.messengerKey}/read`).set(true)
      }, 2000);
    }
  }

  openMessage(item) {
    this.setState({
      isOpenMessage: item.key
    })
  }

  renderMessages() {
    // console.log("this.state.groupData>>>>>", this.state.groupData, "messengerKey", this.props.messengerKey)
    let i = 0;
    let messageCount = this.state.messages.length;
    let messages = [];

    while (i < messageCount) {
      let previous = this.state.messages[i - 1];
      let current = this.state.messages[i];
      // console.log("current in group message>>>", current)
      let next = this.state.messages[i + 1];
      let isMine =
        current.sender ===
        this.props.userinfo.user.Data.userDetails[0].personalDetails.docID;
      let currentMoment = moment(current.timeStamp);
      let prevBySameAuthor = false;
      let nextBySameAuthor = false;
      let startsSequence = true;
      let endsSequence = true;
      let showTimestamp = true;

      if (previous) {
        let previousMoment = moment(previous.timestamp);
        let previousDuration = moment.duration(
          currentMoment.diff(previousMoment)
        );
        // prevBySameAuthor = previous.author === current.author;

        // if (prevBySameAuthor && previousDuration.as('hours') < 1) {
        //   startsSequence = false;
        // }

        // if (previousDuration.as('hours') < 1) {
        //   showTimestamp = false;
        // }

        prevBySameAuthor = previous.sender === current.sender;

        if (prevBySameAuthor) {
          startsSequence = false;
        }

        if (previousDuration.as('hours') < 1) {
          showTimestamp = false;
        }

      }

      if (next) {
        let nextMoment = moment(next.timestamp);
        let nextDuration = moment.duration(nextMoment.diff(currentMoment));
        // nextBySameAuthor = next.author === current.author;

        // if (nextBySameAuthor && nextDuration.as('hours') < 1) {
        //   endsSequence = false;
        // }

        nextBySameAuthor = next.sender === current.sender;
        if (nextBySameAuthor) {
          endsSequence = false;
        }
      }
      const { isOpenMessage } = this.state;

      messages.push(
        <Message
          key={i}
          isMine={isMine}
          secretKey={this.state.secretKey.toString()}
          startsSequence={startsSequence}
          endsSequence={endsSequence}
          showTimestamp={showTimestamp}
          userInfo={_.get(this.props, 'userinfo.user.Data.userDetails[0].personalDetails', {})}
          data={current}
          chatType="groupChat"
          isOpenMessage={isOpenMessage}
          openMessage={this.openMessage}
        />
      );

      // Proceed to the next message.
      i += 1;
    }

    return messages;
  }

  // this is used to send messages in firebase and notifications to the users
  async _handleSendClick(
    messengerKey,
    senderId, // user own UID
    addedContactsArray, // all group members
    groupId,
    text, // this is text entered
    type, // this is type : 'text' or 'image'
    imgData,
    attachSource
  ) {
    {
      const database = firebase.database();

      let senderDisplayName = ShowNameAccordingToLength(this.props.userinfo.user.Data.userDetails[0].personalDetails.firstName + " " + this.props.userinfo.user.Data.userDetails[0].personalDetails.lastName)
      let senderProfilePic = this.props.userinfo.user.Data.userDetails[0]
        .personalDetails.profilePictureURL

      const message = {
        sender: senderId,
        text: encryptMessage(text.toString(), this.state.secretKey.toString()),
        type: type,
        timeStamp: Math.floor(Date.now()),
        read: false,
        chatType: 'groupChat',
        sendType: 'normal', // forward, quote
        // showTopRightDropDown: false,
        senderProfilePic: this.props.userinfo.user.Data.userDetails[0]
          .personalDetails.profilePictureURL,
        senderName: this.props.userinfo.user.Data.userDetails[0].personalDetails
          .firstName,
        sent: true,
        delivered: true,
        messageAddedInMainNode: true
      };
      if (text !== '' && (type == 'text' || type == 'videoCall')) {
        await database.ref('GroupMessages').child(messengerKey).child('messages').push(message).key;
        // UPDATING THE METADATA OF THE NODE
      await database.ref(`GroupMessages/${messengerKey}/metaData`).update({
        "lastMsg": text,
        "sender": senderId,
        "senderName": senderDisplayName,
        'timeStamp': Math.floor(Date.now()),
        "mediaType": type,
        "type": 'text',
        "chatThread": messengerKey,
        "chatType": 'groupChat',
        "readBy": {
          [`${senderId}`]: {
            read: true
          }
        },
      })
      }

      //  else if (type == 'image') {
      else {
        const attach = {
          sender: senderId,
          attachSource: encryptMessage(attachSource.toString(), this.state.secretKey.toString()),
          type: 'attach',
          mediaType: type,
          fileName: imgData ? encryptMessage(imgData.name.toString(), this.state.secretKey.toString()) : '',
          fileSize: imgData ? imgData.size : '',
          // mediaType: "image",
          text: encryptMessage(text.toString(), this.state.secretKey.toString()),
          timeStamp: Math.floor(Date.now()),
          read: false,
          chatType: 'groupChat',
          sendType: 'normal', // forward, quote
          senderProfilePic: senderProfilePic,
          senderName: senderDisplayName,
          sent: true,
          delivered: true,
          messageAddedInMainNode: true
        }
        await database
          .ref('GroupMessages')
          .child(messengerKey)
          .child("messages")
          .push(attach).key
      }

      // UPDATING THE METADATA OF THE NODE
      await database.ref(`GroupMessages/${messengerKey}/metaData`).update({
        "lastMsg": text,
        "sender": senderId,
        "senderName": senderDisplayName,
        'timeStamp': Math.floor(Date.now()),
        "mediaType": type,
        "type": 'attach',
        "chatThread": messengerKey,
        "chatType": 'groupChat',
        "readBy": {
          [`${senderId}`]: {
            read: true
          }
        },
      })

      // creating new node chatList in the Users node and saving data into it
      // addedContactsArray.map(val => {
      //   return database.ref(`Users/${val.docID}/chatList/${groupId}`).update({
      //     lastMsg: type !== 'text' ? type : text,
      //     // lastMsg: type !== 'text' ? (val.docID == senderId) ? `You have shared ${type}` : `${val.firstName} has shared ${type}` : text,
      //     lastMsgSenderName: senderDisplayName,
      //     lastMsgSenderId: senderId,
      //     timeStamp: Math.floor(Date.now()),
      //     read: val.docID == senderId ? true : false,
      //     type: type === 'text' ? "text" : "attach"
      //   });
      // });

      let pushNotiFicationTargetsArr = []; // array whom to send notifications
      addedContactsArray.filter((val) => {
        if (val.docID !== senderId) {
          pushNotiFicationTargetsArr.push(val.docID)
        }
      })

      // sending notification on sending message 
      let objReq = {
        "data": {
          "sourceModule": "messenger", // "sourceModule" for push notifiaction and "source" for notification history
          // "sourceUID": "groupId",
          "MessengerGroupID": groupId,
          "messengerGroupID": groupId,   // ---> THIS IS FOR MUTES,
          "title": `R-DEE Connect`,
          "chatType": "groupChat",
          "body": `${senderDisplayName} sent a message`,
          "targetUID": pushNotiFicationTargetsArr,
          "titleChat": this.state.groupData.groupName,
          "profilePictureURL": `${this.state.groupData.groupIcon}`,
          "messengerKey": groupId, // for push notification body
          "screen": "groupChat" // // for push notification body
        }
      }
      this.props.sendnotification(objReq);
    }
  }

  // for mute functionality 
  onMuteNotificationClick = () => {
    this.setState({ showNotificationPopUpConfirmation: false });
    const { onMuteNotificationClick } = this.props;
    if (
      onMuteNotificationClick &&
      typeof onMuteNotificationClick === 'function'
    ) {
      onMuteNotificationClick(this.state.selectedNotificationVal);
    }
  };


  onDeleteSubmit = () => {
    this.setState({ showDeletePopUp: false });
    const { onDeleteConversation } = this.props;
    // console.log("onDeleteConversation>>>>>", onDeleteConversation)
    // if (onDeleteConversation && typeof onDeleteConversation === 'function') {
    //   onDeleteConversation ();
    // }
  };

  changeText = event => {
    this.setState({ messageText: event.target.value }, () => {
      let reqObj = {
        messengerKey: this.state.messengerKey,
        userDetail: this.props.userinfo.user.Data.userDetails[0],
        text: this.state.messageText,
        groupChat: true
      }
      onFocusTyping(reqObj);

    });
  };

  updateImage = (url, fileType, filesData, captionText) => {
    this.setState(
      {
        messageText: captionText,
        attachSource: url
      },
      () => {

        this.sendText(fileType, filesData);
      }
    );
  };

  async sendText(type, filesData) {
    const database = firebase.database();

    // this will send the message in the firebase
    // and will update on every user node messenger list
    // let type = typeStatus==='text' ? 'text' :'attach';
    this._handleSendClick(
      this.props.messengerKey,
      this.props.userinfo.user.Data.userDetails[0].personalDetails.docID,
      this.state.groupMembers,
      this.state.groupData.groupId,
      this.state.messageText.trim(),
      type,
      filesData,
      this.state.attachSource
    );
    this.setState({ messageText: '' })
  }


  async _handleadminAndLeaveGroup() {
    const database = firebase.database();
    // console.log("this.state.groupData>>>>>>", this.state.groupData.groupAdminUid)
    if (this.state.userOwnUid !== this.state.groupData.groupAdminUid) {
      this._handleLeaveGroupFunction() // when user is not current admin
    } else {
      this.setState({
        showLeaveGroupPopUpConfirmation: false,
        showUserCurrentAdmin: true
      })

      // alert("implement it properly")

      //updating new admin assigned and then leave process
      const updatedGroupDetails = {
        // groupAdminUid: this.state.selectedAdmin, // select admin here then proceed to leave function
        // groupAdminsArray: [this.state.selectedAdmin]
      };

      // const conversationRef = database.ref(`GroupChatDetail/${this.state.groupId}`);
      // await conversationRef.update(updatedGroupDetails);

      // this._handleLeaveGroupFunction() // this is called when admin assigned or changed
    }
  }

  // leaving group in firebase function 
  // first assign admin then leave
  async _handleLeaveGroupFunction() {
    const database = firebase.database();
    const { groupData, userOwnUid, groupMembers } = this.state;

    // this will remove the thread from the user
    database.ref(`Users/${userOwnUid}/chatList/${groupData.groupId}`).remove();

    // updating the new users in the group info
    await database.ref(`GroupChatDetail/${groupData.groupId}/users/${userOwnUid}`).remove();
    this.props.onLeaveGroupClick(); // calling the function in Messenger.js file to reset the states
    // await conversationRef.set(newMembersArr);
    this.setState({
      showLeaveGroupPopUpConfirmation: false
    })
  }

  render() {
    // console.log("this.state.groupData>>>>>>>>>>", this.state.groupData)
    const { userTypingInfo, userOwnUid, muted, notLoadMoreMessages ,downScrollButtonIndicator} = this.state;
    const conversationTitle = _.get(this.state,'groupData.groupName', 'Conversation Title');
    // console.log("userTypingInfo>>>>", userTypingInfo)
    const { onGroupInfoClick, userinfo } = this.props;
    let groupMembersLength = `${_.get(this.state, 'groupMembers', []).length}`
    let noOfMembers = groupMembersLength > 0 ? groupMembersLength + ' members' : '0 member'
    let userTyping = (userTypingInfo.isUserTyping && userTypingInfo.userTypingId !== userOwnUid) ? true : false
    let userTypingName = userTypingInfo.userName !== null ? `${userTypingInfo.userName.split(" ")} is typing...` : ["Someone"];
    let crumbOptions = [
      {
        name: 'Group Info',
        onClick: () => onGroupInfoClick(),
      },
      {
        name: muted ? 'Unmute Conversation' : 'Mute Conversation',
        onClick: () => this.setState({ showNotificationPopUpConfirmation: true }),
      },
      {
        name: 'Delete Conversation',
        onClick: () => this.setState({ showDeletePopUp: true }),
      },

      {
        name: 'Leave Group',
        onClick: () => this.setState({ showLeaveGroupPopUpConfirmation: true }),
      },
      {
        name: 'Video Call',
        onClick: () => {

          let newRoomname = this.state.groupData.groupName.replace(/ /g, "_");
          this._handleSendClick(
            this.props.messengerKey,
            this.props.userinfo.user.Data.userDetails[0].personalDetails.docID,
            this.state.groupMembers,
            this.state.groupData.groupId,
            // this.state.messageText.trim(),
            "Please join meeting from the url " + "https://" + window.location.hostname + routes.VIDEO_CALL + '/' + this.props.userinfo.user.Data.confBridgeCode + '?roomname=' + newRoomname,
            'videoCall',
          );
          // this.setState({ messageText: '' })
          this.props.history.push(routes.VIDEO_CALL + '/' + this.props.userinfo.user.Data.confBridgeCode + '?roomname=' + newRoomname)
        }
      }
    ];


    return (
      <div>
        <div className="message-list" id="message-list-single">
          <div className="toolbarSticky">
            <Toolbar
              title={conversationTitle}
              showMuteIcon={this.state.muted}
              onClickVideoIcon={() => {
                let newRoomname = this.state.groupData.groupName.replace(/ /g, "_");
                this._handleSendClick(
                  this.props.messengerKey,
                  this.props.userinfo.user.Data.userDetails[0].personalDetails.docID,
                  this.state.groupMembers,
                  this.state.groupData.groupId,
                  // this.state.messageText.trim(),
                  "Please join meeting from the url " + "https://" + window.location.hostname + routes.VIDEO_CALL + '/' + this.props.userinfo.user.Data.confBridgeCode + '?roomname=' + newRoomname,
                  'videoCall',
                );
                // this.setState({ messageText: '' })
                this.props.history.push(routes.VIDEO_CALL + '/' + this.props.userinfo.user.Data.confBridgeCode + '?roomname=' + newRoomname)
              }
              }
              imgSource={_.get(this.state.groupData, 'groupIcon', null)}
              onlineStatus={userTyping ? userTypingName : noOfMembers}
              rightItems={[
                <ToolbarButton
                  key="info"
                  icon="ion-ios-information-circle-outline"
                />,
                <ToolbarButton key="video" icon="ion-ios-videocam" />,
                <ToolbarButton key="phone" icon="ion-ios-call" />,
              ]}
              showCrumbs={true}
              crumbOptions={crumbOptions}
            />
          </div>

          {/* this is all messages list*/}
          <div className="message-list-container"  onScroll={this.handleScroll}>
            {(!notLoadMoreMessages && this.state.messages.length > 19) && <div className="loadMoreDiv">
              <Button className="loadMoreChatButton" onClick={() => this.HandleShowmore(this.state.messengerKey)}>Load more</Button>
            </div>}
            {this.renderMessages()}
            {downScrollButtonIndicator && <div >
              <Button className="goDownScrollGroupChatButton" onClick={()=>this.scrollToBottom()}>
                <img src={blueDownScroll} alt="blueDownScroll"/>
              </Button>
            
            </div>}
            <div style={{ float: "left", clear: "both" }}
              ref={(el) => { this.messagesEnd = el; }}>
            </div>
          </div>

        </div>



        <Compose
          updateImage={this.updateImage}
          onSendClick={(e) => {
            e.preventDefault();
            if (this.state.messageText.trim() !== '') {
              this.sendText('text');
            }
          }}
          onFocus={() => {
            let reqObj = {
              messengerKey: this.state.messengerKey,
              userDetail: this.props.userinfo.user.Data.userDetails[0],
              text: this.state.messageText,
              groupChat: true
            }
            onFocusTyping(reqObj);
          }}
          onBlur={() => {
            let reqObj = {
              messengerKey: this.state.messengerKey,
              groupChat: true
            }
            onBlurTyping(reqObj);
          }}
          value={this.state.messageText}
          onChange={this.changeText}
        />
        {/* <MyModal
          show={this.state.showNotificationPopUp}
          showSubmitCancel
          title={'Mute Notifications for...'}
          onSubmit={() =>
            this.setState ({
              showNotificationPopUp: false,
              showNotificationPopUpConfirmation: true,
            })}
          toggle={() => this.setState ({showNotificationPopUp: false})}
          className="notification-modal"
        >
          <form className="notification-option-list">
            <p>
              <input
                type="radio"
                id="select1"
                name="radio-group"
                value={NOTIFICATION_HOUR_VALUE}
                checked={
                  this.state.selectedNotificationVal === NOTIFICATION_HOUR_VALUE
                }
                onClick={() =>
                  this.setState ({
                    selectedNotificationVal: NOTIFICATION_HOUR_VALUE,
                  })}
              />
              <label for="select1">{NOTIFICATION_HOUR_VALUE}</label>
            </p>
            <p>
              <input
                type="radio"
                id="select2"
                name="radio-group"
                value={NOTIFICATION_WEEK_VALUE}
                checked={
                  this.state.selectedNotificationVal === NOTIFICATION_WEEK_VALUE
                }
                onClick={() =>
                  this.setState ({
                    selectedNotificationVal: NOTIFICATION_WEEK_VALUE,
                  })}
              />
              <label for="select2">{NOTIFICATION_WEEK_VALUE}</label>
            </p>
            <p>
              <input
                type="radio"
                id="select3"
                name="radio-group"
                value={NOTIFICATION_FOREVER_VALUE}
                checked={
                  this.state.selectedNotificationVal ===
                    NOTIFICATION_FOREVER_VALUE
                }
                onClick={() =>
                  this.setState ({
                    selectedNotificationVal: NOTIFICATION_FOREVER_VALUE,
                  })}
              />
              <label for="select3">{NOTIFICATION_FOREVER_VALUE}</label>
            </p>
          </form>
        </MyModal> */}

        <Modal
          size="sm"
          isOpen={this.state.showNotificationPopUpConfirmation}
          centered={true}
          toggle={() => this.setState({ showNotificationPopUpConfirmation: false })}
        >
          <ModalBody className="placeOrderModal">
            <div>

              <p className="confirmTitle">{muted ? 'Unmute Conversation' : "Mute Conversation"}</p>
              <p className="confirmmsg">
                {`Are you sure you wish to ${muted ? 'unmute' : 'mute'} '${conversationTitle}'?`}
              </p>
              <div className="buttonDiv">
                <Button className="buttons" onClick={() => this.setState({ showNotificationPopUpConfirmation: false })}>
                  No
              </Button>
                <Button
                  className="buttons yesButton"
                  onClick={() => muted ? this._handleUnMuteMemberOrGroup() : this._handleMuteMemberOrGroup()}
                >
                  Yes
              </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>



        <Modal
          size="sm"
          isOpen={this.state.showLeaveGroupPopUpConfirmation}
          centered={true}
          toggle={() => this.setState({ showLeaveGroupPopUpConfirmation: false })}
        >
          <ModalBody className="placeOrderModal">
            <div>

              <p className="confirmTitle">Leave Group</p>
              <p className="confirmmsg">
                {`Are you sure you want to leave the  ${conversationTitle}?`}
              </p>
              <div className="buttonDiv">
                <Button className="buttons" onClick={() => this.setState({ showLeaveGroupPopUpConfirmation: false })}>
                  No
                </Button>
                <Button
                  className="buttons yesButton"
                  onClick={() => this._handleadminAndLeaveGroup()}
                >
                  Yes
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>


        <MyModal
          show={this.state.showUserCurrentAdmin}
          showSubmitCancel
          title={'Leave Group'}
          onSubmit={() => this.setState({
            showUserCurrentAdmin: false
          })}
          toggle={() =>
            this.setState({ showUserCurrentAdmin: false })}
          className="notification-modal"
          submitText="Ok"
        >
          <div className="mute-notification-body">
            {`You are current admin of this group.please go to group Info and assign an admin and then try to leave group.`}
          </div>
        </MyModal>

        <Modal
          size="sm"
          isOpen={this.state.showDeletePopUp}
          centered={true}
          toggle={() => this.setState({ showDeletePopUp: false })}
        >
          <ModalBody className="placeOrderModal">
            <div>

              <p className="confirmTitle">Delete Conversation</p>
              <p className="confirmmsg">
                Are you sure you want to permanently delete this conversation?
              </p>
              <div className="buttonDiv">
                <Button className="buttons" onClick={() => this.setState({ showDeletePopUp: false })}>
                  Back
                </Button>
                <Button
                  className="buttons yesButton"
                  onClick={() => this._handleDeleteConversation()}
                >
                  Yes
                </Button>
              </div>
            </div>
          </ModalBody>
        </Modal>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userinfo: state.Authreducer,
    messengerList: state.MessengerReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    sendnotification,
    addNotificationHistory,
    messengerGetMutedNotificationsListFunc,
    messengerMuteNotificationsFunc,
    messengerUnMuteNotificationsFunc,
    updateNewMessengerList
  }, dispatch)
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageListGroup);